import React from "react";
import Configures from "../configures/index";
import {GetLoginToken} from "./token";
import {GetConfigures} from "../configures/serve";
import {SERVER_HOST} from "../constants/info-woodchipping";

export const PostToWoodChipping=async  (payload,endpoint,callback)=>{
    const server =SERVER_HOST;
    let url = server + "" + endpoint;
    const token = GetLoginToken();

    fetch(url, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(payload)
    })
        .then((response) => response.json())
        .then((response) => {
            //todo let check if the response code is 401 Unauthorized
            if(typeof response.error !=="undefined"){
                if( response.error ==="Unauthorised access to this resource"){
                    //alert("Unauthorised access to this resource, Please login!")
                    window.location.href = "#/login"
                }
            }
            callback(response, null);
        }).catch((e) => {
        callback(null, e, null);
    });

}
export const PostToPbx=async  (hub,endpoint,callback)=>{
    const server =GetConfigures();
    let url = server + "" + endpoint;
    const token = GetLoginToken();

    fetch(url, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(hub)
    })
        .then((response) => response.json())
        .then((response) => {
            //todo let check if the response code is 401 Unauthorized
            if(typeof response.error !=="undefined"){
                if( response.error ==="Unauthorised access to this resource"){
                    //alert("Unauthorised access to this resource, Please login!")
                    window.location.href = "#/login"
                }
            }
            callback(response, null);
        }).catch((e) => {
        callback(null, e, null);
    });

}

export const PostToAny=async  (endpoint,hub,callback)=>{
    const server =GetConfigures();
    let url =  endpoint;
    const token = GetLoginToken();

    fetch(url, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(hub)
    })
        .then((response) => response.json())
        .then((response) => {
            //todo let check if the response code is 401 Unauthorized
            if(typeof response.error !=="undefined"){
                if( response.error ==="Unauthorised access to this resource"){
                    //alert("Unauthorised access to this resource, Please login!")
                    window.location.href = "#/login"
                }
            }
            callback(response, null);
        }).catch((e) => {
        callback(null, e, null);
    });

}


