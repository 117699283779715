import React from "react";
import {Col, Form, Row} from "antd";

import ImgTotalHeadcount from "../../assets/payroll-contract-dashboard-icon/TotalHeadcount.png"
import ImgActiveHeadcount from "../../assets/payroll-contract-dashboard-icon/ActiveHeadcount.png"
import ImgTerminated from "../../assets/payroll-contract-dashboard-icon/Terminated.png"
import ImgFemale from "../../assets/payroll-contract-dashboard-icon/Female.png"
import ImgMale from "../../assets/payroll-contract-dashboard-icon/Male.png"
import ImgDesignations from "../../assets/payroll-contract-dashboard-icon/Designations.png"

import ImgClockedInToday from "../../assets/payroll-contract-dashboard-icon/ClockedInToday.png"
import ImgUnClockedToday from "../../assets/payroll-contract-dashboard-icon/UnClockedToday.png"
import ImgClockedInPeriod from "../../assets/payroll-contract-dashboard-icon/ClockedInPeriod.png"
import ImgUnclockedPeriod from "../../assets/payroll-contract-dashboard-icon/UnclockedPeriod.png"

import ImgSitesUnclocked from "../../assets/payroll-contract-dashboard-icon/SitesUnclocked.png"
import ImgClocksUnapproved from "../../assets/payroll-contract-dashboard-icon/ClocksUnapproved.png"
import ImgCash from "../../assets/payroll-contract-dashboard-icon/cash.jpg"


export const FormatMoneySouthAfrica = (num, div = " ") => {
    num = parseFloat(num.toFixed(2))
    let strResult = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, div);
    let arr = strResult.split(".");
    if(arr.length===1){
        strResult = strResult+".00";
    }else{
        let last = arr[1];
        if(last.length===1){
            strResult = strResult+"0";
        }
    }
    return strResult;

}
export default ({result}) => {

    const RenderBox = ({color, icon, amount, label}) => {
        return (
            <Col span={4} style={{
                display:"flex",
                backgroundColor: color,
                height: 150,
                justifyContent: "space-between",
                alignItems: "center",
                flex: 1,
                color: "white",
                margin:10,
                padding:10,
                flexDirection:"column",
                borderRadius:10
            }}>
                {icon!==""?<img src={icon} style={styles.icon}  alt={label}/>:null}
                <label style={styles.amount}>{amount}</label>
                <label style={styles.label}>{label}</label>
            </Col>
        )
    }
    return (
        <div style={styles.container}>
            <Row gutter={16} style={{marginTop: 20}}>
                <RenderBox
                    label={"Total Headcount"}
                    color={"#0E3961"}
                    amount={result.TotalHeadCount}
                    icon={ImgTotalHeadcount}
                />
                <RenderBox
                    label={"Active Headcount"}
                    color={"#14545C"}
                    amount={result.ActiveHeadCount}
                    icon={ImgActiveHeadcount}
                />
                <RenderBox
                    label={"Terminated Headcount"}
                    color={"#146E5C"}
                    amount={result.TerminatedHeadCount}
                    icon={ImgTerminated}
                />
                <RenderBox
                    label={"Female"}
                    color={"#1A8555"}
                    amount={result.Female}
                    icon={ImgFemale}
                />
                <RenderBox
                    label={"Male"}
                    color={"#1C9854"}
                    amount={result.Male}
                    icon={ImgMale}
                />
                <RenderBox
                    label={"Designations"}
                    color={"#00B050"}
                    amount={result.Designations}
                    icon={ImgDesignations}
                />
            </Row>

            <Row gutter={16} style={{marginTop: 20}}>
                <RenderBox
                    label={"Clocked In Today"}
                    color={"#0E3961"}
                    amount={result.ClockedToday}
                    icon={ImgClockedInToday}
                />
                <RenderBox
                    label={"Not Clocked In today"}
                    color={"#14545C"}
                    amount={result.NotClockedToday}
                    icon={ImgUnClockedToday}
                />
                <RenderBox
                    label={"Clocked for the Period"}
                    color={"#146E5C"}
                    amount={result.ClockedPeriod}
                    icon={ImgClockedInPeriod}
                />
                <RenderBox
                    label={"Unclocked for the period"}
                    color={"#1A8555"}
                    amount={result.UnClockedPeriod}
                    icon={ImgUnclockedPeriod}
                />
                <RenderBox
                    label={"Site with Unclocked"}
                    color={"#1C9854"}
                    amount={result.SiteWithUnClocked}
                    icon={ImgSitesUnclocked}
                />
                <RenderBox
                    label={"Clocks Unapproved"}
                    color={"#00B050"}
                    amount={result.ClocksUnApproved}
                    icon={ImgClocksUnapproved}
                />
            </Row>

            <Row gutter={16} style={{marginTop: 20}}>
                <RenderBox
                    label={"Gross Pay"}
                    color={"#0E3961"}
                    amount={"R "+FormatMoneySouthAfrica( result.Summary.GrossPay)}
                    icon={ImgCash}
                />
                <RenderBox
                    label={"UIF"}
                    color={"#14545C"}
                    amount={"R "+FormatMoneySouthAfrica( result.Summary.UIF)}
                    icon={ImgCash}
                />
                <RenderBox
                    label={"Deductions"}
                    color={"#146E5C"}
                    amount={"R "+FormatMoneySouthAfrica( result.Summary.Deduction)}
                    icon={ImgCash}
                />
                <RenderBox
                    label={"Paye"}
                    color={"#1A8555"}
                    amount={"R "+FormatMoneySouthAfrica( result.Summary.Paye)}
                    icon={ImgCash}
                />
                <RenderBox
                    label={"Net Pay"}
                    color={"#1C9854"}
                    amount={"R "+FormatMoneySouthAfrica( result.Summary.NetPay)}
                    icon={ImgCash}
                />
                <RenderBox
                    label={""}
                    color={"#00B050"}
                    amount={""}
                    icon={""}
                />
            </Row>
        </div>
    )
}

const styles = {
    icon:{
      width:50,
      height: 50,
    },
    amount:{
      fontsize:24,
      fontWeight:"bold"
    },
    container: {
        padding: 10,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.2)"
    }
}