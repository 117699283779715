import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import ControllerSelectBox from "../../../../components/Common/ControllerInput/ControllerSelectBox";
import {
    ArrayToSelectOption,
    FilterArrayByKey,
    FormatMoneySouthAfrica,
    FormatMoneySouthAfrica2,
    UpcaseFirst
} from "../../../../api/fn";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import {PostToPbx} from "../../../../api/http-post";
import {Button, Col, DatePicker, Drawer, Input, Row} from "antd";
import moment from "moment";
import './styles.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCoffee, faList, faAngleDown, faCartArrowDown, faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons'
//https://www.w3schools.com/icons/fontawesome_icons_directional.asp
import statusGoodIcon from "../../../../assets/img/status-good.jpeg"
import statusBadIcon from "../../../../assets/img/status-bad.jpeg"
import LoadingProcessing from "../../../../components/Common/page/Common/LoadingProcessing";


const formatDate = (dateIn) => {
    return moment(dateIn, "YYYY-MM-DD").format("DD MMM")
}
const formatDay = (dateIn) => {
    return moment(dateIn, "YYYY-MM-DD").format("DDD")
}
const formatDate2 = (dateIn) => {
    return moment(dateIn).format("YYYY-MM-DD")
}
Date.prototype.getWeek = function () {
    let date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    let week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

function getWeekRange(weekNo, yearNo) {
    let firstDayofYear = new Date(yearNo, 0, 1);

    if (firstDayofYear.getDay() > 4) {
        let weekStart = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 8);
        let weekEnd = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 8 + 6);
        return {from: formatDate2(weekStart), to: formatDate2(weekEnd)}
    } else {
        let weekStart = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 1);
        let weekEnd = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 1 + 6);
        return {from: formatDate2(weekStart), to: formatDate2(weekEnd)}
    }
}

class ServiceLogsImportCsvPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Attributes: [],
            Projects: [],
            Periods: [],
            DataDesignation: [],
            Data: [],
            Sites: [],
            Clients: [],
            Items: [],
            Vehicles: [],
            Drivers: [],

            selectedCompany: "",
            selectedClient: "",
            selectedItem: "yes",
            selectedPeriod: "",
            selectedVehicle: "",
            selectedDriver: "",
            newEntry: {
                DeliveryNoteNumber: "",
                TimeInSettlement: "",
                TimeOutSettlement: "",
                TimeArrivedAtDisposed: "",
                TrailerRegistration: "",
                NumberOfUnits: "0"
            },

            mode: "list",
            allDates: [],
            allWeeks: [],
            allDateByWeeks: [],
            startDate: "2022-11-01",
            endDate: moment().format("YYYY-MM-DD"),
            selectedWeek: 0,
            showPopup: false,
            selectedSite: "",
            selectedDate: "",
            selectedLogs: [],
            selectedFilter: "Total",
            fileName:"",
            base64String:"",
            busy:false,
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Projects", "", "", this, undefined, "common/entity/modules/list");
        await fetchDataEntity("Data", "", "", this, undefined, "common/entity/ServiceLogsDeliveryNote/list");
        await fetchDataEntity("Sites", "", "", this, undefined, "common/entity/SettingInvoiceServiceAddonSite/list");
        await fetchDataEntity("Clients", "", "", this, undefined, "common/entity/SettingInvoiceServiceDebtors/list");
        await fetchDataEntity("Items", "", "", this, undefined, "common/entity/SettingInvoiceServiceItem/list");
    }
    onChangeFile=()=>{
        let _this = this;
        const fId=`myFile01`;
        let inputUpload = document.getElementById(fId);
        let myfile = inputUpload.files[0];
        if (!myfile) {
            console.log("No file selected.");
            return;
        }
        let reader = new FileReader();
        reader.readAsDataURL(myfile);
        reader.onload = function () {
            let fileName  = myfile.name
            let base64 = reader.result
            _this.setState({
                fileName:fileName,
                base64String:base64
            });
            let arr=base64.split(";base64,")
            console.log("1 records:::::::> ",base64)
            console.log("2 records:::::::> ",arr[1])
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    handleChangeInput = async (key, value) => {
        this.setState({
            [key]: value,
        })
    }
    handleGetInputValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return ""
        }
        return this.state[key];
    }
    submitCsv = async () => {
        // alert("Coming soon!")
        const state = this.state;
        const newState = state.newEntry;
        let hub = {};
        hub.Org = state.selectedCompany;
        hub.Client = state.selectedClient;
        hub.Item = state.selectedItem;
        hub.FileName = state.fileName;
        hub.Base64String = state.base64String;
        console.log("submitCsv send > ", hub)
        let endpoint = "/services/logs/csv-insert"
        this.setState({busy:true})
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            this.setState({busy:false})
            console.log("submitCsv response > ", data)
            alert("Thank you, your service logs csv import have been recorded!")
        });
    }
    renderCompanyBox = () => {
        let ls = [];
        ls = FilterArrayByKey(this.state.Companies, {})
        ls = ArrayToSelectOption(ls, ["CustomerNumber"], ["Name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Company"}
                name={"selectedCompany"}
            />
        )
    }
    renderClientBox = () => {
        let ls = [];
        for (let i in this.state.Clients) {
            let row = this.state.Clients[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            ls.push(row)
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["name"], ["name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Client"}
                name={"selectedClient"}
            />
        )
    }
    renderItemBox = () => {
        let ls = [];
        for (let i in this.state.Items) {
            let row = this.state.Items[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.client !== this.state.selectedClient) {
                continue
            }
            ls.push(row)
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["name"], ["name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Items"}
                name={"selectedItem"}
            />
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Service Logs Import Csv</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <Row gutter={18}>
                            <Col span={12}>
                                {this.renderCompanyBox()}
                            </Col>
                            <Col span={12}>
                                {this.renderClientBox()}
                            </Col>
                        </Row>
                        <Row gutter={18}>
                            <Col span={24}>
                                {this.renderItemBox()}
                            </Col>
                        </Row>
                        <Row gutter={18} style={{marginTop: 10,paddingLeft:10}}>
                            <Col span={12} style={{marginTop: -5, height: 40}}>
                                <Input id={"myFile01"} type={"file"} className={"ant-picker-input"} onChange={()=>this.onChangeFile()}/>
                            </Col>
                            <Col span={12} style={{marginTop: -5, height: 40}}>
                                <Button
                                    style={styles.datePickerBox}
                                    onClick={() => this.submitCsv()}
                                >
                                    Submit
                                </Button>
                            </Col>
                        </Row>

                    </div>
                </div>
                <LoadingProcessing open={this.state.busy} />
            </Page>
        )
    }
}


export default Connector(ServiceLogsImportCsvPage);
const styles = {
    boxFilterInner: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 5,
        borderRight: "1px solid black",
        fontsize: 14,
        fontWeight: "bold"
    },
    boxFilter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    dateRow: {
        display: "flex",
        flexDirection: "row"
    },
    datePickerBox: {
        height: 40,
        width: "100%"
    },
    row1Th: {
        border: "1px solid black",
    },
    rowForm: {
        marginBottom: 10,
        paddingBottom: 5,
        border: "0px solid lightgray",
        borderBottomWidth: 1,

    },
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}