import React from "react";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import PluginAddDeleteList from "../../../../components/Common/PluginAddDeleteList2";
import {fetchDataEntity} from "../../../../api/entity-data";
import {
    getClientOption,
    getCompanyList, getItemList,
    getMonthOption,
    getProjectList,
    getTemplateList,
    getYearOption, getYesNoOption,
    loadData
} from "./lib";


class LabourSettingInvoiceTemplatePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Modules: [],
            Clients: [],
            Projects:[],
            Items:[],
            Sites:[],
            isLoading: false,
            company: "",
            component: "list",
            newEntry: {},
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await loadData("modules", "Projects",this);
        await loadData("SettingInvoiceServiceDebtors", "Clients",this);
    }

    getProjectList = (data,org) => {
        let ls = [];
        for (let i in data) {
            let row = data[i];
            if (row.org === org) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    render() {
        const org = this.state.newEntry.selectedCompany;
        const companyOptions = getCompanyList(this.state.Companies);
        const projectOptions =this.getProjectList(this.state.Projects,org);
        const templateOptions = [
            {
                key: "Hours",
                val: "Hours"
            },
            {
                key: "Days",
                val: "Days"
            },
        ]
        const clientOptions = getClientOption(this.state.Clients,org);
        console.log("KKKKDisplay Selected Project: ",this.state.newEntry.selectedCompany," > ",this.state);

        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Setup Labour Site Listing</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"SettingInvoiceLabourItemAdditionalInfo"}
                            depending={{
                                "org": {
                                    targetKey: "CustomerNumber",
                                    displayKey: "Name",
                                    storageTable: "Companies"
                                },
                                "client":{
                                    targetKey: "code",
                                    displayKey: "name",
                                    storageTable: "Clients"
                                }
                            }}
                            fields={[
                                {
                                    name: "org",
                                    dataType: "option",
                                    required: true,
                                    label: "Company",
                                    options: companyOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Companies"}
                                },
                                {
                                    name: "client",
                                    dataType: "option",
                                    required: true,
                                    label: "Debtor",
                                    options: clientOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Clients"}
                                },
                                {
                                    name: "template",
                                    dataType: "option",
                                    required: true,
                                    label: "Template",
                                    options: templateOptions,
                                },
                                {
                                    name: "workinghours",
                                    dataType: "float",
                                    required: true,
                                    label: "Working Hours",
                                },


                            ]}
                            deleteKeys={[
                                {key: "org", type: "string"},
                                {key: "project", type: "string"},
                            ]}
                            stateArray={"Data"}
                            table={"SettingInvoiceLabourItemAdditionalInfo"}
                            addFormText={"Labour Invoice Template"}
                            stepTitle={"Setup Labour Invoice Template"}
                            filterByCompany={true}
                            displayRules={[
                                {rowKey:"org",stateKey:"selectedCompany"},
                                {rowKey:"client",stateKey:"selectedClient"},

                            ]}
                            extraFilters={[
                                {
                                    recordKey:"key",
                                    recordValue:"val",
                                    stateKey:"client",
                                    filterRecordKey:"client",
                                    filterStateKey:"selectedClient",
                                    allowBlank:true,
                                    name:"selectedClient",
                                    label:"Debtors",
                                    options: clientOptions,
                                    errorMsg: "Sorry you can't save, Please select your client!"
                                },
                            ]}

                            sortMe={"name"}

                        />

                    </div>
                </div>
            </Page>
        )
    }

}

export default Connector(LabourSettingInvoiceTemplatePage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

