


import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {GetUserToken} from "../../../api/token";
import PluginAddDeleteList from "../../../components/General/PluginAddDeleteList";
import {PostToPbx} from "../../../api/http-post";
import {GetFieldsAdditional, GetFieldsAdditional2} from "../../../constants";
import {fetchDataEntity} from "../../../api/entity-data";
import PageBuilder from "../../../components/web/Common/PageBuilder";


class DatabaseSettingAttributePage2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Modules: [],
            Entities: [],
            isLoading: false,
            component: "list",
            newEntry: {},
            selectedCompany:"",
            selectedEntity:"",
        }
    }

    componentDidMount = async () => {
        //await fetchDataEntity("Companies", "client/entity", "company", this);

        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this);
        await this.loadData("Projects","Projects");
        await this.loadData("Entities","Entities");
    }
    loadData = async (targetEntity, stateKey) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            console.log("SettingSetupSitePage loadInitialData response > ", post, " > ", data);
            let res=[];
            if(data!==null){
                res=data.RESULT
            }
            await _this.setState({
                [stateKey]: res
            })
        });
    }


    getPrefixedListField = () => {
        return GetFieldsAdditional2();
    }


    render() {

        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Setup Attribute</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"attributes"}
                            depending={{"org":{targetKey:"CustomerNumber",displayKey:"Name",storageTable:"Companies"}}}
                            fields={this.getPrefixedListField()}
                            deleteKeys={[
                                {key: "org", type: "string"},
                                {key: "module", type: "string"},
                                {key: "fieldname", type: "string"},
                            ]}
                            displayRules={[
                                {rowKey: "org", stateKey: "selectedCompany"},
                                {rowKey: "module", stateKey: "selectedProject"}
                            ]}
                            stateArray={"Data"}
                            table={"attributes"}
                            addFormText={"Attributes"}
                            stepTitle={"Set Up new attributes"}
                            injectFields={[
                                {
                                    rowKey: "Org",
                                    stateKey: "selectedCompany",
                                    errorMsg: "Sorry you can't save, Please select your company!"
                                },
                                {
                                    rowKey: "Module",
                                    stateKey: "selectedProject",
                                    errorMsg: "Sorry you can't save, Please select your Project!"
                                },
                            ]}
                            filterByCompany={true}
                            filterByModule={true}
                            orderBy={"position"}
                        />
                    </div>
                </div>
            </Page>
        )


        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Setup project form structure 2</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"attributes"}
                            fields={this.getPrefixedListField()}
                            deleteKeys={[
                                {key: "org", type: "string"},
                                {key: "module", type: "string"},
                                {key: "fieldname", type: "string"},
                            ]}
                            stateArray={"Data"}
                            table={"attributes"}
                            addFormText={"Attribute"}
                            stepTitle={"Set Up new attribute"}
                            displayRules={[
                                {rowKey: "org", stateKey: "selectedCompany"},
                                {rowKey: "module", stateKey: "selectedProject"}
                            ]}
                            filterByCompany={true}
                            filterByModule={true}
                            injectFields={[
                                {
                                    rowKey: "Org",
                                    stateKey: "selectedCompany",
                                    errorMsg: "Sorry you can't save, Please select your company!"
                                },
                                {
                                    rowKey: "Module",
                                    stateKey: "selectedProject",
                                    errorMsg: "Sorry you can't save, Please select your Project!"
                                },
                            ]}
                            injectStaticValue={[

                            ]}
                            extra={[]}
                        />
                    </div>
                </div>
            </Page>
        )
    }

}


export default Connector(DatabaseSettingAttributePage2);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

