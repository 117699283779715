/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import {FindImages} from "../../../api/fn";

function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}

const useStyles = makeStyles({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
});

export default (props)=> {
    const classes = useStyles();
    let {
        handleInput,
        handleInput2,
        getHandleValue,
        getEntityData,
        state,
        ls,
        label,
        name,
        files
    }=props;

    let options = [];
    for(let i in ls){
        const row = ls[i];
        let link = "";
        link = FindImages(row.key, files);
        if(link===""){
            link="https://www.pngfind.com/pngs/m/676-6764065_default-profile-picture-transparent-hd-png-download.png"
        }

        options.push({
            code:row.key,
            label:row.val,
            link:link
        })
    }
    const onChange=(e,v)=>{
        if(v===null){
            return
        }
        handleInput2(name,v.code)
    }

    return (
        <Autocomplete
            id="country-select-demo"
            style={{ width: "100%" }}
            options={options}
            classes={{
                option: classes.option,
            }}
            autoHighlight
            getOptionLabel={option => option.label}
            renderOption={option => (
                <React.Fragment>
                    {/*<span>{countryToFlag(option.code)}</span>*/}
                    <img
                        src={option.link}
                        alt={""}
                        className="img-thumbnail"
                        width={50}
                        height={50}
                        style={{borderRadius: 5}}
                    />
                    {option.label}
                </React.Fragment>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    label={"Choose a "+label}
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}

                />
            )}
            onChange={(e,v)=>onChange(e,v)}
        />
    );
}
