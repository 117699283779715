import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import {
    GetMonthList,
    GetYearList,
    LoadDataCommon,
    BuilderOptionComboBox,
    CheckConditions,
    BuilderTableList
} from "../../../../api/fn";
import moment from "moment";
import Title from "../../../../components/Common/Title";
import {Col, Form, Row, Select, Divider, Table} from "antd";
import Page from "../../../../layout/web/Page";
import Connector from "../../../../redux/connector";
import DrawerNewEntry from "../../../../components/General/DrawerNewEntry";
import {UploadToDropBoxBase64WithBusket} from "../../../../services/dropbox-uploader";
import {PostToPbx} from "../../../../api/http-post";
import LoadingProcessing from "../../../../components/Common/page/Common/LoadingProcessing";

const {Option} = Select;

class ManagePurchaseOrderPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Clients: [],
            ItemsService: [],
            ItemsLabour: [],
            Data: [],
            isLoading: false,
            newEntry: {
                Year: moment().year()
            },
            selectedCompany: "",
            selectedClient: "",
            selectedItem: "",
            selectedYear: "",
            selectedMonth: "",
            selectedCategory: "",
            searchKey: "",

            showForm: false,

        }
    }

    componentDidMount = async () => {
        await this.loadInitialData()
    }
    loadInitialData = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await LoadDataCommon("modules", "Modules", this);
        await LoadDataCommon("SettingInvoiceServiceDebtors", "Clients", this);
        await LoadDataCommon("SettingInvoiceServiceItem", "ItemsService", this);
        await LoadDataCommon("SettingInvoiceAddonLabourItems", "ItemsLabour", this);

        await LoadDataCommon("PurchaseOrder", "Data", this);
    }
    closeDrawer = () => {
        this.setState({showForm: false})
    }
    handleGeneralEntryInput = (val, key) => {
        let tmp = this.state;
        tmp.newEntry[key] = val;
        this.setState(tmp)
    }
    handleInput = (val, key) => {
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp)
    }
    getHandleInput = (key) => {
        let tmp = this.state;
        if (typeof tmp.newEntry[key] === "undefined") {
            tmp.newEntry[key] = ""
        }
        return tmp.newEntry[key]
    }
    getFieldList = () => {
        return [
            {
                field: "Year",
                datatype: "option",
                label: "Year",
                options: GetYearList(),
                align: "center",
                mandatory: true
            },
            {
                field: "Month",
                datatype: "option",
                label: "Month",
                options: GetMonthList(),
                align: "center",
                mandatory: true
            },
            {field: "RefNumber", datatype: "string", label: "PO. Number", options: [], align: "left", mandatory: true},
            {
                field: "Description",
                datatype: "string",
                label: "Description",
                options: [],
                align: "left",
                mandatory: true
            },
            {
                field: "DeliveryDate",
                datatype: "date",
                label: "Delivery Date",
                options: [],
                align: "center",
                mandatory: true
            },
            {
                field: "OrderQuantity",
                datatype: "float",
                label: "Invoice Amount Inc. Vat",
                options: [],
                align: "right",
                mandatory: true
            },
            {field: "DownloadLink", datatype: "file", label: "PDF", options: [], align: "left", mandatory: false},
        ]
    }
    getItemData = () => {
        if (this.state.selectedCategory === "service") {
            return this.state.ItemsService;
        } else {
            return this.state.ItemsLabour
        }
    }
    onSubmitRecord = async () => {
        const table = "PurchaseOrder";
        const _this = this;
        let state = this.state;
        let fields = this.getFieldList()
        let record = {
            Org: state.selectedCompany,
            Client: state.selectedClient,
            Item: state.selectedItem,
            Category: state.selectedCategory,
        };
        for (let i in fields) {
            let row = fields[i];
            let val = state.newEntry[row.field];
            if (row.datatype === "float") {
                val = val !== "" ? parseFloat(val) : 0
            } else if (row.datatype === "file" && typeof val !== "undefined") {
                /**
                 * upload to drop box
                 */
                let base64String = val.base64String;
                _this.setState({
                    isLoading: true,
                })
                await UploadToDropBoxBase64WithBusket(
                    base64String,
                    "purchase-order",
                    state.selectedCompany,
                    state.selectedClient,
                    state.selectedItem,
                    state.newEntry["RefNumber"],
                    val.filename,
                    (revision) => {

                    }).then(revision => {
                    console.log("UploadToDropBoxBase64WithBusket revision > ", revision)
                    val = revision
                })
            }
            record[row.field] = val
        }

        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = record;
        post.Params = {}
        let endpoint = "/common/entity/" + table + "/insert";
        this.setState({
            isLoading: true,
        })
        if (!window.confirm("Are sure to submit?")) {
            _this.setState({
                isLoading: false,
            })
            return
        }

        console.log("Submit new record purchase order> ", post)
        await PostToPbx(post, endpoint, async (data) => {
            await LoadDataCommon("PurchaseOrder", "Data", _this);
            _this.setState({isLoading: false, showForm: false, newEntry: {}});
            alert("thank you, pod submitted");
        });

        console.log("onSubmitRecord > ", record);

    }
    onDelete = async (record, deleteLink) => {
        let conditions = [
            {key: "org", datatype: "string"},
            {key: "client", datatype: "string"},
            {key:"project",datatype: "string"},
            {key: "item", datatype: "string"},
            {key: "year", datatype: "string"},
            {key: "month", datatype: "string"},
        ]
        delete record.Action;
        if (!window.confirm("Are you sure to delete?")) {
            return
        }
        let _this = this;
        console.log("You accept to delete!")
        const table = "PurchaseOrder";
        let params = [];
        for (let i in conditions) {
            const row = conditions[i];
            const key = row.key;
            const type = row.datatype;
            const val = record[key];
            params.push({
                key: key,
                type: type,
                val: val
            })
        }

        let hub = {...record};

        let post = {}
        post.Params = params
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/delete"; // "/manager/entity/insert/extensions";
        // let endpoint =this.props.postEndpoint; // "/manager/entity/insert/extensions";


        console.log("PO DELETE ::-) send > ",post)

        this.setState({
            isLoading: true,
        })
        await PostToPbx(post, endpoint, async (data) => {
            console.log("PO DELETE ::-) response > ",data)
            await LoadDataCommon("PurchaseOrder", "Data", _this);
            this.setState({
                isLoading: false,
            })
        });
    }

    checkForNewEntry = () => {
        if (this.state.selectedCompany === "") {
            return false
        }
        if (this.state.selectedClient === "") {
            return false
        }
        if (this.state.selectedItem === "") {
            return false
        }
        return true;
    }

    render() {
        console.log("this.state (--) ", this.state)
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Set Purchase Order</Title>

                    <Divider style={{color: "red"}}>Filter</Divider>
                    <BuilderOptionComboBox
                        data={this.state.Companies}
                        keyVal={"CustomerNumber"}
                        keyLabel={"Name"}
                        label={"Select Organization"}
                        conditions={[]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedCompany"}
                        currentValue={this.state.selectedCompany}
                        comboId={"company"}
                    />
                    <BuilderOptionComboBox
                        data={this.state.Clients}
                        keyVal={"mame"}
                        keyLabel={"name"}
                        label={"Select Debtor"}
                        conditions={[
                            {key: "org", val: this.state.selectedCompany}
                        ]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedClient"}
                        currentValue={this.state.selectedClient}
                        comboId={"client"}
                    />
                    <BuilderOptionComboBox
                        data={[{key: "service", val: "Service Invoice"},
                            {key: "labour", val: "Labour Invoice"},]}
                        keyVal={"key"}
                        keyLabel={"val"}
                        label={"Select category of invoice"}
                        conditions={[]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedCategory"}
                        currentValue={this.state.selectedCategory}
                        comboId={"Category"}
                    />
                    <BuilderOptionComboBox
                        data={this.getItemData()}
                        keyVal={"name"}
                        keyLabel={"name"}
                        label={"Select Item | Job description"}
                        conditions={[
                            {key: "org", val: this.state.selectedCompany},
                            {key: "client", val: this.state.selectedClient},
                        ]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedItem"}
                        currentValue={this.state.selectedItem}
                        comboId={"item"}
                    />
                    <Divider orientation="right" style={{color: "#3880ff"}}>
                        {this.checkForNewEntry() ?
                            <a onClick={() => this.handleInput(true, "showForm")}>New Purchase order</a>
                            : null
                        }
                    </Divider>

                    <BuilderTableList
                        data={this.state.Data}
                        fields={this.getFieldList()}
                        deleteAction={this.onDelete}
                        conditions={[
                            {key: "org", val: this.state.selectedCompany},
                            {key: "client", val: this.state.selectedClient},
                            {key: "item", val: this.state.selectedItem},
                        ]}
                        deleteConditions={[
                            {key: "org", datatype: "string"},
                            {key: "client", datatype: "string"},
                            {key: "item", datatype: "string"},
                            {key: "year", datatype: "string"},
                            {key: "month", datatype: "string"},
                        ]}
                        deleteLink={"year"}
                        searchKey={this.state.searchKey}
                    />
                </div>

                <DrawerNewEntry
                    _this={this}
                    attributes={this.getFieldList()}
                    visible={this.state.showForm}
                    onSubmitRecord={this.onSubmitRecord}
                    title={"New Purchase order"}
                    getHandleInput={this.getHandleInput}
                    handleInput={this.handleGeneralEntryInput}
                    modeForm={"new"}
                    closeDrawer={this.closeDrawer}
                />

                <Row>
                    <Col span={24}>
                        <LoadingProcessing open={this.state.isLoading}/>
                    </Col>
                </Row>
            </Page>
        )
    }


}


export default Connector(ManagePurchaseOrderPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
