import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {Alert, Button, Col, Form, Layout, Row, Select} from "antd";
import {fetchDataEntity} from "../../../api/entity-data";
import {SortData,} from "../../../api/fn";
import {PostToPbx} from "../../../api/http-post";
import moment from "moment";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import DisplayDataReportSummaryHours from "../../../components/Reports/DisplayDataReportSummaryHours";
import Configures from "../../../configures";
import {GetConfigures} from "../../../configures/serve";
import {GetUserToken} from "../../../api/token";
import {loadData} from "../invoices/SetupLabourInvoice/lib";

const {Content} = Layout;
const {Option} = Select;

function convertDateFormat_DDMMYYYY(dateIn) {
    let mydate = moment(dateIn, "YYYY-MM-DD").format("DD MMM YYYY");
    return mydate;
}

function formatCurrency(stringIn) {
    return stringIn.toFixed(2)
}


class ReportPaymentCycleHoursPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Org: "",
            Companies: [],
            Entities: [],
            FnbCsvInfo:[],

            selectedCompany: "",
            selectedModule: "",
            selectedRecord: {},
            selectedPeriod: "",
            selectedSite: "",

            Data: [],
            isLoading: false,
            component: "list",
            initLoading: false,

            DataPeriod: [],
            DataPayCycle:[],
            DataSite:[],
            DataSavedPayroll:[],

            Results: null,
            showViewDetail: false,
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.initialReload()
    }
    initialReload = async () => {
        await fetchDataEntity("Entities", "", "", this, undefined, "common/entity/modules/list");
        await this.loadData("PayrollBankingCsvFnb","FnbCsvInfo")
        await this.loadSavedPayroll()
    }

    loadSavedPayroll=async ()=>{
        await this.loadData("PayrollSavedSummaryAll","DataSavedPayroll")
    }
    loadContractInfo = async (selectedModule) => {
        let _this = this;
        let module = this.state.selectedModule;
        let hub = {
            Org: this.state.selectedCompany,
            Module: "contract",
            Ref: selectedModule,
        }

        console.log("loadContractInfo > ",hub)
        let endpoint = "/payroll/contract/fetch-data"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            let tmp = _this.state;
            tmp.DataPeriod = data.TermsPeriod;
            tmp.DataDesignation = data.TermsDesignation;
            tmp.DataPayCycle = data.TermsPayCycle;
            tmp.DataSite = data.TermsSite;
            tmp.DataRate = data.TermsRate;
            tmp.DataRateSpecific = data.TermsRateSpecific;
            tmp.DataSlot = data.TermsSlot;
            tmp.DataSiteDesignation = data.TermsSiteDesignation;
            tmp.DataShift = data.TermsShift;
            tmp.DataCondition = data.TermsCondition;
            _this.setState(tmp);

            console.log("loadInitialData > ", output, " > ", data)
        });
    }
    loadData = async (targetEntity, stateKey) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            await _this.setState({
                [stateKey]: data.RESULT
            })
            this.setState({
                isLoading: false,
            })
        });
    }
    handleChangeCompany = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    handleChange = async (e, key) => {
        const value = e.value;
        this.setState({
            [key]: value,
        });

        if (key === "selectedModule") {
            console.log("selected module > ", key, value)
            await this.loadContractInfo(value)
        }

    }
    handleChange2 = (e, ee, key) => {
        const val = e;
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
    }


    validateSubmitSearch=()=>{
        const state = this.state;
        if(state.selectedCompany===""){
            alert("Validation fail! Please select your company")
            return false
        }
        if(state.selectedModule===""){
            alert("Validation fail! Please select your project")
            return false
        }
        if(state.selectedPeriod===""){
            alert("Validation fail! Please select your pay circle")
            return false
        }

        return true
    }
    submitGenerateLabourReportCalendar=async ()=>{
        this.setState({
            isLoading: true,
        });

        if(!window.confirm("Do you want to generate payment summary report PDF file?")){
            return
        }

        let arrPeriod = this.state.selectedPeriod.split("#");
        let payDate = arrPeriod[3];
        let payDateString = moment(payDate,"YYYY-MM-DD").format("DD MMM YYYY")

        let hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedModule,
            CompanyName:"MSHENGU TOILET HIRE",
            PayDate: payDateString,
            TitleReport: "EMPLOYMEE PAYMENT SUMMARY REPORT - EPWP 100L Container Employees",
            InnerData: this.state.Results.Summary
        }

        console.log("submitGenerateLabourReportCalendar -Data--> ", hub)
        let endpoint = "/wage/report/labour-calendar"
        await PostToPbx(hub, endpoint, async (output) => {
            this.setState({
                isLoading: false,
            });
            if(output===null){
                console.log("Error response null > ",output);
                return
            }
            let data = output.RESULT;
            let url = data.LinkDownload;
            window.document.title = hub.TitleReport;
            window.open(url, '_blank');
            console.log("-submitGeneratePaymentSummary out  Data--> ", data)
        });

    }
    submitGenerateTimesheetCalendar=async()=>{
        this.setState({
            isLoading: true,
        });

        if(!window.confirm("Do you want to generate timesheet report PDF file?")){
            return
        }

        let arrPeriod = this.state.selectedPeriod.split("#");
        let payDate = arrPeriod[3];
        let payDateString = moment(payDate,"YYYY-MM-DD").format("DD MMM YYYY")

        let hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedModule,
            CompanyName:"MSHENGU TOILET HIRE",
            PayDate: payDateString,
            TitleReport: "EMPLOYMEE PAYMENT SUMMARY REPORT - EPWP 100L Container Employees",
            InnerData: this.state.Results.Summary
        }

        console.log("submitGenerateTimesheetCalendar -Data--> ", hub)
        let endpoint = "/wage/report/timesheet-calendar"
        await PostToPbx(hub, endpoint, async (output) => {
            this.setState({
                isLoading: false,
            });
            if(output===null){
                console.log("Error response null > ",output);
                return
            }
            let data = output.RESULT;
            let url = data.LinkDownload;
            window.document.title = hub.TitleReport;
            window.open(url, '_blank');
            console.log("-submitGenerateTimesheetCalendar out  Data--> ", data)
        });
    }
    submitGenerateLabourReport2Calendar=async ()=>{
        this.setState({
            isLoading: true,
        });

        if(!window.confirm("Do you want to generate payment summary report PDF file?")){
            return
        }

        let arrPeriod = this.state.selectedPeriod.split("#");
        let payDate = arrPeriod[3];
        let payDateString = moment(payDate,"YYYY-MM-DD").format("DD MMM YYYY")

        let hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedModule,
            CompanyName:"MSHENGU TOILET HIRE",
            PayDate: payDateString,
            TitleReport: "EMPLOYMEE PAYMENT SUMMARY REPORT - EPWP 100L Container Employees",
            InnerData: this.state.Results.Summary,
            Method:"calendar"
        }
        let endpoint = "/wage/report/labour2"
        await PostToPbx(hub, endpoint, async (output) => {
            console.log("QQQQQQResponse labour2> ",output)
            this.setState({
                isLoading: false,
            });
            if(output===null){
                console.log("Error response null > ",output);
                return
            }
            let data = output.RESULT;
            let url = data.LinkDownload;
            window.document.title = hub.TitleReport;
            window.open(url, '_blank');
        });
    }
    submitGenerateLabourReport2Actual=async ()=>{
        this.setState({
            isLoading: true,
        });

        if(!window.confirm("Do you want to generate payment summary report PDF file?")){
            return
        }

        let arrPeriod = this.state.selectedPeriod.split("#");
        let payDate = arrPeriod[3];
        let payDateString = moment(payDate,"YYYY-MM-DD").format("DD MMM YYYY")

        let hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedModule,
            CompanyName:"MSHENGU TOILET HIRE",
            PayDate: payDateString,
            TitleReport: "EMPLOYMEE PAYMENT SUMMARY REPORT - EPWP 100L Container Employees",
            InnerData: this.state.Results.Summary,
            Method:"actual"
        }
        let endpoint = "/wage/report/labour2"
        await PostToPbx(hub, endpoint, async (output) => {
            this.setState({
                isLoading: false,
            });
            if(output===null){
                console.log("Error response null > ",output);
                return
            }
            let data = output.RESULT;
            let url = data.LinkDownload;
            window.document.title = hub.TitleReport;
            window.open(url, '_blank');
        });
    }
    submitGenerateLabourReport=async ()=>{
        this.setState({
            isLoading: true,
        });

        if(!window.confirm("Do you want to generate payment summary report PDF file?")){
            return
        }

        let arrPeriod = this.state.selectedPeriod.split("#");
        let payDate = arrPeriod[3];
        let payDateString = moment(payDate,"YYYY-MM-DD").format("DD MMM YYYY")

        let hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedModule,
            CompanyName:"MSHENGU TOILET HIRE",
            PayDate: payDateString,
            TitleReport: "EMPLOYMEE PAYMENT SUMMARY REPORT - EPWP 100L Container Employees",
            InnerData: this.state.Results.Summary
        }
        let endpoint = "/wage/report/labour"
        await PostToPbx(hub, endpoint, async (output) => {
            this.setState({
                isLoading: false,
            });
            if(output===null){
                console.log("Error response null > ",output);
                return
            }
            let data = output.RESULT;
            let url = data.LinkDownload;
            window.document.title = hub.TitleReport;
            window.open(url, '_blank');
        });
    }
    submitGeneratePaymentSummaryCsv=()=>{
        let ls=[];
        ls.push([
            "Code",
            "Name",
            "Id Number",
            "Site",
            "Designation",
            "Gross",
            "Deduction",
            "Net"
        ])
        for(let i in this.state.Results.Summary){
            let row = this.state.Results.Summary[i];
            let ded =  parseFloat(row.Deduction) + parseFloat(row.Paye) + parseFloat(row.UIF)

            ls.push([
                i,
                row.EmpName,
                row.EmpIdNumber,
                row.EmpSite,
                row.EmpDesignation,
                row.GrossPay.toFixed(2),
                ded.toFixed(2),
                row.NetPay.toFixed(2)
            ])
        }
        /*console.log("submitGeneratePaymentSummaryCsv 1 > ",this.state.Results.Summary)
        console.log("submitGeneratePaymentSummaryCsv > ",ls)*/
        let csvContent = "data:text/csv;charset=utf-8,";
        ls.forEach(function(rowArray){
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "pmis_payment_summary.csv");
        link.innerHTML= "Click Here to download";
        document.body.appendChild(link); // Required for FF
        link.click(); // This will download the data file named "my_data.csv".
    }
    submitGeneratePaymentSummary=async ()=>{
        this.setState({
            isLoading: true,
        });

        if(!window.confirm("Do you want to generate payment summary report PDF file?")){
            return
        }

        let arrPeriod = this.state.selectedPeriod.split("#");
        let payDate = arrPeriod[3];
        let payDateString = moment(payDate,"YYYY-MM-DD").format("DD MMM YYYY")

        let hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedModule,
            CompanyName:"MSHENGU TOILET HIRE",
            PayDate: payDateString,
            TitleReport: "EMPLOYMEE PAYMENT SUMMARY REPORT - EPWP 100L Container Employees",
            InnerData: this.state.Results.Summary
        }

        console.log("submitGeneratePaymentSummary -Data--> ", hub)
        let endpoint = "/wage/report/payment-summary"
        await PostToPbx(hub, endpoint, async (output) => {
            this.setState({
                isLoading: false,
            });
            if(output===null){
                console.log("Error response null > ",output);
                return
            }
            let data = output.RESULT;
            let url = data.LinkDownload;
            window.document.title = hub.TitleReport;
            window.open(url, '_blank');
            console.log("-submitGeneratePaymentSummary out  Data--> ", data)
        });

    }
    savePayrollEncova = async ()=>{
        if(!this.validateSubmitSearch()){
            return
        }

        if(!window.confirm("Are you sure to save this pay cycle? this action can't be reverse after saving!")){
            return
        }

        let hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedModule,
        }
        this.setState({
            isLoading: true,
        });
        console.log("savePayrollEncova send >  ", hub)
        let endpoint = "/wage/report/save-payroll"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            console.log("savePayrollEncova out >  ", data)
            this.setState({
                isLoading: false,
            });
            alert("Thank you, payroll saved!")
            await this.loadSavedPayroll()
        });
    }
    submitSearch = async () => {
        this.setState({
            isLoading: true,
            Results: null,
        });

        if(!this.validateSubmitSearch()){
            return
        }

        let hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedModule,
        }

        console.log("1 -Data--> ", hub)
        let endpoint = "/wage/calculation/hourly"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            this.setState({
                Results: data,
                isLoading: false,
            });
            console.log("-search Data--> ", data)
        });

    }
    getPayDate=()=>{
        let arr =  this.state.selectedPeriod.split("#");
        return arr[3]
    }
    hasSavedPayroll=()=>{
        /*
        Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedModule,
         */
        let arr =  this.state.selectedPeriod.split("#");
        let startDate = arr[0]
        let endDate = arr[1]
        let org =this.state.selectedCompany;
        let project =this.state.selectedModule;
        let boo=false;
        let info = {};
        for(let i in this.state.DataSavedPayroll){
            let row = this.state.DataSavedPayroll[i]
            if(row.org!==org){continue}
            if(row.project!==project){continue}
            if(row.startdate!==startDate){continue}
            if(row.enddate!==endDate){continue}
            boo=true
            info = row
        }

        console.log("SAVED PAYROLL INFO: ",            info)
        return{
            has:boo,
            info:info
        }
    }
    getFnbCsvInfo=()=>{
        let response=null;
        for(let i in this.state.FnbCsvInfo){
            let row = this.state.FnbCsvInfo[i];
            if(row.org!==this.state.selectedCompany){
                continue
            }
            if(row.project!==this.state.selectedModule){
                continue
            }
            response = row
        }
        return response
    }

    renderWarningBar = () => {
        return
        let boo = false
        if (this.state.selectedCompany === "" || this.state.selectedEntity === "" || this.state.component === "form") {
            boo = true
        }
        if (!boo) {
            return
        }
        return (
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Alert style={{margin: '16px 0'}} message="Please select your company above to view your records!"
                       type={"error"}/>
            </div>
        )
    }
    renderCompaniesSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={""}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedCompany")}
                    value={this.state.selectedCompany}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderModules = () => {
        let ls = [];
        let org = this.state.selectedCompany;
        for (let i in this.state.Entities) {
            const row = this.state.Entities[i];

            if (row.org === org) {
                ls.push({
                    key: row.name,
                    val: row.name,
                })
            }
        }
        return (
            <Select
                name={"selectedModule"}
                defaultValue=""
                onChange={(e, ee) => this.handleChange(ee, "selectedModule")}
            >
                {ls.map((record, index) => {
                    return (
                        <Option value={record.key}>{record.val}</Option>
                    )
                })}
            </Select>
        )
    }
    formatDate = (dateIn) => {
        return convertDateFormat_DDMMYYYY(dateIn)
    }
    renderPayCycle = () => {
        let data = this.state.DataPayCycle;
        data = SortData("Period", data)
        let ls = [];
        for (let i in data) {
            let row = data[i];
            const key = row.StartDate + "#" + row.EndDate + "#" + row.Period+"#"+row.PayDate;
            const display = row.Period + ". -> Start: " + this.formatDate(row.StartDate) + " -> End: " + this.formatDate(row.EndDate) +
                " -> Pay date: " + this.formatDate(row.PayDate);
            ls.push({
                key: key,
                val: display
            })
        }
        return (
            <React.Fragment>
                <Form.Item label={"Pay Cycle"} required={true}>
                    <Select
                        name={"selectedPeriod"}
                        defaultValue={this.state.selectedPeriod}
                        value={this.state.selectedPeriod}
                        onChange={(e, ee) => this.handleChange2(e, ee, "selectedPeriod")}
                    >
                        {ls.map((record, index) => {
                            return (
                                <Option value={record.key}>{record.val}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            </React.Fragment>
        )
    }


    render() {
        let props = this.props;
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Pay Cycle Hours Report</Title>
                    <Row gutter={16} style={{marginTop: 20}}>
                        <Col span={12}>
                            <Form.Item
                                label={"Company"}
                            >
                                {this.renderCompaniesSelection()}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={"Project"}
                            >
                                {this.renderModules()}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{marginTop: -40}}>
                        <Col span={24}>
                            {this.renderPayCycle()}
                        </Col>
                    </Row>
                    <Row gutter={16} style={{marginTop: -10}}>
                        <Col span={24}>
                            <Button style={{minWidth:"100%"}} danger onClick={() => this.submitSearch()}>Search
                            </Button>&nbsp;&nbsp;
                        </Col>
                    </Row>
                    {this.state.Results !== null ?
                        <Row gutter={24} style={{marginTop: 40}}>
                            <DisplayDataReportSummaryHours
                                dataStore={this.state.Results}
                                payDate={this.getPayDate()}
                                _this={this}
                                payPeriod={this.state.selectedPeriod}
                                org={this.state.selectedCompany}
                                project={this.state.selectedModule}
                                submitSearch={this.submitSearch}
                                submitGeneratePaymentSummary={this.submitGeneratePaymentSummary}
                                submitGeneratePaymentSummaryCsv={this.submitGeneratePaymentSummaryCsv}
                                submitGenerateLabourReport={this.submitGenerateLabourReport}
                                submitGenerateLabourReport2Actual={this.submitGenerateLabourReport2Actual}
                                submitGenerateLabourReport2Calendar={this.submitGenerateLabourReport2Calendar}
                                submitGenerateLabourReportCalendar={this.submitGenerateLabourReportCalendar}
                                submitGenerateTimesheetCalendar={this.submitGenerateTimesheetCalendar}
                                FnbCsvInfo={this.getFnbCsvInfo()}
                            />
                            {!this.hasSavedPayroll().has ?
                                <Row gutter={24} style={{marginTop: 40}}>
                                    <Col span={20} style={styles.payCycleWarningLabel}>
                                        This pay cycle is not saved yet, click on save button
                                    </Col>
                                    <Col span={4} style={styles.payCycleButton}>
                                        <Button style={{minWidth: "100%"}} type="dashed" danger
                                                onClick={() => this.savePayrollEncova()}>
                                            Save payroll
                                        </Button>&nbsp;&nbsp;
                                    </Col>
                                </Row> :
                                <Row gutter={24} style={{marginTop: 40}}>
                                    <Col span={24} style={styles.payCycleSavedLabel}>
                                        Thank you your pay cycle was saved on: {this.hasSavedPayroll().info.orgdatetime}
                                    </Col>
                                </Row>
                            }
                        </Row> : null
                    }
                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }
}


export default Connector(ReportPaymentCycleHoursPage);

const styles={
    payCycleButton:{
        color:"red",
        fontSize:24,
        textAlign:"right",
        display:"flex",
        justifyContent:"flex-start",
        flexDirection:"row",
        alignItems:"center"
    },
    payCycleWarningLabel:{
        color:"red",
        fontSize:24,
        textAlign:"right",
        display:"flex",
        justifyContent:"flex-end",
        flexDirection:"row",
        alignItems:"center"
    },
    payCycleSavedLabel:{
        color:"green",
        fontSize:24,
        textAlign:"right",
        display:"flex",
        justifyContent:"flex-start",
        flexDirection:"row",
        alignItems:"center"
    }
}
