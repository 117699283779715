
import React, {useEffect, useState} from "react";
import "./dashbaord.css";
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {FormatMoneySouthAfrica, nFormatter} from "../../../api/fn";

const DashInvoiceAmountByCategory=({category,cost,percentage,backgroundColor,includeTitle=false})=>{
    let pClass = "diacatContainer";
    if(!includeTitle){
        pClass = "diacatContainer2";
    }
    return(
        <div className={pClass}>
            {includeTitle?
            <div className={"diacatContainerTitle"}>
                Amount by Category
            </div>:null}
            <div className={"diacatLabelBox"}>
                <div className={"diacatLabelCat"}>{category}</div>
                <div className={"diacatLabelAmount"}>R {FormatMoneySouthAfrica(cost)}</div>
            </div>
            <div className={"diacatPie"}>
                <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={buildStyles({
                        textSize: '12px',
                        strokeLinecap: 'butt',
                        textColor: 'black',
                        trailColor: 'lightgray',
                        pathColor: backgroundColor,
                    })}
                />
            </div>
        </div>
    )
}

export default DashInvoiceAmountByCategory;