import React from "react";
import {fetchDataEntity} from "../../../api/entity-data";
import {
    BuilderOptionComboBox, FormatMoneySouthAfrica,
    LoadDataCommon,
    UpcaseFirst
} from "../../../api/fn";
import moment from "moment";
import Title from "../../../components/Common/Title";
import {Col, Divider, Row} from "antd";
import Page from "../../../layout/web/Page";
import Connector from "../../../redux/connector";

import TableDataView from "../../../components/Common/tables/TableDataView";
import {PostToPbx} from "../../../api/http-post";
import QuoteTrackerAddon from "./QuoteTrackerAddon";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";


class QuoteReportTrackerPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Clients: [],
            Projects: [],
            Data: [],
            isLoading: false,
            newEntry: {
                Year: moment().year()
            },
            selectedCompany: "",
            selectedClient: "",
            selectedProject: "",
            selectedStartDate: "",
            selectedEndDate: "",
            selectedQuoteDate: "",
            Result: null,
            openNewTracker:false,
        }
    }

    componentDidMount = async () => {
        await this.loadInitialData();
    }
    loadInitialData = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await LoadDataCommon("modules", "Projects", this);
        await LoadDataCommon("SettingInvoiceServiceDebtors", "Clients", this);
        await LoadDataCommon("QuoteTracker", "Data", this);
    }

    handleInput = (val, key) => {
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp)
    }

    onIsLoading=(val)=>{
       /* this.setState({
            isLoading:val
        })*/
    }

    onDeleteView=async (record)=>{
        let _this = this;
        if(!window.confirm("are you sure to delete: "+record.customername+" | "+record.quotenumber)){
            return
        }
        let rec = null;
        for(let i in this.state.Data){
            let row =this.state.Data[i];
            if(row.quotenumber===record.quotenumber){
                rec = row
            }
        }

        rec.amounttotal = "";
        rec.amountvat = "";
        rec.amountexvat = "";
        delete(rec.view)

        console.log("onDelete record: ",rec)

        let endpoint=`/crud/QuoteTracker/delete`
        let postData= {
            ConditionFieldList:["quotenumber","ref"],
            Data:rec,
            Org:rec.org,
        }

        console.log("onDeleteView<<>>>>>> send : ",postData)
        await PostToPbx(postData, endpoint, async (data) => {
            console.log("onDeleteView response > ",data);
            alert("Thank you , your quote have been deleted")
            window.location.reload();
            //await LoadDataCommon("QuoteTracker", "Data", this);

        });
    }

    onSubmitNewTracker=async (record)=>{
        console.log("Comming soon new tracker manual> ",record)
        let endpoint=`/crud/QuoteTracker/insert`;
        let postData= {
            Org:record.Org,
            Data: record
        }
        this.onIsLoading(false)
        await PostToPbx(postData, endpoint, async (data) => {
            console.log("onSubmitNewTracker response > ",data);
            alert("Thank you , your quote have been saved")
            await LoadDataCommon("QuoteTracker", "Data", this);

        });
    }
    onNewTracker=()=>{
        this.setState({
            openNewTracker:true
        })
    }
    onCloseNewTracker=()=>{
        this.setState({
            openNewTracker:false
        })
    }

    getListSelected=()=>{
        const toFix2=(inValue)=>{
            try{
                let val = inValue.toFixed(2)
                return parseFloat(val)
            }catch (e) {
                return 0.00
            }

        }
        let ls=[];
        for(let i in this.state.Data){
            let row = this.state.Data[i];
            if(row.org ===this.state.selectedCompany){
                row.view = <a href={row.linkdownload} target="_blank" rel={row.org +" "+row.quotenumber}>View</a>
                row.amounttotal = <span style={styles.amount}>R{FormatMoneySouthAfrica(toFix2(row.amounttotal))}</span>
                row.amountvat = <span >R{FormatMoneySouthAfrica(toFix2(row.amountvat))}</span>
                row.amountexvat = <span >R{FormatMoneySouthAfrica(toFix2(row.amountexvat))}</span>
                ls.push(row)
                console.log("22GJGJGJJGJ>> ",ls," > " ,row)
            }
        }

        return ls
    }
    renderList = () => {


        let ls = this.getListSelected()
        let columns = [
            {id: 'quotenumber', label: 'Quote Number', minWidth: 170, dataType: "string"},
            {id: 'customername', label: 'Customer', minWidth: 170, dataType: "string"},
            {id: 'description', label: 'Description', minWidth: 170, dataType: "string"},
            {id: 'quotedate', label: 'Quote Date', minWidth: 170, dataType: "string"},
            {id: 'category', label: 'Category', minWidth: 170, dataType: "string"},
            {id: 'typeofquote', label: 'Type', minWidth: 170, dataType: "string"},
            {id: 'amountexvat', label: 'Amount Ex. Vat', minWidth: 170, dataType: "string"},
            {id: 'amountvat', label: 'Vat Amount', minWidth: 170, dataType: "string"},
            {id: 'amounttotal', label: 'Total Amount', minWidth: 170, dataType: "string"},
            {id: 'periodstart', label: 'Period start', minWidth: 170, dataType: "string"},
            {id: 'view', label: '', minWidth: 170, dataType: "string"},
        ];

        let data =  {columns: columns, data: ls};
        let otherFunctions=[
            /*https://materializecss.com/icons.html*/
            /*{
                icon:"phonelink_lock",
                isFreeAction:false,
                tooltip:"Suspend Client",
                myAction:this.activateClient,
                color:"primary"
            }*/
        ]

        return (
            <TableDataView
                {...data}
                tableTitle={" Records  (" + data.data.length + ") "}
                onAddRecord={this.onNewTracker}
                onDelete={this.onDeleteView}
                otherFunctions={otherFunctions}
                options={{
                    pageSize:20,
                }}
            />
        )

    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Report Quote Tracker</Title>

                    <Divider style={{color: "red"}}>Filter</Divider>
                    <BuilderOptionComboBox
                        key={"cbCompany"}
                        data={this.state.Companies}
                        keyVal={"CustomerNumber"}
                        keyLabel={"Name"}
                        label={"Select Organization"}
                        conditions={[]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedCompany"}
                        currentValue={this.state.selectedCompany}
                        comboId={"company"}
                    />

                    <Divider orientation="center" style={{color: "#3880ff"}}>&nbsp;</Divider>

                    {this.renderList()}

                    <QuoteTrackerAddon
                        visible={this.state.openNewTracker}
                        DataOrganisations={this.state.Companies}
                        DataClients={this.state.Clients}
                        DataProjects={this.state.Projects}
                        onClose={this.onCloseNewTracker}
                        onSubmit={this.onSubmitNewTracker}
                        onIsLoading={this.onIsLoading}
                    />

                    {/*<Row>
                        <Col span={24}>
                            <LoadingProcessing open={this.state.isLoading}/>
                        </Col>
                    </Row>*/}
                </div>
            </Page>
        )
    }

}

export default Connector(QuoteReportTrackerPage);

const styles = {
    amount:{
        color:"#1890ff"
    },
    dateRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent:"space-between",
        marginBottom:20,
        marginTop:20
    },
    datePickerBox: {
        height: 40,
        width: "100%"
    },
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

