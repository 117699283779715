import React from "react";
import {Col, Form, Input, Row, Select, Table} from 'antd';
import {stepContractAttributesTermsPayCycle} from "./Constants";
import {Button, Tooltip} from 'antd';
import {PlusCircleFilled} from '@ant-design/icons';
import {SubmitPost} from "../Common/localFunctions";
import {PostToPbx} from "../../../api/http-post";

const {Option} = Select;

export default class ContractSetupStepDisplayBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            component: "list",
            newEntry:{}
        }
    }
    componentDidMount=async ()=> {
    }

    handleNewEntry=(e,key)=>{
        const val=e.target.value;
        let tmp=this.state;
        tmp.newEntry[key]=val;
        this.setState(tmp);
        console.log("handleNewEntry > ",e," > ",key,val)

    }
    handleChange2=(e,ee,key)=>{
        console.log("handleNewEntry2 > ",e," > ",key,ee)
        const val=e;
        let tmp=this.state;
        tmp.newEntry[key]=val;
        this.setState(tmp);

    }

    clickAdd = () => {
        let {fields} = this.props;
        let newEntry={};
        for(let i in fields){
            const row = fields[i];
            let key=row.name;
            newEntry[key]="";
        }
        this.setState({
            component: "form",
            newEntry:newEntry
        });
    }
    cancelSave=async ()=>{
        this.setState({
            component: "list",
            newEntry:{},
        });
    }
    saveData =async () => {
        let self=this;
        let{_this,stateArray,table,fields}=this.props;
        let tmp=_this.state;
        tmp[stateArray].push(this.state.newEntry);
        _this.setState(tmp);
        this.setState({
            component: "list",
            newEntry:{},
        });
        let contract = this.props.selectContract;
        const data = this.makeDataTypeEntry(fields,this.state.newEntry);
        let hub={...data};
        hub.Org =contract.org;
        hub.Module = contract.module;
        hub.Ref=contract.ref;


        console.log("renderForm > ",this.props.selectContract);

        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = hub;
        post.Params = {}
        let endpoint = "/common/entity/"+table+"/insert"; // "/manager/entity/insert/extensions";

        // let endpoint =this.props.postEndpoint; // "/manager/entity/insert/extensions";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            console.log("loadInitialData > ",hub," > ",data);
           await _this.loadInitialData()
        });


    }

    makeDataTypeEntry=(fields,newEntry)=>{
        console.log("F4")
        const findDataInEntry=(fieldName)=>{
            for(let i in fields){
                const row=fields[i];
                if(row.name===fieldName){
                    return row.dataType;
                }
            }
            return "string"
        }
        let ls={};
        console.log("F5 > ",newEntry)
        for(let i in newEntry){
            const fDataType = findDataInEntry(i);
            let val=newEntry[i];
            if(fDataType==="float" || fDataType==="number"){
                val=parseFloat(val);
            }else if(fDataType==="boolean"){
                val=val==="yes"
            }
            ls[i]=val;
        }
        return ls;
    }

    renderList = () => {
        let {fields, data,addFormText,stepTitle} = this.props;
        let columns = [];
        for (let i in fields) {
            const index = parseInt(i);
            const row = fields[i];
            if (index === 0) {
                columns.push({
                    title: row.label,
                    dataIndex: row.name,
                    render: text => <a>{text}</a>,
                })
            } else {
                columns.push({
                    title: row.label,
                    dataIndex: row.name,
                    align: 'right',
                })
            }
        }

        /*data.push({
            "StartDate": "2020-08-01",
            "EndDate": "2021-08-01",
            "TotalMonth": "12",
            "TotalDay": "365",
            "TotalWeek": "48",
            "TotalYear": "1"
        });*/

        let ls=[];
        for(let i in data){
            let row = data[i];
            let index=parseInt(i);
            row.key=index;
            ls.push(row);
        }


        return (
            <div style={{minWidth: "100%", padding: 5,}}>
                <h3 style={{
                    float:"left",
                    /*color:"red",*/
                    marginTop:-70,
                    fontSize:18,
                }}>{stepTitle}</h3>
                <div style={{
                    selfAlign: "right",
                    minWidth: "100%",
                    maxWidth: "100%",
                    minHeight:38,
                    maxHeight:38,
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems:"center",
                    padding: 0,
                    /*backgroundColor: "green",*/

                }}>
                    <Button
                        type="link"
                        icon={<PlusCircleFilled/>}
                        onClick={() => this.clickAdd()}
                        style={{float:"right",borderRadius:15}}

                    >
                        ADD NEW {addFormText}
                    </Button>
                </div>
                <Table
                    columns={columns}
                    dataSource={ls}
                    bordered
                   /* title={() => 'Period'}*/
                    /*footer={() => 'Footer'}*/
                />
            </div>

        )

    }



    renderForm = () => {
        let {fields,stepTitle} = this.props;

        return (
            <EntryForm
                fields={fields}
                numSpan={11}
                saveData={this.saveData}
                cancelSave={this.cancelSave}
                handleChange={this.handleNewEntry}
                handleChange2={this.handleChange2}
                state={this.state}
                stepTitle={stepTitle}
            />
        )

    }

    render() {
        return (
            <div style={{minWidth: "100%", maxWidth: "100%"}}>

                {this.state.component === "list" ? this.renderList() : this.renderForm()}
            </div>
        )
    }
}

export const EntryForm = (props) => {

    let {fields, numSpan, saveData,handleChange,cancelSave,state,handleChange2,stepTitle} = props;
    let numberCol = 24 / numSpan;
    numberCol = parseInt(numberCol);
    let arrCol = [];
    for (let i = 0; i < numberCol; i++) {
        let n = i + 1
        arrCol.push(n);
    }
    return (

        <Form
            layout="vertical"
        >
            <h3 style={{
                float:"left",
                /*color:"red",*/
                marginTop:-70,
                fontSize:18,
                marginLeft:10,
            }}>{stepTitle}</h3>
            <Row>
                {fields.map((row) => {
                    if (row.dataType === "option" || row.dataType === "boolean") {
                        return (
                            <Col style={styles.gutterBox} span={numSpan}>
                                <Form.Item label={row.label} required={row.required}>
                                <Select
                                    name={row.name}
                                    defaultValue={state.newEntry[row.name]}
                                    value={state.newEntry[row.name]}
                                    onChange={(e,ee) => handleChange2(e,ee, row.name)}
                                >
                                    {row.options.map((record, index) => {
                                        return (
                                            <Option value={record.key}>{record.val}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            </Col>
                        )
                    }
                    return (
                        <Col style={styles.gutterBox} span={numSpan}>
                            <Form.Item label={row.label} required={row.required}>
                                <Input
                                    name={row.name}
                                    placeholder={"input " + row.label}
                                    onChange={(e)=>handleChange(e,row.name)}
                                />
                            </Form.Item>
                        </Col>
                    )
                })}
            </Row>
            <Row>
                {arrCol.map(index => {
                    if (index !== numberCol) {
                        return (
                            <Col style={styles.gutterBox} span={numSpan}>&nbsp;</Col>
                        )
                    }
                    return (
                        <Col
                            style={{justifyContent: "flex-end", display: "flex", padding: 10, paddingRight: -10}}
                             span={numSpan}
                        >
                            <Button onClick={() => cancelSave()} >Cancel</Button>&nbsp;&nbsp;
                            <Button onClick={() => saveData()} danger>Save</Button>
                        </Col>
                    )
                })}
            </Row>
        </Form>

    )

}

const styles = {
    gutterBox: {
        padding: "8px 0",
        margin: 10,
        /*backgroundColor: "#00a0e9",
        minWidth: "50%",
        maxWidth: "50%"*/
    },
    stepsContent: {
        marginTop: 16,
        border: "1px dashed #e9e9e9",
        borderRadius: 2,
        backgroundColor: "#fafafa",
        minHeight: 200,
        textAlign: "center",
        paddingTop: 80,
    },
    stepsAction: {
        marginTop: 24,
    }
}
