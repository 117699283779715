import WoodChippingLoadBarInfo from "./WoodChippingLoadBarInfo.jsx";
import React, {useRef, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faCaretUp,faCheck,faClose} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import {Colors} from "../../constants/colors";
import {Row, Col} from "antd"
import WoodChippingCardHeader from "./WoodChippingCardHeader.jsx";
import WoodChippingCardContent2 from "./WoodChippingCardContent2";
import ProfitTrend from "./ProfitTrend";
import './index.css'
import moment from "moment";
import {sortArrayBasedOnDate, SplitArrayIntoMultiple} from "../../services/service-common";
import result from "../../pages/web/Woodchipping/report/dumy";


// Add the icon to the library
library.add(faCheck,faClose);

export default ({results,endDate,clientAlias}) => {
    const [width, setWidth] = useState(0);
    const parentRef = useRef(null);
    const cRec = results.AnalysisCoCT;
    const cData = results.AnalysisCompost.sort((a, b) => b.CurrentMonthLoad - a.CurrentMonthLoad);
    const clientData = SplitArrayIntoMultiple(cData,2);
    useEffect(() => {
        if (parentRef.current) {
            setWidth(parentRef.current.offsetWidth);
        }
        const handleResize = () => {
            if (parentRef.current) {
                setWidth(parentRef.current.offsetWidth);
            }
        };
        window.addEventListener('resize', handleResize);
        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const getIndicatorIcon=(position)=>{
        return position==="up"?<FontAwesomeIcon icon={faCaretUp} color={Colors.brand.green} style={styles.icon}/> :
        <FontAwesomeIcon icon={faCaretDown} color={Colors.brand.red} style={styles.icon}/>
    }
    const getLineChart=()=>{
        let data =[];// [5000000, 4000000, 4500000, 5000000, 3000000];
        let categories =[]; //["Apr-22", "May-22", "Jun-22", "Jul-22", "Aug-22"]
        for(let i=results.HistoryCoCT.length-1;i>=0;i--){
            let row = results.HistoryCoCT[i];
            data.push(row.LoadMass);
            let monthStr = moment(row.EndDate,"YYYY-MM-DD").format('MMM-YY');
            categories.push(monthStr)
        }
        return {data,categories}
    }
    const getAvgBasedOnData=(data)=>{
        let myAvg = 0;
        let num = 0;
        let total =0;
        for(let i=0;i<data.length;i++){
            num++
            total+=data[i];
        }
        myAvg = total/num
        return 1500000;
    }
    function fNumber(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    const fDate=()=>{
        return moment(endDate).format("MMMM YYYY");
    }
    const getClientName=(cname)=>{
        let data = {
            /*"Plant Boutique Pty Ltd ta Master Organics":"Master Organics",
            "Robusta Wholesale Growers":"Robusta",
            "Landscapers Choice CC":"Landscapers",
            "Braaf Waste Management (Pty) Ltd":"Braaf"*/
        }
        for(let  i in clientAlias){
            let row = clientAlias[i];
            data[row.client] = row.alias;
        }
        if(typeof data[cname] != "undefined"){
            return data[cname];
        }
        return cname
    }

    return (
        <div className={"wrapperContainer"}>
            <Row>
                <Col xs={12}>
                    <WoodChippingCardHeader
                        title={"City of Cape Town"}
                        month={fDate()}
                    />
                    <WoodChippingCardContent2
                        Data={[
                            {
                                data:[
                                    {k:fNumber(cRec.MassCurrent),v: "kgs",kStyle:styles.titleMe},
                                    {k: "Target",v:fNumber(cRec.MassTarget)},
                                    {k:fNumber(cRec.MassBalanceCurrent),v:getIndicatorIcon(cRec.MassCurrentIndicator)},
                                    {k:"Previous Month",v:fNumber(cRec.MassPrevious)},
                                    {k: fNumber(cRec.MassBalancePrevious),v: getIndicatorIcon(cRec.MassPreviousIndicator)},
                                ]
                            },
                            {
                                data:[
                                    {k:fNumber(cRec.LoadCurrent),v: "loads" ,kStyle:styles.titleMe},
                                    {k:"Target",v:cRec.LoadTarget,},
                                    {k:cRec.LoadBalanceCurrent,v:getIndicatorIcon(cRec.LoadCurrentIndicator)},
                                    {k:"Previous Month",v:fNumber(cRec.LoadPrevious)},
                                    {k: cRec.LoadBalancePrevious,v: getIndicatorIcon(cRec.LoadPreviousIndicator)},
                                ]
                            },
                            {
                                data:[
                                    {k:fNumber(cRec.CubicCurrent),v: "m³" ,kStyle:styles.titleMe},
                                    {k:"Target",v:fNumber(cRec.CubicTarget),},
                                    {k:fNumber(cRec.CubicBalanceCurrent),v:getIndicatorIcon(cRec.CubicCurrentIndicator)},
                                    {k:"Previous Month",v:fNumber(cRec.CubicPrevious)},
                                    {k: fNumber(cRec.CubicBalancePrevious),v: getIndicatorIcon(cRec.CubicPreviousIndicator)},
                                ]
                            }

                        ]}
                    />
                </Col>
                <Col xs={12}>
                    <WoodChippingLoadBarInfo
                        Data={[
                            {
                                k: "Load Balance",
                                v: "",
                                styleKey: {fontSize: 18, fontWeight: "bold"},
                            },
                            {
                                k: "City of Cape Town",
                                v: results.AllLoadedCoCT,
                            },
                            {
                                k: "Total Compost Makers",
                                v: results.AllLoadedCompost,
                            },
                            {
                                k: "Balance",
                                v: results.AllLoadedBalance,
                            },
                            {
                                k: <FontAwesomeIcon icon={results.AllLoadedBalance>=0?"check":"close"}/>,
                                styleKey: {fontSize: 22, fontWeight: "bold", color:results.AllLoadedBalance===0? "green":"red"},
                            }

                        ]}/>
                    <div style={{
                        maxHeight:240,
                        marginTop:10,
                    }}>
                        <ProfitTrend
                            avgIn={getAvgBasedOnData(getLineChart().data)}
                            chartId={"dashTrend1"}
                            data={getLineChart().data}
                            columnColor={["#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50",]}
                            titleChart={"Wood Chipping Production"}
                            categories={getLineChart().categories}
                            tickPositions={[0,1000000,2000000,3000000,4000000,5200000]}
                            prefixX={'{text} kgs'}
                            serieName={""}
                        />
                    </div>
                </Col>
            </Row>

            {clientData.map(xRow=>{
                console.log("xRow>> ",xRow)
                return(
                    <Row style={{marginTop:20}}>
                        {xRow.map(yRow=>{
                            let  client= getClientName(yRow.Name)
                            return(
                                <Col span={12}>
                                    <WoodChippingCardHeader
                                        title={client}
                                        month={fDate()}
                                    />
                                    <WoodChippingCardContent2
                                        Data={[
                                            {
                                                data: [
                                                    {k:yRow.CurrentMonthLoad, v: "loads", kStyle: styles.titleMe},
                                                    {k: "Previous Month", v: yRow.PreviousMonthLoad,},
                                                    {k: yRow.Different, v: getIndicatorIcon(yRow.Indicator)},
                                                ],
                                                IconOnly: false,
                                            },
                                            {
                                                data: [
                                                    {k: "Load contribution", v: ""},
                                                    {k: yRow.ContributionPercent.toFixed(2) +"%", v: "", kStyle: styles.titleMe},
                                                ],
                                                IconOnly: false,
                                            },
                                            {
                                                data: [],
                                                IconOnly: true,
                                                percentage:yRow.ContributionPercent
                                            },
                                        ]}
                                    />
                                </Col>
                            )
                        })}
                    </Row>
                )
            })}

        </div>
    )
}

const styles={
    icon:{
        alignSelf:"center",
        fontSize: 24,
    },
    titleMe:{
        fontSize:18,
        fontWeight:"bold"
    }
}