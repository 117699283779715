import {Button, Space, Table} from "antd";
import React from "react";


export default ({DataRules,handleDelete})=>{
    const columns = [
        {
            title: 'Site',
            dataIndex: 'Site',
            key: 'name',
        },
        {
            title: 'Role',
            dataIndex: 'Role',
            key: 'role',
        },
        {
            title: 'Employee',
            dataIndex: 'NumberOfEmployees',
            key: 'employee',
        },
        {
            title: 'Normal',
            dataIndex: 'NormalDayWorked',
            key: 'normal',
        },
        {
            title: 'Saturday',
            dataIndex: 'SaturdayWorked',
            key: 'saturday',
        },
        {
            title: 'Sunday',
            dataIndex: 'SundayWorked',
            key: 'sunday',
        },
        {
            title: 'Phd',
            dataIndex: 'PhWorked',
            key: 'phd',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button onClick={() => handleDelete(record)} danger>Delete</Button>
                </Space>
            ),
        },
    ];

    // DisplayDataLabourOverwriteRules

    return (
        <Table dataSource={DataRules} columns={columns} />
    )
}