import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {Alert, Button, Col, DatePicker, Form, Input, Layout, Row, Select} from "antd";
import {fetchDataEntity} from "../../../api/entity-data";
import {SortData,} from "../../../api/fn";
import {PostToPbx} from "../../../api/http-post";
import moment from "moment";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import DisplayDataReportSummaryHours from "../../../components/Reports/DisplayDataReportSummaryHours";
import Configures from "../../../configures";
import {GetConfigures} from "../../../configures/serve";
import {GetUserToken} from "../../../api/token";
import {loadData} from "../invoices/SetupLabourInvoice/lib";

const {Content} = Layout;
const {Option} = Select;

function convertDateFormat_DDMMYYYY(dateIn) {
    let mydate = moment(dateIn, "YYYY-MM-DD").format("DD MMM YYYY");
    return mydate;
}

function formatCurrency(stringIn) {
    return stringIn.toFixed(2)
}



class PayrollSupportingDocumentU201Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Org: "",
            Companies: [],
            Entities: [],
            FnbCsvInfo: [],

            selectedCompany: "",
            selectedModule: "",
            selectedRecord: {},
            selectedPeriod: "",
            selectedSite: "",
            EmployeeCode:"",
            documentDate:"",

            Data: [],
            isLoading: false,
            component: "list",
            initLoading: false,

            DataPeriod: [],
            DataPayCycle: [],
            DataSite: [],

            Results: null,
            showViewDetail: false,
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.initialReload()
    }
    initialReload = async () => {
        await fetchDataEntity("Entities", "", "", this, undefined, "common/entity/modules/list");
        await this.loadData("PayrollBankingCsvFnb","FnbCsvInfo")
    }
    loadContractInfo = async (selectedModule) => {
        let _this = this;
        let module = this.state.selectedModule;
        let hub = {
            Org: this.state.selectedCompany,
            Module: "contract",
            Ref: selectedModule,
        }

        console.log("loadContractInfo > ",hub)
        let endpoint = "/payroll/contract/fetch-data"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            let tmp = _this.state;
            tmp.DataPeriod = data.TermsPeriod;
            tmp.DataDesignation = data.TermsDesignation;
            tmp.DataPayCycle = data.TermsPayCycle;
            tmp.DataSite = data.TermsSite;
            tmp.DataRate = data.TermsRate;
            tmp.DataRateSpecific = data.TermsRateSpecific;
            tmp.DataSlot = data.TermsSlot;
            tmp.DataSiteDesignation = data.TermsSiteDesignation;
            tmp.DataShift = data.TermsShift;
            tmp.DataCondition = data.TermsCondition;
            _this.setState(tmp);

            console.log("loadInitialData > ", output, " > ", data)
        });
    }
    loadData = async (targetEntity, stateKey) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            await _this.setState({
                [stateKey]: data.RESULT
            })
        });
    }
    handleChangeCompany = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    handleChange = async (e, key) => {
        const value = e.value;
        this.setState({
            [key]: value,
        });

        if (key === "selectedModule") {
            console.log("selected project > ", key, value)
            await this.loadContractInfo(value)
        }

    }
    handleChange2 = (e, ee, key) => {
        const val = e;
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
    }
    validateSubmitSearch=()=>{
        const state = this.state;
        if(state.selectedCompany===""){
            alert("Validation fail! Please select your company")
            return false
        }
        if(state.selectedModule===""){
            alert("Validation fail! Please select your project")
            return false
        }
        if(state.selectedPeriod===""){
            alert("Validation fail! Please select your pay circle")
            return false
        }

        return true
    }
    submitSearch = async () => {
        this.setState({
            isLoading: true,
            Results: null,
        });

        if(!this.validateSubmitSearch()){
            return
        }

        let empCode = this.state.EmployeeCode.trim()
        empCode = empCode.toLocaleUpperCase()

        let arr =  this.state.selectedPeriod.split("#");

        let hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedModule,
        }

        console.log("1 -Data--> ", hub)
        let endpoint = "/emp201/new"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            console.log("-search Data--> ", data)
            this.setState({
                Results: data,
                isLoading: false,
            });

        });

    }
    getPayDate=()=>{
        let arr =  this.state.selectedPeriod.split("#");
        return arr[3]
    }
    renderWarningBar = () => {
        return
        let boo = false
        if (this.state.selectedCompany === "" || this.state.selectedEntity === "" || this.state.component === "form") {
            boo = true
        }
        if (!boo) {
            return
        }
        return (
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Alert style={{margin: '16px 0'}} message="Please select your company above to view your records!"
                       type={"error"}/>
            </div>
        )
    }
    renderCompaniesSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={""}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedCompany")}
                    value={this.state.selectedCompany}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderModules = () => {
        let ls = [];
        let org = this.state.selectedCompany;
        for (let i in this.state.Entities) {
            const row = this.state.Entities[i];

            if (row.org === org) {
                ls.push({
                    key: row.name,
                    val: row.name,
                })
            }
        }
        return (
            <Select
                name={"selectedModule"}
                defaultValue=""
                onChange={(e, ee) => this.handleChange(ee, "selectedModule")}
            >
                {ls.map((record, index) => {
                    return (
                        <Option value={record.key}>{record.val}</Option>
                    )
                })}
            </Select>
        )
    }
    formatDate = (dateIn) => {
        return convertDateFormat_DDMMYYYY(dateIn)
    }
    renderPayCycle = () => {
        let data = this.state.DataPayCycle;
        data = SortData("Period", data)
        let ls = [];
        for (let i in data) {
            let row = data[i];
            const key = row.StartDate + "#" + row.EndDate + "#" + row.Period+"#"+row.PayDate;
            const display = row.Period + ". -> Start: " + this.formatDate(row.StartDate) + " -> End: " + this.formatDate(row.EndDate) +
                " -> Pay date: " + this.formatDate(row.PayDate);
            ls.push({
                key: key,
                val: display
            })
        }
        return (
            <React.Fragment>
                <Form.Item label={"Pay Cycle"} required={true}>
                    <Select
                        name={"selectedPeriod"}
                        defaultValue={this.state.selectedPeriod}
                        value={this.state.selectedPeriod}
                        onChange={(e, ee) => this.handleChange2(e, ee, "selectedPeriod")}
                    >
                        {ls.map((record, index) => {
                            return (
                                <Option value={record.key}>{record.val}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            </React.Fragment>
        )
    }

    renderInputEmployeeCode=()=>{
        return (
            <Form.Item label={"Employee Payroll Code"} required={true}>
                <Input
                    name={"selectedPeriod"}
                    value={this.state.EmployeeCode}
                    onChange={(e) => this.handleChangeCompany( e.target.value, "EmployeeCode")}
                />
            </Form.Item>
        )
    }

    renderIFrameResult=()=>{
        if(typeof this.state.Results.DropBoxRev==="undefined"){
            return null
        }
        console.log("renderIFrameResult > ",this.state.Results)

        let info = this.state.Results;
        let link ="https://cloudcalls.easipath.com/backend-pmis/api/files/download/"+ info.DropBoxRev;
        return (
            <iframe
                src={link}
                title="Invoice"
                style={{minWidth:"100%",minHeight:1024,background: "#FFFFFF"}}
                frameborder="0"

            >
                Press me: <a href={link}>Download PDF</a>
            </iframe>
        )
    }

    renderDocumentDate=()=>{
        return(
            <Form.Item
                name={"documentDate"}
                label={"Document Date"}
                rules={[{required: true, message: 'Select your document date '}]}
            >
                <DatePicker
                    format="YYYY-MM-DD"
                    onChange={(date, dateString) => this.handleChangeCompany( dateString, "documentDate")}
                    style={{minWidth: "100%"}}
                />
            </Form.Item>

        )
    }

    render() {
        let props = this.props;
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>U201 Form</Title>
                    <Row gutter={16} style={{marginTop: 20}}>
                        <Col span={12}>
                            <Form.Item
                                label={"Company"}
                            >
                                {this.renderCompaniesSelection()}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={"Project"}
                            >
                                {this.renderModules()}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{marginTop: -40}}>
                        <Col span={24}>
                            {this.renderPayCycle()}
                        </Col>
                    </Row>

                    <Row gutter={16} style={{marginTop: -10}}>
                        <Col span={24}>
                            <Button style={{minWidth:"100%"}} danger onClick={() => this.submitSearch()}>Generate Document
                            </Button>&nbsp;&nbsp;
                        </Col>
                    </Row>
                    {this.state.Results !== null ?
                        <Row gutter={24} style={{marginTop: 40}}>
                            <Col span={24}>
                                {this.renderIFrameResult()}
                            </Col>
                        </Row> : null
                    }
                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }

}

export default Connector(PayrollSupportingDocumentU201Page);

const styles={

}