
import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import  {Button,Alert, Form, Layout, Select,Modal,Input } from "antd";
import {fetchDataEntity} from "../../../api/entity-data";
import {GetUserToken} from "../../../api/token";
import {SortData, UpCaseFirst} from "../../../api/fn";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {NewAttributeFormStep} from "../../../components/web/Database/NewAttributeFormStep";
import DateSelectorBar from "../../../components/web/Clocking/DateSelectorBar";
import {StepBulkClockingIndex} from "../../../components/web/Clocking/StepBulkClockingIndex";
import {SubmitPost} from "../../../components/web/Common/localFunctions";
const { TextArea } = Input;
const {Content} = Layout;
const {Option} = Select;


class MobileLoginSetupPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Entities: [],
            Attributes: [],
            selectedCompany: "",
            selectedEntity: "",
            selectedRecord: {},
            Data: [],
            isLoading: false,
            component: "list",
            showNewForm:false,
            newUsername:"",
            newPassword:"",
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Entities", "", "", this, undefined, "common/entity/modules/list");
        await fetchDataEntity("Attributes", "", "", this, undefined, "common/entity/attributes/list");
        await this.loadInitialData()
    }

    loadInitialData=async () => {
        await fetchDataEntity("Data", "", "", this, undefined, "common/entity/mobile-login/list")
    }
    initialReload=async () => {
        await fetchDataEntity("Data", "", "", this, undefined, "common/entity/mobile-login/list")
    }
    handleChangeCompany = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    submitForm=async ()=>{
        let _this=this;
        if(this.state.newUsername===""){
            return alert("Field validation fail,please select your username field!")
        }
        if(this.state.newPassword===""){
            return alert("Field validation fail,please select your password field!")
        }
        let user = GetUserToken();
        if(!window.confirm("Are you sure that u want to submit  ["+this.state.newUsername+"] as an username And ["+this.state.newPassword+"] " +
            "as a password attributes?")){
            return
        }
        _this.setState({
            isLoading: false,
        });

        let post={}
        post.AutoGenerate=false;
        post.HasUniqueKey=false;
        post.AutoGenerateField="";

        let hub={};
        hub.Org = this.state.selectedCompany;
        hub.Module = this.state.selectedEntity;
        hub.Username = this.state.newUsername;
        hub.Password = this.state.newPassword;

        post.Data = hub;
        post.Params={}

        let endpoint = "/common/entity/mobile-login/insert"

        await SubmitPost(post, endpoint, "Thank you , Mobile login attributes submitted", this, async (dIn) => {
            alert("Thank you , Mobile login attributes submitted!")
            _this.setState({
                isLoading: false,
                showNewForm:false,
                component:"list",
                newUsername:"",
                newPassword:"",
            });
            await _this.initialReload();
            window.location.reload();
            // alert("Thank you , attributes submitted")
        })

    }
    onAddRecord=()=>{
        if (this.state.selectedCompany === "") {
            return alert("Please select company first!")
        }
        if (this.state.selectedEntity === "") {
            return alert("Please select project first!")
        }
        this.setState({
            showNewForm:true,
            component:"form"
        });
    }
    closeNewForm=()=>{
        this.setState({
            showNewForm:false,
            component:"list"
        });
    }
    renderCompaniesSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={"Select Company"}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedCompany")}
                    value={this.state.selectedCompany}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderModuleSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = [];
        for(let i in this.state.Entities){
            const row = this.state.Entities[i];
            if(row.org!==this.state.selectedCompany){continue}
            ls.push(row);
        }
        return (
            <Form.Item
                label={"Select Project"}
                name={"selectedEntity"}
                rules={[{required: true, message: "Please select your project!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedEntity")}
                    value={this.state.selectedEntity}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.name}
                                value={record.name}
                            >
                                {record.name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderList=()=> {
        if (this.state.component !== "list") {
            return
        }
        let columns = [
            {id:"module",label:"Project"},
            {id:"username",label:"Username Attribute"},
            {id:"password",label:"Password Attribute"},
            {id:"date",label:"Updated Date"},
            {id:"time",label:"Updated Time"},
        ];

        let ls=[];
        for(let i in this.state.Data){
            const row=this.state.Data[i];
            if(row.org!==this.state.selectedCompany){continue}
            if(row.module!==this.state.selectedEntity){continue}
            ls.push(row);
        }

        ls = SortData("position",ls)

        let data = {columns: columns, data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Records")}
                    onAddRecord={this.onAddRecord}
                    allowedAdd={true}
                />
            </GridItem>
        )

    }
    renderWarningBar=()=>{
        return
        let boo=false
        if(this.state.selectedCompany === "" || this.state.selectedEntity === "" ||this.state.component==="form"){
            boo=true
        }
        if(!boo){
            return
        }
        return(
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Alert style={{ margin: '16px 0' }} message="Please select your company above to view your records!" type={"error"}/>
            </div>
        )
    }
    renderNewForm=()=>{
        if (this.state.component !== "form") {
            return
        }

        const buildSelector=(stateKey,options,label,msg)=>{
            return(
                <Form.Item
                    label={label}
                    name={stateKey}
                    rules={[{required: true, message: "Please select your "+msg}]}
                >
                    <Select
                        style={{width: "100%"}}
                        onChange={(value) => this.handleChangeCompany(value, stateKey)}
                        value={this.state[stateKey]}
                    >
                        {options.map((record, index) => {
                            return (
                                <Option
                                    key={record.key}
                                    value={record.val}
                                >
                                    {record.val}
                                </Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            )
        }

        let ls = [];
        for(let i in this.state.Attributes){
            const row=this.state.Attributes[i];
            if(row.org!==this.state.selectedCompany){continue}
            if(row.module!==this.state.selectedEntity){continue}
            if(row.fieldname===this.state.newUsername){continue}
            if(row.fieldname===this.state.newPassword){continue}
            ls.push({
                key:row.fieldname,
                val:row.fieldname
            })
        }



        return(
            <div>
                <Alert
                    message={(
                    <div>
                        <span style={{float:"left"}}>New Mobile setup</span>
                        <a
                            style={{float:"right",color:"blue",cursor:"pointer"}}
                            onClick={this.closeNewForm}
                        >Close</a>
                    </div>
                )}
                  style={{minHeight:50}}
                />
                <br/>
                {buildSelector("newUsername",ls,"Select Username attribute","Username")}
                {buildSelector("newPassword",ls,"Select Password attribute","Password")}
                <Form.Item
                    label={""}
                    name={""}
                    rules={[{required: true, message: "Please select your "}]}
                >
                    <Button
                        type="primary"
                        style={{float:"right"}}
                        onClick={()=>this.submitForm()}

                    >
                        Submit
                    </Button>
                </Form.Item>
            </div>
        )
    }

    render() {
        let props = this.props;
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Mobile Setup Login Attribute</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        {this.renderCompaniesSelection()}
                        {this.renderModuleSelection()}
                    </div>
                    {this.renderWarningBar()}
                    {this.renderList()}
                    {this.renderNewForm()}
                </div>
            </Page>
        )
    }

}


export default Connector(MobileLoginSetupPage);

const styles = {
    container:{

    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

/*
<div style={{minWidth:"100%"}}>
                    <Button
                        style={{maxWidth: "10%",minWidth: "10%",float:"right"}}
                        onClick={()=>this.submitForm()}

                    >
                        Submit
                    </Button>
                </div>
 */
