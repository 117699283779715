import React from "react";
import {Alert, Table} from "antd"

export default (props) => {
    let {_this} = props;
    let {ErrorTitle, ErrorCode, ErrorData} = _this.state;

    let columns=[];
    // let rec = { errorCode:0,line:index,UniqueKeyError:"No",UniqueKey:"",MandatoryKeyError:"",MandatoryKeys:""};
    columns.push({
        title: "Line",
        dataIndex: "line",
        align: 'left',
    })
    columns.push({
        title: "Unique Key Error?",
        dataIndex: "UniqueKeyError",
        align: 'right',
    })
    columns.push({
        title: "Unique Key Name",
        dataIndex: "UniqueKey",
        align: 'right',
    });
    columns.push({
        title: "Mandatory Key Error?",
        dataIndex: "MandatoryKeyError",
        align: 'right',
    })
    columns.push({
        title: "Mandatory Keys",
        dataIndex: "MandatoryKeys",
        align: 'right',
    });
    let ls=ErrorData;

    return (
        <div>
            <h1>Validation</h1>
            {ErrorCode > 0 ?
                <React.Fragment>
                    <Alert type={"error"} message={ErrorTitle}/>
                    <Table
                        columns={columns}
                        dataSource={ls}
                        bordered
                    />
                </React.Fragment>
                :
                <Alert type={"success"} message={"Ok! you now  upload your data"}/>

            }
        </div>
    )
}