import {useEffect} from "react";
import {Colors} from "../../constants/colors";


export default ({chartId,titleChart,data,categories,avgIn,serieName,tickPositions,prefixX})=>{

    let addAvgLine = function(axis) {
        let points = axis.series[0].points,
            point = {},
            i = 0,
            total = 0,
            avgLength = 0,
            avg = 0,
            len = points.length;

        for (i = 0; i < len; i++) {
            point = points[i];
            if (point.isInside) {
                total += point.y;
                avgLength++;
            }
        }

        avg = (total / avgLength).toFixed(6)

        axis.chart.get('yA0').removePlotLine('avg');
        axis.chart.get('yA0').addPlotLine({
            id: 'avg',
            value: avgIn,
            color: 'red',
            dashStyle: 'dash',
            width: 1,
            label: {
                text: ""
            },
            zIndex: 4
        });

    }
    let Highcharts = window.Highcharts;
    let events = {};
    if(avgIn>0){
        events = {
            load: function () {
                addAvgLine(this.get('xA0'));
            }
        }
    }
    let positions = [];
    if(typeof tickPositions!=="undefined"){
        positions=tickPositions;
    }
    let prefix = "{text}"
    if(typeof prefixX!=="undefined"){
        prefix = prefixX
    }
    let options={
        chart: {
            zoomType: 'xy',
            events: events
        },
        title: {
            text: `<span style="color:${Colors.brand.dark};font-weight: bold;font-size: 12px">${titleChart}</span>`
        },
        subtitle: {
            text: ''
        },
        legend: {
            enabled: false
        },

        xAxis: {
            id: 'xA0',
            categories: categories,
            title: {
                text: null
            }
        },
        yAxis: {
            id: 'yA0',
            min: 0,
            title: {
                text: '',
                align: 'high'
            },
            labels: {
                format: prefix
            },
            tickPositions:positions
        },
        series: [{
            name:serieName,
            data,
        }],
        plotOptions: {
            series: {
                borderWidth: 2,
                dataLabels: {
                    enabled: false,
                    formatter:function (){return `${this.x}%`} // 'R{point.y:nFormatter(point.y)}'
                }
            }
        },

    }
    useEffect(()=>{
        Highcharts.chart(chartId,options);
    })
    return(
        <div id={chartId} className={""} style={{
            maxHeight:270,
            minHeight:"50%",
            maxWidth:"100%",
            minWidth:"100%"
        }}>

        </div>
    )
}