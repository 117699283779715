import moment from "moment";
import './VerificationIssue.css'
import React, {useState} from "react";
import {Colors} from "../../constants/colors";
import {Button, Col, Drawer, Row} from "antd";
// Function to format dates
const formatDate = (dateStr) => {
    return moment(dateStr, "YYYY-MM-DD").format('DD-MMM');
};
// Function to transform the data
const transformData = (data) => {
    const transformedData = {};
    const dates = new Set();

    Object.keys(data).forEach((site) => {
        transformedData[site] = {};

        Object.keys(data[site]).forEach((date) => {
            dates.add(date);
            transformedData[site][date] = data[site][date].UnitDiffer;
        });
    });

    return {transformedData, dates: Array.from(dates).sort()};
};

export default ({data}) => {
    const [selectedSite, setSelectedSite] = useState('');
    const {transformedData, dates} = transformData(data); // Initialize here
    const [displayPopup,setDisplayPopup] = useState(false);
    const [selectedIssue,setSelectedIssue] = useState(null);

    const sites = Object.keys(transformedData);

    const handleSiteChange = (event) => {
        setSelectedSite(event.target.value);
    };
    const onClickIssue = (site,date) => {
        let record = data[site][date]
        setSelectedIssue(record)
        setDisplayPopup(true);
    }
    const closePopup=()=>{
        setDisplayPopup(false);
        setSelectedIssue(null)
    }
    const onSubmitIssue=()=>{
        //todo
        alert("Coming soon! Mr Peter David be patient :(")
    }

    const RenderPopup=()=>{
        if(!displayPopup){
            return null;
        }
        return(
            <Drawer
                title={"Selected Issue"}
                width={720}
                onClose={closePopup}
                visible={displayPopup}
                bodyStyle={{paddingBottom: 80, overflow: "scroll"}}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >

                        <Button onClick={() => closePopup()} style={{marginRight: 8}}>
                            Close
                        </Button>

                        <Button onClick={() => onSubmitIssue()} type="primary">
                            Submit Fix Issue
                        </Button>
                    </div>
                }
            >
                <p className={"pPop"}>Site Name (CoCT): <span className={"selectedPopup"}> {selectedIssue.SiteClient}</span></p>
                <p className={"pPop"}>Site Name (CMIS): <span className={"selectedPopup"}>{selectedIssue.SiteCmis}</span></p>
                <p className={"pPop"}>Date: <span className={"selectedPopup"}>{moment(selectedIssue.Date).format("DD-MMM-YYYY")}</span></p>
                <p className={"pPop"}>Delivery Note: <span className={"selectedPopup"}>{selectedIssue.DeliveryNote}</span></p>
                <p className={"pPop"}>#services(CoCT): <span className={"selectedPopup"}>{selectedIssue.UnitClient}</span></p>
                <p className={"pPop"}>#services(CMIS): <span className={"selectedPopup"}>{selectedIssue.UnitActual}</span></p>
                <p className={"pPop"}>#services(DIFF): <span className={"selectedPopup"}>{selectedIssue.UnitDiffer}</span></p>

            </Drawer>
        )
    }
    return (
        <div className="App">
            <div>
                <label htmlFor="site-select">Filter by Site: </label>
                <select id="site-select" value={selectedSite} onChange={handleSiteChange}>
                    <option value="">All Sites</option>
                    {sites.map((site) => (
                        <option key={site} value={site}>
                            {site}
                        </option>
                    ))}
                </select>
            </div>
            <table>
                <thead>
                <tr>
                    <th>Site</th>
                    {dates.map((date) => (
                        <th key={date}>{formatDate(date)}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {Object.keys(transformedData).map((site) => (
                    <tr key={site}>
                        <td>{site}</td>
                        {dates.map((date) => {
                            let myTag =transformedData[site][date] || 0
                            if(myTag!==0){
                                return (
                                    <td
                                        key={date}
                                        onClick={() => onClickIssue(site,date)}
                                        style={{
                                        cursor:"pointer",
                                        color:Colors.brand.blue,
                                            fontWeight:"bold"
                                    }}>
                                        {transformedData[site][date] || 0}
                                    </td>
                                )
                            }
                            return (
                                <td key={date}>{transformedData[site][date] || 0}</td>
                            )
                        })}
                    </tr>
                ))}
                </tbody>
            </table>
            {RenderPopup()}
        </div>
    );
}