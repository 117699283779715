import React from "react";
import {fetchDataEntity} from "../../../api/entity-data";
import ControllerSelectBox from "../../../components/Common/ControllerInput/ControllerSelectBox";
import {ArrayToSelectOption, FilterArrayByKey, SortData, UpcaseFirst} from "../../../api/fn";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {PostToPbx} from "../../../api/http-post";
import moment from "moment";
import GridContainer from "../../../components/Common/dashboard/Grid/GridContainer";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import {Button, Col, Row} from "antd";
import SearchComboBox from "../../../components/Common/SearchBoxSelectionImage/SearchComboBox";
import BoxSelect from "../../../components/Common/ControllerInput/BoxSelect";
import TableDataView from "../../../components/Common/tables/TableDataView";


class PensionProvidentFundLoggerPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Attributes:[],
            Projects: [],
            Periods: [],
            Employees: [],
            Sites: [],
            Files:[],
            Data:[],

            selectedCompany: "",
            selectedProject: "",
            selectedSite: "",
            selectedEmployee: "",
            currentAmount: 0,
            accAmount:0,

            step: 0,
            showStep: false,
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Projects", "", "", this, undefined, "common/entity/modules/list");
        await fetchDataEntity("Files", "", "", this,undefined,"common/entity/files/list");
        await fetchDataEntity("Data", "", "", this,undefined,"common/entity/PensionProvidentFundContribution/list");
    }
    requestNewDeduction = () => {
        this.setState({
            component: "form"
        })
    }
    handleChangeInput = async ( key,value) => {
        console.log("handleChangeInput > ",key," > ",value)
        this.setState({
            [key]: value,
        });
        if (key === "selectedProject" && this.state.selectedCompany !== "") {
            await this.loadSelectedDatabaseAttributes();
            await this.loadSelectedContractInfo();
        }
    }
    handleGetInputValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return ""
        }
        return this.state[key];
    }
    loadSelectedDatabaseAttributes = async () => {
        let conditions=[
            {key:"org",val:""}
        ]
        await fetchDataEntity("Databases", "", "", this,undefined,"common/entity/databases/list");
        await fetchDataEntity("Attributes", "", "", this,undefined,"common/entity/attributes/list");
    }
    loadSelectedContractInfo=async ()=>{
        let _this = this;
        let state = this.state;
        let hub = {
            Org: state.selectedCompany,
            Module: "contract",
            Ref: state.selectedProject,
        }

        let endpoint = "/payroll/contract/fetch-data"
        await PostToPbx(hub, endpoint, async (output) => {
            console.log("loadInitialData > ", hub, output)
            let data = output.RESULT;
            let tmp = _this.state;
            tmp.Periods =  data.TermsPayCycle;
            tmp.Sites = data.TermsSite;

            _this.setState(tmp);

            console.log("loadSelectedContractInfo > ", tmp.Sites, " > ", tmp.Periods)
        });
    }

    findEmpName = ()=>{
        let attributes = [];
        let storeAttributes = SortData("position",this.state.Attributes)
        for(let i in storeAttributes) {
            let row = storeAttributes[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.module !== this.state.selectedProject) {
                continue
            }


            if(!row.displayselection){
                continue
            }
            attributes.push(row.fieldname)
        }

        let name = "";
        for(let i in this.state.Databases){
            let row = this.state.Databases[i];
            if(row.org!==this.state.selectedCompany){continue}
            if(row.module!==this.state.selectedProject){continue}
            if (row.ref !== this.state.selectedEmployee) {
                continue
            }
            let fields=[];
            const uniqueKey = row.uniquekey;
            fields.push(uniqueKey);
            for(let a in attributes){
                fields.push(attributes[a])
            }
            row.fullName =this.getEmployeeOption(fields,row.data)
            name  = row.fullName;
        }
        return name
    }

    submitDeduction=async ()=>{
        let _this = this;
        let state = this.state;
        console.log("submitDeduction state > ",state)
        let arr=state.selectedPeriod.split("#");


        let currentAmount=parseFloat(state.currentAmount);
        let accAmount = parseFloat(state.accAmount);

        let hub={};
        hub.Org = state.selectedCompany;
        hub.PayCyclePeriod = `${arr[1]}#${arr[2]}#${arr[0]}`
        hub.WorkerRef =state.selectedEmployee;
        hub.Project = state.selectedProject;
        hub.CurrentAmount = currentAmount;
        hub.AccAmount = accAmount;
        hub.PayDate = arr[3];
        hub.Site = state.selectedSite;
        hub.WorkerName = this.findEmpName();

        let postData={
            Data:hub
        }
        console.log("submitDeduction send > ", hub)

        let endpoint = "/common/entity/PensionProvidentFundContribution/insert"
        await PostToPbx(postData, endpoint, async (output) => {
            let data = output.RESULT;

            console.log("submitDeduction response > ", data)
            alert("Thank you, yours deduction have been recorded!")
            await fetchDataEntity("Data", "", "", this,undefined,"common/entity/PensionProvidentFundContribution/list");
        });
    }

    onDeleteView=async (rec)=>{
        if(!window.confirm(`Are you sure to delete deduction for ${rec.workername}`)){
            return
        }
        let postData={
            Params:[
                {key:"org",type:"string",val:rec.org},
                {key:"project",type:"string",val:rec.project},
                {key:"PayCyclePeriod",type:"string",val:rec.paycycleperiod},
                {key:"workerref",type: "string",val:rec.workerref},
            ]
        }
        console.log("onDeleteView send > ", postData)

        let endpoint = "/common/entity/PensionProvidentFundContribution/delete"
        await PostToPbx(postData, endpoint, async (output) => {
            let data = output.RESULT;

            console.log("onDeleteView response > ", data)
            alert("Thank you, yours deduction have been deleted!")
            await fetchDataEntity("Data", "", "", this,undefined,"common/entity/PensionProvidentFundContribution/list");
        });
    }
    renderCompanyBox = () => {
        let ls = [];
        ls = FilterArrayByKey( this.state.Companies,{})
        ls = ArrayToSelectOption(ls,["CustomerNumber"],["Name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Company"}
                name={"selectedCompany"}
            />
        )
    }
    renderProjectBox = () => {
        let ls = [];
        for(let i in this.state.Projects){
            let row = this.state.Projects[i];
            if(row.org!==this.state.selectedCompany){continue}
            ls.push(row)
        }
        ls = FilterArrayByKey( ls,{})
        ls = ArrayToSelectOption(ls,["name"],["name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Project"}
                name={"selectedProject"}
            />
        )
    }
    renderPayCycleBox = () => {
        const shortDisplayDate=(dateIn)=>{
            return moment(dateIn, "YYYY-MM-DD").format("DD MMM YYYY")
        }
        let ls = [];
        for(let i in this.state.Periods){
            let row = this.state.Periods[i];
            if(row.Org!==this.state.selectedCompany){continue}
            if(row.Ref!==this.state.selectedProject){continue}
            //todo let make display name
            const startDate =shortDisplayDate(row.StartDate);
            const endDate =shortDisplayDate(row.EndDate);
            const payDate = shortDisplayDate(row.PayDate);
            row.DisplayLabel = `${row.Period}. -> Start: ${startDate} -> End: ${endDate} -> Pay date: ${payDate}`;
            row.DisplayValue = `${row.Period}#${row.StartDate}#${row.EndDate}#${row.PayDate}`
            ls.push(row)
        }
        ls = FilterArrayByKey( ls,{})
        ls = ArrayToSelectOption(ls,["DisplayValue"],["DisplayLabel"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Pay Circle period"}
                name={"selectedPeriod"}
            />
        )
    }
    renderSiteBox = () => {
        let ls = [];
        for(let i in this.state.Sites){
            let row = this.state.Sites[i];
            if(row.Org!==this.state.selectedCompany){continue}
            if(row.Module!=="contract"){continue}
            if(row.Ref!==this.state.selectedProject){continue}
            ls.push(row)
        }
        ls = FilterArrayByKey( ls,{})
        ls = ArrayToSelectOption(ls,["Name"],["Name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Site"}
                name={"selectedSite"}
            />
        )
    }

    getEmployeeOption=(attributes,data)=>{
        let name="";
        let x=0;
        for(let i in attributes){
            let field = attributes[i];
            if(x===0){
                name=data[field]
            }else{
                name=name+" "+data[field]
            }
            x++;
        }

        return name;
    }

    renderEmployeeBox = () => {
        let ls = [];
        let attributes = [];
        let storeAttributes = SortData("position",this.state.Attributes)
        for(let i in storeAttributes) {
            let row = storeAttributes[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.module !== this.state.selectedProject) {
                continue
            }


            if(!row.displayselection){
                continue
            }
            attributes.push(row.fieldname)
        }

        console.log("this.state.Files > ",this.state.Files," > ",attributes)
        for(let i in this.state.Databases){
            let row = this.state.Databases[i];
            if(row.org!==this.state.selectedCompany){continue}
            if(row.module!==this.state.selectedProject){continue}
            if (row.data.Site !== this.state.selectedSite) {
                continue
            }
            let fields=[];
            const uniqueKey = row.uniquekey;
            fields.push(uniqueKey);
            for(let a in attributes){
                fields.push(attributes[a])
            }
            row.fullName =this.getEmployeeOption(fields,row.data)
            ls.push(row)
        }
        console.log("renderEmployeeBox > ",ls)
        ls = FilterArrayByKey( ls,{})
        ls = ArrayToSelectOption(ls,["ref"],["fullName"])

        return(
            <BoxSelect
                _this={this}
                handleInput={this.handleChangeInput}
                handleInput2={this.handleChangeInput}
                label={"Employee"}
                ls={ls}
                files={this.state.Files}
                stateKey={"key"}
                val={"val"}
                getHandleValue={this.handleGetInputValue}
                name={"selectedEmployee"}
            />
        )

        return(
            <SearchComboBox
                _this={this}
                handleInput={this.handleChangeInput}
                label={"Employee666"}
                ls={ls}
                stateKey={"key"}
                val={"val"}
                getHandleValue={this.handleGetInputValue}
            />
        )
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Employee"}
                name={"selectedEmployee"}
            />
        )
    }

    renderDeductionList=()=>{
        let columns = [
            {id: 'workerref', label: 'Emp. Number', minWidth: 170, dataType: "string"},
            {id: 'workername', label: 'Full Name', minWidth: 170, dataType: "string"},
            {id: 'currentamount', label: 'Monthly Amount', minWidth: 170, dataType: "string"},
            {id: 'accamount', label: 'Accumulated Year Amount', minWidth: 170, dataType: "string"},
        ];


        let ls=[];

        for(let i in this.state.Data){
            let row= this.state.Data[i];
            if(row.org!==this.state.selectedCompany){continue}
            if(row.project!==this.state.selectedProject){continue}
            if(typeof this.state.selectedPeriod==="undefined"){continue}
            let arr = this.state.selectedPeriod.split("#")
            let p = arr[0];
            let start = arr[1];
            let end = arr[2];
            let targetPeriod = `${start}#${end}#${p}`
            if(row.paycycleperiod !==targetPeriod){continue}
            ls.push(row)
        }
        let otherFunctions=[
        ]
        let data  = {columns: columns, data: ls}
        return (
            <div>

                <Row gutter={18}>
                    <Col span={24}>
                        <TableDataView
                            {...data}
                            tableTitle={UpcaseFirst(this.state.selectedProject) + " Records  (" + data.data.length + ") "}
                            onAddRecord={this.onAddRecord}
                            onDelete={this.onDeleteView}
                            otherFunctions={otherFunctions}
                            options={{
                                pageSize:20,
                            }}
                        />
                    </Col>
                </Row>
            </div>
        )
    }

    render() {
        return(
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>PENSION PROVIDENT FUND DEDUCTION Logging</Title>
                    <div style={{minWidth: "100%", padding: 10}}>

                        <Row gutter={18}>
                            <Col span={12} >
                                {this.renderCompanyBox()}
                            </Col>
                            <Col span={12} >
                                {this.renderProjectBox()}
                            </Col>
                        </Row>
                        <Row gutter={18}>
                            <Col span={12} >
                                {this.renderPayCycleBox()}
                            </Col>
                            <Col span={12} >
                                {this.renderSiteBox()}
                            </Col>
                        </Row>
                        <div style={{
                            borderRadius:5,
                            borderWidth:1,
                            borderColor:"blue",
                            borderStyle:'solid',
                            minHeight:35,
                            padding:10
                        }}>
                            <h4 style={{color:"blue",}}>
                                <u>NEW PENSION PROVIDENT FUND DEDUCTION FORM</u>
                            </h4>
                            <Row gutter={18}>
                                <Col span={6} >
                                    {this.renderEmployeeBox()}
                                </Col>
                                <Col span={6} >
                                    <input
                                        placeholder={"Current Monthly deduction amount?"}
                                        onChange={(e)=>this.handleChangeInput("currentAmount",e.target.value)}
                                        style={{minWidth:"100%",marginTop:30,minHeight:40}}
                                    />
                                </Col>
                                <Col span={6} >
                                    <input
                                        placeholder={"Year Accumulated deduction amount?"}
                                        onChange={(e)=>this.handleChangeInput("accAmount",e.target.value)}
                                        style={{minWidth:"100%",marginTop:30,minHeight:40}}
                                    />
                                </Col>
                                <Col span={6} >
                                    <Button
                                        type={"primary"}
                                        style={{minWidth:"100%",
                                            marginTop:30,
                                            minHeight:40,
                                            borderRadius:15,
                                            fontSize:18,
                                            fontWeight:"bold"
                                        }}
                                        onClick={()=>this.submitDeduction()}
                                    >Add Deduction</Button>
                                </Col>
                            </Row>
                        </div>

                    </div>
                    <div>
                        {this.renderDeductionList()}
                    </div>
                </div>
            </Page>
        )
    }

}



export default Connector(PensionProvidentFundLoggerPage);
const styles = {
    container:{

    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
