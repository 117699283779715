import React from "react";
import {Button, Drawer, Table} from 'antd';
import {Tabs, Tab} from 'material-ui/Tabs';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

export default (props)=> {
    let {_this} = props;
    let {showViewPastel, } = _this.state;
    let {
        lsNormal ,
        lsNormalCsv ,
        lsSaturday ,
        lsSaturdayCsv ,
        lsSunday ,
        lsSundayCsv ,
        lsPhh ,
        lsPhhCsv ,
    }=props;

    const onClose = () => {
        _this.setState({
            showViewPastel: false,
        })
    }


    const counterFunction=(ls)=>{
        let tot=0;
        for(let i in ls){
            const row=ls[i];
            tot +=row.Quantity;
        }
        return tot;
    }

    let totDayNormal=counterFunction(lsNormal),
        totDaySaturday=counterFunction(lsSaturday),
        totDaySunday=counterFunction(lsSunday),totDayPh=counterFunction(lsPhh);

    return (
        <Drawer
            title={"Export Csv Pastel"}
            width={720}
            onClose={onClose}
            visible={showViewPastel}
            bodyStyle={{paddingBottom: 80}}
            footer={
                <div
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <MuiThemeProvider>
                    <Tabs>
                        <Tab label="Normal Day" className="tab-header">
                            <BuildTable data={lsNormal} dataCsv={lsNormalCsv} daytype={"Normal"} totalDay={totDayNormal}/>
                        </Tab>
                        <Tab label="Saturday">
                            <BuildTable data={lsSaturday} dataCsv={lsSaturdayCsv} daytype={"Saturday"}  totalDay={totDaySaturday}/>
                        </Tab>
                        <Tab label="Sunday">
                            <BuildTable data={lsSunday} dataCsv={lsSundayCsv} daytype={"Sunday"}  totalDay={totDaySunday}/>
                        </Tab>
                        <Tab label="Public Holiday">
                            <BuildTable data={lsPhh} dataCsv={lsPhhCsv} daytype={"Public Holiday"}  totalDay={totDayPh}/>
                        </Tab>
                    </Tabs>
                    </MuiThemeProvider>

                    <Button onClick={onClose} style={{marginRight: 8}}>
                        Close
                    </Button>

                </div>
            }
        >

        </Drawer>
    )
}

const BuildTable = ({data,dataCsv,daytype,totalDay})=>{
    const headers = [{
        id: 'first',
        display: 'First column'
    }, {
        id: 'second',
        display: 'Second column'
    }];

    const rows = [{
        first: 'foo',
        second: 'bar'
    }, {
        first: 'foobar',
        second: 'foobar'
    }];


    let columns=[
        {title: "Code", dataIndex: "EmployeeCode", align: 'left'},
        {title: "Name", dataIndex: "Employee", align: 'left'},
        {title: "Trans.", dataIndex: "TransactionCode", align: 'center'},
        {title: "Cost", dataIndex: "CostCentre", align: 'right'},
        {title: "Quantity", dataIndex: "Quantity", align: 'center'},
        {title: "Rate", dataIndex: "Rate", align: 'right'},
        {title: "Amount", dataIndex: "Amount", align: 'right'},
    ];

    return(
        <div className="pastelDiv">
            <h3 className="pastelHeading">
                <span className="pastelTitle">{daytype}</span>
                &nbsp;&nbsp;
                <span className="pastelTitleDetail"><small>(People=<b>{data.length}</b>)</small></span>
                &nbsp;
                <span className="pastelTitleDetail"><small>(TotalDay=<b>{totalDay}</b>)</small></span>
                &nbsp;&nbsp;

                <a onClick={()=>(MyDownload(dataCsv,daytype))} className="pastelDownloadBtn">
                    Download
                </a>

            </h3>
            <Table
                columns={columns}
                dataSource={data}
                bordered
            />
            {/*<table className={"table pastelTable"}>
                <thead>
                <tr>
                    <th>Employee Code</th>
                    <th>Employee</th>
                    <th>Transaction Code</th>
                    <th>Job Code</th>
                    <th>Cost Centre</th>
                    <th>Quantity</th>
                    <th>Rate</th>
                    <th>Amount</th>
                    <th>Override</th>
                </tr>
                </thead>
                <tbody>
                {data.map((row)=>{
                    return(<tr>
                        <td>{row.EmployeeCode}</td>
                        <td>{row.Employee}</td>
                        <td>{row.TransactionCode}</td>
                        <td>{row.JobCode}</td>
                        <th>{row.CostCentre}</th>
                        <td>{row.Quantity}</td>
                        <td>{row.Rate}</td>
                        <td>{row.Amount}</td>
                        <td>{row.Override}</td>
                    </tr>)
                })}
                </tbody>
            </table>*/}
        </div>
    )
};

const MyDownload=(rows,daytype)=>{
    //const rows = [["name1", "city1", "some other info"], ["name2", "city2", "more info"]];
    let datas = [];
    for(let  i in rows){
        const row = rows[i];
        datas.push([
            row.Code,
            row.Tans,
            row.Blanc1,
            row.Blanc2,
            row.Days,
            row.Rate,
            row.Blanc3,
            row.Override
        ]);
    }
    let csvContent = "data:text/csv;charset=utf-8,";
    datas.forEach(function(rowArray){
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "pmis_pastel_"+daytype+".csv");
    link.innerHTML= "Click Here to download";
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
}
