import React from "react";

export default (props)=>{

    return(
        <h2 style={{color:"green",fontSize:22}}>
            Your are ready to submit attributes list! please click on Done button below
        </h2>
    )
}
