import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {GetUserToken} from "../../../api/token";
import PluginAddDeleteList from "../../../components/Common/PluginAddDeleteList2";
import {PostToPbx} from "../../../api/http-post";
import {fetchDataEntity} from "../../../api/entity-data";

class MobileProRegisterUserPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Roles: [],
            isLoading: false,
            company: "",
            component: "list",
            newEntry: {},
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await this.loadData("roles", "Data");
    }
    loadData = async (targetEntity, stateKey) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            await _this.setState({
                [stateKey]: data.RESULT
            })
        });
    }

    getRoleList = () => {
        let ls = [];
        for (let i in this.state.Roles) {
            let row = this.state.Roles[i];
            if (row.org === this.state.newEntry.org) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    getCompanyList = () => {
        let ls = [];
        for (let i in this.state.Companies) {
            let row = this.state.Companies[i];
            ls.push({
                key: row.CustomerNumber,
                val: row.Name,
            });
        }
        return ls;
    }

    render() {

        const companyOptions = this.getCompanyList();
        const roleOptions = this.getRoleList()

        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Register Mobile Pro user</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"MobileAppRegister"}
                            depending={{
                                "org": {
                                    targetKey: "CustomerNumber",
                                    displayKey: "Name",
                                    storageTable: "Companies"
                                }
                            }}
                            fields={[
                                {
                                    name: "org",
                                    dataType: "option",
                                    required: true,
                                    label: "Company",
                                    options: companyOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Companies"}
                                },
                                {
                                    name: "fullname",
                                    dataType: "text",
                                    required: true,
                                    label: "Full Name",
                                    options:[]
                                },
                                {
                                    name: "username",
                                    dataType: "text",
                                    required: true,
                                    label: "Username",
                                    options:[]
                                },
                                {
                                    name: "password",
                                    dataType: "text",
                                    required: true,
                                    label: "password",
                                    options:[],
                                    displayList:false,
                                },
                                {
                                    name: "role",
                                    dataType: "option",
                                    required: true,
                                    label: "Role",
                                    options: [
                                        {key:"Administration",val:'Administration'},
                                        {key:"Executive",val:"Executive"},
                                        {key:"super",val:"Super"}
                                    ],
                                    depending: []
                                },

                            ]}
                            deleteKeys={[
                                {key: "username", type: "string"},
                            ]}
                            stateArray={"Data"}
                            table={"MobileAppRegister"}
                            addFormText={"Define PMIS Pro User"}
                            stepTitle={"Setup PMIS Pro User"}

                            filterByCompany={true}
                            displayRules={[
                                {rowKey:"org",stateKey:"selectedCompany"},
                            ]}
                            extraFilters={[
                            ]}

                            sortMe={"name"}
                            postEndpoint={"/mobile2/register/new"}
                        />

                    </div>
                </div>
            </Page>
        )
    }

}

export default Connector(MobileProRegisterUserPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

