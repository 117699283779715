import React from "react";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import PluginAddDeleteList from "../../../../components/Common/PluginAddDeleteList2";
import {fetchDataEntity} from "../../../../api/entity-data";
import {
    getClientOption,
    getCompanyList, getItemList,
    getMonthOption,
    getProjectList,
    getTemplateList,
    getYearOption,
    loadData
} from "./lib";


class SetupLabourInvoiceDesignationsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Modules: [],
            Clients: [],
            Projects:[],
            Items:[],
            isLoading: false,
            company: "",
            component: "list",
            newEntry: {},
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await loadData("modules", "Modules",this);
        await loadData("SettingInvoiceServiceDebtors", "Clients",this);
        await loadData("SettingInvoiceAddonProject", "Projects",this);
        await loadData("SettingInvoiceAddonLabourItems","Items",this)
    }

    render() {
        const org = this.state.newEntry.selectedCompany;
        const client = this.state.newEntry.selectedClient;
        const project = this.state.newEntry.selectedProject;
        const item = this.state.newEntry.selectedItem;
        const companyOptions = getCompanyList(this.state.Companies);
        const clientOptions = getClientOption(this.state.Clients,org);
        const projectOptions =getProjectList(this.state.Projects,org,client);
        const itemOptions = getItemList(this.state.Items,org,client,project);

        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Setup Labour Invoice Designations</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"SettingInvoiceAddonLabourDesignations"}
                            depending={{
                                "org": {
                                    targetKey: "CustomerNumber",
                                    displayKey: "Name",
                                    storageTable: "Companies"
                                },
                                "project":{
                                    targetKey: "code",
                                    displayKey: "name",
                                    storageTable: "Projects"
                                }
                            }}
                            fields={[
                                {
                                    name: "org",
                                    dataType: "option",
                                    required: true,
                                    label: "Company",
                                    options: companyOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Companies"}
                                },
                                {
                                    name: "client",
                                    dataType: "option",
                                    required: true,
                                    label: "Debtor",
                                    options: clientOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Clients"}
                                },
                                {
                                    name: "project",
                                    dataType: "option",
                                    required: true,
                                    label: "Project",
                                    options: projectOptions,
                                    depending: {stateKey: "project", displayKey: "Name", storageTable: "Projects"}
                                },

                                {
                                    name: "name",
                                    dataType: "string",
                                    required: true,
                                    label: "Designation name",
                                    options: []
                                },
                                {
                                    name: "ratenormal",
                                    dataType: "float",
                                    required: true,
                                    label: "Normal Rate",
                                    options: []
                                },
                                {
                                    name: "ratesaturday",
                                    dataType: "float",
                                    required: true,
                                    label: "Saturday Rate",
                                    options: []
                                },
                                {
                                    name: "ratesunday",
                                    dataType: "float",
                                    required: true,
                                    label: "Sunday Rate",
                                    options: []
                                },
                                {
                                    name: "rateph",
                                    dataType: "float",
                                    required: true,
                                    label: "Public Holiday Rate",
                                    options: []
                                },


                            ]}
                            deleteKeys={[
                                {key: "org", type: "string"},
                                {key: "client", type: "string"},
                                {key: "project", type: "string"},
                                {key: "name", type: "string"},

                            ]}
                            stateArray={"Data"}
                            table={"SettingInvoiceAddonLabourDesignations"}
                            addFormText={"Designation"}
                            stepTitle={"Setup invoice designation"}

                            filterByCompany={true}
                            displayRules={[
                                {rowKey:"org",stateKey:"selectedCompany"},
                                {rowKey:"client",stateKey:"selectedClient"},
                                {rowKey:"project",stateKey:"selectedProject"},
                            ]}
                            extraFilters={[
                                {
                                    recordKey:"key",
                                    recordValue:"val",
                                    stateKey:"client",
                                    filterRecordKey:"client",
                                    filterStateKey:"selectedClient",
                                    allowBlank:true,
                                    name:"selectedClient",
                                    label:"Debtors",
                                    options: clientOptions,
                                    errorMsg: "Sorry you can't save, Please select your client!"
                                },
                                {
                                    recordKey:"key",
                                    recordValue:"val",
                                    stateKey:"project",
                                    filterRecordKey:"project",
                                    filterStateKey:"selectedProject",
                                    allowBlank:true,
                                    name:"selectedProject",
                                    label:"Project",
                                    options: projectOptions,
                                    errorMsg: "Sorry you can't save, Please select your project!"
                                },

                            ]}

                            sortMe={"name"}

                        />

                    </div>
                </div>
            </Page>
        )
    }

}

export default Connector(SetupLabourInvoiceDesignationsPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

