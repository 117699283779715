import React, {useState} from "react";
import "./dashbaord.css";
import {Col, DatePicker, Radio, Row} from 'antd';
import moment from "moment";

const SwitchPeriodInvoice = ({year, onChangePeriod, handleInput, mode, monthList}) => {
    const [value, setValue] = React.useState(1);
    const [selectPeriod, setSelectedPeriod] = useState(moment().format("YYYY-MM"));
    let listMonths = monthList; /*[
        {label:"JAN",period:1,selected:false},
        {label:"FEB",period:2,selected:false},
        {label:"MAR",period:3,selected:false},
        {label:"APR",period:4,selected:false},
        {label:"MAY",period:5,selected:false},
        {label:"JUN",period:6,selected:false},
        {label:"JUL",period:7,selected:false},
        {label:"AUG",period:8,selected:false},
        {label:"SEP",period:9,selected:false},
        {label:"OCT",period:10,selected:false},
        {label:"NOV",period:11,selected:false},
        {label:"DEC",period:12,selected:false},
    ]*/

    const onChange = e => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
        onChangePeriod(e.target.value);
    };
    const onChangeMode = e => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
        handleInput(e.target.value, "mode");
    };
    const onChangeYear = (e) => {
        let value = e.target.value;
        handleInput(value, "targetYear")
    }
    const getYearList = () => {
        return [
            "2021",
            "2022",
            "2023",
            "2024",
            "2025"
        ]
    }
    const innerOnchangePeriod = (value) => {
        onChangePeriod(value);
        setSelectedPeriod(value)
    }
    const renderPeriod = () => {
        return listMonths.map(row => {
            let extraLabel = {};
            let extraButton = {};
            console.log("renderPeriod > ", `${row.period}===${selectPeriod} > ${row.period === selectPeriod}`)
            if (row.period === selectPeriod) {
                extraLabel = {color: "red", fontWeight: "bold"};
                extraButton = {backgroundColor: "cornflowerblue"}
            }
            return (
                <div className={"spiPeriodContentBox"}>
                    <div className={"spiPeriodContentBoxLabel"} style={{...extraLabel}}>
                        {row.label}
                    </div>
                    <div
                        className={"spiPeriodContentBoxButton"}
                        style={{...extraButton}}
                        onClick={() => innerOnchangePeriod(row.period)}>
                        &nbsp;
                    </div>
                </div>
            )
        })
    }
    const renderPeriodIndex = () => {
        return listMonths.map(row => {
            let extraLabel = {};
            let extraButton = {};
            if (row.period === selectPeriod) {
                extraLabel = {color: "red", fontWeight: "bold"};
                extraButton = {backgroundColor: "cornflowerblue"}
            }
            return (
                <Col span={2} style={{minHeight:20,maxHeight:20}} onClick={() => innerOnchangePeriod(row.period)}>
                    <div className={"spiPeriodContentBoxLabel"} style={{...extraLabel,minWidth:"100%",maxWidth:"100%"}}>
                        {row.label}
                    </div>
                    {/*<div
                        className={"spiPeriodContentBoxButton"}
                        style={{...extraButton}}
                        onClick={() => innerOnchangePeriod(row.period)}>
                        &nbsp;
                    </div>*/}
                </Col>
            )
        })
    }
    const renderPeriodLabel= () => {
        return listMonths.map(row => {
            let extraLabel = {};
            let extraButton = {};
            if (row.period === selectPeriod) {
                extraLabel = {color: "red", fontWeight: "bold"};
                extraButton = {backgroundColor: "cornflowerblue"}
            }
            return (
                <Col span={2} style={{minHeight:20,maxHeight:20}} onClick={() => innerOnchangePeriod(row.period)}>
                    {/*<div className={"spiPeriodContentBoxLabel"} style={{...extraLabel}}>
                        {row.label}
                    </div>*/}
                    <div
                        className={"spiPeriodContentBoxButton2"}
                        style={{...extraButton,minHeight:20,maxHeight:20,minWidth:"100%",maxWidth:"100%"}}
                        onClick={() => innerOnchangePeriod(row.period)}>
                        &nbsp;
                    </div>
                </Col>
            )
        })
    }
    return (
        <div className={"spiContainer"}>
            <div className={"spiInvoiceDate"}>
                <div className={"spiInvoiceDateLabel"}>PERIOD TYPE:</div>
                <div className={"spiInvoiceDateSwitch"}>
                    <Radio.Group onChange={onChangeMode} value={mode}>
                        <Radio value={"define"}>Define</Radio>
                        <Radio value={"month"}>Month</Radio>
                        <Radio value={"YTD"}>YTD</Radio>
                        <Radio value={"finance-year"}>Financial</Radio>
                    </Radio.Group>
                </div>
            </div>

            <div className={"spiYearBox"}>
                <div className={"spiInvoiceDateLabel"}>Define:</div>
                <Row>
                    <Col span={6}>
                        {/*<span>Year:</span>*/}
                        <select onChange={(e) => onChangeYear(e)}>
                            {getYearList().map(item => {
                                let selected = false;
                                if (item === year) {
                                    selected = true;
                                }
                                return (
                                    <option value={item} selected={selected}>{item}</option>
                                )
                            })}
                        </select>
                    </Col>
                    <Col span={9}>
                        <div className={"datePicker"}>
                            {/*<span>Start:</span>*/}
                            <DatePicker
                                onChange={(date, dateString) => handleInput( dateString,"defineStartDate")}
                                placeholder={"Start Date"}
                            />
                        </div>
                    </Col>
                    <Col span={9}>
                        <div className={"datePicker"}>
                            {/*<span>End:</span>*/}
                            <DatePicker
                                onChange={(date, dateString) => handleInput( dateString,"defineEndDate",)}
                                placeholder={"End Date"}
                            />
                        </div>

                    </Col>
                </Row>

            </div>
            <Row style={{minWidth:"100%",maxWidth:"100%",marginTop:20}}>
                {renderPeriodIndex()}
            </Row>
            <Row style={{minWidth:"100%",maxWidth:"100%"}}>
                {renderPeriodLabel()}
            </Row>
            {/*<div className={"spiPeriodBox"}>


            </div>*/}
        </div>
    )
}

export default SwitchPeriodInvoice;