import React from "react";
import { Row, Col } from 'antd';
import { Input } from 'antd';
import { Checkbox ,Select} from 'antd';


export default (props)=>{
    let {_this}=props;
    function onChange(e) {
        console.log(`checked = ${e.target.checked}`);
    }
    let ls=[/*"First name","Second name","Phone number","Email address","Username","Password"*/];
    for(let i in _this.state.initialFields){
        const row=_this.state.initialFields[i];
        ls.push({
            ...row,
            index:i,
            cat:"init",
        });
    }
    for(let i in _this.state.otherFields){
        const row=_this.state.otherFields[i];
        ls.push({
            ...row,
            index:parseInt(i),
            cat:"other",
        });
    }

    const onChangeSet=(recIn,command,state)=>{
        console.log("onChangeUnique > ",command," > ",state," > ",recIn);
        let lsInit=[];
        let lsOther=[];

            for(let i in _this.state.initialFields){
                const row = _this.state.initialFields[i];
                if(parseInt(recIn.index)===parseInt(i) && recIn.cat==="init"){
                    let old =row[command];
                    console.log("onChangeUnique > * , > ",recIn.cat," > ",old," > ",state);
                    row[command]=state;
                }
                lsInit.push(row)
            }

            for(let i in _this.state.otherFields){
                const row = _this.state.otherFields[i];
                if(parseInt(recIn.index)===parseInt(i) && recIn.cat==="other"){
                    row[command]=state;
                }
                lsOther.push(row)
            }

        let tmp=_this.state;
        tmp.initialFields =lsInit;
        tmp.otherFields = lsOther;
        _this.setState(tmp);

        console.log("onChangeUnique > **lsInit , > ",lsInit);
        console.log("onChangeUnique > **lsOther , > ",lsOther);

    }

    const renderInput=(rec)=>{
        const keyName = rec.FieldName;
        return(
            <Row>
                <Col span={5} style={styles.firstRowCol1}>
                    {keyName}
                </Col>
                <Col span={2} style={styles.contentCenter}>
                    <Checkbox
                        checked={rec.Unique}
                        onChange={(value)=>onChangeSet(rec,"Unique",value.target.checked)}
                    />
                </Col>
                <Col span={2} style={styles.contentCenter}>
                    <Checkbox
                        checked={rec.Mandatory}
                        onChange={(value)=>onChangeSet(rec,"Mandatory",value.target.checked)}
                    />
                </Col>
                <Col span={2} style={styles.contentCenter}>
                    <Checkbox
                        checked={rec.Required}
                        onChange={(value)=>onChangeSet(rec,"Required",value.target.checked)}
                    />
                </Col>
                <Col span={2} style={styles.contentCenter}>
                    <Checkbox
                        checked={rec.DisplayTable}
                        onChange={(value)=>onChangeSet(rec,"DisplayTable",value.target.checked)}
                    />
                </Col>
                <Col span={2} style={styles.contentCenter}>
                    <Checkbox
                        checked={rec.DisplaySelection}
                        onChange={(value)=>onChangeSet(rec,"DisplaySelection",value.target.checked)}
                    />
                </Col>
                <Col span={2} style={styles.contentCenter}>
                    <Select
                        style={{minWidth:"100%",}}
                        value={rec.DataType}
                        defaultValue={rec.DataType}
                        onChange={(value)=>onChangeSet(rec,"DataType",value)}
                    >
                        <Select.Option value={"string"}>Text</Select.Option>
                        <Select.Option value={"float"}>Number</Select.Option>
                        <Select.Option value={"date"}>Date</Select.Option>
                        <Select.Option value={"list"}>List option</Select.Option>
                        <Select.Option value={"link"}>Associate</Select.Option>
                    </Select>
                </Col>
                <Col span={2} style={styles.contentCenter}>
                    <Input
                        value={rec.Position}
                        onChange={(value)=>onChangeSet(rec,"Position",value.target.value)}
                    />
                </Col>
                <Col span={2} style={styles.contentCenter}>
                    <Select
                        style={{minWidth:"100%",}}
                        value={rec.LinkEntity}
                        defaultValue={rec.LinkEntity}
                        onChange={(value)=>onChangeSet(rec,"LinkEntity",value)}
                    >
                        <Select.Option value={"Site"}>Site</Select.Option>
                        <Select.Option value={"Contract"}>Contract</Select.Option>
                        {_this.props._this.state.Entities.map((item)=>{
                            if(_this.props._this.state.selectedCompany!==item.org){
                                return null;
                            }
                            return(<Select.Option value={item.name}>{item.name}</Select.Option>)
                        })}
                    </Select>
                </Col>
                <Col span={2} style={styles.contentCenter}>
                    <Input
                        placeholder={"comma delimited"}
                        value={rec.Options}
                        onChange={(value)=>onChangeSet(rec,"Options",value.target.value)}
                    />
                </Col>
            </Row>
        )
    }
    return(
        <div>
            <Row>
                <Col span={4} style={styles.firstRowCol1}>
                    &nbsp;
                </Col>
                <Col span={2} style={styles.headerColor}>
                    Unique
                </Col>
                <Col span={2} style={styles.headerColor}>
                     Mandatory
                </Col>
                <Col span={2} style={styles.headerColor}>
                    Required
                </Col>
                <Col span={2} style={styles.headerColor}>
                    DisplayTable
                </Col>
                <Col span={2} style={styles.headerColor}>
                    DisplayPopUp
                </Col>
                <Col span={2} style={styles.headerColor}>
                    DataType
                </Col>
                <Col span={2} style={styles.headerColor}>
                    Position
                </Col>
                <Col span={2} style={styles.headerColor}>
                    Link
                </Col>
                <Col span={2} style={styles.headerColor}>
                    Options
                </Col>

            </Row>
            {ls.map((item)=>{
                return renderInput(item)
            })}
        </div>
    )
}

const styles={
    firstRowCol1:{
        textAlign:"right"
    },
    headerColor:{
        color:"orange",
        textAlign: "center"
    },
    contentCenter:{
        display:"flex",
        justifyContent:"center"
    }
}
