import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import FileJpgOutlined from "@ant-design/icons/lib/icons/FileJpgOutlined";
import React from "react";
import moment from "moment";


const Downloader = (props) => {
    let {linkDownload, name} = props;
    const onDownload = () => {
        let url = linkDownload;
        let xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = "Invoice_Labour_" + linkDownload;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove()
        };
        xhr.open('GET', url);
        xhr.send();
    }

    const docView = () => {
        let link = linkDownload;
        console.log("Opening link pdf link request > ", link);
        document.title = "Invoice Labour " + name;
        window.open(link, '_blank');
    }
    return (
        <>
            <DownloadOutlined
                style={styles.container}
                onClick={() => onDownload()}
            />
            |
            <FileJpgOutlined
                style={styles.container}
                onClick={() => docView()}
            />
            
        </>
    )
}

export default Downloader;

const styles={
    container:{
        width: 15,
        color: "#3880ff",
        cursor: "pointer",
        marginLeft:5,
        marginRight:5,
    }
}