import React, {Fragment} from 'react';
import IconButton from "@material-ui/core/IconButton";
import Badge from '@material-ui/core/Badge';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from 'material-table';
import AddIcon from '@material-ui/icons/Add';
import {makeStyles} from "@material-ui/core";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";

const _filefy = require("filefy");

//https://github.com/mbrn/material-table.com/blob/master/src/pages/docs/all-props/columns.md
//https://github.com/mbrn/material-table.com/blob/master/src/pages/docs/all-props/options.md

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,

    },
    tablecell: {
        fontSize: '30pt'
    },
    th:{
        backgroundColor:"red"
    }
}));

export const exportPDF = (data,columns) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "My Awesome Report";
    const headers = [["NAME", "PROFESSION"]];

    //const data = this.state.people.map(elt=> [elt.name, elt.profession]);

    let content = {
        startY: 50,
        head: headers,
        body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf")
}

function htmlEntities(inString) {

    if(typeof inString.type!=="undefined"){

        if(typeof inString.type.name!=="undefined"){
            if(inString.type.name==="Badge"){
                const count = inString.props.count;
                return count;
            }
        }
        if(typeof inString.props!=="undefined"){
            let values = inString.props.children;
            let str  = "";
            for(let i in values){
                const val=values[i];
                try{
                    str = str+val+" ";
                }catch (e){
                    str = str+" "+" ";
                }

            }
            str = str.replace("  "," ");
            str = str.trim();
            return str;
        }else{

        }
    }


    let str = inString.toString()
    return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}

const exportCsv = (allColumns, allData) => {
    const columns = allColumns.filter(columnDef => columnDef["export"] !== false);
    const exportedData = allData.map(rowData => columns.map(columnDef => htmlEntities(rowData[columnDef.field])));
    new _filefy.CsvBuilder('filename_' + moment().format('YYYY-MM-DDTHHmmss')+'.csv')
        .setDelimeter(',')
        .setColumns(columns.map(columnDef => columnDef.title))
        .addRows(exportedData)
        .exportFile();
}

const prepareData = (dataToPrepare) => {
    let record = {};
    for (let key in dataToPrepare) {
        const rock = dataToPrepare[key];
        if (key === "link") {
            record[key] = rock.props.src;
        } else {
            record[key] = rock
        }

    }
    return record;
}

const StyledBadge = withStyles(theme => ({
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
    title:{
        fontSize:12
    }
}))(Badge);

export default ({
                    onAddRecord,
                    tableTitle,
                    closeDownload,
                    openDownload,
                    openFilterForm,
                    columns,
                    data,
                    onDelete,
                    openUpload,
                    openDetail,
                    attr,
                    onClickOptions,
                    handleInputFilterParams,
                    otherFunctions,
                    options,
                    noAddButton,
                    moreButtons,
                    allowDelete,
                    allowedAdd
                }) => {
    if (typeof data === "undefined") {
        return null
    }

    let storeData = data;


    /**
     *
     * let adapt our columns
     */


    const findDataType = (ref) => {
        for (let i in columns) {
            const row = columns[i];
            if (row.field === ref) {
                if (ref === "link") {
                    return "image"
                } else {
                    return row.dataType;
                }
            }

        }
        return ""
    }

    let newColumns = [];

    for (let i in columns) {
        const row = columns[i];
        newColumns.push({
            title: row.label,
            field: row.id,
            cellStyle:row.cellStyle,
            headerStyle:row.headerStyle,
        })
    }
    columns = newColumns;
    let newData = [];
    let indexMe = 0;
    for (let i in storeData) {
        let row = storeData[i];

        //row = prepareData(row);
        //row.store = row;
        let newRow = {};
        for (let key in row) {
            let val = row[key];
            if (typeof val === "boolean") {
                // variable is a boolean
                val = val === true ? "Yes" : "No"
            }
            let ans = Array.isArray(val);
            if (ans) {
                val = (
                    <IconButton aria-label="cart" onClick={() => requestOpenFilter(row, val)}>
                        <StyledBadge
                            badgeContent={val.length} color="primary"
                        >
                            <FilterCenterFocusIcon/>
                        </StyledBadge>
                    </IconButton>
                )
            }

            const dataType = findDataType(key);

            if (dataType === "image") {
                val = <img
                    src={val}
                    alt={row.ref}
                    className="img-thumbnail"
                    width={50}
                    height={50}
                    style={{borderRadius: 5}}
                />
            }


            newRow[key] = val
        }
        newData.push(newRow);
        indexMe++;
    }


    data = newData;

    /**
     * let ajust our data to insert command
     *
     */

    let openFilter = false;
    let filterData = [];
    let filterRef = "---";
    const requestOpenFilter = (record, row) => {
        openFilter = true;
        filterData = row;
        filterRef = record.ref + " " + row.name;
        handleInputFilterParams("showFilterPopup", true);
        handleInputFilterParams("filterData", filterData);
        handleInputFilterParams("fitlerTitle", filterRef);
        handleInputFilterParams("selectedRecord", record);
    }

    const tableIcons={
        add:<AddIcon  />
    }



    let actions = [
        {
            icon: "add",
            tooltip: 'Add Data',
            isFreeAction: true,
            iconProps: {color: "primary"},
            onClick: () => onAddRecord(),
        },

    ];
    if (typeof noAddButton !== "undefined") {
        if(noAddButton){
            actions = []
        }
    }

    if (typeof allowedAdd !== "undefined") {
        if(!allowedAdd){
            actions = []
        }
    }

    if (typeof onDelete !== "undefined" && !allowDelete) {
        actions.push({
            icon: 'delete',
            tooltip: 'Delete Row',
            onClick: (event, rowData) => onDelete(prepareData(rowData)),
        })
    }
    console.log("table view openUpload > ",openUpload," > ",typeof openUpload !== "undefined")
    if (typeof openUpload !== "undefined") {
        actions.push({
            icon: 'cloud_upload',
            tooltip: 'Upload File',
            onClick: (event, rowData) => openUpload(prepareData(rowData)),
        })
    }
    if (typeof openDownload !== "undefined") {
        actions.push({
            icon: 'cloud_download',
            tooltip: 'Download File',
            onClick: (event, rowData) => openDownload(prepareData(rowData)),
        })
    }
    if (typeof openDetail !== "undefined") {
        actions.push({
            icon: 'list',
            tooltip: 'Detail View',
            isFreeAction: false,
            onClick: (event, rowData) => openDetail(prepareData(rowData)),
        })
    }
    if (typeof openFilterForm !== "undefined") {
        actions.push({
            icon: 'filter_center_focus',
            tooltip: 'Attach Filter',
            onClick: (event, rowData) => openDetail(prepareData(rowData)),
        })
    }


    /**
     * let add our others function
     *
     */
    if(typeof otherFunctions !=="undefined"){
        for(let i in otherFunctions){
            const row = otherFunctions[i];
            actions.push({
                icon: row.icon,
                tooltip: row.tooltip,
                isFreeAction: row.isFreeAction,
                color:typeof row.color!=="undefined"?row.color:"action",
                onClick: (event, rowData) => row.myAction(prepareData(rowData)),
            })
        }
    }

    const classes =useStyles();

    let opts={
        pageSize:5,
    }
    if(typeof options !=="undefined"){
        opts = options
    }

    return (
        <Fragment>
            <MaterialTable
                title={<span style={{fontSize:14}}>{tableTitle}</span>}
                columns={columns}
                data={newData}
                actions={actions}
                icons={tableIcons}
                className={classes}
                options={{...opts,
                    exportButton: true,
                    exportCsv,
                }}
            />

            {data.length === 0 ?
                <p className={"alert alert-info"}>Sorry data empty</p> : null
            }

        </Fragment>

    )


}

/*
editable={{
                onRowAdd: newData => onRowAdd(newData),
                onRowUpdate: (newData, oldData) =>onRowUpdate(newData, oldData),
                onRowDelete: oldData =>
                    new Promise(resolve => {
                        onDelete(oldData);
                        resolve();
                        return {  data };
                    }),
            }}
 */
