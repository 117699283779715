import React from "react";
import {Button} from "antd";
export default (props)=>{
    let {_this}=props;
    let {CsvDataValid ,CsvPureData}=_this.state;
    const onSubmitUpload=async ()=>{
       await _this.onSubmitUpload();
    }
    return(
        <div>
            <h1>Info</h1>
            <p>
                You are about to upload: {CsvDataValid.length} / {CsvPureData.length}
            </p>
            <p>If you are happy you can continue with you submit button bellow</p>
            <Button
                type={"success"}
                onClick={onSubmitUpload}
            >Submit Upload</Button>
        </div>
    )

}