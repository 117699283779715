import React from "react";
import {Layout} from 'antd';
import {useEffect, useState} from 'react';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './styles.css';
import Header from "./Header";
import SideMenu from "./SideMenu";
import BarInfo from "./BarInfo";
const {Content} = Layout;
function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}
export default (props) => {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    return (
        <Layout style={{}}>
            <Header {...props} />
            <Layout>
                <SideMenu {...props}/>
                <Layout style={{
                    padding: '0 24px 24px',
                    minHeight:windowSize.innerHeight-100
                }}>
                    <BarInfo {...props}/>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: windowSize.innerHeight-100,
                            backgroundColor:"inherit"
                        }}
                    >
                        {props.children}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}

