import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import ControllerSelectBox from "../../../../components/Common/ControllerInput/ControllerSelectBox";
import {
    ArrayToSelectOption,
    FilterArrayByKey,
    FormatMoneySouthAfrica,
    FormatMoneySouthAfrica2,
    UpcaseFirst
} from "../../../../api/fn";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import {PostToAny, PostToPbx} from "../../../../api/http-post";
import {Badge, Button, Col, DatePicker, Divider, Drawer, Input, List, Row} from "antd";
import moment from "moment";
import './styles.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCoffee, faList, faAngleDown, faCartArrowDown, faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons'
//https://www.w3schools.com/icons/fontawesome_icons_directional.asp
import statusGoodIcon from "../../../../assets/img/status-good.jpeg"
import statusBadIcon from "../../../../assets/img/status-bad.jpeg"
import {isDateBetween} from "../../../../api/date-functions";
import LoadingProcessing from "../../../../components/Common/page/Common/LoadingProcessing";
import VerificationIssue from "../../../../components/Reports/VerificationIssue";


const formatDate = (dateIn) => {
    return moment(dateIn, "YYYY-MM-DD").format("DD MMM")
}
const formatDay = (dateIn) => {
    return moment(dateIn, "YYYY-MM-DD").format("DDD")
}
const formatDate2 = (dateIn) => {
    return moment(dateIn).format("YYYY-MM-DD")
}
Date.prototype.getWeek = function () {
    let date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    let week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

function formatNumberWithSpaces(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}


class ServiceLogsCoctVerificationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Attributes: [],
            Projects: [],
            Periods: [],
            DataDesignation: [],
            Data: [],
            Sites: [],
            Clients: [],
            Items: [],
            Vehicles: [],
            Drivers: [],

            selectedCompany: "",
            selectedClient: "",
            selectedItem: "yes",
            selectedPeriod: "",
            selectedVehicle: "",
            selectedDriver: "",
            newEntry: {
                DeliveryNoteNumber: "",
                TimeInSettlement: "",
                TimeOutSettlement: "",
                TimeArrivedAtDisposed: "",
                TrailerRegistration: "",
                NumberOfUnits: "0"
            },
            base64String: "",
            fileName: "",
            sheetName: "2021",
            mode: "list",
            busy: false,
            Result: null,
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Projects", "", "", this, undefined, "common/entity/modules/list");
        //await fetchDataEntity("Data", "", "", this, undefined, "common/entity/ServiceLogsDeliveryNote/list");
        await fetchDataEntity("Sites", "", "", this, undefined, "common/entity/SettingInvoiceServiceAddonSite/list");
        await fetchDataEntity("Clients", "", "", this, undefined, "common/entity/SettingInvoiceServiceDebtors/list");
        await fetchDataEntity("Items", "", "", this, undefined, "common/entity/SettingInvoiceServiceItem/list");
        await fetchDataEntity("Vehicles", "", "", this, undefined, "common/entity/ServiceLogsVehicle/list");
        await fetchDataEntity("Drivers", "", "", this, undefined, "common/entity/ServiceLogsDriver/list");

    }

    handleChangeInput = async (key, value) => {
        this.setState({
            [key]: value,
        })
    }
    handleGetInputValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return ""
        }
        return this.state[key];
    }
    checkForNewEntry = (dis) => {
        const msg = (info) => {
            if (dis) {
                alert(`Please select ${info}`)
            }
        }
        if (this.state.selectedCompany === "") {
            msg("Company")
            return false
        }
        if (this.state.selectedItem === "") {
            msg("Item")
            return false
        }
        if (this.state.selectedClient === "") {
            msg("Client")
            return false
        }
        if (this.state.startDate === "") {
            msg("Start Date")
            return false
        }
        if (this.state.endDate === "") {
            msg("End Date")
            return false
        }
        if (this.state.base64String === "") {
            msg("File")
            return false
        }
        if (this.state.sheetName === "") {
            msg("Sheet Name")
            return false
        }
        return true

    }
    submitVerificationRequest = async () => {
        //todo
        if (!this.checkForNewEntry(true)) {
            return
        }
        this.setState({
            isLoading: true,
            Result: null,
        })


        let postData = {
            Org: this.state.selectedCompany,
            Client: this.state.selectedClient,
            Project: this.state.selectedItem,
            Base64String: this.state.base64String.split(",")[1],
            FileName: this.state.fileName,
            SheetName: this.state.sheetName,
            Start_Date: this.state.startDate,
            End_Date: this.state.endDate,
        }

        console.log("submitVerificationRequest send > ", postData)
        let endpoint = "/serviceverification/virification-coct"
        await PostToPbx(postData, endpoint, (res) => {
            console.log("submitVerificationRequest response > ", res)
            this.setState({
                isLoading: false,
            })
            if (res !== null) {
                let myResult = res.RESULT;
                this.setState({
                    Result: myResult,
                })
            }
            console.log(res)
        })

    }
    handleInputFile = (e) => {
        let _this = this;
        let fName = `fileID1001`;
        let inputUpload = document.getElementById(fName);
        let myfile = inputUpload.files[0];
        if (!myfile) {
            console.log("No file selected.");
            return;
        }
        let reader = new FileReader();
        reader.readAsDataURL(myfile);
        reader.onload = function () {
            _this.setState({
                base64String: reader.result,
                fileName: myfile.name
            })
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    renderCompanyBox = () => {
        let ls = [];
        ls = FilterArrayByKey(this.state.Companies, {})
        ls = ArrayToSelectOption(ls, ["CustomerNumber"], ["Name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Company"}
                name={"selectedCompany"}
            />
        )
    }
    renderClientBox = () => {
        let ls = [];
        for (let i in this.state.Clients) {
            let row = this.state.Clients[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            ls.push(row)
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["name"], ["name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Client"}
                name={"selectedClient"}
            />
        )
    }
    renderItemBox = () => {
        let ls = [];
        for (let i in this.state.Items) {
            let row = this.state.Items[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.client !== this.state.selectedClient) {
                continue
            }
            ls.push(row)
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["name"], ["name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Items"}
                name={"selectedItem"}
            />
        )
    }

    RenderListDetailSummary = () => {
        let result = this.state.Result;
        if (result == null) {
            return null
        }

        const data = [
            {
                title: 'Number of service from CoCT logs',
                count: result.TotalClientService, // Example count for badge
            },
            {
                title: 'Number of service from CMIS logs',
                count: result.TotalActualService, // Example count for badge
            },
            {
                title: 'Total missing from CoCT logs',
                count: result.TotalIssues, // Example count for badge
            },
        ];

        console.log("RenderListResultBudge >>> ", data)

        return (
            <div className="listDetail">
                <h3 className={"listTitle"}>Summary of verification</h3>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={" " + item.title}
                                description=" Number of service"
                            />
                            <div>
                            <span style={{
                                fontWeight: "bold",
                                fontSize: 18,
                                color: "#FF6359",
                                marginRight: 20
                            }}>
                                {formatNumberWithSpaces(item.count)}
                            </span>
                                {/*<Badge count={item.count} overflowCount={10000} />*/}
                            </div>
                        </List.Item>
                    )}
                />
            </div>
        );
    }
    renderListDetail = () => {
        console.log("renderListDetail> ", this.state.Result)
        if (this.state.Result == null) {
            return null
        }
        return (
            <div className="listDetail">
                <h3 className={"listTitle"}>Issues not matches</h3>
                <VerificationIssue data={this.state.Result.IssueSummary}/>
            </div>

        )
    }

    render() {

        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Service Logs Verification CoCT</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <Row gutter={18}>
                            <Col span={12}>
                                {this.renderCompanyBox()}
                            </Col>
                            <Col span={12}>
                                {this.renderClientBox()}
                            </Col>
                        </Row>
                        <Row gutter={18} style={{marginTop: -30}}>
                            <Col span={12}>
                                {this.renderItemBox()}
                            </Col>
                            <Col span={12} style={{...styles.dateRow, marginTop: 30, height: 40}}>
                                <Col span={12}>
                                    <DatePicker
                                        style={styles.datePickerBox}
                                        onChange={(date, dateString) => this.setState({startDate: dateString})}
                                        placeholder={"Target Start Date"}
                                    />
                                </Col>
                                <Col span={12}>
                                    <DatePicker
                                        style={styles.datePickerBox}
                                        onChange={(date, dateString) => this.setState({endDate: dateString})}
                                        placeholder={"Target End Date"}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 0, marginLeft: 10}}>
                            <Col span={24}>
                                <Input type={"file"} accept=".xls,.xlsx" onChange={this.handleInputFile}
                                       id={"fileID1001"}/>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 10, marginLeft: 10}}>
                            <Col span={24}>
                                <Input
                                    type={"text"}
                                    onChange={(e) => this.handleChangeInput("sheetName", e.target.value)}
                                    placeholder={"Sheet Name"}
                                    value={this.state.sheetName}
                                />
                            </Col>
                        </Row>


                        <Divider orientation="right" style={{color: "#3880ff"}}>
                            {this.checkForNewEntry(false) ?
                                <a onClick={this.submitVerificationRequest}>Submit Verification</a>
                                : <h3 style={{color: "red"}}>Please select the organisation and file to upload.</h3>
                            }
                        </Divider>

                        <Row >
                            <Col span={24} style={{
                                backgroundColor:"white"
                            }}>
                                {this.RenderListDetailSummary()}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                {this.renderListDetail()}
                            </Col>
                        </Row>

                    </div>

                    <LoadingProcessing open={this.state.busy}/>
                </div>
            </Page>
        )
    }

}

export default Connector(ServiceLogsCoctVerificationPage);
const styles = {
    btnExport: {
        fontsize: 11,
        width: 75,
        marginTop: 1,
        backgroundColor: "gray",
        color: "white"
    },
    boxFilterInner: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 5,
        borderRight: "1px solid black",
        fontsize: 14,
        fontWeight: "bold"
    },
    boxFilter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    dateRow: {
        display: "flex",
        flexDirection: "row"
    },
    datePickerBox: {
        height: 40,
        width: "100%"
    },
    row1Th: {
        border: "1px solid black",
    },
    rowForm: {
        marginBottom: 10,
        paddingBottom: 5,
        border: "0px solid lightgray",
        borderBottomWidth: 1,

    },
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}