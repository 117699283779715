import React from "react";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import {Layout, Select, Input} from "antd";
import {GetUserToken} from "../../../../api/token";
import PluginAddDeleteList from "../../../../components/Common/PluginAddDeleteList2";
import {PostToPbx} from "../../../../api/http-post";
import {fetchDataEntity} from "../../../../api/entity-data";

const {TextArea} = Input;
const {Content} = Layout;
const {Option} = Select;

class InvoiceSetupSitePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Modules: [],
            Clients: [],
            Items:[],
            isLoading: false,
            company: "",
            component: "list",
            newEntry: {},
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await this.loadData("modules", "Modules");
        await this.loadData("SettingInvoiceServiceDebtors", "Clients");
        await this.loadData("SettingInvoiceServiceItem", "Items");
    }
    loadData = async (targetEntity, stateKey) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            await _this.setState({
                [stateKey]: data.RESULT
            })
        });
    }

    getModuleList = () => {
        let ls = [];
        for (let i in this.state.Modules) {
            let row = this.state.Modules[i];
            if (row.org === this.state.newEntry.org) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    getCompanyList = () => {
        let ls = [];
        for (let i in this.state.Companies) {
            let row = this.state.Companies[i];
            ls.push({
                key: row.CustomerNumber,
                val: row.Name,
            });
        }
        return ls;
    }

    getCircleList = () => {
        let ls = [];
        ls.push({
            key: "week",
            val: "Week",
        });
        ls.push({
            key: "month",
            val: "Month",
        });

        return ls;
    }

    getYesNoOption = () => {
        return [
            {key: "yes", val: "Yes"},
            {key: "no", val: "No"},
        ]
    }
    getClientOption=()=>{
        let ls = [];
        for (let i in this.state.Clients) {
            let row = this.state.Clients[i];
            console.log(`(((:-> ${row.org}==${this.state.newEntry.selectedCompany} > `,row.org === this.state.newEntry.selectedCompany,this.state.newEntry)
           if (row.org === this.state.newEntry.selectedCompany) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    getItemOption=()=>{
        let ls = [];
        for (let i in this.state.Items) {
            let row = this.state.Items[i];
            if (row.org === this.state.newEntry.selectedCompany && row.client===this.state.newEntry.selectedClient) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }

    render() {

        const companyOptions = this.getCompanyList();
        const clientOptions = this.getClientOption();
        const itemOptions = this.getItemOption();
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Setup Site</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"SettingInvoiceServiceAddonSite"}
                            depending={{
                                "org": {
                                    targetKey: "CustomerNumber",
                                    displayKey: "Name",
                                    storageTable: "Companies"
                                }
                            }}
                            fields={[
                                {
                                    name: "org",
                                    dataType: "option",
                                    required: true,
                                    label: "Company",
                                    options: companyOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Companies"}
                                },
                                {
                                    name: "client",
                                    dataType: "option",
                                    required: true,
                                    label: "Debtor",
                                    options: clientOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Companies"}
                                },
                                {
                                    name: "item",
                                    dataType: "option",
                                    required: true,
                                    label: "Item | Job description",
                                    options: itemOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Companies"}
                                },

                                {
                                    name: "site",
                                    dataType: "string",
                                    required: true,
                                    label: "Site name",
                                    options: []
                                },
                                {
                                    name: "suburb",
                                    dataType: "string",
                                    required: true,
                                    label: "Suburb",
                                    options: []
                                },
                                {
                                    name: "quantity",
                                    dataType: "float",
                                    required: true,
                                    label: "Quantity unit",
                                    options: []
                                },
                                {
                                    name: "rate",
                                    dataType: "float",
                                    required: true,
                                    label: "Rate",
                                    options: []
                                },
                                {
                                    name: "serviceperweek",
                                    dataType: "float",
                                    required: true,
                                    label: "Service Per Week",
                                    options: []
                                },
                                {
                                    name: "mon",
                                    dataType: "boolean",
                                    required: true,
                                    label: "Monday",
                                    options: this.getYesNoOption()
                                },
                                {
                                    name: "tue",
                                    dataType: "boolean",
                                    required: true,
                                    label: "Tuesday",
                                    options: this.getYesNoOption()
                                },
                                {
                                    name: "wed",
                                    dataType: "boolean",
                                    required: true,
                                    label: "Wednesday",
                                    options: this.getYesNoOption()
                                },
                                {
                                    name: "thu",
                                    dataType: "boolean",
                                    required: true,
                                    label: "Thursday",
                                    options: this.getYesNoOption()
                                },
                                {
                                    name: "fri",
                                    dataType: "boolean",
                                    required: true,
                                    label: "Friday",
                                    options: this.getYesNoOption()
                                },
                                {
                                    name: "sat",
                                    dataType: "boolean",
                                    required: true,
                                    label: "Saturday",
                                    options: this.getYesNoOption()
                                },
                                {
                                    name: "sun",
                                    dataType: "boolean",
                                    required: true,
                                    label: "Sunday",
                                    options: this.getYesNoOption()
                                },

                            ]}
                            deleteKeys={[
                                {key: "org", type: "string"},
                                {key: "client", type: "string"},
                                {key: "item", type: "string"},
                                {key: "site", type: "string"},
                            ]}
                            stateArray={"Data"}
                            table={"SettingInvoiceServiceAddonSite"}
                            addFormText={"Attribute Site"}
                            stepTitle={"Set Up new site"}

                            filterByCompany={true}
                            displayRules={[
                                {rowKey:"org",stateKey:"selectedCompany"},
                                {rowKey:"client",stateKey:"selectedClient"},
                                {rowKey:"item",stateKey:"selectedItem"}
                            ]}
                            extraFilters={[
                                {
                                    recordKey:"key",
                                    recordValue:"val",
                                    stateKey:"client",
                                    filterRecordKey:"client",
                                    filterStateKey:"selectedClient",
                                    allowBlank:true,
                                    name:"selectedClient",
                                    label:"Debtor",
                                    options: clientOptions,
                                    errorMsg: "Sorry you can't save, Please select your client!"
                                },
                                {
                                    recordKey:"key",
                                    recordValue:"val",
                                    stateKey:"item",
                                    filterRecordKey:"item",
                                    filterStateKey:"selectedItem",
                                    allowBlank:true,
                                    name:"selectedItem",
                                    label:"Item | Job description",
                                    options: itemOptions,
                                    errorMsg: "Sorry you can't save, Please select your client!"
                                },
                            ]}


                        />

                    </div>
                </div>
            </Page>
        )
    }

}

export default Connector(InvoiceSetupSitePage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

