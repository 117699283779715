import React from "react";
import "./dashbaord.css";
import {FormatMoneySouthAfrica} from "../../../api/fn";
const DashPaid  =({amount,icon,footerText,prefix,suffix,colorSchema,extraBold,invoices})=>{
    let newAmount = FormatMoneySouthAfrica(amount);
    if(footerText==="Fully Paid invoices"||footerText==="Overdue invoices"||footerText==="Current invoices"){
        newAmount=parseInt(amount)
    }
    const onClickDash=()=>{
        if(footerText!=="undefined"){
            let msg='';
            for(let i in invoices){
                let row = invoices[i];
                let amount = row.invoiceamount;
                let inv =row.invoicenumber;
                let client = row.client;
                msg +=`\n\nInvoice: ${inv}  Amount: R ${FormatMoneySouthAfrica(amount)} | ${client}`
            }
            alert(msg)
        }
    }
    const haveInvoices=()=>{
        console.log(footerText," :)  haveInvoices > ",typeof invoices!=="undefined"," > ",invoices)
        if(typeof invoices!=="undefined"){
            return "dashPointer"
        }
        return ""
    }
    return(
        <div className={"dashPaidContainer "+haveInvoices()} onClick={()=>onClickDash()}>
            <div className={"dashPaidIcon"}>
                <img className={"PaidIcon1"} src={icon} alt={footerText}  width="57" height="57" />
            </div>
            <div className={"dashPaidAmount"} style={{...extraBold}}>{prefix}{newAmount}{suffix}</div>
            <div className={"dashPaidFooterText"} style={{color:colorSchema}}>{footerText}</div>
        </div>
    )
}

export default DashPaid;