import React, {useState} from "react";
import {Button, Col, Drawer, Input, Row, Space, Table, Typography} from "antd";
import DisplayDataLabourOverwriteRules from "./DisplayDataLabourOverwriteRules";


export default ({selectedCompany, selectedClient, selectedItem, sites, onFinish, onClose, visible}) => {
    const [InputSite, setInputSite] = useState("")
    const [InputRole, setInputRole] = useState("")
    const [InputEmployee, setInputEmployee] = useState("")
    const [InputNormal, setInputNormal] = useState("")
    const [InputSaturday, setInputSaturday] = useState("")
    const [InputSunday, setInputSunday] = useState("")
    const [InputPh, setInputPh] = useState("")

    const [DataRules,setDataRules]=useState([])
    const roles=["General Worker","Team Leader"]

    const onDone=()=>{
        onFinish(DataRules)
    }
    const addRule = () => {
        let record={
            Site:InputSite,
            Role:InputRole,
            NumberOfEmployees:parseInt(InputEmployee),
            NormalDayWorked:parseInt(InputNormal),
            SaturdayWorked:parseInt(InputSaturday),
            SundayWorked:parseInt(InputSunday),
            PhWorked:parseInt(InputPh)
        }
        let arr = [...DataRules,record]
        setDataRules(arr)
        setInputSite("")
        setInputEmployee("")
        setInputNormal("")
        setInputSaturday("")
        setInputPh("")
    }
    const handleDelete=(record)=>{
        console.log("handleDelete > ",record)
        let data = [];
        for(let i in DataRules){
            let rec = DataRules[i];
            if(JSON.stringify(rec)===JSON.stringify(record)){
                continue
            }
            /*if(rec.Site===record.Site && rec.Role===record.Role ){
                continue
            }*/
            data.push(rec)
        }
        setDataRules(data)
    }
    const RenderFormInput = () => {
        let inputs = [
            {label: "Site", onChange: setInputSite, controller: "option", value: InputSite, options: sites},
            {label: "Role", onChange: setInputRole, controller: "option", value: InputRole, options: roles},
            {label: "Emp", onChange: setInputEmployee, controller: "input", value: InputEmployee},
            {label: "Normal", onChange: setInputNormal, controller: "input", value: InputNormal},
            {label: "Sat", onChange: setInputSaturday, controller: "input", value: InputSaturday},
            {label: "Sun", onChange: setInputSunday, controller: "input", value: InputSunday},
            {label: "Phd", onChange: setInputPh, controller: "input", value: InputPh},
        ]

        return (
            <div>
                    {inputs.map((item, index) => {
                        if (item.controller === "option") {
                            return (
                                <Row>

                                    <Col span={24}>
                                        <Typography.Title level={5}>{item.label}</Typography.Title>
                                        <select
                                            className={"ant-input"}
                                            value={item.value}
                                            onChange={(e) => item.onChange(e.target.value)}
                                        >
                                            <option value={""}>--Select--</option>
                                            {item.options.map(op => {
                                                return (
                                                    <option value={op}>{op}</option>
                                                )
                                            })}
                                        </select>
                                    </Col>
                               </Row>
                            )
                        }
                        return (
                            <Row>
                                <Col span={24}>
                                    <Typography.Title level={5}>{item.label}</Typography.Title>
                                    <Input
                                        key={index + "#" + item.label}
                                        value={item.value}
                                        onChange={(e) => item.onChange(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        )
                    })}


                <Row>
                    <Col span={24}>
                        <Button onClick={()=>addRule()} style={{minWidth:"100%",border:"1px solid red",borderRadius:5}} type={"warning"}>
                            Add Rule
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }
    const RenderList=()=>{
        const columns = [
            {
                title: 'Site',
                dataIndex: 'Site',
                key: 'name',
            },
            {
                title: 'Role',
                dataIndex: 'Role',
                key: 'role',
            },
            {
                title: 'Employee',
                dataIndex: 'NumberOfEmployees',
                key: 'employee',
            },
            {
                title: 'Normal',
                dataIndex: 'NormalDayWorked',
                key: 'normal',
            },
            {
                title: 'Saturday',
                dataIndex: 'SaturdayWorked',
                key: 'saturday',
            },
            {
                title: 'Sunday',
                dataIndex: 'SundayWorked',
                key: 'sunday',
            },
            {
                title: 'Phd',
                dataIndex: 'PhWorked',
                key: 'phd',
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle">
                        <Button onClick={() => handleDelete(record)} danger>Delete</Button>
                    </Space>
                ),
            },
        ];

        // DisplayDataLabourOverwriteRules

        return (
            <DisplayDataLabourOverwriteRules
                DataRules={DataRules}
                handleDelete={handleDelete}
            />
        )
        return (
            <Table dataSource={DataRules} columns={columns} />
        )
    }
    return (
        <Drawer
            title={"labour overwrite  invoice rules"}
            width={720}
            onClose={onClose}
            visible={visible}
            bodyStyle={{paddingBottom: 80, overflow: "scroll"}}
            footer={
                <div
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={onClose} style={{marginRight: 8}}>
                        Cancel
                    </Button>
                    <Button onClick={()=>onDone()} type="primary">
                        Finish
                    </Button>
                </div>
            }
        >

            <div style={{marginTop: 20}}>
                <h3>New Rule</h3>
                {RenderFormInput()}
            </div>
                <div style={{marginTop: 20}}>
                    <h3>List of added rules</h3>
                    {RenderList()}
                </div>

        </Drawer>
)

}