import React from "react";
import {Drawer, Form, Button, Col, Row, Input, Select, DatePicker} from 'antd';
import {makeCol, SortData} from "../../api/fn";
import {SubmitPost, SubmitPost2} from "../web/Common/localFunctions";


export class DrawerEditForm extends React.Component{
    constructor(props) {
        super(props);
        const parentState = this.props._this.state;
        this.state={
            Attributes:parentState.Attributes,
            record: parentState.selectedRecord,
        }
    }
    handleChangeInput=(key,val)=>{
        let tmp=this.state;
        tmp.record.data[key] = val;
        this.setState(tmp);
    }
    onChangeDate=(date,dateString,fieldName)=>{
        this.handleChangeInput(fieldName,dateString)
    }
    getHandleChangeValKey=(key)=>{
        let tmp=this.state;
        let val="";


        if(typeof tmp.record.data[key] ==="undefined"){
            tmp.record.data[key] = val;
            //this.setState(tmp)
            return ""
        }

        console.log("):(-->getHandleChangeValKey > ",key," > ", tmp.record, " > ",typeof tmp.record.data[key] ==="undefined"," > ",tmp.record.data[key])
        return tmp.record.data[key];
    }
    reloadFunction=async ()=>{
        await this.props._this.loadInitialData();
        this.onClose()
    }
    onSubmit=async ()=>{
        const parentState = this.props._this.state;
        console.log("):(-->Original > ",parentState.selectedRecord.data)
        console.log("):(-->New entry > ",this.state.record.data)
        let hub = this.state.record;
        delete hub.mobile;
        delete hub.tableData;

        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = hub;
        post.Params = {}
        let endpoint = "/common/entity/databases/insert"; // "/manager/entity/insert/extensions";

        // let endpoint =this.props.postEndpoint; // "/manager/entity/insert/extensions";
        this.setState({
            isLoading: true,
        })
        await SubmitPost2(post, endpoint, "Thank your your record have been saved!", this,this.reloadFunction)
    }
    onClose=()=>{
        const {visibleKey,_this}=this.props;
        _this.setState({
            [visibleKey]:false,
        })
    }
    getAttributes=()=>{
        const parentState = this.props._this.state;
        let ls=[];
        let org=parentState.selectedRecord.org;
        let module = parentState.selectedRecord.module;
        for(let i in parentState.Attributes){
            let row=parentState.Attributes[i];
            if(row.org!==org){continue}
            if(row.module!==module){continue}
            ls.push(row)
        }
        ls = SortData("position",ls)
        return ls;
    }
    renderForm=()=>{

        const parentState = this.props._this.state;

        let _this = this;

        let attributes = this.getAttributes();
        let uniquekey = this.state.record.uniquekey;
        console.log("):(-->attributes > ",attributes)
        let ls = [];
        let loopIndex = 0;
        for (let i = 0; i < attributes.length; i += 2) {
            loopIndex = i;
            const row = attributes[i];
            let row2 = null;
            let nextIndex = i + 1;
            if (nextIndex < (attributes.length)) {
                row2 = attributes[nextIndex]
            }

            console.log("(::)row.mandatory > ",row.fieldname," > ",row.mandatory)

            ls.push(
                <Row gutter={16}>
                    {makeCol(row, _this,uniquekey)}
                    {makeCol(row2, _this,uniquekey)}
                </Row>
            );
        }
        return(
            <div style={{minWidth:"100%",maxWidth:"100%",marginTop:20}}>
                <Form layout="vertical" hideRequiredMark>
                    {ls.map(row=>{
                        return row;
                    })}
                </Form>
            </div>
        )
    }
    render() {
        const {visibleKey,title,_this}=this.props;
        const visible = _this.state[visibleKey]
        return(
            <>
                <Drawer
                    title={title}
                    width={720}
                    onClose={this.onClose}
                    visible={visible}
                    bodyStyle={{paddingBottom: 80,overflow:"scroll"}}
                    footer={
                        <div
                            style={{
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={this.onClose} style={{marginRight: 8}}>
                                Cancel
                            </Button>
                            <Button  onClick={this.onSubmit} type="primary">
                                Submit
                            </Button>
                        </div>
                    }
                >
                    {this.renderForm()}
                </Drawer>
            </>
        )
    }

}

