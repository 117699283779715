import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import ControllerSelectBox from "../../../../components/Common/ControllerInput/ControllerSelectBox";
import {ArrayToSelectOption, FilterArrayByKey,  UpcaseFirst} from "../../../../api/fn";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import {PostToPbx} from "../../../../api/http-post";
import {Button, Col, Row} from "antd";
import TableDataView from "../../../../components/Common/tables/TableDataView";

class ServiceContractorVehiclePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Attributes: [],
            Projects: [],
            Periods: [],
            DataDesignation: [],
            Data:[],
            Vehicles:[],
            Contractors:[],

            selectedCompany: "",
            selectedProject: "",
            selectedVehicle:"",
            selectedContractor:"",
            newEntry:{
                vehicle :"",
                contractor     :"",
            },

            mode: "list",
        }

    }
    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Projects", "", "", this, undefined, "common/entity/modules/list");
        await fetchDataEntity("Data", "", "", this, undefined, "common/entity/ServiceContractorVehicle/list");
        await fetchDataEntity("Vehicles", "", "", this, undefined, "common/entity/ServiceLogsVehicle/list");
        await fetchDataEntity("Contractors", "", "", this, undefined, "common/entity/ServiceContractor/list");


    }
    handleChangeInput = async (key, value) => {
        console.log("handleChangeInput > ", key, " > ", value)
        this.setState({
            [key]: value,
        })
    }
    handleGetInputValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return ""
        }
        return this.state[key];
    }
    onAddRecord=()=>{
        let state = this.state;
        if(state.selectedCompany===""){
            return alert("Please select your company!")
        }

        this.setState({mode:"form"})
    }
    onDeleteView = async (row) => {
        if(!window.confirm("Are you sure to delete this driver: "+row.name+"?")){
            return
        }
        let hub =[
            {key:"org",val:row.org,type:"string"},
            {key:"IdNumber",val:row.idnumber,type:"string"},
        ]

        let postData = {
            Params:hub,
        }
        console.log("onDeleteView send > ", hub)
        let endpoint = "/common/entity/ServiceContractorVehicle/delete"
        await PostToPbx(postData, endpoint, async (output) => {
            let data = output.RESULT;
            console.log("onDeleteView response > ", data)
            await fetchDataEntity("Data", "", "", this, undefined, "common/entity/ServiceContractorVehicle/list");
            this.setState({mode:"list"})
            alert("Thank you, your driver have been deleted!")
        });
    }
    submitRecord = async () => {
        let state = this.state;
        let entry = state.newEntry;
        if(!window.confirm("Are you sure to submit this record?")){
            return
        }
        let hub = {};
        hub.Org = state.selectedCompany;
        hub.Vehicle =state.selectedVehicle;
        hub.contractor = state.selectedContractor

        let postData = {
            Data:hub,
        }
        console.log("submitRecord send > ", hub)
        let endpoint = "/common/entity/ServiceContractorVehicle/insert"
        await PostToPbx(postData, endpoint, async (output) => {
            let data = output.RESULT;
            console.log("submitRecord response > ", data)
            await fetchDataEntity("Data", "", "", this, undefined, "common/entity/ServiceContractorVehicle/list");
            this.setState({mode:"list"})
            alert("Thank you, your sub contractor have been recorded!")
        });
    }
    renderCompanyBox = () => {
        let ls = [];
        ls = FilterArrayByKey(this.state.Companies, {})
        ls = ArrayToSelectOption(ls, ["CustomerNumber"], ["Name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Company"}
                name={"selectedCompany"}
            />
        )
    }
    renderList = () => {
        if(this.state.mode!=="list"){
            return null
        }
        let columns = [
            /*{id: 'identitytype', label: 'Identity Type', minWidth: 170, dataType: "string"},*/
            {id: 'vehicle', label: 'Vehicle', minWidth: 170, dataType: "string"},
            {id: 'contractor', label: 'Contractor', minWidth: 170, dataType: "string"},
        ];


        let ls=[];
        for(let i in this.state.Data){
            let row= this.state.Data[i];
            if(row.org!==this.state.selectedCompany){continue}
            /*if(row.project!==this.state.selectedProject){continue}*/
            ls.push(row)
        }
        let otherFunctions=[
            /*https://materializecss.com/icons.html*/
            /*{
                icon:"phonelink_lock",
                isFreeAction:false,
                tooltip:"Suspend Client",
                myAction:this.activateClient,
                color:"primary"
            }*/
        ]
        let data  = {columns: columns, data: ls}
        return (
            <div>
                {/*<h4 style={{color: "blue",}}>
                    <u>Setup Service Contractor </u>
                </h4>*/}
                <Row gutter={18}>
                    <Col span={24}>
                        <TableDataView
                            {...data}
                            tableTitle={UpcaseFirst(this.state.selectedProject) + " Records  (" + data.data.length + ") "}
                            onAddRecord={this.onAddRecord}
                            onDelete={this.onDeleteView}
                            openUpload={this.openUpload}
                            openDetail={this.openDetail}
                            closeDownload={this.closeDownload}
                            openDownload={this.openDownload}
                            otherFunctions={otherFunctions}
                            options={{
                                pageSize:20,
                            }}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
    renderForm = () => {
        if(this.state.mode!=="form"){
            return null
        }
        let entry = this.state.newEntry;
        const onChange=(key,val)=>{
            let tmp = this.state.newEntry;
            tmp[key] = val;
            this.setState({newEntry:tmp})
        }
        const onChange2=(key,val)=>{
            onChange(key,val)
        }
        const getValue=(name)=>{
            return this.state.newEntry[name]
        }
        const getVehicles=()=>{
            let ls =[];
            for(let i in this.state.Vehicles){
                let row =this.state.Vehicles[i];
                if(row.org!==this.state.selectedCompany){
                    continue
                }
                ls.push(row)
            }
            return ls
        }
        const getContractors=()=>{
            let ls =[];
            for(let i in this.state.Contractors){
                let row =this.state.Contractors[i];
                if(row.org!==this.state.selectedCompany){
                    continue
                }
                ls.push(row)
            }
            return ls
        }
        const innerRender=(label,value,key)=>{
            return(
                <Row gutter={18} style={styles.rowForm}>
                    <Col span={6}>
                        {label}
                    </Col>
                    <Col span={6}>
                        <input
                            className={"input"}
                            value={value}
                            onChange={(e)=>onChange(key,e.target.value)}
                            style={{minWidth:225}}
                        />
                    </Col>
                </Row>
            )
        }
        const innerRenderOption=(label,value,keyStore,display,data,stateKey)=>{
            let ls = ArrayToSelectOption(data, [keyStore], [display])

            return(
                <Row gutter={18} style={styles.rowForm}>
                    <Col span={6}>
                        {label}
                    </Col>
                    <Col span={6}>
                        <ControllerSelectBox
                            handleInput2={this.handleChangeInput}
                            getHandleValue={this.handleGetInputValue}
                            ls={ls}
                            label={label}
                            name={stateKey}
                        />
                    </Col>
                </Row>
            )
        }



        return (
            <div>
                <h4 style={{color: "blue",}}>
                    <u>NEW Contractor Vehicle Allocation</u>
                </h4>
                {innerRenderOption("Vehicle",entry.vehicle,"regnumber","regnumber",getVehicles(),"selectedVehicle")}
                {innerRenderOption("Contractor",entry.contractor,"name","name",getContractors(),"selectedContractor")}

                <Row gutter={18}>
                    <Col span={24}>
                        <Button
                            type={"primary"}
                            style={{
                                minWidth: "100%",
                                marginTop: 30,
                                minHeight: 40,
                                borderRadius: 15,
                                fontSize: 18,
                                fontWeight: "bold"
                            }}
                            onClick={() => this.submitRecord()}
                        >Add Contractor</Button>
                    </Col>
                </Row>
            </div>

        )
    }
    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Setup Sub Contractor Vehicle</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <Row gutter={18}>
                            <Col span={12}>
                                {this.renderCompanyBox()}
                            </Col>
                        </Row>
                        <div style={{
                            borderRadius: 5,
                            borderWidth: 1,
                            borderColor: "lightblue",
                            borderStyle: 'solid',
                            minHeight: 35,
                            padding: 5
                        }}>
                            {this.renderList()}
                            {this.renderForm()}
                        </div>

                    </div>
                </div>
            </Page>
        )
    }
}

export default Connector(ServiceContractorVehiclePage);
const styles = {
    rowForm:{
        marginBottom:10,
        paddingBottom:5,
        border:"0px solid lightgray",
        borderBottomWidth: 1,

    },
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}