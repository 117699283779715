import React from "react";
import {Col, DatePicker, Form, Input, Row, Select, Table} from 'antd';
import {Button,Modal} from 'antd';
import {PlusCircleFilled, SearchOutlined} from '@ant-design/icons';
import {PostToPbx} from "../../api/http-post";
import {GetUserToken} from "../../api/token";
import GenericComboBox from "../General/GenericComboBox";
import {SortData} from "../../api/fn";

import DrawerEditEntry from "../Common/DrawerEditForm2";
import moment from "moment";
import TableDataView from "./tables/TableDataView";

const {Option} = Select;
const {Search} = Input;

export default class PluginAddDeleteList2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Projects: [],
            component: "list",
            newEntry: {},
            isInAction: false,
            selectedRecord: null,
            selectedKeyName: "",
            selectedKeyValue: "",
            selectedFieldStructure: null,
            actionPerform: "",
            searchText: "",
            editMode:false,
            editRecord:null
        }
    }

    componentDidMount = async () => {
        await this.initializeData();
        await this.loadData()
    }
    initializeData = async () => {
        let userInfo = GetUserToken();
        let paramsProject = {};
        let paramsCompany = {};
        if (userInfo.Role !== "super") {
            paramsProject = [
                {key: "Org", val: userInfo.Org, type: "string"},
            ];
            paramsCompany = [
                {key: "CustomerNumber", val: userInfo.Org, type: "string"},
            ];
        }
        await this.fetchData("companies", "Companies", paramsCompany, null);
        await this.fetchData("modules", "Projects", paramsProject, null);
    }
    loadData = async () => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = this.props.targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            await _this.setState({
                Data: data.RESULT
            })
        });
    }
    fetchData = async (table, stateKey, params, feedback) => {
        let _this = this;
        let userInfo = GetUserToken();
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = params;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })
        await PostToPbx(post, endpoint, async (data) => {
            let res = [];
            if (data !== null) {
                res = data.RESULT;
            }
            await _this.setState({
                [stateKey]: res
            });
            console.log("fetchData response === > ", table, " > ", post, data);
            if (feedback !== null) {
                feedback(res)
            }
        });
    }
    handleChangeCompany = async (val, key) => {
        this.setState({
            [key]: val,
        });
        this.setParentState(key, val);
    }
    getHandleInput=(key)=>{
        if(typeof this.state.editRecord[key]!=="undefined"){
            return this.state.editRecord[key]
        }
        return "";
    }
    handleNewEntry = (e, key) => {
        const val = e.target.value;
        let tmp = this.state;
        tmp.newEntry[key] = val;
        this.setState(tmp);
        this.setParentState(key,val);
    }
    handleInputEdit=(val,key)=>{
        let record = this.state.editRecord;
        record[key] = val;
        let tmp = this.state;
        tmp.editRecord = record;
        this.setState(tmp)
    }
    handleChange2 = (e, ee, key) => {
        const val = e;
        let tmp = this.state;
        tmp.newEntry[key] = val;
        this.setState(tmp);
        this.setParentState(key,val);
    }
    handleChangeDate = (e, key) => {
        const val = e;
        let tmp = this.state;
        tmp.newEntry[key] = val;
        this.setState(tmp);
        this.setParentState(key,val);
    }
    setParentState=(key,val)=>{
        let tmp = this.props._this.state;
        tmp.newEntry[key] = val;
        this.props._this.setState(tmp);
        console.log("setParentState > ",this.props._this.state)
    }
    clickAdd = () => {
        let {fields} = this.props;
        let newEntry = {};
        for (let i in fields) {
            const row = fields[i];
            let key = row.name;
            newEntry[key] = "";
        }
        this.setState({
            component: "form",
            newEntry: newEntry
        });
    }
    cancelSave = async () => {
        this.setState({
            component: "list",
            newEntry: {},
        });
    }

    saveDataEdit=async ()=>{
        let { fields,table} = this.props;
        let self = this;

        const data = this.makeDataTypeEntry(fields, this.state.editRecord);

        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = data;
        post.Params = {}
        let endpoint = "/common/entity/" + table + "/insert"; // "/manager/entity/insert/extensions";
        // let endpoint =this.props.postEndpoint; // "/manager/entity/insert/extensions";
        this.setState({
            isLoading: true,
        })
        await PostToPbx(post, endpoint, async (data) => {
            await self.loadData()
            self.closeDrawer()
        });
    }
    saveDataSpecialLink=async (post)=>{
        let self = this;
        let endpoint = this.props.postEndpoint;
        // let endpoint =this.props.postEndpoint; // "/manager/entity/insert/extensions";
        this.setState({
            isLoading: true,
        })
        await PostToPbx(post, endpoint, async (data) => {
            await self.loadData()
        });
    }
    saveData = async () => {
        let userInfo = GetUserToken();
        let self = this;
        let {_this, stateArray, table, fields} = this.props;
        let tmp = _this.state;
        console.log("SaveData newEntry > ",this.state);
        tmp[stateArray].push(this.state.newEntry);
        _this.setState(tmp);
        this.setState({
            component: "list",
            newEntry: {},
        });
        let contract = this.props.selectContract;
        const data = this.makeDataTypeEntry(fields, this.state.newEntry);
        let hub = {...data};
        hub.Org = userInfo.Org;

        if(typeof this.props.postEndpoint!=="undefined"){
            return this.saveDataSpecialLink(hub)
        }

        console.log("saveData > ",hub)


        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = hub;
        post.Params = {}
        let endpoint = "/common/entity/" + table + "/insert"; // "/manager/entity/insert/extensions";
        // let endpoint =this.props.postEndpoint; // "/manager/entity/insert/extensions";
        this.setState({
            isLoading: true,
        })
        await PostToPbx(post, endpoint, async (data) => {
            await self.loadData()
        });
    }
    requestAction = (row, text) => {
        let targetRecord = {};
        let keyName = "";
        for (let i in this.state.Data) {
            const rec = this.state.Data[i];
            keyName = row.name;
            if (typeof rec[keyName] === "undefined") {
                continue
            }
            if (rec[keyName] !== text) {
                continue
            }
            targetRecord = rec
        }

        this.setState({
            isInAction: true,
            selectedRecord: targetRecord,
            selectedKeyName: keyName,
            selectedKeyValue: text,
            selectedFieldStructure: row,
        })
    }
    requestDeleteRecord=async (record)=>{
        let userInfo = GetUserToken();
        let self = this;
        let {_this, stateArray, table, fields} = this.props;
        const deleteKeys = this.props.deleteKeys;
        const msg = this.state.selectedKeyName.toLocaleUpperCase()+": "+this.state.selectedKeyValue.toLocaleUpperCase();
        if(!window.confirm("Are you sure to delete record - "+msg+" ?")){
            return
        }

        let params=[];
        for(let i in deleteKeys){
            const row=deleteKeys[i];
            const key  = row.key;
            const type = row.type;
            const val = record[key];
            params.push({
                key:key,
                type:type,
                val:val
            })
        }

        /**
         * let make our line data
         */

        const data = this.makeDataTypeEntry(fields, record);

        let hub =data // {...record};

        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = hub;
        post.Params = params
        post.Entity  = table;
        let endpoint = "/common/entity/" + table + "/delete"; // "/manager/entity/insert/extensions";
        // let endpoint =this.props.postEndpoint; // "/manager/entity/insert/extensions";
        this.setState({
            isLoading: true,
        })
        await PostToPbx(post, endpoint, async (data) => {
            await self.loadData();
            self.cancelActionPerform()
        });

    }
    requestEditRecord=(record)=>{
        console.log("ZZZZZYYYYYY=> ",record)
        this.setState({
            editMode:true,
            editRecord:record
        })
        this.cancelActionPerform()
    }
    closeDrawer=()=>{
        this.setState({
            editMode:false,
            editRecord:null
        })
    }
    cancelActionPerform = () => {
        this.setState({
            isInAction: false,
            selectedRecord: null,
            selectedKeyName: "",
            selectedKeyValue: "",
            selectedFieldStructure: null,
            actionPerform: "",
        })
    }

    makeDataTypeEntry = (fields, newEntry) => {
        const findDataInEntry = (fieldName) => {
            for (let i in fields) {
                const row = fields[i];
                if (row.name === fieldName) {
                    return row.dataType;
                }
            }
            return "string"
        }
        let ls = {};
        for (let i in newEntry) {
            const fDataType = findDataInEntry(i);
            let val = newEntry[i];
            if (fDataType === "float" || fDataType === "number") {
                val = parseFloat(val);
            } else if (fDataType === "boolean") {
                val = val === "yes"
            }
            ls[i] = val;
        }
        return ls;
    }
    getDataFromSearch=()=>{
        let ls = [];//this.state.Data;
        /**
         * let filter data
         * @type {string|string|*}
         */
        let filterCompanyField = "org";
        let filterProjectField = "module";
        if(typeof this.props.filterCompanyField!=="undefined"){
            filterCompanyField = this.props.filterCompanyField
        }
        if(typeof this.props.filterProjectField!=="undefined"){
            filterProjectField = this.props.filterProjectField
        }

        const parentState = this.props._this.state.newEntry;

        const combineFiled=(rowIn)=>{
            let info = this.props.combineInfo;
            let key = info.key;
            let field1 = info.field1;
            let field2=info.field2;
            let combiner = info.combiner;
            rowIn[key] = rowIn[field1]+`${combiner}`+rowIn[field2];
            return rowIn
        }

        for(let i in this.state.Data) {
            let row = this.state.Data[i];
            if(typeof this.props.combineInfo!=="undefined"){
                row = combineFiled(row)
                console.log("COMBINE INFO > ",row )
            }

            if(typeof this.props.filterByCompany!=="undefined"){
                if(!this.props.filterByCompany){continue}
                if(parentState["selectedCompany"]!==row[filterCompanyField]){continue}
            }
            if(typeof this.props.filterByModule!=="undefined"){
                if(!this.props.filterByModule){continue}
                if(parentState["selectedProject"]!==row[filterProjectField]){continue}
            }

            ls.push(row)
        }
        let textInput  = this.state.searchText;
        let newData = ls.filter(item => {
            const itemData = JSON.stringify(item).toLocaleLowerCase();
            const textData = textInput.toLocaleLowerCase();
            return itemData.indexOf(textData) > -1
        });
        return newData;
    }
    renderList = () => {
        let {fields, addFormText, stepTitle} = this.props;
        let data = this.getDataFromSearch();
        let columns = [
            /*{
                title: "View",
                dataIndex: "vData",
            }*/
        ];
        let columns2 = []
        const renderText=(text,name)=>{
            let val = text;
            if(typeof this.props.depending[name]!=="undefined"){
                let row = this.props.depending[name];
                let storage = this.props._this.state[row.storageTable];
                let targetKey = row.targetKey;
                let targetDisplay = row.displayKey;
                for(let i in storage){
                    let rec = storage[i];
                    if(rec[targetKey]===text){
                        return rec[targetDisplay];
                    }
                }
            }
            return val;
        }
        for (let i in fields) {
            const index = parseInt(i);
            const row = fields[i];
            let toShow=true;
            if(typeof row.displayList !=="undefined"){
                toShow =row.displayList;
            }

            if(!toShow){
                continue
            }
            if (index === 0) {
                columns.push({
                    title: row.label,
                    dataIndex: row.name,
                    render: text => <a onClick={() => this.requestAction(row, text)}>{renderText(text,row.name)}</a>,
                })
                columns2.push({
                    id: row.name.toLowerCase(), label: row.label, minWidth: 170, dataType: "string"
                })
            } else {
                columns.push({
                    title: row.label,
                    dataIndex: row.name,
                    align: 'right',
                })
                columns2.push({
                    id: row.name.toLowerCase(), label: row.label, minWidth: 170, dataType: "string"
                })
            }
        }


        let _this = this;
        const canDisplayRecord = (rowIn) => {
            if (typeof _this.props.displayRules === "undefined") {
                return true;
            }
            // {rowKey:"org",stateKey:"selectedCompany"}
            let data = _this.props.displayRules;
            for (let i in data) {
                let row = data[i];
                if (rowIn[row.rowKey] !== _this.state[row.stateKey]) {
                    return false;
                }
            }

            if (typeof _this.props.extraFilters !== "undefined") {
                for (let i in _this.props.extraFilters) {
                    let row = _this.props.extraFilters[i];
                    console.log(`|***> 0> ${row.allowBlank}`)
                    if (row.allowBlank) {
                        if (_this.state[row.filterStateKey] === "") {
                            return true
                        }
                    }

                    if (rowIn[row.filterRecordKey] !== _this.state[row.filterStateKey]) {
                        return false;
                    }
                }
            }

            return true;
        }

        const replaceBooleanValue=(rowIn)=>{
            let ls={};
            for(let i in rowIn){
                let val=rowIn[i];
                let isBool=false;
                for (let e in fields) {
                    const rec = fields[e];
                    if(rec.name===i){
                        if(rec.dataType==="boolean"){
                            isBool=true;
                        }
                    }
                }
                if(isBool){
                    val =val?"Yes":"No"
                }
                ls[i] = val;

            }
            return ls;
        }
        const combineFiled=(rowIn)=>{
            let info = this.props.combineInfo;
            let key = info.key;
            let field1 = info.field1;
            let field2=info.field2;
            let combiner = info.combiner;
            rowIn[key] = rowIn[field1]+`${combiner}`+rowIn[field2];
            return rowIn
        }

        let ls = [];
        for (let i in data) {
            let row = data[i];
            row = replaceBooleanValue(row);
            if(typeof this.props.combineInfo!=="undefined"){
                row = combineFiled(row)
                console.log("COMBINE INFO > ",row )
            }
            let index = parseInt(i);
            row.key = index;
            //row.vData = <a onClick={() => this.requestAction(row, "View")}>View</a>
            if (canDisplayRecord(row)) {
                ls.push(row);
            }
        }

        if(typeof this.props.sortMe!=="undefined"){
            let fieldToSort = this.props.sortMe;
            data =SortData(fieldToSort,data)

        }

        console.log("ls >>",ls)

        let otherFunctions=[
            /*https://materializecss.com/icons.html*/
            {
                icon:"edit",
                isFreeAction:false,
                tooltip:"Edit",
                myAction:this.requestEditRecord,
                color:"primary"
            }
        ]
        let dataTable = {columns: columns2, data: ls}



        return (
            <div style={{minWidth: "100%", padding: 5,overflow:"scroll"}}>

                <div style={{
                    selfAlign: "right",
                    minWidth: "100%",
                    maxWidth: "100%",
                    minHeight: 38,
                    /*maxHeight: 38,
                    flexDirection: "row",
                    justifyContent: "flex-end",*/
                    alignItems: "center",
                    padding: 0,
                    /*backgroundColor: "green",*/

                }}>

                    <Row>
                        <Col span={24}>
                            <div style={{float: "left", minWidth: "100%", maxWidth: "100%"}}>
                                {this.renderCompaniesSelection()}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div style={{float: "left", minWidth: "100%", maxWidth: "100%"}}>
                                {this.renderProjectSelection()}
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            {this.renderExtraFilter()}
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <div style={{float: "left", minWidth: "100%", maxWidth: "100%"}}>
                                <Search
                                    allowClear
                                    onChange={(e) => this.handleChangeCompany(e.target.value, "searchText")}
                                    style={{width: "90%"}}
                                    size="small"
                                    placeholder="small size"
                                    prefix={<SearchOutlined/>}
                                />
                            </div>
                        </Col>
                        <Col span={12} style={{display:"flex",justifyContent:"flex-end",flex:1}}>
                            <Button
                                type="link"
                                icon={<PlusCircleFilled/>}
                                onClick={() => this.clickAdd()}
                                style={{float: "right", borderRadius: 15}}
                            >
                                ADD NEW {addFormText}
                            </Button>
                        </Col>
                    </Row>

                </div>
                {/*<Table
                    columns={columns}
                    dataSource={ls}
                    bordered
                />*/}
                <TableDataView
                    {...dataTable}
                    tableTitle={ " Records  (" + ls.length + ") "}
                    onDelete={this.requestDeleteRecord}
                    otherFunctions={otherFunctions}
                    allowedAdd={false}
                    options={{
                        pageSize:20,
                    }}
                />
            </div>

        )

    }
    renderProjectSelection = () => {
        if (typeof this.props.filterByModule === "undefined") {
            return null
        } else {
            if (!this.props.filterByModule) {
                return null;
            }
        }
        let data = [];
        let company = this.state.selectedCompany;
        for (let i in this.state.Projects) {
            let row = this.state.Projects[i];
            console.log("&&&&--isMo> ", company, " > ", row.org)
            if (company === row.org) {
                data.push(row)
            }
        }
        return (
            <GenericComboBox
                displayConditionStateKey={this.state.component}
                displayConditionStateValue={"list"}
                data={data}
                label={"Select Project"}
                name={"selectedProject"}
                message={"Please select your project!"}
                handleChange={this.handleChangeCompany}
                getValue={this.state.selectedProject}
                recordOptionKey={"name"}
                recordOptionValue={"name"}
                recordOptionDisplay={"name"}
                noCondition={true}
            />
        )
    }
    renderCompaniesSelection = () => {
        if (typeof this.props.filterByCompany === "undefined") {
            return null
        } else {
            if (!this.props.filterByCompany) {
                return null;
            }
        }

        return (
            <GenericComboBox
                displayConditionStateKey={this.state.component}
                displayConditionStateValue={"list"}
                data={this.state.Companies}
                label={"Select Organization"}
                name={"selectedCompany"}
                message={"Please select your organization!"}
                handleChange={this.handleChangeCompany}
                getValue={this.state.selectedCompany}
                recordOptionKey={"customernumber"}
                recordOptionValue={"customernumber"}
                recordOptionDisplay={"name"}
                noCondition={true}
            />
        )
    }
    renderExtraFilter = () => {
        /**
         *
         extraFilters={[
                                {
                                    name:"selectedActivity",
                                    label:"Activity",
                                    options:this.getActivitiesOptions(),
                                    errorMsg: "Sorry you can't save, Please select your Activity!"
                                }
                            ]}
         *
         */
        if (typeof this.props.extraFilters === "undefined") {
            return null
        }

        return this.props.extraFilters.map(row => {
            return (
                <div style={{float: "left", minWidth: "100%", maxWidth: "100%"}}>
                    <GenericComboBox
                        displayConditionStateKey={this.state.component}
                        displayConditionStateValue={"list"}
                        data={row.options}
                        label={row.label}
                        name={row.name}
                        message={row.errorMsg}
                        handleChange={this.handleChangeCompany}
                        getValue={this.state[row.name]}
                        recordOptionKey={row.recordKey}
                        recordOptionValue={row.recordValue}
                        recordOptionDisplay={row.recordValue}
                        noCondition={true}
                    />
                </div>
            )
        })
    }
    renderForm = () => {
        let {fields, stepTitle} = this.props;

        return (
            <EntryForm
                fields={fields}
                numSpan={11}
                saveData={this.saveData}
                cancelSave={this.cancelSave}
                handleChange={this.handleNewEntry}
                handleChange2={this.handleChange2}
                handleChangeDate={this.handleChangeDate}
                state={this.state}
                stepTitle={stepTitle}
            />
        )

    }
    renderRequestActionPopup = () => {
        if (!this.state.isInAction) {
            return
        }
        let _this = this;
        const renderP = (key, val) => {
            return (
                <p>{key} : {val}</p>
            )
        }
        const avoidKeys = (keyIn) => {
            let arr = ["id", "appname", "org", "profile", "status", "orgdatetime","key"];
            for (let i in arr) {
                const item = arr[i];
                if (item === keyIn) {
                    return false
                }
            }
            return true
        }
        let visible = this.state.isInAction;
        let record = this.state.selectedRecord;
        let lsData = [];
        for (let i in record) {
            if (!avoidKeys(i)) {
                continue
            }
            const val = record[i];
            lsData.push({
                key: i,
                val: val,
            })
        }

        const  titleForm ="Action Perform " +this.state.selectedKeyName.toLocaleUpperCase()+": "+this.state.selectedKeyValue.toLocaleUpperCase();
        return (
            <Modal
                title={titleForm}
                visible={visible}
                onOk={this.cancelActionPerform}
                onCancel={this.cancelActionPerform}
            >
                {lsData.map(rec => {
                    return renderP(rec.key, rec.val)
                })}
                <React.Fragment>
                    <br/>
                    <Button
                        danger={true}
                        onClick={()=>this.requestDeleteRecord(record)}
                    >Delete Record</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                        type={"dashed"}
                        onClick={()=>this.requestEditRecord(record)}
                    >Edit Record</Button>&nbsp;&nbsp;

                </React.Fragment>
            </Modal>
        )
    }
    renderDrawerEdit=()=>{
        let {fields, stepTitle} = this.props;
        if(!this.state.editMode){
            return null
        }
        return(
            <DrawerEditEntry
                visible={this.state.editMode}
                attributes={fields}
                record={this.state.editRecord}
                closeDrawer={this.closeDrawer}
                getHandleInput={this.getHandleInput}
                handleInput={this.handleInputEdit}
                onSubmitRecord={this.saveDataEdit}
            />
        )
    }
    render() {
        return (
            <div style={{minWidth: "100%", maxWidth: "100%"}}>

                {this.state.component === "list" ? this.renderList() : this.renderForm()}
                {this.renderRequestActionPopup()}
                {this.renderDrawerEdit()}
            </div>
        )
    }
}

export const EntryForm = (props) => {

    let {fields, numSpan, saveData, handleChange, cancelSave, state, handleChange2, stepTitle,handleChangeDate} = props;
    let numberCol = 24 / numSpan;
    numberCol = parseInt(numberCol);
    let arrCol = [];
    for (let i = 0; i < numberCol; i++) {
        let n = i + 1
        arrCol.push(n);
    }

    let filterFields=[];
    for(let i in fields){
        let row=fields[i];
        if(typeof row.formEntry!=="undefined"){
            if(!row.formEntry){
                continue
            }
        }
        filterFields.push(row)
    }
    return (

        <Form
            layout="vertical"
            style={{
            }}
        >
            <Row>
            <h3 style={{
                float: "left",
                color:"red",
                marginTop: 10,
                fontSize: 18,
                marginLeft: 10,
            }}>{stepTitle}</h3>
            </Row>
            <Row>
                {filterFields.map((row) => {
                    if (row.dataType === "option" || row.dataType === "boolean") {
                        return (
                            <Col style={styles.gutterBox} span={numSpan}>
                                <Form.Item label={row.label} required={row.required}>
                                    <Select
                                        name={row.name}
                                        defaultValue={state.newEntry[row.name]}
                                        value={state.newEntry[row.name]}
                                        onChange={(e, ee) => handleChange2(e, ee, row.name)}
                                    >
                                        {row.options.map((record, index) => {
                                            return (
                                                <Option
                                                    value={record.key}
                                                >{record.val}</Option >
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )
                    }
                    if (row.dataType === "date"){
                        let myDefaultDate = moment();
                        return (
                            <Col style={styles.gutterBox} span={numSpan}>
                                <Form.Item label={row.label} required={row.required}>
                                    <DatePicker
                                        format="YYYY-MM-DD"
                                        onChange={(date, dateString) => handleChangeDate( dateString, row.name)}
                                        defaultValue={myDefaultDate}
                                        style={{minWidth: "100%"}}
                                    />
                                    {/*<Input
                                        name={row.name}
                                        placeholder={"input " + row.label}
                                        onChange={(e) => handleChange(e, row.name)}
                                    />*/}
                                </Form.Item>
                            </Col>
                        )
                    }
                    return (
                        <Col style={styles.gutterBox} span={numSpan}>
                            <Form.Item label={row.label} required={row.required}>
                                <Input
                                    name={row.name}
                                    placeholder={"input " + row.label}
                                    onChange={(e) => handleChange(e, row.name)}
                                />
                            </Form.Item>
                        </Col>
                    )
                })}
            </Row>
            <Row>
                {arrCol.map(index => {
                    if (index !== numberCol) {
                        return (
                            <Col style={styles.gutterBox} span={numSpan}>&nbsp;</Col>
                        )
                    }
                    return (
                        <Col
                            style={{justifyContent: "flex-end", display: "flex", padding: 10, paddingRight: -10}}
                            span={numSpan}
                        >
                            <Button onClick={() => cancelSave()}>Cancel</Button>&nbsp;&nbsp;
                            <Button onClick={() => saveData()} danger>Save</Button>
                        </Col>
                    )
                })}
            </Row>
        </Form>

    )

}

const styles = {
    gutterBox: {
        padding: "8px 0",
        margin: 10,
        /*backgroundColor: "#00a0e9",
        minWidth: "50%",
        maxWidth: "50%"*/
    },
    stepsContent: {
        marginTop: 16,
        border: "1px dashed #e9e9e9",
        borderRadius: 2,
        backgroundColor: "#fafafa",
        minHeight: 200,
        textAlign: "center",
        paddingTop: 80,
    },
    stepsAction: {
        marginTop: 24,
    }
}
