

export const ArrayFieldContact=[
    {FieldName:"Phone number",DataType:"string"},
    {FieldName:"Email address",DataType:"string"},
];
export const ArrayFieldTitle=[
    {FieldName:"Name",DataType:"string"},
    {FieldName:"Description",DataType:"string"},
];
export const ArrayFieldIdentity=[
    {FieldName:"Id number",DataType:"string"},
    {FieldName:"Passport",DataType:"string"},
];
export const ArrayFieldName=[
    {FieldName:"First name",DataType:"string"},
    {FieldName:"Second name",DataType:"string"},
];
export const ArrayFieldLogin=[
    {FieldName:"Username",DataType:"string"},
    {FieldName:"Password",DataType:"string"},
];
export const ArrayFieldVehicle=[
    {FieldName:"Brand",DataType:"string"},
    {FieldName:"Model",DataType:"string"},
    {FieldName:"Year",DataType:"string"},
    {FieldName:"Mileage",DataType:"float"},
];
export const ArrayFieldGeolocation=[
    {FieldName:"Longitude",DataType:"float"},
    {FieldName:"Latitude",DataType:"float"},
];






