import React, {useEffect} from "react";

const DashOutstandingByStatus = ({chartId, titleChart, data}) => {
    let categories=[];
    for(let i in data){
        let row = data[i];
        categories.push(row.name)
    }
    let Highcharts = window.Highcharts;
    let options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: `<span style="color:#01B1A8;">${titleChart}</span>`
        },
        categories:categories,
        subtitle: {
            text: ''
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: [{
            name: 'Brands',
            colorByPoint: true,
            data,
        }]

    }
    useEffect(() => {
        Highcharts.chart(chartId, options);
    })
    return (
        <div id={chartId} style={{minWidth:"99%",maxWidth:"99%",marginTop:10,minHeight:300,maxHeight:300}}>

        </div>
    )
}

export default DashOutstandingByStatus;