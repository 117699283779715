import React from "react";
import {fetchDataEntity} from "../../../api/entity-data";
import ControllerSelectBox from "../../../components/Common/ControllerInput/ControllerSelectBox";
import {ArrayToSelectOption, FilterArrayByKey} from "../../../api/fn";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {PostToPbx} from "../../../api/http-post";
import moment from "moment";
import GridContainer from "../../../components/Common/dashboard/Grid/GridContainer";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import {Button, Col, Row} from "antd";


class ReportPayrollUploadBulkCsv extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Projects: [],
            Periods: [],
            Employees: [],
            Sites: [],

            selectedCompany: "",
            selectedProject: "",
            selectedSite: "",
            newUpload:{
                base64string:"",
                filename:""
            },

            step: 0,
            showStep: false,
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Projects", "", "", this, undefined, "common/entity/modules/list");
        /*await fetchDataEntity("Databases", "", "", this,undefined,"common/entity/databases/list");
        await fetchDataEntity("Attributes", "", "", this,undefined,"common/entity/attributes/list");
        await this.initialReload()*/
    }
    onDropHandler=() =>{
        let _this=this;
        let inputUpload = document.getElementById("fileID");
        let myfile = inputUpload.files[0];
        if (!myfile) {
            console.log("No file selected.");
            return;
        }
        let reader = new FileReader();
        reader.readAsDataURL(myfile);
        reader.onload = function () {
            let tmp = _this.state;
            tmp.newUpload.filename = myfile.name;
            tmp.newUpload.base64string = reader.result;
            _this.setState(tmp);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    handleChangeInput = async ( key,value) => {
        console.log("handleChangeInput > ",key," > ",value)
        this.setState({
            [key]: value,
        });
        if (key === "selectedProject" && this.state.selectedCompany !== "") {
            await this.loadSelectedDatabaseAttributes();
            await this.loadSelectedContractInfo();
        }
    }
    handleGetInputValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return ""
        }
        return this.state[key];
    }
    loadSelectedDatabaseAttributes = async () => {
        let conditions=[
            {key:"org",val:""}
        ]
        await fetchDataEntity("Databases", "", "", this,undefined,"common/entity/databases/list");
        await fetchDataEntity("Attributes", "", "", this,undefined,"common/entity/attributes/list");
    }
    loadSelectedContractInfo=async ()=>{
        let _this = this;
        let state = this.state;
        let hub = {
            Org: state.selectedCompany,
            Module: "contract",
            Ref: state.selectedProject,
        }

        let endpoint = "/payroll/contract/fetch-data"
        await PostToPbx(hub, endpoint, async (output) => {
            console.log("loadInitialData > ", hub, output)
            let data = output.RESULT;
            let tmp = _this.state;
            tmp.Periods =  data.TermsPayCycle;
            tmp.Sites = data.TermsSite;
            _this.setState(tmp);

            console.log("loadSelectedContractInfo > ", tmp.Sites, " > ", tmp.Periods)
        });
    }

    submitUpload=async ()=>{
        let _this = this;
        const state = this.state;
        let module = this.state.selectedModule;
        let hub = {}

        hub.Base64String = state.newUpload.base64string;
        hub.FileName =  state.newUpload.filename;
        hub.Org = this.state.selectedCompany;
        hub.Module = "contract"
        hub.Project = this.state.selectedProject;
        hub.TargetDate = this.state.selectedPeriod.split("#")[1];
        console.log("submitUpload > send", hub)


        let endpoint = "/clock/import/csv"
        await PostToPbx(hub, endpoint, async (output) => {
            console.log("loadInitialData > ", hub, output)
            alert("Thank you, upload submitted, the email will be send to your box after finish!")
        });
    }

    renderCompanyBox = () => {
        let ls = [];
        ls = FilterArrayByKey( this.state.Companies,{})
        ls = ArrayToSelectOption(ls,["CustomerNumber"],["Name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Company"}
                name={"selectedCompany"}
            />
        )
    }
    renderProjectBox = () => {
        let ls = [];
        for(let i in this.state.Projects){
            let row = this.state.Projects[i];
            if(row.org!==this.state.selectedCompany){continue}
            ls.push(row)
        }
        ls = FilterArrayByKey( ls,{})
        ls = ArrayToSelectOption(ls,["name"],["name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Project"}
                name={"selectedProject"}
            />
        )
    }
    renderPayCycleBox = () => {
        const shortDisplayDate=(dateIn)=>{
            return moment(dateIn, "YYYY-MM-DD").format("DD MMM YYYY")
        }
        let ls = [];
        for(let i in this.state.Periods){
            let row = this.state.Periods[i];
            if(row.Org!==this.state.selectedCompany){continue}
            if(row.Ref!==this.state.selectedProject){continue}
            //todo let make display name
            const startDate =shortDisplayDate(row.StartDate);
            const endDate =shortDisplayDate(row.EndDate);
            const payDate = shortDisplayDate(row.PayDate);
            row.DisplayLabel = `${row.Period}. -> Start: ${startDate} -> End: ${endDate} -> Pay date: ${payDate}`;
            row.DisplayValue = `${row.Period}#${row.StartDate}#${row.EndDate}#${row.PayDate}`
            ls.push(row)
        }
        ls = FilterArrayByKey( ls,{})
        ls = ArrayToSelectOption(ls,["DisplayValue"],["DisplayLabel"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Pay Circle period"}
                name={"selectedPeriod"}
            />
        )
    }

    render() {
        return(
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Bulk Clock Upload CSV</Title>
                    <div style={{minWidth: "100%", padding: 10}}>

                        <Row gutter={18}>
                            <Col span={12} >
                                {this.renderCompanyBox()}
                            </Col>
                            <Col span={12} >
                                {this.renderProjectBox()}
                            </Col>
                        </Row>
                        <Row gutter={18}>
                            <Col span={12} >
                                {this.renderPayCycleBox()}
                            </Col>
                            <Col span={12} >
                                <input
                                    id={"fileID"}
                                    type={"file"}
                                    style={styles.inputFile}
                                    onChange={this.onDropHandler}
                                />
                            </Col>
                        </Row>
                        <Row gutter={18}>
                            <Col span={24} >
                                <Button
                                    type={"primary"}
                                    style={{
                                        minWidth:"100%",
                                        maxWidth:"100%",
                                        marginTop:30,
                                        minHeight:40,
                                        borderRadius:15,
                                        fontSize:18,
                                        fontWeight:"bold"
                                    }}
                                    onClick={()=>this.submitUpload()}
                                >Upload File</Button>
                            </Col>
                        </Row>


                    </div>
                </div>
            </Page>
        )
    }

}



export default Connector(ReportPayrollUploadBulkCsv);
const styles = {
    inputFile:{
      minWidth: "100%",
        maxWidth: "100%",
        marginTop:40,
    },
    container:{

    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
