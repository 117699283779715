import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import ControllerSelectBox from "../../../../components/Common/ControllerInput/ControllerSelectBox";
import {
    ArrayToSelectOption,
    FilterArrayByKey,
    FormatMoneySouthAfrica,
    FormatMoneySouthAfrica2,
    UpcaseFirst
} from "../../../../api/fn";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import {PostToPbx} from "../../../../api/http-post";
import {Button, Col, DatePicker, Drawer, Input, Row} from "antd";
import moment from "moment";
import './styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCoffee, faList, faAngleDown, faCartArrowDown, faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons'
//https://www.w3schools.com/icons/fontawesome_icons_directional.asp
import statusGoodIcon from "../../../../assets/img/status-good.jpeg"
import statusBadIcon from "../../../../assets/img/status-bad.jpeg"
import {isDateBetween} from "../../../../api/date-functions";
import LoadingProcessing from "../../../../components/Common/page/Common/LoadingProcessing";


const formatDate = (dateIn) => {
    return moment(dateIn, "YYYY-MM-DD").format("DD MMM")
}
const formatDay = (dateIn) => {
    return moment(dateIn, "YYYY-MM-DD").format("DDD")
}
const formatDate2 = (dateIn) => {
    return moment(dateIn).format("YYYY-MM-DD")
}
Date.prototype.getWeek = function () {
    let date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    let week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

function getWeekRange(weekNo, yearNo) {
    let firstDayofYear = new Date(yearNo, 0, 1);

    if (firstDayofYear.getDay() > 4) {
        let weekStart = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 8);
        let weekEnd = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 8 + 6);
        return {from: formatDate2(weekStart), to: formatDate2(weekEnd)}
    } else {
        let weekStart = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 1);
        let weekEnd = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 1 + 6);
        return {from: formatDate2(weekStart), to: formatDate2(weekEnd)}
    }
}

const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');

class ServicesDeliveryDailyLoggerPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Attributes: [],
            Projects: [],
            Periods: [],
            DataDesignation: [],
            Data: [],
            Sites: [],
            Clients: [],
            Items: [],
            Vehicles: [],
            Drivers: [],

            selectedCompany: "",
            selectedClient: "",
            selectedItem: "yes",
            selectedPeriod: "",
            selectedVehicle: "",
            selectedDriver: "",
            newEntry: {
                DeliveryNoteNumber: "",
                TimeInSettlement: "",
                TimeOutSettlement: "",
                TimeArrivedAtDisposed: "",
                TrailerRegistration: "",
                NumberOfUnits: "0"
            },

            mode: "list",
            allDates: [],
            allWeeks: [],
            allDateByWeeks: [],
            startDate: startOfMonth,
            endDate: moment().format("YYYY-MM-DD"),
            selectedWeek: 0,
            showPopup: false,
            selectedSite: "",
            selectedDate: "",
            selectedLogs: [],
            selectedFilter:"Total",
            busy:false,
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Projects", "", "", this, undefined, "common/entity/modules/list");
        //await fetchDataEntity("Data", "", "", this, undefined, "common/entity/ServiceLogsDeliveryNote/list");
        await fetchDataEntity("Sites", "", "", this, undefined, "common/entity/SettingInvoiceServiceAddonSite/list");
        await fetchDataEntity("Clients", "", "", this, undefined, "common/entity/SettingInvoiceServiceDebtors/list");
        await fetchDataEntity("Items", "", "", this, undefined, "common/entity/SettingInvoiceServiceItem/list");
        await fetchDataEntity("Vehicles", "", "", this, undefined, "common/entity/ServiceLogsVehicle/list");
        await fetchDataEntity("Drivers", "", "", this, undefined, "common/entity/ServiceLogsDriver/list");
        this.generateDate()
    }
    requestDeleteLog = async (record) => {
        if (!window.confirm("are you sure to delete this log: " + record.deliverynumber)) {
            return
        }
        let Params = [
            {key: "org", val: record.org, type: "string"},
            {key: "client", val: record.client, type: "string"},
            {key: "item", val: record.item, type: "string"},
            {key: "site", val: record.site, type: "string"},
            {key: "deliveryDate", val: record.deliverydate, type: "string"},
            {key: "deliveryNumber", val: record.deliverynumber, type: "string"},
        ]
        let postData = {
            Params: Params,
        }
        console.log("requestDeleteLog send > ", postData)
        let endpoint = "/common/entity/ServiceLogsDeliveryNote/delete"
        await PostToPbx(postData, endpoint, async (output) => {
            let data = output.RESULT;
            console.log("requestDeleteLog response > ", data)
            await fetchDataEntity("Data", "", "", this, undefined, "common/entity/ServiceLogsDeliveryNote/list");
            alert("Thank you, your delivery note have been deleted!")
            this.setState({showPopup: false})
        });
    }
    onSubmitLog = async () => {
        // alert("Coming soon!")
        const state = this.state;
        const newState = state.newEntry;
        let hub = {};
        hub.Org = state.selectedCompany;
        hub.Client = state.selectedClient;
        hub.Item = state.selectedItem;
        hub.Site = state.selectedSite;
        hub.Vehicle = state.selectedVehicle;
        hub.Driver = state.selectedDriver;

        hub.NumberOfUnits = parseInt(newState.NumberOfUnits)
        hub.DeliveryNumber = newState.DeliveryNoteNumber;
        hub.DeliveryTimeIn = newState.TimeInSettlement;
        hub.DeliveryTimeOut = newState.TimeOutSettlement;
        hub.DeliveryDate = state.selectedDate;
        hub.DeliveryTimeArrived = newState.TimeArrivedAtDisposed;
        hub.TrailerRegister = newState.TrailerRegistration;

        let postData = {
            Data: hub,
        }
        console.log("submitRecord send > ", hub)
        let endpoint = "/common/entity/ServiceLogsDeliveryNote/insert"
        await PostToPbx(postData, endpoint, async (output) => {
            let data = output.RESULT;
            console.log("submitRecord response > ", data)
            await fetchDataEntity("Data", "", "", this, undefined, "common/entity/ServiceLogsDeliveryNote/list");
            alert("Thank you, your delivery note have been recorded!")
            this.setState({showPopup: false})
        });
    }
    submitSearch = async () => {
        let state = this.state;

        let hub ={
            Params:[
                {key:"org",val:this.state.selectedCompany,type:"string"},
            ]
        }
        let endpoint=`/common/entity/ServiceLogsDeliveryNote/list`
        this.setState({busy:true})
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            let ls=[];
            for(let i in data){
                let row =data[i];
                let dDate = row.deliverydate;
                if(row.client!==this.state.selectedClient){continue}
                if (row.item !== state.selectedItem) {
                    continue
                }
                if(!isDateBetween(this.state.startDate,this.state.endDate,dDate)){
                    continue
                }
                ls.push(row)
            }
            this.setState({Data:ls})
            this.generateDate();
            this.setState({busy:false})
        })


    }
    requestPopup = (site, date, logsList) => {
        //alert("Site: " + site + " > Date: " + date)
        this.setState({
            showPopup: true,
            selectedSite: site,
            selectedDate: date,
            selectedLogs: logsList
        })
    }
    handleChangeInput = async (key, value) => {
        this.setState({
            [key]: value,
        })
    }
    generateDatesByWeek = (weekNo, year) => {
        let dates = []
        let infoRange = getWeekRange(weekNo, year)
        const date = new Date(infoRange.from);
        const endDate = new Date(infoRange.to);
        while (date <= endDate) {
            let newDate = moment(date).format("YYYY-MM-DD")
            let newDay = moment(date).format("ddd")
            let weekNo = parseInt(moment(date).format("w"))
            dates.push({
                date: newDate,
                day: newDay,
                weekNo: weekNo,
            });
            date.setDate(date.getDate() + 1);
        }
        return dates
    }
    generateDate = () => {
        let state = this.state;
        let weeks = {};
        let weeks2 = {};
        const dates = [];
        const date = new Date(state.startDate);
        const endDate = new Date(state.endDate);
        while (date <= endDate) {
            let newDate = moment(date).format("YYYY-MM-DD")
            let newDay = new Date(date).toDateString().split(" ")[0]
            let weekNo = moment(date).isoWeek();
            let year = parseInt(moment(date).format("YYYY"))
            dates.push({
                date: newDate,
                day: newDay,
                weekNo: weekNo,
            });
            let wNo = `${weekNo}-${year}`
            weeks[wNo] = `${weekNo}-${year}`
            date.setDate(date.getDate() + 1);
            if (typeof weeks2[wNo] === "undefined") {
                weeks2[wNo] = []
            }
            weeks2[wNo].push(
                {
                    date: newDate,
                    day: newDay,
                    weekNo: wNo,
                }
            )
        }
        let allWeeks = [];
        for (let i in weeks) {
            allWeeks.push(i)
        }
        this.setState({
            allDates: dates,
            allWeeks,
            weeks2
        })
    }
    handleGetInputValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return ""
        }
        return this.state[key];
    }
    isWorkingDayForSite = (siteInfo, dayIn) => {
        let {WorkingDaysByWeek} = this.findWorkingDayOfSiteByWeek(siteInfo);
        for (let i in WorkingDaysByWeek) {
            let item = WorkingDaysByWeek[i]
            if (item === dayIn) {
                return true
            }
        }
        return false
    }
    findWorkingDayOfSiteByWeek = (site) => {
        let days = [];
        let total = 0;
        if (site.mon) {
            total++
            days.push("Mon")
        }
        if (site.tue) {
            total++
            days.push("Tue")
        }
        if (site.wed) {
            total++
            days.push("Wed")
        }
        if (site.thu) {
            total++
            days.push("Thu")
        }
        if (site.fri) {
            total++
            days.push("Fri")
        }
        if (site.sat) {
            total++
            days.push("Sat")
        }
        if (site.sun) {
            total++
            days.push("Sun")
        }
        return {
            WorkingDaysByWeek: days,
        }
    }
    findPlannedWorkingDay = (siteInfo) => {
        let totalPlannedDaysForPeriod = 0;
        for (let i in this.state.allDates) {
            let row = this.state.allDates[i];
            if (this.isWorkingDayForSite(siteInfo, row.day)) {
                totalPlannedDaysForPeriod++
            }
        }
        return {totalPlannedDaysForPeriod}
    }
    findActualBySiteForPeriod = (site) => {
        const isInDates = (dateIn) => {
            console.log("1 ^^^^isDate > ", this.state.allDates)
            for (let i in this.state.allDates) {
                let row = this.state.allDates[i];
                if (row.date === dateIn) {
                    return true
                }
            }
            return false
        }
        let state = this.state;
        let details = [];
        let total = 0;

        for (let i in state.Data) {
            let row = state.Data[i];
            /*if (row.org !== state.selectedCompany) {
                continue
            }
            if (row.client !== state.selectedClient) {
                continue
            }
            if (row.item !== state.selectedItem) {
                continue
            }
            if (!isInDates(row.deliverydate)) {
                continue
            }*/
            if (row.site !== site) {
                continue
            }
            total += row.numberofunits;
        }
        return {totalActual: total}
    }
    findSummaryBySite = (siteInfo) => {
        if(siteInfo.site==="7de Laan"){
            console.log("(----|siteInfo|----> ",siteInfo)
        }
        let state = this.state;
        let totalUnits, totalPlan, totalDiff, totalActualCost, totalPlannedCost;
        totalUnits = siteInfo.quantity;
        let {WorkingDaysByWeek} = this.findWorkingDayOfSiteByWeek(siteInfo)
        let {totalActual} = this.findActualBySiteForPeriod(siteInfo.site)
        /**
         * let find our plan
         */
        let {totalPlannedDaysForPeriod} = this.findPlannedWorkingDay(siteInfo)
        totalPlan = totalUnits * totalPlannedDaysForPeriod;
        /**
         * let find difference between plan and actual
         */
        totalDiff = totalActual - totalPlan
        /**
         * let find total cost and lost
         */
        totalActualCost = totalActual * siteInfo.rate
        totalPlannedCost = totalPlan * siteInfo.rate
        totalPlannedCost = totalPlannedCost.toFixed(2)
        let totalLost = totalPlannedCost - totalActualCost
        totalLost = totalLost.toFixed(2)

        let listWorkDays = "";
        for (let i in WorkingDaysByWeek) {
            let item = WorkingDaysByWeek[i]
            listWorkDays += item + ",";
        }
        listWorkDays = listWorkDays.trim()

        return {
            totalPlan,
            totalUnits,
            totalDiff,
            WorkingDaysByWeek,
            totalActual,
            totalPlannedDaysForPeriod,
            totalActualCost,
            totalPlannedCost,
            totalLost,
            listWorkDays
        }

    }
    findServiceByDate = (site, date) => {
        let state = this.state;
        let numberOfUnits = 0;
        let numberOfDelivery = 0;
        let logsList = [];
        for (let i in state.Data) {
            let row = state.Data[i];
            if (row.org !== state.selectedCompany) {
                continue
            }
            if (row.client !== state.selectedClient) {
                continue
            }
            if (row.item !== state.selectedItem) {
                continue
            }
            if (row.deliverydate !== date) {
                continue
            }
            if (row.site !== site) {
                continue
            }
            numberOfUnits += row.numberofunits;
            numberOfDelivery++;
            logsList.push(row)
        }
        return {numberOfUnits, numberOfDelivery, logsList}
    }
    getTargetSite = () => {
        let state = this.state;
        let sites = [];
        for (let i in state.Sites) {
            let row = state.Sites[i]
            if (row.org !== state.selectedCompany) {
                continue
            }
            if (row.client !== state.selectedClient) {
                continue
            }
            if (row.item !== state.selectedItem) {
                continue
            }
            sites.push(row)
        }
        return sites
    }
    getTotalStatus=()=>{
        let sites = this.getTargetSite();
        let totalStatusGood=0,totalStatusBad=0;
        for(let i in sites){
            let row = sites[i];
            let summary = this.findSummaryBySite(row)
            if(summary.totalDiff===0){
                totalStatusGood++
            }else{
                totalStatusBad++
            }
        }
        return{totalStatusGood,totalStatusBad}
    }
    onClickExportSubcontract=()=>{
        let dataExport=[
            ["Number","Site","Units","Actual Services","Start Date","End Date"]
        ];
        let infos = this.getTargetSite()
        let formatDate=(dateIn)=>{
            return moment(dateIn,"YYYY-MM-DD").format("DD/MM/YYYY")
        }
        for(let i in infos){
            let rowSite =infos[i];
            let summary = this.findSummaryBySite(rowSite);
            let record = [
                parseInt(i)+1,
                 rowSite.site,
                rowSite.quantity,
                summary.totalActual,
                formatDate(this.state.startDate),
                formatDate(this.state.endDate),
            ]
            dataExport.push(record)
        }
        /**
         * export to csv
         */

        let csvContent = "data:text/csv;charset=utf-8,";
        dataExport.forEach(function(info){
            let row = info.join(",");
            csvContent += row + "\r\n";
        });
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "pmis_service_logs_subcontractor.csv");
        link.innerHTML= "Click Here to download";
        document.body.appendChild(link);
        link.click();


        /*
        {
            totalPlan,
            totalUnits,
            totalDiff,
            WorkingDaysByWeek,
            totalActual,
            totalPlannedDaysForPeriod,
            totalActualCost,
            totalPlannedCost,
            totalLost,
            listWorkDays
        }
         */
    }
    renderPopup = () => {
        if (!this.state.showPopup) {
            return null
        }
        const onClose = () => {
            this.setState({showPopup: false})
        }
        let inputsForm = () => {
            return [
                {key: "NumberOfUnits", label: "NumberOfUnits", isEmpty: false, type: "text"},
                {key: "DeliveryNoteNumber", label: "Delivery Note Number", isEmpty: false, type: "text"},
                {key: "TimeInSettlement", label: "Time In Settlement", isEmpty: false, type: "time"},
                {key: "TimeOutSettlement", label: "Time Out Settlement", isEmpty: false, type: "time"},
                {key: "TimeArrivedAtDisposed", label: "Time Arrived At Disposed", isEmpty: false, type: "time"},
                {key: "TrailerRegistration", label: "Trailer Registration", isEmpty: false, type: "text"},
            ]
        }
        const innerOnChance = (key, value) => {
            let newEntry = this.state.newEntry;
            newEntry[key] = value;
            this.setState({newEntry})
        }
        const innerRenderText = (keyState, label) => {
            return (
                <Row>
                    <Col span={6} className={"popupLabel"}>
                        {label}:
                    </Col>
                    <Col span={6} className={"popupInput"}>
                        <Input
                            onChange={(e) => {
                                innerOnChance(keyState, e.target.value)
                            }}
                            placeholder={"Enter your delivery.."}
                        ></Input>
                    </Col>
                </Row>
            )
        }
        return (
            <Drawer
                title={"Services logs detail"}
                width={720}
                onClose={onClose}
                visible={this.state.showPopup}
                bodyStyle={{paddingBottom: 80, overflow: "scroll"}}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >

                        <Button onClick={()=>onClose()} style={{marginRight: 8}}>
                            Cancel
                        </Button>

                        <Button onClick={() => this.onSubmitLog()} type="primary">
                            Submit
                        </Button>
                    </div>
                }
            >
                <p>Site: {this.state.selectedSite}</p>
                <p>Date: {this.state.selectedDate}</p>
                {inputsForm().map(row => {
                    if (row.type === "time") {
                        return innerRenderText(row.key, row.label)
                    }
                    return innerRenderText(row.key, row.label)
                })}

                <Row>
                    <Col span={12} className={"popupInput"}>
                        {this.renderDriverBox()}
                    </Col>
                </Row>
                <Row>
                    <Col span={12} className={""}>
                        {this.renderVehicleBox()}
                    </Col>
                </Row>
                <div style={{marginTop: 20}}>
                    <h3>Logs detail</h3>
                    <Row>
                        <Col span={2} className={""}>&nbsp;</Col>
                        <Col span={3} className={"row1Th"}>Units</Col>
                        <Col span={5} className={"row1Th"}>Note#</Col>
                        <Col span={5} className={"row1Th"}>Driver</Col>
                        <Col span={5} className={"row1Th"}>Vehicle</Col>
                        <Col span={2} className={"row1Th"}>T in</Col>
                        <Col span={2} className={"row1Th"}>T out</Col>
                    </Row>
                    {this.state.selectedLogs.map(row => {
                        return (
                            <Row>
                                <Col span={2} className={"row1Th"}><a onClick={() => this.requestDeleteLog(row)}>Del</a>
                                </Col>
                                <Col span={3} className={"row1Th"}>{row.numberofunits}</Col>
                                <Col span={5} className={"row1Th"}>{row.deliverynumber}</Col>
                                <Col span={5} className={"row1Th"}>{row.driver}</Col>
                                <Col span={5} className={"row1Th"}>{row.vehicle}</Col>
                                <Col span={2} className={"row1Th"}>{row.deliverytimein}</Col>
                                <Col span={2} className={"row1Th"}>{row.deliverytimeout}</Col>
                            </Row>
                        )
                    })}
                </div>

            </Drawer>
        )
    }
    renderDriverBox = () => {
        let ls = [];
        for (let i in this.state.Drivers) {
            let row = this.state.Drivers[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            ls.push(row)
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["name"], ["name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Driver"}
                name={"selectedDriver"}
            />
        )
    }
    renderVehicleBox = () => {
        let ls = [];
        for (let i in this.state.Vehicles) {
            let row = this.state.Vehicles[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            ls.push(row)
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["regnumber"], ["regnumber"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Vehicle"}
                name={"selectedVehicle"}
            />
        )
    }
    renderCompanyBox = () => {
        let ls = [];
        ls = FilterArrayByKey(this.state.Companies, {})
        ls = ArrayToSelectOption(ls, ["CustomerNumber"], ["Name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Company"}
                name={"selectedCompany"}
            />
        )
    }
    renderClientBox = () => {
        let ls = [];
        for (let i in this.state.Clients) {
            let row = this.state.Clients[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            ls.push(row)
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["name"], ["name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Client"}
                name={"selectedClient"}
            />
        )
    }
    renderItemBox = () => {
        let ls = [];
        for (let i in this.state.Items) {
            let row = this.state.Items[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.client !== this.state.selectedClient) {
                continue
            }
            ls.push(row)
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["name"], ["name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Items"}
                name={"selectedItem"}
            />
        )
    }
    renderDashboardStatus=()=>{
        const toFix=(valIn)=>{
            let str = valIn.toFixed(2)
            return parseFloat(str)
        }
        let sites = this.getTargetSite();
        let totSite=0,totPlannedUnits=0,totPlannedRevenue=0,totActualRevenue=0,totImpact=0;
        let totPlannedServices=0,totActualServices=0,totMissedServices=0;
        for(let i in sites){
            let row = sites[i];
            let summary = this.findSummaryBySite(row)
            totSite++
            totPlannedUnits +=row.quantity;
            totPlannedRevenue += summary.totalPlan * row.rate;
            totActualRevenue +=summary.totalActual * row.rate;

            totPlannedServices +=summary.totalPlan;
            totActualServices +=summary.totalActual;
        }
        totMissedServices = totActualServices  - totPlannedServices
        /**
         *
         * @type {string}
         */
        let statusIndex = "bad";
        let financialIndex= 0;
        if(totActualRevenue>0){
            financialIndex = (totActualRevenue/totPlannedRevenue)*100
            financialIndex = toFix(financialIndex)
        }

        let servicePerformanceIndex=0;
        if(totActualServices>0 && totPlannedServices>0){
            servicePerformanceIndex = (totActualServices/totPlannedServices)*100
        }
        servicePerformanceIndex = toFix(servicePerformanceIndex)

        let ls=[
            {label:"Total Sites",total:totSite,currency:false,currValue:"",extraClass:""},
            {label:"Total Units",total:totPlannedUnits,currency:true,currValue:"",extraClass:""},
            {label:"Total Planned Services",total:totPlannedServices,currency:true,currValue:"",extraClass:""},
            {label:"Total Actual Services",total:totActualServices,currency:true,currValue:"",extraClass:""},
            {label:"Service missed",total:totMissedServices,currency:true,currValue:"",extraClass:{color:"red"}},
        ]

        return(
            <Row gutter={18} className={"stRow borderTop"}>
                {ls.map(row=>{
                    return(
                        <Col span={4} className={"stRowCol"}>
                            <label className={"stRowLabel"}>{row.label}:</label>
                            <label className={`stRowTotal1 `} style={{...row.extraClass}}>
                                {row.currency?row.currValue:""} {row.currency?FormatMoneySouthAfrica2(row.total):row.total}
                            </label>
                        </Col>
                    )
                })}
                <Col span={4} className={"stRowCol"}>
                    <label className={"stRowLabel"}>Performance Index:
                        <span style={{color:"black",fontWeight:"bold",fontSize:16}}>
                        {financialIndex}%
                        </span>
                    </label>
                    <label className={"stRowTotal1"}>
                        <img className={"statusIcon"} src={statusIndex==="good"?statusGoodIcon:statusBadIcon}/>
                    </label>
                </Col>
            </Row>
        )
    }
    renderList = () => {
        let state = this.state;
        let allWeeksAndDates = [];
        for (let i in state.weeks2) {
            if (this.state.selectedWeek !== i) {
                continue
            }
            let rows = state.weeks2[i];
            for (let a in rows) {
                let rec = rows[a];
                allWeeksAndDates.push(rec)
            }
        }

        let {totalStatusBad,totalStatusGood}=this.getTotalStatus()
        let arrRadioButtons=[
            {label:"Total",color:"black",value:this.getTargetSite().length},
            {label:"Good",color:"green",value:totalStatusGood},
            {label:"Bad",color:"red",value:totalStatusBad},
        ]

        /**
         * let define our date in store
         * @type {{}}
         */
        let stockDates = {};
        for(let i in state.allWeeks){
            let item = state.allWeeks[i];
            if (state.selectedWeek !== item) {
                continue
            }
            let arr = item.split("-")
            let week = parseInt(arr[0])
            let year = parseInt(arr[1])
            let dates = this.generateDatesByWeek(week, year)
            let key =`${week}--${year}`;
            stockDates[key] = dates
        }
        /*
        state.allWeeks.map((item, index) => {
                        if (state.selectedWeek !== item) {
                            return null
                        }
         */

        return (
            <div style={{backgroundColor: "white", padding: 10}}>
                {this.renderDashboardStatus()}
                <Row gutter={18} className={"borderBottom weekRow"}>
                    <Col span={1}>
                        Weeks:
                    </Col>
                    <Col span={10} style={{display: "flex", flexDirection: "row"}}>
                        {state.allWeeks.map((item, index) => {
                            let bgStyle = {}
                            if (this.state.selectedWeek === item) {
                                bgStyle = {
                                    backgroundColor: "lightblue",
                                    color: "white"
                                }
                            }
                            return (
                                <Col span={2}>
                                    <Button
                                        onClick={() => this.setState({selectedWeek: item})}
                                        style={{
                                            ...bgStyle, width: "100%"
                                        }}>
                                        {index + 1}
                                    </Button>
                                </Col>
                            )
                        })}
                        <Col span={1}>
                            <button
                                onClick={()=>this.onClickExportSubcontract()}
                                style={styles.btnExport}>
                                Export
                            </button>
                        </Col>

                    </Col>

                    <Col span={12} style={styles.boxFilter}>

                        <Col span={5}>
                            <label>Site Service Index: </label>
                        </Col>
                        {arrRadioButtons.map(row=>{
                            return(
                                <Col span={4} style={styles.boxFilterInner}>
                                    <input
                                        name={"filter1"}
                                        type={"radio"}
                                        onChange={(e)=>{
                                            this.setState({selectedFilter:row.label})
                                            console.log("@::> ",e.target.value,row.label)
                                        }}
                                    />
                                    <label style={{color:row.color}}>{row.label}</label>
                                    <label style={{color:row.color}}>{row.value}</label>
                                </Col>
                            )
                        })}

                    </Col>

                </Row>
                <Row gutter={18}>
                    <Col span={1} className={"row1Th pushCenter"}>
                        Action
                    </Col>
                    <Col span={1} className={"row1Th pushCenter"}>
                        #
                    </Col>
                    <Col span={5} className={"row1Th"}>
                        site
                    </Col>
                    <Col span={1} className={"row1Th pushCenter row1ThE"}>
                        Units
                    </Col>
                    <Col span={1} className={"row1Th pushCenter row1ThE"}>
                        Days
                    </Col>
                    <Col span={1} className={"row1Th pushCenter row1ThE"}>
                        Plan
                    </Col>
                    <Col span={1} className={"row1Th pushCenter row1ThE"}>
                        Actual
                    </Col>
                    <Col span={1} className={"row1Th pushCenter row1ThE"}>
                        Diff
                    </Col>
                    <Col span={1} className={"row1Th pushCenter row1ThE"}>
                        Rate
                    </Col>
                    <Col span={1} className={"row1Th pushCenter row1ThE"}>
                        Status
                    </Col>
                    <Col span={3} className={"row1Th pushCenter row1ThE"}>
                        Service Day
                    </Col>
                    {state.allWeeks.map((item, index) => {
                        if (state.selectedWeek !== item) {
                            return null
                        }
                        let arr = item.split("-")
                        let week = parseInt(arr[0])
                        let year = parseInt(arr[1])

                        let key =`${week}--${year}`;
                        let dates = stockDates[key]
                        //let dates = this.generateDatesByWeek(week, year)
                        return dates.map(infoDate => {
                            let colRed = "dtBgTh";

                            if (infoDate.day === "Sat" || infoDate.day === "Sun") {
                                console.log("PPPPIO > ", infoDate)
                                colRed = "colRed2"
                            }
                            return (
                                <Col span={1} className={"row1Th pushCenter extHeader " + colRed}>
                                    {formatDate(infoDate.date)}
                                </Col>
                            )
                        })

                    })}
                </Row>
                <Row gutter={18}>
                    <Col span={1} className={"row1Th pushCenter"}>
                        &nbsp;
                    </Col>
                    <Col span={1} className={"row1Th pushCenter"}>
                        &nbsp;
                    </Col>
                    <Col span={5} className={"row1Th"}>
                        &nbsp;
                    </Col>
                    <Col span={1} className={"row1Th pushCenter"}>
                        &nbsp;
                    </Col>
                    <Col span={1} className={"row1Th pushCenter"}>
                        &nbsp;
                    </Col>
                    <Col span={1} className={"row1Th pushCenter"}>
                        &nbsp;
                    </Col>
                    <Col span={1} className={"row1Th pushCenter"}>
                        &nbsp;
                    </Col>
                    <Col span={1} className={"row1Th pushCenter"}>
                        &nbsp;
                    </Col>
                    <Col span={1} className={"row1Th pushCenter"}>
                        &nbsp;
                    </Col>

                    <Col span={1} className={"row1Th pushCenter"}>
                        &nbsp;
                    </Col>
                    <Col span={3} className={"row1Th pushCenter"}>
                        &nbsp;
                    </Col>

                    {state.allWeeks.map((item, index) => {
                        if (state.selectedWeek !== item) {
                            return null
                        }
                        let arr = item.split("-")
                        let week = parseInt(arr[0])
                        let year = parseInt(arr[1])
                        let key =`${week}--${year}`;
                        let dates = stockDates[key]
                        //let dates = this.generateDatesByWeek(week, year)
                        return dates.map(infoDate => {
                            let colRed = "";
                            if (infoDate.day === "Sat" || infoDate.day === "Sun") {
                                colRed = "colRed"
                            }
                            return (
                                <Col span={1} className={"row1Th pushCenter " + colRed}>
                                    {infoDate.day}
                                </Col>
                            )
                        })

                    })}
                </Row>
                {this.getTargetSite().map((rowSite, index1) => {

                    let summary = this.findSummaryBySite(rowSite)
                    if(this.state.selectedFilter==="Good"){
                        if(summary.totalDiff!==0){
                            return null
                        }
                    }
                    if(this.state.selectedFilter==="Bad"){
                        if(summary.totalDiff===0){
                            return null
                        }
                    }
                    return (
                        <Row gutter={18}>
                            <Col span={1} className={"row1Th pushCenter"} style={{
                                cursor:"pointer"
                            }}>
                                <FontAwesomeIcon icon={faList} color={"lightblue"}/>
                            </Col>
                            <Col span={1} className={"row1Th pushCenter"}>
                                {index1 + 1}
                            </Col>
                            <Col span={5} className={"row1Th"}>
                                {rowSite.site}
                            </Col>
                            <Col span={1} className={"row1Th pushCenter"}>
                                {rowSite.quantity}
                            </Col>
                            <Col span={1} className={"row1Th pushCenter"}>
                                {summary.totalPlannedDaysForPeriod}
                            </Col>
                            <Col span={1} className={"row1Th pushCenter"}>
                                {summary.totalPlan}
                            </Col>
                            <Col span={1} className={"row1Th pushCenter"}>
                                {summary.totalActual}
                            </Col>
                            <Col span={1} className={"row1Th pushCenter"}>
                                {summary.totalDiff}
                            </Col>
                            <Col span={1} className={"row1Th pushCenter"}>
                                R{rowSite.rate}
                            </Col>

                            <Col span={1} className={"row1Th pushCenter pushRight"}>
                                {summary.totalDiff !== 0 ?
                                    <FontAwesomeIcon icon={faCaretDown} color={"red"} /> :
                                    <FontAwesomeIcon icon={faCaretUp} color={"green"}/>
                                }
                            </Col>
                            <Col span={3} className={"row1Th pushCenter"}>
                                {summary.listWorkDays}
                            </Col>


                            {state.allWeeks.map((item, index) => {
                                if (state.selectedWeek !== item) {
                                    return null
                                }
                                let arr = item.split("-")
                                let week = parseInt(arr[0])
                                let year = parseInt(arr[1])
                                let key =`${week}--${year}`;
                                let dates = stockDates[key]
                                //let dates = this.generateDatesByWeek(week, year)
                                return dates.map(infoDate => {
                                    let {
                                        numberOfUnits,
                                        numberOfDelivery,
                                        logsList
                                    } = this.findServiceByDate(rowSite.site, infoDate.date)
                                    let extraStyle = {}
                                    if (numberOfUnits !== 0) {
                                        if (rowSite.quantity !== numberOfUnits) {
                                            extraStyle = {borderColor: "red"}
                                        }
                                    }
                                    return (
                                        <Col
                                            span={1}
                                            className={"row1Th pushCenter clickBtnCol"}
                                            onClick={() => this.requestPopup(rowSite.site, infoDate.date, logsList)}
                                            style={{
                                                ...extraStyle,
                                            }}
                                        >
                                            <label className={"numberServiceLabel"}>{numberOfUnits}</label><br/>
                                            {/*<label className={"deliveryLabel"}>{numberOfDelivery}</label>*/}
                                        </Col>
                                    )
                                })
                            })}
                        </Row>
                    )
                })}

            </div>
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Service Logs</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <Row gutter={18}>
                            <Col span={12}>
                                {this.renderCompanyBox()}
                            </Col>
                            <Col span={12}>
                                {this.renderClientBox()}
                            </Col>
                        </Row>
                        <Row gutter={18} style={{marginTop: -30}}>
                            <Col span={12}>
                                {this.renderItemBox()}
                            </Col>
                            <Col span={12} style={styles.dateRow}>
                                <Col span={10} style={{marginTop: 30, height: 40}}>
                                    <DatePicker
                                        style={styles.datePickerBox}
                                        onChange={(date, dateString) => this.setState({startDate: dateString})}
                                        placeholder={"Start Date"}
                                    />
                                </Col>
                                <Col span={10} style={{marginTop: 30, height: 40}}>
                                    <DatePicker
                                        style={styles.datePickerBox}
                                        onChange={(date, dateString) => this.setState({endDate: dateString})}
                                        placeholder={"End Date"}
                                    />
                                </Col>
                                <Col span={4} style={{marginTop: 30, height: 40}}>
                                    <Button
                                        style={styles.datePickerBox}
                                        onClick={() => this.submitSearch()}
                                    >
                                        Search
                                    </Button>
                                </Col>

                            </Col>
                        </Row>
                        <div style={{
                            borderRadius: 5,
                            borderWidth: 1,
                            borderColor: "lightblue",
                            borderStyle: 'solid',
                            minHeight: 35,
                            padding: 5
                        }}>
                            {this.renderList()}

                        </div>

                    </div>
                    {this.renderPopup()}
                    <LoadingProcessing open={this.state.busy} />
                </div>
            </Page>
        )
    }
}

export default Connector(ServicesDeliveryDailyLoggerPage);
const styles = {
    btnExport:{
      fontsize: 11,
      width: 75,
        marginTop:1,
        backgroundColor: "gray",
        color:"white"
    },
    boxFilterInner: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems:"center",
        paddingLeft:10,
        paddingRight:5,
        borderRight:"1px solid black",
        fontsize:14,
        fontWeight:"bold"
    },
    boxFilter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems:"center"
    },
    dateRow: {
        display: "flex",
        flexDirection: "row"
    },
    datePickerBox: {
        height: 40,
        width: "100%"
    },
    row1Th: {
        border: "1px solid black",
    },
    rowForm: {
        marginBottom: 10,
        paddingBottom: 5,
        border: "0px solid lightgray",
        borderBottomWidth: 1,

    },
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}