import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {Alert, Col, Form, Layout, Row, Select, Divider} from "antd";
import {fetchDataEntity} from "../../../api/entity-data";
import {
    SortData,
} from "../../../api/fn";
import {Button} from 'antd';
import {PostToPbx} from "../../../api/http-post";
import moment from "moment";
import PopupDetail from "./PopupDetail";
import PopupExportPastel from "./PopupExportPastel";
import PopupExportBank from "./PopupExportBank";
import FilePptOutlined from "@ant-design/icons/lib/icons/FilePptOutlined";
import Configures from "../../../configures";
import {GetConfigures} from "../../../configures/serve";

const {Content} = Layout;
const {Option} = Select;

function convertDateFormat_DDMMYYYY(dateIn) {
    let mydate = moment(dateIn, "YYYY-MM-DD").format("DD MMM YYYY");
    return mydate;
}

function formatCurrency(stringIn) {
    return stringIn.toFixed(2)
}



class ReportPaymentCycleDaysPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Org: "",
            Companies: [],
            Entities: [],
            Databases: [],
            Attributes: [],
            selectedCompany: "",
            selectedModule: "",
            selectedRecord: {},
            selectedPeriod: "",
            selectedSite: "",
            Data: [],
            isLoading: false,
            component: "list",
            initLoading: false,

            DataPeriod: [],
            DataDesignation: [],
            DataSite: [],
            DataSiteDesignation: [],
            DataPayCycle: [],
            DataRate: [],
            DataRateSpecific: [],
            DataCondition: [],
            DataShift: [],

            Results: null,
            showViewDetail: false,
            detailCode: "",
            detailRecord: {},
            detailList: [],

            showViewPastel: false,
            showViewBank:false,

            BankName: "Bank",
            BankAccountKey: "Account Number",
            BankBranchKey: "Branch Code",
            BankSecondaryKey: "ID Number",
            BankHolderName: "First Name",
            BankHolderSurname: "Surname",
            BankHolderAccountType:1,
            BankOwnRefPrefix: "EPWP",
            BankTheirRefPrefix: "Sivuyile Wages",
            BankFoundReleaseDate: "2020-08-31",
            BankAccountFoundRelease: "62747401975",
            BankFileHeading: "BInSol - U ver 1.00"
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.initialReload()
    }
    initialReload = async () => {
        await fetchDataEntity("Entities", "", "", this, undefined, "common/entity/modules/list");
        await fetchDataEntity("Databases", "", "", this, undefined, "common/entity/databases/list");
        await fetchDataEntity("Attributes", "", "", this, undefined, "common/entity/attributes/list");
    }
    loadContractInfo = async (selectedModule) => {
        let _this = this;
        let module = this.state.selectedModule;
        let hub = {
            Org: this.state.selectedCompany,
            Module: "contract",
            Ref: selectedModule,
        }

        let endpoint = "/payroll/contract/fetch-data"
        await PostToPbx(hub, endpoint, async (output) => {
            console.log("loadInitialData > ", hub, output)
            let data = output.RESULT;
            let tmp = _this.state;
            tmp.DataPeriod = data.TermsPeriod;
            tmp.DataDesignation = data.TermsDesignation;
            tmp.DataPayCycle = data.TermsPayCycle;
            tmp.DataSite = data.TermsSite;
            tmp.DataRate = data.TermsRate;
            tmp.DataRateSpecific = data.TermsRateSpecific;
            tmp.DataSlot = data.TermsSlot;
            tmp.DataSiteDesignation = data.TermsSiteDesignation;
            tmp.DataShift = data.TermsShift;
            tmp.DataCondition = data.TermsCondition;
            _this.setState(tmp);

            console.log("loadInitialData > ", output, " > ", data)
        });
    }
    handleChangeCompany = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    handleChange = async (e, key) => {
        const value = e.value;
        this.setState({
            [key]: value,
        });

        if (key === "selectedModule") {
            console.log("selected module > ", key, value)
            await this.loadContractInfo(value)
        }

    }
    handleChange2 = (e, ee, key) => {
        const val = e;
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
    }
    deleteClock=async (item)=>{
        console.log("deleteClock > ",item)
        if(!window.confirm("Are you sure to delete this record!")){
            return
        }
        let hub = {...item}
        let endpoint = "/clock/admin/delete"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            alert("Clock deleted!")
            this.setState({
                Results: data,
                isLoading: false,
            });
           //await this.submitSearch()
        });
    }
    showWeekend = (code, rowIn, counter, detailType) => {
        if (this.state.Results === null || typeof this.state.Results.Sites[this.state.selectedSite] === "undefined") {
            return
        }
        let ls = [];
        let info = this.state.Results.Detail[code];
        console.log("2 (:)---> ", info)
        /*
        DayNormal   bool
	DaySaturday bool
	DaySunday   bool
	DayPhh      bool
         */
        for (let i in info) {
            let row = info[i];
            //let check for weekend Saturday and Sunday
            if (detailType === "weekend") {
                if (row.DaySaturday || row.DaySunday) {
                    ls.push(row)
                }
            } else if (detailType === "normal") {
                if (row.DayNormal) {
                    ls.push(row)
                }
            } else if (detailType === "holiday") {
                if (row.DayPhh) {
                    ls.push(row)
                }
            } else {
                ls.push(row)
            }

        }
        this.setState({
            showViewDetail: true,
            detailCode: code,
            detailRecord: rowIn,
            detailList: ls,
            detailType: detailType
        })
    }
    submitSearch = async () => {
        this.setState({
            isLoading: true,
            Results: null,
        });

        /*
        hub.PayCycleRef = "2020-08-01#2020-08-31#6"
	hub.Org = "C100015"
	hub.Ref = "Cleaning of Public Toilets/Bath Houses - Group 3"
         */

        let hub = {
            Org: this.state.selectedCompany,
            PayCycleRef: this.state.selectedPeriod,
            Ref: this.state.selectedModule,
        }
        console.log("1 -Data--> ", hub)
        let endpoint = "/report/paycycle/employee"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            this.setState({
                Results: data,
                isLoading: false,
            });
            console.log("-Data--> ", data)
        });

    }
    renderWarningBar = () => {
        return
        let boo = false
        if (this.state.selectedCompany === "" || this.state.selectedEntity === "" || this.state.component === "form") {
            boo = true
        }
        if (!boo) {
            return
        }
        return (
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Alert style={{margin: '16px 0'}} message="Please select your company above to view your records!"
                       type={"error"}/>
            </div>
        )
    }
    renderCompaniesSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={""}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedCompany")}
                    value={this.state.selectedCompany}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderModules = () => {
        let ls = [];
        let org = this.state.selectedCompany;
        for (let i in this.state.Entities) {
            const row = this.state.Entities[i];

            if (row.org === org) {
                ls.push({
                    key: row.name,
                    val: row.name,
                })
            }
        }
        return (
            <Select
                name={"selectedModule"}
                defaultValue=""
                onChange={(e, ee) => this.handleChange(ee, "selectedModule")}
            >
                {ls.map((record, index) => {
                    return (
                        <Option value={record.key}>{record.val}</Option>
                    )
                })}
            </Select>
        )
    }
    renderSite = () => {
        let ls = [];
        for (let i in this.state.DataSite) {
            let row = this.state.DataSite[i];
            ls.push({
                key: row.Name,
                val: row.Name
            })
        }
        return (
            <React.Fragment>
                <Form.Item label={"Site"} required={true}>
                    <Select
                        name={"selectedSite"}
                        defaultValue={this.state.selectedSite}
                        value={this.state.selectedSite}
                        onChange={(e, ee) => this.handleChange2(e, ee, "selectedSite")}
                    >
                        {ls.map((record, index) => {
                            return (
                                <Option value={record.key}>{record.val}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            </React.Fragment>
        )
    }
    formatDate = (dateIn) => {
        return convertDateFormat_DDMMYYYY(dateIn)
    }
    renderPayCycle = () => {
        let data = this.state.DataPayCycle;
        data = SortData("Period", data)
        let ls = [];
        for (let i in data) {
            let row = data[i];
            const key = row.StartDate + "#" + row.EndDate + "#" + row.Period;
            const display = row.Period + ". -> Start: " + this.formatDate(row.StartDate) + " -> End: " + this.formatDate(row.EndDate) +
                " -> Pay date: " + this.formatDate(row.PayDate);
            ls.push({
                key: key,
                val: display
            })
        }
        return (
            <React.Fragment>
                <Form.Item label={"Pay Cycle"} required={true}>
                    <Select
                        name={"selectedPeriod"}
                        defaultValue={this.state.selectedPeriod}
                        value={this.state.selectedPeriod}
                        onChange={(e, ee) => this.handleChange2(e, ee, "selectedPeriod")}
                    >
                        {ls.map((record, index) => {
                            return (
                                <Option value={record.key}>{record.val}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            </React.Fragment>
        )
    }
    renderReportSummaryPeriod = () => {
        if (this.state.Results === null || typeof this.state.Results === "undefined") {
            return
        }
        let info = this.state.Results;

        return this.renderReportSummaryBar({
            title: "Period Summary",
            employees: info.TotalWorker,
            payees: info.TotalPayees,
            grossPay: formatCurrency(info.TotalGrossPay),
            phhPay: info.TotalPhhPay,
            uif: formatCurrency(info.TotalUif),
            netPay: formatCurrency(info.TotalNetPay),
            deduction: formatCurrency(info.TotalDeduction),
            noPay: info.TotalNoPay,
        })
    }
    renderReportSummarySite = () => {
        if (this.state.Results === null || typeof this.state.Results.Sites[this.state.selectedSite] === "undefined") {
            return
        }
        let info = this.state.Results.Sites[this.state.selectedSite];
        return this.renderReportSummaryBar({
            title: "Site Summary",
            employees: info.Employees,
            payees: info.Payees,
            grossPay: formatCurrency(info.GrossPay),
            phhPay: formatCurrency(info.PhhPay),
            uif: formatCurrency(info.Uif),
            netPay: formatCurrency(info.NetPay),
            noPay: info.NoPay,
            deduction: info.Deduction,
        })
    }
    renderReportSummaryBar = (props) => {

        return (
            <div>
                <Divider orientation="left" plain>
                    <span style={styles.blockSummaryText}>{props.title}</span>
                </Divider>
                <Row style={styles.rowReport}>
                    <Col span={3} style={styles.colCenter}>
                        <span style={styles.colReportTitle}>Employees</span>
                    </Col>
                    <Col span={3} style={styles.colCenter}>
                        <span style={styles.colReportTitle}> Payees</span>
                    </Col>
                    <Col span={3} style={styles.colCenter}>
                        <span style={styles.colReportTitle}>GrossPay</span>
                    </Col>
                    <Col span={3} style={styles.colCenter}>
                        <span style={styles.colReportTitle}>PPH Pay</span>
                    </Col>
                    <Col span={3} style={styles.colCenter}>
                        <span style={styles.colReportTitle}>UIF</span>
                    </Col>
                    <Col span={3} style={styles.colCenter}>
                        <span style={styles.colReportTitle}>Deduction</span>
                    </Col>
                    <Col span={3} style={styles.colCenter}>
                        <span style={styles.colReportTitle}>Net Pay</span>
                    </Col>
                    <Col span={3} style={styles.colCenter}>
                        <span style={styles.colReportTitle}>No Pay</span>
                    </Col>
                </Row>
                <Row style={{...styles.rowReport,/*,borderTopWidth:1, borderColor: "white",borderStyle:"solid"*/}}>

                    <Col span={3} style={styles.colCenter}>
                        <span style={styles.colReportBody}>{props.employees}</span>
                    </Col>
                    <Col span={3} style={styles.colCenter}>
                        <span style={styles.colReportBody}>{props.payees}</span>
                    </Col>
                    <Col span={3} style={styles.colCenter}>
                        <span style={styles.colReportBody}>{props.grossPay}</span>
                    </Col>
                    <Col span={3} style={styles.colCenter}>
                        <span style={styles.colReportBody}>{props.phhPay}</span>
                    </Col>
                    <Col span={3} style={styles.colCenter}>
                        <span style={styles.colReportBody}>{props.uif}</span>
                    </Col>
                    <Col span={3} style={styles.colCenter}>
                        <span style={styles.colReportBody}>{props.deduction}</span>
                    </Col>
                    <Col span={3} style={styles.colCenter}>
                        <span style={styles.colReportBody}>{props.netPay}</span>
                    </Col>
                    <Col span={3} style={styles.colCenter}>
                        <span style={styles.colReportBody}>{props.noPay}</span>
                    </Col>
                </Row>
            </div>
        )
    }
    renderReportSummaryWorker = () => {
        if (this.state.Results === null || typeof this.state.Results.Sites[this.state.selectedSite] === "undefined") {
            return
        }

        let info = this.state.Results.Workers;
        console.log("1 (:)---> ", this.state.Results)
        let ls = [];
        for (let i in info) {
            let row = info[i];
            console.log("(:)---> ", row.Code, " > ", (row.Site !== this.state.selectedSite), " > ", row.Site, " > ", this.state.selectedSite)
            if (row.Site !== this.state.selectedSite) {
                continue
            }
            ls.push(row)
        }

        return (
            <div>
                <Row>
                    <Col span={1} style={styles.RowHeaderAlignCenter}>
                        &nbsp;
                    </Col>
                    <Col span={2} style={styles.RowHeaderAlignLeft}>
                        Code
                    </Col>
                    <Col span={4} style={styles.RowHeaderAlignLeft}>
                        Name
                    </Col>
                    <Col span={2} style={styles.RowHeaderAlignLeft}>
                        Designation
                    </Col>
                    <Col span={1} style={styles.RowHeaderAlignCenter}>
                        Days
                    </Col>
                    <Col span={1} style={styles.RowHeaderAlignCenter}>
                        WKDAY
                    </Col>
                    <Col span={1} style={styles.RowHeaderAlignCenter}>
                        W/END
                    </Col>
                    <Col span={1} style={styles.RowHeaderAlignCenter}>
                        PPH
                    </Col>
                    <Col span={1} style={styles.RowHeaderAlignCenter}>
                        Hours
                    </Col>
                    <Col span={1} style={styles.RowHeaderAlignRight}>
                        GrossPay
                    </Col>
                    <Col span={1} style={styles.RowHeaderAlignRight}>
                        UIF
                    </Col>
                    <Col span={1} style={styles.RowHeaderAlignRight}>
                        Deduction
                    </Col>
                    <Col span={1} style={styles.RowHeaderAlignRight}>
                        NetPay
                    </Col>
                    <Col span={1} style={styles.RowHeaderAlignRight}>
                        NoPay
                    </Col>
                </Row>
                {ls.map((row, index) => {
                    return (
                        <Row>
                            <Col span={1} style={styles.RowBodyAlignCenter}>
                                &nbsp;
                                <FilePptOutlined
                                    style={{width:50,color:"red",cursor:"pointer"}}
                                    onClick={()=>this.generatePaySlip(row)}
                                />
                            </Col>
                            <Col span={2} style={styles.RowBodyAlignLeft}>
                                {row.Code}
                            </Col>
                            <Col span={4} style={styles.RowBodyAlignLeft}>
                                {row.Name}
                            </Col>
                            <Col span={2} style={styles.RowBodyAlignLeft}>
                                {row.Role}
                            </Col>
                            <Col span={1} style={styles.RowBodyAlignCenter}>
                                <a onClick={() => this.showWeekend(row.Code, row, row.WeekendPay, "all")}>
                                    {parseInt(row.WeekPay) + parseInt(row.WeekendPay) + parseInt(row.PhhPay)}
                                </a>
                            </Col>
                            <Col span={1} style={styles.RowBodyAlignCenter}>
                                <a onClick={() => this.showWeekend(row.Code, row, row.WeekendPay, "normal")}> {row.WeekPay}</a>
                            </Col>
                            <Col span={1} style={styles.RowBodyAlignCenter}>
                                <a onClick={() => this.showWeekend(row.Code, row, row.WeekendPay, "weekend")}> {row.WeekendPay}</a>
                            </Col>
                            <Col span={1} style={styles.RowBodyAlignCenter}>
                                <a onClick={() => this.showWeekend(row.Code, row, row.WeekendPay, "holiday")}> {row.PhhPay}</a>
                            </Col>
                            <Col span={1} style={styles.RowBodyAlignCenter}>
                                {row.Hours}
                            </Col>
                            <Col span={1} style={styles.RowBodyAlignRight}>
                                R {row.GrossPay}
                            </Col>
                            <Col span={1} style={styles.RowBodyAlignRight}>
                                R {row.Uif}
                            </Col>
                            <Col span={1} style={styles.RowBodyAlignRight}>
                                R {row.Deduction}
                            </Col>
                            <Col span={1} style={styles.RowBodyAlignRight}>
                                R {row.NetPay}
                            </Col>
                            <Col span={1} style={styles.RowBodyAlignRight}>
                                {row.NoPay}
                            </Col>


                        </Row>
                    )
                })}

            </div>
        )

    }
    renderReportPopupDetail = () => {
        if (this.state.Results === null || typeof this.state.Results.Sites[this.state.selectedSite] === "undefined") {
            return
        }

        return (
            <PopupDetail _this={this}/>
        )
    }
    renderReportPopupExportCsvPastel = () => {
        if (this.state.Results === null || typeof this.state.Results.Sites[this.state.selectedSite] === "undefined") {
            return
        }
        let informations = this.buildExportCsvPastel();
        return (
            <PopupExportPastel _this={this} {...informations}/>
        )
    }
    renderReportPopupExportCsvBank = () => {
        if (this.state.Results === null || typeof this.state.Results.Sites[this.state.selectedSite] === "undefined") {
            return
        }
        let data = this.buildBankExportCsv();
        return (
            <PopupExportBank _this={this} data={data}/>
        )
    }
    getTotalShift = () => {
        if (this.state.Results === null || typeof this.state.Results.Sites[this.state.selectedSite] === "undefined") {
            return 0
        }
        let tot = 0;
        let info = this.state.Results.Workers;
        let ls = [];
        for (let i in info) {
            let row = info[i];
            tot += parseInt(row.WeekPay) + parseInt(row.WeekendPay) + parseInt(row.PhhPay)
        }
        return tot;
    }
    getPayCycleInfo = () => {
        let selectedPeriod = this.state.selectedPeriod;
        let data = this.state.DataPayCycle;
        data = SortData("Period", data)
        let ls = {StartDate: "", EndDate: "", Period: 0, PayDate: ""};
        for (let i in data) {
            let row = data[i];
            const key = row.StartDate + "#" + row.EndDate + "#" + row.Period;
            if (selectedPeriod === key) {
                ls = row;
            }
        }
        return ls
    }
    buildBankExportCsv = () => {
        let ls = [];
        if (this.state.Results === null || typeof this.state.Results.Sites[this.state.selectedSite] === "undefined") {
            return ls;
        }
        const payInfo = this.getPayCycleInfo();
        let databases = {};
        for (let i in this.state.Databases) {
            let row = this.state.Databases[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.module !== this.state.selectedModule) {
                continue
            }
            let bank = "";
            let acc = "";
            let branch = "";
            let extRef = "";
            let name = "";
            let surname = "";

            console.log("EMP (:)---> ",row.data)
            if (typeof row.data[this.state.BankAccountKey] !== "undefined") {
                acc = row.data[this.state.BankAccountKey];
            }
            if (typeof row.data[this.state.BankBranchKey] !== "undefined") {
                branch = row.data[this.state.BankBranchKey];
            }
            if (typeof row.data[this.state.BankSecondaryKey] !== "undefined") {
                extRef = row.data[this.state.BankSecondaryKey];
            }
            if (typeof row.data[this.state.BankHolderName] !== "undefined") {
                name = row.data[this.state.BankHolderName];
            }
            if (typeof row.data[this.state.BankHolderSurname] !== "undefined") {
                surname = row.data[this.state.BankHolderSurname];
            }
            if (typeof row.data[this.state.BankName] !== "undefined") {
                bank = row.data[this.state.BankName];
            }
            databases[row.ref] = {
                bankAccount: acc,
                bankBranch: branch,
                extRef: extRef,
                name: name,
                surname: surname,
                bankName: bank,
            }
        }
        console.log("EMP (:)---> ",databases)
        let lsCsv = [];
        /*lsCsv.push({
            "RECIPIENT NAME":'',
            "RECIPIENT ACCOUNT":'',
            "RECIPIENT ACCOUNT TYPE":'',
            "BRANCHCODE":'',
            "AMOUNT":'',
            "OWN REFERENCE":'',
            "RECIPIENT REFERENCE":''
        })*/
        const workers = this.state.Results.Workers;
        const shortPayDate = moment(payInfo.PayDate, "YYYY-MM-DD").format("MMM-YYYY")
        for (let i in workers) {
            const row = workers[i];
            let ourRef = this.state.BankOwnRefPrefix + " " + shortPayDate;
            let theirRef = this.state.BankTheirRefPrefix + "" + shortPayDate;

            if(typeof  databases[row.Code]==="undefined"){continue}
            const workerInfo = databases[row.Code]

            lsCsv.push({
                "RECIPIENT NAME":workerInfo.name+' '+workerInfo.surname,
                "RECIPIENT ACCOUNT":workerInfo.bankAccount,
                "RECIPIENT ACCOUNT TYPE":this.state.BankHolderAccountType,
                "BRANCHCODE":workerInfo.bankBranch,
                "AMOUNT":row.NetPay.toFixed(2),
                "OWN REFERENCE":ourRef,
                "RECIPIENT REFERENCE":theirRef
            })
        }
        return lsCsv;
    }
    buildExportCsvPastel = () => {
        let lsNormal = [];
        let lsNormalCsv = [];
        let lsSaturday = [];
        let lsSaturdayCsv = [];
        let lsSunday = [];
        let lsSundayCsv = [];
        let lsPhh = [];
        let lsPhhCsv = [];

        if (this.state.Results === null || typeof this.state.Results.Sites[this.state.selectedSite] === "undefined") {
            return {
                lsNormal,
                lsNormalCsv,
                lsSaturday,
                lsSaturdayCsv,
                lsSunday,
                lsSundayCsv,
                lsPhh,
                lsPhhCsv,
            }
        }


        const builder = (empCode, fullname, transCode, numDays, rate, override, storeData, storeCsv) => {
            storeData.push({
                EmployeeCode: empCode,
                Employee: fullname,
                TransactionCode: transCode,
                JobCode: '',
                CostCentre: "",
                Quantity: numDays,
                Rate: rate.toFixed(2),
                Amount: "",
                Override: override
            });
            storeCsv.push({
                Code: empCode,
                Tans: transCode,
                Blanc1: '',
                Blanc2: '',
                Days: numDays,
                Rate: rate.toFixed(1),
                Blanc3: '',
                Override: override
            })
        }

        //let build normal days
        const workers = this.state.Results.Workers;
        for (let i in workers) {
            const row = workers[i];
            const ref = row.Code;
            const name = row.Name;
            let totNormal = 0;
            let rateNormal = 0;
            let totSaturday = 0;
            let rateSaturday = 0;
            let totSunday = 0;
            let rateSunday = 0;
            let totPhh = 0;
            let ratePhh = 0;

            let info = this.state.Results.Detail[ref];
            for (let i in info) {
                let rec = info[i];
                if (rec.DayNormal) {
                    totNormal = totNormal + parseInt(rec.Hours);
                    rateNormal = rec.RateEmployee;
                }
                if (rec.DaySaturday) {
                    totSaturday += parseInt(rec.Hours);
                    rateSaturday = rec.RateEmployee;
                }
                if (rec.DaySunday) {
                    totSunday += parseInt(rec.Hours);
                    rateSunday = rec.RateEmployee;
                }
                if (rec.DayPhh) {
                    totPhh += parseInt(rec.Hours);
                    ratePhh = rec.RateEmployee;
                }
            }
            builder(ref, name, "NORM", totNormal, rateNormal, "N", lsNormal, lsNormalCsv)
            builder(ref, name, "SATU", totSaturday, rateSaturday, "N", lsSaturday, lsSaturdayCsv)
            builder(ref, name, "SUND", totSunday, rateSunday, "N", lsSunday, lsSundayCsv)
            builder(ref, name, "PUBL", totPhh, ratePhh, "N", lsPhh, lsPhhCsv)
        }

        return {
            lsNormal,
            lsNormalCsv,
            lsSaturday,
            lsSaturdayCsv,
            lsSunday,
            lsSundayCsv,
            lsPhh,
            lsPhhCsv,
        }
    }
    openShowViewPastel = () => {
        this.setState({
            showViewPastel: true
        })
    }
    openShowViewBank= () => {
        this.setState({
            showViewBank: true
        })
    }
    generatePaySlip=async (rowIn)=>{
        let _this = this;
        if(!window.confirm("Are you to generate payslip for employee: "+rowIn.Name)){
            return
        }
        let pDate = this.getPayDate(this.state.selectedPeriod);
        let hub = {
            Org: this.state.selectedCompany,
            PayCycleRef: this.state.selectedPeriod,
            Contract: this.state.selectedModule,
            PayDate: pDate,
            Ref:rowIn.Code,

        }
        this.setState({
            currentPayslip: null,
            isLoading: true,
        });
        console.log("1 -Data--> ", hub)
        console.log("generatePaySlip > ",rowIn);
        let endpoint = "/payslip/single/generate"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            this.setState({
                currentPayslip: data,
                isLoading: false,
            });
            // DropBoxRev
            const server = GetConfigures();
            const dropboxId = data.DropBoxRev;
            const endpoint ="/files/download/"+dropboxId;
            let url = server + "" + endpoint;

            window.document.title = "Payslip - "+rowIn.Code+" - "+rowIn.Name;
            window.open(url, '_blank');

            console.log("out-Data--> ", data)
        });

    }
    getPayDate=(inPeriod)=>{
        let data = this.state.DataPayCycle;
        data = SortData("Period", data)
        let pDate = "";
        for (let i in data) {
            let row = data[i];
            const key = row.StartDate + "#" + row.EndDate + "#" + row.Period;
            const display = row.Period + ". -> Start: " + this.formatDate(row.StartDate) + " -> End: " + this.formatDate(row.EndDate) +
                " -> Pay date: " + this.formatDate(row.PayDate);
            if(inPeriod===key){
                pDate =moment(row.PayDate, "YYYY-MM-DD").format("DD/MM/YYYY"); //this.formatDate(row.PayDate)
            }
        }
        return pDate;
    }

    render() {
        let props = this.props;
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Pay Cycle Days Report</Title>
                    <Row gutter={16} style={{marginTop: 20}}>
                        <Col span={12}>
                            <Form.Item
                                label={"Company"}
                            >
                                {this.renderCompaniesSelection()}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={"Project"}
                            >
                                {this.renderModules()}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{marginTop: -10}}>
                        <Col span={12}>
                            {this.renderPayCycle()}
                        </Col>
                        <Col span={12}>
                            {this.renderSite()}
                        </Col>
                    </Row>
                    <Row gutter={16} style={{marginTop: -10}}>
                        <Col span={24}>
                            <Divider orientation="center" plain>
                                <Button danger onClick={() => this.submitSearch()}>Search
                                    ({this.getTotalShift()})</Button>&nbsp;&nbsp;
                                <Button danger onClick={() => this.openShowViewPastel()}>Pastel</Button>
                                &nbsp;&nbsp;
                                <Button danger onClick={() => this.openShowViewBank()}>Bank</Button>
                                &nbsp;&nbsp;
                                <Button danger onClick={() => alert("Coming soon!")}>Invoice</Button>
                                &nbsp;&nbsp;
                                <Button danger onClick={() => alert("Coming soon!")}>Payslip</Button>
                            </Divider>
                        </Col>
                    </Row>

                    {this.renderReportSummaryPeriod()}
                    {this.renderReportSummarySite()}
                    <br/>
                    <br/>
                    {this.renderReportSummaryWorker()}
                    {this.renderReportPopupDetail()}
                    {this.renderReportPopupExportCsvPastel()}
                    {this.renderReportPopupExportCsvBank()}
                    {this.renderWarningBar()}

                </div>

            </Page>
        )
    }
}


export default Connector(ReportPaymentCycleDaysPage);

const styles = {
    RowHeaderAlignRight: {
        fontSize: 11,
        color: "black",
        /*fontWeight:"bold",*/
        textAlign: "right",
    },
    RowHeaderAlignLeft: {
        fontSize: 11,
        color: "black",
        /*fontWeight:"bold",*/
        textAlign: "right",
    },
    RowHeaderAlignCenter: {
        fontSize: 11,
        color: "black",
        /* fontWeight:"bold",*/
        textAlign: "center",
    },

    RowBodyAlignRight: {
        fontSize: 11,
        color: "gray",
        textAlign: "right",
    },
    RowBodyAlignLeft: {
        fontSize: 11,
        color: "gray",
        textAlign: "right",
    },
    RowBodyAlignCenter: {
        fontSize: 11,
        color: "gray",
        textAlign: "center",
    },


    firstRowCol1: {
        textAlign: "right"
    },
    headerColor: {
        color: "orange",
        textAlign: "center"
    },
    contentCenter: {
        display: "flex",
        justifyContent: "center"
    },
    blockSummaryText: {
        fontSize: 18, color: "black", fontWeight: "150", marginTop: 10
    },
    colCenter: {
        textAlign: "right",

    },
    colReportTitle: {
        fontSize: 18,
        color: "gray",
        fontWeight: "bold"
    },
    colReportBody: {
        fontSize: 18,
        color: "black",
        fontWeight: "bold"
    },
    rowReport: {
        backgroundColor: "lightgray"
    },
    gutterBox: {
        padding: "8px 0",
        margin: 10,
        /*backgroundColor: "#00a0e9",
        minWidth: "50%",
        maxWidth: "50%"*/
    },
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
