
import React from "react";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import {Layout, Select, Input} from "antd";
import {GetUserToken} from "../../../../api/token";
import PluginAddDeleteList from "../../../../components/Common/PluginAddDeleteList2";
import {PostToPbx} from "../../../../api/http-post";
import {fetchDataEntity} from "../../../../api/entity-data";

const {TextArea} = Input;
const {Content} = Layout;
const {Option} = Select;

class IntegrationPastelProjectPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Modules: [],
            Products: [],
            isLoading: false,
            company: "",
            component: "list",
            newEntry: {},
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await this.loadData("modules", "Modules");
    }
    loadData = async (targetEntity, stateKey) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            await _this.setState({
                [stateKey]: data.RESULT
            })
        });
    }

    getModuleList = () => {
        let ls = [];
        for (let i in this.state.Modules) {
            let row = this.state.Modules[i];
            if (row.org === this.state.newEntry.org) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    getCompanyList = () => {
        let ls = [];
        for (let i in this.state.Companies) {
            let row = this.state.Companies[i];
            ls.push({
                key: row.CustomerNumber,
                val: row.Name,
            });
        }
        return ls;
    }


    render() {
        const companyOptions = this.getCompanyList();
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Setup Pastel Project</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"PastelProject"}
                            depending={{
                                "org": {
                                    targetKey: "CustomerNumber",
                                    displayKey: "Name",
                                    storageTable: "Companies"
                                }
                            }}
                            fields={[
                                {
                                    name: "org",
                                    dataType: "option",
                                    required: true,
                                    label: "Organization",
                                    options: companyOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Companies"}
                                },
                                {
                                    name: "name",
                                    dataType: "string",
                                    required: true,
                                    label: "Name",
                                    options: []
                                },

                            ]}
                            deleteKeys={[
                                {key: "org", type: "string"},
                                {key: "name", type: "string"},
                            ]}
                            stateArray={"Data"}
                            table={"PastelProject"}
                            addFormText={"Project"}
                            stepTitle={"Setup new Project"}

                            filterByCompany={true}

                        />

                    </div>
                </div>
            </Page>
        )
    }

}

export default Connector(IntegrationPastelProjectPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

