import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {Layout, Select, Input} from "antd";
import {GetUserToken} from "../../../api/token";
import PluginAddDeleteList from "../../../components/Common/PluginAddDeleteList2";
import {PostToPbx} from "../../../api/http-post";
import {fetchDataEntity} from "../../../api/entity-data";

const {TextArea} = Input;
const {Content} = Layout;
const {Option} = Select;

class ComplianceSetupRequiredPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Modules: [],
            Products: [],
            isLoading: false,
            company:"",
            component: "list",
            newEntry:{},
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await this.loadData("modules", "Modules");
    }
    loadData = async (targetEntity, stateKey) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            console.log("SettingProductPage loadInitialData response > ", post, " > ", data);
            await _this.setState({
                [stateKey]: data.RESULT
            })
        });
    }

    getBooleanOption = () => {
        let ls = [];
        ls.push({
            key: "yes",
            val: "Yes"
        });
        ls.push({
            key: "no",
            val: "No"
        })
        return ls
    }

    getModuleList = () => {
        let ls = [];
        console.log("getModuleList > ",this.state);
        for (let i in this.state.Modules) {
            let row = this.state.Modules[i];
            if (row.org === this.state.newEntry.org) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    getCompanyList = () => {
        let ls = [];
        for (let i in this.state.Companies) {
            let row = this.state.Companies[i];
            ls.push({
                key: row.CustomerNumber,
                val: row.Name,
            });
        }
        return ls;
    }

    render() {
        const modulesOptions = this.getModuleList();
        const companyOptions = this.getCompanyList();
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Compliance Setup</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"ComplianceSetup"}
                            depending={{"org":{targetKey:"CustomerNumber",displayKey:"Name",storageTable:"Companies"}}}
                            fields={[
                                {
                                    name: "org",
                                    dataType: "option",
                                    required: true,
                                    label: "Company",
                                    options: companyOptions,
                                    depending:{stateKey:"org",displayKey:"Name",storageTable:"Companies"}
                                },
                                {
                                    name: "module",
                                    dataType: "option",
                                    required: true,
                                    label: "Project & Contract",
                                    options: modulesOptions,

                                },
                                {name: "name", dataType: "string", required: true, label: "Name", options: []},
                                {name: "scoreemployee", dataType: "float", required: true, label: "Employee Score", options: []},
                                {name: "scorecompany", dataType: "float", required: true, label: "Company Score", options: []},

                            ]}
                            deleteKeys={[
                                {key: "org", type: "string"},
                                {key: "module", type: "string"},
                                {key: "name", type: "string"},
                            ]}
                            stateArray={"Data"}
                            table={"ComplianceSetup"}
                            addFormText={"Compliance Setup"}
                            stepTitle={"Set Up new Compliance"}
                        />
                    </div>
                </div>
            </Page>
        )
    }

}


export default Connector(ComplianceSetupRequiredPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

/*
<div style={{minWidth:"100%"}}>
                    <Button
                        style={{maxWidth: "10%",minWidth: "10%",float:"right"}}
                        onClick={()=>this.submitForm()}

                    >
                        Submit
                    </Button>
                </div>
 */



/*

import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {Alert, Form, Layout, Select, Input, Button} from "antd";
import {fetchDataEntity} from "../../../api/entity-data";
import {GetUserToken} from "../../../api/token";
import {SortData, UpCaseFirst} from "../../../api/fn";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {NewAttributeFormStep} from "../../../components/web/Database/NewAttributeFormStep";
import {SubmitPost} from "../Common/localFunctions";
const {Content} = Layout;
const {Option} = Select;


class ComplianceSetupRequiredPage extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            Companies:[],
            Entities:[],
            selectedCompany:"",
            selectedEntity:"",
            selectedRecord:{},
            Data:[],
            isLoading:false,
            component:"list",
            Name:"",
            Score:"",
            AllowUserCapture:"",
            Required:"",

        }
    }
    componentDidMount=async () =>{
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.initialReload()
    }
    initialReload=async()=>{
        await fetchDataEntity("Entities", "", "", this,undefined,"common/entity/modules/list");
        await fetchDataEntity("Data", "", "", this,undefined,"common/entity/ComplianceSetup/list")
    }
    loadInitialData=async()=>{
        await fetchDataEntity("Data", "", "", this,undefined,"common/entity/ComplianceSetup/list")
    }
    onAddRecord=async ()=>{
        let record ={}
        for(let i in this.state.Entities){
            const row = this.state.Entities[i];
            console.log("@@@@---> ",this.state.selectedCompany,"!=",row.org," > ",this.state.selectedCompany!==row.org)
            if(this.state.selectedCompany!==row.org){
                continue
            }
            console.log("@@@@---> 2: ",this.state.selectedEntity,"!=",row," > ",this.state.selectedEntity!==row.name)
            if(this.state.selectedEntity!==row.name){
                continue
            }
            record = row
        }
        this.setState({
            component:"form",
            selectedRecord:record
        })
    }
    handleChangeCompany = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    submitForm = async () => {
        let _this = this;
        if (this.state.selectedEmployee === "") {
            return alert("Field validation fail,please select your employee!")
        }
        let state = this.state;
        let user = GetUserToken();
        if (!window.confirm("Are you sure that u want to submit compliance [" + this.state.name + "] ?" )) {
            return
        }
        _this.setState({
            isLoading: false,
        });

        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";

        let hub = {};
        hub.Org = this.state.selectedCompany;
        hub.Module = this.state.selectedEntity;
        hub.Name = state.Name;
        hub.Score = parseFloat(state.Score);
        hub.Required = state.Required==="yes";
        hub.AllowUserCapture = state.AllowUserCapture==="yes";

        post.Data = hub;
        post.Params = {}

        let endpoint = "/common/entity/ComplianceSetup/insert";
        console.log("submitForm > ",post)

        await SubmitPost(post, endpoint, "Thank you , Compliance setup submitted", this, async (dIn) => {
            alert("Thank you , Compliance requirement submitted!")
            _this.setState({
                isLoading: false,
                showNewForm: false,
                component: "list",
                Name:"",
                Score:"",
                AllowUserCapture:"",
                Required:"",
            });
            await _this.initialReload();
            window.location.reload();
            // alert("Thank you , attributes submitted")
        })

    }
    renderCompaniesSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={"Select Company"}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedCompany")}
                    value={this.state.selectedCompany}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderModuleSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = [];
        for(let i in this.state.Entities){
            const row = this.state.Entities[i];
            if(row.org!==this.state.selectedCompany){continue}
            ls.push(row);
        }
        return (
            <Form.Item
                label={"Select Project"}
                name={"selectedEntity"}
                rules={[{required: true, message: "Please select your project!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedEntity")}
                    value={this.state.selectedEntity}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.name}
                                value={record.name}
                            >
                                {record.name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderList=()=> {

        let user = GetUserToken();
        let columns = [
            {id:"module",label:"Project"},
            {id:"name",label:"Name of Compliance"},
            {id:"score",label:"Score"},
            {id:"required",label:"Required"},
            {id:"allowusercapture",label:"Allow User Capture"},
        ];
        if (this.state.component !== "list") {
            return
        }

        let ls=[];
        for(let i in this.state.Data){
            const row=this.state.Data[i];
            if(row.org!==this.state.selectedCompany){continue}
            if(row.module!==this.state.selectedEntity){continue}
            ls.push(row);
        }

        ls = SortData("position",ls)

        let data = {columns: columns, data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Records")}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                />
            </GridItem>
        )

    }
    closeNewForm = () => {
        this.setState({
            showNewForm: false,
            component: "list",
            selectedRecord:null

        });
    }
    renderForm=()=>{
        if (this.state.component !== "form") {
            return
        }
        let _this = this;
        let getStateValue=(key)=>{
            return _this.state[key];
        }
        const buildSelector = ({stateKey, options, label, msg,handleChange,getStateValue}) => {
            return (
                <Form.Item
                    label={label}
                    name={stateKey}
                    rules={[{required: true, message: "Please select your " + msg}]}
                >
                    <Select
                        style={{width: "100%"}}
                        onChange={(value) => handleChange(value, stateKey)}
                        value={getStateValue(stateKey)}
                    >
                        {options.map((record, index) => {
                            return (
                                <Option
                                    key={record.key}
                                    value={record.val}
                                >
                                    {record.val}
                                </Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            )
        }
        const buildInput= ({stateKey, label, msg,handleChange,state,getStateValue}) => {
            return (
                <Form.Item
                    label={label}
                    name={stateKey}
                    rules={[{required: true, message: "Please select your " + msg}]}
                >
                    <Input
                        style={{width: "100%"}}
                        onChange={(value) => handleChange(value, stateKey)}
                        value={getStateValue(stateKey)}
                    />

                </Form.Item>
            )
        }

        let fields = [
            {stateKey:"Name",label:"Compliance Name",msg:"compliance name",handleChange:this.handleChangeCompany,state:this.state,dataType:"input",getStateValue:getStateValue},
            {stateKey:"Score",label:"Compliance Score",msg:"compliance score",handleChange:this.handleChangeCompany,state:this.state,dataType:"input",getStateValue:getStateValue},
            {stateKey:"Required",label:"Required?",msg:"Required",handleChange:this.handleChangeCompany,state:this.state,dataType:"option",getStateValue:getStateValue,options:[
                    {key:"yes",val:"Yes"},
                    {key:"no",val:"No"},
                ]},
            {stateKey:"AllowUserCapture",label:"Allow User Capture",msg:"Allow User Capture",handleChange:this.handleChangeCompany,state:this.state,dataType:"option",getStateValue:getStateValue,options:[
                    {key:"yes",val:"Yes"},
                    {key:"no",val:"No"},
                ]},
        ]

        return (
            <div>
                <Alert
                    message={(
                        <div>
                            <span style={{float: "left"}}>New compliance required setup</span>
                            <a
                                style={{float: "right", color: "blue", cursor: "pointer"}}
                                onClick={this.closeNewForm}
                            >Close</a>
                        </div>
                    )}
                    style={{minHeight: 50}}
                />
                <br/>

                {fields.map(row=>{
                    if(row.dataType==="option"){
                        return buildSelector({...row})
                    }else if(row.dataType==="input"){
                        return buildInput({...row})
                    }
                })}
                <Form.Item
                    label={""}
                    name={""}
                    rules={[{required: true, message: "Please select your "}]}
                >
                    <Button
                        type="primary"
                        style={{float: "right"}}
                        onClick={() => this.submitForm()}

                    >
                        Submit
                    </Button>
                </Form.Item>
            </div>
        )


    }
    renderWarningBar=()=>{
        return
        let boo=false
        if(this.state.selectedCompany === "" || this.state.selectedEntity === "" ||this.state.component==="form"){
            boo=true
        }
        if(!boo){
            return
        }
        return(
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Alert style={{ margin: '16px 0' }} message="Please select your company above to view your records!" type={"error"}/>
            </div>
        )
    }
    render() {
        let props = this.props;
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Field Structure (Setup Column Headings)</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        {this.renderCompaniesSelection()}
                        {this.renderModuleSelection()}
                    </div>
                    {this.renderWarningBar()}
                    {this.renderList()}
                    {this.renderForm()}
                </div>
            </Page>
        )
    }
}

export default Connector(ComplianceSetupRequiredPage);

const styles = {
    container:{

    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
*/
