import React from "react";
import {Button, Drawer, Table,Row,Col} from 'antd';

const format = num => {
    const n = String(num),
        p = n.indexOf('.')
    return n.replace(
        /\d(?=(?:\d{3})+(?:\.|$))/g,
        (m, i) => p < 0 || i < p ? `${m},` : m
    )
}
export default (props) => {
    let {showView, data, setShowView} = props;

    const onClose = () => {
        setShowView(false)
    }

    const numFormat=(numberIn)=>{
         numberIn = parseFloat(numberIn)
        let val = format(numberIn);
        let val2 = val.replace(","," ");
        return val2
    }

    const renderAllPeriod=()=>{
        let roles = {};
        let totalAllEmployee=0;
        let totalAllWage=0;
        for(let i in data) {
            let row = data[i];
           let role =  row.EmpDesignation;
           roles[role] = true;
            totalAllEmployee++;
            totalAllWage +=row.NetPay
        }

        let ls=[];
        for(let role in roles){
            let totEmp=0;
            let totWage=0;
            for(let i in data) {
                let row = data[i];
                if( row.EmpDesignation===role){
                    totEmp++;
                    totWage +=parseFloat(row.NetPay)
                }
            }
            ls.push({
                role:role,
                emp:totEmp,
                wage:totWage
            })
        }

        return(
            <>
                <Row >
                    <Col span={14} style={styles.Row1Col}>Total Employee:</Col>
                    <Col span={10} style={{...styles.Row1Col,textAlign:"right"}}>{totalAllEmployee}</Col>
                </Row>
                <Row>
                    <Col span={14}  style={styles.Row1Col}>Total Wage:</Col>
                    <Col span={10} style={{...styles.Row1Col,textAlign:"right"}}>{numFormat(totalAllWage.toFixed(2))}</Col>
                </Row>
                <br/><br/>
                <Row >
                    <Col span={10} style={{...styles.Row1Col,textAlign:"left",color:"#3880ff"}}>Designation</Col>
                    <Col span={4} style={{...styles.Row1Col,textAlign:"center",color:"#3880ff"}}>Employees</Col>
                    <Col span={10} style={{...styles.Row1Col,textAlign:"right",color:"#3880ff"}}>Wages</Col>
                </Row>
                {ls.map(row=>{
                    return(
                        <Row >
                            <Col span={10} style={{...styles.Row1Col,textAlign:"left"}}>{row.role}</Col>
                            <Col span={4} style={{...styles.Row1Col,textAlign:"center"}}>{row.emp}</Col>
                            <Col span={10} style={{...styles.Row1Col,textAlign:"right"}}>R{numFormat(row.wage.toFixed(2))}</Col>
                        </Row>
                    )
                })}
            </>
        )

    }


    return (
        <Drawer
            title={"Quick Summary (Payments)"}
            width={720}
            onClose={onClose}
            visible={showView}
            bodyStyle={{paddingBottom: 80}}
            footer={
                <div
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={onClose} style={{marginRight: 8}}>
                        Close
                    </Button>

                </div>
            }
        >
            {renderAllPeriod()}
        </Drawer>
    )

}

const styles={
    Row1:{
        border:"1px solid black"
    },
    Row1Col:{
        border:"1px solid black"
    }
}

