import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import {
    GetMonthList,
    GetYearList,
    LoadDataCommon,
    BuilderOptionComboBox,
    CheckConditions,
    BuilderTableList, SortData, formatMoney, FormatMoneySouthAfrica
} from "../../../../api/fn";
import moment from "moment";
import Title from "../../../../components/Common/Title";
import {Col, Form, Row, Select, Divider, Table, Button} from "antd";
import Page from "../../../../layout/web/Page";
import Connector from "../../../../redux/connector";
import DrawerNewEntry from "../../../../components/General/DrawerNewEntry";
import {UploadToDropBoxBase64WithBusket} from "../../../../services/dropbox-uploader";
import {PostToPbx} from "../../../../api/http-post";
import LoadingProcessing from "../../../../components/Common/page/Common/LoadingProcessing";
import {ServiceInvoiceHaddock} from "../../../../components/Invoicings/ServiceInvoice/ServiceInvoiceHaddock";
import {ServiceInvoiceAdhocMoreki} from "../../../../components/Invoicings/ServiceInvoice/ServiceInvoiceAdhocMoreki";
import {ServiceInvoiceAdhocSanitech} from "../../../../components/Invoicings/ServiceInvoice/ServiceInvoiceAdhocSanitech";
import {ServiceInvoiceAdhocStellenbosch} from "../../../../components/Invoicings/ServiceInvoice/ServiceInvoiceAdhocStellenbosch";
import {AddOrMinusToDate} from "../../../../api/date-functions";
import {getPeriodDataFilter} from "../../../../services/service-common";

const {Option} = Select;

class InvoiceDraftVaccinationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Clients: [],
            Items: [],
            Data: [],
            PurchaseOrders: [],
            Sequences: [],
            Periods: [],
            Sites: [],
            InvoiceTrackers: [],
            isLoading: false,
            newEntry: {
                Year: moment().year()
            },
            newHaddock: {},
            selectedCompany: "",
            selectedClient: "",
            selectedItem: "",
            selectedYear: "",
            selectedMonth: "",
            searchKey: "",

            selectedPeriodYear: "",
            selectedPeriodMonth: "",
            selectedPeriodNumber: "",
            selectedPeriodStart: "",
            selectedPeriodEnd: "",

            selectedPurchaseOrder: null,

            mode: "normal",// or haddock
            isHaddock: false,

            showForm: false,

            Result: null,

        }
    }

    componentDidMount = async () => {
        await this.loadInitialData()
    }
    loadInitialData = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await LoadDataCommon("modules", "Modules", this);
        await LoadDataCommon("SettingInvoiceServiceDebtors", "Clients", this);
        await LoadDataCommon("SettingInvoiceAddonLabourItems", "Items", this);
        await LoadDataCommon("PurchaseOrder", "PurchaseOrders", this);
        await LoadDataCommon("InvoiceNumberInfo", "Sequences", this);
        await LoadDataCommon("SettingInvoiceAddonLabourItemPeriod", "Periods", this);
        await LoadDataCommon("SettingInvoiceServiceAddonSite", "Sites", this);
        await LoadDataCommon("InvoiceTracker", "InvoiceTrackers", this);
    }


    closeDrawer = () => {
        this.setState({showForm: false})
    }

    handleInput = (val, key) => {
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp)
    }
    handleInputPeriod = (val, key) => {
        let tmp = this.state;
        tmp[key] = val;
        let record = JSON.parse(val);
        tmp.selectedPeriodYear = record.year;
        tmp.selectedPeriodMonth = record.month;
        tmp.selectedPeriodNumber = record.periodnumber;
        tmp.selectedPeriodStart = record.startdate;
        tmp.selectedPeriodEnd = record.enddate;
        this.setState(tmp)
    }

    findPurchaseOrder = () => {
        let pod = null
        for (let i in this.state.PurchaseOrders) {
            let row = this.state.PurchaseOrders[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.client !== this.state.selectedClient) {
                continue
            }
            if (row.year !== this.state.selectedPeriodYear) {
                continue
            }
            if (row.month !== this.state.selectedPeriodMonth) {
                continue
            }
            if (row.item !== this.state.selectedItem) {
                continue
            }

            pod = row
        }

        if (pod !== null)
            console.log("findPurchaseOrder > ", pod, " > ", pod.refnumber || "none")
        return pod;
    }
    getNextSequenceNumber = () => {
        let ls = [];
        for (let i in this.state.InvoiceTrackers) {
            let row = this.state.InvoiceTrackers[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            let current =0;
            try {
                current =parseInt( row.invoicenumber);
                if(!isNaN(current)){
                    ls.push(current);
                }
            }catch (e) {

            }
        }
        ls.sort(function (a, b) {
            return a - b;
        });
        console.log(ls);
        let index = ls.length;
        let nextValue = ls[index - 1];

        if (typeof nextValue === "undefined") {
            nextValue = 0
        }
        return nextValue + 1;
    }

    getPeriodData = () => {
        return getPeriodDataFilter(this)
        let ls = [];
        let conditions = [
            {key: "org", val: this.state.selectedCompany},
            {key: "client", val: this.state.selectedClient},
            {key: "item", val: this.state.selectedItem},
        ]
        let data = SortData("periodnumber", this.state.Periods)
        for (let i in data) {
            let row = data[i];
            if (!CheckConditions(row, conditions)) {
                continue
            }
            let from = moment(row.startdate, "YYYY-MM-DD").format("DD-MMM-YYYY");
            let to = moment(row.enddate, "YYYY-MM-DD").format("DD-MMM-YYYY");
            let record = {
                Ref: `${JSON.stringify(row)}`,
                Description: `Number: ${row.periodnumber} | Y: ${row.year} | M: ${row.month} | From: ${from} To: ${to}`
            }
            ls.push(record)
        }
        return ls
    }

    findTemplate = (org, client, item) => {
        let template = "";
        for (let i in this.state.Items) {
            let row = this.state.Items[i];
            if (row.org !== org) {
                continue
            }
            if (row.client !== client) {
                continue
            }
            if (row.name !== item) {
                continue
            }
            template = row.invoicetemplate
        }
        return template
    }
    submitGenerate = async () => {
        let _this = this;
        let state = this.state;

        let pod = this.findPurchaseOrder();

        let post = {
            Org: state.selectedCompany,
            Project: state.selectedClient,
            ClientName: state.selectedClient,
            PeriodYear: state.selectedPeriodYear,
            PeriodMonth: state.selectedPeriodMonth,
            Template: this.findTemplate(state.selectedCompany, state.selectedClient, state.selectedItem),
            InvoiceName: state.selectedItem,
            InvoiceNumber: `${this.getNextSequenceNumber()}`,
            Pod: pod,
            StartDate:state.selectedPeriodStart,
            EndDate:state.selectedPeriodEnd,
        }

        let endpoint = "/invoices/vaccination/draft";
        this.setState({
            isLoading: true,
        });
        console.log("submitGenerate send > ", post);
        await PostToPbx(post, endpoint, async (data) => {
            let result = null;
            if(data!==null){
                result =  data.RESULT.Result;
            }else{
                alert("Sorry invoice can not be generator due to the missing setting, check if the following is set: header, backing detail")
            }
            _this.setState({isLoading: false, Result: result});
        });

    }
    onViewInvoice = (downloadLink) => {
        document.title = "PMIS Service Invoice";
        window.open(downloadLink, '_blank');
    }

    getInvoiceDate=()=>{
        let date =this.state.selectedPeriodEnd;
        if(date===""){
            date = moment().format("YYYY-MM-DD")
        }
        return date;
    }
    findInvoiceTerms=()=>{
        let num = 12;
        for (let i in this.state.InvoiceTerms) {
            let row = this.state.InvoiceTerms[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.org !== this.state.selectedClient) {
                continue
            }
            num = row.days
        }
        return num;
    }
    getInvoiceDueDate=()=>{
        let convertStringDateIntoTime=(inString)=>{
            let arr = inString.split("-");
            let year = parseInt(arr[0]);
            let month = parseInt(arr[1]);
            let day = parseInt(arr[2])
            return new Date(year, month, day)
        }
        let invDate = this.getInvoiceDate();
        let terms = this.findInvoiceTerms();
        let myDate = convertStringDateIntoTime(invDate);
        myDate.setDate(myDate.getDate() + terms);
        let strYear = myDate.getFullYear();
        let strMonth = myDate.getMonth();
        if(strMonth<10){strMonth = "0"+strMonth}
        let strDay = myDate.getDate();
        return `${strYear}-${strMonth}-${strDay}`
    }
    saveResultToInvoiceTracker =async () => {
        let _this = this;
        let state  = this.state;
        let info = this.state.Result;
        let invoiceNumber = this.getNextSequenceNumber();
        let pod = this.findPurchaseOrder();
        let totalAmount = info.TotalAmount;
        let subAmount = info.Subtotal;
        let vatAmount = info.Vat;

        if(pod===null){
            pod={
                refnumber:"",
                deliverydate:"",
                orderquantity:0,
                downloadlink:"",
            }
        }

        let record = {
            AppName:"pmis",
            Org: state.selectedCompany,
            Client: state.selectedClient,
            Item: state.selectedItem,

            InvoiceNumber: `${invoiceNumber}`,
            PoNumber: pod.refnumber,
            InvoiceDate: this.getInvoiceDate(),
            InvoiceAmount: totalAmount,
            PoExVat: subAmount,
            PoVat: vatAmount,
            PoTotal: pod.orderquantity,
            PaidAmount: 0,
            Terms: this.findInvoiceTerms(),
            DueDate: AddOrMinusToDate(this.getInvoiceDate(),31,"days","add"),
            Category: "vaccination",
            LinkInvNumber: info.DownloadLink,
            LinkPoNumber: pod.downloadlink,

            EntryType: "",
        }

        console.log("on About to submit inv tracker: ",record);
        if(!window.confirm("Are you to submit this invoice tracker?")){
            return
        }

        let endpoint = "/invoices/tracker/insert";
        this.setState({
            isLoading: true,
        })
        await PostToPbx(record, endpoint, async (data) => {
            alert("thank you, tracker  submitted, please check our tracker record!");
            _this.setState({isLoading: false,Result:null});
            await _this.loadInitialData();

        });

        console.log("onSubmitRecord > ",record);

    }
    renderResult = () => {
        const toFix2=(inValue)=>{
            let val = inValue.toFixed(2)
            return parseFloat(val)
        }
        const tableHeadJson = [
            {field: 'Action', label: '#', align: "center"},
            {field: 'Period', label: 'Period', align: "left"},
            {field: 'InvoiceNumber', label: 'Invoice No.', align: "left"},
            {field: 'PONumber', label: 'PO Number', align: "left"},
            {field: 'TotalUnit', label: 'Units', align: "center"},
            {field: 'TotalServices', label: 'Services', align: "center"},
            {field: 'TotalSite', label: 'Sites', align: "center"},
            {field: 'Subtotal', label: 'Subtotal', align: "right"},
            {field: 'Vat', label: 'Vat', align: "right"},
            {field: 'Total', label: 'Total', align: "right"},
        ]
        let columns = [];
        for (let i in tableHeadJson) {
            let row = tableHeadJson[i];
            columns.push({
                title: row.label,
                dataIndex: row.field,
                align: row.align,
            });
        }

        let info = this.state.Result;
        if(typeof info==="undefined"){

            info.PurchaseOrder = {RefNumber:""};

        }
        let ls = [
            {
                Action: <>
                    <a onClick={()=>this.saveResultToInvoiceTracker()}>Save Tracker</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                    <a onClick={() => this.onViewInvoice(info.DownloadLink)}>Email To</a> &nbsp;&nbsp;|&nbsp;&nbsp;
                    {/*<a onClick={() => this.onDownloadInvoice(info.DownloadLink)}>Download</a>*/}
                </>,
                Period: info.PeriodString,
                InvoiceNumber: info.InvNumber,
                PONumber: info.PurchaseOrder.RefNumber,
                Subtotal: "R " + FormatMoneySouthAfrica(toFix2(info.Subtotal)),
                Vat: "R " + FormatMoneySouthAfrica(toFix2(info.Vat)),
                Total: "R " + FormatMoneySouthAfrica(toFix2(info.TotalAmount)),
                TotalUnit: info.TotalQuantity,
                TotalSite: info.TotalSite,
                TotalServices: info.TotalNumberOfService,
            }
        ]

        return (
            <Table
                columns={columns}
                dataSource={ls}
                bordered
            />
        )

    }

    renderIFrameInvoice = () => {
        let info = this.state.Result;
        var delayInMilliseconds = 500; // half a second

        setTimeout(function () {
            let iframe = document.getElementsByTagName('iframe')[0];

        }, delayInMilliseconds);
        return (
            <iframe
                src={info.DownloadLink}
                title="Invoice"
                style={{minWidth: "100%", minHeight: 1024, background: "#FFFFFF"}}
                frameborder="0"

            >
                Press me: <a href={info.DownloadLink}>Download PDF</a>
            </iframe>
        )
    }


    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Generate Vaccination Tools invoice</Title>

                    <Divider style={{color: "red"}}>Filter</Divider>
                    <BuilderOptionComboBox
                        data={this.state.Companies}
                        keyVal={"CustomerNumber"}
                        keyLabel={"Name"}
                        label={"Select Organization"}
                        conditions={[]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedCompany"}
                        currentValue={this.state.selectedCompany}
                        comboId={"company"}
                    />
                    <BuilderOptionComboBox
                        data={this.state.Clients}
                        keyVal={"mame"}
                        keyLabel={"name"}
                        label={"Select Debtor"}
                        conditions={[
                            {key: "org", val: this.state.selectedCompany}
                        ]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedClient"}
                        currentValue={this.state.selectedClient}
                        comboId={"client"}
                    />
                    <BuilderOptionComboBox
                        data={this.state.Items}
                        keyVal={"name"}
                        keyLabel={"name"}
                        label={"Select Item | Job description"}
                        conditions={[
                            {key: "org", val: this.state.selectedCompany},
                            {key: "client", val: this.state.selectedClient},
                        ]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedItem"}
                        currentValue={this.state.selectedItem}
                        comboId={"item"}
                    />

                    <BuilderOptionComboBox
                        data={this.getPeriodData()}
                        keyVal={"Ref"}
                        keyLabel={"Description"}
                        label={"Select Period"}
                        conditions={[]}
                        handleFunction={this.handleInputPeriod}
                        stateKey={"selectedPeriod"}
                        currentValue={this.state.selectedPeriod}
                        comboId={"period"}
                    />


                    {this.state.selectedCompany !== "" ?
                        <Divider orientation="center" style={{color: "#3880ff"}}>
                            Invoice number: {this.getNextSequenceNumber()}
                            <span style={styles.podNumber}>
                            | PO number:
                                {this.findPurchaseOrder() !== null ? this.findPurchaseOrder().refnumber : "none"}
                        </span>
                        </Divider> : <Divider orientation="center" style={{color: "#3880ff"}}>
                            &nbsp;
                        </Divider>}
                    <Row>
                        <Col span={24} style={styles.rowButton}>
                            <Button onClick={() => this.submitGenerate()}
                                    style={{
                                        minWidth: "100%",
                                        border: "0.5px solid red",
                                        borderRadius: 5,
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: 16
                                    }}
                                    type={"primary"}>
                                Generate Invoice
                            </Button>

                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <LoadingProcessing open={this.state.isLoading}/>
                        </Col>
                    </Row>

                    {this.state.Result !== null ?
                        <Row>
                            <Col span={24}>
                                {this.renderResult()}
                            </Col>
                            <Col span={24}>
                                {this.renderIFrameInvoice()}
                            </Col>
                        </Row> : null}
                </div>
            </Page>

        )
    }

}


export default Connector(InvoiceDraftVaccinationPage);

const styles = {
    podNumber: {
        color: "orange",
    },
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
