import React from "react";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import {Layout, Select, Input} from "antd";
import {GetUserToken} from "../../../../api/token";
import PluginAddDeleteList from "../../../../components/Common/PluginAddDeleteList2";
import {PostToPbx} from "../../../../api/http-post";
import {fetchDataEntity} from "../../../../api/entity-data";


class InvoiceExtraSettingAccountStatementPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Modules: [],
            Clients: [],
            Items:[],
            isLoading: false,
            company: "",
            component: "list",
            newEntry: {},
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await this.loadData("modules", "Modules");
        await this.loadData("SettingInvoiceServiceDebtors", "Clients");
        await this.loadData("SettingInvoiceServiceItem", "Items");
    }
    loadData = async (targetEntity, stateKey) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            await _this.setState({
                [stateKey]: data.RESULT
            })
        });
    }

    getModuleList = () => {
        let ls = [];
        for (let i in this.state.Modules) {
            let row = this.state.Modules[i];
            if (row.org === this.state.newEntry.org) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    getCompanyList = () => {
        let ls = [];
        for (let i in this.state.Companies) {
            let row = this.state.Companies[i];
            ls.push({
                key: row.CustomerNumber,
                val: row.Name,
            });
        }
        return ls;
    }


    getClientOption=()=>{
        let ls = [];
        for (let i in this.state.Clients) {
            let row = this.state.Clients[i];
            if (row.org === this.state.newEntry.selectedCompany) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    getItemOption=()=>{
        let ls = [];
        for (let i in this.state.Items) {
            let row = this.state.Items[i];
            if (row.org === this.state.newEntry.selectedCompany && row.client===this.state.newEntry.selectedClient) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }

    render() {

        const companyOptions = this.getCompanyList();
        const clientOptions = this.getClientOption();
        const itemOptions = this.getItemOption();
        const lower = (inVal)=>{
            return inVal.toLocaleLowerCase()
        }
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Setup invoice account statement info</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"InvoiceAccountStatement"}
                            depending={{
                                "org": {
                                    targetKey: "CustomerNumber",
                                    displayKey: "Name",
                                    storageTable: "Companies"
                                }
                            }}
                            fields={[
                                {
                                    name: "org",
                                    dataType: "option",
                                    required: true,
                                    label: "Organisation",
                                    options: companyOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Companies"}
                                },
                                {
                                    name: "client",
                                    dataType: "option",
                                    required: true,
                                    label: "Debtor",
                                    options: clientOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Companies"}
                                },

                                {
                                    name: lower("ClientBusinessName"),
                                    dataType: "text",
                                    required: true,
                                    label: "Debtor Name",
                                    options: []
                                },

                                {
                                    name: lower("AccountNumber"),
                                    dataType: "text",
                                    required: true,
                                    label: "Debtor Account Number",
                                    options: []
                                },
                                {
                                    name: lower("ClientAddressLine1"),
                                    dataType: "text",
                                    required: true,
                                    label: "Debtor Address Line1",
                                    options: []
                                },
                                {
                                    name: lower("ClientAddressLine2"),
                                    dataType: "text",
                                    required: true,
                                    label: "Debtor Address Line2",
                                    options: []
                                },

                                {
                                    name: lower("ClientPostalCode"),
                                    dataType: "text",
                                    required: true,
                                    label: "Debtor Postal Code",
                                    options: []
                                },

                                {
                                    name: lower("ClientSuburb"),
                                    dataType: "text",
                                    required: true,
                                    label: "Debtor Address Suburb",
                                    options: []
                                },
                                {
                                    name: lower("ClientCity"),
                                    dataType: "text",
                                    required: true,
                                    label: "Debtor Address City",
                                    options: []
                                },
                                {
                                    name: lower("OwnerBusinessName"),
                                    dataType: "text",
                                    required: true,
                                    label: "Company Name",
                                    options: []
                                },
                                {
                                    name: lower("OwnerAddressLine1"),
                                    dataType: "text",
                                    required: true,
                                    label: "Company Address Line 1",
                                    options: []
                                },
                                {
                                    name: lower("OwnerAddressLine2"),
                                    dataType: "text",
                                    required: true,
                                    label: "OwnerAddressLine2",
                                    options: []
                                },
                                {
                                    name: lower("OwnerPostalCode"),
                                    dataType: "text",
                                    required: true,
                                    label: "Company Address Postal Code",
                                    options: []
                                },
                                {
                                    name: lower("OwnerSuburb"),
                                    dataType: "text",
                                    required: true,
                                    label: "Company Address Suburb",
                                    options: []
                                },
                                {
                                    name: lower("OwnerCity"),
                                    dataType: "text",
                                    required: true,
                                    label: "Company Address City",
                                    options: []
                                },

                            ]}
                            deleteKeys={[
                                {key: "org", type: "string"},
                                {key: "client", type: "string"},
                            ]}
                            stateArray={"Data"}
                            table={"InvoiceAccountStatement"}
                            addFormText={"debtor account statement"}
                            stepTitle={"Set Up new debtor account statement"}

                            filterByCompany={true}
                            displayRules={[
                                {rowKey:"org",stateKey:"selectedCompany"},
                                {rowKey:"client",stateKey:"selectedClient"},
                            ]}
                            extraFilters={[
                                {
                                    recordKey:"key",
                                    recordValue:"val",
                                    stateKey:"client",
                                    filterRecordKey:"client",
                                    filterStateKey:"selectedClient",
                                    allowBlank:true,
                                    name:"selectedClient",
                                    label:"Debtor",
                                    options: clientOptions,
                                    errorMsg: "Sorry you can't save, Please select your client!"
                                },

                            ]}


                        />

                    </div>
                </div>
            </Page>
        )
    }

}

export default Connector(InvoiceExtraSettingAccountStatementPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

