import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import Title from "../../../../components/Common/Title";
import Page from "../../../../layout/web/Page";
import Connector from "../../../../redux/connector";
import {
    BuildComboDataClient,
    BuildComboDataCompany, BuildComboDataProject, DeleteUser,
    FetchConfigDataService, FilterArrayDataWithConditions, FilterArrayDataWithConditionsNotEqual,
    SubmitNewChippingRate, SubmitNewChippingUser
} from "../../../../services/service-woodchipping";
import {Col, Form, Input, Row} from "antd";
import {UpCaseFirst} from "../../../../api/fn";
import ControllerSelectBox from "../../../../components/Common/ControllerInput/ControllerSelectBox";
import moment from "moment";
import GridItem from "../../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../../components/Common/tables/TableDataView";
import DrawerFrame from "../../../../components/General/DrawerFrame";


class WoodChippingSettingUserPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            ConfigData: {},
            selectedCompany: "",
            selectedRole: "",

            InputUsername: "",
            InputName: "",
            InputPhone: "",
            InputEmail: "",
            InputRole: "",
            InputPassword:"",
            editMode: false,
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Data", "", "", this, undefined, "common/entity/WcLogin/list");
        await this.fetchConfigData();
    }

    fetchConfigData = async () => {
        await FetchConfigDataService((res) => {
            this.setState({
                ConfigData: res
            })
        })

    }
    handleChangeInput = async (key, value) => {
        this.setState({
            [key]: value,
        })
    }
    handleGetInputValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return ""
        }
        return this.state[key];
    }
    onAddRecord = () => {
        const alertMsg = (field) => {
            alert(` ${field} can't empty, please selection before adding`)

        }
        if (this.state.selectedCompany === "") {
            alertMsg("company")
            return
        }

        this.setState({
            editMode: true
        })
    }
    onCancelRecord = () => {
        this.setState({
            editMode: false
        })
    }

     onDeleteRecord=async (record)=>{
         if(!window.confirm("Are you sure to delete this user: "+record.Name)){
             return
         }
         await DeleteUser(record,(res)=>{
             alert(`Thank you, user : ${record.Name} deleted`)
         })
         await this.fetchConfigData()
     }


    submitSearch = async () => {
        alert("coming soon!")

    }
    validationInputSubmit = () => {
        const alertMsg = (field) => {
            alert(`Validation fail, ${field} can't empty`)
            return false
        }
        if (this.state.InputUsername === "") {
            return alertMsg("username")
        } else if (this.state.InputName === "") {
            return alertMsg("name")
        }else if (this.state.InputPhone=== "") {
            return alertMsg("phone")
        }else if (this.state.InputRole=== "") {
            return alertMsg("role")
        }else if (this.state.InputPassword === "") {
            return alertMsg("password")
        }
        return true
    }
    onSubmit = async () => {
        if (!this.validationInputSubmit()) {
            return
        }
        await SubmitNewChippingUser(
            {
                Org: this.state.selectedCompany,
                Name: this.state.InputName,
                Username: this.state.InputUsername,
                Phone: this.state.InputPhone,
                Email: this.state.InputEmail,
                Role: this.state.InputRole,
                Password:this.state.InputPassword,
            },
            async (res) => {
                console.log("Thank you, rate submited> ", res)
                alert("Thank you, rate submited")
                await this.fetchConfigData();
                this.onCancelRecord()
            })
    }

    renderAddRatePopOver = () => {
        if (!this.state.editMode) {
            return null
        }

        let inputs = [
            {label: "Name", stateField: "InputName"},
            {label: "Username", stateField: "InputUsername"},
            {label: "Phone", stateField: "InputPhone"},
            {label: "Email", stateField: "InputEmail"},
            {label: "Password", stateField: "InputPassword"},
        ]

        return (
            <DrawerFrame
                title={"Add Wood Chipping Rate"}
                visible={this.state.editMode}
                onClose={this.onCancelRecord}
                onSubmit={this.onSubmit}
            >
                {inputs.map(row => {
                    return (
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name={row.stateField}
                                    label={row.label}
                                    rules={[{required: true, message: 'Please enter ' + row.label}]}
                                >
                                    <Input
                                        placeholder={"Please enter user " + row.label}
                                        onChange={(e) => this.handleChangeInput(row.stateField, e.target.value)}
                                        value={this.handleGetInputValue(row.stateField)}
                                        defaultValue={this.handleGetInputValue(row.stateField)}
                                        required={row.mandatory}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                })}

                <Row gutter={16}>
                    <Col span={24}>
                        <ControllerSelectBox
                            handleInput2={this.handleChangeInput}
                            getHandleValue={this.handleGetInputValue}
                            ls={[
                                {key: "agent", val: "Agent"},
                                {key: "admin", val: "Admin"},
                            ]}
                            label={"Role"}
                            name={"InputRole"}
                        />
                    </Col>
                </Row>

            </DrawerFrame>
        )
    }
    renderList = () => {
        let columns = [
            {id: "Name", label: "Full name"},
            {id: "Username", label: "Username"},
            {id: "Phone", label: "Phone"},
            {id: "Email", label: "Email"},
            {id: "Role", label: "Role"},
        ];
        let storeData = FilterArrayDataWithConditionsNotEqual(
            this.state.ConfigData.Users,
            [
                {field: "Org", value: this.state.selectedCompany},
            ],
            [
                {field: "Role", value: "super"},
            ]
        );
        let data = {columns: columns, data: storeData};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Users Available")}
                    onAddRecord={this.onAddRecord}
                    allowedAdd={true}
                    onDelete={this.onDeleteRecord}
                />
            </GridItem>
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Setup Wood Chipping Users</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <Row gutter={18}>
                            <Col span={24}>
                                <ControllerSelectBox
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={BuildComboDataCompany(this.state.ConfigData)}
                                    label={"Company"}
                                    name={"selectedCompany"}
                                />
                            </Col>

                        </Row>


                        <Row gutter={18}>
                            <Col span={24}>
                                {this.renderList()}
                            </Col>
                        </Row>

                        {this.renderAddRatePopOver()}

                    </div>
                </div>
            </Page>
        )
    }

}

export default Connector(WoodChippingSettingUserPage);
const styles = {
    boxFilterInner: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 5,
        borderRight: "1px solid black",
        fontsize: 14,
        fontWeight: "bold"
    },
    boxFilter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    dateRow: {
        display: "flex",
        flexDirection: "row"
    },
    datePickerBox: {
        height: 40,
        width: "100%"
    },
    row1Th: {
        border: "1px solid black",
    },
    rowForm: {
        marginBottom: 10,
        paddingBottom: 5,
        border: "0px solid lightgray",
        borderBottomWidth: 1,

    },

    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}