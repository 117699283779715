import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import Title from "../../../../components/Common/Title";
import Page from "../../../../layout/web/Page";
import Connector from "../../../../redux/connector";

import {
    BuildComboDataClient,
    BuildComboDataCompany, BuildComboDataProject, CalculateSummaryLogsChipping, FetchChippingLogsSummary,
    FetchConfigDataService, FetchLogsChipping, FilterArrayDataWithConditionsNotEqual,
} from "../../../../services/service-woodchipping";
import {Button, Col, DatePicker, Row} from "antd";
import ControllerSelectBox from "../../../../components/Common/ControllerInput/ControllerSelectBox";
import moment from "moment";
import GridItem from "../../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../../components/Common/tables/TableDataView";
import {FormatMoneySouthAfrica2, UpCaseFirst} from "../../../../api/fn";

import '../../ServiceModule/Labour/styles.css'
import {Colors} from "../../../../constants/colors";
import LoadingProcessing from "../../../../components/Common/page/Common/LoadingProcessing";


class WoodChippingRepportSummaryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: null,
            ConfigData: {},
            selectedCompany: "",
            selectedClient: "",
            selectedProject: "",
            selectedCategory: "",
            selectedCompostMaker: "",
            startDate: moment().format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),

            isLoading: false,
            results: null,
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        //await fetchDataEntity("Data", "", "", this, undefined, "common/entity/WcRate/list");
        await this.fetchConfigData();
    }

    fetchConfigData = async () => {
        await FetchConfigDataService((res) => {
            this.setState({
                ConfigData: res
            })
        })

    }
    handleChangeInput = async (key, value) => {
        this.setState({
            [key]: value,
        })
    }
    handleGetInputValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return ""
        }
        return this.state[key];
    }

    submitSearch = async () => {
        let postData = {
            Org: this.state.selectedCompany,
            Client: this.state.selectedClient,
            Project: this.state.selectedProject,
            StartDate: this.state.startDate,
            EndDate: this.state.endDate,
            Category: this.state.selectedCategory,
            CompostMaker: this.state.selectedCompostMaker,
        }
        let endpoint = "/report/summary/CoCT"
        console.log("::) submitSearch send > ", postData)
        this.setState({isLoading: true})
        await FetchChippingLogsSummary(postData, endpoint, (res) => {
            console.log("::) submitSearch response > ", res)
            this.setState({
                results: res,
                isLoading: false
            })
        })
    }

    renderSummaryData = () => {
        let data = [];
        if (this.state.Data !== null) {
            data = this.state.Data.Monthly.Logs;
        }
        let info = CalculateSummaryLogsChipping({
            client: this.state.selectedClient,
            project: this.state.selectedProject,
        }, data)

        let ls = [
            {label: "Days", total: info.totalDays, currency: false, formatNumber: true, currValue: "", extraClass: ""},
            {
                label: "Records",
                total: info.totalRecord,
                currency: false,
                formatNumber: true,
                currValue: "",
                extraClass: ""
            },
            {
                label: "Cubic Size",
                total: info.totalCubicSize,
                currency: false,
                formatNumber: true,
                currValue: "",
                extraClass: ""
            },
            {
                label: "Load Mass",
                total: info.totalLoadMass,
                currency: false,
                formatNumber: true,
                currValue: "",
                extraClass: ""
            },
        ]

        return (
            <Row gutter={18} className={"stRow borderTop"}>
                {ls.map(row => {
                    return (
                        <Col span={4} className={"stRowCol"}>
                            <label className={"stRowLabel"}>{row.label}:</label>
                            <label className={`stRowTotal1 `} style={{...row.extraClass}}>
                                {row.formatNumber ? FormatMoneySouthAfrica2(row.total) : row.total}
                            </label>
                        </Col>
                    )
                })}
            </Row>
        )
    }

    renderReport = () => {
        let info = this.state.results;
        if (info === null) {
            return (
                <div style={{minWidth: "100%", minHeight: 350, fontSize: 24}}>
                    <h1 color={"red"}>Waiting for report</h1>
                </div>
            )
        }


        return (
            <iframe
                src={info.DownloadLink}
                title="Invoice"
                style={{minWidth: "100%", minHeight: 1024, background: "#FFFFFF"}}
                frameborder="0"

            >
                Press me: <a href={info.DownloadLink}>Download PDF</a>
            </iframe>
        )

        let columns = [
            {id: "DeliveryDate", label: "Date"},
            {id: "WbRef", label: "WB Ref"},
            {id: "CubicSize", label: "Cubic Size"},
            {id: "LoadMass", label: "Load Mass"},
            {id: "Vehicle", label: "Vehicle"},
            {id: "Driver", label: "Driver"},
            {id: "DeliveryNote", label: "Delivery Note"},
            {id: "CompostMaker", label: "Compost Maker"},
        ];
        let storeData = [];
        if (this.state.Data !== null) {
            storeData = FilterArrayDataWithConditionsNotEqual(
                this.state.Data.Monthly.Logs,
                [
                    {field: "Client", value: this.state.selectedClient},
                    {field: "Project", value: this.state.selectedProject},
                ],
                []
            );
        }

        let data = {columns: columns, data: storeData};

        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Logs Monthly")}
                    onAddRecord={this.onAddRecord}
                    allowedAdd={true}
                    onDelete={this.onDeleteRecord}
                />
            </GridItem>
        )
    }

    displayComboBoxCompost = () => {
        if (this.state.selectedCategory === "chipping" || this.state.selectedCategory === ""){
            return []
        }
        return BuildComboDataClient(this.state.ConfigData, this.state.selectedCompany)
    }


    render() {
        return (

            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",

                }}>
                    <Title style={styles.title}> Wood Chipping Service Summary CoCT & Compost Maker</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <Row gutter={18}>
                            <Col span={24}>
                                <ControllerSelectBox
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={[{key: "chipping", val: "City Of Cape Town"}, {
                                        key: "compost",
                                        val: "Compost Maker"
                                    }]}
                                    label={"Report Category e.g. CoCT or Compost Maker"}
                                    name={"selectedCategory"}
                                />
                            </Col>
                        </Row>
                        <Row gutter={18}>
                            <Col span={12}>
                                <ControllerSelectBox
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={BuildComboDataCompany(this.state.ConfigData)}
                                    label={"Company"}
                                    name={"selectedCompany"}
                                />
                            </Col>
                            <Col span={12}>
                                <ControllerSelectBox
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={BuildComboDataClient(this.state.ConfigData, this.state.selectedCompany)}
                                    label={"Client"}
                                    name={"selectedClient"}
                                />
                            </Col>
                        </Row>

                        <Row gutter={18}>
                            <Col span={12}>
                                <ControllerSelectBox
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={BuildComboDataProject(
                                        this.state.ConfigData,
                                        this.state.selectedCompany,
                                        this.state.selectedClient
                                    )}
                                    label={"Project"}
                                    name={"selectedProject"}
                                />
                            </Col>
                            <Col span={12} >
                                <ControllerSelectBox
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={this.displayComboBoxCompost()}
                                    label={"Compost Maker"}
                                    name={"selectedCompostMaker"}
                                />
                            </Col>
                        </Row>
                        <Row gutter={18}>
                            <Col span={12} style={{marginTop: 30, height: 40}}>
                                <DatePicker
                                    style={styles.datePickerBox}
                                    onChange={(date, dateString) => this.setState({startDate: dateString})}
                                    placeholder={"Start Date"}
                                />
                            </Col>
                            <Col span={12} style={{marginTop: 30, height: 40}}>
                                <DatePicker
                                    style={styles.datePickerBox}
                                    onChange={(date, dateString) => this.setState({endDate: dateString})}
                                    placeholder={"End Date"}
                                />
                            </Col>

                        </Row>
                        <Row gutter={18}>
                            <Col span={24} style={{marginTop: 30, height: 40}}>
                                <Button
                                    style={{
                                        ...styles.datePickerBox,
                                        backgroundColor: Colors.brand.light,
                                        color: "white",
                                        borderRadius: 20
                                    }}
                                    onClick={() => this.submitSearch()}
                                >
                                    Search
                                </Button>
                            </Col>
                        </Row>

                        <div style={{backgroundColor: "white", padding: 10, marginTop: 30}}>
                            <Row>
                                <Col span={24}>
                                    <LoadingProcessing open={this.state.isLoading}/>
                                </Col>
                            </Row>
                            {/*<Row>
                                <Col span={24}>
                                    {this.renderSummaryData()}
                                </Col>
                            </Row>*/}

                            <Row gutter={18}>
                                <Col span={24}>
                                    {this.renderReport()}
                                </Col>
                            </Row>

                        </div>

                    </div>
                </div>
            </Page>
        )
    }

}

export default Connector(WoodChippingRepportSummaryPage);
const styles = {
    boxFilterInner: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 5,
        borderRight: "1px solid black",
        fontsize: 14,
        fontWeight: "bold"
    },
    boxFilter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    dateRow: {
        display: "flex",
        flexDirection: "row"
    },
    datePickerBox: {
        height: 40,
        width: "100%"
    },
    row1Th: {
        border: "1px solid black",
    },
    rowForm: {
        marginBottom: 10,
        paddingBottom: 5,
        border: "0px solid lightgray",
        borderBottomWidth: 1,

    },

    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}