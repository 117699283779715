import   {LOGGED_IN} from '../../constants';
const initial ={
    Token:null,
    User:null,
    Date:null,
    Time:null,
    Access:[],
}


const reduceLogin = (state = initial, action) => {
    let newState = state;
    switch (action.type) {
        case LOGGED_IN:
            newState.Token = action.payload.Token;
            newState.User = action.payload.User;
            newState.Date = action.payload.Date;
            newState.Time = action.payload.Time;
            newState.Access = action.payload.Access;
            return Object.assign({}, newState);
        default:
            return state
    }
}

export default reduceLogin;
