import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {Layout, Select, Input} from "antd";
import {GetUserToken} from "../../../api/token";
import PluginAddDeleteList from "../../../components/Common/PluginAddDeleteList2";
import {PostToPbx} from "../../../api/http-post";
import {fetchDataEntity} from "../../../api/entity-data";

const {TextArea} = Input;
const {Content} = Layout;
const {Option} = Select;

class DatabaseSettingEmployeeCodeRulesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Modules: [],
            Products: [],
            isLoading: false,
            company:"",
            component: "list",
            newEntry:{},
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await this.loadData("modules", "Modules");
    }
    loadData = async (targetEntity, stateKey) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            console.log("SettingProductPage loadInitialData response > ", post, " > ", data);
            await _this.setState({
                [stateKey]: data.RESULT
            })
        });
    }

    getBooleanOption = () => {
        let ls = [];
        ls.push({
            key: "yes",
            val: "Yes"
        });
        ls.push({
            key: "no",
            val: "No"
        })
        return ls
    }

    getModuleList = () => {
        let ls = [];
        console.log("getModuleList > ",this.state);
        for (let i in this.state.Modules) {
            let row = this.state.Modules[i];
            if (row.org === this.state.newEntry.org) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    getCompanyList = () => {
        let ls = [];
        for (let i in this.state.Companies) {
            let row = this.state.Companies[i];
            ls.push({
                key: row.CustomerNumber,
                val: row.Name,
            });
        }
        return ls;
    }
    getColorList = () => {
        let ls = [];
        ls.push({
            key: "green",
            val: "Green",
        });
        ls.push({
            key: "orange",
            val: "Amber",
        });
        ls.push({
            key: "red",
            val: "Red",
        });
        return ls;
    }
    getThresholdsList = () => {
        let ls = [];
        ls.push({
            key: "good",
            val: "Good",
        });
        ls.push({
            key: "satisfactory",
            val: "Satisfactory",
        });
        ls.push({
            key: "bad",
            val: "Bad",
        });
        return ls;
    }

    render() {
        const modulesOptions = this.getModuleList();
        const companyOptions = this.getCompanyList();
        const colorOptions = this.getColorList()
        const thresholdOptions = this.getThresholdsList();
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Employee Code Rules</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"OnboardCodeRules"}
                            depending={{"org":{targetKey:"CustomerNumber",displayKey:"Name",storageTable:"Companies"}}}
                            fields={[
                                {
                                    name: "org",
                                    dataType: "option",
                                    required: true,
                                    label: "Company",
                                    options: companyOptions,
                                    depending:{stateKey:"org",displayKey:"Name",storageTable:"Companies"}
                                },
                                {
                                    name: "module",
                                    dataType: "option",
                                    required: true,
                                    label: "Project & Contract",
                                    options: modulesOptions,

                                },
                                {name: "prefix", dataType: "string", required: true, label: "Prefix", options: []},
                                {name: "digit", dataType: "float", required: true, label: "Number of Digit", options: []},
                                {name: "startfrom", dataType: "float", required: true, label: "Start From", options: []},
                            ]}
                            deleteKeys={[
                                {key: "org", type: "string"},
                                {key: "module", type: "string"},
                            ]}
                            stateArray={"Data"}
                            table={"OnboardCodeRules"}
                            addFormText={"Employee Code rules"}
                            stepTitle={"Set Up new Employee Code rules"}
                        />
                    </div>
                </div>
            </Page>
        )
    }

}


export default Connector(DatabaseSettingEmployeeCodeRulesPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}


