import React, {useEffect, useState} from "react";
import {Button, Col, Drawer, Input, Row, Typography,Checkbox} from "antd";
import {Colors} from "../../../../constants/colors";


export default ({visible, data, onClose, submitRecord}) => {
    const [loading, setLoading] = useState(false);
    const [indexing, setIndexing] = useState({});
    const [mode, setMode] = useState("select");
    const [newRate, setNewRate] = useState("");
    const [allBoxes, setAllBoxes] = useState({});
    const [inputs, setInputs] = useState({});

    useEffect(() => {
        if(!loading){
            setLoading(true);
            loadValues()
        }
        return ()=>{
            setAllBoxes({})
            setInputs({})
        }
    }, []);

    useEffect(()=>{
        loadValues()
    },[data])

    const onSubmit=()=>{
       // let outputData =[];
        let changedPrice = {}
        for(let i in data){
            let row = data[i];
            if(!allBoxes[row.site]){
                continue
            }
            let nRate = inputs[row.site]

            if( nRate !==row.rate && nRate!==""){
                changedPrice[row.site] = {
                    from: row.rate,
                    to: inputs[row.site] ,
                    site: {...row,rate: parseFloat(inputs[row.site])}
                }
            }

            /*if(typeof nRate === "undefined"){
                nRate = row.rate
            }
            if(nRate=== ""){
                nRate = row.rate
            }
            let v = parseFloat(nRate)
            if(isNaN(v)){
                nRate = row.rate
            }
            row.rate = parseFloat(nRate)
            outputData.push(row)*/
        }

        console.log("changedPrice ::> ", changedPrice);
        let postData = []
        for(let i in changedPrice){
            let row = changedPrice[i];
            postData.push(row.site);
        }
        submitRecord(postData)
    }

    const loadValues=()=>{
        let boxes = {}
        let cInputs = {}
        for(let i in data){
            let row = data[i];
            boxes[row.site] = false
            cInputs[row.site] = ""
        }
        setAllBoxes(boxes)
        setInputs(cInputs)
        console.log("[Finish loading] > ",data.length )
    }

    const onSelectAll = (val) => {
        let boxes = {}
        let cInputs = {}
        for(let i in data){
            let row = data[i];
            boxes[row.site] = val
            cInputs[row.site] = newRate
        }
        setInputs(cInputs)
        setAllBoxes(boxes)
    }
    const onSelectOneCheck = (e, site) => {
        setAllBoxes({...allBoxes, [site]:e})
    }
    const isChecked=(site)=>{
        if(typeof allBoxes[site] === "undefined"){
            return false
        }
       return  allBoxes[site]
    }
    const getValue=(site)=>{
        if(typeof inputs[site] === "undefined"){
            return ""
        }
       // console.log("isChecked > ",inputs[site])
        return  inputs[site]
    }
    const setValueAll=(val)=>{
        const updatedObj = Object.keys(inputs).reduce((acc, key) => {
            acc[key] = val;
            return acc;
        }, {});
        setInputs(updatedObj);
        setNewRate(val)
    }
    const setValueOne=(val,site)=>{
        setInputs({...inputs, [site]:val})
        //todo make check box available if new value is not empty
        if(val!==""){
            onSelectOneCheck(true,site)
        }else{
            onSelectOneCheck(false,site)
        }
    }

    const RenderSelection = () => {

        return (
            <div>
                <Row style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: 50,
                }}>
                    <Col span={4}>
                        <Typography.Title level={5}>Rate:</Typography.Title>
                    </Col>
                    <Col span={20}>
                        <Input
                            key={"indexNewRate"}
                            value={newRate}
                            onChange={(e) => setValueAll(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Row key={"site"} style={{
                            borderBottom: "1px solid red",
                            marginInline: 5,
                            marginBottom: 5,
                        }}>
                            <Col span={2} style={styles.colCheck}>
                                <label style={styles.totalSite}>{data.length}</label>
                                <Checkbox
                                    type={"checkbox"}
                                       onChange={(e) => onSelectAll(e.target.checked)}
                                />
                            </Col>
                            <Col span={10}>
                                <label>Sites </label>
                            </Col>
                            <Col span={3}>
                                <label>Old Rate</label>
                            </Col>
                            <Col span={7}>
                                <label>New Rate</label>
                            </Col>
                        </Row>
                        {data.map((row, index) => {
                            return (
                                <Row key={row.site} style={{
                                    borderBottom: "1px solid #ddd",
                                    marginInline: 5,
                                    marginBottom: 5,
                                    padding: 5
                                }}>
                                    <Col span={2} style={styles.colCheck}>
                                        {index + 1}. <Checkbox
                                        type={"checkbox"}
                                        checked={isChecked(row.site)}
                                        onChange={(e) => onSelectOneCheck(e.target.checked, row.site)}
                                    />
                                    </Col>
                                    <Col span={10}>
                                        <label>{row.site}</label>
                                    </Col>
                                    <Col span={3}>
                                        <label>R{row.rate}</label>
                                    </Col>
                                    <Col span={7}>
                                        <Input
                                            type={"text"}
                                            style={{minWidth: "100%"}}
                                            value={getValue(row.site)}
                                            onChange={(e) => setValueOne(e.target.value, row.site)}
                                        />
                                    </Col>
                                </Row>
                            )
                        })}
                    </Col>
                </Row>
            </div>
        )
    }
    const RenderRate = () => {

    }

    return (
        <Drawer
            title={"New rate"}
            width={720}
            onClose={onClose}
            visible={visible}
            bodyStyle={{paddingBottom: 80, overflow: "scroll"}}
            footer={
                <div
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={onClose} style={{marginRight: 8}}>
                        Cancel
                    </Button>
                    <Button onClick={() => onSubmit()} type="primary">
                        Submit
                    </Button>
                </div>
            }
        >

            <div style={{marginTop: 20}}>
                {mode === "select" ? RenderSelection() : RenderRate()}
            </div>

        </Drawer>
    )

}

const styles = {
    totalSite: {
        color: Colors.brand.blue,
        fontSize: 16,
        fontWeight: "bold",
    },
    colCheck: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
    }
}