
import React, {useEffect, useState} from "react";
import "./dashbaord.css";
import {nFormatter} from "../../../api/fn";

const DashChart2=({chartId,titleChart,data,columnColor,categories})=>{
    let Highcharts = window.Highcharts;
    let options={
        chart: {
            type: 'bar'
        },
        title: {
            text: `<span style="color:#01B1A8;">${titleChart}</span>`
        },
        subtitle: {
            text: ''
        },
        accessibility: {
            announceNewData: {
                enabled: true
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                    formatter:function (){return nFormatter(this.y)}
                }
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            x: -40,
            y: 80,
            floating: true,
            borderWidth: 1,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
            shadow: true,
            enabled: false
        },
        /*plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    formatter:function (){return nFormatter(this.x)} // 'R{point.y:nFormatter(point.y)}'
                }
            }
        },*/
        colors:columnColor,
        xAxis: {
            categories: categories,
            title: {
                text: null
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: '',
                align: 'high'
            },
            labels: {
                /*overflow: 'justify'*/
            }
        },
        credits: {
            enabled: false
        },
        series: data,

    }
    useEffect(()=>{
        Highcharts.chart(chartId,options);
    })
    return(
        <div id={chartId} className={"highchartsBox"}>

        </div>
    )
}

export default DashChart2;