import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import Title from "../../../../components/Common/Title";
import Page from "../../../../layout/web/Page";
import Connector from "../../../../redux/connector";
import {PostToPbx, PostToWoodChipping} from "../../../../api/http-post";
import {
    BuildComboDataAny,
    BuildComboDataClient,
    BuildComboDataCompany, BuildComboDataProject,
    FetchConfigDataService, FilterArrayDataWithConditions, SubmitDeleteRecordCommon,
    SubmitNewChippingRate, SubmitNewChippingTarget
} from "../../../../services/service-woodchipping";
import { Col,  Form, Input, Row} from "antd";
import {LoadDataCommon, UpCaseFirst} from "../../../../api/fn";
import ControllerSelectBox from "../../../../components/Common/ControllerInput/ControllerSelectBox";
import moment from "moment";
import GridItem from "../../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../../components/Common/tables/TableDataView";
import DrawerFrame from "../../../../components/General/DrawerFrame";


class WoodChippingSettingAliasPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies:[],
            Clients:[],
            ConfigData: {},
            selectedCompany: "",
            selectedClient: "",
            selectedProject: "",
            InputAlias: "",
            editMode: false,
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await LoadDataCommon("SettingInvoiceServiceDebtors", "Clients", this);
        await fetchDataEntity("Data", "", "", this, undefined, "common/entity/WcAlias/list");
        await this.fetchConfigData();
    }

    initialLoad=async ()=>{
        await fetchDataEntity("Data", "", "", this, undefined, "common/entity/WcAlias/list");
    }

    fetchConfigData = async () => {
        await FetchConfigDataService((res) => {
            this.setState({
                ConfigData: res
            })
        })

    }
    handleChangeInput = async (key, value) => {
        this.setState({
            [key]: value,
        })
    }
    handleGetInputValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return ""
        }
        return this.state[key];
    }
    onAddRecord = () => {
        const alertMsg = (field) => {
            alert(` ${field} can't empty, please selection before adding`)

        }
        if (this.state.selectedCompany === "") {
            alertMsg("company")
            return
        } else if (this.state.selectedClient === "") {
            alertMsg("client")
            return
        }

        this.setState({
            editMode: true
        })
    }
    onCancelRecord = () => {
        this.setState({
            editMode: false
        })
    }


    submitSearch = async () => {
        alert("coming soon!")

    }
    validationInputSubmit = () => {
        const alertMsg = (field) => {
            alert(`Validation fail, ${field} can't empty`)
            return false
        }
        if (this.state.InputAlias === "") {
            return alertMsg("Alias")
        }
        return true
    }
    onSubmit = async () => {
        if (!this.validationInputSubmit()) {
            return
        }
        await SubmitNewChippingTarget(
            {
                org: this.state.selectedCompany,
                client: this.state.selectedClient,
                alias:this.state.InputAlias
            },
            "WcAlias",
            async (res) => {
                console.log("Thank you, alias submited> ",res)
                alert("Thank you, alias submited")
                await this.fetchConfigData();
                await this.initialLoad()
                //await fetchDataEntity("Data", "", "", this, undefined, "common/entity/WcAlias/list");
                this.onCancelRecord()
            })
    }
    onDelete=async (record)=>{
        await SubmitDeleteRecordCommon(
            "WcAlias",
            record,
            [
                {key: "org", type: "string",val:record.org},
                {key: "client", type: "string",val:record.client},
            ],
            `Are you sure to delete client: ${record.client} - Alias: ${record.alias}?`,
            "Thank you, record deleted!",
            this,
             this.initialLoad,
        )
    }

    renderAddRatePopOver = () => {
        if (!this.state.editMode) {
            return null
        }

        let inputs = [
            {label: "Alias Name", stateField: "InputAlias"},
        ]

        return (
            <DrawerFrame
                title={"Add Wood Chipping Target"}
                visible={this.state.editMode}
                onClose={this.onCancelRecord}
                onSubmit={this.onSubmit}
            >
                {inputs.map(row => {
                    return (
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name={row.stateField}
                                    label={row.label}
                                    rules={[{required: true, message: 'Please enter ' + row.label}]}
                                >
                                    <Input
                                        placeholder={"Please enter user " + row.label}
                                        onChange={(e) => this.handleChangeInput(row.stateField, e.target.value)}
                                        value={this.handleGetInputValue(row.stateField)}
                                        defaultValue={this.handleGetInputValue(row.stateField)}
                                        required={row.mandatory}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                })}


            </DrawerFrame>
        )
    }
    renderList = () => {
        let columns = [
            {id: "client", label: "Client"},
            {id: "alias", label: "Alias"},
        ];
        let storeData = FilterArrayDataWithConditions(
            this.state.Data,
            [
                {field:"org",value:this.state.selectedCompany},
               /* {field:"client",value:this.state.selectedClient},*/
            ])
        let data = {columns: columns, data: storeData};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Monthly target")}
                    onAddRecord={this.onAddRecord}
                    allowedAdd={true}
                    onDelete={this.onDelete}
                />
            </GridItem>
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Setup Wood Chipping Alias</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <Row gutter={18}>
                            <Col span={12}>
                                <ControllerSelectBox
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={BuildComboDataAny(this.state.Companies,"CustomerNumber","Name")}
                                    label={"Company"}
                                    name={"selectedCompany"}
                                />
                            </Col>
                            <Col span={12}>
                                <ControllerSelectBox
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={BuildComboDataAny(
                                        FilterArrayDataWithConditions(
                                        this.state.Clients,
                                        [
                                            {field:"org",value:this.state.selectedCompany},
                                        ]),
                                        "name",
                                        "name")
                                }
                                    label={"Client"}
                                    name={"selectedClient"}
                                />
                            </Col>
                        </Row>

                        <Row gutter={18}>
                            <Col span={24}>
                                {this.renderList()}
                            </Col>
                        </Row>

                        {this.renderAddRatePopOver()}

                    </div>
                </div>
            </Page>
        )
    }

}

export default Connector(WoodChippingSettingAliasPage);
const styles = {
    boxFilterInner: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 5,
        borderRight: "1px solid black",
        fontsize: 14,
        fontWeight: "bold"
    },
    boxFilter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    dateRow: {
        display: "flex",
        flexDirection: "row"
    },
    datePickerBox: {
        height: 40,
        width: "100%"
    },
    row1Th: {
        border: "1px solid black",
    },
    rowForm: {
        marginBottom: 10,
        paddingBottom: 5,
        border: "0px solid lightgray",
        borderBottomWidth: 1,

    },

    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}