const TOKEN_KEY = "token";
const TOKEN_USER = "@pbx-user";
const TOKEN_ACCESS = "access";

export const GetLoginToken = () => {
    return localStorage.getItem(TOKEN_KEY)
}
export const GetLoginAccess = () => {
    let data = localStorage.getItem(TOKEN_ACCESS);

    if(data===null){
        return [];
    }
    if(typeof data==="undefined"){
        return [];
    }
    if(data===undefined){
        return [];
    }

    let result  = []
    try{
        result = JSON.parse(data)

    }catch (e) {

    }
    return result
}
export const  SaveToken = (token, user,access) => {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(TOKEN_USER, JSON.stringify(user));
    localStorage.setItem(TOKEN_ACCESS, JSON.stringify(access));
}
export const GetUserToken = () => {
    return JSON.parse(localStorage.getItem(TOKEN_USER))
}
