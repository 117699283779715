import {CheckConditions, SortData} from "../api/fn";
import moment from "moment/moment";

export const SplitArrayIntoMultiple=(data,numItem)=>{
    let out=[];
    let current=[];
    for(let i in data){
        let rec = data[i]
        current.push(rec)
        if(current.length>=numItem){
            out.push(current)
            current = []
        }
    }
    if(current.length>0){
        out.push(current)
    }
    return out
}
export const sortArrayBasedOnDate=(data,fieldToSort,order)=>{
    function compareByDate(a, b) {
        const dateA = new Date(a[fieldToSort]);
        const dateB = new Date(b[fieldToSort]);
        return dateA - dateB;
    }
    data.sort(compareByDate);
    if(order==="asc"){
        return data
    }
    let out=[]
    for(let x=data.length-1;x>=0;x--){
        let record = data[x]
        out.push(record)
    }
    return out
}
export const getPeriodDataFilter=(_this)=>{

    let ls = [];
    let conditions = [
        {key: "org", val: _this.state.selectedCompany},
        {key: "client", val: _this.state.selectedClient},
        {key: "item", val: _this.state.selectedItem},
    ]
    let data =sortArrayBasedOnDate(_this.state.Periods,"startdate","desc"); // SortData("periodnumber", _this.state.Periods)
    for (let i in data) {
        let row = data[i];
        if (!CheckConditions(row, conditions)) {
            continue
        }
        let from = moment(row.startdate, "YYYY-MM-DD").format("DD-MMM-YYYY");
        let to = moment(row.enddate, "YYYY-MM-DD").format("DD-MMM-YYYY");
        let record = {
            Ref: `${JSON.stringify(row)}`,
            Description: moment(row.startdate).format("MMM YYYY") ,//`Number: ${row.periodnumber} | Y: ${row.year} | M: ${row.month} | From: ${from} To: ${to}`
        }
        ls.push(record)
    }
    return ls
}