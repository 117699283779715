import {PostToPbx, PostToWoodChipping} from "../api/http-post";
import {GetUserToken} from "../api/token";
import moment from "moment";
import {AppName, DbName} from "../constants/info-woodchipping";
import {fetchDataEntity} from "../api/entity-data";
// Import the MD5 module from CryptoJS
const CryptoJS = require('crypto-js');

export const FilterArrayDataWithConditionsNotEqual = (data, conditions, conditionsNotEqual) => {
    let outData = [];
    for (let i in data) {
        let row = data[i];
        let isOk = true;
        for (let a in conditions) {
            let cond = conditions[a]
            let condField = cond.field;
            let condValue = cond.value;
            if (typeof row[condField] === "undefined") {
                isOk = false
                break
            }
            if (row[condField] !== condValue) {
                isOk = false
                break
            }
        }
        for (let a in conditionsNotEqual) {
            let cond = conditionsNotEqual[a]
            let condField = cond.field;
            let condValue = cond.value;
            if (typeof row[condField] === "undefined") {
                isOk = false
                break
            }
            if (row[condField] === condValue) {
                isOk = false
                break
            }
        }
        if (!isOk) {
            continue
        }
        outData.push(row)
    }
    return outData
}
export const FilterArrayDataWithConditions = (data, conditions) => {
    let outData = [];
    for (let i in data) {
        let row = data[i];
        let isOk = true;
        for (let a in conditions) {
            let cond = conditions[a]
            let condField = cond.field;
            let condValue = cond.value;
            if (typeof row[condField] === "undefined") {
                isOk = false
                break
            }
            if (row[condField] !== condValue) {
                isOk = false
                break
            }
        }
        if (!isOk) {
            continue
        }
        outData.push(row)
    }
    return outData
}

export const DeleteUser = async (record, feedback) => {
    delete record.tableData;
    let endpoint = `/data/delete/user`
    let payload = {
        Org: record.Org,
        RecordUser: record
    }
    console.log("DeleteUser send > ", payload)
    await PostToWoodChipping(payload, endpoint, (res) => {
        feedback(res)
    })
}
export const SubmitNewChippingUser = async (record, feedback) => {
    /*const userInfo = GetUserLoginInfo()
    record.AppName = "pmis";
    record.Date = moment().format("YYYY-MM-DD")
    record.Time = moment().format("HH:mm:SS")
    record.OrgDateTime = moment().format("YYYY-MM-DD HH:mm:SS")
    record.Status = "active"*/

    /*// Generate MD5 hash
    const md5Hash = CryptoJS.MD5(record.password).toString();
    record.password = md5Hash*/

    let payload = {...record}
    console.log("SubmitNewChippingUser > ", payload)
    let endpoint = "/woodchipping/auth/create-user"
    await PostToWoodChipping(payload, endpoint, (res) => {
        feedback(res)
    })
}
export const SubmitNewChippingTarget = async (record, table, feedback) => {
    const userInfo = GetUserLoginInfo()
    record.AppName = "pmis";
    record.Date = moment().format("YYYY-MM-DD")
    record.Time = moment().format("HH:mm:SS")
    record.OrgDateTime = moment().format("YYYY-MM-DD HH:mm:SS")
    record.Status = "active"

    let payload = {
        AppName,
        DbName,
        Data: record,
        Org: userInfo.Org,
        Table: table,
    }
    let endpoint = "/data/insert-any"
    await PostToWoodChipping(payload, endpoint, (res) => {
        feedback(res)
    })
}
export const SubmitDeleteRecordCommon = async (table, record, conditionsParam, warningMsg, feedbackMsg, _this, fn) => {
    if (!window.confirm(warningMsg)) {
        return
    }
    console.log("Record to delete: ", record)
    let post = {}
    post.Params = conditionsParam /*[
            {key: "org", type: "string",val:record.org},
            {key: "client", type: "string",val:record.client},
        ]*/
    post.Entity = table;
    let endpoint = `/common/entity/${table}/delete`;
    _this.setState({
        isLoading: true,
    })
    console.log("onDelete send >> : ", post)
    await PostToPbx(post, endpoint, async (data) => {
        _this.setState({
            isLoading: false,
        })
        await fn()
        alert(feedbackMsg)
    });

}
export const SubmitNewChippingRate = async (record, feedback) => {
    const userInfo = GetUserLoginInfo()
    record.AppName = "pmis";
    record.Date = moment().format("YYYY-MM-DD")
    record.Time = moment().format("HH:mm:SS")
    record.OrgDateTime = moment().format("YYYY-MM-DD HH:mm:SS")
    record.Status = "active"

    let payload = {
        AppName,
        DbName,
        Data: record,
        Org: userInfo.Org,
        Table: "WcRate",
    }
    let endpoint = "/data/insert-any"
    await PostToWoodChipping(payload, endpoint, (res) => {
        feedback(res)
    })
}
export const BuildComboDataProject = (configData, selectedCompany, selectedClient) => {
    if (IsConfigDataEmpty(configData)) {
        return [];
    }
    let data = []
    for (let i in configData.Projects) {
        let item = configData.Projects[i]
        if (item.Org !== selectedCompany) {
            continue
        }
        if (item.Client !== selectedClient) {
            continue
        }
        data.push({key: item.Name, val: item.Name})
    }
    return data
}
export const BuildComboDataClient = (configData, selectedCompany) => {
    if (IsConfigDataEmpty(configData)) {
        return [];
    }
    let data = []
    for (let i in configData.Clients) {
        let item = configData.Clients[i]
        if (item.Org !== selectedCompany) {
            continue
        }
        data.push({key: item.Name, val: item.Name})
    }
    return data
}
export const BuildComboDataAny = (data, fieldKey, fieldValue) => {
    console.log("::::*> ", fieldKey, " > ", fieldValue, " > ", data)
    let out = []
    for (let i in data) {
        let item = data[i]
        let key = item[fieldKey]
        let val = item[fieldValue]
        out.push({key: key, val: val})
    }
    return out
}
export const BuildComboDataCompany = (configData) => {
    if (IsConfigDataEmpty(configData)) {
        return [];
    }
    let data = []
    for (let i in configData.Companies) {
        let item = configData.Companies[i]
        data.push({key: item.Code, val: item.Name})
    }
    return data
}
export const IsConfigDataEmpty = (data) => {
    if (JSON.stringify(data) === "{}") {
        return true
    }
    return false
}
export const CalculateSummaryLogsChipping = (dataFilter, dataLogs, compostMaker, rate) => {
    let filterData = FilterArrayDataWithConditionsNotEqual(
        dataLogs,
        [
            {field: "Client", value: dataFilter.client},
            {field: "Project", value: dataFilter.project},
        ],
        []
    );

    let totalRecord = 0, totalQuantity = 0, totalCubicSize = 0, totalLoadMass = 0, totalDays = 0;
    let revenue = 0;
    let days = {}
    for (let i in filterData) {
        let row = filterData[i];
        if (compostMaker !== "") {
            if (row.CompostMaker !== compostMaker) {
                continue
            }
        }
        days[row.DeliveryDate] = true
        totalRecord++
        totalCubicSize += row.CubicSize;
        totalLoadMass += row.LoadMass;
        totalQuantity += row.Quantity;
    }

    if (compostMaker === "") {
        /** let make sure to calculate the total based on filter*/
        revenue = totalLoadMass * rate
    } else {
        revenue = totalCubicSize * rate
    }

    const keysArray = Object.keys(days);
    const numberOfKeys = keysArray.length;
    return {
        totalRecord,
        totalCubicSize,
        totalLoadMass,
        totalQuantity,
        totalDays: numberOfKeys,
        rate,
        revenue
    }
}
export const FetchChippingLogsSummary = async (postData, endpoint, feedback) => {
    let payload = {
        ...postData,
    }
    await PostToWoodChipping(payload, endpoint, (res) => {
        console.log("FetchLogsChipping result> ", res.RESULT)
        feedback(res.RESULT)
    })
}
export const FetchLogsChipping = async (postData, feedback) => {
    const userInfo = GetUserLoginInfo()
    let endpoint = "/dashboard/chipping/logs/min"
    let payload = {
        Username: userInfo.Username,
        Role: userInfo.Role,
        Org: postData.Org,
        TargetDate: postData.TargetDate,
    }
    await PostToWoodChipping(payload, endpoint, (res) => {
        console.log("FetchLogsChipping result> ", res.RESULT)
        feedback(res.RESULT)
    })
}
export const FetchConfigDataService = async (feedback) => {
    const userInfo = GetUserLoginInfo()
    let endpoint = "/woodchipping/config/data"
    let payload = {Org: userInfo.Org, Username: userInfo.Username, Role: userInfo.Role}
    await PostToWoodChipping(payload, endpoint, (res) => {
        let data = res.RESULT.feedback;
        if (userInfo.Role !== "super") {//exclude other companies from the list
            let companies = [];
            for (let i in data.Companies) {
                let row = data.Companies[i];
                if (row.Code !== userInfo.Org) {
                    continue
                }
                companies.push(row)
            }
            data.Companies = companies
        }
        feedback(data);
    })
}

export const GetUserLoginInfo = () => {
    let user = GetUserToken();
    if (user === null) {
        user = {
            Org: "",
            Username: "",
            Role: "guest"
        }
    }
    let hub = {
        Org: user.Org,
        Username: user.Username,
        Role: user.Role
    }
    return hub
}