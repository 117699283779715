import React from "react";
import PageBuilder from "../../../components/web/Common/PageBuilder";
import Connector from "../../../redux/connector";
import {EntitiesOptions} from "../../../constants";

class DatabaseSettingEntityPage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let StateStorage = [
            {stateField: "Companies", moduleEntity: "client/entity", table: "company", noCondition: false},
            {stateField: "Modules", moduleEntity: "", table: "", noCondition: false,inEndpoint:"common/entity/modules/list"},
        ];
        let displayListColumns = [
            {id: "Client", label: "Company"},
            {id: "Name", label: "Name"},
            {id: "haspayroll", label: "Include Contract?"},
            /*{id: "haspayroll", label: "Contract"},
            {id: "HasClock", label: "Clock"},
            {id: "HasTitle", label: "Title"},
            {id: "HasName", label: "Names"},
            {id: "HasContact", label: "Contact"},
            {id: "HasVehicle", label: "Vehicle"},
            {id: "HasIdentity", label: "Identity"},
            {id: "HasGeoLocation", label: "GeoLocation"},
            {id: "HasLogin", label: "Login"},*/
        ]
        let translateFields = [
            {
                keyName: "Client",
                keySearch: "CustomerNumber",
                keyValue: "org",
                keyReturn: "Name",
                stateEntity: "Companies"
            }
        ];
        let entityOptions = [
            /*{keyState: "Registries", targetKey: "Org", storeVariable: "registries"},
            {keyState: "Trunks", targetKey: "Org", storeVariable: "trunks"},
            {keyState: "Agents", targetKey: "Org", storeVariable: "agents"},
            {keyState: "Data", targetKey: "Org", storeVariable: "extensions"},*/
        ];

        let bldOptions = [
            {
                label: "Name of Company",
                message: "Please select your company name",
                key: "CustomerNumber",
                value: "Name",
                lookInState: true,
                targetStorage: "Companies",
                StateKey: "Org"
            },
        ];

        const yesNoOption =[
            {key:"human",val:"Human"},
            {key:"object",val:"Object"},
        ]

        let bldFields = [
            {
                label: "Name",
                message: "Please input your source name",
                type: "text",
                StateKey: "Name",
                required:true
            },
            ...EntitiesOptions
        ]

        return (
            <PageBuilder
                pageTitle={"Project Naming"}
                StateStorage={StateStorage}
                displayListColumns={displayListColumns}
                displayTableKeyState={"Modules"}
                hasTranslateField={true}
                translateFields={translateFields}
                entityOptions={entityOptions}
                bldOptions={[]}
                bldFields={bldFields}
                newFormTitle={"New Entity"}
                postMsgResponse={"Thank you, project submitted!"}
                postMsgWarning={"Are you sure you want to submit this new entity"}
                postEndpoint={"/common/entity/modules/insert"}
                listKeyToLower={true}

                AutoGenerate={false}
                HasUniqueKey={false}
                AutoGenerateField={""}
                ParamsDelete={[
                    {key:"org",type:"string"},
                    {key:"name",type:"string"},
                ]}

            />
        )
    }

}

export default Connector(DatabaseSettingEntityPage);

