
import React from "react";
import { Drawer, Form, Button, Col, Row, Input, Select, DatePicker,Table } from 'antd';
import { OrderedListOutlined,SearchOutlined } from '@ant-design/icons';
import CloudDownloadIcon from "@material-ui/icons/DeleteForeverSharp";
import IconButton from "@material-ui/core/IconButton";

const { Option } = Select;

export default (props)=>{
    let {_this}=props;
    let { showViewDetail,detailCode,detailRecord,detailList,detailType}=_this.state;

    const onClose=()=>{
        _this.setState({

                showViewDetail:false,
                detailCode:"",
                detailRecord:{},
                detailList:[],

        })
    }
    let visible =showViewDetail;
    let ls=detailList;
    console.log("Popup detail list > ",ls)
    return(
        <Drawer
            title={"T & A Clocking: : "+detailRecord.Name+" -> "+detailCode+""}
            width={720}
            onClose={onClose}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <div
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={onClose} style={{ marginRight: 8 }}>
                        Close
                    </Button>

                </div>
            }
        >
                <Row gutter={16}>
                    <Col span={1} style={styles.RowHeaderAlignLeft}>#</Col>
                    <Col span={4} style={styles.RowHeaderAlignLeft}>Date</Col>
                    <Col span={4} style={styles.RowHeaderAlignLeft}>Day</Col>
                    <Col span={2}  style={styles.RowHeaderAlignCenter}>Hours</Col>
                    <Col span={3}  style={styles.RowHeaderAlignRight}>Rate</Col>
                    <Col span={3}  style={styles.RowHeaderAlignRight}>GrossPay</Col>
                    <Col span={3}  style={styles.RowHeaderAlignRight}>#</Col>
                </Row>
                {ls.map((item,index)=>{
                    let num = parseInt(index)+1
                    return(
                        <Row gutter={16}>
                            <Col span={1} style={styles.RowBodyAlignLeft}> {num}</Col>
                            <Col span={4} style={styles.RowBodyAlignLeft}> {item.ClockDate}</Col>
                            <Col span={4} style={styles.RowBodyAlignLeft}>{item.Day}</Col>
                            <Col span={2} style={styles.RowBodyAlignCenter}>{item.Hours}</Col>
                            <Col span={3} style={styles.RowBodyAlignRight}>R{item.RateEmployee}/{item.WageMethod}</Col>
                            <Col span={3} style={styles.RowBodyAlignRight}>R{item.CostEmployee}</Col>
                            <Col span={3} style={styles.RowBodyAlignRight}>
                                <IconButton edge="end" aria-label="delete"
                                            onClick={() => _this.deleteClock(item)}>
                                    <CloudDownloadIcon style={{color: "red"}}/>
                                </IconButton>
                            </Col>
                        </Row>
                    )
                })}

        </Drawer>
    )
}

const styles={
    RowHeaderAlignRight:{
        fontSize:11,
        color:"black",
        /*fontWeight:"bold",*/
        textAlign:"right",
    },
    RowHeaderAlignLeft:{
        fontSize:11,
        color:"black",
        /*fontWeight:"bold",*/
        textAlign:"right",
    },
    RowHeaderAlignCenter:{
        fontSize:11,
        color:"black",
        /* fontWeight:"bold",*/
        textAlign:"center",
    },

    RowBodyAlignRight:{
        fontSize:11,
        color:"gray",
        textAlign:"right",
    },
    RowBodyAlignLeft:{
        fontSize:11,
        color:"gray",
        textAlign:"right",
    },
    RowBodyAlignCenter:{
        fontSize:11,
        color:"gray",
        textAlign:"center",
    },

}