import React from 'react';
import {Form,Select}from "antd";
import {useState} from "react";
import {SortData} from "../../../api/fn";

export default (props) => {
    let {_this} = props;
    let {fieldList, fieldMap,CsvHeaders} = _this.state;
    const [componentSize, setComponentSize] = useState('large');
    const onFormLayoutChange = ({ size }) => {
        setComponentSize(size);
    };
    const _onChangeField=(val,key)=>{
        _this.handleChangeMappingSet(val,key);
    }
    fieldList = SortData("position",fieldList)
    return (
        <div style={{marginTop: 20}}>
            <Form
                labelCol={{span: 4}}
                wrapperCol={{span: 14}}
                layout="horizontal"
                initialValues={{size: componentSize}}
                onValuesChange={onFormLayoutChange}
                size={componentSize}
            >

                {CsvHeaders.map((y) => {
                    return (
                        <Form.Item label={y}>
                            <Select
                                name={y}
                                onChange={(e) => _onChangeField(e, y)}
                                value={_this.handleChangeMappingGet(y)}
                            >
                                {fieldList.map((x) => {
                                    return(
                                        <Select.Option value={x}>{x}</Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    )
                })}
            </Form>
        </div>
    )


}