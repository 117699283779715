import React from "react";
import "./dashbaord.css";
import {MailOutlined} from "@ant-design/icons";

const TotalInvoicesSend=({amount})=>{
    return(
        <div className={"tisContainer"}>
            <div>
                <MailOutlined className={"tisIcon"}/>
            </div>
            <div className={"tisAmount"}>
                {amount}
            </div>
            <div className={"tisFooter"}>
                Total Invoices sent
            </div>
        </div>
    )
}

export default TotalInvoicesSend;