import moment from "moment";

export const AddOrMinusToDate=(dateIn,value,periodType,action='subtract')=>{
    // Get the current date
    const currentDate = moment(dateIn);
    // Subtract one year
    let num = parseInt(value)
    let newDate;
    if(action==="subtract"){
        newDate = currentDate.subtract(num, periodType);
    }else{
        newDate = currentDate.add(num, periodType);
    }
    //const oneYearAgo = currentDate.subtract(num, 'years');
    // Format the date if needed
    return newDate.format('YYYY-MM-DD');
}
export const GetWeekNumberFromDate=(dateIn)=>{
    Date.prototype.getWeek = function (dowOffset) {
        dowOffset = typeof(dowOffset) == 'number' ? dowOffset : 0;
        let newYear = new Date(this.getFullYear(),0,1);
        let day = newYear.getDay() - dowOffset;
        day = (day >= 0 ? day : day + 7);
        let daynum = Math.floor((this.getTime() - newYear.getTime() -
            (this.getTimezoneOffset()-newYear.getTimezoneOffset())*60000)/86400000) + 1;
        let weeknum;
        //if the year starts before the middle of a week
        if(day < 4) {
            weeknum = Math.floor((daynum+day-1)/7) + 1;
            if(weeknum > 52) {
                let nYear = new Date(this.getFullYear() + 1,0,1);
                let nday = nYear.getDay() - dowOffset;
                 nday = nday >= 0 ? nday : nday + 7;
                /*if the next year starts before the middle of
                  the week, it is week #1 of that year*/
                weeknum = nday < 4 ? 1 : 53;
            }
        }
        else {
            weeknum = Math.floor((daynum+day-1)/7);
        }
        return weeknum;
    };

    let arrDate = dateIn.split("-")
    let mydate = new Date(parseInt(arrDate[0]),parseInt(arrDate[1]),parseInt(arrDate[2])); // month number starts from 0

    console.log("W(:::)-> ",dateIn," -> ",mydate.getWeek())
    return mydate.getWeek()
}
export const getDateListBetween=(start,end)=>{
    let arr=[];
    let condition=true;
    arr.push(start);
    let sDate = start;
    while(condition){
        let new_date = moment(sDate, "YYYY-MM-DD").add(1, 'month').format("YYYY-MM-DD");
        sDate = new_date
        if(isDateBetween(start,end,sDate)){
            arr.push(sDate)
        }else{
            condition=false;
            break
        }
    }
        return arr
}

export const FindFinancialYearRange = (targetMonthStart = "Mar",
        targetYear = `${parseInt(moment().format("YYYY"))-1}`
) => {
    let sDate = moment(`${targetYear}-${targetMonthStart}-01`, "YYYY-MMM-DD").format("YYYY-MM-DD")
    let eDateWork1 = moment(sDate).add(11, 'M')
    let eDate = moment(eDateWork1).endOf('month').format("YYYY-MM-DD");
    console.log(`FindFinancialYearRange > targetMonthStart: ${targetMonthStart} 
     > targetYear: ${targetYear} > sDate:${sDate} > eDate: ${eDate}`)
    return {
        startDate:sDate,
        endDate:eDate
    }
}

export const stringToDate = (inValue) => {
    let date = new Date(inValue + " 00:00:00");
    date.setHours(0, 0, 0, 0);
    return date;
}
export const isDateGreatThan = (compareDate, targetDate) => {
    compareDate = stringToDate(compareDate);
    targetDate = stringToDate(targetDate);
    return compareDate > targetDate
}
export const isDateLessThan = (compareDate, targetDate) => {
    compareDate = stringToDate(compareDate);
    targetDate = stringToDate(targetDate);
    return compareDate < targetDate
}
export const isDateBetween = (from, to, check) => {
    from = stringToDate(from);
    to = stringToDate(to);
    check = stringToDate(check);
    return check >= from && check <= to
}
export const calculateNumberOfDaysBetweenDates = (startDate, endDate) => {
    let date1 = stringToDate(startDate);
    let date2 = stringToDate(endDate);
    let Difference_In_Time = date2.getTime() - date1.getTime();
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    //To display the final no. of days (result)
    console.log("Total number of days between dates  <br>"
        + date1 + "<br> and <br>"
        + date2 + " is: <br> "
        + Difference_In_Days);

    return Difference_In_Days;
}

export const FindMonthIndexFromShortName = (valueIn) => {
    let listMonths = [
        {label: "JAN", period: 1, selected: false},
        {label: "FEB", period: 2, selected: false},
        {label: "MAR", period: 3, selected: false},
        {label: "APR", period: 4, selected: false},
        {label: "MAY", period: 5, selected: false},
        {label: "JUN", period: 6, selected: false},
        {label: "JUL", period: 7, selected: false},
        {label: "AUG", period: 8, selected: false},
        {label: "SEP", period: 9, selected: false},
        {label: "OCT", period: 10, selected: false},
        {label: "NOV", period: 11, selected: false},
        {label: "DEC", period: 12, selected: false},
    ];

    let pos = 0;
    for (let i in listMonths) {
        let row = listMonths[i];
        let label = row.label;
        console.log("###:> ", label, " > ", valueIn)
        if (label === valueIn.toUpperCase()) {
            pos = row.period
            break
        }
    }
    return pos
}