
import React from "react";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import {Layout, Select, Input} from "antd";
import {GetUserToken} from "../../../../api/token";
import PluginAddDeleteList from "../../../../components/Common/PluginAddDeleteList2";
import {PostToPbx} from "../../../../api/http-post";
import {fetchDataEntity} from "../../../../api/entity-data";
import {LoadDataCommon} from "../../../../api/fn";

const {TextArea} = Input;
const {Content} = Layout;
const {Option} = Select;

class ManageInvoiceTransactionAdhocPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Modules: [],
            Products: [],
            Clients:[],
            ItemsService:[],
            ItemsLabour:[],
            VirtualCompanies:[],
            isLoading: false,
            company: "",
            component: "list",
            newEntry: {},
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await this.loadData("modules", "Modules");
        await this.loadData("SettingInvoiceServiceDebtors", "Clients");

        await this.loadData("SettingInvoiceServiceItem", "ItemsService");
        await this.loadData("SettingInvoiceAddonLabourItems", "ItemsLabour");

    }
    loadData = async (targetEntity, stateKey) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            await _this.setState({
                [stateKey]: data.RESULT
            })
        });
    }

    getModuleList = () => {
        let ls = [];
        for (let i in this.state.Modules) {
            let row = this.state.Modules[i];
            if (row.org === this.state.newEntry.org) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    getCompanyList = () => {
        let ls = [];
        for (let i in this.state.Companies) {
            let row = this.state.Companies[i];
            ls.push({
                key: row.CustomerNumber,
                val: row.Name,
            });
        }
        return ls;
    }
    getClientOption=()=>{
        let ls = [];
        for (let i in this.state.Clients) {
            let row = this.state.Clients[i];
            if (row.org === this.state.newEntry.selectedCompany) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    getItemOption=()=>{
        let ls = [];
        if(this.state.newEntry.category==="service"){
            for (let i in this.state.ItemsService) {
                let row = this.state.ItemsService[i];
                if (row.org === this.state.newEntry.selectedCompany) {
                    continue
                }
                if (row.client === this.state.newEntry.client) {
                    continue
                }
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }

        if(this.state.newEntry.category==="labour"){
            for (let i in this.state.ItemsLabour) {
                let row = this.state.ItemsLabour[i];
                if (row.org === this.state.newEntry.selectedCompany) {
                    continue
                }
                if (row.client === this.state.newEntry.client) {
                    continue
                }
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }

        return ls;
    }
    getCategoryOption=()=>{
        return[
            {key:"service",val:"Service"},
            {key:"labour",val:"labour"}
        ]
    }

    render() {

        const companyOptions = this.getCompanyList();
        const clientOptions = this.getClientOption();
        const categoryOption =this.getCategoryOption();
        const getItemOption = this.getItemOption();

        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Load Transaction Adhoc</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"InvoiceTransactionAdhoc"}
                            depending={{
                                "org": {
                                    targetKey: "CustomerNumber",
                                    displayKey: "Name",
                                    storageTable: "Companies"
                                }
                            }}
                            fields={[
                                {
                                    name: "org",
                                    dataType: "option",
                                    required: true,
                                    label: "Organization",
                                    options: companyOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Companies"}
                                },
                                {
                                    name: "client",
                                    dataType: "option",
                                    required: true,
                                    label: "Debtor",
                                    options: clientOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Companies"}
                                },
                                {
                                    name: "category",
                                    dataType: "option",
                                    required: true,
                                    label: "Category",
                                    options: categoryOption,
                                    depending: {}
                                },
                                {
                                    name: "reference",
                                    dataType: "text",
                                    required: true,
                                    label: "Reference",
                                    options: [],
                                    depending: {}
                                },
                                {
                                    name: "referencetype",
                                    dataType: "option",
                                    required: true,
                                    label: "Reference Type",
                                    options: [
                                        {key:"Credit Note",val:"Credit Note"},
                                        {key:"Payment",val:"Payment"},
                                    ],
                                    depending: {}
                                },
                                {
                                    name: "description",
                                    dataType: "text",
                                    required: true,
                                    label: "Description",
                                    options: [],
                                    depending: {}
                                },
                                {
                                    name: "transdate",
                                    dataType: "text",
                                    required: true,
                                    label: "Transaction Date",
                                    options: [],
                                    depending: {}
                                },
                                {
                                    name: "amount",
                                    dataType: "float",
                                    required: true,
                                    label: "Amount",
                                    options: [],
                                    depending: {}
                                },
                                {
                                    name: "comment",
                                    dataType: "text",
                                    required: true,
                                    label: "Comment",
                                    options: [],
                                    depending: {}
                                },

                            ]}
                            deleteKeys={[
                                {key: "org", type: "string"},
                                {key: "client", type: "string"},
                            ]}
                            stateArray={"Data"}
                            table={"InvoiceTransactionAdhoc"}
                            addFormText={"TTransaction Adhoc"}
                            stepTitle={"Setup new Transaction Adhoc"}

                            filterByCompany={true}


                        />

                    </div>
                </div>
            </Page>
        )
    }

}

export default Connector(ManageInvoiceTransactionAdhocPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

