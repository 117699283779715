import React from "react";

import {Steps, Button, message, Alert} from 'antd';
import {SubmitPost} from "../Common/localFunctions";
import StepBulkClockingInfo from "./StepBulkClockingInfo";
import StepBulkClockingWorker from "./StepBulkClockingWorker";
import {PostToPbx} from "../../../api/http-post";
import StepBulkClockingTimer from "./StepBulkClockingTimer";
import moment from "moment";


const {Step} = Steps;

export class StepBulkClockingIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            initialFields: [],
            otherFields: [],
            newFieldOther: "",
        }
    }

    componentDidMount = async () => {
        let _this = this.props._this;
        console.log("NewAttributeFormStep selectedRecord :> ", _this.state.selectedRecord);
        this.loadInitialField();
        await this.loadContractData();
    }


    loadContractData = async () => {
        let _this = this;
        let state = this.props._this.state;
        let contract = this.props._this.state.selectContract;
        console.log("loadContractData contract > ", contract)
        let hub = {
            Org: state.selectedCompany,
            Module: "contract",
            Ref: state.selectedEntity,
        }

        let endpoint = "/payroll/contract/fetch-data"
        await PostToPbx(hub, endpoint, async (output) => {
            console.log("loadContractData > ", hub, output)
            let data = output.RESULT;
            let tmp = _this.props._this.state;
            tmp.DataPeriod = data.TermsPeriod;
            tmp.DataDesignation = data.TermsDesignation;
            tmp.DataPayCycle = data.TermsPayCycle;
            tmp.DataSite = data.TermsSite;
            tmp.DataRate = data.TermsRate;
            tmp.DataRateSpecific = data.TermsRateSpecific;
            tmp.DataSlot = data.TermsSlot;
            tmp.DataSiteDesignation = data.TermsSiteDesignation;
            tmp.DataShift = data.TermsShift;
            tmp.DataCondition = data.TermsCondition;
            _this.props._this.setState(tmp);
            console.log("loadInitialData > ", output, " > ", data)
        });
    }
    handleInput = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    loadInitialData=async ()=>{
        this.props._this.setState({
            component: "list",
            selectedRecord: {}
        })
    }
    loadInitialField = () => {
        const rec = this.props._this.state.selectedRecord;
        let ls = [];

        this.setState({
            initialFields: ls
        })
    }


    next() {
        const current = this.state.current + 1;
        this.setState({current});
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({current});
    }

    getSteps = () => {

        return [
            {
                title: 'Info',
                content: <StepBulkClockingInfo _this={this.props._this}/>,
            },
            {
                title: 'Worker',
                content: <StepBulkClockingWorker _this={this.props._this}/>,
            },
            {
                title: 'Timer Shift',
                content: <StepBulkClockingTimer _this={this.props._this}/>,
            },
            {
                title: 'Submit',
                content: 'Ready to submit',
            },
        ];
    }

    findPayCycle=(date)=>{
        let compareDate = moment(date, "YYYY-MM-DD");
        let startDate   = moment("12/01/2013","YYYY-MM-DD");
        let endDate     = moment("15/01/2013", "YYYY-MM-DD");
        let rec={};
        let boo=false;
        for(let i in this.props._this.DataPayCycle){
            const row = this.props._this.DataPayCycle[i];
             startDate   = moment(row.StartDate,"YYYY-MM-DD");
             endDate     = moment(row.EndDate, "YYYY-MM-DD");
             if(compareDate.isBetween(startDate, endDate)){
                 boo=true;
                 rec=row
             }
        }
        return {boo,rec}
    }

    submitRecord = async () => {
        // attributes-bulk
        let _this = this;
        let state = this.props._this.state;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";

        let endpoint = "/common/entity/clock-request-bulk/insert"; // "/manager/entity/insert/extensions";
        this.setState({
            isLoading: true,
        });
        const buildOptions = (strIn) => {
            const arr = strIn.split(",");
            let ls = {};
            for (let i in arr) {
                const item = arr[i];
                ls[item] = item
            }
            return ls
        }
        let data = [];
        let haveUniqueKey = false;

        let payRef="";
        let payDate="";
        let payCycleInfo = this.findPayCycle(state.clockDate);
        if(payCycleInfo.boo){
            const r =payCycleInfo.rec;
            payRef=r.StartDate+"#"+r.EndDate+"#"+r.Period;
            payDate = r.PayDate;

        }
        for(let i in state.newEntry){
            let rec = state.newEntry[i];
            let o={
                Org       :state.selectedCompany,
                Module    :state.selectedEntity,
                Ref       :state.selectedEntity,
                ClockDate :state.clockDate,
                ProjectModule :"contract",
                ProjectRef    :state.selectedEntity,
                PayCycle      :payRef,
                PayDate:payDate,
                Shift         :state.selectedShift,
                Slot          :1,
                WorkerRef    :rec.key,
                WorkerModule :state.selectedEntity,
                Site :state.selectedSite,
                StartTime :rec.start,
                EndTime   :rec.end,
                QaState   :"pending",
                Hours: parseFloat(rec.hours),
            }
            data.push(o)

        }
        /*
         */

        post.Data = data;
        post.Params = {}

        console.log("PageBuilder post submitRecord > ", post);


        await SubmitPost(post, endpoint, "Thank you , clocks submitted", this, async (dIn) => {
            _this.props._this.setState({
                component: "list",
                selectedSite: "",
                selectedShift:"",
                newEntry:{},
            })
            _this.setState({
                isLoading: false,
            });
            await _this.props._this.initialReload();
            // alert("Thank you , attributes submitted")
        })
    }

    selectionInfo=()=>{
        let state = this.props._this.state;
        const entity = state.selectedEntity;
        const clockDate = state.clockDate;
        let company ="";
        const clientCode = state.selectedCompany;

        for(let i in state.Companies){
            const row=state.Companies[i];
            if(row.CustomerNumber===clientCode){
                company = row.Name;
            }
        }
        return{
            entity,company,clockDate
        }
    }

    render() {
        let state = this.props._this.state;
        let {current} = this.state;
        let steps = this.getSteps();
        let {company ,entity,clockDate}= this.selectionInfo();
        console.log("RRRRRRR-=-=-=->>> ",state,)
        return (
            <div style={styles.container}>
                <div style={{minWidth: "100%", color: "red"}}>
                    <Alert style={{margin: '16px 0'}}
                           message={"New Bulk Clock | Database: "+entity+" | company: "+company+" | Date: "+clockDate}
                           type={"info"}
                    />
                </div>
                <Steps current={current}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title}/>
                    ))}
                </Steps>
                <div style={styles.stepsContent}>{steps[current].content}</div>
                <div style={styles.stepsAction}>
                    <Button
                        type="secondary"
                        onClick={() => this.props._this.setState({
                            component: "list",
                            selectedRecord: {},
                            newEntry:{},
                            clockDate:"",
                        })}
                        style={{backgroundColor: "red", color: "white", marginRight: 5}}
                    >
                        Cancel
                    </Button>
                    {current < steps.length - 1 && (
                        <Button type="primary" onClick={() => this.next()}>
                            Next
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button type="primary" onClick={async () => {
                            await this.submitRecord();
                            message.success('Processing complete!');

                        }}>
                            Done
                        </Button>
                    )}
                    {current > 0 && (
                        <Button style={{margin: '0 8px'}} onClick={() => this.prev()}>
                            Previous
                        </Button>
                    )}
                </div>
            </div>
        )
    }

}

const styles = {
    stepsContent: {
        marginTop: 16,
        border: '1px dashed #e9e9e9',
        borderRadius: 2,
        backgroundColor: '#fafafa',
        minHeight: 200,
        textAlign: "center",
        paddingTop: 80
    },
    stepsAction: {
        marginTop: 24
    },
    container: {
        padding: 10,
        minWidth: "100%",
        maxWidth: "100%",
        minHeight: 500,
        /*backgroundColor: "lightgray"*/
    }
}
