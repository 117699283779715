import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {Alert, Button, Form, Layout, List, Select, Skeleton,Typography,Space} from "antd";
import {fetchDataEntity} from "../../../api/entity-data";
import {GetUserToken} from "../../../api/token";
import {SortData, UpCaseFirst} from "../../../api/fn";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {NewAttributeFormStep} from "../../../components/web/Database/NewAttributeFormStep";
import {ContractList} from "../../../components/web/Database/ContractList";
import Avatar from "@material-ui/core/Avatar";
import SettingOutlined from "@ant-design/icons/lib/icons/SettingOutlined";
import ContractSetupStep from "../../../components/web/Settings/ContractSetupStep";



const { Text, Link } = Typography;

const {Content} = Layout;
const {Option} = Select;


class DatabaseSettingContractPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Org: "",
            Companies: [],
            Entities: [],
            Databases: [],
            Attributes: [],
            selectedCompany: "",
            selectedEntity: "",
            selectedRecord: {},
            Data: [],
            isLoading: false,
            component: "list",
            initLoading: false,
            loading: true,
            showSetup: false,
            selectContract: null,
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this);
        await this.initialReload()
    }
    initialReload = async () => {
        await fetchDataEntity("Entities", "", "", this, undefined, "common/entity/modules/list");
        await fetchDataEntity("Attributes", "", "", this, undefined, "common/entity/attributes/list");
        await fetchDataEntity("Databases", "", "", this, undefined, "common/entity/databases/list");
    }
    handleChangeCompany = (value, key) => {
        this.setState({
            [key]: value,
        });
        console.log("handleChangeCompany > ", value, " > ", key)
    }
    renderCompaniesSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={"Select Company"}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedCompany")}
                    value={this.state.selectedCompany}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderWarningBar = () => {
        return
        let boo = false
        if (this.state.selectedCompany === "" || this.state.selectedEntity === "" || this.state.component === "form") {
            boo = true
        }
        if (!boo) {
            return
        }
        return (
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Alert style={{margin: '16px 0'}} message="Please select your company above to view your records!"
                       type={"error"}/>
            </div>
        )
    }
    getListDisplayField = (org, module, targetDisplay) => {
        let ls = [];
        const attributes = this.state.Attributes;
        for (let i in attributes) {
            const row = attributes[i];
            if (row.org !== org) {
                continue
            }
            if (row.module === module) {
                if (row[targetDisplay]) {
                    ls.push(row)
                }
            }
        }
        ls = SortData("position", ls);
        return ls
    }
    getFiledDisplayData = (inData) => {
        const attributes = this.getListDisplayField(inData.org, inData.module, "displaytable");
        let name = "";
        for (let i in attributes) {
            const row = attributes[i];
            const fieldname = row.fieldname;
            console.log("addFiledDisplayData +++> ", fieldname, row);
            name = name + inData.data[fieldname] + ", ";
        }
        return name
    }
    addSetup = (row) => {
        this.setState({
            showSetup: true,
            selectContract: row,
        })
    }
    renderList = () => {
        const {loading, initLoading} = this.state;
        const loadMore =
            !loading ? (
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: 12,
                        height: 32,
                        lineHeight: '32px',
                    }}
                >
                    <Button onClick={this.onLoadMore}>loading more</Button>
                </div>
            ) : null;

        if (this.state.component !== "list") {
            return
        }

        //todo let find contract entity
        let contractEntities = [];
        let contractEntitiesMap = {};
        for (let i in this.state.Entities) {
            const row = this.state.Entities[i];
            console.log("(:)--Has ORg : ", row.org, "==", this.state.Org, " > ", row.org !== this.state.Org);
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            console.log("setup contract 0 : ", row.haspayroll)
            if (row.haspayroll) {
                row.module = "contract";
                row.ref=row.name;
                contractEntities.push(row);
                contractEntitiesMap[row.name] = row;
            }
        }

        //todo target databases


        let ls = contractEntities;

        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <Space direction="vertical">
                <a  style={{fontSize:24}}>Project List</a>
                </Space>
                <List
                    className="demo-loadmore-list"
                    loading={initLoading}
                    itemLayout="horizontal"
                    loadMore={loadMore}
                    dataSource={ls}
                    renderItem={item => (
                        <List.Item
                            actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">more</a>]}
                        >
                            <Skeleton avatar title={false} loading={item.loading} active>
                                <List.Item.Meta
                                    avatar={
                                        <Avatar
                                            src="https://www.waxdigital.com/wp-content/uploads/2017/07/icon-contract-management-green-300x300.png"/>
                                    }
                                    title={<a href="https://ant.design">{item.ref}</a>}
                                    description={""}
                                />
                                <div>
                                    <a onClick={() => this.addSetup(item)}>
                                        {/*<SettingOutlined />&nbsp;*/}
                                        setup
                                    </a>
                                </div>
                            </Skeleton>
                        </List.Item>
                    )}
                />
            </GridItem>
        )

    }
    renderForm = () => {

        return(
            <ContractSetupStep
               _this={this}
            />
        )
    }

    render() {
        let props = this.props;
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    {/*<Title style={styles.title}>Contract Setup</Title>*/}

                    {!this.state.showSetup ?
                        <React.Fragment>
                            <div style={{minWidth: "100%", padding: 10}}>
                                {this.renderCompaniesSelection()}
                            </div>
                            {this.renderWarningBar()}
                            {this.renderList()}
                        </React.Fragment> :
                        <React.Fragment>
                            {this.renderForm()}
                        </React.Fragment>
                    }
                </div>
            </Page>
        )
    }
}


export default Connector(DatabaseSettingContractPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
