
import React from "react";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import {Layout, Select, Input} from "antd";
import {GetUserToken} from "../../../../api/token";
import PluginAddDeleteList from "../../../../components/Common/PluginAddDeleteList2";
import {PostToPbx} from "../../../../api/http-post";
import {fetchDataEntity} from "../../../../api/entity-data";

const {TextArea} = Input;
const {Content} = Layout;
const {Option} = Select;

class ManageInvoiceLabourReportSettingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Modules: [],
            Projects:[],
            Products: [],
            Clients:[],
            VirtualCompanies:[],
            isLoading: false,
            company: "",
            component: "list",
            newEntry: {},
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await this.loadData("modules", "Modules");
        await this.loadData("SettingInvoiceServiceDebtors", "Clients");
        await this.loadData("InvoiceVirtualCompany", "VirtualCompanies");
        await this.loadData("SettingInvoiceAddonProject", "Projects");

    }
    loadData = async (targetEntity, stateKey) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            await _this.setState({
                [stateKey]: data.RESULT
            })
        });
    }

    getModuleList = () => {
        let ls = [];
        for (let i in this.state.Modules) {
            let row = this.state.Modules[i];
            if (row.org === this.state.newEntry.org) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    getProjectList = () => {
        let ls = [];
        for (let i in this.state.Projects) {
            let row = this.state.Projects[i];
            console.log(`<>Compare Project --> ${row.org}==${this.state.newEntry.org}`)
            if (row.org === this.state.newEntry.org) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    getCompanyList = () => {
        let ls = [];
        for (let i in this.state.Companies) {
            let row = this.state.Companies[i];
            ls.push({
                key: row.CustomerNumber,
                val: row.Name,
            });
        }
        return ls;
    }
    getClientOption=()=>{
        let ls = [];
        for (let i in this.state.Clients) {
            let row = this.state.Clients[i];
            if (row.org === this.state.newEntry.selectedCompany) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    getVirtualCompanyOption=()=>{
        let ls = [];
        for (let i in this.state.VirtualCompanies) {
            let row = this.state.VirtualCompanies[i];
            if (row.org === this.state.newEntry.selectedCompany) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }


    render() {

        const companyOptions = this.getCompanyList();
        const clientOptions = this.getClientOption();
        const vCompaniesOptions = this.getVirtualCompanyOption()
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Labour Report Setting</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"LabourReportSetting"}
                            depending={{
                                "org": {
                                    targetKey: "CustomerNumber",
                                    displayKey: "Name",
                                    storageTable: "Companies"
                                }
                            }}
                            fields={[
                                {
                                    name: "org",
                                    dataType: "option",
                                    required: true,
                                    label: "Organization",
                                    options: companyOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Companies"}
                                },
                                {
                                    name: "project",
                                    dataType: "option",
                                    required: true,
                                    label: "Project",
                                    options: this.getProjectList(),
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Companies"}
                                },

                                {
                                    name: "vendorcontactperson",
                                    dataType: "text",
                                    required: true,
                                    label: "VendorContactPerson",
                                    options: [],
                                    depending: {}
                                },
                                {
                                    name: "projectnumber",
                                    dataType: "text",
                                    required: true,
                                    label: "ProjectNumber",
                                    options: [],
                                    depending: {}
                                },
                                {
                                    name: "agentrepresentative",
                                    dataType: "text",
                                    required: true,
                                    label: "Agent Representative",
                                    options: [],
                                    depending: {}
                                },
                                {
                                    name: "department",
                                    dataType: "text",
                                    required: true,
                                    label: "Department",
                                    options: [],
                                    depending: {}
                                },
                                {
                                    name: "contractorvendoremail",
                                    dataType: "text",
                                    required: true,
                                    label: "VendorEmail",
                                    options: [],
                                    depending: {}
                                },
                                {
                                    name: "contractorvendorcellNumber",
                                    dataType: "text",
                                    required: true,
                                    label: "VendorCellNumber",
                                    options: [],
                                    depending: {}
                                },
                                {
                                    name: "contractorvendorworknumber",
                                    dataType: "text",
                                    required: true,
                                    label: "WorkNumber",
                                    options: [],
                                    depending: {}
                                },
                                {
                                    name: "vendorcompanyname",
                                    dataType: "text",
                                    required: true,
                                    label: "CompanyName",
                                    options: [],
                                    depending: {}
                                },
                                {
                                    name: "projectstartdate",
                                    dataType: "text",
                                    required: true,
                                    label: "ProjectStartDate",
                                    options: [],
                                    depending: {}
                                },
                                {
                                    name: "projectenddate",
                                    dataType: "text",
                                    required: true,
                                    label: "ProjectEndDate",
                                    options: [],
                                    depending: {}
                                },
                                {
                                    name: "projectname",
                                    dataType: "text",
                                    required: true,
                                    label: "ProjectName",
                                    options: [],
                                    depending: {}
                                },

                                {
                                    name: "directorate",
                                    dataType: "text",
                                    required: true,
                                    label: "Directorate",
                                    options: [],
                                    depending: {}
                                },
                                {
                                    name: "jobstarttime",
                                    dataType: "text",
                                    required: true,
                                    label: "JobStartTime",
                                    options: [],
                                    depending: {}
                                },
                                {
                                    name: "jobendtime",
                                    dataType: "text",
                                    required: true,
                                    label: "JobEndTime",
                                    options: [],
                                    depending: {}
                                },
                                {
                                    name: "workinghour",
                                    dataType: "float",
                                    required: true,
                                    label: "WorkingHour",
                                    options: [],
                                    depending: {}
                                },
                            ]}
                            deleteKeys={[
                                {key: "org", type: "string"},
                                {key: "company", type: "string"},
                                {key: "client", type: "string"},
                            ]}
                            stateArray={"Data"}
                            table={"LabourReportSetting"}
                            addFormText={"Labour Report Setting"}
                            stepTitle={"Setup Labour Report Setting"}

                            filterByCompany={true}


                        />

                    </div>
                </div>
            </Page>
        )
    }

}

export default Connector(ManageInvoiceLabourReportSettingPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

