import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {Alert, Button, Col, Form, Layout, Row, Select} from "antd";
import {fetchDataEntity} from "../../../api/entity-data";
import {formatMoney, SortData,} from "../../../api/fn";
import {PostToPbx} from "../../../api/http-post";
import moment from "moment";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import DisplayDataReportSummaryHours from "../../../components/Reports/DisplayDataReportSummaryHours";
import Configures from "../../../configures";
import SummaryInfo from "./SummaryInfo";
import Downloader from "./Downloader";

const {Content} = Layout;
const {Option} = Select;

function convertDateFormat_DDMMYYYY(dateIn) {
    let mydate = moment(dateIn, "YYYY-MM-DD").format("DD MMM YYYY");
    return mydate;
}

function formatCurrency(stringIn) {
    return stringIn.toFixed(2)
}


class InvoiceLabourAllPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Org: "",
            Companies: [],
            Entities: [],

            selectedCompany: "",
            selectedModule: "",
            selectedRecord: {},
            selectedPeriod: "",
            selectedSite: "",

            Data: [],
            isLoading: false,
            component: "list",
            initLoading: false,

            DataPeriod: [],
            DataPayCycle: [],
            DataSite: [],

            Results: null,
            showViewDetail: false,
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.initialReload()
    }
    initialReload = async () => {
        await fetchDataEntity("Entities", "", "", this, undefined, "common/entity/modules/list");
    }
    loadContractInfo = async (selectedModule) => {
        let _this = this;
        let module = this.state.selectedModule;
        let hub = {
            Org: this.state.selectedCompany,
            Module: "contract",
            Ref: selectedModule,
        }

        console.log("loadContractInfo > ", hub)
        let endpoint = "/payroll/contract/fetch-data"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            let tmp = _this.state;
            tmp.DataPeriod = data.TermsPeriod;
            tmp.DataDesignation = data.TermsDesignation;
            tmp.DataPayCycle = data.TermsPayCycle;
            tmp.DataSite = data.TermsSite;
            tmp.DataRate = data.TermsRate;
            tmp.DataRateSpecific = data.TermsRateSpecific;
            tmp.DataSlot = data.TermsSlot;
            tmp.DataSiteDesignation = data.TermsSiteDesignation;
            tmp.DataShift = data.TermsShift;
            tmp.DataCondition = data.TermsCondition;
            _this.setState(tmp);

            console.log("loadInitialData > ", output, " > ", data)
        });
    }
    handleChangeCompany = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    handleChange = async (e, key) => {
        const value = e.value;
        this.setState({
            [key]: value,
        });

        if (key === "selectedModule") {
            console.log("selected module > ", key, value)
            await this.loadContractInfo(value)
        }

    }
    handleChange2 = (e, ee, key) => {
        const val = e;
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
    }
    validateSubmitSearch = () => {
        const state = this.state;
        if (state.selectedCompany === "") {
            alert("Validation fail! Please select your company")
            return false
        }
        if (state.selectedModule === "") {
            alert("Validation fail! Please select your project")
            return false
        }
        if (state.selectedPeriod === "") {
            alert("Validation fail! Please select your pay circle")
            return false
        }

        return true
    }
    submitSearch = async () => {
        this.setState({
            isLoading: true,
            Results: null,
        });

        if (!this.validateSubmitSearch()) {
            return
        }

        let hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedModule,
        }

        console.log("1 -Data--> ", hub)
        let endpoint = "/invoices/labour/report/all"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            this.setState({
                Results: data,
                isLoading: false,
            });
            console.log("-search Data--> ", data)
        });

    }
    getPayDate = () => {
        let arr = this.state.selectedPeriod.split("#");
        return arr[3]
    }

    renderWarningBar = () => {
        return
        let boo = false
        if (this.state.selectedCompany === "" || this.state.selectedEntity === "" || this.state.component === "form") {
            boo = true
        }
        if (!boo) {
            return
        }
        return (
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Alert style={{margin: '16px 0'}} message="Please select your company above to view your records!"
                       type={"error"}/>
            </div>
        )
    }
    renderCompaniesSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={""}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedCompany")}
                    value={this.state.selectedCompany}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderModules = () => {
        let ls = [];
        let org = this.state.selectedCompany;
        for (let i in this.state.Entities) {
            const row = this.state.Entities[i];

            if (row.org === org) {
                ls.push({
                    key: row.name,
                    val: row.name,
                })
            }
        }
        return (
            <Select
                name={"selectedModule"}
                defaultValue=""
                onChange={(e, ee) => this.handleChange(ee, "selectedModule")}
            >
                {ls.map((record, index) => {
                    return (
                        <Option value={record.key}>{record.val}</Option>
                    )
                })}
            </Select>
        )
    }
    formatDate = (dateIn) => {
        return convertDateFormat_DDMMYYYY(dateIn)
    }
    renderPayCycle = () => {
        let data = this.state.DataPayCycle;
        data = SortData("Period", data)
        let ls = [];
        for (let i in data) {
            let row = data[i];
            const key = row.StartDate + "#" + row.EndDate + "#" + row.Period + "#" + row.PayDate;
            const display = row.Period + ". -> Start: " + this.formatDate(row.StartDate) + " -> End: " + this.formatDate(row.EndDate) +
                " -> Pay date: " + this.formatDate(row.PayDate);
            ls.push({
                key: key,
                val: display
            })
        }
        return (
            <React.Fragment>
                <Form.Item label={"Pay Cycle"} required={true}>
                    <Select
                        name={"selectedPeriod"}
                        defaultValue={this.state.selectedPeriod}
                        value={this.state.selectedPeriod}
                        onChange={(e, ee) => this.handleChange2(e, ee, "selectedPeriod")}
                    >
                        {ls.map((record, index) => {
                            return (
                                <Option value={record.key}>{record.val}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            </React.Fragment>
        )
    }
    renderSummary=()=>{



        let numPeople=0,numNormal=0,numSat=0,numPh=0;
        for(let i in this.state.Results){
            let row=this.state.Results[i];
            numPeople = row.Peoples;
            numNormal = row.NormalDays;
            numSat = row.SatDays;
            numPh = row.PhhDays;
        }

        let ls=[
            {label:"Number of People",val:numPeople},
            {label:"Number of Normal Days",val:numNormal},
            {label:"Number of Saturday",val:numSat},
            {label:"Number of Public Holiday",val:numPh},
        ]

        return ls.map(row=>{
            return(
                <SummaryInfo label={row.label} val={row.val}/>
            )
        })

    }
    renderReport = () => {

        return (
            <div style={styles.containerReport}>
                {this.renderSummary()}
                <Row>
                    <Col span={5} style={styles.pushLeft}>Invoice Name</Col>
                    <Col span={2} style={styles.center}>Purchase No.</Col>
                    <Col span={2} style={styles.center}>Invoice No.</Col>

                    {/*<Col span={3} style={styles.putRight}>Cost Normal day</Col>
                    <Col span={3} style={styles.putRight}>Cost Saturday</Col>
                    <Col span={3} style={styles.putRight}>Cost Ph.</Col>*/}
                    <Col span={5} style={styles.putRight}>Subtotal</Col>
                    <Col span={5} style={styles.putRight}>Vat</Col>
                    <Col span={5} style={styles.putRight}>Total</Col>
                </Row>
                {this.state.Results.map(row => {
                    return (
                        <Row>
                            <Col span={5} style={styles.pushLeft}>
                                <Downloader linkDownload={row.InvLinkDownload} name={row.InvName}/>
                                {row.InvName}
                            </Col>
                            <Col span={2} style={styles.center2}>{row.PurchaseNumber}</Col>
                            <Col span={2} style={styles.center2}>{row.InvNumber}</Col>
                            {/*<Col span={3} style={styles.putRight2}>{row.NormalCost.toFixed(2)}</Col>
                            <Col span={3} style={styles.putRight2}>{row.SatCost.toFixed(2)}</Col>
                            <Col span={3} style={styles.putRight2}>{row.PhhCost.toFixed(2)}</Col>*/}
                            <Col span={5} style={styles.putRight2}>R{formatMoney(row.SubTotal)}</Col>
                            <Col span={5} style={styles.putRight2}>{row.Vat.toFixed(2)}</Col>
                            <Col span={5} style={styles.putRight2}>{row.Total.toFixed(2)}</Col>
                        </Row>
                    )
                })}
            </div>
        )
    }

    render() {

        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Pay Cycle Hours Report</Title>
                    <Row gutter={16} style={{marginTop: 20}}>
                        <Col span={12}>
                            <Form.Item
                                label={"Company"}
                            >
                                {this.renderCompaniesSelection()}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={"Project"}
                            >
                                {this.renderModules()}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{marginTop: -40}}>
                        <Col span={24}>
                            {this.renderPayCycle()}
                        </Col>
                    </Row>
                    <Row gutter={16} style={{marginTop: -10}}>
                        <Col span={24}>
                            <Button style={{minWidth: "100%"}} danger onClick={() => this.submitSearch()}>Search
                            </Button>&nbsp;&nbsp;
                        </Col>
                    </Row>
                    {this.state.Results !== null ?
                        <div style={{marginTop: 40}}>
                            {this.renderReport()}
                        </div> : null
                    }
                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }

}


export default Connector(InvoiceLabourAllPage);

const styles = {
    containerReport: {
        minWidth:"100%",
        maxWidth:"100%"
    },
    pushLeft: {
        textAlign: "left",
        border: "1px solid black",
    },
    putRight: {
        textAlign: "right",
        border: "1px solid black",
    },
    center: {
        textAlign: "center",
        border: "1px solid black",
    },

    pushLeft2: {
        textAlign: "left",
        border: "1px solid black",
        color:"#337aff"
    },
    putRight2: {
        textAlign: "right",
        border: "1px solid black",
        color:"#337aff"
    },
    center2: {
        textAlign: "center",
        border: "1px solid black",
        color:"#337aff"
    }
}

// #337aff
