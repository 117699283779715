import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import Title from "../../../../components/Common/Title";
import Page from "../../../../layout/web/Page";
import Connector from "../../../../redux/connector";

import {
    BuildComboDataClient,
    BuildComboDataCompany, BuildComboDataProject, FetchConfigDataService,
} from "../../../../services/service-woodchipping";
import {Button, Col, DatePicker, Input, Row, Form, List, Badge, Table} from "antd";
import { Tabs } from 'antd';
import ControllerSelectBox from "../../../../components/Common/ControllerInput/ControllerSelectBox";
import moment from "moment";


import '../../ServiceModule/Labour/styles.css'
import {PostToWoodChipping} from "../../../../api/http-post";
import LoadingProcessing from "../../../../components/Common/page/Common/LoadingProcessing";


class WoodChippingVerificationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: null,
            ConfigData: {},
            selectedCompany: "",
            selectedClient: "",
            selectedProject: "",
            selectedCompostMaker:"",
            targetDate: moment().format("YYYY-MM-DD"),

            InputBase64String: "",
            InputFileName:"",
            InputDateStart: "",
            InputDateEnd: "",
            InputSheetName: moment().format("MMMM YYYY"),
            InputRowDataStartLine:"7",
            mode: "list",
            busy:false,
        }

        this.fileRef = React.createRef()
    }
    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        //await fetchDataEntity("Data", "", "", this, undefined, "common/entity/WcRate/list");
        await this.fetchConfigData();
    }
    onChangeStartDate=(dateString)=>{
        this.setState({
            InputDateStart:dateString,
            InputSheetName: moment(dateString).format("MMMM YYYY").toUpperCase()
        })
    }
    fetchConfigData = async () => {
        await FetchConfigDataService((res) => {
            this.setState({
                ConfigData: res
            })
        })
    }
    handleChangeInput = async (key, value) => {
        this.setState({
            [key]: value,
        })
    }
    handleGetInputValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return ""
        }
        return this.state[key];
    }
    onChangeFile=()=>{
        const file = this.fileRef.current.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const base64String = event.target.result;
            const fileName = file.name;
            this.setState({
                InputBase64String: base64String,
                InputFileName:fileName,
            });
        };
        reader.readAsDataURL(file);
    }

    validationOfInput=()=>{

        return true
    }
    onSubmit=async ()=>{
        if(!this.validationOfInput()){
            return
        }
        let payload={
            Org: this.state.selectedCompany,
            Client: this.state.selectedClient,
            Project: this.state.selectedProject,
            StartDate: this.state.InputDateStart,
            EndDate: this.state.InputDateEnd,
            FileInfo:{
                FileName: this.state.InputFileName,
                Base64String:this.state.InputBase64String.split(";base64,")[1],
            },
            SheetName:this.state.InputSheetName,
            ColHeaderRow: parseInt(this.state.InputRowDataStartLine),
        }
        let endpoint = "/report/delivery-note/verification"
        console.log("::onSubmit send> ", payload)
        this.setState({
            busy:true,
            Results:null,
        })
        await PostToWoodChipping(payload,endpoint,(res)=> {
            console.log("::onSubmit result> ", res)
            try{
                this.setState({
                    busy:false,
                    Results:res? res.RESULT:null,
                })
            }catch (e) {
                console.log("::onSubmit response error ",e)
                this.setState({
                    busy:false,
                    Results:null,
                })
            }

        })
    }

    RenderInput=(label,stateKey)=>{
        return(
            <Form.Item
                label={label}
                name={label} // This name will be used when you submit the form
                rules={[{ required: true, message: 'Please input your username!' }]}
            >
                <Input
                    accept={"numeric"}
                    className={"ant.input"}
                    onChange={(e)=>this.setState({
                        [stateKey]:e.target.value
                    })}
                    value={this.state[stateKey]}
                    defaultValue={this.state[stateKey]}
                />
            </Form.Item>
        )
    }

    RenderListResultBudge=()=>{
        let result = this.state.Results;


        const data = [
            {
                title: 'Number of record from CoCT logs',
                count: result.NumberOfRecordOut, // Example count for badge
            },
            {
                title: 'Number of record from Our logs',
                count: result.NumberOfRecordIn, // Example count for badge
            },
            {
                title: 'Total missing from CoCT logs',
                count: result.TotalMissingOut, // Example count for badge
            },
            {
                title: 'Total missing from Our logs',
                count: result.TotalMissingIn, // Example count for badge
            },
        ];

        console.log("RenderListResultBudge >>> ",data)

        return (
            <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            title={item.title}
                            description="Number of record"
                        />
                        <div>
                            <Badge count={item.count} />
                        </div>
                    </List.Item>
                )}
            />
        );
    }
    RenderInnerListMissingData=(data,includePrice)=>{
        let columns=[
            {
                title: 'Web Ref',
                dataIndex: 'WbRef',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Note No#',
                dataIndex: 'DeliveryNote',
                align: 'center',
            },
            {
                title: 'Date',
                className: 'Date',
                dataIndex: 'money',
                align: 'center',
                render: (text) => <span>{moment(text).format("DD-MMM-YYYY")}</span>,
            },
            {
                title: 'Cubic Size',
                className: 'CubicSize',
                dataIndex: 'CubicSize',
                align: 'right',
            },
            {
                title: 'Cubic Size',
                dataIndex: 'LoadMass',
                align: 'right',
            },

        ]

        if(includePrice){
            columns  = [...columns,
                {
                    title: 'Price',
                    dataIndex: 'Price',
                    align: 'right',
                },
                {
                    title: 'Total',
                    dataIndex: 'Total',
                    align: 'right',
                    className: 'numeric',
                    render: (text) => <span>R{text?parseFloat(text.toFixed(2)):0.0}</span>,
                },
            ]
        }

        return(
            <Table
                columns={columns}
                dataSource={data}
                bordered
                /*title={() => 'Header'}
                footer={() => 'Footer'}*/
            />
        )
    }
    RenderTabsResult=()=>{
        const { TabPane } = Tabs;
        let result = this.state.Results;

        return(
            <Tabs
                defaultActiveKey="1"
                items={[
                    {
                        label: 'Missing From CoCT',
                        key: '1',
                        children: 'Tab 1',
                    },
                    {
                        label: 'Missing From Us',
                        key: '2',
                        children: 'Tab 2',
                        disabled: true,
                    },
                ]}
            >
                <TabPane tab="Missing From CoCT" key="1">
                    {this.RenderInnerListMissingData(result.RecordMissingOut,false)}
                </TabPane>
                <TabPane tab="Missing From Us" key="2">
                    {this.RenderInnerListMissingData(result.RecordMissingIn,true)}
                </TabPane>
            </Tabs>
        )
    }
    render() {
        return (

            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",

                }}>
                    <Title style={styles.title}> Wood Chipping Delivery Note Verification</Title>
                    <div style={{minWidth: "100%", }}>
                        <Row gutter={18}>
                            <Col span={12}>
                                <ControllerSelectBox
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={BuildComboDataCompany(this.state.ConfigData)}
                                    label={"Company"}
                                    name={"selectedCompany"}
                                />
                            </Col>
                            <Col span={12}>
                                <ControllerSelectBox
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={BuildComboDataClient(this.state.ConfigData, this.state.selectedCompany)}
                                    label={"Client"}
                                    name={"selectedClient"}
                                />
                            </Col>
                        </Row>
                        <Row gutter={18}>
                            <Col span={12}>
                                <ControllerSelectBox
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={BuildComboDataProject(
                                        this.state.ConfigData,
                                        this.state.selectedCompany,
                                        this.state.selectedClient
                                    )}
                                    label={"Project"}
                                    name={"selectedProject"}
                                />
                            </Col>
                            <Col span={12} style={styles.dateRow}>
                                <Col span={12} style={{paddingTop: 30}}>
                                    <DatePicker
                                        style={styles.datePickerBox}
                                        onChange={(date, dateString) => {this.onChangeStartDate( dateString)}}
                                        placeholder={"Start Date"}
                                    />
                                </Col>
                                <Col span={12} style={{paddingTop: 30}}>
                                    <DatePicker
                                        style={styles.datePickerBox}
                                        onChange={(date, dateString) => this.setState({InputDateEnd: dateString})}
                                        placeholder={"End Date"}
                                    />
                                </Col>

                            </Col>
                        </Row>
                        <Row gutter={18}>
                            <Col span={12}>
                                {this.RenderInput("Sheet name","InputSheetName")}

                            </Col>
                            <Col span={12}>
                                {this.RenderInput("Row data Start","InputRowDataStartLine")}
                            </Col>
                        </Row>
                        <Row gutter={18}>
                            <Col span={12} style={styles.dateRow}>
                                <input
                                    type={"file"}
                                    ref={this.fileRef}
                                    id={"fileId"}
                                    accept=".xlsx, .xls"
                                    onChange={this.onChangeFile}
                                    className={"ant-input"}
                                    style={styles.datePickerBox}
                                />
                            </Col>
                            <Col span={12} style={{}}>
                                <Button
                                    style={styles.datePickerBox}
                                    onClick={() => this.onSubmit()}
                                >
                                    Search
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <LoadingProcessing open={this.state.busy}/>
                            </Col>
                        </Row>

                        {this.state.Results?<div style={{
                            backgroundColor: "white", padding: 10, marginTop: 30,
                            minHeight: 350
                        }}>

                            <h2>Verification result</h2>
                            <hr style={{color:"red"}}/>
                            <br/>

                            {this.RenderListResultBudge()}
                            <br/><br/>
                            {this.RenderTabsResult()}


                        </div>:null}

                    </div>
                </div>
            </Page>
        )
    }
}

export default Connector(WoodChippingVerificationPage);
const styles = {
    boxFilterInner: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 5,
        borderRight: "1px solid black",
        fontsize: 14,
        fontWeight: "bold"
    },
    boxFilter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    dateRow: {
        display: "flex",
        flexDirection: "row"
    },
    datePickerBox: {
        height: 40,
        width: "100%"
    },
    row1Th: {
        border: "1px solid black",
    },
    rowForm: {
        marginBottom: 10,
        paddingBottom: 5,
        border: "0px solid lightgray",
        borderBottomWidth: 1,

    },

    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}