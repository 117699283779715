import React from "react";
import {Alert} from "antd";
import {Steps, Button, message} from 'antd';
import {Form, Input, Radio, Col, Row} from 'antd';
import {
    stepContractAttributesPeriod,
    stepContractAttributesTermsCondition,
    stepContractAttributesTermsDesignation,
    stepContractAttributesTermsPayCycle,
    stepContractAttributesTermsRate, stepContractAttributesTermsRateSpecific,
    stepContractAttributesTermsShift,
    stepContractAttributesTermsSite, stepContractAttributesTermsSiteDesignation
} from "./Constants";
import ContractSetupStepDisplayBuilder from "./ContractSetupStepDisplayBuilder";
import {PostToPbx} from "../../../api/http-post";
import ContractSetupStepStart from "./ContractSetupStepStart";

const {Step} = Steps;
// period, payCycle,designation, condition, rate, site, finish


export default class ContractSetupStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            DataPeriod: [],
            DataDesignation: [],
            DataSite: [],
            DataSiteDesignation: [],
            DataPayCycle: [],
            DataRate: [],
            DataRateSpecific:[],
            DataCondition: [],
            DataShift: [],
        };
    }

    componentDidMount = async () => {
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        let _this = this;
        let contract = this.props._this.state.selectContract;
        let hub = {
            Org: contract.org,
            Module: contract.module,
            Ref: contract.ref,
        }

        let endpoint = "/payroll/contract/fetch-data"
        await PostToPbx(hub, endpoint, async (output) => {
            console.log("loadInitialData > ", hub, output)
            let data = output.RESULT;
            let tmp = _this.state;
            tmp.DataPeriod = data.TermsPeriod;
            tmp.DataDesignation = data.TermsDesignation;
            tmp.DataPayCycle = data.TermsPayCycle;
            tmp.DataSite = data.TermsSite;
            tmp.DataRate = data.TermsRate;
            tmp.DataRateSpecific = data.TermsRateSpecific;
            tmp.DataSlot = data.TermsSlot;
            tmp.DataSiteDesignation = data.TermsSiteDesignation;
            tmp.DataShift = data.TermsShift;
            tmp.DataCondition = data.TermsCondition;
            _this.setState(tmp);

            console.log("loadInitialData > ", output, " > ", data)
        });
    }

    next() {
        const current = this.state.current + 1;
        this.setState({current});
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({current});
    }

    closeSetup = () => {
        if (!window.confirm("Are you sure to exit this setup?")) {
            return
        }
        let {_this} = this.props;
        _this.setState({
            showSetup: false,
            selectContract: null,
        })
    }
    getOptionFromState = (attributes, targetKey) => {
        let ls = []
        let data = this.state[attributes];
        for (let a in data) {
            const rec = data[a];
            ls.push({
                key: rec[targetKey],
                val: rec[targetKey]
            })
        }
        return ls;
    }
    getExtraData = (stepAttributes, conditionVal, loopAttribute, loopVal) => {
        let attributesData = [];
        for (let i in stepAttributes) {
            let row = stepAttributes[i];
            if (row.name === conditionVal) {
                row.options = this.getOptionFromState(loopAttribute, loopVal)
            }
            attributesData.push(row)
        }
        return attributesData
    }
    steps = () => {

        let rateAttributes = this.getExtraData(stepContractAttributesTermsRate, "Designation", "DataDesignation", "Name");
        rateAttributes = this.getExtraData(rateAttributes, "Slot", "DataShift", "Name");

        let rateAttributesSpecific = this.getExtraData(stepContractAttributesTermsRateSpecific, "Designation", "DataDesignation", "Name");
        rateAttributesSpecific = this.getExtraData(rateAttributesSpecific, "Slot", "DataShift", "Name");
        rateAttributesSpecific = this.getExtraData(rateAttributesSpecific, "Site", "DataSite", "Name");

        let siteEmployeeAttributes = this.getExtraData(stepContractAttributesTermsSiteDesignation, "Designation", "DataDesignation", "Name");
        siteEmployeeAttributes = this.getExtraData(siteEmployeeAttributes, "Site", "DataSite", "Name");
        // De grendel RD, 12


        return [
            {
                title: 'Start',
                content: <ContractSetupStepStart _this={this}/>,
            },
            {
                title: 'Period',
                content: <ContractSetupStepDisplayBuilder
                    fields={stepContractAttributesPeriod}
                    data={this.state.DataPeriod}
                    stateArray={"DataPeriod"}
                    _this={this}
                    selectContract={this.props._this.state.selectContract}
                    table={"terms-period"}
                    addFormText={"Period"}
                    stepTitle={"Contract Period"}
                />,
            },
            {
                title: 'Conditions',
                content: <ContractSetupStepDisplayBuilder
                    fields={stepContractAttributesTermsCondition}
                    data={this.state.DataCondition}
                    stateArray={"DataCondition"}
                    _this={this}
                    selectContract={this.props._this.state.selectContract}
                    table={"terms-condition"}
                    addFormText={"Condition"}
                    stepTitle={"Set Up Employee Working Conditions"}
                />,
            },
            {
                title: 'Designation',
                content: <ContractSetupStepDisplayBuilder
                    fields={stepContractAttributesTermsDesignation}
                    data={this.state.DataDesignation}
                    stateArray={"DataDesignation"}
                    _this={this}
                    selectContract={this.props._this.state.selectContract}
                    table={"terms-designation"}
                    addFormText={"Designation"}
                    stepTitle={"Set Up Employee Designation"}
                />,
            },
            {
                title: 'Pay Cycle',
                content: <ContractSetupStepDisplayBuilder
                    fields={stepContractAttributesTermsPayCycle}
                    data={this.state.DataPayCycle}
                    stateArray={"DataPayCycle"}
                    _this={this}
                    selectContract={this.props._this.state.selectContract}
                    table={"terms-paycycle"}
                    addFormText={"Pay cycle"}
                    stepTitle={"Set Pay Cycles"}
                />,
            },
            {
                title: 'Shift',
                content: <ContractSetupStepDisplayBuilder
                    fields={stepContractAttributesTermsShift}
                    data={this.state.DataShift}
                    stateArray={"DataShift"}
                    _this={this}
                    selectContract={this.props._this.state.selectContract}
                    table={"terms-shift"}
                    addFormText={"Shift"}
                    stepTitle={"Set Up Shifts"}
                />,
            }, {
                title: 'Site',
                content: <ContractSetupStepDisplayBuilder
                    fields={stepContractAttributesTermsSite}
                    data={this.state.DataSite}
                    stateArray={"DataSite"}
                    _this={this}
                    selectContract={this.props._this.state.selectContract}
                    table={"terms-site"}
                    addFormText={"Site"}
                    stepTitle={"Set Up Sites as Stipulated by the Contract"}
                />,
            }, {
                title: 'Employees',
                content: <ContractSetupStepDisplayBuilder
                    fields={siteEmployeeAttributes}
                    data={this.state.DataSiteDesignation}
                    stateArray={"DataSiteDesignation"}
                    _this={this}
                    selectContract={this.props._this.state.selectContract}
                    table={"terms-site-designation"}
                    addFormText={"Employees site"}
                    stepTitle={"Set Up Employees by Designation"}
                />,
            }, {
                title: 'Rate',
                content: <ContractSetupStepDisplayBuilder
                    fields={rateAttributes}
                    data={this.state.DataRate}
                    stateArray={"DataRate"}
                    _this={this}
                    selectContract={this.props._this.state.selectContract}
                    table={"terms-rate"}
                    addFormText={"Rate"}
                    stepTitle={"Set Up Global Employee Wage Rates"}
                />,
            },{
                title: 'Special Rate',
                content: <ContractSetupStepDisplayBuilder
                    fields={rateAttributesSpecific}
                    data={this.state.DataRateSpecific}
                    stateArray={"DataRateSpecific"}
                    _this={this}
                    selectContract={this.props._this.state.selectContract}
                    table={"terms-rate-specific"}
                    addFormText={"Rate Specific"}
                    stepTitle={"Set Up Specific site Employee Wage Rates"}
                />,
            },
        ]
    }

    getSelectedCompanyName=()=>{
        let {selectedCompany,Companies}=this.props._this.state;
        let name = selectedCompany;
        for(let i in Companies){
            const row = Companies[i];
            if(row.CustomerNumber===selectedCompany){
                name=row.Name
            }
        }
        return name.toLocaleUpperCase();
    }

    render() {
        let {_this} = this.props;
        const {current} = this.state;
        return (
            <div style={{marginTop: -50}}>
                <div style={{minWidth: "100%", padding: 10, color: "red",marginTop:10,}}>
                    <Alert style={{margin: '16px 0', minHeight: 40, maxHeight: 40,itemAlign:"center"}}
                           type={"info"}
                           message={<div style={{minWidth: "100%"}}>
                               <span style={{float: "left"}}>{this.getSelectedCompanyName()}</span>
                               <span style={{float: "right"}}>{_this.state.selectContract.ref.toLocaleUpperCase()}</span>
                           </div>
                           }>

                    </Alert>
                </div>
                <Steps current={current}>
                    {this.steps().map(item => (
                        <Step key={item.title} title={item.title}/>
                    ))}
                </Steps>
                <div style={styles.myBarContent}>
                    <div style={styles.stepsAction}>
                        <Button type="danger" style={{margin: '0 8px'}} onClick={() => this.closeSetup()}>
                            Exit
                        </Button>
                        {current > 0 && (
                            <Button style={{margin: '0 8px'}} onClick={() => this.prev()}>
                                Previous
                            </Button>
                        )}
                        {current < this.steps().length - 1 && (
                            <Button type="primary" onClick={() => this.next()}>
                                Next
                            </Button>
                        )}
                        {current === this.steps().length - 1 && (
                            <Button type="primary" onClick={() => message.success('Processing complete!')}>
                                Done
                            </Button>
                        )}

                    </div>
                </div>


                <div style={styles.stepsContent}>{this.steps()[current].content}</div>


            </div>
        );
    }
}

const styles = {
    myBarContent: {
        margin: 10,
        justifyContent: "center",
        minWidth: "100%",
        maxWidth: "100%",
        flex: 1,
        display: "flex",
        marginTop: 20
    },
    gutterBox: {
        padding: "8px 0",
        margin: 10,
        /*backgroundColor: "#00a0e9",
        minWidth: "50%",
        maxWidth: "50%"*/
    },
    stepsContent: {
        marginTop: 16,
        border: "1px dashed #e9e9e9",
        borderRadius: 2,
        backgroundColor: "#fafafa",
        minHeight: 200,
        textAlign: "center",
        paddingTop: 80,
    },
    stepsAction: {
        minWidth: "25%",
        maxWidth: "25%",
    }
}

/*
export default (props) => {
    let {_this} = props;
    const closeSetup = () => {
        _this.setState({
            showSetup: false,
            selectContract: null,
        })
    }
    return (
        <div style={{marginTop: -50}}>
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Alert style={{margin: '16px 0'}} message={"Contract setup wizard: " + _this.state.selectContract.ref}
                       type={"info"}/>
            </div>
            <div style={{minWidth: "100%", padding: 10}}>
                <a
                    onClick={() => closeSetup()}
                    style={{cursor: "pointer", color: "blue"}}
                >Close</a>
            </div>
        </div>
    )
}*/
