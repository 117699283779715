import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import  {Alert, Form, Layout, Select, Modal, Input, Checkbox,Button} from "antd";
import {fetchDataEntity} from "../../../api/entity-data";
import {GetUserToken} from "../../../api/token";
import {SortData, UpCaseFirst} from "../../../api/fn";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {NewAttributeFormStep} from "../../../components/web/Database/NewAttributeFormStep";
import DateSelectorBar from "../../../components/web/Clocking/DateSelectorBar";
import {StepBulkClockingIndex} from "../../../components/web/Clocking/StepBulkClockingIndex";
import {SubmitPost} from "../../../components/web/Common/localFunctions";

const {TextArea} = Input;
const {Content} = Layout;
const {Option} = Select;


class QaClockingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Entities: [],
            Databases: [],
            Attributes: [],
            selectedCompany: "",
            selectedEntity: "",
            selectedRecord: {},
            Data: [],
            isLoading: false,
            component: "list",
            comment: "",
            qaState: "",
            showModalReject: false,
            bulkQaApprove:{},
            bulkQaReject:{},
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Entities", "", "", this, undefined, "common/entity/modules/list");
        await fetchDataEntity("Databases", "", "", this, undefined, "common/entity/databases/list");
        await fetchDataEntity("Attributes", "", "", this, undefined, "common/entity/attributes/list");
        await this.initialReload()
    }
    initialReload = async () => {
        await fetchDataEntity("Data", "", "", this, undefined, "common/entity/clock-request/list")
    }
    loadInitialData = async () => {
        await fetchDataEntity("Data", "", "", this, undefined, "common/entity/clock-request/list")
    }
    handleChangeCompany = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    submitQa = async () => {
        let _this = this;
        _this.setState({
            isLoading: false,
        });
        let user = GetUserToken();
        let record = this.state.selectedRecord;
        delete record.qaAction;
        delete record.chooseApprove;
        delete record.chooseReject;
        /*if(!window.confirm("Are you sure that u want to : "+this.state.qaState+" Employee Code: "+record.workerref+"?")){
            return
        }*/

        let endpoint = "/clock/qq/new"
        let hub = {
            Org: this.state.selectedCompany,
            Clock: record,
            Username: user.Username,
            State: this.state.qaState, //approved , reject
            Comment: this.state.comment,
        }
        await SubmitPost(hub, endpoint, "Thank you , clocks submitted", this, async (dIn) => {
            alert("Thank you, qa clock " + hub.State + " submitted!")
            _this.setState({
                isLoading: false,
                showModalReject: false,
                selectedRecord: null,
                qaState: ""
            });
            await _this.initialReload();
            window.location.reload();
            // alert("Thank you , attributes submitted")
        })

    }
    submitQaBulk=async ()=>{
        let _this = this;
        _this.setState({
            isLoading: false,
        });
        let user = GetUserToken();

        let hub=[];
        for(let i in this.state.bulkQaApprove){
            let record = this.state.bulkQaApprove[i].row;
            delete record.qaAction;
            delete record.chooseApprove;
            delete record.chooseReject;
            hub.push({
                Org: this.state.selectedCompany,
                Clock: record,
                Username: user.Username,
                State: "approved", //approved , reject
                Comment: "bulk approved",
            })
        }
        for(let i in this.state.bulkQaReject){
            let record = this.state.bulkQaReject[i].row;
            delete record.qaAction;
            delete record.chooseApprove;
            delete record.chooseReject;
            hub.push({
                Org: this.state.selectedCompany,
                Clock: record,
                Username: user.Username,
                State: "reject", //approved , reject
                Comment: "bulk reject",
            })
        }

        let postData ={
            Data:hub
        }

        console.log("Bulk Qa submit send > ",hub)
        let endpoint = "/clock/qq/new-bulk";
        await SubmitPost(postData, endpoint, "Thank you , clocks submitted", this, async (dIn) => {
            alert("Thank you, qa clock bulk submitted!")
            _this.setState({
                isLoading: false,
                showModalReject: false,
                selectedRecord: null,
                qaState: ""
            });
            await _this.initialReload();
            window.location.reload();
            // alert("Thank you , attributes submitted")
        })

    }
    requestApprove = (row) => {
        this.setState({
            showModalReject: true,
            selectedRecord: row,
            qaState: "approved"
        });
    }
    requestReject = (row) => {
        this.setState({
            showModalReject: true,
            selectedRecord: row,
            qaState: "reject"
        });
    }
    renderCompaniesSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={"Select Company"}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedCompany")}
                    value={this.state.selectedCompany}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderModuleSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = [];
        for (let i in this.state.Entities) {
            const row = this.state.Entities[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            ls.push(row);
        }
        return (
            <Form.Item
                label={"Select Project"}
                name={"selectedEntity"}
                rules={[{required: true, message: "Please select your project!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedEntity")}
                    value={this.state.selectedEntity}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.name}
                                value={record.name}
                            >
                                {record.name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    getValueChecked=(status,index)=>{
        let store;
        if(status==="approve"){
            store=this.state.bulkQaApprove;
        }else{
            store=this.state.bulkQaReject;
        }


        if(typeof store[index]==="undefined"){
            return false;
        }
        return store[index].checked;
        /*
        bulkQaApprove:{},
            bulkQaReject:{},
         */
    }
    onChoose=(e,index,row,status)=>{
        console.log("selected check: ",index,"> ",row)
        let tmp = this.state;
        let store={};
        if(status==="approve"){
            store=this.state.bulkQaApprove;
        }else{
            store=this.state.bulkQaReject;
        }

        store[index]={
            checked:e.target.checked,
            row:row
        }

        if(status==="approve"){
            tmp.bulkQaApprove = store
        }else{
            tmp.bulkQaReject = store
        }
        this.setState(tmp)

    }
    displayBulkButton=()=>{
        let hasValue=false;
        for(let i in this.state.bulkQaApprove){
            hasValue =true
        }
        for(let i in this.state.bulkQaReject){
            hasValue =true
        }
        return hasValue;
    }
    renderList = () => {

        let user = GetUserToken();
        let columns = [
            {id: "chooseApprove", label: "Approve"},
            {id: "chooseReject", label: "Reject"},
            {id: "clockdate", label: "Clock Date"},
            {id: "workerref", label: "Worker"},
            {id: "site", label: "Site"},
            {id: "hours", label: "Hours"},
            {id: "starttime", label: "Start Time"},
            {id: "endtime", label: "End Time"},
            {id: "qastate", label: "Qa Start"},
            {id: "qaAction", label: "Action"}
        ];
        if (this.state.component !== "list") {
            return
        }

        let ls = [];

        for (let i in this.state.Data) {

            const row = this.state.Data[i];
            console.log("UUUUUUU00000>>>> ", row, " > ", this.state.selectedCompany, " > ", this.state.selectedEntity)
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.projectref !== this.state.selectedEntity) {
                continue
            }

            let index=`${row.workerref}--${row.org}--${row.workermodule}--${row.paycycle}--${row.clockdate}--${row.starttime}`;

            row.chooseApprove=(
                <React.Fragment>
                    <Checkbox
                        checked={this.getValueChecked("approve",index)}
                        onChange={(e)=>this.onChoose(e,index,row,"approve")}
                        style={{color:"green"}}
                    />
                </React.Fragment>
            )
            row.chooseReject=(
                <React.Fragment>
                    <Checkbox
                        checked={this.getValueChecked("reject",index)}
                        onChange={(e)=>this.onChoose(e,index,row,"reject")}
                        style={{color:"red"}}
                    />
                </React.Fragment>
            )

            if(row.endtime==="00:00:00"){
                row.qaAction = (
                    <React.Fragment>
                        <h3
                            style={{cursor: "pointer", fontSize: 18, fontWeight: "bold"}}
                        >
                            <img
                                style={{width:50,height:50}}
                                src={"https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fimg.freepik.com%2Ffree-photo%2Fstop-road-sign_1194-6978.jpg%3Fsize%3D338%26ext%3Djpg&f=1&nofb=1"}
                                 />
                        </h3>
                    </React.Fragment>
                )
            }else{
                row.qaAction = (
                    <React.Fragment>
                        <a
                            style={{cursor: "pointer", fontSize: 18, fontWeight: "bold"}}
                            onClick={() => this.requestApprove(row)}
                        >Approve</a>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <a
                            onClick={() => this.requestReject(row)}
                            style={{cursor: "pointer", color: "red", fontSize: 18, fontWeight: "bold"}}
                        >Reject</a>
                    </React.Fragment>
                )
            }

            /*row.qaAction=(<React.Fragment>
                <Button
                    type={"dashed"}
                    danger={true}
                />&nbsp;&nbsp;|&nbsp;&nbsp;
                <Button
                    type={"dashed"}
                    primary={true}
                />
            </React.Fragment>)*/
            ls.push(row);
        }

        ls = SortData("position", ls)

        let data = {columns: columns, data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                {this.displayBulkButton()?<Button type={"primary"} onClick={this.submitQaBulk}>Submit Bulk</Button>:null}
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Clocking Records")}
                    onAddRecord={this.onAddRecord}
                    allowedAdd={false}
                />
            </GridItem>
        )

    }
    renderWarningBar = () => {
        return
        let boo = false
        if (this.state.selectedCompany === "" || this.state.selectedEntity === "" || this.state.component === "form") {
            boo = true
        }
        if (!boo) {
            return
        }
        return (
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Alert style={{margin: '16px 0'}} message="Please select your company above to view your records!"
                       type={"error"}/>
            </div>
        )
    }
    renderModalReject = () => {
        if (!this.state.showModalReject) {
            return
        }
        let _this = this;
        const cancelReject = () => {
            _this.setState({
                showModalReject: false,
                qaState: "",
                comment: "",
                selectedRecord: null,
            })
        }
        const renderP = (key, val) => {
            return (
                <p>{key} : {val}</p>
            )
        }
        const onChangeComment = (e) => {
            console.log("onChangeComment : ", e, " > ")
            _this.setState({
                comment: e.target.value
            })
        }
        let visible = this.state.showModalReject;
        let record = this.state.selectedRecord;
        return (
            <Modal
                title={"Qa " + this.state.qaState.toLocaleUpperCase() + " Clock"}
                visible={visible}
                onOk={this.submitQa}
                confirmLoading={this.state.isLoading}
                onCancel={cancelReject}
            >
                {renderP("Employee Code:", record.workerref)}
                {renderP("Site:", record.site)}
                {renderP("Clock Date:", record.clockdate)}
                {renderP("Hours:", record.hours)}
                {renderP("Start time:", record.starttime)}
                {renderP("End time:", record.endtime)}

                {this.state.qaState === "reject" ?
                    <React.Fragment>
                        <br/>
                        <p>Comment: </p>
                        <TextArea
                            rows={4}
                            onChange={(e) => onChangeComment(e)}
                        />
                    </React.Fragment> : null}
            </Modal>
        )
    }

    render() {
        let props = this.props;
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>QA Clocking</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        {this.renderCompaniesSelection()}
                        {this.renderModuleSelection()}
                    </div>
                    {this.renderWarningBar()}
                    {this.renderList()}
                    {this.renderModalReject()}
                </div>
            </Page>
        )
    }

}


export default Connector(QaClockingPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
