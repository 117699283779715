import Page from "../../../layout/web/Page";
import React from "react";
import Connector from "../../../redux/connector";
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import MainFeaturedPost from '../../../components/web/Home/Welcome/MainFeaturedPost';
import FeaturedPost from '../../../components/web/Home/Welcome/FeaturedPost';

import Footer from '../../../components/web/Home/Welcome/Footer';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import imageIcon1 from "../../../assets/home/payroll_group.png"
import imageIcon2 from "../../../assets/home/quote_group.png"
import imageIcon3 from "../../../assets/home/invoice_group.png"
import imageIcon4 from "../../../assets/home/contract_group.png"

const useStyles = makeStyles((theme) => ({
    mainGrid: {
        marginTop: theme.spacing(3),
    },
}));



const linkImage1="https://i0.wp.com/www.blueglobe27.com/wp-content/uploads/2019/03/PPA_HR_Payroll_Modules_Mar19Featured-Image.jpg?fit=1200%2C628";

//'https://source.unsplash.com/random',
const mainFeaturedPost = {
    title: 'CMIS Unlocks the Value Creating Potential of Your EPWP Contract',
    description:
        "CMIS provides interactive tools for business owners and\n" +
        "managers to gain insight for decision-making which is\n" +
        "critical for optimizing contract performance and deriving\n" +
        "the maximum value from your EPWP contract.",
    image: linkImage1,
    imgText: 'main image description',
    linkText: '',
};

const featuredPosts = [
    {
        title: 'Payroll Management',
        date: 'Database',
        descriptionStart:"With our",
        descriptionBold:"PAYROLL MANAGEMENT",
        description:
            'module we enable you to productively ' +
            'and accurately manage your project ' +
            'payroll no matter what the size of the ' +
            'staff compliment.',
        image: imageIcon1,
        imageText: 'Image Text',
        bgColor:"#0E3961"
    },
    {
        title: 'Quotation Management',
        date: 'Quotation',
        descriptionStart:"Prepare detailed",
        descriptionBold:"PRICE QUOTES",
        description:
            'by taking the guesswork out of\n' +
            'planning. It takes just a few clicks\n' +
            'to generate detailed and precise\n' +
            'assessments of the cost associated\n' +
            'with a given project. ',
        image: imageIcon2,
        imageText: 'Image Text',
    },
    {
        title: 'Invoice Management',
        date: 'Invoice',
        descriptionStart:"Our professional",
        descriptionBold:"INVOICE MINDER",
        description:
            'software allows you to transform your\n' +
            'time-sheets and delivery notes into\n' +
            'ready-to-pay invoices, saving time in\n' +
            'billing and receiving your payments\n' +
            'sooner.',
        image: imageIcon3,
        imageText: 'Image Text',
    },
    {
        title: 'Contract Management',
        date: 'Intelligence',
        descriptionStart:"Embed a competitive edge in your business with our",
        descriptionBold:"CONTRACT MINDER ",
        description:
            'app. Here we turn complex\n' +
            'contract data into powerful and\n' +
            'visually easy-to-understand\n' +
            'dashboards',
        image: imageIcon4,
        imageText: 'Image Text',
    },
];


class HomeOverviewIndexPage extends React.Component{

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                    maxWidth:"100%",
                    minWidth:"100%"
                }}>
                    <React.Fragment>
                        <CssBaseline />
                        <Container maxWidth="lg">
                            <main>
                                <MainFeaturedPost post={mainFeaturedPost} />
                                <Grid container spacing={4}>
                                    {featuredPosts.map((post) => (
                                        <FeaturedPost key={post.title} post={post} />
                                    ))}
                                </Grid>
                            </main>
                            <Footer title="PMIS" description="payroll management information system" />
                        </Container>

                    </React.Fragment>

                </div>
            </Page>
        )
    }
}

HomeOverviewIndexPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = {
    container:{

    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

export default withStyles(styles)(Connector(HomeOverviewIndexPage));



