import React from "react";
import {Alert, Col, Row} from "antd";
import moment from "moment";

export default (props) => {
    const {dataRecordWeeks, dataRecordSummary,payDate} = props;

    const toFix=(valIn)=>{
        return valIn.toFixed(2);
    }

    const buildInnerBlock1Center = (val, span, backgroundColor, index) => {
        return (
            <Col span={span} style={{
                ...styles.buildInnerBlock,
                backgroundColor: backgroundColor,
                marginTop: 15,
                paddingTop: 20,
            }}>
                <Row style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginLeft: 5,
                    marginRight: 5,
                }}>
                    <Col style={{flex:1,flexDirection:"row",justifyContent:"center",display:"flex",color:"red",fontWeight:"bold"}}>
                        {val}
                    </Col>
                </Row>
            </Col>
        )
    }

    const buildInnerBlock1 = (val, span, backgroundColor, index) => {
        return (
            <Col span={span} style={{
                ...styles.buildInnerBlock,
                backgroundColor: backgroundColor,
                marginTop: 15,
                paddingTop: 20,
            }}>
                <Row style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginLeft: 5,
                    marginRight: 5,
                }}>
                    <Col style={{flex:1,flexDirection:"row",justifyContent:"flex-end",display:"flex",color:"red",fontWeight:"bold"}}>
                        {val}
                    </Col>
                </Row>
            </Col>
        )
    }
    const buildInnerBlock2 = (val1, val2, span, backgroundColor, index) => {
        return (
            <Col span={span} style={{
                ...styles.buildInnerBlock,
                backgroundColor: backgroundColor,
                paddingTop: 20,
            }}
            >
                <Row style={styles.innerSection1Box2}>
                    <Col>{val1}</Col>
                    <Col>R{val2}</Col>
                </Row>
            </Col>
        )
    }
    const buildInnerBlock3 = (val1, val2, val3, span, backgroundColor, index) => {
        return (
            <Col span={span} style={{
                ...styles.buildInnerBlock,
                backgroundColor: backgroundColor,
                paddingTop: 20,
            }}
            >
                <Row style={styles.innerSection1Box2}>
                    <Col>{val1}</Col>
                    <Col>{val2}</Col>
                    <Col>R{val3}</Col>
                </Row>
            </Col>
        )
    }
    const buildTotalLineCalender = (val, span, mLeft) => {
        if (typeof span === "undefined") {
            span = 2
        }
        if (typeof mLeft === "undefined") {
            mLeft = 0
        }
        return (
            <Col span={span} style={{
                borderWidth: 1,
                borderColor: "gray",
                borderStyle: "solid",
                fontSize: 14,
                minHeight: 50,
                maxHeight: 50,
                textAlign: "center",
                marginLef: mLeft,
                marginTop: 15,
                paddingTop: 20,
            }}>
                <p style={styles.bodyDays}>{val}</p>
            </Col>
        )
    }

    const renderBigHeader = () => {
        return (
            <>
                <hr/>
                <Row>
                    <Col span={10} style={styles.section0Box1}>
                        EPWP Wage Calculation Methodology
                    </Col>
                    <Col span={1} style={styles.section0Box2}>
                        Mon-Sat
                    </Col>
                    <Col span={2} style={styles.section0Box2}>
                        NORMAL TIME
                    </Col>
                    <Col span={2} style={styles.section0Box2}>
                        OVERTIME
                    </Col>
                    <Col span={1} style={styles.section0Box2}>
                        SATURDAY
                    </Col>
                    <Col span={2} style={styles.section0Box2}>
                        SUNDAY
                    </Col>
                    <Col span={3} style={styles.section0Box2}>
                        PAID PUBLIC HOLIDAY
                    </Col>
                    <Col span={1} style={styles.section0Box2}>
                        WAGES
                    </Col>
                </Row>

                <Row>
                    <Col span={10} style={styles.section1Box1}>
                    <span>
                        {/*Sep-21*/}
                        {moment(payDate,"YYYY-MM-DD").format("MMM-YYYY")}
                    </span>
                    </Col>
                    <Col span={1} style={{...styles.section1Box2, backgroundColor: "white"}}>
                        <Row style={styles.innerSection1Box2}>
                            <Col>Hours</Col>
                        </Row>
                    </Col>
                    {/*normal time*/}
                    <Col span={2} style={{
                        ...styles.section1Box2,
                        backgroundColor: "#ACB9CA",
                        color: "black",
                        fontWeight: "300"
                    }}>
                        <Row style={styles.innerSection1Box2}>
                            <Col>Hours</Col>
                            <Col>Wages</Col>
                        </Row>
                    </Col>
                    {/*overtime*/}
                    <Col span={2} style={{...styles.section1Box2, backgroundColor: "#D9E1F2", color: "black"}}>
                        <Row style={styles.innerSection1Box2}>
                            <Col>Hours</Col>
                            <Col>Wages</Col>
                        </Row>
                    </Col>
                    {/*saturday*/}
                    <Col span={1} style={{...styles.section1Box2, backgroundColor: "#ACB9CA", color: "black"}}>
                        <Row style={styles.innerSection1Box2}>
                            <Col>H.</Col>
                            <Col>Wages</Col>
                        </Row>
                    </Col>
                    {/*sunday*/}
                    <Col span={2} style={{...styles.section1Box2, backgroundColor: "#ACB9CA", color: "black"}}>
                        <Row style={styles.innerSection1Box2}>
                            <Col>Hours</Col>
                            <Col>Wages</Col>
                        </Row>
                    </Col>
                    {/*public holiday*/}
                    <Col span={3} style={{...styles.section1Box2, backgroundColor: "#D9E1F2", color: "black"}}>
                        <Row style={styles.innerSection1Box2}>
                            <Col>Worked</Col>
                            <Col>Statute</Col>
                            <Col>Wages</Col>
                        </Row>
                    </Col>
                    <Col span={1} style={styles.section1Box2}>
                        <Row style={styles.innerSection1Box2}>
                            <Col style={{flex:1,flexDirection:"row",justifyContent:"flex-end",display:"flex",color:'red',fontWeight:"bold"}}>
                                Wages
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row style={{backgroundColor: "white", maxHeight: 25}}>
                    <Col span={10} style={{backgroundColor: "white", maxHeight: 24, minHeight: 24, margin: 5,}}>
                        <Row style={{minWidth: "100%", maxWidth: "100%"}}>
                            <Col span={2} style={styles.underlineBottom}>Mon</Col>
                            <Col span={2} style={styles.underlineBottom}>Tue</Col>
                            <Col span={2} style={styles.underlineBottom}>Wed</Col>
                            <Col span={2} style={styles.underlineBottom}>Thu</Col>
                            <Col span={2} style={styles.underlineBottom}>Fri</Col>
                            <Col span={2} style={styles.underlineBottom}>Sat</Col>
                            <Col span={2} style={styles.underlineBottom}>Sun</Col>
                            <Col span={3} style={{...styles.underlineBottom, marginRight: 10}}>&nbsp;</Col>
                            <Col span={3} style={styles.underlineBottom2}>Hours</Col>
                            <Col span={3} style={styles.underlineBottom2}>Days</Col>
                        </Row>
                    </Col>
                    <Col span={1}
                         style={{...styles.section2Box2, backgroundColor: "white", maxHeight: 25, minHeight: 25}}>
                        &nbsp;
                    </Col>
                    {/*normal time*/}
                    <Col span={2} style={{
                        ...styles.section2Box2,
                        backgroundColor: "white",
                        color: "black",
                        fontWeight: "300",
                        maxHeight: 25,
                        minHeight: 25,
                        marginTop: 10
                    }}>

                    </Col>
                    {/*overtime*/}
                    <Col span={2} style={{
                        ...styles.section2Box2,
                        backgroundColor: "white",
                        color: "white",
                        maxHeight: 25,
                        minHeight: 25
                    }}>
                        &nbsp;
                    </Col>
                    {/*sunday*/}
                    <Col span={1} style={{
                        ...styles.section2Box2,
                        backgroundColor: "white",
                        color: "white",
                        maxHeight: 25,
                        minHeight: 25
                    }}>
                        &nbsp;
                    </Col>
                    {/*sunday*/}
                    <Col span={3}
                         style={{
                             ...styles.section2Box2,
                             backgroundColor: "white",
                             color: "white",
                             maxHeight: 25,
                             minHeight: 25
                         }}>
                        &nbsp;
                    </Col>
                    <Col span={2}
                         style={{...styles.section2Box2, backgroundColor: "white", maxHeight: 25, minHeight: 25}}>
                        &nbsp;
                    </Col>
                </Row>
            </>
        )
    }
    const renderBody = () => {
        let ls = [];
        let totMon = 0, totTue = 0, totWed = 0, totThu = 0, totFri = 0, totSat = 0, totSun = 0, totHours = 0,
            totDays = 0, totCost = 0, totCostMoSat = 0,totCostPh=0;
        /*let record = {
            Mon: {date: "30-Aug", days: 0},
            Tue: {date: "31-Aug", days: 0},
            Wed: {date: "01-Sep", days: 8},
            Thu: {date: "02-Sep", days: 8},
            Fri: {date: "03-Sep", days: 8},
            Sat: {date: "04-Sep", days: 8},
            Sun: {date: "05-Sep", days: 8},
            /!* other*!/
            Week: {name: "week 1", hours: 25, days: 14, cost: 258},
            HoursMonSat: 32,
            NormalTime: {hours: 32, cost: 763.84},
            OverTime: {hours: 0, cost: 0},
            SundayTime: {hours: 8, cost: 381},
            PphTime: {hours: 4, bonus: 8, cost: 286.44},
            wages: 286.44
        }*/
        const toShortDate = (dataIn) => {
            let date = moment(dataIn, "YYYY-MM-DD").format("DD-MMM");
            if(date==="Invalid date"){
                date = "---"
            }
            return date
        }
        let genTotal=0;
        for (let i in dataRecordWeeks) {
            let row = dataRecordWeeks[i];
            let totGross = row.TotHourRateNor  + row.TotHourRateSat + +row.TotHourRateSun + row.TotHourRateOT + row.TotHourRatePh;
            let hours = row.TotHourNor + row.TotHourOT + row.TotHourPh;
            let record = {
                Mon: {date: toShortDate(row.Mon.Date), days: row.Mon.WorkHour,isPh:row.Mon.Ph},
                Tue: {date: toShortDate(row.Tue.Date), days: row.Tue.WorkHour,isPh:row.Tue.Ph},
                Wed: {date: toShortDate(row.Wed.Date), days: row.Wed.WorkHour,isPh:row.Wed.Ph},
                Thu: {date: toShortDate(row.Thu.Date), days: row.Thu.WorkHour,isPh:row.Thu.Ph},
                Fri: {date: toShortDate(row.Fri.Date), days: row.Fri.WorkHour,isPh:row.Fri.Ph},
                Sat: {date: toShortDate(row.Sat.Date), days: row.Sat.WorkHour,isPh:row.Sat.Ph},
                Sun: {date: toShortDate(row.Sun.Date), days: row.Sun.WorkHour,isPh:row.Sun.Ph},
                /* other*/
                Week: {name: `week ${row.Week}`,
                    hours: hours,
                    days: row.TotDays, cost: toFix(row.TotHourRates)},
                HoursMonSat: row.TotHourNor + row.TotHourSat,
                NormalTime: {hours: row.TotHourNor , cost: toFix(row.TotHourRateNor) },
                OverTime: {hours: row.TotHourOT, cost: row.TotHourRateOT<0?0:toFix(row.TotHourRateOT)},
                SundayTime: {hours: row.TotHourSun, cost: toFix(row.TotHourRateSun)},
                SaturdayTime: {hours: row.TotHourSat, cost: toFix(row.TotHourRateSat)},
                PphTime: {hours: row.TotHourPh, bonus: row.TotHourBonus, cost: toFix(row.TotHourRatePh)},
                wages: totGross
            }
            totMon += row.Mon.WorkHour;
            totTue += row.Tue.WorkHour;
            totWed += row.Wed.WorkHour;
            totThu += row.Thu.WorkHour;
            totFri += row.Fri.WorkHour;
            totSat += row.Sat.WorkHour;
            totSun += row.Sun.WorkHour;

            totHours += hours;
            totDays += row.TotDays;
            totCost += totGross;
            totCostMoSat += row.TotHourNor + row.TotHourSat;
            totCostPh +=row.TotHourRatePh

            ls.push(record);
        }

        let totalLine = {
            Mon: {days: totMon},
            Tue: {days: totTue},
            Wed: {days: totWed},
            Thu: {days: totThu},
            Fri: {days: totFri},
            Sat: {days: totSat},
            Sun: {days: totSun},
            /* other*/
            Week: {name: "", hours: totHours, days: totDays, cost: toFix(totCost)},
            HoursMonSat: totCostMoSat.toFixed(0),
            NormalTime: {hours: dataRecordSummary.TotNor.Hours, cost: toFix(dataRecordSummary.TotNor.Cost)},
            OverTime: {hours: dataRecordSummary.TotOT.Hours, cost: toFix(dataRecordSummary.TotOT.Cost)},
            SaturdayTime: {hours: dataRecordSummary.TotSat.Hours, cost: toFix(dataRecordSummary.TotSat.Cost)},
            SundayTime: {hours: dataRecordSummary.TotSun.Hours, cost: toFix(dataRecordSummary.TotSun.Cost)},
            PphTime: {hours: dataRecordSummary.TotPh.Hours, bonus: 8, cost: toFix(dataRecordSummary.TotPh.Cost)},
            wages: toFix(totCost),
        }

        return (
            <>
                {
                    ls.map((row, index) => {
                        const getStyle=(isPph)=>{
                            if(!isPph){
                                return styles.bodyDate
                            }
                            return {
                                ...styles.bodyDate,
                                backgroundColor:"yellow",
                            }
                        }
                        return (
                            <Row style={{maxHeight: 50}}>
                                <Col span={10}
                                     style={{backgroundColor: "white", maxHeight: 50, minHeight: 50, margin: 5,}}>
                                    <Row>
                                        <Col span={2} style={styles.underlineBottom2}>
                                            <p style={getStyle(row.Mon.isPh)}>{row.Mon.date}</p>
                                            <p style={styles.bodyDays}>{row.Mon.days}</p>
                                        </Col>
                                        <Col span={2} style={styles.underlineBottom2}>
                                            <p style={getStyle(row.Tue.isPh)}>{row.Tue.date}</p>
                                            <p style={styles.bodyDays}>{row.Tue.days}</p>
                                        </Col>
                                        <Col span={2} style={styles.underlineBottom2}>
                                            <p style={getStyle(row.Wed.isPh)}>{row.Wed.date}</p>
                                            <p style={styles.bodyDays}>{row.Wed.days}</p>
                                        </Col>
                                        <Col span={2} style={styles.underlineBottom2}>
                                            <p style={getStyle(row.Thu.isPh)}>{row.Thu.date}</p>
                                            <p style={styles.bodyDays}>{row.Thu.days}</p>
                                        </Col>
                                        <Col span={2} style={styles.underlineBottom2}>
                                            <p style={getStyle(row.Fri.isPh)}>{row.Fri.date}</p>
                                            <p style={styles.bodyDays}>{row.Fri.days}</p>
                                        </Col>
                                        <Col span={2} style={styles.underlineBottom2}>
                                            <p style={{...styles.bodyDate, color: "red"}}>{row.Sat.date}</p>
                                            <p style={styles.bodyDays}>{row.Sat.days}</p>
                                        </Col>
                                        <Col span={2} style={styles.underlineBottom2}>
                                            <p style={{...styles.bodyDate, color: "red"}}>{row.Sun.date}</p>
                                            <p style={styles.bodyDays}>{row.Sun.days}</p>
                                        </Col>
                                        <Col span={3} style={{...styles.underlineBottom2, marginRight: 10}}>
                                            <p style={{fontWeight: "bold"}}>{row.Week.name}</p>
                                        </Col>
                                        <Col span={3} style={styles.underlineBottom2}>
                                            <p style={styles.bodyDays}>{row.Week.hours}</p>
                                        </Col>
                                        <Col span={3} style={styles.underlineBottom2}>
                                            <p style={styles.bodyDays}>{row.Week.days}</p>
                                        </Col>
                                    </Row>
                                </Col>
                                {buildInnerBlock1Center(row.HoursMonSat.toFixed(0), 1, "white", index)}
                                {buildInnerBlock2(row.NormalTime.hours, row.NormalTime.cost, 2, "#ACB9CA", index)}
                                {buildInnerBlock2(row.OverTime.hours, row.OverTime.cost, 2, "#D9E1F2", index)}
                                {buildInnerBlock2(row.SaturdayTime.hours, row.SaturdayTime.cost, 1, "#ACB9CA", index)}
                                {buildInnerBlock2(row.SundayTime.hours, row.SundayTime.cost, 2, "#ACB9CA", index)}
                                {buildInnerBlock3(row.PphTime.hours, row.PphTime.bonus, row.PphTime.cost, 3, "#D9E1F2", index)}
                                {buildInnerBlock1("R" + toFix(row.wages), 1, "white", index)}
                            </Row>
                        )
                    })
                }
                <Row>
                    <Col span={24} style={{backgroundColor: "white", maxHeight: 25, minHeight: 25, margin: 5,}}>
                        <hr/>
                    </Col>
                </Row>

                <Row style={{maxHeight: 25}}>
                    <Col span={10}
                         style={{backgroundColor: "white", maxHeight: 25, minHeight: 25, margin: 5,}}>
                        <Row>
                            {buildTotalLineCalender(totalLine.Mon.days)}
                            {buildTotalLineCalender(totalLine.Tue.days)}
                            {buildTotalLineCalender(totalLine.Wed.days)}
                            {buildTotalLineCalender(totalLine.Thu.days)}
                            {buildTotalLineCalender(totalLine.Fri.days)}
                            {buildTotalLineCalender(totalLine.Sat.days)}
                            {buildTotalLineCalender(totalLine.Sun.days)}
                            <Col span={3}>
                                <p style={{fontWeight: "bold"}}>&nbsp;</p>
                            </Col>
                            {buildTotalLineCalender(totalLine.Week.hours, 3, 5)}
                            {buildTotalLineCalender(totalLine.Week.days, 3, 5)}
                        </Row>
                    </Col>
                    {buildInnerBlock1Center(totalLine.HoursMonSat, 1, "white", 1)}
                    {buildInnerBlock2(totalLine.NormalTime.hours, totalLine.NormalTime.cost, 2, "#ACB9CA", 1)}
                    {buildInnerBlock2(totalLine.OverTime.hours, totalLine.OverTime.cost, 2, "#D9E1F2", 1)}
                    {buildInnerBlock2(totalLine.SaturdayTime.hours, totalLine.SaturdayTime.cost, 1, "#ACB9CA", 1)}
                    {buildInnerBlock2(totalLine.SundayTime.hours, totalLine.SundayTime.cost, 2, "#ACB9CA", 1)}
                    {buildInnerBlock3(totalLine.PphTime.hours, totalLine.PphTime.bonus, totCostPh, 3, "#D9E1F2", 1)}
                    {buildInnerBlock1("R" + totalLine.wages, 1, "white", 1)}

                </Row>

            </>
        )

    }
    const renderEmpInfo = () => {
        const buildInfoLine = (key, val) => {
            return (
                <>
                    <span style={styles.infoKey}>{key}</span>
                    <span style={styles.infoVal}>{val}</span>
                </>

            )
        }
        console.log("ZZZZZZ-renderEmpInfo > ",dataRecordSummary)
        return (
            <p>
                {buildInfoLine("Employee No:",dataRecordSummary.EmpNumber )}
                {buildInfoLine("","|" )}
                {buildInfoLine("Full Name:",dataRecordSummary.EmpName )}
                {buildInfoLine("","|" )}
                {buildInfoLine("Site:",dataRecordSummary.EmpSite )}
                {buildInfoLine("","|" )}
                {buildInfoLine("Designation:",dataRecordSummary.EmpDesignation )}
                {buildInfoLine("","|" )}
                {buildInfoLine("Rate:",dataRecordSummary.RateNor )}
            </p>
        )
    }
    return (
        <div>
            {renderEmpInfo()}
            {renderBigHeader()}
            {renderBody()}
        </div>
    )
}

const styles = {
    infoKey:{
        fontWeight:"bold",
        color:"gray"
    },
    infoVal:{
        color:"#3880ff"
    },
    buildInnerBlock: {
        fontSize: 11,
        borderWidth: 1,
        borderColor: "lightgray",
        borderStyle: "solid",
        minHeight: 50,
        maxHeight: 50,
        margin: 5,
        marginTop: 15,
        flexDirection: "row",
        justifyContent: "space-between",
    },
    bodyDate: {
        fontSize: 8,
    },
    bodyDays: {
        fontSize: 12,
        fontWeight: "bold"
    },
    underlineBottom1: {
        borderBottomWidth: 1,
        borderBottomColor: "black",
        borderBottomStyle: "solid",
    },
    underlineBottom: {
        borderBottomColor: "gray",
        borderBottomWidth: 2,
        borderBottomStyle: "solid",
        textAlign: "center",
        marginBottom: 5,
        minHeight: 24,
        maxHeight: 24,
    },
    underlineBottom2: {
        borderWidth: 1,
        borderColor: "lightgray",
        borderStyle: "solid",
        fontSize: 10,
        minHeight: 50,
        maxHeight: 50,
        textAlign: "center",
        marginTop: 10,
    },
    innerSection1Box2: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginLeft: 5,
        marginRight: 5,
    },
    section1Box2: {
        fontSize: 11,
        borderWidth: 1,
        borderColor: "black",
        borderStyle: "solid",
        minHeight: 30,
        margin: 5,
        flexDirection: "row",
        justifyContent: "space-between"
    },
    section2Box2: {
        fontSize: 11,
        borderWidth: 0,
        borderColor: "black",
        borderStyle: "solid",
        margin: 5,
        flexDirection: "row",
        justifyContent: "space-between",

    },
    section0Box2: {
        margin: 5,
        color: "black",
        fontSize: 12,
        fontWeight: "bold",
        textAlign: "center"
    },
    section0Box1: {
        borderBottomWidth: 1,
        margin: 5,
        color: "black",
        fontSize: 12,
        fontWeight: "bold",
        textAlign: "center"
    },
    section1Box1: {
        borderTopWidth: 1,
        borderTopColor: "black",
        borderTopStyle: "solid",
        minHeight: 30,
        margin: 5,
        paddingLeft: 5,
        paddingTop: 5,
        fontWeight: "bold"
    },
    underlineCol: {
        borderBottomWidth: 1,
        borderColor: "black",
        borderStyle: "solid"
    },
    container: {
        minWidth: "100%",
        backgroundColor: "gray"
    }
}