import React from "react";
import moment from "moment/moment";
import {fetchDataEntity} from "../../../../api/entity-data";
import {ArrayToSelectOption, FilterArrayByKey} from "../../../../api/fn";
import ControllerSelectBox from "../../../../components/Common/ControllerInput/ControllerSelectBox";
import Title from "../../../../components/Common/Title";
import {Button, Col, DatePicker, Input, Row} from "antd";
import Connector from "../../../../redux/connector";
import Page from "../../../../layout/web/Page";
import {PostToPbx} from "../../../../api/http-post";

const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');

class LabourLoggingSyncPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Projects: [],
            Data: [],
            selectedCompany: "",
            selectedProject: "",
            startDate: startOfMonth,
            endDate: moment().format("YYYY-MM-DD"),

        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Projects", "", "", this, undefined, "common/entity/modules/list");

    }
    handleChangeInput = async (key, value) => {
        this.setState({
            [key]: value,
        })
    }
    handleGetInputValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return ""
        }
        return this.state[key];
    }
    submitSync=async ()=>{
        let postData = {
            Org: this.state.selectedCompany,
            Project: this.state.selectedProject,
            StartDate: this.state.startDate,
            EndDate: this.state.endDate,
        }
        console.log("submitSync send > ", postData)
        let endpoint = "/labour/logs/sync"
        await PostToPbx(postData, endpoint, async (output) => {
            if(output===null){
                console.log("<>OUtput is empty")
                return
            }
            let data = output.RESULT;
            console.log("submitSync response > ", data)
            alert("Thank you file sync in progress please check after some minutes")
        });
    }
    renderCompanyBox = () => {
        let ls = [];
        ls = FilterArrayByKey(this.state.Companies, {})
        ls = ArrayToSelectOption(ls, ["CustomerNumber"], ["Name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Company"}
                name={"selectedCompany"}
            />
        )
    }
    renderProjectBox = () => {
        let ls = [];
        for (let i in this.state.Projects) {
            let row = this.state.Projects[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            ls.push(row)
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["name"], ["name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Project"}
                name={"selectedProject"}
            />
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Labour Import Csv Logs</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <Row gutter={18}>
                            <Col span={12}>
                                {this.renderCompanyBox()}
                            </Col>
                            <Col span={12}>
                                {this.renderProjectBox()}
                            </Col>
                        </Row>
                        <Row gutter={18} style={{marginTop: -30,paddingLeft:10}}>
                            <Col span={12} style={{marginTop: 30, height: 40}}>
                                <DatePicker
                                    style={styles.datePickerBox}
                                    onChange={(date, dateString) => this.setState({startDate: dateString})}
                                    placeholder={"Start Date"}
                                />
                            </Col>
                            <Col span={12} style={{marginTop: 30, height: 40,paddingLeft:10}}>
                                <DatePicker
                                    style={styles.datePickerBox}
                                    onChange={(date, dateString) => this.setState({endDate: dateString})}
                                    placeholder={"End Date"}
                                />
                            </Col>
                        </Row>
                        <Row gutter={18} style={{marginTop: 10,paddingLeft:10}}>
                            <Col span={24} style={{marginTop: -5, height: 40}}>
                                <Button
                                    style={styles.datePickerBox}
                                    onClick={() => this.submitSync()}
                                >
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Page>
        )

    }
}


export default Connector(LabourLoggingSyncPage);

const styles = {
    boxFilterInner: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 5,
        borderRight: "1px solid black",
        fontsize: 14,
        fontWeight: "bold"
    },
    boxFilter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    dateRow: {
        display: "flex",
        flexDirection: "row"
    },
    datePickerBox: {
        height: 40,
        width: "100%"
    },
    row1Th: {
        border: "1px solid black",
    },
    rowForm: {
        marginBottom: 10,
        paddingBottom: 5,
        border: "0px solid lightgray",
        borderBottomWidth: 1,

    },
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}