import React from "react";
import {Button, Drawer, Table} from 'antd';
import {Tabs, Tab} from 'material-ui/Tabs';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {CSVLink, CSVDownload} from 'react-csv';
import ExcelBook from "./ExcelBook";
import {formatMoney} from "../../../api/fn";


export default (props) => {
    let {showView, data, setShowView,companyName} = props;

    companyName = companyName.replace("(Pty) Ltd","")

    const onClose = () => {
        setShowView(false)
    }

    const counterFunction = (ls) => {
        let tot = 0;
        for (let i in ls) {
            const row = ls[i];
            tot += row.Hours;
        }
        return tot;
    }
    const cleanInputLine=(strIn )=>{
        strIn = strIn.replace(`\r`,"");
        strIn = strIn.replace(`\n`,"");
        return strIn
    }
    const generateCsv=()=>{

        let ls=[
        ];
        for(let i in data){
            let row=data[i];
            console.log("PayAccsys > ",row)
            if(row.PaymentMethod!=="PayAccsys"){continue}
            let bankAccount = cleanInputLine(row.BankAccount);
            let branchCode = cleanInputLine(row.BranchCode);
             let empNumber = cleanInputLine(row.EmpNumber)
            let empName = cleanInputLine(row.EmpName)
            ls.push([
                empNumber,
                empName,
                row.NetPay.toFixed(2),
                bankAccount,
                branchCode,
                companyName,
            ])
        }




        let csvContent = "data:text/csv;charset=utf-8,";
        ls.forEach(function(info){
            let row = info.join(",");
            csvContent += row + "\r\n";
        });
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "pmis_payment_payaccsys.csv");
        link.innerHTML= "Click Here to download";
        document.body.appendChild(link); // Required for FF
        link.click(); // This will download the data file named "my_data.csv".
    }
    const getCsvDataProCard=()=>{
        let ls=[];
        for(let i in data){
            let row=data[i];
            if(row.PaymentMethod!=="FlexPay"){continue}
            let arrName = row.EmpName.split(" ");
            console.log("getCsvDataProCard > ",row.EmpIdNumber,row)
            if(row.NetPay===0){
                continue
            }
            ls.push({
                EmployeeCode: row.EmpNumber,
                Name: arrName[0],
                Surname: arrName[1],
                NetPay: row.NetPay.toFixed(2),
                Account: row.BankAccount,
                BranchCode:  row.BranchCode,
                Site: row.EmpSite,
                Designation: row.EmpDesignation,
                Bank: row.BankName,
                EmployeeIDNo: row.EmpIdNumber,
                Company: companyName,
            });
        }
        return ls
    }

    const getCsvDataSysAcc=()=>{
        let ls=[];
        for(let i in data){
            let row=data[i];
            console.log("PayAccsys > ",row)
            if(row.PaymentMethod!=="PayAccsys"){continue}
            ls.push([
                row.EmpNumber,
                row.EmpName,
                row.NetPay.toFixed(2),
                row.BankAccount,
                row.BranchCode,
                companyName,
            ])
        }
        return ls
    }
    const renderInnerProcard=()=>{
        let columns=[
            {title: "Code", dataIndex: "Code", align: 'left'},
            {title: "Name", dataIndex: "Name", align: 'left'},
            {title: "Net Pay", dataIndex: "NetPay", align: 'center'},
            {title: "Bank", dataIndex: "Bank", align: 'right'},
            {title: "Account", dataIndex: "Account", align: 'center'},
            {title: "BranchCode", dataIndex: "BranchCode", align: 'left'},
            {title: "Site", dataIndex: "Site", align: 'left'},
            {title: "Designation", dataIndex: "Designation", align: 'left'},
        ];
        let ls=[];
        for(let i in data){
            let row=data[i];
            if(row.PaymentMethod!=="FlexPay"){continue}
            if(row.NetPay===0){continue}
            ls.push({
                Code: row.EmpNumber,
                Name: row.EmpName,
                NetPay:row.NetPay.toFixed(2),
                Bank:row.BankName,
                Account:row.BankAccount,
                BranchCode:row.BranchCode,
                Site:row.EmpSite,
                Designation:row.EmpDesignation,
            })
        }

        return(
            <Table
                columns={columns}
                dataSource={ls}
                bordered
            />
        )
    }
    const renderInnerPayAccSys=()=>{
        let columns=[
            {title: "Code", dataIndex: "Code", align: 'left'},
            {title: "Name", dataIndex: "Name", align: 'left'},
            {title: "Net Pay", dataIndex: "NetPay", align: 'center'},
            {title: "Bank", dataIndex: "Bank", align: 'right'},
            {title: "Account", dataIndex: "Account", align: 'center'},
            {title: "BranchCode", dataIndex: "BranchCode", align: 'left'},
            {title: "Company", dataIndex: "Company", align: 'left'},
        ];
        let ls=[];
        for(let i in data){
            let row=data[i];
            if(row.PaymentMethod!=="PayAccsys"){continue}
            ls.push({
                Code: row.EmpNumber,
                Name: row.EmpName,
                NetPay:row.NetPay.toFixed(2),
                Bank:row.BankName,
                Account:row.BankAccount,
                BranchCode:row.BranchCode,
                Company:companyName,
            })
        }

        return(
            <Table
                columns={columns}
                dataSource={ls}
                bordered
            />
        )
    }

    const renderProCard=()=>{
        let totalCost=0;
        let peoples=0;
        for(let i in data){
            let row=data[i];
            if(row.PaymentMethod!=="FlexPay"){continue}
            totalCost +=parseFloat(row.NetPay);
            peoples++;
        }

        let lastTotal = totalCost.toFixed(2);
        console.log("renderProCard > ",totalCost," > ",lastTotal)

        return (
            <>
                <h3 className="pastelHeading">
                    <span className="pastelTitleDetail"><small>(People=<b>{peoples}</b>)</small></span>
                    &nbsp;
                    <span className="pastelTitleDetail"><small>(TotalCost=R<b>{lastTotal}</b>)</small></span>
                    &nbsp;&nbsp;
                </h3>

                <ExcelBook data={getCsvDataProCard()}/>

                {renderInnerProcard()}

            </>
        )
    }

    const renderPayAccZSys = () => {
        let totalCost=0;
        let peoples=0;
        for(let i in data){
            let row=data[i];
            if(row.PaymentMethod!=="PayAccsys"){continue}
            totalCost +=parseFloat(row.NetPay);
            peoples++;
        }

        let lastTotal = formatMoney(totalCost);

        return (
            <>
                <h3 className="pastelHeading">
                    <span className="pastelTitleDetail"><small>(People=<b>{peoples}</b>)</small></span>
                    &nbsp;
                    <span className="pastelTitleDetail"><small>(TotalCost=<b>{lastTotal}</b>)</small></span>
                    &nbsp;&nbsp;
                </h3>
                <p><a onClick={()=>generateCsv()}><i className="fa fa-cloud-download"/> Download CSV</a></p>
                {/*<CSVLink
                    style={{marginTop: '10px'}}
                    data={getCsvDataSysAcc()}
                    className="btn btn-lg btn-primary btn-bd-download d-none d-lg-inline-block mb-3 mb-md-0 ml-md-3">
                    <i className="fa fa-cloud-download"/>
                    Download CSV --
                </CSVLink>*/}

                {renderInnerPayAccSys()}

            </>
        )
    }


    return (
        <Drawer
            title={"PAYMENT GETWAY CSV EXPORT"}
            width={720}
            onClose={onClose}
            visible={showView}
            bodyStyle={{paddingBottom: 80}}
            footer={
                <div
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <MuiThemeProvider>
                        <Tabs>
                            <Tab label="PAYACCSYS" className="tab-header">
                                {renderPayAccZSys()}
                            </Tab>
                            <Tab label="FLEXPAY">
                                {renderProCard()}
                            </Tab>
                        </Tabs>
                    </MuiThemeProvider>

                    <Button onClick={onClose} style={{marginRight: 8}}>
                        Close
                    </Button>

                </div>
            }
        >

        </Drawer>
    )

}

