import React, {useEffect, useState} from "react";
import "./dashbaord.css";
import {FormatMoneySouthAfrica, nFormatter} from "../../../api/fn";


const DashChartColumn2=({chartId,titleChart,data,columnColor,extraClass,tickPositions})=>{
    let Highcharts = window.Highcharts;
    const ddd = window.document.getElementById(chartId);
    let positions = [];
    if(typeof tickPositions!=="undefined"){
        positions=tickPositions;
    }
    const makeMeShort=(value)=>{

    }
    let options={
        chart: {
            type: 'column',
            height:"50%"
        },
        title: {
            text: `<span style="color:#01B1A8;font-size: 12px;color: #203764;font-weight: bold">${titleChart}</span>`
        },
        subtitle: {
            text: ''
        },
        accessibility: {
            announceNewData: {
                enabled: true
            }
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: ''
            },
            tickPositions:positions
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                    formatter:function (){return FormatMoneySouthAfrica(this.y)} // 'R{point.y:nFormatter(point.y)}'
                }
            }
        },

        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> of total<br/>'
        },

        colors:columnColor,

        series: [
            {
                name: "Invoices",
                colorByPoint: true,
                data: data
            }
        ],
    }
    useEffect(()=>{
        Highcharts.chart(chartId,options);
    })

    let myClass = "";
    if(typeof extraClass!=="undefined"){
        myClass = extraClass
    }
    return(
        <div id={chartId} className={`highchartsBox ${myClass}`}>

        </div>
    )
}

export default DashChartColumn2;