import {Form, Select} from "antd";
import React from "react";
const {Option} = Select;
export default (props)=>{
    let {
        displayConditionStateKey,
        displayConditionStateValue,
        data,label,name,message,
        handleChange,getValue,
        recordOptionKey,
        recordOptionValue,
        recordOptionDisplay,
        noCondition
    }=props;

    if(typeof noCondition==="undefined"){
        if (displayConditionStateKey === displayConditionStateValue) {
            return null
        }
    }else{

    }

    let ls = data;
    if(typeof ls ==="undefined"){
        ls=[];
    }
    if( ls ===null){
        ls=[];
    }
    return (
        <Form.Item
            label={label}
            name={name}
            rules={[{required: true, message: message}]}
        >
            <Select
                style={{width: "100%"}}
                onChange={(value) => handleChange(value, name)}
                value={getValue}
            >
                {ls.map((record, index) => {
                    return (
                        <Option
                            key={record[recordOptionKey]}
                            value={record[recordOptionKey]}
                        >
                            {record[recordOptionDisplay]}
                        </Option>
                    )
                })}
            </Select>
        </Form.Item>

    )
}
