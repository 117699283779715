import React, { Component } from 'react'
import {DropzoneDialog} from 'material-ui-dropzone'

export default class UploadDocument extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            files: [],
            category:"",
            name:"",
            Files:[],
            Outputs:[],
        };
    }

    handleClose() {
        this.props.handleClose();
    }

    handleSave=async (files) =>{
        //Saving files to state for further use and closing Modal.
        let output =[];
        for(let i in files){
            await this.saveToDatabase(files[i]);
        }
        console.log("selectedRecord --> ",this.props.selectedRecord)
        let data = {
            files: files,
            module: this.props.module,
            ref:this.props.selectedRecord.Ref,
            category:this.props.uploadCategory,
        };

        this.setState({
            Files:files
        })
        await this.updateStateFiles();
        console.log("Upload documents handleSave ",data);
        console.log("Upload documents handleSave 2 ",this.state.Outputs);

        await this.props.handleSave();


    }

    updateStateFiles=async ()=>{
        let _this=this;
        this.state.Files.forEach(async (file)=>{
            await _this.saveToDatabase(file)
        })
        console.log('Done!');
    }
    saveToDatabase=async (file)=>{
        let _this = this;
        const reader = new FileReader();
        reader.onload =async () => {
            let files=_this.state.Outputs;
            const o={
                base64string: reader.result,
                filename:file.name,
                module: _this.props.module,
                ref:_this.props.selectedRecord.Ref,
                category:_this.props.uploadCategory,
            };
            await _this.props.submitFile(o);
            files.push(o);
            _this.setState({Outputs:files})
            console.log(" saveToDatabase > ",o);

        }
        reader.readAsDataURL(file);
        reader.onerror = function (error) {
            console.log('Error uploading file: ', error);
        };
    }

    onDropHandler=(files) =>{
        return
        const _this=this;
        let file = files;
        const reader = new FileReader();
        reader.onload = () => {
            let _files = _this.state.Files;
            _files.push({
                id:"",
                base64string: reader.result,
                filename:file.name,
            })
            _this.setState({
                Files:_files
            })
        };
        reader.readAsDataURL(file);
        reader.onerror = function (error) {
            console.log('Error uploading file: ', error);
        };
    }

    submitFile=async (info)=>{

    }

    render() {
        /*let options=[
            {key:"profile",val:"Profile"},
            {key:"gallery",val:"Gallery"},
            {key:"other",val:"Other"},
        ];*/

        return (
            <div>

                <DropzoneDialog
                    open={this.props.open}
                    onSave={this.handleSave.bind(this)}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp','application/pdf']}
                    showPreviews={true}
                    maxFileSize={5000000}
                    onClose={this.handleClose.bind(this)}
                    onDrop={this.onDropHandler}
                    filesLimit={this.props.filesLimit}
                />
            </div>
        );
    }
}
