import React from "react";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import PluginAddDeleteList from "../../../../components/Common/PluginAddDeleteList2";
import {fetchDataEntity} from "../../../../api/entity-data";
import {
    getClientOption,
    getCompanyList, getItemList,
    getMonthOption,
    getProjectList,
    getTemplateList,
    getYearOption,
    loadData
} from "./lib";


class SetupLabourInvoiceItemPeriodPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Modules: [],
            Clients: [],
            Projects:[],
            Items:[],
            isLoading: false,
            company: "",
            component: "list",
            newEntry: {},
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await loadData("modules", "Modules",this);
        await loadData("SettingInvoiceServiceDebtors", "Clients",this);
        await loadData("SettingInvoiceAddonProject", "Projects",this);
        await loadData("SettingInvoiceAddonLabourItems","Items",this)
    }

    render() {
        const org = this.state.newEntry.selectedCompany;
        const client = this.state.newEntry.selectedClient;
        const project = this.state.newEntry.selectedProject;
        const item = this.state.newEntry.selectedItem;
        const companyOptions = getCompanyList(this.state.Companies);
        const clientOptions = getClientOption(this.state.Clients,org);
        const projectOptions =getProjectList(this.state.Projects,org,client);
        const itemOptions = getItemList(this.state.Items,org,client,project);

        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Setup Labour Invoice Period</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"SettingInvoiceAddonLabourItemPeriod"}
                            depending={{
                                "org": {
                                    targetKey: "CustomerNumber",
                                    displayKey: "Name",
                                    storageTable: "Companies"
                                },
                                "project":{
                                    targetKey: "code",
                                    displayKey: "name",
                                    storageTable: "Projects"
                                }
                            }}
                            fields={[
                                {
                                    name: "org",
                                    dataType: "option",
                                    required: true,
                                    label: "Company",
                                    options: companyOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Companies"}
                                },
                                {
                                    name: "client",
                                    dataType: "option",
                                    required: true,
                                    label: "Debtor",
                                    options: clientOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Clients"}
                                },
                                {
                                    name: "project",
                                    dataType: "option",
                                    required: true,
                                    label: "Project",
                                    options: projectOptions,
                                    depending: {stateKey: "project", displayKey: "Name", storageTable: "Projects"}
                                },
                                {
                                    name: "item",
                                    dataType: "option",
                                    required: true,
                                    label: "Item Description",
                                    options: itemOptions,
                                    depending: {stateKey: "item", displayKey: "Name", storageTable: "Items"}
                                },
                                {
                                    name: "year",
                                    dataType: "option",
                                    required: true,
                                    label: "Year",
                                    options: getYearOption()
                                },
                                {
                                    name: "month",
                                    dataType: "option",
                                    required: true,
                                    label: "Month",
                                    options: getMonthOption()
                                },

                                {
                                    name: "startdate",
                                    dataType: "date",
                                    required: true,
                                    label: "StartDate",
                                    options: []
                                },
                                {
                                    name: "enddate",
                                    dataType: "date",
                                    required: true,
                                    label: "End Date",
                                    options: []
                                },
                                {
                                    name: "periodnumber",
                                    dataType: "float",
                                    required: true,
                                    label: "Period Number",
                                    options: []
                                },


                            ]}
                            deleteKeys={[
                                {key: "org", type: "string"},
                                {key: "client", type: "string"},
                                {key: "project", type: "string"},
                                {key: "item", type: "string"},
                                {key: "year", type: "string"},
                                {key: "month", type: "string"},
                            ]}
                            stateArray={"Data"}
                            table={"SettingInvoiceAddonLabourItemPeriod"}
                            addFormText={"Invoice Period"}
                            stepTitle={"Setup invoice period"}

                            filterByCompany={true}
                            displayRules={[
                                {rowKey:"org",stateKey:"selectedCompany"},
                                {rowKey:"client",stateKey:"selectedClient"},
                                /*{rowKey:"project",stateKey:"selectedProject"},*/
                                {rowKey:"item",stateKey:"selectedItem"}
                            ]}
                            extraFilters={[
                                {
                                    recordKey:"key",
                                    recordValue:"val",
                                    stateKey:"client",
                                    filterRecordKey:"client",
                                    filterStateKey:"selectedClient",
                                    allowBlank:true,
                                    name:"selectedClient",
                                    label:"Debtors",
                                    options: clientOptions,
                                    errorMsg: "Sorry you can't save, Please select your client!"
                                },
                                /*{
                                    recordKey:"key",
                                    recordValue:"val",
                                    stateKey:"project",
                                    filterRecordKey:"project",
                                    filterStateKey:"selectedProject",
                                    allowBlank:true,
                                    name:"selectedProject",
                                    label:"Project",
                                    options: projectOptions,
                                    errorMsg: "Sorry you can't save, Please select your project!"
                                },*/
                                {
                                    recordKey:"key",
                                    recordValue:"val",
                                    stateKey:"item",
                                    filterRecordKey:"item",
                                    filterStateKey:"selectedItem",
                                    allowBlank:true,
                                    name:"selectedItem",
                                    label:"Item Description",
                                    options: itemOptions,
                                    errorMsg: "Sorry you can't save, Please select your item!"
                                }
                            ]}

                            sortMe={"name"}

                        />

                    </div>
                </div>
            </Page>
        )
    }

}

export default Connector(SetupLabourInvoiceItemPeriodPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

