import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import ControllerSelectBox from "../../../../components/Common/ControllerInput/ControllerSelectBox";
import {
    ArrayToSelectOption,
    FilterArrayByKey,
    FormatMoneySouthAfrica,
    FormatMoneySouthAfrica2,
    UpcaseFirst
} from "../../../../api/fn";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import {PostToPbx} from "../../../../api/http-post";
import {Button, Col, DatePicker, Drawer, Input, Row} from "antd";
import moment from "moment";
import '../../ServiceModule/Labour/styles.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCoffee, faList, faAngleDown, faCartArrowDown, faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons'
//https://www.w3schools.com/icons/fontawesome_icons_directional.asp
import statusGoodIcon from "../../../../assets/img/status-good.jpeg"
import statusBadIcon from "../../../../assets/img/status-bad.jpeg"


const formatDate = (dateIn) => {
    return moment(dateIn, "YYYY-MM-DD").format("DD MMM")
}
const formatDay = (dateIn) => {
    return moment(dateIn, "YYYY-MM-DD").format("DDD")
}
const formatDate2 = (dateIn) => {
    return moment(dateIn).format("YYYY-MM-DD")
}
Date.prototype.getWeek = function () {
    let date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    let week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

function getWeekRange(weekNo, yearNo) {
    let firstDayofYear = new Date(yearNo, 0, 1);

    if (firstDayofYear.getDay() > 4) {
        let weekStart = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 8);
        let weekEnd = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 8 + 6);
        return {from: formatDate2(weekStart), to: formatDate2(weekEnd)}
    } else {
        let weekStart = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 1);
        let weekEnd = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 1 + 6);
        return {from: formatDate2(weekStart), to: formatDate2(weekEnd)}
    }
}
const toFix = (valIn) => {
    let str = valIn.toFixed(2)
    return parseFloat(str)
}


class LabourLoggingDailyLogsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Attributes: [],
            Projects: [],
            Periods: [],
            DataDesignation: [],
            Data: [],
            Sites: [],
            Clients: [],
            Items: [],
            Vehicles: [],
            Drivers: [],
            Employees: [],
            WorkingDaysInfo:{
                Days : 0,
                Dates :[],
                Hours :8,
            },

            selectedCompany: "",
            selectedClient: "",
            selectedItem: "yes",
            selectedPeriod: "",
            selectedVehicle: "",
            selectedDriver: "",
            newEntry: {
                DeliveryNoteNumber: "",
                TimeInSettlement: "",
                TimeOutSettlement: "",
                TimeArrivedAtDisposed: "",
                TrailerRegistration: "",
                NumberOfUnits: "0"
            },

            mode: "list",
            allDates: [],
            allWeeks: [],
            allDateByWeeks: [],
            startDate: "2022-11-01",
            endDate: moment().format("YYYY-MM-DD"),
            selectedWeek: 0,
            showPopup: false,
            selectedSite: "",
            selectedDate: "",
            selectedLogs: [],
            selectedFilter: "Total",
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Projects", "", "", this, undefined, "common/entity/modules/list");
        //await fetchDataEntity("Data", "", "", this, undefined, "common/entity/ServiceLogsDeliveryNote/list");
        await fetchDataEntity("Sites", "", "", this, undefined, "common/entity/SettingInvoiceServiceAddonSite/list");
        await fetchDataEntity("Clients", "", "", this, undefined, "common/entity/SettingInvoiceServiceDebtors/list");
        await fetchDataEntity("Items", "", "", this, undefined, "common/entity/SettingInvoiceServiceItem/list");
        await fetchDataEntity("Vehicles", "", "", this, undefined, "common/entity/ServiceLogsVehicle/list");
        await fetchDataEntity("Drivers", "", "", this, undefined, "common/entity/ServiceLogsDriver/list");
        this.generateDate()
    }
    loadLabourLogs = async () => {
        let postData = {
            Org: this.state.selectedCompany,
            Project: this.state.selectedProject,
            StartDate: this.state.startDate,
            EndDate: this.state.endDate,
        }
        console.log("loadLabourLogs send > ", postData)
        let endpoint = "/labour/logs/search"
        await PostToPbx(postData, endpoint, async (output) => {
            let data = output.RESULT;
            console.log("loadLabourLogs response > ", data)
            this.setState({Data: data.Logs})
        });
    }
    loadEmployees = async () => {
        let postData = {
            Org: this.state.selectedCompany,
            Project: this.state.selectedProject,
            StartDate: this.state.startDate,
            EndDate: this.state.endDate
        }
        console.log("loadEmployees send > ", postData)
        let endpoint = "/labour/logs/employees"
        await PostToPbx(postData, endpoint, async (output) => {
            if(output===null){
                console.log("<>OUtput is empty")
                return
            }
            let data = output.RESULT;
            console.log("loadEmployees response > ", data)
            this.setState({Employees: data.Employees,WorkingDaysInfo:data.WorkingDaysInfo})
        });
    }
    requestDeleteLog = async (record) => {
        if (!window.confirm("are you sure to delete this log: " + record.deliverynumber)) {
            return
        }
        let Params = [
            {key: "org", val: record.org, type: "string"},
            {key: "client", val: record.client, type: "string"},
            {key: "item", val: record.item, type: "string"},
            {key: "site", val: record.site, type: "string"},
            {key: "deliveryDate", val: record.deliverydate, type: "string"},
            {key: "deliveryNumber", val: record.deliverynumber, type: "string"},
        ]
        let postData = {
            Params: Params,
        }
        console.log("requestDeleteLog send > ", postData)
        let endpoint = "/common/entity/ServiceLogsDeliveryNote/delete"
        await PostToPbx(postData, endpoint, async (output) => {
            let data = output.RESULT;
            console.log("requestDeleteLog response > ", data)
            await fetchDataEntity("Data", "", "", this, undefined, "common/entity/ServiceLogsDeliveryNote/list");
            alert("Thank you, your delivery note have been deleted!")
            this.setState({showPopup: false})
        });
    }
    onSubmitLog = async () => {
        // alert("Coming soon!")
        const state = this.state;
        const newState = state.newEntry;
        const info = this.state.selectedSite;
        let hub = {};
        hub.Org = state.selectedCompany;
        hub.Project = state.selectedProject;
        hub.WorkerRef = info.Code;
        hub.Hours = parseInt(newState.Hours)
        hub.WorkDate = state.selectedDate;

        console.log("submitRecord send > ", hub)
        let endpoint = "/labour/logs/new"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            console.log("submitRecord response > ", data)
            await this.loadLabourLogs()
            alert("Thank you, your labour logs have been recorded!")
            this.setState({showPopup: false})
        });
    }
    submitSearch = async () => {
        let state = this.state;
        this.generateDate();
        await this.loadEmployees();
        await this.loadLabourLogs();
    }
    requestPopup = (site, date, logsList) => {
        console.log(">>>>Site: " , site , " > Date: " + date)
        this.setState({
            showPopup: true,
            selectedSite: site,
            selectedDate: date,
            selectedLogs: logsList
        })
    }
    handleChangeInput = async (key, value) => {
        this.setState({
            [key]: value,
        })
    }
    generateDatesByWeek = (weekNo, year) => {
        let dates = []
        let infoRange = getWeekRange(weekNo, year)
        const date = new Date(infoRange.from);
        const endDate = new Date(infoRange.to);
        while (date <= endDate) {
            let newDate = moment(date).format("YYYY-MM-DD")
            let newDay = moment(date).format("ddd")
            let weekNo = parseInt(moment(date).format("w"))
            dates.push({
                date: newDate,
                day: newDay,
                weekNo: weekNo,
            });
            date.setDate(date.getDate() + 1);
        }
        return dates
    }
    generateDate = () => {
        let state = this.state;
        let weeks = {};
        let weeks2 = {};
        const dates = [];
        const date = new Date(state.startDate);
        const endDate = new Date(state.endDate);
        while (date <= endDate) {
            let newDate = moment(date).format("YYYY-MM-DD")
            let newDay = new Date(date).toDateString().split(" ")[0]
            let weekNo = moment(date).isoWeek();
            let year = parseInt(moment(date).format("YYYY"))
            dates.push({
                date: newDate,
                day: newDay,
                weekNo: weekNo,
            });
            let wNo = `${weekNo}-${year}`
            weeks[wNo] = `${weekNo}-${year}`
            date.setDate(date.getDate() + 1);
            if (typeof weeks2[wNo] === "undefined") {
                weeks2[wNo] = []
            }
            weeks2[wNo].push(
                {
                    date: newDate,
                    day: newDay,
                    weekNo: wNo,
                }
            )
        }
        let allWeeks = [];
        for (let i in weeks) {
            allWeeks.push(i)
        }
        this.setState({
            allDates: dates,
            allWeeks,
            weeks2
        })
    }
    handleGetInputValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return ""
        }
        return this.state[key];
    }
    isWorkingDayForSite = (siteInfo, dayIn) => {
        let {WorkingDaysByWeek} = this.findWorkingDayOfSiteByWeek(siteInfo);
        for (let i in WorkingDaysByWeek) {
            let item = WorkingDaysByWeek[i]
            if (item === dayIn) {
                return true
            }
        }
        return false
    }
    findWorkingDayOfSiteByWeek = (site) => {
        let days = [];
        let total = 0;
        if (site.mon) {
            total++
            days.push("Mon")
        }
        if (site.tue) {
            total++
            days.push("Tue")
        }
        if (site.wed) {
            total++
            days.push("Wed")
        }
        if (site.thu) {
            total++
            days.push("Thu")
        }
        if (site.fri) {
            total++
            days.push("Fri")
        }
        if (site.sat) {
            total++
            days.push("Sat")
        }
        if (site.sun) {
            total++
            days.push("Sun")
        }
        return {
            WorkingDaysByWeek: days,
        }
    }
    findPlannedWorkingDay = (siteInfo) => {
        let totalPlannedDaysForPeriod = 0;
        for (let i in this.state.allDates) {
            let row = this.state.allDates[i];
            if (this.isWorkingDayForSite(siteInfo, row.day)) {
                totalPlannedDaysForPeriod++
            }
        }
        return {totalPlannedDaysForPeriod}
    }
    findActualByEmployeeForPeriod = (emp) => {
        let state = this.state;
        let totalDays=0,totalHours=0;
        for (let i in state.Data) {
            let row = state.Data[i];
            if (row.WorkerRef !== emp.Code) {
                continue
            }
            totalDays++;
            totalHours +=row.Hours;
        }
        return {totalDays,totalHours}
    }
    findSummaryByEmployee = (empInfo) => {
        let state = this.state;
        let totalActualDays, totalPlan, totalDiff, totalActualCost, totalPlannedCost;
        let {totalDays,totalHours} = this.findActualByEmployeeForPeriod(empInfo)
        totalActualDays =  totalDays;
        totalPlan = this.state.WorkingDaysInfo.Days;
        /**
         * let find difference between plan and actual
         */
        totalDiff = totalActualDays - totalPlan
        /**
         * let find total cost and lost
         */

        return {
            totalPlan,
            totalDiff,
            totalActualDays,
            totalHours
        }

    }
    isPlannedWorkingDate=(dateIn)=>{
        for(let i in this.state.WorkingDaysInfo.Dates){
            let targetDate  = this.state.WorkingDaysInfo.Dates[i];
            if(targetDate===dateIn){
                return true
            }
        }
        return false
    }
    findServiceByDate = (empCode, date) => {
        let state = this.state;
        let numberOfUnits = 0;
        let numberOfDelivery = 0;
        let logsList = [];
        for (let i in state.Data) {
            let row = state.Data[i];
            if (row.Org !== state.selectedCompany) {
                continue
            }
            if (row.Project !== state.selectedProject) {
                continue
            }

            if (row.WorkerRef !== empCode) {
                continue
            }
            if (row.WorkDate !== date) {
                continue
            }
            numberOfUnits += row.Hours;
            numberOfDelivery++;
            logsList.push(row)
        }
        return {numberOfUnits, numberOfDelivery, logsList}
    }
    getTotalStatus = () => {
        let totalStatusGood = 0, totalStatusBad = 0;
        for (let i in this.state.Employees) {
            let row = this.state.Employees[i];
            let summary = this.findSummaryByEmployee(row)
            if (summary.totalDiff === 0) {
                totalStatusGood++
            } else {
                totalStatusBad++
            }
        }
        return {totalStatusGood, totalStatusBad}
    }
    renderPopup = () => {
        if (!this.state.showPopup) {
            return null
        }
        const onClose = () => {
            this.setState({showPopup: false})
        }
        let inputsForm = () => {
            return [
                {key: "Hours", label: "Number of Hours", isEmpty: false, type: "text"},
            ]
        }
        const innerOnChance = (key, value) => {
            let newEntry = this.state.newEntry;
            newEntry[key] = value;
            this.setState({newEntry})
        }
        const innerRenderText = (keyState, label) => {
            return (
                <Row>
                    <Col span={6} className={"popupLabel"}>
                        {label}:
                    </Col>
                    <Col span={6} className={"popupInput"}>
                        <Input
                            onChange={(e) => {
                                innerOnChance(keyState, e.target.value)
                            }}
                            placeholder={"Enter your delivery.."}
                        ></Input>
                    </Col>
                </Row>
            )
        }
        const info = this.state.selectedSite;
        return (
            <Drawer
                title={"Services logs detail"}
                width={720}
                onClose={onClose}
                visible={this.state.showPopup}
                bodyStyle={{paddingBottom: 80, overflow: "scroll"}}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >

                        <Button onClick={onClose} style={{marginRight: 8}}>
                            Cancel
                        </Button>

                        <Button onClick={() => this.onSubmitLog()} type="primary">
                            Submit
                        </Button>
                    </div>
                }
            >
                <p>Employee Code: {info.Code}</p>
                <p>Site: {info.Site}</p>
                <p>Rate: {info.Rate}</p>
                <p>Full name: {info.Name}</p>
                <p>Designation: {info.Designation}</p>
                <p>Date: {this.state.selectedDate}</p>
                {inputsForm().map(row => {
                    if (row.type === "time") {
                        return innerRenderText(row.key, row.label)
                    }
                    return innerRenderText(row.key, row.label)
                })}

            </Drawer>
        )
    }
    renderDriverBox = () => {
        let ls = [];
        for (let i in this.state.Drivers) {
            let row = this.state.Drivers[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            ls.push(row)
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["name"], ["name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Driver"}
                name={"selectedDriver"}
            />
        )
    }
    renderVehicleBox = () => {
        let ls = [];
        for (let i in this.state.Vehicles) {
            let row = this.state.Vehicles[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            ls.push(row)
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["regnumber"], ["regnumber"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Vehicle"}
                name={"selectedVehicle"}
            />
        )
    }
    renderCompanyBox = () => {
        let ls = [];
        ls = FilterArrayByKey(this.state.Companies, {})
        ls = ArrayToSelectOption(ls, ["CustomerNumber"], ["Name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Company"}
                name={"selectedCompany"}
            />
        )
    }
    renderClientBox = () => {
        let ls = [];
        for (let i in this.state.Projects) {
            let row = this.state.Projects[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            ls.push(row)
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["name"], ["name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Project"}
                name={"selectedProject"}
            />
        )
    }
    renderItemBox = () => {
        let ls = [];
        for (let i in this.state.Items) {
            let row = this.state.Items[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.client !== this.state.selectedClient) {
                continue
            }
            ls.push(row)
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["name"], ["name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Items"}
                name={"selectedItem"}
            />
        )
    }
    renderDashboardStatus = () => {
        let employees = this.state.Employees

        let sites = employees.length;
        let totEmployee = 0, totPlannedDays = 0, totPlannedRevenue = 0, totActualRevenue = 0, totImpact = 0;
        let totPlannedServices = 0, totalActualDays = 0, totMissedDays = 0;
        for (let i in employees) {
            let row = employees[i];
            let summary = this.findSummaryByEmployee(row)
            totEmployee++
            totPlannedDays += this.state.WorkingDaysInfo.Days;
            totalActualDays += summary.totalActualDays
        }
        totMissedDays = totalActualDays - totPlannedDays
        /**
         *
         * @type {string}
         */
        let statusIndex = "bad";
        let financialIndex = 0;

        let servicePerformanceIndex = 0;
        if (totalActualDays > 0 && totPlannedDays > 0) {
            servicePerformanceIndex = (totalActualDays / totPlannedDays) * 100
        }
        servicePerformanceIndex = toFix(servicePerformanceIndex)

        let ls = [
            {label: "Total Emp.", total: totEmployee, currency: false, currValue: "", extraClass: ""},
            {label: "---", total: 0, currency: true, currValue: "", extraClass: ""},
            {label: "Total Planned Days", total: totPlannedDays, currency: true, currValue: "", extraClass: ""},
            {label: "Total Actual Days", total: totalActualDays, currency: true, currValue: "", extraClass: ""},
            {
                label: "Service missed",
                total: totMissedDays,
                currency: true,
                currValue: "",
                extraClass: {color: "red"}
            },
        ]

        return (
            <Row gutter={18} className={"stRow borderTop"}>
                {ls.map(row => {
                    return (
                        <Col span={4} className={"stRowCol"}>
                            <label className={"stRowLabel"}>{row.label}:</label>
                            <label className={`stRowTotal1 `} style={{...row.extraClass}}>
                                {row.currency ? row.currValue : ""} {row.currency ? FormatMoneySouthAfrica2(row.total) : row.total}
                            </label>
                        </Col>
                    )
                })}
                <Col span={4} className={"stRowCol"}>
                    <label className={"stRowLabel"}>Performance Index:
                        <span style={{color: "black", fontWeight: "bold", fontSize: 16}}>
                        {financialIndex}%
                        </span>
                    </label>
                    <label className={"stRowTotal1"}>
                        <img className={"statusIcon"} src={statusIndex === "good" ? statusGoodIcon : statusBadIcon}/>
                    </label>
                </Col>
            </Row>
        )
    }
    renderList = () => {
        let state = this.state;
        let employees = state.Employees.length;
        let allWeeksAndDates = [];
        for (let i in state.weeks2) {
            if (this.state.selectedWeek !== i) {
                continue
            }
            let rows = state.weeks2[i];
            for (let a in rows) {
                let rec = rows[a];
                allWeeksAndDates.push(rec)
            }
        }

        let {totalStatusBad, totalStatusGood} = this.getTotalStatus()
        let arrRadioButtons = [
            {label: "Total", color: "black", value: employees},
            {label: "Good", color: "green", value: totalStatusGood},
            {label: "Bad", color: "red", value: totalStatusBad},
        ]
        return (
            <div style={{backgroundColor: "white", padding: 10}}>
                {this.renderDashboardStatus()}
                <Row gutter={18} className={"borderBottom weekRow"}>
                    <Col span={2}>
                        Weeks:
                    </Col>
                    <Col span={10} style={{display: "flex", flexDirection: "row"}}>
                        {state.allWeeks.map((item, index) => {
                            let bgStyle = {}
                            if (this.state.selectedWeek === item) {
                                bgStyle = {
                                    backgroundColor: "lightblue",
                                    color: "white"
                                }
                            }
                            return (
                                <Col span={2}>
                                    <Button
                                        onClick={() => this.setState({selectedWeek: item})}
                                        style={{
                                            ...bgStyle, width: "100%"
                                        }}>
                                        {index + 1}
                                    </Button>
                                </Col>
                            )
                        })}
                    </Col>
                    <Col span={12} style={styles.boxFilter}>
                        <Col span={5}>
                            <label>Site Service Index: </label>
                        </Col>
                        {arrRadioButtons.map(row => {
                            return (
                                <Col span={5} style={styles.boxFilterInner}>
                                    <input
                                        name={"filter1"}
                                        type={"radio"}
                                        onChange={(e) => {
                                            this.setState({selectedFilter: row.label})
                                            console.log("@::> ", e.target.value, row.label)
                                        }}
                                    />
                                    <label style={{color: row.color}}>{row.label}</label>
                                    <label style={{color: row.color}}>{row.value}</label>
                                </Col>
                            )
                        })}
                    </Col>

                </Row>
                <Row gutter={18}>
                    <Col span={1} className={"row1Th pushCenter"}>
                        Action
                    </Col>
                    <Col span={1} className={"row1Th pushCenter"}>
                        #
                    </Col>
                    <Col span={4} className={"row1Th"}>
                        Employee
                    </Col>
                    <Col span={2} className={"row1Th pushCenter row1ThE"}>
                        Code
                    </Col>

                    <Col span={2} className={"row1Th pushCenter row1ThE"}>
                        Role
                    </Col>

                    <Col span={1} className={"row1Th pushCenter row1ThE"}>
                        Plan
                    </Col>
                    <Col span={1} className={"row1Th pushCenter row1ThE"}>
                        Actual
                    </Col>
                    <Col span={1} className={"row1Th pushCenter row1ThE"}>
                        Diff
                    </Col>
                    <Col span={1} className={"row1Th pushCenter row1ThE"}>
                        Status
                    </Col>
                    <Col span={3} className={"row1Th pushCenter row1ThE"}>
                        Site
                    </Col>
                    {state.allWeeks.map((item, index) => {
                        if (state.selectedWeek !== item) {
                            return null
                        }
                        let arr = item.split("-")
                        let week = parseInt(arr[0])
                        let year = parseInt(arr[1])
                        let dates = this.generateDatesByWeek(week, year)
                        return dates.map(infoDate => {
                            let colRed = "dtBgTh";

                            if (infoDate.day === "Sat" || infoDate.day === "Sun") {
                                console.log("PPPPIO > ", infoDate)
                                colRed = "colRed2"
                            }
                            return (
                                <Col span={1} className={"row1Th pushCenter extHeader " + colRed}>
                                    {formatDate(infoDate.date)}
                                </Col>
                            )
                        })

                    })}
                </Row>
                <Row gutter={18}>
                    <Col span={1} className={"row1Th pushCenter"}>
                        &nbsp;
                    </Col>
                    <Col span={1} className={"row1Th pushCenter"}>
                        &nbsp;
                    </Col>
                    <Col span={4} className={"row1Th"}>
                        &nbsp;
                    </Col>
                    <Col span={2} className={"row1Th pushCenter"}>
                        &nbsp;
                    </Col>
                    <Col span={2} className={"row1Th pushCenter"}>
                        &nbsp;
                    </Col>

                    <Col span={1} className={"row1Th pushCenter"}>
                        &nbsp;
                    </Col>
                    <Col span={1} className={"row1Th pushCenter"}>
                        &nbsp;
                    </Col>
                    <Col span={1} className={"row1Th pushCenter"}>
                        &nbsp;
                    </Col>

                    <Col span={1} className={"row1Th pushCenter"}>
                        &nbsp;
                    </Col>
                    <Col span={3} className={"row1Th pushCenter"}>
                        &nbsp;
                    </Col>

                    {state.allWeeks.map((item, index) => {
                        if (state.selectedWeek !== item) {
                            return null
                        }
                        let arr = item.split("-")
                        let week = parseInt(arr[0])
                        let year = parseInt(arr[1])
                        let dates = this.generateDatesByWeek(week, year)
                        return dates.map(infoDate => {
                            let colRed = "";
                            if (infoDate.day === "Sat" || infoDate.day === "Sun") {
                                colRed = "colRed"
                            }
                            return (
                                <Col span={1} className={"row1Th pushCenter " + colRed}>
                                    {infoDate.day}
                                </Col>
                            )
                        })

                    })}
                </Row>
                {this.state.Employees.map((rowEmp, index1) => {

                    let summary = this.findSummaryByEmployee(rowEmp)
                    if (this.state.selectedFilter === "Good") {
                        if (summary.totalDiff !== 0) {
                            return null
                        }
                    }
                    if (this.state.selectedFilter === "Bad") {
                        if (summary.totalDiff === 0) {
                            return null
                        }
                    }
                    return (
                        <Row gutter={18}>
                            <Col span={1} className={"row1Th pushCenter"} style={{
                                cursor: "pointer"
                            }}>
                                <FontAwesomeIcon icon={faList} color={"lightblue"}/>
                            </Col>
                            <Col span={1} className={"row1Th pushCenter"}>
                                {index1 + 1}
                            </Col>
                            <Col span={4} className={"row1Th"}>
                                {rowEmp.Name}
                            </Col>
                            <Col span={2} className={"row1Th pushCenter"}>
                                {rowEmp.Code}
                            </Col>
                            <Col span={2} className={"row1Th pushCenter"}  style={{fontSize:10}}>
                                {rowEmp.Designation}
                            </Col>

                            <Col span={1} className={"row1Th pushCenter"}>
                                {this.state.WorkingDaysInfo.Days}
                            </Col>
                            <Col span={1} className={"row1Th pushCenter"}>
                                {summary.totalActualDays}
                            </Col>
                            <Col span={1} className={"row1Th pushCenter"}>
                                {summary.totalDiff}
                            </Col>

                            <Col span={1} className={"row1Th pushCenter pushRight"}>
                                {summary.totalDiff !== 0 ?
                                    <FontAwesomeIcon icon={faCaretDown} color={"red"}/> :
                                    <FontAwesomeIcon icon={faCaretUp} color={"green"}/>
                                }
                            </Col>
                            <Col span={3} className={"row1Th pushCenter"} style={{fontSize:10}}>
                                {rowEmp.Site}
                            </Col>


                            {state.allWeeks.map((item, index) => {
                                if (state.selectedWeek !== item) {
                                    return null
                                }
                                let arr = item.split("-")
                                let week = parseInt(arr[0])
                                let year = parseInt(arr[1])
                                let dates = this.generateDatesByWeek(week, year)
                                return dates.map(infoDate => {
                                    let {
                                        numberOfUnits,
                                        numberOfDelivery,
                                        logsList
                                    } = this.findServiceByDate(rowEmp.Code, infoDate.date)
                                    let extraStyle = {}
                                    if (numberOfUnits !== 0) {
                                        if (rowEmp.quantity !== numberOfUnits) {
                                            extraStyle = {color: "orange"}
                                        }
                                    }

                                    /**
                                     * let verify if this is planned working day
                                     */
                                    if(this.isPlannedWorkingDate(infoDate.date)){
                                        if(numberOfUnits===0){
                                            extraStyle = {...extraStyle,backgroundColor:"red",color:"white"}
                                        }
                                    }
                                    return (
                                        <Col
                                            span={1}
                                            className={"row1Th pushCenter clickBtnCol"}
                                            onClick={() => this.requestPopup(rowEmp, infoDate.date, logsList)}
                                            style={{
                                                ...extraStyle,
                                            }}
                                        >
                                            <label className={"numberServiceLabel"}>{numberOfUnits}</label><br/>
                                            {/*<label className={"deliveryLabel"}>{numberOfDelivery}</label>*/}
                                        </Col>
                                    )
                                })
                            })}
                        </Row>
                    )
                })}

            </div>
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Service Logs</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <Row gutter={18}>
                            <Col span={12}>
                                {this.renderCompanyBox()}
                            </Col>
                            <Col span={12}>
                                {this.renderClientBox()}
                            </Col>
                        </Row>
                        <Row gutter={18} style={{marginTop: -30}}>
                            <Col span={12}>
                                {this.renderItemBox()}
                            </Col>
                            <Col span={12} style={styles.dateRow}>
                                <Col span={10} style={{marginTop: 30, height: 40}}>
                                    <DatePicker
                                        style={styles.datePickerBox}
                                        onChange={(date, dateString) => this.setState({startDate: dateString})}
                                        placeholder={"Start Date"}
                                    />
                                </Col>
                                <Col span={10} style={{marginTop: 30, height: 40}}>
                                    <DatePicker
                                        style={styles.datePickerBox}
                                        onChange={(date, dateString) => this.setState({endDate: dateString})}
                                        placeholder={"End Date"}
                                    />
                                </Col>
                                <Col span={4} style={{marginTop: 30, height: 40}}>
                                    <Button
                                        style={styles.datePickerBox}
                                        onClick={() => this.submitSearch()}
                                    >
                                        Search
                                    </Button>
                                </Col>

                            </Col>
                        </Row>
                        <div style={{
                            borderRadius: 5,
                            borderWidth: 1,
                            borderColor: "lightblue",
                            borderStyle: 'solid',
                            minHeight: 35,
                            padding: 5
                        }}>
                            {this.renderList()}

                        </div>

                    </div>
                    {this.renderPopup()}
                </div>
            </Page>
        )
    }
}

export default Connector(LabourLoggingDailyLogsPage);
const styles = {
    boxFilterInner: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 5,
        borderRight: "1px solid black",
        fontsize: 14,
        fontWeight: "bold"
    },
    boxFilter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    dateRow: {
        display: "flex",
        flexDirection: "row"
    },
    datePickerBox: {
        height: 40,
        width: "100%"
    },
    row1Th: {
        border: "1px solid black",
    },
    rowForm: {
        marginBottom: 10,
        paddingBottom: 5,
        border: "0px solid lightgray",
        borderBottomWidth: 1,

    },
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}