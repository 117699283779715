import React, {useState} from "react";
import {Button, Col,Row, DatePicker, Drawer, Form, Table,Input} from 'antd';
import {Tabs, Tab} from 'material-ui/Tabs';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import moment from "moment";

const currentDate = moment().format("YYYY-MM-DD")
export default (props) => {
    let {data,showView,setShowView,FnbCsvInfo} = props;
    const [releaseDate,setReleaseDate]=useState(currentDate);
    const [BankFileHeading,setBankFileHeading]=useState("");


    let infoBankOwnerPrefix="PMIS"
    let infoBankBenPrefix="PMIS"
    let infoBankHeader = "I-2022"
    let infoBankAccount = ""
    if(FnbCsvInfo!==null){
        let row         = FnbCsvInfo;
        infoBankOwnerPrefix = row.prefixrefkeyowner;
        infoBankBenPrefix = row.prefixrefkeybeneficiary;
        infoBankHeader = row.header;
        infoBankAccount = row.accountnumber;
    }


    const onClose = () => {
        setShowView(false);
    }

    const renderDateSelect=()=>{
        const onChangeStartDate=(key,date)=>{
            setReleaseDate(date)
            console.log("onChangeStartDate -----> ",date)
        }
        const onChange=(e)=>{
            let val = e.target.value;
            setBankFileHeading(val);
            console.log("onChange2 -----> ",val)
        }

        return (
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="startDate"
                        label="Start Date"
                        rules={[{ required: true, message: 'Please choose the start date' }]}
                    >
                        <DatePicker onChange={onChangeStartDate} style={{minWidth:"100%"}}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="bankHeading"
                        label="Bank File Heading"
                        rules={[{ required: true, message: 'Please BankFileHeading date' }]}
                    >
                        <Input onChange={onChange} style={{minWidth:"100%"}}/>
                    </Form.Item>
                </Col>
            </Row>
        )
    }
    return (
        <Drawer
            title={"Export Csv Bank"}
            width={720}
            onClose={onClose}
            visible={showView}
            bodyStyle={{paddingBottom: 80}}
            footer={
                <div
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <MuiThemeProvider>
                        <Tabs>
                            <Tab label="FNB" className="tab-header">
                                {renderDateSelect()}
                                <BuildTable data={data} releaseDate={releaseDate} BankFileHeading={infoBankHeader} bankAccount={infoBankAccount}/>
                            </Tab>
                        </Tabs>
                    </MuiThemeProvider>

                    <Button onClick={onClose} style={{marginRight: 8}}>
                        Close
                    </Button>

                </div>
            }
        >

        </Drawer>
    )
}

const BuildTable = ({data,releaseDate,BankFileHeading,bankAccount}) => {

    let columns = [
        {title: "RECIPIENT NAME", dataIndex: "RECIPIENT NAME", align: 'left'},
        {title: "RECIPIENT ACCOUNT", dataIndex: "RECIPIENT ACCOUNT", align: 'left'},
        {title: "RECIPIENT ACCOUNT TYPE", dataIndex: "RECIPIENT ACCOUNT TYPE", align: 'center'},
        {title: "AMOUNT", dataIndex: "AMOUNT", align: 'right'},
        {title: "OWN REFERENCE", dataIndex: "OWN REFERENCE", align: 'left'},
        {title: "RECIPIENT REFERENCE", dataIndex: "RECIPIENT REFERENCE", align: 'left'},
    ];

    return (
        <div className="pastelDiv">
            <h3 className="pastelHeading">
                <span className="pastelTitle">FNB Payment </span>
                &nbsp;&nbsp;
                <span className="pastelTitleDetail"><small>(People=<b>{data.length}</b>)</small></span>
                &nbsp;
                <a onClick={() => (MyDownload(data,releaseDate,BankFileHeading,bankAccount))} className="pastelDownloadBtn">
                    Download
                </a>
            </h3>
            <Table
                columns={columns}
                dataSource={data}
                bordered
            />
        </div>
    )
};

const MyDownload = (rows,releaseDate,BankFileHeading,bankAccount) => {
    console.log("bankAccount>>> ",bankAccount)
    let datas = [];
    datas.push(
        [BankFileHeading,"", "", "", "", "",""],

    );
    datas.push([
        moment(releaseDate, "YYYY-MM-DD").format("YYYY/MM/DD"),"", "", "", "", "",""
    ]);
    datas.push([[bankAccount],"", "", "", "", "",""]);
    datas.push([
        "RECIPIENT NAME",
        "RECIPIENT ACCOUNT",
        "RECIPIENT ACCOUNT TYPE",
        "BRANCHCODE",
        "AMOUNT",
        "OWN REFERENCE",
        "RECIPIENT REFERENCE"]);
    for (let i in rows) {
        const row = rows[i];
        datas.push([
            cleanVal(row["RECIPIENT NAME"]),
            cleanVal(row["RECIPIENT ACCOUNT"]),
            cleanVal(row["RECIPIENT ACCOUNT TYPE"]),
            cleanVal(row["BRANCHCODE"]),
            cleanVal(row["AMOUNT"]),
            cleanVal(row["OWN REFERENCE"]),
            cleanVal(row["RECIPIENT REFERENCE"])
        ]);
    }
    let csvContent = "data:text/csv;charset=utf-8,";
    datas.forEach(function (rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "FNB_Upload_" +
        moment(releaseDate,"YYYY-MM-DD").format("DD MMM YYYY") + ".csv");
    link.innerHTML = "Click Here to download";
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
}

const cleanVal=(str)=>{
    str=str.toString().replace(`\r`,'');
    str=str.toString().replace(`\n`,'');
    return str
}