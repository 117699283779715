import React from "react"
import axios from "axios";
//import data from '/data/index.json';
import MyJson from "./index.json";
let info = {};
export const LoadConfigInfo =async () =>{
    let url = "/data/index.json"
    let data = {};
    return await axios
        .get(url)
        .then((res)=>{
            info = res.data
            return data
        })
        .catch(err=>console.log("Error fetching config file: ",err))
}

export const GetConfigures=()=>{
    let server = MyJson.ServerPbx;
    return server;

    //let data = window.globalTS.config_url;
    let url = process.env.PUBLIC_URL+"/data/index.json";
     url = "/data/index.json"
    let data = {};
    return axios
        .get(url)
        .then((res)=>{
            data = res.data
            return data
        })
        .catch(err=>console.log("Error fetching config file: ",err))

}