import React, {useState} from "react";
import "./dashbaord.css";
import {Row,Col} from "antd";
import moment from "moment";

const AnalysisOfAgedDebtorsTable=({data})=>{
    /*let ls=[
        {client:"City of Cape Town",ageOne:3484681.64,ageTwo:0,ageThree:0,ageFour:0,total:3484681.64},
        {client:"Sanitech a Division of Waco (Pty) Ltd",ageOne:660140.25,ageTwo:653458.75,ageThree:0,ageFour:0,total:1313599},
        {client:"Moreki Distribution C.C",ageOne:5175000,ageTwo:488750,ageThree:0,ageFour:0,total:977500},
        {client:"Stellenbosch Municipality",ageOne:78494.40,ageTwo:0,ageThree:0,ageFour:0,total:78494.40},
    ];*/

    let ls = data
    const renderBody=()=>{
        let streped = 0;
        const tbRowBg="lightgray";//"lightsalmon";
        // saddlebrown
        return ls.map(row=>{
            let extraStyle={};
            if(streped===1){
                extraStyle={backgroundColor:tbRowBg}
            }
            streped = streped===0?1:0;
            let whiteBorder={
                borderBottomWidth:1,
                borderBottom:"1px solid white",
                borderTop:"1px solid white",
            }
            return(
                <Row className={"adRowBody"} style={{...extraStyle}}>
                    <Col span={8} className={"adColTd adPushLeft"}>{row.client}</Col>
                    <Col span={3} className={"adColTd"} style={{...whiteBorder,backgroundColor:"#00B050"}}>{row.ageOne}</Col>
                    <Col span={3} className={"adColTd"} style={{...whiteBorder,backgroundColor:"#FFC000"}}>{row.ageTwo}</Col>
                    <Col span={3} className={"adColTd"} style={{...whiteBorder,backgroundColor:"#FF6359"}}>{row.ageThree}</Col>
                    <Col span={3} className={"adColTd"} style={{...whiteBorder,backgroundColor:"#B17ED8"}}>{row.ageFour}</Col>
                    <Col span={4} className={"adColTd"}>{row.total}</Col>
                </Row>
            )
        })
    }
    return(
        <div className={"AnalysisOfAgedDebtorsTable"}>
            <div className={"adTitle1"}>Analysis of Aged Debtors</div>
            <div className={"adTitle2"}>As at {moment().format("DD-MMM-YYYY")}</div>
            <Row className={"adRowHeader"}>
                <Col span={8} className={"adColTh adPushLeft"}>Customer</Col>
                <Col span={3} className={"adColTh adPushCenter"}>30 Days</Col>
                <Col span={3} className={"adColTh adPushCenter"}>31-60</Col>
                <Col span={3} className={"adColTh adPushCenter"}>61-90</Col>
                <Col span={3} className={"adColTh adPushCenter"}>91-120</Col>
                <Col span={4} className={"adColTh adPushCenter"}>Total</Col>
            </Row>
            {renderBody()}
        </div>
    )
}

export default AnalysisOfAgedDebtorsTable;