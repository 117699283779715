export const stepContractAttributesPeriod = [
    {name: "StartDate", dataType: "date", required: true, label: "Start Date", options: []},
    {name: "EndDate", dataType: "date", required: true, label: "End Date", options: []},
    {name: "TotalYear", dataType: "float", required: false, label: "Total Years", options: []},
    {name: "TotalMonth", dataType: "float", required: false, label: "Total Months", options: []},
    {name: "TotalWeek", dataType: "float", required: false, label: "Total Weeks", options: []},
    {name: "TotalDay", dataType: "float", required: false, label: "Total Days", options: []},
];

export const stepContractAttributesTermsPayCycle = [
    {name: "Period", dataType: "float", required: true, label: "Pay Cycle Period Number", options: []},
    {name: "StartDate", dataType: "date", required: true, label: "Pay Cycle Start Date", options: []},
    {name: "EndDate", dataType: "date", required: true, label: "Pay Cycle End Date", options: []},
    {name: "PayDate", dataType: "date", required: true, label: "Pay Date(Date funds to be released into beneficiaries account)", options: []},
];

export const stepContractAttributesTermsDesignation = [
    {name: "Name", dataType: "string", required: true, label: "Designation", options: []},
];

export const stepContractAttributesTermsCondition = [
    {
        name: "PayCycle", dataType: "option", required: true, label: "Wage Payment Cycle", options: [
            {key: "Weekly", val: "Weekly"},
            {key: "Fortnightly", val: "Fortnightly"},
            {key: "Monthly", val: "Monthly"},
        ]
    },
    {
        name: "RateType", dataType: "option", required: true, label: "Wage Calculation Method", options: [
            {key: "hour", val: "per hour"},
            {key: "day", val: "per day"},
            {key: "week", val: "per week"},
            {key: "fortnight", val: "per fortnight"},
            {key: "month", val: "per month"},
        ]
    },
    {
        name: "FixHours", dataType: "boolean", required: true, label: "Wage Calculation?", options: [
            {key: "no", val: "Variable as per clocking"},
            {key: "yes", val: "Fixed"},
        ]
    },
    {
        name: "TargetHours", dataType: "float", required: false, label: "If Fixed,Targeted Working Hours per Day", options: []
    },
];

export const stepContractAttributesTermsSite = [
    {name: "Name", dataType: "string", required: true, label: "Site Name", options: []},
    {name: "NumberOfUnit", dataType: "float", required: false, label: "Number Of Unit", options: []},
    {name: "TotalPeople", dataType: "float", required: false, label: "Number Of Employees", options: []},
    {name: "Lng", dataType: "string", required: false, label: "Longitude", options: []},
    {name: "Lat", dataType: "string", required: false, label: "Latitude", options: []},
    {name: "Radius", dataType: "float", required: false, label: "Clocking Radius", options: []},
    /* {name: "Angle", dataType: "float", required: true, label: "Geo angle", options: []},*/
    {name: "Address", dataType: "string", required: false, label: "Physical Address", options: []},
];

export const stepContractAttributesTermsRate = [
    {name: "Designation", dataType: "option", required: true, label: "Designation", options: []},
    {name: "Slot", dataType: "option", required: true, label: "Shift", options: []},
    {
        name: "ChargeType", dataType: "option", required: true, label: "Charge", options: [
            {key: "employee", val: "Employee"},
            {key: "client", val: "Client"},
        ]
    },
    {name: "Normal", dataType: "float", required: true, label: "Normal", options: []},
    {name: "Saturday", dataType: "float", required: false, label: "Saturday", options: []},
    {name: "Sunday", dataType: "float", required: false, label: "Sunday", options: []},
    {name: "Pph", dataType: "float", required: true, label: "Public Holiday", options: []},
];
export const stepContractAttributesTermsRateSpecific = [
    {name: "Site", dataType: "option", required: true, label: "Site", options: []},
    {name: "Designation", dataType: "option", required: true, label: "Designation", options: []},
    {name: "Slot", dataType: "option", required: true, label: "Shift", options: []},
    {
        name: "ChargeType", dataType: "option", required: true, label: "Charge", options: [
            {key: "employee", val: "Employee"},
            {key: "client", val: "Client"},
        ]
    },
    {name: "Normal", dataType: "float", required: true, label: "Normal", options: []},
    {name: "Saturday", dataType: "float", required: false, label: "Saturday", options: []},
    {name: "Sunday", dataType: "float", required: false, label: "Sunday", options: []},
    {name: "Pph", dataType: "float", required: true, label: "Public Holiday", options: []},
];

export const stepContractAttributesTermsShift = [
    {name: "Name", dataType: "string", required: true, label: "Shift Name", options: []},
    {
        name: "ShiftType", dataType: "option", required: true, label: "Type of Shift", options: [
            {key: "time", val: "Time based"},
            {key: "day", val: "Day based"},
        ]
    },
    {name: "Start", dataType: "time", required: true, label: "Shift Start Time", options: []},
    {name: "End", dataType: "time", required: true, label: "Shift End Time", options: []},
    {name: "Total", dataType: "float", required: true, label: "Total Hours per Shift", options: []},
];
export const stepContractAttributesTermsSiteDesignation = [
    {name: "Site", dataType: "option", required: true, label: "Site", options: []},
    {name: "Designation", dataType: "option", required: true, label: "Designation", options: []},
    {name: "Total", dataType: "float", required: true, label: "Total Employees", options: []},
];


/*

type TermsSiteDesignation struct {
	Id      string
	AppName string
	Org     string
	Module  string
	Ref     string

	Designation string
	Site        string
	Total       float64

	Profile     map[string]interface{}
	OrgDateTime string
	Date        string
	Time        string
	Status      string
}

type TermsShift struct {
	Id      string
	AppName string
	Org     string
	Module  string
	Ref     string

	Name      string
	ShiftType string  // day,time
	Start     string  //08:30
	End       string  // 17:50
	Total     float64 // 8 hours

	Profile     map[string]interface{}
	OrgDateTime string
	Date        string
	Time        string
	Status      string
}


type TermsPayCycle struct {
	Period    float64
	StartDate string
	EndDate   string
	PayDate   string
}
type TermsDesignation struct {
	Id      string
	AppName string
	Org     string
	Module  string
	Ref     string

	Name string

	Profile     map[string]interface{}
	OrgDateTime string
	Date        string
	Time        string
	Status      string
}
type TermsCondition struct {
	Id      string
	AppName string
	Org     string
	Module  string
	Ref     string

	PayCycle string //one week, two weeks,....
	RateType string // hourly,daily

	Profile     map[string]interface{}
	OrgDateTime string
	Date        string
	Time        string
	Status      string
}
type TermsRate struct {
	Id          string
	AppName     string
	Org         string
	Module      string
	Ref         string
	Designation string
	Slot        string
	ChargeType  string
	Normal      float64
	Saturday    float64
	Sunday      float64
	Pph         float64

	Profile     map[string]interface{}
	OrgDateTime string
	Date        string
	Time        string
	Status      string
}
type TermsSite struct {
	Id      string
	AppName string
	Org     string
	Module  string
	Ref     string

	Name                 string
	NumberOfUnit         float64
	NumberOfPeopleByRole map[string]float64
	TotalPeople          float64
	Lng                  float64
	Lat                  float64
	Angle                float64
	Address              string

	Profile     map[string]interface{}
	OrgDateTime string
	Date        string
	Time        string
	Status      string
}
     */
