
import React from "react";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import {Layout, Select, Input} from "antd";
import {GetUserToken} from "../../../../api/token";
import PluginAddDeleteList from "../../../../components/Common/PluginAddDeleteList2";
import {PostToPbx} from "../../../../api/http-post";
import {fetchDataEntity} from "../../../../api/entity-data";

const {TextArea} = Input;
const {Content} = Layout;
const {Option} = Select;

class SettingExtraConfigureAreaPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Projects: [],
            Modules:[],
            isLoading: false,
            company: "",
            component: "list",
            newEntry: {},
        }
    }

    componentDidMount = async () => {
        let userInfo = GetUserToken();
        await fetchDataEntity("Companies", "client/entity", "company", this)
        //await this.loadData2("PastelProject", "Projects",[/*{key:"org",val:userInfo.Org}*/])
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await this.loadData("modules", "Modules");

        //await this.loadData("PastelEmpAttribute", "VirtualCompanies");
    }
    loadData2 = async (targetEntity, stateKey,params) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.Entity = table;
        post.Data = {};
        post.Params = params;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            console.log("loaddata 2> ",data)
            await _this.setState({
                [stateKey]: data.RESULT
            })
        });
    }
    loadData = async (targetEntity, stateKey) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            await _this.setState({
                [stateKey]: data.RESULT
            })
        });
    }

    getModuleList = () => {
        let ls = [];
        for (let i in this.state.Modules) {
            let row = this.state.Modules[i];
            if (row.org === this.state.newEntry.org) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    getCompanyList = () => {
        let ls = [];
        for (let i in this.state.Companies) {
            let row = this.state.Companies[i];
            ls.push({
                key: row.CustomerNumber,
                val: row.Name,
            });
        }
        return ls;
    }

    getClientOption=()=>{
        let ls = [];
        for (let i in this.state.Clients) {
            let row = this.state.Clients[i];
            if (row.org === this.state.newEntry.selectedCompany) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    getProjectOption=()=>{
        let ls = [];
        console.log("all projects: ",this.state.Projects)
        for (let i in this.state.Projects) {
            let row = this.state.Projects[i];
            if (row.org === this.state.newEntry.selectedCompany) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    getDataTypeOption=()=>{
        return[
            {key: "text", val: "Text"},
            {key: "list", val: "List"},
            {key: "date", val: "Date"},
            {key: "yesno", val: "Yes / No"},
        ]
    }




    render() {
        const projectOptions = this.getProjectOption();
        const companyOptions = this.getCompanyList();

        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Setup Labour Area</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"PayrollVirtualArea"}
                            depending={{
                                "org": {
                                    targetKey: "CustomerNumber",
                                    displayKey: "Name",
                                    storageTable: "Companies"
                                }
                            }}
                            fields={[
                                {
                                    name: "org",
                                    dataType: "option",
                                    required: true,
                                    label: "Organization",
                                    options: companyOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Companies"}
                                },
                                {
                                    name: "project",
                                    dataType: "option",
                                    required: true,
                                    label: "Project",
                                    options: this.getModuleList(),
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Modules"}
                                },
                                {
                                    name: "name",
                                    dataType: "text",
                                    required: true,
                                    label: "Name",
                                    options: [],
                                    depending: {}
                                },

                            ]}
                            deleteKeys={[
                                {key: "org", type: "string"},
                                {key: "project", type: "string"},
                                {key: "name", type: "string"},
                            ]}
                            stateArray={"Data"}
                            table={"PayrollVirtualArea"}
                            addFormText={"Labour Area"}
                            stepTitle={"Setup new labour area"}

                            filterByCompany={true}



                        />

                    </div>
                </div>
            </Page>
        )
    }

}

export default Connector(SettingExtraConfigureAreaPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

