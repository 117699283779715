import {Button, Col, Form, Row, Select} from "antd";
import React from "react";
import {PostToPbx} from "../../../api/http-post";
import {fetchDataEntity} from "../../../api/entity-data";
import {SortData} from "../../../api/fn";
import moment from "moment";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import DayPunching from "../../../components/Reports/DayPunching";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Connector from "../../../redux/connector";

const {Option} = Select;

class DayClockingPunchingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Org: "",
            Companies: [],
            Projects: [],
            DataPayCycle: [],
            DataPeriod: [],
            DataSite: [],
            Data: [],
            isLoading: false,
            component: "list",
            initLoading: false,

            selectedCompany: "",
            selectedModule: "",
            selectedRecord: {},
            selectedPeriod: "",

            Results: null,
            showViewDetail: false,
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.initialReload()
    }
    initialReload = async () => {
        await fetchDataEntity("Projects", "", "", this, undefined, "common/entity/modules/list");
    }
    sampleData = () => {
        return ``
    }
    LoadContractInfo = async (selectedModule) => {
        let _this = this;
        let hub = {
            Org: this.state.selectedCompany,
            Module: "contract",
            Ref: selectedModule,
        }
        let endpoint = "/payroll/contract/fetch-data"
        await PostToPbx(hub, endpoint, async (output) => {
            const data = output.RESULT;
            let tmp = _this.state;
            tmp.DataPeriod = data.TermsPeriod;
            tmp.DataDesignation = data.TermsDesignation;
            tmp.DataPayCycle = data.TermsPayCycle;
            tmp.DataSite = data.TermsSite;
            tmp.DataRate = data.TermsRate;
            tmp.DataRateSpecific = data.TermsRateSpecific;
            tmp.DataSlot = data.TermsSlot;
            tmp.DataSiteDesignation = data.TermsSiteDesignation;
            tmp.DataShift = data.TermsShift;
            tmp.DataCondition = data.TermsCondition;
            _this.setState(tmp);
        });
    }
    HandleChangeCompany = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    HandleChange = async (e, key) => {
        const value = e.value;
        this.setState({
            [key]: value,
        });
        if (key === "selectedModule") {
            await this.LoadContractInfo(value)
        }
    }
    HandleChange2 = (e, ee, key) => {
        const val = e;
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
    }
    SubmitSearch = async () => {
        this.setState({
            isLoading: true,
            Results: null,
        });

        if (!this.ValidateSubmitSearch()) {
            return
        }
        const hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedModule,
        };

        console.log("1 -Data--> ", hub)
        let endpoint = "/wage/report/clock-summary"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            this.setState({
                Results: data,
                isLoading: false,
            });
            console.log("-search Data out --> ", JSON.stringify(data))
        });

    }
    onSubmitChange = async (infoIn) => {
        if (!window.confirm("Are you sure to submit changes?")) {
            return
        }
        let data = [];
        for (let i in infoIn) {
            let row = infoIn[i];
            row.org = this.state.selectedCompany;
            row.project = this.state.selectedModule;
            data.push(row);
        }
        const hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedModule,
            records: data
        };

        this.setState({
            isLoading: true,
        });

        let _this = this;

        console.log("1onSubmitChange send ", hub)
        let endpoint = "/clock/day-punching/new"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            this.setState({
                Results: data,
                isLoading: false,
            });
            console.log("onSubmitChange out --> ", JSON.stringify(data))
           await _this.SubmitSearch()
        });
    }
    ValidateSubmitSearch = () => {
        const state = this.state;
        if (state.selectedCompany === "") {
            alert("Validation fail! Please select your company")
            return false
        }
        if (state.selectedModule === "") {
            alert("Validation fail! Please select your project")
            return false
        }
        if (state.selectedPeriod === "") {
            alert("Validation fail! Please select your pay circle")
            return false
        }

        return true
    }
    formatDate = (dateIn) => {
        return moment(dateIn, "YYYY-MM-DD").format("DD MMM YYYY");
    }

    RenderCompaniesSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={""}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.HandleChangeCompany(value, "selectedCompany")}
                    value={this.state.selectedCompany}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    RenderProject = () => {
        let ls = [];
        let org = this.state.selectedCompany;
        for (let i in this.state.Projects) {
            const row = this.state.Projects[i];
            if (row.org === org) {
                ls.push({
                    key: row.name,
                    val: row.name,
                })
            }
        }
        return (
            <Select
                name={"selectedModule"}
                defaultValue=""
                onChange={(e, ee) => this.HandleChange(ee, "selectedModule")}
            >
                {ls.map((record, index) => {
                    return (
                        <Option value={record.key}>{record.val}</Option>
                    )
                })}
            </Select>
        )
    }

    RenderPayCycle = () => {
        let data = this.state.DataPayCycle;
        data = SortData("Period", data)
        let ls = [];
        for (let i in data) {
            let row = data[i];
            const key = row.StartDate + "#" + row.EndDate + "#" + row.Period + "#" + row.PayDate;
            const display = row.Period + ". -> Start: " + this.formatDate(row.StartDate) + " -> End: " + this.formatDate(row.EndDate) +
                " -> Pay date: " + this.formatDate(row.PayDate);
            ls.push({
                key: key,
                val: display
            })
        }
        return (
            <React.Fragment>
                <Form.Item label={"Pay Cycle"} required={true}>
                    <Select
                        name={"selectedPeriod"}
                        defaultValue={this.state.selectedPeriod}
                        value={this.state.selectedPeriod}
                        onChange={(e, ee) => this.HandleChange2(e, ee, "selectedPeriod")}
                    >
                        {ls.map((record, index) => {
                            return (
                                <Option value={record.key}>{record.val}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            </React.Fragment>
        )
    }

    render() {
        let props = this.props;
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                    minWidth: "100%",
                    maxWidth: "100%"
                }}>
                    <Title style={styles.title}>DAY PUNCHING</Title>
                    <Row gutter={16} style={{marginTop: 20}}>
                        <Col span={12}>
                            <Form.Item
                                label={"Company"}
                            >
                                {this.RenderCompaniesSelection()}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={"Project"}
                            >
                                {this.RenderProject()}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{marginTop: -40}}>
                        <Col span={24}>
                            {this.RenderPayCycle()}
                        </Col>
                    </Row>
                    <Row gutter={16} style={{marginTop: -10}}>
                        <Col span={24}>
                            <Button style={{minWidth: "100%"}} danger onClick={() => this.SubmitSearch()}>Search
                            </Button>&nbsp;&nbsp;
                        </Col>
                    </Row>

                    <DayPunching dataIn={this.state.Results} onSubmitChange={this.onSubmitChange}/>

                    {this.state.Results !== null ?
                        <DayPunching dataIn={this.state.Results}/> :
                        <div>Waiting for search result</div>
                    }
                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }

}

export default Connector(DayClockingPunchingPage);

const styles = {}
