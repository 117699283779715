import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import PluginAddDeleteList from "../../../components/Common/PluginAddDeleteList2";
import {fetchDataEntity} from "../../../api/entity-data";
import {

    getCompanyList,
    getYesNoOption,
    loadData
} from "./lib";


class QuoteLabourPlannedRatePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Modules: [],
            Clients: [],
            Projects:[],
            Items:[],
            Sites:[],
            isLoading: false,
            company: "",
            component: "list",
            newEntry: {},
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await loadData("modules", "Projects",this);
    }

    getProjectList = (data,org) => {
        let ls = [];
        for (let i in data) {
            let row = data[i];
            if (row.org === org) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    render() {
        const org = this.state.newEntry.selectedCompany;
        const companyOptions = getCompanyList(this.state.Companies);
        const projectOptions =this.getProjectList(this.state.Projects,org);

        console.log("KKKKDisplay Selected Project: ",this.state.newEntry.selectedCompany," > ",this.state);

        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Setup Designation Rate</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"QuoteLabourPlannedRate"}
                            depending={{
                                "org": {
                                    targetKey: "CustomerNumber",
                                    displayKey: "Name",
                                    storageTable: "Companies"
                                },
                                "project":{
                                    targetKey: "code",
                                    displayKey: "name",
                                    storageTable: "Projects"
                                }
                            }}
                            fields={[
                                {
                                    name: "org",
                                    dataType: "option",
                                    required: true,
                                    label: "Company",
                                    options: companyOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Companies"}
                                },
                                {
                                    name: "project",
                                    dataType: "option",
                                    required: true,
                                    label: "Project",
                                    options: projectOptions,
                                    depending: {stateKey: "project", displayKey: "Name", storageTable: "Projects"}
                                },

                                {
                                    name: "role",
                                    dataType: "option",
                                    required: true,
                                    label: "Designation",
                                    options: [
                                        {key:"General Worker",val:"General Worker"},
                                        {key:"Team Leader",val:"Team Leader"},
                                    ],
                                },
                                {
                                    name: "mon",
                                    dataType: "float",
                                    required: true,
                                    label: "Mon",
                                },
                                {
                                    name: "tue",
                                    dataType: "float",
                                    required: true,
                                    label: "Tue",
                                },
                                {
                                    name: "wed",
                                    dataType: "float",
                                    required: true,
                                    label: "Wed",
                                },
                                {
                                    name: "thu",
                                    dataType: "float",
                                    required: true,
                                    label: "Thu",
                                },
                                {
                                    name: "fri",
                                    dataType: "float",
                                    required: true,
                                    label: "Fri",
                                },
                                {
                                    name: "sat",
                                    dataType: "float",
                                    required: true,
                                    label: "Sat",
                                },
                                {
                                    name: "sun",
                                    dataType: "float",
                                    required: true,
                                    label: "Sun",
                                },
                                {
                                    name: "ph",
                                    dataType: "float",
                                    required: true,
                                    label: "Ph",
                                },


                            ]}
                            deleteKeys={[
                                {key: "org", type: "string"},
                                {key: "project", type: "string"},
                                {key: "role", type: "string"},

                            ]}
                            stateArray={"Data"}
                            table={"QuoteLabourPlannedRate"}
                            addFormText={"Quote Labour Site Listing"}
                            stepTitle={"Setup Labour Rate "}

                            filterByCompany={true}
                            displayRules={[
                                {rowKey:"org",stateKey:"selectedCompany"},
                                {rowKey:"project",stateKey:"selectedProject"},

                            ]}
                            extraFilters={[
                                {
                                    recordKey:"key",
                                    recordValue:"val",
                                    stateKey:"project",
                                    filterRecordKey:"project",
                                    filterStateKey:"selectedProject",
                                    allowBlank:true,
                                    name:"selectedProject",
                                    label:"Project",
                                    options: projectOptions,
                                    errorMsg: "Sorry you can't save, Please select your project!"
                                },
                            ]}

                            sortMe={"name"}

                        />

                    </div>
                </div>
            </Page>
        )
    }
}


export default Connector(QuoteLabourPlannedRatePage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

