import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import Title from "../../../../components/Common/Title";
import Page from "../../../../layout/web/Page";
import Connector from "../../../../redux/connector";
import {PostToWoodChipping} from "../../../../api/http-post";
import {
    BuildComboDataClient,
    BuildComboDataCompany, BuildComboDataProject,
    FetchConfigDataService, FilterArrayDataWithConditions, SubmitDeleteRecordCommon,
    SubmitNewChippingRate, SubmitNewChippingTarget
} from "../../../../services/service-woodchipping";
import { Col,  Form, Input, Row} from "antd";
import { UpCaseFirst} from "../../../../api/fn";
import ControllerSelectBox from "../../../../components/Common/ControllerInput/ControllerSelectBox";
import moment from "moment";
import GridItem from "../../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../../components/Common/tables/TableDataView";
import DrawerFrame from "../../../../components/General/DrawerFrame";


class WoodChippingSettingTargetPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            ConfigData: {},
            selectedCompany: "",
            selectedClient: "",
            selectedProject: "",
            InputLoad: "",
            InputCubic: "",
            InputMass:"",
            editMode: false,
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Data", "", "", this, undefined, "common/entity/WcTarget/list");
        await this.fetchConfigData();
    }

    initialLoad=async ()=>{
        await fetchDataEntity("Data", "", "", this, undefined, "common/entity/WcTarget/list");
    }
    fetchConfigData = async () => {
        await FetchConfigDataService((res) => {
            console.log("fetchConfigData > ",res)
            this.setState({
                ConfigData: res
            })
        })

    }
    handleChangeInput = async (key, value) => {
        this.setState({
            [key]: value,
        })
    }
    handleGetInputValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return ""
        }
        return this.state[key];
    }
    onAddRecord = () => {
        const alertMsg = (field) => {
            alert(` ${field} can't empty, please selection before adding`)

        }
        if (this.state.selectedCompany === "") {
            alertMsg("company")
            return
        } else if (this.state.selectedClient === "") {
            alertMsg("client")
            return
        }else if (this.state.selectedProject === "") {
            alertMsg("project")
            return
        }

        this.setState({
            editMode: true
        })
    }
    onCancelRecord = () => {
        this.setState({
            editMode: false
        })
    }


    submitSearch = async () => {
        alert("coming soon!")

    }
    validationInputSubmit = () => {
        const alertMsg = (field) => {
            alert(`Validation fail, ${field} can't empty`)
            return false
        }
        if (this.state.InputLoad === "") {
            return alertMsg("target load")
        } else if (this.state.InputMass=== "") {
            return alertMsg("target mass")
        } else if (this.state.InputCubic=== "") {
            return alertMsg("target cubic")
        }
        return true
    }
    onSubmit = async () => {
        if (!this.validationInputSubmit()) {
            return
        }
        await SubmitNewChippingTarget(
            {
                org: this.state.selectedCompany,
                client: this.state.selectedClient,
                project: this.state.selectedProject,
                load:parseFloat(this.state.InputLoad),
                mass: parseFloat( this.state.InputMass),
                cubic: parseFloat( this.state.InputCubic),
            },
            "WcTarget",
            async (res) => {
                console.log("Thank you, target submited> ",res)
                alert("Thank you, target submited")
                await this.fetchConfigData();
                await this.initialLoad();
                this.onCancelRecord()
            })
    }

    onDelete=async (record)=>{
        await SubmitDeleteRecordCommon(
            "WcTarget",
            record,
            [
                {key: "org", type: "string",val:record.org},
                {key: "client", type: "string",val:record.client},
                {key: "project", type: "string",val:record.project},
            ],
            `Are you sure to delete target of client: ${record.client} - Project: ${record.project}?`,
            "Thank you, record deleted!",
            this,
            this.initialLoad,
        )
    }

    renderAddRatePopOver = () => {
        if (!this.state.editMode) {
            return null
        }

        let inputs = [
            {label: "Monthly Load", stateField: "InputLoad"},
            {label: "Monthly Mass", stateField: "InputMass"},
            {label: "Monthly Cubic", stateField: "InputCubic"},
        ]

        return (
            <DrawerFrame
                title={"Add Wood Chipping Target"}
                visible={this.state.editMode}
                onClose={this.onCancelRecord}
                onSubmit={this.onSubmit}
            >
                {inputs.map(row => {
                    return (
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name={row.stateField}
                                    label={row.label}
                                    rules={[{required: true, message: 'Please enter ' + row.label}]}
                                >
                                    <Input
                                        placeholder={"Please enter user " + row.label}
                                        onChange={(e) => this.handleChangeInput(row.stateField, e.target.value)}
                                        value={this.handleGetInputValue(row.stateField)}
                                        defaultValue={this.handleGetInputValue(row.stateField)}
                                        required={row.mandatory}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                })}


            </DrawerFrame>
        )
    }
    renderList = () => {
        let columns = [
            {id: "client", label: "Client"},
            {id: "project", label: "Project"},
            {id: "load", label: "Load"},
            {id: "mass", label: "Mass"},
            {id: "cubic", label: "Cubic"},
        ];
        let storeData = FilterArrayDataWithConditions(
            this.state.Data,
            [
                {field:"org",value:this.state.selectedCompany},
                /*{field:"client",value:this.state.selectedClient},
                {field:"project",value:this.state.selectedProject},*/
            ])
        let data = {columns: columns, data: storeData};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Monthly target")}
                    onAddRecord={this.onAddRecord}
                    allowedAdd={true}
                    onDelete={this.onDelete}
                />
            </GridItem>
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Setup Wood Chipping Target</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <Row gutter={18}>
                            <Col span={12}>
                                <ControllerSelectBox
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={BuildComboDataCompany(this.state.ConfigData)}
                                    label={"Company"}
                                    name={"selectedCompany"}
                                />
                            </Col>
                            <Col span={12}>
                                <ControllerSelectBox
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={BuildComboDataClient(this.state.ConfigData, this.state.selectedCompany)}
                                    label={"Client"}
                                    name={"selectedClient"}
                                />
                            </Col>
                        </Row>

                        <Row gutter={18}>
                            <Col span={24}>
                                <ControllerSelectBox
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={BuildComboDataProject(
                                        this.state.ConfigData,
                                        this.state.selectedCompany,
                                        this.state.selectedClient
                                    )}
                                    label={"Project"}
                                    name={"selectedProject"}
                                />
                            </Col>

                        </Row>

                        <Row gutter={18}>
                            <Col span={24}>
                                {this.renderList()}
                            </Col>
                        </Row>

                        {this.renderAddRatePopOver()}

                    </div>
                </div>
            </Page>
        )
    }

}

export default Connector(WoodChippingSettingTargetPage);
const styles = {
    boxFilterInner: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 5,
        borderRight: "1px solid black",
        fontsize: 14,
        fontWeight: "bold"
    },
    boxFilter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    dateRow: {
        display: "flex",
        flexDirection: "row"
    },
    datePickerBox: {
        height: 40,
        width: "100%"
    },
    row1Th: {
        border: "1px solid black",
    },
    rowForm: {
        marginBottom: 10,
        paddingBottom: 5,
        border: "0px solid lightgray",
        borderBottomWidth: 1,

    },

    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}