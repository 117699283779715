import eConfig from "../configures";
import moment from "moment";
import {dropboxConfig} from "../configures/dropbox-config";
import Base64 from "./Base64";

export const UploadToDropBoxBase64WithBusket=async (base64String,busket,org,client,item,ref,fileName,feedback) =>{
    let imageData = _base64ToArrayBuffer(base64String);
    let key = dropboxConfig.DROPBOX_TOKEN;
    let date = moment().format("YYYY-MM-DD");
    console.log("RRRRFilename > ",fileName)
    let arrExt = fileName.split(".");
    let ext = arrExt[(arrExt.length-1)]
    let contentType = "application/octet-stream";
    if(ext.toLocaleLowerCase()==="pdf"){
        //contentType = "application/pdf";
    }
    let dropboxApiArg = {
        "path":`/applications/pmis/${busket}/${org}/${org}--${client}--${item}--${ref}--${date}.${ext}`,
        "mode": "add",
        "autorename": true,
        "mute": false
    }
    console.log("UUUUUUUUUU>> ",fileName, " > ",ext," > ",dropboxApiArg)
    let headers={
        "Authorization":"Bearer "+key,
        "Content-Type": contentType,
        "Dropbox-API-Arg":JSON.stringify(dropboxApiArg)
    }
    return fetch("https://content.dropboxapi.com/2/files/upload", {
        method: "POST",
        headers:headers,
        body: imageData
    })
        .then(response =>response.json())
        .then(response => {
            const endpoint ="/files/download/"+response.rev;
            let server = dropboxConfig.server+"/"+dropboxConfig.serverBackend;
            let url = server + "" +endpoint
            feedback(url);
            return url
        })
        .catch(error => {
            console.log("upload error", error);
            alert("Upload failed!");
        });
}

function _base64ToArrayBuffer(base64) {
    base64 = base64.split(';base64,')[1];
    let binary_string = Base64.atob(base64),
        len = binary_string.length,
        bytes = new Uint8Array(len),
        i;
    for (i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}