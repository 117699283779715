import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {Alert, Button, Col, Form, Layout, Row, Select} from "antd";
import {fetchDataEntity} from "../../../api/entity-data";
import {SortData, UpCaseFirst,} from "../../../api/fn";
import {PostToPbx} from "../../../api/http-post";
import moment from "moment";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import DisplayDataReportSummaryHours from "../../../components/Reports/DisplayDataReportSummaryHours";
import Configures from "../../../configures";
import {GetConfigures} from "../../../configures/serve";
import {GetUserToken} from "../../../api/token";
import {loadData} from "../invoices/SetupLabourInvoice/lib";
import TableDataView from "../../../components/Common/tables/TableDataView";

const {Content} = Layout;
const {Option} = Select;

function convertDateFormat_DDMMYYYY(dateIn) {
    let mydate = moment(dateIn, "YYYY-MM-DD").format("DD MMM YYYY");
    return mydate;
}

function formatCurrency(stringIn) {
    return stringIn.toFixed(2)
}



class PayrollSupportingDocumentUFillingPage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            Org: "",
            Companies: [],
            Entities: [],
            FnbCsvInfo:[],

            selectedCompany: "",
            selectedModule: "",
            selectedRecord: {},
            selectedPeriod: "",
            selectedSite: "",

            Data: [],
            isLoading: false,
            component: "list",
            initLoading: false,

            DataPeriod: [],
            DataPayCycle:[],
            DataSite:[],

            Results: null,
            showViewDetail: false,
        }

    }
    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.initialReload()
    }
    initialReload = async () => {
        await fetchDataEntity("Entities", "", "", this, undefined, "common/entity/modules/list");
        await this.loadData("PayrollBankingCsvFnb","FnbCsvInfo")
    }
    loadContractInfo = async (selectedModule) => {
        let _this = this;
        let module = this.state.selectedModule;
        let hub = {
            Org: this.state.selectedCompany,
            Module: "contract",
            Ref: selectedModule,
        }

        console.log("loadContractInfo > ",hub)
        let endpoint = "/payroll/contract/fetch-data"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            let tmp = _this.state;
            tmp.DataPeriod = data.TermsPeriod;
            tmp.DataDesignation = data.TermsDesignation;
            tmp.DataPayCycle = data.TermsPayCycle;
            tmp.DataSite = data.TermsSite;
            tmp.DataRate = data.TermsRate;
            tmp.DataRateSpecific = data.TermsRateSpecific;
            tmp.DataSlot = data.TermsSlot;
            tmp.DataSiteDesignation = data.TermsSiteDesignation;
            tmp.DataShift = data.TermsShift;
            tmp.DataCondition = data.TermsCondition;
            _this.setState(tmp);

            console.log("loadInitialData > ", output, " > ", data)
        });
    }
    loadData = async (targetEntity, stateKey) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            await _this.setState({
                [stateKey]: data.RESULT
            })
        });
    }
    handleChangeCompany = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    handleChange = async (e, key) => {
        const value = e.value;
        this.setState({
            [key]: value,
        });

        if (key === "selectedModule") {
            console.log("selected module > ", key, value)
            await this.loadContractInfo(value)
        }

    }
    handleChange2 = (e, ee, key) => {
        const val = e;
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
    }
    validateSubmitSearch=()=>{
        const state = this.state;
        if(state.selectedCompany===""){
            alert("Validation fail! Please select your company")
            return false
        }
        if(state.selectedModule===""){
            alert("Validation fail! Please select your project")
            return false
        }
        if(state.selectedPeriod===""){
            alert("Validation fail! Please select your pay circle")
            return false
        }

        return true
    }
    submitSearch = async () => {
        this.setState({
            isLoading: true,
            Results: null,
        });

        if(!this.validateSubmitSearch()){
            return
        }

        let hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedModule,
        }

        console.log("1 -Data--> ", hub)
        let endpoint = "/wage/calculation/hourly"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            this.setState({
                Results: data,
                isLoading: false,
            });
            console.log("-search Data--> ", data)
        });

    }
    getPayDate=()=>{
        let arr =  this.state.selectedPeriod.split("#");
        return arr[3]
    }
    renderWarningBar = () => {
        return
        let boo = false
        if (this.state.selectedCompany === "" || this.state.selectedEntity === "" || this.state.component === "form") {
            boo = true
        }
        if (!boo) {
            return
        }
        return (
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Alert style={{margin: '16px 0'}} message="Please select your company above to view your records!"
                       type={"error"}/>
            </div>
        )
    }
    renderCompaniesSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={""}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedCompany")}
                    value={this.state.selectedCompany}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderModules = () => {
        let ls = [];
        let org = this.state.selectedCompany;
        for (let i in this.state.Entities) {
            const row = this.state.Entities[i];

            if (row.org === org) {
                ls.push({
                    key: row.name,
                    val: row.name,
                })
            }
        }
        return (
            <Select
                name={"selectedModule"}
                defaultValue=""
                onChange={(e, ee) => this.handleChange(ee, "selectedModule")}
            >
                {ls.map((record, index) => {
                    return (
                        <Option value={record.key}>{record.val}</Option>
                    )
                })}
            </Select>
        )
    }
    renderSite = () => {
        let ls = [];
        for (let i in this.state.DataSite) {
            let row = this.state.DataSite[i];
            ls.push({
                key: row.Name,
                val: row.Name
            })
        }
        return (
            <React.Fragment>
                <Form.Item label={"Site"} required={true}>
                    <Select
                        name={"selectedSite"}
                        defaultValue={this.state.selectedSite}
                        value={this.state.selectedSite}
                        onChange={(e, ee) => this.handleChange2(e, ee, "selectedSite")}
                    >
                        {ls.map((record, index) => {
                            return (
                                <Option value={record.key}>{record.val}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            </React.Fragment>
        )
    }
    formatDate = (dateIn) => {
        return convertDateFormat_DDMMYYYY(dateIn)
    }
    renderPayCycle = () => {
        let data = this.state.DataPayCycle;
        data = SortData("Period", data)
        let ls = [];
        for (let i in data) {
            let row = data[i];
            const key = row.StartDate + "#" + row.EndDate + "#" + row.Period+"#"+row.PayDate;
            const display = row.Period + ". -> Start: " + this.formatDate(row.StartDate) + " -> End: " + this.formatDate(row.EndDate) +
                " -> Pay date: " + this.formatDate(row.PayDate);
            ls.push({
                key: key,
                val: display
            })
        }
        return (
            <React.Fragment>
                <Form.Item label={"Pay Cycle"} required={true}>
                    <Select
                        name={"selectedPeriod"}
                        defaultValue={this.state.selectedPeriod}
                        value={this.state.selectedPeriod}
                        onChange={(e, ee) => this.handleChange2(e, ee, "selectedPeriod")}
                    >
                        {ls.map((record, index) => {
                            return (
                                <Option value={record.key}>{record.val}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            </React.Fragment>
        )
    }

    RenderResult=()=>{
        console.log("RenderResult > ",this.state.Results)
        let columns = [
            {id: "EmpName", label: "EMPLOYEE NAME & SURNAME"},
            {id: "EmpIdNumber", label: "ID NUMBER"},
            {id: "GrossPay", label: "GROSS SALARY"},
            {id: "EmpContractStart", label: "START DATE"},
            {id: "TerminationDate", label: "END DATE"},
            {id: "TerminationReason", label: "TERMINATION REASON"},
        ]
        let ls=[]
        let infos = this.state.Results.Summary
        for(let i in infos){
            let row = infos[i];
            if(row.GrossPay ===0){
                continue
            }
            row.GrossPay  = row.GrossPay.toFixed(2)
            if(row.TerminationDate===""){
                row.TerminationDate="N/A"
            }else{
                row.TerminationDate=moment(row.TerminationDate,"YYYY-MM-DD").format("YYYY/MM/DD")
            }
            if(row.TerminationReason===""){
                row.TerminationReason="N/A"
            }
            //03/09/2021

            if(row.EmpContractStart.includes("/")){
                row.EmpContractStart = moment(row.EmpContractStart,"DD/MM/YYYY").format("YYYY/MM/DD")
            }else{
                row.EmpContractStart = moment(row.EmpContractStart,"YYYY-MM-DD").format("YYYY/MM/DD")
            }


            ls.push(row)
        }

        /*
        TerminationReason string
			TerminationCode   string
			TerminationDate   string
         */
        let label = moment(this.getPayDate(),"YYYY-MM-DD").format("MMMM-YYYY")
        let data = {columns: columns, data: ls};
        return(
            <div>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst(label)}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                    allowDelete={typeof allowedDelete!=="undefined"}
                    allowedAdd={false}
                    otherFunctions={[]}
                />
            </div>
        )
    }
    render() {
        let props = this.props;
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>UFilling</Title>
                    <Row gutter={16} style={{marginTop: 20}}>
                        <Col span={12}>
                            <Form.Item
                                label={"Organisation"}
                            >
                                {this.renderCompaniesSelection()}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={"Project"}
                            >
                                {this.renderModules()}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{marginTop: -40}}>
                        <Col span={24}>
                            {this.renderPayCycle()}
                        </Col>
                    </Row>
                    <Row gutter={16} style={{marginTop: -10}}>
                        <Col span={24}>
                            <Button style={{minWidth:"100%"}} danger onClick={() => this.submitSearch()}>Search
                            </Button>&nbsp;&nbsp;
                        </Col>
                    </Row>
                    {this.state.Results !== null ?
                        <Row gutter={24} style={{marginTop: 40}}>
                            <Col span={24}>
                            <this.RenderResult />
                            </Col>
                        </Row> : null
                    }
                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }
}


export default Connector(PayrollSupportingDocumentUFillingPage);

const styles={

}
