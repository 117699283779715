import React, {useState} from "react";
import "./index.css";
import { Radio } from 'antd';
import moment from "moment";

const SwitchPeriodInvoice2=({year,onChangePeriod,handleInput,mode})=>{
    const [value, setValue] = React.useState(1);
    const [selectPeriod,setSelectedPeriod]=useState(parseInt(moment().format("MM")));
    let listMonths=[
        {label:"JAN",period:1,selected:false},
        {label:"FEB",period:2,selected:false},
        {label:"MAR",period:3,selected:false},
        {label:"APR",period:4,selected:false},
        {label:"MAY",period:5,selected:false},
        {label:"JUN",period:6,selected:false},
        {label:"JUL",period:7,selected:false},
        {label:"AUG",period:8,selected:false},
        {label:"SEP",period:9,selected:false},
        {label:"OCT",period:10,selected:false},
        {label:"NOV",period:11,selected:false},
        {label:"DEC",period:12,selected:false},
    ]
    const onChange = e => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
        onChangePeriod(e.target.value);
    };
    const onChangeMode = e => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
        handleInput(e.target.value,"mode");
    };
    const onChangeYear=(e)=>{
        let value = e.target.value;
        handleInput(value,"targetYear")
    }
    const getYearList=()=>{
        return [
            "2021",
            "2022"
        ]
    }
    const innerOnchangePeriod=(value)=>{
        onChangePeriod(value);
        setSelectedPeriod(value)
    }
    const renderPeriod=()=>{
        return listMonths.map(row=>{
            let extraLabel={};
            let extraButton={};
            if(row.period===selectPeriod){
                extraLabel={color:"red",fontWeight:"bold"};
                extraButton={backgroundColor:"cornflowerblue"}
            }
            return(
                <div className={"spiPeriodContentBox"}>
                    <div className={"spiPeriodContentBoxLabel"} style={{...extraLabel}} onClick={()=>innerOnchangePeriod(row.period)}>
                        {row.label}
                    </div>
                    <div
                        className={"spiPeriodContentBoxButton"}
                        style={{...extraButton}}
                        onClick={()=>innerOnchangePeriod(row.period)}>
                        &nbsp;
                    </div>
                </div>
            )
        })
    }
    return(
        <div className={"spiContainer"}>
            <div className={"spiInvoiceDate"}>
                <div className={"spiInvoiceDateLabel"}>EXTRA FILTERING BY INVOICE DATE</div>
                <div className={"spiInvoiceDateSwitch"}>
                    <Radio.Group onChange={onChangeMode} value={mode}>
                        <Radio value={"month"}>Month</Radio>
                        <Radio value={"finance-year"}>Financial Year</Radio>
                        <Radio value={"calendar-year"}>Calendar Year</Radio>
                        <Radio value={"all"}>All</Radio>
                    </Radio.Group>
                </div>
            </div>

            <div className={"spiYearBox"}>
                <select onChange={(e)=>onChangeYear(e)}>
                    {getYearList().map(item=>{
                        let selected=false;
                        if(item===year){
                            selected=true;
                        }
                        return(
                            <option value={item} selected={selected}>{item}</option>
                        )
                    })}
                </select>

                <div className={"spiPeriodBox"}>
                    {renderPeriod()}
                </div>

            </div>

        </div>
    )
}

export default SwitchPeriodInvoice2;