import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import ControllerSelectBox from "../../../../components/Common/ControllerInput/ControllerSelectBox";
import {
    ArrayToSelectOption,
    FilterArrayByKey,
    FormatMoneySouthAfrica,
    FormatMoneySouthAfrica2,
    UpcaseFirst
} from "../../../../api/fn";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import {PostToPbx} from "../../../../api/http-post";
import {Button, Col, DatePicker, Drawer, Input, Row} from "antd";
import moment from "moment";
import './styles.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCoffee, faList, faAngleDown, faCartArrowDown, faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons'


class ServiceLogsFormPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Sites: [],
            Clients: [],
            Items: [],
            Vehicles: [],
            Drivers: [],

            selectedCompany: "",
            selectedClient: "",
            selectedItem: "yes",
            targetDate:"",// moment().format("YYYY-MM-DD"),
            inputs: {}
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Projects", "", "", this, undefined, "common/entity/modules/list");
        await fetchDataEntity("Data", "", "", this, undefined, "common/entity/ServiceLogsDeliveryNote/list");
        await fetchDataEntity("Sites", "", "", this, undefined, "common/entity/SettingInvoiceServiceAddonSite/list");
        await fetchDataEntity("Clients", "", "", this, undefined, "common/entity/SettingInvoiceServiceDebtors/list");
        await fetchDataEntity("Items", "", "", this, undefined, "common/entity/SettingInvoiceServiceItem/list");
        await fetchDataEntity("Vehicles", "", "", this, undefined, "common/entity/ServiceLogsVehicle/list");
        await fetchDataEntity("Drivers", "", "", this, undefined, "common/entity/ServiceLogsDriver/list");

    }
    handleChangeInput = async (key, value) => {
        this.setState({
            [key]: value,
        })
    }
    handleGetInputValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return ""
        }
        return this.state[key];
    }
    submitLogs=async ()=>{
        let state = this .state;
        let inputs  =state.inputs;
        let org = state.selectedCompany;
        let client = state.selectedClient;
        let item = state.selectedItem;
        let targetDate =state.targetDate;
        let recordPending=[]
        let records = []
        let x=0;
        for(let i in inputs){
            let row = inputs[i];
            x++
            let site = i;
            let vehicle = "";
            let units = 0;
            let note ="";

            let vehicle2 = "";
            let units2 = 0;
            let note2 ="";

            let vehicle3 = "";
            let units3 = 0;
            let note3 ="";


            if(typeof row["vehicle"]!=="undefined"){
                vehicle =row["vehicle"];
            }
            if(typeof row["units"]!=="undefined"){
                units =parseInt(row["units"]);
            }
            if(typeof row["note"]!=="undefined"){
                note =row["note"];
            }


            if(typeof row["vehicle2"]!=="undefined"){
                vehicle2 =row["vehicle2"];
            }
            if(typeof row["units2"]!=="undefined"){
                units2 =parseInt(row["units2"]);
            }
            if(typeof row["note2"]!=="undefined"){
                note2 =row["note2"];
            }


            if(typeof row["vehicle3"]!=="undefined"){
                vehicle3 =row["vehicle3"];
            }
            if(typeof row["units3"]!=="undefined"){
                units3 =parseInt(row["units3"]);
            }
            if(typeof row["note3"]!=="undefined"){
                note3 =row["note3"];
            }


            /**
             * let check maybe the use start and decide to empty record
             */
            if(vehicle===""&& units===0 && note===""){
                continue
            }

            /**
             * let check for site validation pending
             * missing any of mandatory field after start
             */
            let boo = true
            let missing=[]
            if(vehicle===""){
                boo=false;
                missing.push("Vehicle")
            }
            if(units===0){
                boo=false;
                missing.push("Units")
            }
            if(note===""){
                boo=false;
                missing.push("Delivery Note")
            }
            if(!boo){
                recordPending.push({
                    site,
                    position:x,
                    fields:missing
                })
                continue
            }



            records.push({
                position:x,
                site:site,
                vehicle,
                units,
                note,

                vehicle2,
                units2,
                note2,

                vehicle3,
                units3,
                note3,

                org,
                client,
                item,
                targetDate
            })

        }

        if(recordPending.length>0){
            let msg = `Sorry please complete following site missing 
            mandatory field\n`;
            for(let i in recordPending){
                let row=recordPending[i];
                msg +=`\n> ${row.position}. ${row.site} \n`;
                for(let a in row.fields){
                    let field = row.fields[a];
                    msg +=`  -${field}\n`;
                }
            }
            alert(msg)
            return
        }else if(records.length===0){
            alert("Sorry you did not complete any site to submit!")
            return
        }

        if(!window.confirm(`Are you sure to submit logs for ${records.length} site(s)?`)){
            return
        }

        let postData = {
            Infos: records,
        }
        console.log("submitLogs send > ", postData)
        let endpoint = "/services/logs/bulk-insert"
        await PostToPbx(postData, endpoint, async (output) => {
            if(output===null){
                alert("Sorry something went wrong from backend, please try again!")
                return
            }
            let data = output.RESULT;
            if(data==="OK"){
                alert("thank you, your logs have been submitted successful ")
                window.location.reload()
            }else{
                alert(data)
            }
        });
    }

    filterStateData = (data, options) => {
        let ls = [];
        for (let i in data) {
            let row = data[i];
            let valid = true;
            for (let a in options) {
                let checkKey = a;
                let checkValue = options[a];
                if (typeof row[checkKey] === "undefined") {
                    valid = false
                } else if (row[checkKey] !== checkValue) {
                    valid = false
                }
            }
            if (!valid) {
                continue
            }
            ls.push(row)
        }
        return ls
    }
    setInputValue = (site, fieldName, value) => {
        const inputs = this.state.inputs;
        if (typeof inputs[site] === "undefined") {
            inputs[site] = {}
        }
        if (typeof inputs[site][fieldName] === "undefined") {
            inputs[site][fieldName] = ""
        }
        inputs[site][fieldName] = value
        this.setState({inputs})
    }
    getInputValue = (site, fieldName) => {
        const inputs = this.state.inputs;
        let value = "";
        if (typeof inputs[site] !== "undefined") {
            if (typeof inputs[site][fieldName] !== "undefined") {
                value = inputs[site][fieldName]
            }
        }
        return value
    }
    buildSelectionOption = (site, fieldName, data, key, value) => {
        let selectedValue = this.getInputValue(site, fieldName)

        return (
            <select
                className={"ant-input"}
                onChange={(e) => this.setInputValue(site, fieldName, e.target.value)}
            >
                <option></option>
                {data.map(row => {
                    let selected = false;
                    if (selectedValue === row[key]) {
                        selected = true
                    }
                    return (
                        <option value={row[key]} selected={selected}>{row[value]}</option>
                    )
                })
                }
            </select>
        )
    }
    renderCompanyBox = () => {
        let ls = [];
        ls = FilterArrayByKey(this.state.Companies, {})
        ls = ArrayToSelectOption(ls, ["CustomerNumber"], ["Name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Company"}
                name={"selectedCompany"}
            />
        )
    }
    renderClientBox = () => {
        let ls = [];
        for (let i in this.state.Clients) {
            let row = this.state.Clients[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            ls.push(row)
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["name"], ["name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Client"}
                name={"selectedClient"}
            />
        )
    }
    renderItemBox = () => {
        let ls = [];
        for (let i in this.state.Items) {
            let row = this.state.Items[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.client !== this.state.selectedClient) {
                continue
            }
            ls.push(row)
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["name"], ["name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Items"}
                name={"selectedItem"}
            />
        )
    }
    renderFailValidation = () => {
        let state = this.state;

        return (
            <div>
                <h1>Please select all required field before continue:</h1>
                {state.selectedCompany===""&&<p>- Company</p>}
                {state.selectedClient===""&&<p>- Client</p>}
                {state.selectedItem===""&&<p>- Item</p>}
                {state.targetDate===""&&<p>- Target Date</p>}
            </div>
        )
    }


    renderForm = () => {
        let state = this.state;
        if (state.selectedCompany === "") {
            return this.renderFailValidation()
        }
        if (state.selectedClient === "") {
            return this.renderFailValidation()
        }
        if (state.selectedItem === "") {
            return this.renderFailValidation()
        }
        if (state.targetDate === "") {
            return this.renderFailValidation()
        }

        /**
         * let filter out sites
         */
        let targetSites = this.filterStateData(state.Sites, {
            org: state.selectedCompany,
            client: state.selectedClient,
            item: state.selectedItem
        })
        let targetVehicles = this.filterStateData(state.Vehicles, {
            org: state.selectedCompany,
        })
        let targetDrivers = this.filterStateData(state.Drivers, {
            org: state.selectedCompany,
        })

        console.log("HHHHHtargetVehicles> ", targetVehicles)

        return (
            <div>
                <Row>
                    <Col span={24} style={styles.col}>
                        <button
                            className={"ant-input"}
                            onClick={this.submitLogs}
                            style={{
                                backgroundColor:"#1890ff",
                                color:"white"
                        }}
                        >
                            Submit
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col span={4} style={styles.col}>
                        Site
                    </Col>

                    <Col span={2}  style={styles.colBorderLeft}>
                        1 Vehicle
                    </Col>
                    <Col span={2}  style={styles.col}>
                        1 Units
                    </Col>
                    <Col span={2}  style={styles.colBorderRight}>
                        1 D. Note
                    </Col>


                    <Col span={2}  style={styles.col}>
                        2 Vehicle
                    </Col>
                    <Col span={2}  style={styles.col}>
                        2 Units
                    </Col>
                    <Col span={2}  style={styles.colBorderRight}>
                        2 D. Note
                    </Col>


                    <Col span={2}  style={styles.col}>
                        3 Vehicle
                    </Col>
                    <Col span={2}  style={styles.col}>
                        3 Units
                    </Col>
                    <Col span={2}  style={styles.colBorderRight}>
                        3 D. Note
                    </Col>
                </Row>
                {targetSites.map((row, index) => {
                    return (
                        <Row>
                            <Col span={4}  style={styles.col}>
                                {index+1}.&nbsp;
                                <input
                                    className={"ant-input"}
                                    value={row.site}
                                    disabled={true}
                                    />

                            </Col>


                            <Col span={2}  style={styles.colBorderLeft}>
                                {this.buildSelectionOption(row.site, "vehicle", targetVehicles, "regnumber", "regnumber")}
                            </Col>
                            <Col span={2}  style={styles.col}>
                                <input
                                    value={this.getInputValue(row.site,"units")}
                                    className={"ant-input"}
                                    onChange={(e)=>this.setInputValue(row.site,"units",e.target.value)}
                                />
                            </Col>
                            <Col span={2}  style={styles.colBorderRight}>
                                <input
                                    className={"ant-input"}
                                    value={this.getInputValue(row.site,"note")}
                                    onChange={(e)=>this.setInputValue(row.site,"note",e.target.value)}
                                />
                            </Col>


                            <Col span={2}  style={styles.col}>
                                {this.buildSelectionOption(row.site, "vehicle2", targetVehicles, "regnumber", "regnumber")}
                            </Col>
                            <Col span={2}  style={styles.col}>
                                <input
                                    value={this.getInputValue(row.site,"units2")}
                                    className={"ant-input"}
                                    onChange={(e)=>this.setInputValue(row.site,"units2",e.target.value)}
                                />
                            </Col>
                            <Col span={2}  style={styles.colBorderRight}>
                                <input
                                    className={"ant-input"}
                                    value={this.getInputValue(row.site,"note2")}
                                    onChange={(e)=>this.setInputValue(row.site,"note2",e.target.value)}
                                />
                            </Col>


                            <Col span={2}  style={styles.col}>
                                {this.buildSelectionOption(row.site, "vehicle3", targetVehicles, "regnumber", "regnumber")}
                            </Col>
                            <Col span={2}  style={styles.col}>
                                <input
                                    value={this.getInputValue(row.site,"units3")}
                                    className={"ant-input"}
                                    onChange={(e)=>this.setInputValue(row.site,"units3",e.target.value)}
                                />
                            </Col>
                            <Col span={2}  style={styles.colBorderRight}>
                                <input
                                    className={"ant-input"}
                                    value={this.getInputValue(row.site,"note3")}
                                    onChange={(e)=>this.setInputValue(row.site,"note3",e.target.value)}
                                />
                            </Col>

                        </Row>
                    )
                })}
            </div>
        )

    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Service Logs Entry Form</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <Row gutter={18}>
                            <Col span={12}>
                                {this.renderCompanyBox()}
                            </Col>
                            <Col span={12}>
                                {this.renderClientBox()}
                            </Col>
                        </Row>
                        <Row gutter={18} style={{marginTop: -30}}>
                            <Col span={12}>
                                {this.renderItemBox()}
                            </Col>
                            <Col span={12} style={styles.dateRow}>
                                <Col span={20} style={{marginTop: 30, height: 40}}>
                                    <DatePicker
                                        style={styles.datePickerBox}
                                        onChange={(date, dateString) => this.setState({targetDate: dateString})}
                                        placeholder={"Target Date"}
                                    />
                                </Col>
                                <Col span={4} style={{marginTop: 30, height: 40}}>
                                    <Button
                                        style={styles.datePickerBox}
                                        onClick={() => this.submitSearch()}
                                    >
                                        Search
                                    </Button>
                                </Col>
                            </Col>
                        </Row>
                    </div>
                    <div style={{
                        borderRadius: 5,
                        borderWidth: 1,
                        borderColor: "lightblue",
                        borderStyle: 'solid',
                        minHeight: 35,
                        padding: 5
                    }}>
                        {this.renderForm()}
                    </div>
                </div>
            </Page>
        )
    }

}

export default Connector(ServiceLogsFormPage);
const styles = {
    colBorderLeft:{
        margin:3,
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-between",
        borderLeftWidth:3,
        borderLeftColor: "lightblue",
        borderLeftStyle:"solid",
        paddingLeft: 5,
    },
    colBorderRight:{
        margin:3,
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-between",
        borderRightWidth:3,
        borderRightColor: "lightblue",
        borderRightStyle:"solid",
        paddingRight: 5,
    },
    col:{
      margin:3,
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-between"
    },
    boxFilterInner: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 5,
        borderRight: "1px solid black",
        fontsize: 14,
        fontWeight: "bold"
    },
    boxFilter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    dateRow: {
        display: "flex",
        flexDirection: "row"
    },
    datePickerBox: {
        height: 40,
        width: "100%"
    },
    row1Th: {
        border: "1px solid black",
    },
    rowForm: {
        marginBottom: 10,
        paddingBottom: 5,
        border: "0px solid lightgray",
        borderBottomWidth: 1,

    },
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}