import React from "react";
import {fetchDataEntity} from "../../../api/entity-data";
import {
    BuilderOptionComboBox,
    CheckConditions,
    FormatMoneySouthAfrica,
    LoadDataCommon,
    SortData
} from "../../../api/fn";
import moment from "moment";
import Title from "../../../components/Common/Title";
import {Button, Col, DatePicker, Divider, Row, Table} from "antd";
import Page from "../../../layout/web/Page";
import Connector from "../../../redux/connector";
import {PostToPbx} from "../../../api/http-post";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import LabourInvoiceAdhoc from "../../../components/Invoicings/LabourInvoice/LabourInvoiceAdhoc";

class QuoteGenerateKeyPersonnelPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Clients: [],
            Projects: [],
            Data: [],
            isLoading: false,
            newEntry: {
                Year: moment().year()
            },
            selectedCompany: "",
            selectedClient: "",
            selectedProject: "",
            selectedStartDate: "",
            selectedEndDate: "",
            selectedQuoteDate:"",
            Result: null,
        }
    }
    componentDidMount = async () => {
        await this.loadInitialData();
    }
    loadInitialData = async ()=>{
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await LoadDataCommon("modules", "Projects", this);
        await LoadDataCommon("SettingInvoiceServiceDebtors", "Clients", this);
    }

    handleInput = (val, key) => {
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp)
    }

    handleInputPeriod = (val, key) => {
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp)
    }
    submitGenerateQuote=async ()=>{
        let _this =this;
        let state = this.state;

        let post={
            Org           :state.selectedCompany,
            Ref       :state.selectedProject,
            StartDate    :state.selectedStartDate,
            EndDate   :state.selectedEndDate,
            QuoteDate:state.selectedQuoteDate,
        }

        let endpoint = "/quote/generate/key-personnel";
        this.setState({
            isLoading: true,
        });
        console.log("submitGenerateQuote adhoc send > ",post);
        await PostToPbx(post, endpoint, async (data) => {
            console.log("submitGenerateQuote response > ",data);
            _this.setState({isLoading: false,Result:data.RESULT});
        });
    }

    onSaveQuotation=async ()=>{
        let _this = this;
        if(!window.confirm("Do you really want to save this quote?")){
            return
        }
        let data = this.state.Result;
        data.CustomerName = this.state.selectedClient;
        data.Category = "labour"
        data.TypeOfQuote = "normal"

        console.log(":) Save quote request approved! ",data)

        let endpoint=`/crud/QuoteTracker/insert`;
        let postData= {
            Org:this.state.Result.Org,
            Data: data
        }
        await PostToPbx(postData, endpoint, async (data) => {
            console.log("submitGenerateQuote response > ",data);
            alert("Thank you , your quote have been saved")
        });

    }
    renderIFrameInvoice=()=>{
        let info = this.state.Result;
        return (
            <iframe
                src={info.LinkDownload}
                title="Quote-Labour"
                style={{minWidth:"100%",minHeight:1024,background: "#FFFFFF"}}
                frameborder="0"

            >
                Presss me: <a href={info.LinkDownload}>Download PDF</a>
            </iframe>
        )
    }

    renderResult=()=>{
        const toFix2=(inValue)=>{
            let val = inValue.toFixed(2)
            return parseFloat(val)
        }
        const formatDate=(dateIn)=>{
            return moment(dateIn,"YYYY-MM-DD").format("DD MMM YYYY")
        }
        const tableHeadJson = [
            {field:'Action',label:'#',align:"center"},
            {field:'Period',label:'Period',align:"left"},
            {field:'QuoteNumber',label:'Quote Number',align:"left"},

            {field:'Subtotal',label:'Subtotal',align:"right"},
            {field:'Vat',label:'Vat',align:"right"},
            {field:'Total',label:'Total',align:"right"},
        ]
        let columns=[];
        for(let i  in tableHeadJson){
            let row = tableHeadJson[i];
            columns.push({
                title: row.label,
                dataIndex: row.field,
                align:row.align,
            });
        }
        let info = this.state.Result;

        let ls =[
            {
                Action:<>
                    <a onClick={()=>this.onSaveQuotation()}>Save Tracker</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                </>,
                Period:formatDate(info.PeriodStart)+" To "+formatDate(info.PeriodEnd),
                QuoteNumber:info.QuoteNumber,
                Subtotal: "R "+FormatMoneySouthAfrica(toFix2(info.AmountExVat)),
                Vat:"R "+FormatMoneySouthAfrica(toFix2(info.AmountVat)),
                Total:"R "+FormatMoneySouthAfrica(toFix2(info.AmountTotal)),
            }
        ]

        return(
            <Table
                columns={columns}
                dataSource={ls}
                bordered
            />
        )
    }
    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Generate Quote Key Personnel</Title>

                    <Divider style={{color: "red"}}>Filter</Divider>
                    <BuilderOptionComboBox
                        key={"cbCompany"}
                        data={this.state.Companies}
                        keyVal={"CustomerNumber"}
                        keyLabel={"Name"}
                        label={"Select Organization"}
                        conditions={[]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedCompany"}
                        currentValue={this.state.selectedCompany}
                        comboId={"company"}
                    />
                    <BuilderOptionComboBox
                        key={"cbClient"}
                        data={this.state.Clients}
                        keyVal={"mame"}
                        keyLabel={"name"}
                        label={"Select Debtor"}
                        conditions={[
                            {key: "org", val: this.state.selectedCompany}
                        ]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedClient"}
                        currentValue={this.state.selectedClient}
                        comboId={"client"}
                    />

                    <BuilderOptionComboBox
                        data={this.state.Projects}
                        keyVal={"code"}
                        keyLabel={"name"}
                        label={"Select Project"}
                        conditions={[
                            {key: "org", val: this.state.selectedCompany},
                        ]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedProject"}
                        currentValue={this.state.selectedItem}
                        comboId={"project"}
                    />

                    <Row style={styles.dateRow}>
                        <Col span={11} style={{ height: 45}}>
                            <DatePicker
                                style={styles.datePickerBox}
                                onChange={(date, dateString) => this.setState({selectedStartDate: dateString})}
                                placeholder={"Period Start Date"}
                            />
                        </Col>
                        <Col span={11} style={{height: 45}}>
                            <DatePicker
                                style={styles.datePickerBox}
                                onChange={(date, dateString) => this.setState({selectedEndDate: dateString})}
                                placeholder={"Period End Date"}
                            />
                        </Col>
                    </Row>
                    <Row style={styles.dateRow}>
                        <Col span={24} style={{ height: 45}}>
                            <DatePicker
                                style={styles.datePickerBox}
                                onChange={(date, dateString) => this.setState({selectedQuoteDate: dateString})}
                                placeholder={"Quote Date"}
                            />
                        </Col>

                    </Row>

                    <Row>
                        <Col span={24}>
                            <Button onClick={()=>this.submitGenerateQuote()} style={{minWidth:"100%",border:"1px solid red",borderRadius:5}} type={"warning"}>
                                Generate Quote
                            </Button>
                        </Col>
                    </Row>

                    <Divider orientation="center" style={{color: "#3880ff"}}></Divider>


                    {this.state.Result!==null?
                        <Row>
                            <Col span={24}>
                                {this.renderResult()}
                            </Col>
                            <Col span={24}>
                                {this.renderIFrameInvoice()}
                            </Col>
                        </Row>:null}


                    <Row>
                        <Col span={24}>
                            <LoadingProcessing open={this.state.isLoading}/>
                        </Col>
                    </Row>
                </div>
            </Page>
        )
    }

}

export default Connector(QuoteGenerateKeyPersonnelPage);

const styles = {
    dateRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent:"space-between",
        marginBottom:20,
        marginTop:20
    },
    datePickerBox: {
        height: 40,
        width: "100%"
    },
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
