import {Menu} from "antd";
import React from "react";
import { Layout} from 'antd';
import {CheckDisplayModule} from "../../api/nav";
import {GetUserNavigationMenus_} from "../../api/navigation-menus";
import {GetLoginAccess, GetUserToken} from "../../api/token";
import {UserOutlined} from "@ant-design/icons";
import connector from "../../redux/connector";
import logoImg from "../../assets/img/favicon.ico";
const { Header } = Layout;


class HeaderClass extends React.Component{

    constructor(props) {
        super(props);
    }

    render() {
        let props = this.props;
        let ls=[];
        const nav =props.nav;
        let defaultApp =nav.CurrentApp;


        const user = GetUserToken();
        const access = GetLoginAccess();
        let navMenus={}
        let menuData=[];
        for(let i in access){
            let row = access[i];
            if(user.Role==="super"){
                menuData.push({
                    module:row.Module,
                    menu:row.Menu,
                    subMenu:row.Submenu,
                    link:row.Link,
                });
                continue
            }

            if(user.Org===row.Org && user.Role===row.Role){
                menuData.push({
                    module:row.Module,
                    menu:row.Menu,
                    subMenu:row.Submenu,
                    link:row.Link,
                });
            }
        }
        navMenus=GetUserNavigationMenus_(props,menuData)

        const hasModuleHaveMenu=(module)=>{
            if(user.Role==="super"){
                return true
            }
            let boo=false;
            let info1Data = navMenus[module];
            for(let menu in info1Data){
                let mLs = info1Data[menu];
                if(mLs.length>0){
                    boo = true
                }
            }
            return boo
        }

        for(let i in navMenus){
            let module = i;
            if(hasModuleHaveMenu(module)){
                ls.push(module)
            }

        }
        const setAppName=(appName)=>{
            //todo let find the default menu for this app
            const store = nav.menus[appName];
            let defaultMenu = "";
            let defaultModule ="";

            for(let i in store){
                defaultModule = i;
                for(let a in store[i]){
                    const row = store[i][a];
                    if(row.isDefault){
                        defaultMenu = row.name;
                    }
                }
                break
            }
            props.SetNavAppName(appName);
            props.SetNavAppModule(defaultModule);
            props.SetNavAppMenu(defaultMenu);
        }

        const logoutMe=()=>{
            if(!window.confirm("Do you really want to logout?")){
                return
            }
            /*props.SetNavAppModule("authentication");
            props.SetNavAppMenu("Login");
            props.SetNavAppLink("login");*/

            window.location.href="#/login";
            window.location.reload();
        }


        return(
            <Header className="header" style={{
                display:"flex",
                justifyContent:"space-between",
                alignItems:"center",
            }}>
                <img src={logoImg} className="logo" style={{width:30,height:30}}/>
                <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[defaultApp]}>

                    {ls.map((item, index) => {
                        return (
                            <Menu.Item
                                key={item}
                                onClick={() => setAppName(item)}
                            >
                                {item.toUpperCase()}
                            </Menu.Item>
                        )
                    })}
                </Menu>

                <div style={{
                    alignSelf: 'end'
                }}>
                    <a onClick={() => logoutMe()} style={{
                        color: "white",
                        marginRight: 10,
                        float: "right"
                    }}><UserOutlined/> {user.FullName} | {user.Role} | Logout2</a>
                </div>

            </Header>
        )
    }

}

export default connector(HeaderClass);
