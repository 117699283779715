import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {Alert, Button, Col, Form, Layout, Row, Select} from "antd";
import {fetchDataEntity} from "../../../api/entity-data";
import {SortData,} from "../../../api/fn";
import {PostToPbx} from "../../../api/http-post";
import moment from "moment";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import DisplayDataReportSummaryHours from "../../../components/Reports/DisplayDataReportSummaryHours";
import Configures from "../../../configures";
import {GetConfigures} from "../../../configures/serve";
import {GetUserToken} from "../../../api/token";
import {loadData} from "../invoices/SetupLabourInvoice/lib";
import DisplayDataReportSummaryHours2 from "../../../components/Reports/DisplayDataReportSummaryHours2";

const {Content} = Layout;
const {Option} = Select;

function convertDateFormat_DDMMYYYY(dateIn) {
    let mydate = moment(dateIn, "YYYY-MM-DD").format("DD MMM YYYY");
    return mydate;
}

function formatCurrency(stringIn) {
    return stringIn.toFixed(2)
}


class PayrollActionMasterFilePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Org: "",
            Companies: [],
            Entities: [],
            FnbCsvInfo: [],

            selectedCompany: "",
            selectedModule: "",
            selectedRecord: {},
            selectedPeriod: "",
            selectedSite: "",

            Data: [],
            isLoading: false,
            component: "list",
            initLoading: false,

            DataPeriod: [],
            DataPayCycle: [],
            DataSite: [],

            Results: null,
            showViewDetail: false,
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.initialReload()
    }
    initialReload = async () => {
        await fetchDataEntity("Entities", "", "", this, undefined, "common/entity/modules/list");
        await this.loadData("PayrollBankingCsvFnb", "FnbCsvInfo")
    }
    loadContractInfo = async (selectedModule) => {
        let _this = this;
        let module = this.state.selectedModule;
        let hub = {
            Org: this.state.selectedCompany,
            Module: "contract",
            Ref: selectedModule,
        }

        console.log("loadContractInfo > ", hub)
        let endpoint = "/payroll/contract/fetch-data"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            let tmp = _this.state;
            tmp.DataPeriod = data.TermsPeriod;
            tmp.DataDesignation = data.TermsDesignation;
            tmp.DataPayCycle = data.TermsPayCycle;
            tmp.DataSite = data.TermsSite;
            tmp.DataRate = data.TermsRate;
            tmp.DataRateSpecific = data.TermsRateSpecific;
            tmp.DataSlot = data.TermsSlot;
            tmp.DataSiteDesignation = data.TermsSiteDesignation;
            tmp.DataShift = data.TermsShift;
            tmp.DataCondition = data.TermsCondition;
            _this.setState(tmp);

            console.log("loadInitialData > ", output, " > ", data)
        });
    }
    loadData = async (targetEntity, stateKey) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            await _this.setState({
                [stateKey]: data.RESULT
            })
        });
    }
    handleChangeCompany = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    handleChange = async (e, key) => {
        const value = e.value;
        this.setState({
            [key]: value,
        });

        if (key === "selectedModule") {
            console.log("selected module > ", key, value)
            await this.loadContractInfo(value)
        }

    }
    handleChange2 = (e, ee, key) => {
        const val = e;
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
    }
    generatePaySlip = async (rowIn) => {
        let _this = this;
        if (!window.confirm("Are you to generate payslip for employee: " + rowIn.Name)) {
            return
        }
        let pDate = this.getPayDate(this.state.selectedPeriod);
        let hub = {
            Org: this.state.selectedCompany,
            PayCycleRef: this.state.selectedPeriod,
            Contract: this.state.selectedModule,
            PayDate: pDate,
            Ref: rowIn.Code,

        }
        this.setState({
            currentPayslip: null,
            isLoading: true,
        });
        console.log("1 -Data--> ", hub)
        console.log("generatePaySlip > ", rowIn);
        let endpoint = "/payslip/single/generate"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            this.setState({
                currentPayslip: data,
                isLoading: false,
            });
            // DropBoxRev
            const server = GetConfigures();
            const dropboxId = data.DropBoxRev;
            const endpoint = "/files/download/" + dropboxId;
            let url = server + "" + endpoint;

            window.document.title = "Payslip - " + rowIn.Code + " - " + rowIn.Name;
            window.open(url, '_blank');

            console.log("out-Data--> ", data)
        });

    }

    validateSubmitSearch = () => {
        const state = this.state;
        if (state.selectedCompany === "") {
            alert("Validation fail! Please select your company")
            return false
        }
        if (state.selectedModule === "") {
            alert("Validation fail! Please select your project")
            return false
        }
        if (state.selectedPeriod === "") {
            alert("Validation fail! Please select your pay circle")
            return false
        }

        return true
    }
    submitGenerateLabourReportCalendar = async () => {
        this.setState({
            isLoading: true,
        });

        if (!window.confirm("Do you want to generate payment summary report PDF file?")) {
            return
        }

        let arrPeriod = this.state.selectedPeriod.split("#");
        let payDate = arrPeriod[3];
        let payDateString = moment(payDate, "YYYY-MM-DD").format("DD MMM YYYY")

        let hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedModule,
            CompanyName: "MSHENGU TOILET HIRE",
            PayDate: payDateString,
            TitleReport: "EMPLOYMEE PAYMENT SUMMARY REPORT - EPWP 100L Container Employees",
            InnerData: this.state.Results.Summary
        }

        console.log("submitGenerateLabourReportCalendar -Data--> ", hub)
        let endpoint = "/wage/report/labour-calendar"
        await PostToPbx(hub, endpoint, async (output) => {
            this.setState({
                isLoading: false,
            });
            if (output === null) {
                console.log("Error response null > ", output);
                return
            }
            let data = output.RESULT;
            let url = data.LinkDownload;
            window.document.title = hub.TitleReport;
            window.open(url, '_blank');
            console.log("-submitGeneratePaymentSummary out  Data--> ", data)
        });

    }
    submitGenerateTimesheetCalendar = async () => {
        this.setState({
            isLoading: true,
        });

        if (!window.confirm("Do you want to generate timesheet report PDF file?")) {
            return
        }

        let arrPeriod = this.state.selectedPeriod.split("#");
        let payDate = arrPeriod[3];
        let payDateString = moment(payDate, "YYYY-MM-DD").format("DD MMM YYYY")

        let hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedModule,
            CompanyName: "MSHENGU TOILET HIRE",
            PayDate: payDateString,
            TitleReport: "EMPLOYMEE PAYMENT SUMMARY REPORT - EPWP 100L Container Employees",
            InnerData: this.state.Results.Summary
        }

        console.log("submitGenerateTimesheetCalendar -Data--> ", hub)
        let endpoint = "/wage/report/timesheet-calendar"
        await PostToPbx(hub, endpoint, async (output) => {
            this.setState({
                isLoading: false,
            });
            if (output === null) {
                console.log("Error response null > ", output);
                return
            }
            let data = output.RESULT;
            let url = data.LinkDownload;
            window.document.title = hub.TitleReport;
            window.open(url, '_blank');
            console.log("-submitGenerateTimesheetCalendar out  Data--> ", data)
        });
    }
    submitGenerateLabourReport = async () => {
        this.setState({
            isLoading: true,
        });

        if (!window.confirm("Do you want to generate payment summary report PDF file?")) {
            return
        }

        let arrPeriod = this.state.selectedPeriod.split("#");
        let payDate = arrPeriod[3];
        let payDateString = moment(payDate, "YYYY-MM-DD").format("DD MMM YYYY")

        let hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedModule,
            CompanyName: "MSHENGU TOILET HIRE",
            PayDate: payDateString,
            TitleReport: "EMPLOYMEE PAYMENT SUMMARY REPORT - EPWP 100L Container Employees",
            InnerData: this.state.Results.Summary
        }

        let endpoint = "/wage/report/labour"
        await PostToPbx(hub, endpoint, async (output) => {
            this.setState({
                isLoading: false,
            });
            if (output === null) {
                console.log("Error response null > ", output);
                return
            }
            let data = output.RESULT;
            let url = data.LinkDownload;
            window.document.title = hub.TitleReport;
            window.open(url, '_blank');
        });

    }
    submitGeneratePaymentSummary = async () => {
        this.setState({
            isLoading: true,
        });

        if (!window.confirm("Do you want to generate payment summary report PDF file?")) {
            return
        }

        let arrPeriod = this.state.selectedPeriod.split("#");
        let payDate = arrPeriod[3];
        let payDateString = moment(payDate, "YYYY-MM-DD").format("DD MMM YYYY")

        let hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedModule,
            CompanyName: "MSHENGU TOILET HIRE",
            PayDate: payDateString,
            TitleReport: "EMPLOYMEE PAYMENT SUMMARY REPORT - EPWP 100L Container Employees",
            InnerData: this.state.Results.Summary
        }

        console.log("submitGeneratePaymentSummary -Data--> ", hub)
        let endpoint = "/wage/report/payment-summary"
        await PostToPbx(hub, endpoint, async (output) => {
            this.setState({
                isLoading: false,
            });
            if (output === null) {
                console.log("Error response null > ", output);
                return
            }
            let data = output.RESULT;
            let url = data.LinkDownload;
            window.document.title = hub.TitleReport;
            window.open(url, '_blank');
            console.log("-submitGeneratePaymentSummary out  Data--> ", data)
        });

    }
    submitSearch = async () => {
        this.setState({
            isLoading: true,
            Results: null,
        });

        if (!this.validateSubmitSearch()) {
            return
        }

        let hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedModule,
        }

        console.log("1 -Data--> ", hub)
        let endpoint = "/wage/calculation/hourly"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            this.setState({
                Results: data,
                isLoading: false,
            });
            console.log("-search Data--> ", data)
        });

    }
    getPayDate = () => {
        let arr = this.state.selectedPeriod.split("#");
        return arr[3]
    }

    getFnbCsvInfo = () => {
        let response = null;
        for (let i in this.state.FnbCsvInfo) {
            let row = this.state.FnbCsvInfo[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.project !== this.state.selectedModule) {
                continue
            }
            response = row
        }
        return response
    }

    renderWarningBar = () => {
        return
        let boo = false
        if (this.state.selectedCompany === "" || this.state.selectedEntity === "" || this.state.component === "form") {
            boo = true
        }
        if (!boo) {
            return
        }
        return (
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Alert style={{margin: '16px 0'}} message="Please select your company above to view your records!"
                       type={"error"}/>
            </div>
        )
    }
    renderCompaniesSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={""}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedCompany")}
                    value={this.state.selectedCompany}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderModules = () => {
        let ls = [];
        let org = this.state.selectedCompany;
        for (let i in this.state.Entities) {
            const row = this.state.Entities[i];

            if (row.org === org) {
                ls.push({
                    key: row.name,
                    val: row.name,
                })
            }
        }
        return (
            <Select
                name={"selectedModule"}
                defaultValue=""
                onChange={(e, ee) => this.handleChange(ee, "selectedModule")}
            >
                {ls.map((record, index) => {
                    return (
                        <Option value={record.key}>{record.val}</Option>
                    )
                })}
            </Select>
        )
    }
    renderSite = () => {
        let ls = [];
        for (let i in this.state.DataSite) {
            let row = this.state.DataSite[i];
            ls.push({
                key: row.Name,
                val: row.Name
            })
        }
        return (
            <React.Fragment>
                <Form.Item label={"Site"} required={true}>
                    <Select
                        name={"selectedSite"}
                        defaultValue={this.state.selectedSite}
                        value={this.state.selectedSite}
                        onChange={(e, ee) => this.handleChange2(e, ee, "selectedSite")}
                    >
                        {ls.map((record, index) => {
                            return (
                                <Option value={record.key}>{record.val}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            </React.Fragment>
        )
    }
    formatDate = (dateIn) => {
        return convertDateFormat_DDMMYYYY(dateIn)
    }
    renderPayCycle = () => {
        let data = this.state.DataPayCycle;
        data = SortData("Period", data)
        let ls = [];
        for (let i in data) {
            let row = data[i];
            const key = row.StartDate + "#" + row.EndDate + "#" + row.Period + "#" + row.PayDate;
            const display = row.Period + ". -> Start: " + this.formatDate(row.StartDate) + " -> End: " + this.formatDate(row.EndDate) +
                " -> Pay date: " + this.formatDate(row.PayDate);
            ls.push({
                key: key,
                val: display
            })
        }
        return (
            <React.Fragment>
                <Form.Item label={"Pay Cycle"} required={true}>
                    <Select
                        name={"selectedPeriod"}
                        defaultValue={this.state.selectedPeriod}
                        value={this.state.selectedPeriod}
                        onChange={(e, ee) => this.handleChange2(e, ee, "selectedPeriod")}
                    >
                        {ls.map((record, index) => {
                            return (
                                <Option value={record.key}>{record.val}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            </React.Fragment>
        )
    }
    conditionInfo = () => {
        let link =""// this.props.location.pathname;
        if(typeof this.props.location !=="undefined"){
            if(typeof this.props.location.pathname !=="undefined"){
                link = this.props.location.pathname;
                link = link.replace("/", "")
            }
        }

        let infos = {
            "wagepayroll-payroll-banking-integration": {PageTitle: "Bank Integration", commandList: ["bank"]},
            "wagepayroll-payroll-payrollactions-gateway-ntegration":{PageTitle: "PAYMENT GATEWAY INTEGRATION", commandList: ["payment-gateway"]},
            "wagepayroll-payroll-payrollactions-pastel-ntegration":{PageTitle: "PAYMENT PASTEL INTEGRATION", commandList: ["pastel"]},
            "wagepayroll-payroll-payrollactions-banking-payment-report":{PageTitle: "BANKING PAYMENT REPORT", commandList: ["payment-summary"]},
            "wagepayroll-payroll-payrollactions-coct-payment-report":{PageTitle: "CoCT PAYMENT REPORT", commandList: ["payment-summary"]},
            "wagepayroll-payroll-payrollactions-payroll-payslip":{PageTitle: "PAYSLIP", commandList: ["payslip"]},
            "wagepayroll-payroll-payrollactions-labour-report":{PageTitle: "LABOUR REPORT", commandList: ["labour-report-calendar"]},
            "wagepayroll-payroll-payrollactions-timesheet-report":{PageTitle: "TIMESHEET REPORT", commandList: ["timesheet-calendar"]},
        }
        let PageTitle = "", commandList = "";
        if (typeof infos[link] !== "undefined") {
            let record = infos[link]
            PageTitle = record.PageTitle;
            commandList = record.commandList
        }
        console.log("Master file props> ", link,this.props)
        return {PageTitle, commandList}
    }


    render() {
        let {PageTitle, commandList} = this.conditionInfo();
        // console.log("Master file props> ",this.props,PageTitle," > ",commandList)
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>{PageTitle}</Title>
                    <Row gutter={16} style={{marginTop: 20}}>
                        <Col span={12}>
                            <Form.Item
                                label={"Company"}
                            >
                                {this.renderCompaniesSelection()}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={"Project"}
                            >
                                {this.renderModules()}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{marginTop: -40}}>
                        <Col span={24}>
                            {this.renderPayCycle()}
                        </Col>
                    </Row>
                    <Row gutter={16} style={{marginTop: -10}}>
                        <Col span={24}>
                            <Button style={{minWidth: "100%"}}  onClick={() => this.submitSearch()}>Search
                            </Button>&nbsp;&nbsp;
                        </Col>
                    </Row>
                    {this.state.Results !== null ?
                        <Row gutter={24} style={{marginTop: 40}}>
                            <DisplayDataReportSummaryHours2
                                dataStore={this.state.Results}
                                payDate={this.getPayDate()}
                                _this={this}
                                payPeriod={this.state.selectedPeriod}
                                org={this.state.selectedCompany}
                                project={this.state.selectedModule}
                                submitSearch={this.submitSearch}
                                submitGeneratePaymentSummary={this.submitGeneratePaymentSummary}
                                submitGenerateLabourReport={this.submitGenerateLabourReport}
                                submitGenerateLabourReportCalendar={this.submitGenerateLabourReportCalendar}
                                submitGenerateTimesheetCalendar={this.submitGenerateTimesheetCalendar}
                                FnbCsvInfo={this.getFnbCsvInfo()}
                                commandList={commandList}
                            />
                        </Row> : null
                    }
                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }
}

export default PayrollActionMasterFilePage;

//export default Connector(PayrollActionMasterFilePage);

const styles = {}
