import React, {Fragment} from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import Button, {Alert, Form, Layout, Select, Modal, Input} from "antd";
import {fetchDataEntity} from "../../../api/entity-data";
import {GetUserToken} from "../../../api/token";
import {SortData, UpCaseFirst} from "../../../api/fn";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {NewAttributeFormStep} from "../../../components/web/Database/NewAttributeFormStep";
import DateSelectorBar from "../../../components/web/Clocking/DateSelectorBar";
import {StepBulkClockingIndex} from "../../../components/web/Clocking/StepBulkClockingIndex";
import {SubmitPost} from "../../../components/web/Common/localFunctions";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import IconButton from "@material-ui/core/IconButton";
import ListIcon from "@material-ui/icons/List";
import Configures from "../../../configures";
import moment from "moment";
import {PostToPbx} from "../../../api/http-post";
import {GetConfigures} from "../../../configures/serve";

const {TextArea} = Input;
const {Content} = Layout;
const {Option} = Select;

const server =GetConfigures();


class ComplianceQaPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Entities: [],
            Databases: [],
            Attributes: [],
            selectedCompany: "",
            selectedEntity: "",
            selectedRecord: {},
            Data: [],
            isLoading: false,
            component: "list",
            comment: "",
            qaState: "",
            showModalReject: false,
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Entities", "", "", this, undefined, "common/entity/modules/list");
        await fetchDataEntity("Databases", "", "", this, undefined, "common/entity/databases/list");
        await fetchDataEntity("Attributes", "", "", this, undefined, "common/entity/attributes/list");
        await this.initialReload()
    }
    initialReload = async () => {
        await fetchDataEntity("Data", "", "", this, undefined, "common/entity/ComplianceQa/list")
    }
    loadInitialData = async () => {
        await fetchDataEntity("Data", "", "", this, undefined, "common/entity/ComplianceQa/list")
    }
    handleChangeCompany = (value, key) => {
        this.setState({
            [key]: value,
        })
    }


    submitStatus = async (row, table,feedback) => {
        delete row.linkDoc;
        delete row.qaAction;
        delete row.state;

        let _this=this;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = row;
        post.Params = {}
        let endpoint = "/common/entity/" + table + "/insert";
        this.setState({
            isLoading: true,
        })

        console.log("submitStatus send > ", post);

        await PostToPbx(post, endpoint, async (data) => {
            console.log("submitStatus response > ",  data);
            let res = [];
            if (data !== null) {
                res = data.RESULT;
            }
            feedback("done");
            await _this.initialReload();
        });

    }

    requestApprove = async (row) => {
        if (!window.confirm(`Are you sure to approve this compliance document? ${row.Name}`)) {
            return
        }
        this.setState({
            showModalReject: false,
            selectedRecord: row,
            qaState: "approved"
        });
        let user = GetUserToken();
        row.status = "approved";
        row.approvedby = `${user.Username} # ${user.FullName}`;
        row.date = moment().format("YYYY-MM-DD")
        row.date = moment().format("HH:mm:SS")

        delete row.linkDoc;
        delete row.state;

        await this.submitStatus(row,"ComplianceQa",async ()=>{
            alert("Status approved submitted ");
            await this.submitStatus(row,"ComplianceData",()=>{
               console.log("done!")
            });
        });
    }
    requestReject = async (row) => {
        if (!window.confirm(`Are you sure to reject this compliance document? ${row.Name}`)) {
            return
        }
        this.setState({
            showModalReject: false,
            selectedRecord: row,
            qaState: "reject"
        });
        let user = GetUserToken();
        row.status = "reject";
        row.approvedby = `${user.Username} # ${user.FullName}`;
        row.date = moment().format("YYYY-MM-DD")
        row.date = moment().format("HH:mm:SS")

        delete row.linkDoc;
        delete row.state;

        await this.submitStatus(row,"ComplianceQa",async ()=>{
            alert("Status approved submitted ");
        });
    }
    getUrl = (storeUrl) => {
        let arr = storeUrl.split("/")
        let index = arr.length - 1
        const dropboxId = arr[index];
        const endpoint = "/files/download/" + dropboxId;
        let url = server + "" + endpoint;
        return url;
    }
    docDownload = (docUrl, filename) => {
        var url = this.getUrl(docUrl);
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
            var a = document.createElement('a'); // create html element anchor
            a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
            a.download = filename; // Set the file name.
            a.style.display = 'none'; // set anchor as hidden
            document.body.appendChild(a);
            a.click();
            a.remove()
        };
        xhr.open('GET', url);
        xhr.send();
    }
    docView = (docUrl, filename) => {
        let link = this.getUrl(docUrl);
        console.log("Opening link pdf link request > ", link);
        document.title = filename;
        window.open(link, '_blank');
    }
    renderCompaniesSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={"Select Company"}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedCompany")}
                    value={this.state.selectedCompany}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderModuleSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = [];
        for (let i in this.state.Entities) {
            const row = this.state.Entities[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            ls.push(row);
        }
        return (
            <Form.Item
                label={"Select Project"}
                name={"selectedEntity"}
                rules={[{required: true, message: "Please select your project!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedEntity")}
                    value={this.state.selectedEntity}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.name}
                                value={record.name}
                            >
                                {record.name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderList = () => {

        let columns = [
            {id: "ref", label: "Ref"},
            {id: "name", label: "Name"},
            {id: "linkDoc", label: "Download"},
            {id: "approvedby", label: "Approved By"},
            {id: "score", label: "Score"},
            {id: "state", label: "Status"},
            {id: "date", label: "Date"},
            {id: "time", label: "Time"},
            {id: "qaAction", label: "Action"}
        ];
        if (this.state.component !== "list") {
            return
        }

        let ls = [];
        for (let i in this.state.Data) {
            const row = this.state.Data[i];
            console.log("UUUUUUU00000>>>> ", " > ", this.state.selectedCompany, " > ", this.state.selectedEntity, " > ", row)
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.module !== this.state.selectedEntity) {
                continue
            }
            row.state = row.status==="active"?
                <span style={{color:"orange"}}>Pending</span>:
                <span style={{color:"orange"}}>{row.status}</span>;

            row.linkDoc = (
                <>
                    <IconButton edge="end" aria-label="delete"
                                onClick={() => this.docDownload(row.dropboxid, row.name)}>
                        <CloudDownloadIcon style={{color: "green"}}/>
                    </IconButton>
                    &nbsp; &nbsp;
                    <IconButton edge="end" aria-label="delete" onClick={() => this.docView(row.dropboxid, row.name)}>
                        <ListIcon style={{color: "black"}}/>
                    </IconButton>
                </>
            )

            if (row.endtime === "00:00:00") {
                row.qaAction = (
                    <React.Fragment>
                        <h3
                            style={{cursor: "pointer", fontSize: 18, fontWeight: "bold"}}
                        >
                            <img
                                style={{width: 50, height: 50}}
                                src={"https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fimg.freepik.com%2Ffree-photo%2Fstop-road-sign_1194-6978.jpg%3Fsize%3D338%26ext%3Djpg&f=1&nofb=1"}
                            />
                        </h3>
                    </React.Fragment>
                )
            } else {
                row.qaAction = (
                    <React.Fragment>
                        <a
                            style={{cursor: "pointer", fontSize: 18, fontWeight: "bold"}}
                            onClick={() => this.requestApprove(row)}
                        >Approve</a>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <a
                            onClick={() => this.requestReject(row)}
                            style={{cursor: "pointer", color: "red", fontSize: 18, fontWeight: "bold"}}
                        >Reject</a>
                    </React.Fragment>
                )
            }

            /*row.qaAction=(<React.Fragment>
                <Button
                    type={"dashed"}
                    danger={true}
                />&nbsp;&nbsp;|&nbsp;&nbsp;
                <Button
                    type={"dashed"}
                    primary={true}
                />
            </React.Fragment>)*/
            ls.push(row);
        }

        ls = SortData("position", ls)

        let data = {columns: columns, data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Clocking Records")}
                    onAddRecord={this.onAddRecord}
                    allowedAdd={false}
                />
            </GridItem>
        )

    }
    renderWarningBar = () => {
        return
        let boo = false
        if (this.state.selectedCompany === "" || this.state.selectedEntity === "" || this.state.component === "form") {
            boo = true
        }
        if (!boo) {
            return
        }
        return (
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Alert style={{margin: '16px 0'}} message="Please select your company above to view your records!"
                       type={"error"}/>
            </div>
        )
    }
    renderModalReject = () => {
        if (!this.state.showModalReject) {
            return
        }
        let _this = this;
        const cancelReject = () => {
            _this.setState({
                showModalReject: false,
                qaState: "",
                comment: "",
                selectedRecord: null,
            })
        }
        const renderP = (key, val) => {
            return (
                <p>{key} : {val}</p>
            )
        }
        const onChangeComment = (e) => {
            console.log("onChangeComment : ", e, " > ")
            _this.setState({
                comment: e.target.value
            })
        }
        let visible = this.state.showModalReject;
        let record = this.state.selectedRecord;
        return (
            <Modal
                title={"Qa " + this.state.qaState.toLocaleUpperCase() + " Clock"}
                visible={visible}
                onOk={this.submitQa}
                confirmLoading={this.state.isLoading}
                onCancel={cancelReject}
            >
                {renderP("Employee Code:", record.workerref)}
                {renderP("Site:", record.site)}
                {renderP("Clock Date:", record.clockdate)}
                {renderP("Hours:", record.hours)}
                {renderP("Start time:", record.starttime)}
                {renderP("End time:", record.endtime)}

                {this.state.qaState === "reject" ?
                    <React.Fragment>
                        <br/>
                        <p>Comment: </p>
                        <TextArea
                            rows={4}
                            onChange={(e) => onChangeComment(e)}
                        />
                    </React.Fragment> : null}
            </Modal>
        )
    }

    render() {
        let props = this.props;
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>QA Compliance</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        {this.renderCompaniesSelection()}
                        {this.renderModuleSelection()}
                    </div>
                    {this.renderWarningBar()}
                    {this.renderList()}
                    {this.renderModalReject()}
                </div>
            </Page>
        )
    }

}


export default Connector(ComplianceQaPage);

const styles = {
    docLink: {
        cursor: "pointer",
        color: "lightblue"
    },
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
