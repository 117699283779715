import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import Title from "../../../../components/Common/Title";
import Page from "../../../../layout/web/Page";
import Connector from "../../../../redux/connector";

import {
    BuildComboDataClient,
    BuildComboDataCompany, BuildComboDataProject, CalculateSummaryLogsChipping,
    FetchConfigDataService, FetchLogsChipping, FilterArrayDataWithConditionsNotEqual,
} from "../../../../services/service-woodchipping";
import {Button, Col, DatePicker, Row} from "antd";
import ControllerSelectBox from "../../../../components/Common/ControllerInput/ControllerSelectBox";
import moment from "moment";
import GridItem from "../../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../../components/Common/tables/TableDataView";
import {
    FormatMoneySouthAfrica,
    FormatMoneySouthAfrica2,
    FormatMoneySouthAfrica3,
    UpCaseFirst
} from "../../../../api/fn";

import '../../ServiceModule/Labour/styles.css'


class WoodChippingRepportLogsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: null,
            ConfigData: {},
            selectedCompany: "",
            selectedClient: "",
            selectedProject: "",
            selectedCompostMaker:"",
            targetDate: moment().format("YYYY-MM-DD"),

            InputRateChipping: "",
            InputRateSale: "",
            mode: "list",
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        //await fetchDataEntity("Data", "", "", this, undefined, "common/entity/WcRate/list");
        await this.fetchConfigData();
    }

    fetchConfigData = async () => {
        await FetchConfigDataService((res) => {
            this.setState({
                ConfigData: res
            })
        })

    }
    handleChangeInput = async (key, value) => {
        this.setState({
            [key]: value,
        })
    }
    handleGetInputValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return ""
        }
        return this.state[key];
    }

    submitSearch = async () => {
        await FetchLogsChipping({
            Org: this.state.selectedCompany,
            Client: this.state.selectedClient,
            Project: this.state.Project,
            TargetDate: this.state.targetDate,
        }, (res) => {
            this.setState({
                Data: res
            })
        })
    }

    getRate=()=>{
        let rate = 0;
        if(this.state.ConfigData===null){
            return rate
        }
        if(typeof this.state.ConfigData.Rates ==="undefined"){
            return rate
        }
        /**
         * let find rate for chipping action
         */
        if(this.state.selectedCompostMaker===""){
            for(let i in this.state.ConfigData.Rates) {
                let row = this.state.ConfigData.Rates[i];
                if (row.Org !== this.state.selectedCompany) {
                    continue
                }
                if(row.Client !==this.state.selectedClient){
                    continue
                }
                if(row.Project !==this.state.selectedProject){
                    continue
                }
                rate = row.Chipping;
            }
        }else{
            /**
             * let find rate for compost maker
             */
            for(let i in this.state.ConfigData.Rates) {
                let row = this.state.ConfigData.Rates[i];
                if (row.Org !== this.state.selectedCompany) {
                    continue
                }
                if(this.state.selectedCompostMaker!==row.Client){
                    continue
                }
                rate = row.Sale;
            }
        }


        console.log(`99 @@@@-> `,this.state.ConfigData," > ",rate)

        return rate;
    }
    renderSummaryData = () => {
        let data = [];
        if (this.state.Data !== null) {
            data = this.state.Data.Monthly.Logs;
        }
        console.log("::this.getRate> ",this.getRate())
        let info = CalculateSummaryLogsChipping({
            client: this.state.selectedClient,
            project: this.state.selectedProject,
        }, data,this.state.selectedCompostMaker,this.getRate())

        let ls = [
            {label: "Days", total: info.totalDays, currency: false, formatNumber: true, currValue: "", extraClass: ""},
            {
                label: "Records",
                total: info.totalRecord,
                currency: false,
                formatNumber: true,
                currValue: "",
                extraClass: ""
            },
            {
                label: "Cubic Size",
                total: info.totalCubicSize,
                currency: false,
                formatNumber: true,
                currValue: "",
                extraClass: ""
            },
            {
                label: "Load Mass",
                total: info.totalLoadMass,
                currency: false,
                formatNumber: true,
                currValue: "",
                extraClass: ""
            },
            {
                label: "Price/kg",
                total: info.rate,
                currency: false,
                formatNumber: true,
                currValue: "",
                extraClass: ""
            },
            {
                label: "Actual Revenue",
                total: parseFloat(info.revenue.toFixed(2)),
                currency: true,
                formatNumber: true,
                currValue: "",
                extraClass: ""
            },
        ]

        console.log("***&&****> ",ls)

        return (
            <Row gutter={18} className={"stRow borderTop"}>
                {ls.map(row => {
                    return (
                        <Col span={4} className={"stRowCol"}>
                            <label className={"stRowLabel"}>{row.label}:</label>
                            <label className={`stRowTotal1 `} style={{...row.extraClass}}>
                                {row.currency ? FormatMoneySouthAfrica3(row.total) : FormatMoneySouthAfrica2(row.total)}
                            </label>
                        </Col>
                    )
                })}
            </Row>
        )
    }

    renderList = () => {




        let columns = [
            {id: "DeliveryDate", label: "Date"},
            {id: "WbRef", label: "WB Ref"},
            {id: "CubicSize", label: "Cubic Size"},
            {id: "LoadMass", label: "Load Mass"},
            {id: "Vehicle", label: "Vehicle"},
            {id: "Driver", label: "Driver"},
            {id: "DeliveryNote", label: "Delivery Note"},
            {id: "CompostMaker", label: "Compost Maker"},
        ];
        let storeData = [];
        if (this.state.Data !== null) {
            storeData = FilterArrayDataWithConditionsNotEqual(
                this.state.Data.Monthly.Logs,
                [
                    {field: "Client", value: this.state.selectedClient},
                    {field: "Project", value: this.state.selectedProject},
                ],
                []
            );
        }

        /** let filter based on the selected compost maker */
        if(this.state.selectedCompostMaker!==""){
            let outData = [];
            for(let i in storeData){
                let row = storeData[i];
                if(row.CompostMaker!==this.state.selectedCompostMaker){
                    continue
                }
                outData.push(row)
            }
            storeData = outData
        }



        let data = {columns: columns, data: storeData};

        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={"Daily Logs Monthly - "+moment(this.state.targetDate,"YYYY-MM-DD").format("MMMM YYYY")}
                    onAddRecord={this.onAddRecord}
                    allowedAdd={true}
                    onDelete={this.onDeleteRecord}
                />
            </GridItem>
        )
    }

    render() {
        return (

            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",

                }}>
                    <Title style={styles.title}> Wood Chipping Logs</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <Row gutter={18}>
                            <Col span={12}>
                                <ControllerSelectBox
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={BuildComboDataCompany(this.state.ConfigData)}
                                    label={"Company"}
                                    name={"selectedCompany"}
                                />
                            </Col>
                            <Col span={12}>
                                <ControllerSelectBox
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={BuildComboDataClient(this.state.ConfigData, this.state.selectedCompany)}
                                    label={"Client"}
                                    name={"selectedClient"}
                                />
                            </Col>
                        </Row>

                        <Row gutter={18}>
                            <Col span={12}>
                                <ControllerSelectBox
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={BuildComboDataProject(
                                        this.state.ConfigData,
                                        this.state.selectedCompany,
                                        this.state.selectedClient
                                    )}
                                    label={"Project"}
                                    name={"selectedProject"}
                                />
                            </Col>
                            <Col span={12} style={styles.dateRow}>
                                <Col span={10} style={{marginTop: 30, height: 40}}>
                                    <DatePicker
                                        style={styles.datePickerBox}
                                        onChange={(date, dateString) => this.setState({targetDate: dateString})}
                                        placeholder={"Target Date"}
                                    />
                                </Col>
                                <Col span={10} style={{marginTop: 0, height: 40}}>
                                    <ControllerSelectBox
                                        handleInput2={this.handleChangeInput}
                                        getHandleValue={this.handleGetInputValue}
                                        ls={BuildComboDataClient(this.state.ConfigData, this.state.selectedCompany)}
                                        label={"Compost Maker"}
                                        name={"selectedCompostMaker"}
                                    />
                                </Col>
                                <Col span={4} style={{marginTop: 30, height: 40}}>
                                    <Button
                                        style={styles.datePickerBox}
                                        onClick={() => this.submitSearch()}
                                    >
                                        Search
                                    </Button>
                                </Col>

                            </Col>
                        </Row>

                        <div style={{backgroundColor: "white", padding: 10,marginTop:30}}>
                            <Row>
                                <Col span={24}>
                                    {this.renderSummaryData()}
                                </Col>
                            </Row>

                            <Row gutter={18}>
                                <Col span={24}>
                                    {this.renderList()}
                                </Col>
                            </Row>

                        </div>

                    </div>
                </div>
            </Page>
        )
    }

}

export default Connector(WoodChippingRepportLogsPage);
const styles = {
    boxFilterInner: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 5,
        borderRight: "1px solid black",
        fontsize: 14,
        fontWeight: "bold"
    },
    boxFilter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    dateRow: {
        display: "flex",
        flexDirection: "row"
    },
    datePickerBox: {
        height: 40,
        width: "100%"
    },
    row1Th: {
        border: "1px solid black",
    },
    rowForm: {
        marginBottom: 10,
        paddingBottom: 5,
        border: "0px solid lightgray",
        borderBottomWidth: 1,

    },

    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}