
import {Row, Col} from "antd";
import {Colors} from "../../constants/colors";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons'
import CircularProgressBar from "./CircularProgressBar";


export default ({Data}) => {

    const hasIconOnly=()=>{

    }
    const RenderBox = ({item,index}) => {
        let mySpan = 8;
        let applyEndBox = {}
        if(Data.length===(parseInt(index) + 1)){
            applyEndBox={
                borderRightWidth: 0,
            }
        }

        if(item.IconOnly){
            return(
                <Col span={mySpan} style={{
                    ...styles.box,
                    display: 'flex',
                    justifyContent:'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    ...applyEndBox,
                }}>
                    <CircularProgressBar
                        percentageValue={item.percentage}
                        displayLabel={false}
                    />
                </Col>
            )
        }

        return(
            <Col span={mySpan} style={{...styles.box,...applyEndBox}}>
                {item.data.map((rec,x)=>{
                    if(typeof rec.vStyle==="undefined"){
                        rec.vStyle = {};
                    }

                    return(
                        <div style={{...styles.dirRow,gap:7}}>
                            <label style={{
                                ...rec.kStyle,
                            }}>{rec.k}</label>
                            <label style={{
                                ...rec.vStyle,
                            }}>{rec.v}</label>
                        </div>
                    )
                })}
            </Col>
        )
    };

    return (
        <div style={styles.container}>
            <Row>
                <Col span={24} style={styles.currentMonth}
                >
                    Current Month
                </Col>
            </Row>
            <Row>
                {Data.map((item, index) => {
                    return (
                        <RenderBox key={index} item={item} index={index}/>
                    );
                })}
            </Row>
        </div>
    );
};

const styles = {
    dirRow:{
        flexDirection: 'row',
        justifyContent: 'center',
        display: "flex",
        margin:10,
        marginBottom:20
    },
    currentMonth:{
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        display: "flex",
        minHeight: 45,
        color:Colors.brand.gray,
        fontSize: 16,
        opacity:0.6
    },
    withIco:{
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        display: "flex",
        marginTop: -5,
    },
    icon:{
        alignSelf:"center",
        fontSize: 24,
    },
    targetValue: {
        fontSize: 16,
    },
    p: {
        textAlign: "center",
        marginTop:0,
    },
    amountValue: {
        fontSize: 20,
        fontWeight: 'bold',
        marginRight: 10,
        color: Colors.brand.black
    },
    box: {
        minHeight: 100,
        borderRightWidth: 0.5,
        borderRightStyle: "solid",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRightColor: Colors.brand.lightGray,

    },
    container: {
        backgroundColor: Colors.brand.white,
        marginTop:-3,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        marginRight: 20
    }
};
