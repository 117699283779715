
import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {Alert, Form, Layout, Select} from "antd";
import {fetchDataEntity} from "../../../api/entity-data";
import {GetUserToken} from "../../../api/token";
import {
    csvJSON,
    findRecordDisplayKey, findRecordDisplayKeyX, findRecordKey, findRecordKeyField, findRecordKeyX,
    findRecordType,
    findRecordUniqueKey, findRecordUniqueKeyX,
    SortData,
    UpCaseFirst
} from "../../../api/fn";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {NewAttributeFormStep} from "../../../components/web/Database/NewAttributeFormStep";
import  ReactFileReader from "react-file-reader";
import StepMappingField from "../../../components/web/CsvImportEmployees/StepMappingField";
import Steppers from "../../../components/web/CsvImportEmployees/Steppers";
import { Button} from 'antd';
import ContractSetupStep from "../../../components/web/Settings/ContractSetupStep";
import ValidationsInput from "../../../components/web/CsvImportEmployees/ValidationsInput";
import {SubmitPost} from "../../../components/web/Common/localFunctions";
import {PostToPbx} from "../../../api/http-post";

const {Content} = Layout;
const {Option} = Select;


class DatabaseImportEmployeeCsvPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Entities: [],
            selectedCompany: "",
            selectedModule: "",
            selectedRecord: {},
            selectContract:{},
            Attributes: [],
            isLoading: false,
            component: "list",
            CsvHeaders:[],
            CsvPureData:[],
            fieldList:[],
            fieldMap:{},
            entryMapping:{
            },
            showStepper:false,
            CsvDataValid:[],
            CsvDataError:[],
            CsvDataMapped:[],
            CsvDataNotMapped:[],
            ErrorTitle:"",
            ErrorCode:0,
            ErrorData:[],
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.initialReload()
    }
    initialReload = async () => {
        await fetchDataEntity("Entities", "", "", this, undefined, "common/entity/modules/list");
        await fetchDataEntity("Attributes", "", "", this, undefined, "common/entity/attributes/list");
    }
    handleChangeCompany = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    handleChangeMappingSet = (value, key) => {
        let tmp=this.state;
        tmp.entryMapping[key]=value;
        this.setState(tmp);
    }
    handleChangeMappingGet = ( key) => {
        let tmp=this.state;
        let value = "";
        if(typeof tmp.entryMapping[key] !=="undefined"){
            value=tmp.entryMapping[key]
        }
        return value;
    }
    checkValidation=()=>{
        ValidationsInput({
            _this:this,
        })
    }

    onSubmitUpload=async ()=>{
        let _this=this;
        if(!window.confirm("Are you sure to submit this csv upload?")){
            return
        }

        let fieldsList = this.getListField();
        let fieldsListOriginal=[];
        let ls = [];
        for (let i in this.state.Attributes) {
            const row = this.state.Attributes[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.module !== this.state.selectedModule) {
                continue
            }
            fieldsListOriginal.push(row);
        }

        let o = {};
        for (let i in fieldsList) {
            const row = fieldsList[i];
            const key = row.field;
            let val = this.state[key];
            const type = findRecordType(key, fieldsList);
            if (type === "float") {
                val = parseFloat(val)
            } else if (type === "number") {
                val = parseInt(val)
            } else if (type === "boolean") {
                val = val === "yes"
            } else {
                //todo do nothing
            }
            o[key] = val;
        }


        console.log("onSubmitRecord send 1 > ", o, " > ", this.state)
        let hub =[];
        for(let i in this.state.CsvDataValid){
            const data  = this.state.CsvDataValid[i];
            let rec = {
                Org: this.state.selectedCompany,
                module: _this.state.selectedModule,
                Data: data,
                Ref: findRecordUniqueKeyX(fieldsListOriginal, data),
                names: findRecordDisplayKeyX(fieldsListOriginal, "displaydelection", data),
                Email: findRecordKeyX(fieldsListOriginal, "email", data),
                Phone: findRecordKeyX(fieldsListOriginal, "phone", data),
                Username: findRecordKeyX(fieldsListOriginal, "username", data),
                Name: findRecordKeyX(fieldsListOriginal, "name", data),
                Surname: findRecordKeyX(fieldsListOriginal, "surname", data),
                Password: findRecordKeyX(fieldsListOriginal, "password", data),
                UniqueKey: findRecordKeyField(fieldsListOriginal, "unique", _this)
            }
            hub.push(rec)
        }

        console.log("onSubmitRecord send 2 > ", hub);

        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = hub;
        post.Params = {}
        let endpoint = "/common/entity/databases-bulk/insert"; // "/manager/entity/insert/extensions";

        // let endpoint =this.props.postEndpoint; // "/manager/entity/insert/extensions";
        this.setState({
            isLoading: true,
        })
        await PostToPbx(post, endpoint, async (data) => {
            alert("Thank your your record have been saved!");
            _this.closeExitUpload();
        });

    }
    getListField = () => {
        let ls = [];
        let store = this.state.Attributes;
        store = SortData("position", store)
        for (let i in store) {
            const row = store[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.module !== this.state.selectedModule) {
                continue
            }

            let type = "text";
            let options = [];
            if (row.datatype === "string") {
                type = "text";
            }
            if (row.datatype === "boolean") {
                type = "radio";
                options = [true, false];
            }
            if (row.datatype === "list") {
                type = "option";
                for (let i in row.options) {
                    options.push({
                        key: i,
                        val: row.options[i],
                    })
                }
            }
            if (row.datatype === "link") {
                type = "option";
                for (let e in this.state.Databases) {
                    const record = this.state.Databases[e];
                    if(record.org!==this.state.Org){continue}
                    if(record.module!==row.linkentity){continue}
                    options.push({
                        key: record.ref,
                        val:record.ref,
                    })
                }
            }

            let my = {
                field: row.fieldname,
                label: row.fieldname.toLocaleUpperCase(),
                options: options,
                type: type,
                required: row.required,
                dataType: row.datatype,
                position: row.position,
            }
            ls.push(my);

        }
        ls = SortData("position",ls);
        return ls;
    }

    closeExitUpload=()=>{
        //TODO
        window.location.reload();
    }


    buildFieldList=()=>{
        let ls=[];
        let my={};
        //for(let i in this.state.)
        for(let i in this.state.Attributes){
            let row=this.state.Attributes[i];

            if(row.org!==this.state.selectedCompany){
                continue
            }
            console.log(":((--> ",row," > ",this.state.selectedCompany," > ",this.state.selectedModule)
            if(row.module!==this.state.selectedModule){
                continue
            }

            ls.push(row.fieldname);
            my[row.fieldname] = row;
        }
        this.setState({
            fieldList:ls,
            fieldMap:my,
        })
    }

    handleFiles = files => {
        const _this=this;
        let reader = new FileReader();
        reader.onload = function(e) {
            let result = csvJSON(reader.result);
            _this.setState({
                CsvHeaders:result.headers,
                CsvPureData:result.data
            });
            _this.buildFieldList();
        }
        reader.readAsText(files[0]);
    }

    renderModuleSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = [];
        for(let i in this.state.Entities){
            const row = this.state.Entities[i];
            if(row.org!==this.state.selectedCompany){continue}
            ls.push(row);
        }
        return (
            <Form.Item
                label={"Select Project"}
                name={"selectedModule"}
                rules={[{required: true, message: "Please select your project!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedModule")}
                    value={this.state.selectedModule}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.name}
                                value={record.name}
                            >
                                {record.name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderCompaniesSelection = () => {
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={"Select Company"}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedCompany")}
                    value={this.state.selectedCompany}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderWarningBar=()=>{
        return
        let boo=false
        if(this.state.selectedCompany === "" || this.state.selectedEntity === "" ){
            boo=true
        }
        if(!boo){
            return
        }
        return(
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Alert style={{ margin: '16px 0' }} message="Please select your company above to view your records!" type={"error"}/>
            </div>
        )
    }
    renderCommandSetup=()=>{
        if(this.state.selectedCompany === "" || this.state.selectedModule === "" ){
            return null
        }
        return(
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Button type={"primary"} onClick={()=>this.setState({showStepper:true})}>Start Import</Button>
            </div>
        )
    }
    renderUploadComponent=()=>{
        if(this.state.selectedCompany === "" || this.state.selectedEntity === "" ){
            return
        }

        return(
            <div style={{minWidth: "100%", padding: 10}}>
                <ReactFileReader handleFiles={this.handleFiles} fileTypes={'.csv'}>
                    <button className='btn'>Upload</button>
                </ReactFileReader>
            </div>
        )
    }

    renderMapping=()=>{
        if(this.state.selectedCompany === "" || this.state.selectedEntity === "" ){
            return
        }
       // if(this.state.fieldList.length===0){return}
        return(
            <StepMappingField _this={this}/>
        )
    }

    renderSteppers=()=>{

        return(
            <Steppers _this={this} />
        )
    }

    render() {
        let props = this.props;
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Import Employees CSV</Title>
                    {!this.state.showStepper ?
                        <div style={{minWidth: "100%", padding: 10}}>
                            {this.renderCompaniesSelection()}
                            {this.renderModuleSelection()}
                            {this.renderWarningBar()}
                            {this.renderCommandSetup()}
                        </div> :
                        <React.Fragment>
                            {this.renderSteppers()}
                            {/*{this.renderUploadComponent()}
                            {this.renderMapping()}*/}
                        </React.Fragment>
                    }


                </div>
            </Page>
        )
    }
}

export default Connector(DatabaseImportEmployeeCsvPage);

const styles = {
    container:{

    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}


