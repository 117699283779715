import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import PluginAddDeleteList from "../../../components/Common/PluginAddDeleteList2";
import {fetchDataEntity} from "../../../api/entity-data";
import {

    getCompanyList,
  getYesNoOption,
    loadData
} from "./lib";


class QuoteLabourPlannedSite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Modules: [],
            Clients: [],
            Projects:[],
            Items:[],
            Sites:[],
            isLoading: false,
            company: "",
            component: "list",
            newEntry: {},
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await loadData("modules", "Projects",this);
    }

    getProjectList = (data,org) => {
        let ls = [];
        for (let i in data) {
            let row = data[i];
            if (row.org === org) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    render() {
        const org = this.state.newEntry.selectedCompany;
        const companyOptions = getCompanyList(this.state.Companies);
        const projectOptions =this.getProjectList(this.state.Projects,org);

        console.log("KKKKDisplay Selected Project: ",this.state.newEntry.selectedCompany," > ",this.state);

        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Setup Labour Site Listing</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"QuoteLabourPlannedSite"}
                            depending={{
                                "org": {
                                    targetKey: "CustomerNumber",
                                    displayKey: "Name",
                                    storageTable: "Companies"
                                },
                                "project":{
                                    targetKey: "code",
                                    displayKey: "name",
                                    storageTable: "Projects"
                                }
                            }}
                            fields={[
                                {
                                    name: "org",
                                    dataType: "option",
                                    required: true,
                                    label: "Company",
                                    options: companyOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Companies"}
                                },
                                {
                                    name: "project",
                                    dataType: "option",
                                    required: true,
                                    label: "Project",
                                    options: projectOptions,
                                    depending: {stateKey: "project", displayKey: "Name", storageTable: "Projects"}
                                },
                                {
                                    name: "site",
                                    dataType: "text",
                                    required: true,
                                    label: "Site",
                                },


                                {
                                    name: "generalworker",
                                    dataType: "float",
                                    required: true,
                                    label: "GeneralWorker",
                                },
                                {
                                    name: "teamleader",
                                    dataType: "float",
                                    required: true,
                                    label: "TeamLeader",
                                },
                                {
                                    name: "puller",
                                    dataType: "float",
                                    required: true,
                                    label: "Puller",
                                },
                                {
                                    name: "washer",
                                    dataType: "float",
                                    required: true,
                                    label: "Washer",
                                },
                                {
                                    name: "pullertl",
                                    dataType: "float",
                                    required: true,
                                    label: "PullerTl",
                                },
                                {
                                    name: "washertl",
                                    dataType: "float",
                                    required: true,
                                    label: "WasherTl",
                                },
                                {
                                    name: "rovingpuller",
                                    dataType: "float",
                                    required: true,
                                    label: "RovingPuller",
                                },




                                {
                                    name: "mon",
                                    dataType: "boolean",
                                    required: true,
                                    label: "Monday",
                                    options: getYesNoOption()
                                },
                                {
                                    name: "tue",
                                    dataType: "boolean",
                                    required: true,
                                    label: "Tuesday",
                                    options: getYesNoOption()
                                },
                                {
                                    name: "wed",
                                    dataType: "boolean",
                                    required: true,
                                    label: "Wednesday",
                                    options: getYesNoOption()
                                },
                                {
                                    name: "thu",
                                    dataType: "boolean",
                                    required: true,
                                    label: "Thursday",
                                    options: getYesNoOption()
                                },
                                {
                                    name: "fri",
                                    dataType: "boolean",
                                    required: true,
                                    label: "Friday",
                                    options: getYesNoOption()
                                },
                                {
                                    name: "sat",
                                    dataType: "boolean",
                                    required: true,
                                    label: "Saturday",
                                    options: getYesNoOption()
                                },
                                {
                                    name: "sun",
                                    dataType: "boolean",
                                    required: true,
                                    label: "Sunday",
                                    options: getYesNoOption()
                                },


                            ]}
                            deleteKeys={[
                                {key: "org", type: "string"},
                                {key: "project", type: "string"},
                                {key: "site", type: "string"},
                            ]}
                            stateArray={"Data"}
                            table={"QuoteLabourPlannedSite"}
                            addFormText={"Quote Labour Site Listing"}
                            stepTitle={"Setup Quote Labour Site Listing"}

                            filterByCompany={true}
                            displayRules={[
                                {rowKey:"org",stateKey:"selectedCompany"},
                                {rowKey:"project",stateKey:"selectedProject"},

                            ]}
                            extraFilters={[
                                {
                                    recordKey:"key",
                                    recordValue:"val",
                                    stateKey:"project",
                                    filterRecordKey:"project",
                                    filterStateKey:"selectedProject",
                                    allowBlank:true,
                                    name:"selectedProject",
                                    label:"Project",
                                    options: projectOptions,
                                    errorMsg: "Sorry you can't save, Please select your project!"
                                },
                            ]}

                            sortMe={"name"}

                        />

                    </div>
                </div>
            </Page>
        )
    }
}


export default Connector(QuoteLabourPlannedSite);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

