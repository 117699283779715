import React from "react";
import moment from "moment/moment";
import {fetchDataEntity} from "../../../../api/entity-data";
import {ArrayToSelectOption, FilterArrayByKey} from "../../../../api/fn";
import ControllerSelectBox from "../../../../components/Common/ControllerInput/ControllerSelectBox";
import Title from "../../../../components/Common/Title";
import {Button, Col, DatePicker, Input, Row} from "antd";
import Connector from "../../../../redux/connector";
import Page from "../../../../layout/web/Page";
import {PostToPbx} from "../../../../api/http-post";
import LoadingProcessing from "../../../../components/Common/page/Common/LoadingProcessing";

const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');

class LabourLoggingMonthlyCoCTPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Projects: [],
            Data: [],
            selectedCompany: "",
            selectedProject: "",
            InputEvaluatorRole: "",
            InputEvaluatorName: "",
            InputRepresentativeRole: "",
            InputRepresentativeName: "",
            InputTenderNumber:"",
            startDate:"",// startOfMonth,
            endDate:"",// moment().format("YYYY-MM-DD"),
            Results: null,
            busy:false,
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Projects", "", "", this, undefined, "common/entity/modules/list");

    }
    handleChangeInput = async (key, value) => {
        this.setState({
            [key]: value,
        })
    }
    handleGetInputValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return ""
        }
        return this.state[key];
    }
    findCompanyName=(org)=>{
        let name = org;
        let companies = this.state.Companies;
        console.log("companies > ",org," > ",companies)
        for(let i in companies){
            let row = companies[i];
            if(row.CustomerNumber===org){
                name = row.Name;
            }
        }
        return name;
    }
    validationInput=()=>{
        const msg=(field)=>{
            alert(`error [${field}] can't be empty! please provide and try again`)
            return false
        }
        if(this.state.selectedCompany===""){
            return msg("Company Name")
        }
        if(this.state.selectedProject===""){
            return msg("Project Name")
        }
        if(this.state.startDate===""){
            return msg("Start Data")
        }
        if(this.state.endDate===""){
            return msg("End Date")
        }

        return true
    }
    submitGenerateReport = async () => {
        if(!this.validationInput()){
            return
        }
        this.setState({Results:null,busy:true})
        let postData = {
            Org: this.state.selectedCompany,
            CompanyName:this.findCompanyName(this.state.selectedCompany),
            Project: this.state.selectedProject,
            StartDate: this.state.startDate,
            EndDate: this.state.endDate,
            EvaluatorDesignation:this.state.InputEvaluatorRole,
            EvaluatorName:this.state.InputEvaluatorName,
            RepresentativeRole:this.state.InputRepresentativeRole,
            RepresentativeName:this.state.InputRepresentativeName,
            ReportName:"Planned Labour Summary",
            PeriodName: moment(this.state.endDate,"YYYY-MM-DD").format("MMMM YYYY"),
            Client:"City of Cape Town",
            TenderNo:this.state.InputTenderNumber,
            Period:`${this.state.startDate}#${this.state.endDate}#${this.state.endDate}`
        }
        console.log("submitGenerateReport send > ", postData)
        let endpoint = "/labour/logs/export-monthly"
        await PostToPbx(postData, endpoint, async (output) => {
            if (output === null) {
                console.log("<>OUtput is empty")
                return
            }
            let data = output.RESULT;
            this.setState({
                Results:data.RESULT,
                busy:false
            })
            console.log("submitGenerateReport response > ", data.RESULT)
            alert("Thank you your pdf file ready to download")

        });
    }
    displayPdf = (dataIn) => {

        /*let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "pmis_labour_logs.csv");
        link.innerHTML = "Click Here to download";
        document.body.appendChild(link);
        link.click();*/
    }
    renderCompanyBox = () => {
        let ls = [];
        ls = FilterArrayByKey(this.state.Companies, {})
        ls = ArrayToSelectOption(ls, ["CustomerNumber"], ["Name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Company"}
                name={"selectedCompany"}
            />
        )
    }
    renderProjectBox = () => {
        let ls = [];
        for (let i in this.state.Projects) {
            let row = this.state.Projects[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            ls.push(row)
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["name"], ["name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Project"}
                name={"selectedProject"}
            />
        )
    }

    renderPdf = () => {
        if (this.state.Results === null) {
            return null
        }

        let downloadLink = this.state.Results.LinkDownload

        console.log("Ready for renderPdf> ",downloadLink," > ",this.state.Results)

        return (
            <div>
                <iframe
                    src={downloadLink}
                    title="Invoice"
                    style={{minWidth: "100%", minHeight: 1024, background: "#FFFFFF"}}
                    frameBorder="0"

                >
                    Presss me: <a href={this.state.downloadLink}>Download PDF</a>
                </iframe>
            </div>
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Labour Logging Monthly CoCT</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <Row gutter={18}>
                            <Col span={12}>
                                {this.renderCompanyBox()}
                            </Col>
                            <Col span={12}>
                                {this.renderProjectBox()}
                            </Col>
                        </Row>
                        <Row gutter={18} style={{marginTop: -30, paddingLeft: 10}}>
                            <Col span={12} style={{marginTop: 30, height: 40}}>
                                <DatePicker
                                    style={styles.datePickerBox}
                                    onChange={(date, dateString) => this.setState({startDate: dateString})}
                                    placeholder={"Start Date"}
                                />
                            </Col>
                            <Col span={12} style={{marginTop: 30, height: 40, paddingLeft: 10}}>
                                <DatePicker
                                    style={styles.datePickerBox}
                                    onChange={(date, dateString) => this.setState({endDate: dateString})}
                                    placeholder={"End Date"}
                                />
                            </Col>
                        </Row>

                        <div style={styles.inputBoxGroup}>
                            <Row gutter={18} style={{marginTop: -30, paddingLeft: 10}}>
                                <Col span={12} style={{marginTop: 30, height: 40}}>
                                    <Input
                                        style={styles.datePickerBox}
                                        onChange={(e) => this.setState({InputEvaluatorRole: e.target.value})}
                                        placeholder={"Officer Designation"}
                                    />
                                </Col>
                                <Col span={12} style={{marginTop: 30, height: 40}}>
                                    <Input
                                        style={styles.datePickerBox}
                                        onChange={(e) => this.setState({InputRepresentativeRole: e.target.value})}
                                        placeholder={"Company Representative Designation"}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={18} style={{marginTop: -20, paddingLeft: 10}}>
                                <Col span={12} style={{marginTop: 30, height: 40}}>
                                    <Input
                                        style={styles.datePickerBox}
                                        onChange={(e) => this.setState({InputEvaluatorName: e.target.value})}
                                        placeholder={"Officer Name"}
                                    />
                                </Col>
                                <Col span={12} style={{marginTop: 30, height: 40}}>
                                    <Input
                                        style={styles.datePickerBox}
                                        onChange={(e) => this.setState({InputRepresentativeName: e.target.value})}
                                        placeholder={"Company Representative Name"}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={18} style={{marginTop: -20, paddingLeft: 10}}>
                                <Col span={12} style={{marginTop: 30, height: 40}}>
                                    <Input
                                        style={styles.datePickerBox}
                                        onChange={(e) => this.setState({InputTenderNumber: e.target.value})}
                                        placeholder={"Tender No."}
                                    />
                                </Col>
                            </Row>



                        </div>


                        <Row gutter={18} style={{marginTop: 10, paddingLeft: 10}}>
                            <Col span={24} style={{marginTop: -5, height: 40}}>
                                <Button
                                    style={styles.datePickerBox}
                                    onClick={() => this.submitGenerateReport()}
                                >
                                    Generate Report
                                </Button>
                            </Col>
                        </Row>

                        {this.renderPdf()}
                    </div>
                </div>

                <LoadingProcessing open={this.state.busy}/>
            </Page>
        )

    }
}


export default Connector(LabourLoggingMonthlyCoCTPage);

const styles = {
    inputBoxGroup: {
        marginTop:5,
        padding:5,
        border:"1px solid black"
    },
    boxFilterInner: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 5,
        borderRight: "1px solid black",
        fontsize: 14,
        fontWeight: "bold"
    },
    boxFilter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    dateRow: {
        display: "flex",
        flexDirection: "row"
    },
    datePickerBox: {
        height: 40,
        width: "100%"
    },
    row1Th: {
        border: "1px solid black",
    },
    rowForm: {
        marginBottom: 10,
        paddingBottom: 5,
        border: "0px solid lightgray",
        borderBottomWidth: 1,

    },
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}