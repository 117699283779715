import React from "react";
import {Col, Form, Input, Row, Select, Table} from 'antd';
import {Button, Tooltip, Modal} from 'antd';
import {PlusCircleFilled} from '@ant-design/icons';
import {SubmitPost} from "../Common/localFunctions";
import {PostToPbx} from "../../api/http-post";
import {GetUserToken} from "../../api/token";
import GenericComboBox from "./GenericComboBox";
import {isValueBoolean, SortData, SortDataDesc} from "../../api/fn";
import {SearchOutlined} from '@ant-design/icons';

const {Option} = Select;
const {Search} = Input;

export default class PluginAddDeleteList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Projects: [],
            component: "list",
            newEntry: {},
            isInAction: false,
            selectedRecord: null,
            selectedKeyName: "",
            selectedKeyValue: "",
            selectedFieldStructure: null,
            actionPerform: "",
            selectedCompany: "",
            selectedProject: "",
            searchText: "",
        }
    }

    componentDidMount = async () => {
        await this.initializeData();
        await this.loadData()
    }
    initializeData = async () => {
        let userInfo = GetUserToken();
        let paramsProject = {};
        let paramsCompany = {};
        if (userInfo.Role !== "super") {
            paramsProject = [
                {key: "Org", val: userInfo.Org, type: "string"},
            ];
            paramsCompany = [
                {key: "CustomerNumber", val: userInfo.Org, type: "string"},
            ];
        }

        await this.fetchData("companies", "Companies", paramsCompany, null);
        await this.fetchData("modules", "Projects", paramsProject, null);
    }
    loadData = async () => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = this.props.targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            //console.log("loadInitialData response > ", post, " > ", data);
            let res = [];
            if (data !== null) {
                res = data.RESULT;
            }
            /*if(typeof _this.props.sortBy==="undefined"){
                res = SortDataDesc("orgdatetime",res)
            }else{
                res = SortDataDesc(_this.props.sortBy,res)
            }*/
            await _this.setState({
                Data: res
            });
            _this.arrayHolder = res;
            console.log("loadInitialData response > ", table, " > ", data, " > ", _this.state);
        });
    }
    fetchData = async (table, stateKey, params, feedback) => {
        let _this = this;
        let userInfo = GetUserToken();
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = params;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })
        await PostToPbx(post, endpoint, async (data) => {
            let res = [];
            if (data !== null) {
                res = data.RESULT;
            }
            await _this.setState({
                [stateKey]: res
            });
            console.log("fetchData response === > ", table, " > ", post, data);
            if (feedback !== null) {
                feedback(res)
            }
        });
    }
    handleChangeCompany = async (val, key) => {
        this.setState({
            [key]: val,
        });
        this.setParentState(key, val);
    }
    handleNewEntry = (e, key) => {
        const val = e.target.value;
        let tmp = this.state;
        tmp.newEntry[key] = val;
        this.setState(tmp);
        this.setParentState(key, val);
    }
    handleChange2 = (e, ee, key) => {
        const val = e;
        let tmp = this.state;
        tmp.newEntry[key] = val;
        this.setState(tmp);
        this.setParentState(key, val);
    }
    setParentState = (key, val) => {
        let tmp = this.props._this.state;
        if (typeof tmp.newEntry[key] === "undefined") {
            tmp.newEntry[key] = "";
        }
        tmp.newEntry[key] = val;
        tmp[key] = val;
        this.props._this.setState(tmp);
    }
    clickAdd = () => {
        let {fields} = this.props;
        let newEntry = {};
        for (let i in fields) {
            const row = fields[i];
            let key = row.name;
            newEntry[key] = "";
        }
        this.setState({
            component: "form",
            newEntry: newEntry
        });
    }
    cancelSave = async () => {
        this.setState({
            component: "list",
            newEntry: {},
        });
    }
    saveData = async () => {
        let userInfo = GetUserToken();
        let self = this;
        let {_this, stateArray, table, fields} = this.props;
        let tmp = _this.state;
        console.log("SaveData newEntry > ", this.state);
        tmp[stateArray].push(this.state.newEntry);
        _this.setState(tmp);
        this.setState({
            component: "list",
            newEntry: {},
        });
        let contract = this.props.selectContract;
        let data = this.makeDataTypeEntry(fields, this.state.newEntry);
        let hub = {...data};
        /*
        injectFields={[
                                {rowKey:"Org",stateKey:"selectedCompany"},
                                {rowKey:"Module",stateKey:"selectedProject"}
                            ]}
         */

        if (typeof this.props.injectFields !== "undefined") {
            for (let i in this.props.injectFields) {
                let row = this.props.injectFields[i];
                hub[row.rowKey] = this.state[row.stateKey];
                if (this.state[row.stateKey] === "") {
                    return alert(row.errorMsg)
                }
            }
        } else {
            hub.Org = userInfo.Org;
        }

        /*
        injectStaticValue={[
                                {key:"category",val:"entities"}
                            ]}
         */
        /**
         * let inject our status values
         */
        if (typeof this.props.injectStaticValue !== "undefined") {
            for (let i in this.props.injectStaticValue) {
                let row = this.props.injectStaticValue[i];
                hub[row.key] = row.val;
            }
        }


        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = hub;
        post.Params = {}
        let endpoint = "/common/entity/" + table + "/insert"; // "/manager/entity/insert/extensions";
        if(typeof this.props.specialInsertEndpoint !=="undefined"){
            endpoint = this.props.specialInsertEndpoint;
            post=hub
        }
        // let endpoint =this.props.postEndpoint; // "/manager/entity/insert/extensions";
        this.setState({
            isLoading: true,
        })
        await PostToPbx(post, endpoint, async (data) => {
            await self.loadData()
        });
    }
    requestAction = (row, text) => {
        let targetRecord = {};
        let keyName = "";
        for (let i in this.state.Data) {
            const rec = this.state.Data[i];
            keyName = row.name;
            if (typeof rec[keyName] === "undefined") {
                continue
            }
            if (rec[keyName] !== text) {
                continue
            }
            targetRecord = rec
        }
        console.log("F1 > ",targetRecord)
        this.setState({
            isInAction: true,
            selectedRecord: targetRecord,
            selectedKeyName: keyName,
            selectedKeyValue: text,
            selectedFieldStructure: row,
        })
    }
    requestDeleteRecord = async (record) => {
        let userInfo = GetUserToken();
        let self = this;
        let {_this, stateArray, table, fields} = this.props;
        const deleteKeys = this.props.deleteKeys;
        const msg = this.state.selectedKeyName.toLocaleUpperCase() + ": " + this.state.selectedKeyValue.toLocaleUpperCase();
        if (!window.confirm("Are you sure to delete record - " + msg + " ?")) {
            return
        }

        let params = [];
        for (let i in deleteKeys) {
            const row = deleteKeys[i];
            const key = row.key;
            const type = row.type;
            const val = record[key];
            params.push({
                key: key,
                type: type,
                val: val
            })
        }

        let hub = {...record};

        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = hub;
        post.Params = params
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/delete"; // "/manager/entity/insert/extensions";
        // let endpoint =this.props.postEndpoint; // "/manager/entity/insert/extensions";
        this.setState({
            isLoading: true,
        })
        await PostToPbx(post, endpoint, async (data) => {
            await self.loadData();
            self.cancelActionPerform()
        });

    }
    requestEditRecord = (record) => {

    }
    cancelActionPerform = () => {
        this.setState({
            isInAction: false,
            selectedRecord: null,
            selectedKeyName: "",
            selectedKeyValue: "",
            selectedFieldStructure: null,
            actionPerform: "",
        })
    }


    makeDataTypeEntry = (fields, newEntry) => {
        const buildListOption = (inString) => {
            let arr = inString.split(",");
            let str = {}
            for (let i in arr) {
                let key = arr[i];
                str[key] = key;
            }
            return str
        }
        const findDataInEntry = (fieldName) => {
            for (let i in fields) {
                const row = fields[i];
                if (row.name === fieldName) {
                    return row.dataType;
                }
            }
            return "string"
        }
        let ls = {};
        for (let i in newEntry) {
            const fDataType = findDataInEntry(i);
            let val = newEntry[i];
            if (fDataType === "float" || fDataType === "number") {
                val = parseFloat(val);
            } else if (fDataType === "boolean") {
                val = val === "yes"
            } else if (fDataType === "list") {
                val = buildListOption(val)
            }
            ls[i] = val;
        }
        return ls;
    }

    renderProjectSelection = () => {
        if (typeof this.props.filterByModule === "undefined") {
            return null
        } else {
            if (!this.props.filterByModule) {
                return null;
            }
        }
        let data = [];
        let company = this.state.selectedCompany;
        for (let i in this.state.Projects) {
            let row = this.state.Projects[i];
            console.log("&&&&--isMo> ", company, " > ", row.org)
            if (company === row.org) {
                data.push(row)
            }
        }
        return (
            <GenericComboBox
                displayConditionStateKey={this.state.component}
                displayConditionStateValue={"list"}
                data={data}
                label={"Select Project"}
                name={"selectedProject"}
                message={"Please select your project!"}
                handleChange={this.handleChangeCompany}
                getValue={this.state.selectedProject}
                recordOptionKey={"name"}
                recordOptionValue={"name"}
                recordOptionDisplay={"name"}
                noCondition={true}
            />
        )
    }
    renderCompaniesSelection = () => {
        if (typeof this.props.filterByCompany === "undefined") {
            return null
        } else {
            if (!this.props.filterByCompany) {
                return null;
            }
        }

        console.log("renderCompaniesSelection > ",this.state.Companies)

        return (
            <GenericComboBox
                displayConditionStateKey={this.state.component}
                displayConditionStateValue={"list"}
                data={this.state.Companies}
                label={"Select Company"}
                name={"selectedCompany"}
                message={"Please select your company!"}
                handleChange={this.handleChangeCompany}
                getValue={this.state.selectedCompany}
                recordOptionKey={"customernumber"}
                recordOptionValue={"customernumber"}
                recordOptionDisplay={"name"}
                noCondition={true}
            />
        )
    }
    renderExtraFilter = () => {
        /**
         *
         extraFilters={[
                                {
                                    name:"selectedActivity",
                                    label:"Activity",
                                    options:this.getActivitiesOptions(),
                                    errorMsg: "Sorry you can't save, Please select your Activity!"
                                }
                            ]}
         *
         */
        if (typeof this.props.extraFilters === "undefined") {
            return null
        }

        return this.props.extraFilters.map(row => {
            return (
                <div style={{float: "left", minWidth: "30%", maxWidth: "30%"}}>
                    <GenericComboBox
                        displayConditionStateKey={this.state.component}
                        displayConditionStateValue={"list"}
                        data={row.options}
                        label={row.label}
                        name={row.name}
                        message={row.errorMsg}
                        handleChange={this.handleChangeCompany}
                        getValue={this.state[row.name]}
                        recordOptionKey={row.recordKey}
                        recordOptionValue={row.recordValue}
                        recordOptionDisplay={row.recordKey}
                        noCondition={true}
                    />
                </div>
            )
        })
    }
    getDataFromSearch=()=>{
        let ls = this.state.Data;
        let textInput  = this.state.searchText;
        let newData = ls.filter(item => {
            const itemData = JSON.stringify(item).toLocaleLowerCase();
            const textData = textInput.toLocaleLowerCase();
            return itemData.indexOf(textData) > -1
        });
        return newData;
    }
    renderList = () => {
        let {fields, addFormText, stepTitle} = this.props;
        let data = this.getDataFromSearch();
        let columns = [];
        /**
         * let check for order number
         */
        if(typeof this.props.orderBy !=="undefined"){
            data  = SortData(this.props.orderBy,data)
        }
        const renderText = (text, name) => {
            if (typeof this.props.depending === "undefined") {
                return text;
            }
            let val = text;
            if (typeof this.props.depending[name] !== "undefined") {
                let row = this.props.depending[name];
                let storage = this.props._this.state[row.storageTable];
                let targetKey = row.targetKey;
                let targetDisplay = row.displayKey;
                for (let i in storage) {
                    let rec = storage[i];
                    if (rec[targetKey] === text) {
                        return rec[targetDisplay];
                    }
                }
            }
            return val;
        }

        let index = 0;
        for (let i in fields) {

            const row = fields[i];
            if (typeof row.show !== "undefined") {
                if (!row.show) {
                    continue
                }
            }
            if (index === 0) {
                columns.push({
                    title: row.label,
                    dataIndex: row.name,
                    render: text => <a onClick={() => this.requestAction(row, text)}>{renderText(text, row.name)}</a>,
                })
            } else {

                columns.push({
                    title: row.label,
                    dataIndex: row.name,
                    align: 'right',
                })
            }
            index++
        }

        /*data.push({
            "StartDate": "2020-08-01",
            "EndDate": "2021-08-01",
            "TotalMonth": "12",
            "TotalDay": "365",
            "TotalWeek": "48",
            "TotalYear": "1"
        });*/
        let _this = this;
        const canDisplayRecord = (rowIn) => {
            if (typeof _this.props.displayRules === "undefined") {
                return true;
            }
            // {rowKey:"org",stateKey:"selectedCompany"}
            let data = _this.props.displayRules;
            for (let i in data) {
                let row = data[i];
                if (rowIn[row.rowKey] !== _this.state[row.stateKey]) {
                    return false;
                }
            }

            if (typeof _this.props.extraFilters !== "undefined") {
                for (let i in _this.props.extraFilters) {
                    let row = _this.props.extraFilters[i];
                    if (row.allowBlank) {
                        if (_this.state[row.filterStateKey] === "") {
                            return true
                        }
                    }
                    if (rowIn[row.filterRecordKey] !== _this.state[row.filterStateKey]) {
                        return false;
                    }
                }
            }

            return true;
        }

        const replaceBooleanValue = (rowIn) => {
            let ls = {};
            for (let i in rowIn) {
                let val = rowIn[i];
                let isBool = false;
                for (let e in fields) {
                    const rec = fields[e];
                    if (rec.name === i) {
                        if (rec.dataType === "boolean") {
                            isBool = true;
                        }
                    }
                }
                if (isBool) {
                    val = val ? "Yes" : "No"
                }
                ls[i] = val;

            }
            return ls;
        }

        let ls = [];
        for (let i in data) {
            let row = data[i];
            if (typeof row.show !== "undefined") {
                if (!row.show) {
                    continue
                }
            }
            row = replaceBooleanValue(row);
            let index = parseInt(i);
            row.key = index;
            if (canDisplayRecord(row)) {
                ls.push(row);
            }
        }

        console.log("*******8888---> ", ls, " > ", data)


        return (
            <div style={{minWidth: "100%", padding: 5,}}>

                <div style={{
                    selfAlign: "right",
                    minWidth: "100%",
                    maxWidth: "100%",
                    minHeight: 38,
                    maxHeight: 38,
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    padding: 0,
                    /*backgroundColor: "green",*/
                }}>
                    {/*{this.renderCompaniesSelection()}
                    {this.renderProjectSelection()}*/}
                    <Row>
                        <Col span={8}>
                            <div style={{float: "left", minWidth: "100%", maxWidth: "100%"}}>
                                {this.renderCompaniesSelection()}
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={{float: "left", minWidth: "100%", maxWidth: "100%"}}>
                                {this.renderProjectSelection()}
                            </div>
                        </Col>
                        <Col span={6}>
                            <Button
                                type="link"
                                icon={<PlusCircleFilled/>}
                                onClick={() => this.clickAdd()}
                                style={{float: "right", borderRadius: 15}}
                            >
                                ADD NEW {addFormText}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {this.renderExtraFilter()}
                        </Col>
                    </Row>

                            <div style={{float: "left", minWidth: "100%", maxWidth: "100%", marginButton: 10}}>
                                <Search
                                    allowClear
                                    onChange={(e) => this.handleChangeCompany(e.target.value, "searchText")}
                                    style={{width: "100%"}}
                                    size="small"
                                    placeholder="small size"
                                    prefix={<SearchOutlined/>}
                                />
                            </div>


                </div>
                <Table
                    columns={columns}
                    dataSource={ls}
                    bordered
                    /* title={() => 'Period'}*/
                    /*footer={() => 'Footer'}*/
                />
            </div>

        )

    }
    renderForm = () => {
        let {fields, stepTitle} = this.props;

        return (
            <EntryForm
                fields={fields}
                numSpan={11}
                saveData={this.saveData}
                cancelSave={this.cancelSave}
                handleChange={this.handleNewEntry}
                handleChange2={this.handleChange2}
                state={this.state}
                stepTitle={stepTitle}
            />
        )

    }
    renderRequestActionPopup = () => {
        if (!this.state.isInAction) {
            return
        }
        let _this = this;
        function isObject(objValue) {
            return objValue && typeof objValue === 'object' && objValue.constructor === Object;
        }
        const renderP = (key, val) => {
            return (
                <p>{key} : {val}</p>
            )
        }
        const avoidKeys = (keyIn) => {
            let arr = ["id", "appname", "org", "profile", "status", "orgdatetime", "key"];
            for (let i in arr) {
                const item = arr[i];
                if (item === keyIn) {
                    return false
                }
            }
            return true
        }
        let visible = this.state.isInAction;
        let record = this.state.selectedRecord;
        let lsData = [];
        for (let i in record) {
            if (!avoidKeys(i)) {
                continue
            }
            let val = record[i];
            if (isValueBoolean(val)) {
                val = val ? "Yes" : "No"
            }
            if(isObject(val)){
                val = JSON.stringify(val)
            }
            lsData.push({
                key: i,
                val: val,
            })
        }

        const titleForm = "Action Perform " + this.state.selectedKeyName.toLocaleUpperCase() + ": " + this.state.selectedKeyValue.toLocaleUpperCase();
        return (
            <Modal
                title={titleForm}
                visible={visible}
                onOk={this.cancelActionPerform}
                onCancel={this.cancelActionPerform}
            >
                {lsData.map(rec => {
                    return renderP(rec.key, rec.val)
                })}
                <React.Fragment>
                    <br/>
                    <Button
                        danger={true}
                        onClick={() => this.requestDeleteRecord(record)}
                    >Delete Record</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                        type={"dashed"}
                        onClick={() => this.requestEditRecord(record)}
                    >Edit Record</Button>&nbsp;&nbsp;

                </React.Fragment>
            </Modal>
        )
    }

    render() {
        return (
            <div style={{minWidth: "100%", maxWidth: "100%"}}>

                {this.state.component === "list" ? this.renderList() : this.renderForm()}
                {this.renderRequestActionPopup()}
            </div>
        )
    }
}

export const EntryForm = (props) => {

    let {fields, numSpan, saveData, handleChange, cancelSave, state, handleChange2, stepTitle} = props;
    let numberCol = 24 / numSpan;
    numberCol = parseInt(numberCol);
    let arrCol = [];
    for (let i = 0; i < numberCol; i++) {
        let n = i + 1
        arrCol.push(n);
    }
    return (

        <Form
            layout="vertical"
        >

            <Row>
                <h3 style={{
                    float: "left",
                    color: "lightblue",
                    marginTop: 10,
                    fontSize: 18,
                    marginLeft: 10,
                    minWidth: "100%"
                }}>{stepTitle}</h3>
            </Row>
            <Row>
                {fields.map((row) => {
                    if (row.dataType === "option" || row.dataType === "boolean") {
                        return (
                            <Col style={styles.gutterBox} span={numSpan}>
                                <Form.Item label={row.label} required={row.required}>
                                    <Select
                                        name={row.name}
                                        defaultValue={state.newEntry[row.name]}
                                        value={state.newEntry[row.name]}
                                        onChange={(e, ee) => handleChange2(e, ee, row.name)}
                                    >
                                        {row.options.map((record, index) => {
                                            return (
                                                <Option value={record.key}>{record.val}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )
                    }
                    return (
                        <Col style={styles.gutterBox} span={numSpan}>
                            <Form.Item label={row.label} required={row.required}>
                                <Input
                                    name={row.name}
                                    placeholder={"input " + row.label}
                                    onChange={(e) => handleChange(e, row.name)}
                                />
                            </Form.Item>
                        </Col>
                    )
                })}
            </Row>
            <Row>
                {arrCol.map(index => {
                    if (index !== numberCol) {
                        return (
                            <Col style={styles.gutterBox} span={numSpan}>&nbsp;</Col>
                        )
                    }
                    return (
                        <Col
                            style={{justifyContent: "flex-end", display: "flex", padding: 10, paddingRight: -10}}
                            span={numSpan}
                        >
                            <Button onClick={() => cancelSave()}>Cancel</Button>&nbsp;&nbsp;
                            <Button onClick={() => saveData()} danger>Save</Button>
                        </Col>
                    )
                })}
            </Row>
        </Form>

    )

}

const styles = {
    gutterBox: {
        padding: "8px 0",
        margin: 10,
        /*backgroundColor: "#00a0e9",
        minWidth: "50%",
        maxWidth: "50%"*/
    },
    stepsContent: {
        marginTop: 16,
        border: "1px dashed #e9e9e9",
        borderRadius: 2,
        backgroundColor: "#fafafa",
        minHeight: 200,
        textAlign: "center",
        paddingTop: 80,
    },
    stepsAction: {
        marginTop: 24,
    }
}
