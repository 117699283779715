import React from "react";
import Connector from "../../../redux/connector";
import Page from "../../../layout/web/Page";
import Title from "../../Common/Title";
import LoadingProcessing from "../../Common/page/Common/LoadingProcessing";
import {fetchDataEntity} from "../../../api/entity-data";
import {FindFieldFromRecordWithData,  SortData, UpCaseFirst} from "../../../api/fn";
import GridItem from "../../Common/dashboard/Grid/GridItem";
import TableDataView from "../../Common/tables/TableDataView";
import {GetRowOptions} from "../../../api/fn";
import {SubmitPost}from "./localFunctions";
import {Alert, Button, Form, Input, Select} from "antd";
import {GetUserToken} from "../../../api/token";
const {Option} = Select;



class PageBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies:[],
            selectedCompany:"",
            Data:[],
            isLoading:false,
            component:"list",
        }
    }
    componentDidMount = async () => {
       // IsNotSuperUser();
        await this.loadInitialData();
        await fetchDataEntity("Companies", "client/entity", "company", this)
    }
    loadInitialData = async () => {
        for(let i in this.props.StateStorage) {
            let store = this.props.StateStorage[i];
            await fetchDataEntity(store.stateField, store.moduleEntity, store.table, this, store.noCondition,store.inEndpoint)
        }
        //await fetchDataEntity("Data", "manager/entity", "extensions", this, false);

    }
    handleChange = (value,key) => {
        this.setState({
            [key]: value,
        })
    }

    onFinish = async (values) => {
        if (!window.confirm(this.props.postMsgWarning /*"Are you sure you want to submit this extension ?"*/)) {
            return
        }
        let _this=this;
        const findRecord=(name)=>{
            let rows=_this.props.bldFields;
            for(let i in rows){
                let row=rows[i];
                if(row.StateKey===name){
                    return row.type
                }
            }
            return "text"
        }
        let post={}
        post.AutoGenerate=_this.props.AutoGenerate;
        post.HasUniqueKey=_this.props.HasUniqueKey;
        post.AutoGenerateField=_this.props.AutoGenerateField;

        let hub={};
        hub.Org = this.state.selectedCompany;

        for(let key in values){
            let val=values[key];
            const type  =findRecord(key);
            if(type==="float"){
                val = parseFloat(val)
            }
            if(type==="number"){
                val = parseInt(val)
            }
            if(type==="boolean"){
                val = val==="yes"
            }
            hub[key] = val;
        };
        let endpoint =this.props.postEndpoint; // "/manager/entity/insert/extensions";
        this.setState({
            isLoading:true,
        });

        post.Data = hub;
        post.Params={}

        console.log("PageBuilder post > ",post);

        await SubmitPost(post, endpoint, this.props.postMsgResponse,this)
    };
    onAddRecord = () => {
        let {includeSelection}=this.props;

        if(this.state.selectedCompany===""&& typeof includeSelection ==="undefined"){
            return alert("Sorry please select your company?")
        }
        this.setState({
            component: "form"
        })
    }
    reloadPage=async ()=>{
        //window.location.reload();
        await this.loadInitialData()
    }
    onDelete =async (row) => {
        let _this=this;
        if (!window.confirm("Are you sure you want to remove this inbound queue ?")) {
            return
        }
        console.log('onDelete:', row);
        this.setState({
            component: "list"
        });

        let params=[];
        for(let i in this.props.ParamsDelete){
            let rec =this.props.ParamsDelete[i];
            let val=row[rec.key];
            rec.val = val;
            params.push(rec)
        }
        let hub={}
        hub.AutoGenerate=_this.props.AutoGenerate;
        hub.HasUniqueKey=_this.props.HasUniqueKey;
        hub.AutoGenerateField=_this.props.AutoGenerateField;
        hub.Params = params

        // postEndpoint={"/pdf/entity/insert/info"}
        let postEndpoint = this.props.postEndpoint;

        let endpoint =postEndpoint.replace("/insert","/delete");// "/manager/entity/remove/extensions";
        console.log("onDelete > ",endpoint," > ",row)
        await SubmitPost(hub, endpoint, "Tank you, Extension removed",this)

    }

    renderList=()=>{
        let {
            injectFunctionAllowed,
            injectFunctionKey,
            injectFunctionAction,
            injectFunctionIcon,
            injectFunctionTitle,
            injectFunctionTitleStyle
        }=this.props;
        let user = GetUserToken();
        let columns=this.props.displayListColumns;
        if (this.state.component !== "list") {
            return
        }

        if(typeof this.props.listKeyToLower !=="undefined") {
            if (this.props.listKeyToLower) {
                let tmpColumns=[];
                for(let i in columns){
                    let row=columns[i];
                    let o={
                        id: row.id!=="Client"?row.id.toLocaleLowerCase():row.id,
                        label:row.label,
                    }
                    tmpColumns.push(o)
                }
                columns = tmpColumns
            }
        }

        let ls = []; //this.state.Data;
        let {displayTableKeyState}=this.props;
        let {hasTranslateField,translateFields}=this.props;
        console.log("^^^^^^---> ",displayTableKeyState,this.state[displayTableKeyState]);
        for(let i in this.state[displayTableKeyState]){
            let row = this.state[displayTableKeyState][i];
            ls.push(row);
        }
        if(hasTranslateField){
            let tmpList =[];
            for(let a in ls){
                let row = ls[a];
                for(let i in translateFields) {
                    let record = translateFields[i];
                    row[record.keyName] = FindFieldFromRecordWithData(
                        record.keySearch,//"CustomerNumber",
                        row[record.keyValue], // org
                        record.keyReturn, //"Name",
                        this.state[record.stateEntity], //this.state.Companies
                    );
                }

                /**
                 * let check for privilege
                 *
                 */
                /*if(user.Role!=="super"){
                    if(user.Org!==row.Org){
                        continue
                    }
                }*/
                let comp="";
                if(typeof row.Org!=="undefined"){comp= row.Org}
                if(typeof row.org!=="undefined"){comp= row.org}

                if(comp !==this.state.selectedCompany  ){
                    continue
                }

                //TODO let check for injection

                tmpList.push(row)
            }

            ls =tmpList;
        }

        //TODO let check for injection


        if(typeof injectFunctionAllowed!=="undefined") {
            if (injectFunctionAllowed) {
                let tmpList=[];
                for(let i in ls){
                    let row = ls[i];
                    let val=row[injectFunctionKey];
                    console.log("1 (:)------> ",injectFunctionKey," > ",row[injectFunctionKey]," > ",typeof injectFunctionAllowed!=="undefined")

                    row[injectFunctionKey]= ( <span /*onClick={()=>injectFunctionAction(row)}*/
                               style={[...injectFunctionTitleStyle,{cursor:"pointer"}]}
                            >
                                {injectFunctionTitle}
                            </span>);

                    tmpList.push(row)
                }
                ls = tmpList;
            }
        }


        ls = SortData("Position",ls)

        let data = {columns: columns, data: ls};
        let {allowedDelete,otherFunctions,allowedAdd}=this.props;

        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Records")}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                    allowDelete={typeof allowedDelete!=="undefined"}
                    allowedAdd={allowedAdd}
                    otherFunctions={otherFunctions}
                />
            </GridItem>
        )
    }
    renderForm=()=>{
        if (this.state.component !== "form") {
            return
        }

        let {entityOptions}=this.props;//[]; // let registries=[],trunks=[],agents=[],ranges=[],extensions=[];
        let storage={};
        for(let i in entityOptions){
            const recordOption = entityOptions[i];
            const keyLocalStore =recordOption.storeVariable;
            const keyState = recordOption.keyState;
            const targetKey = recordOption.targetKey;
            storage[keyLocalStore]=[];

            for(let i in this.state[keyState]){
                const row = this.state[keyState][i];
                if(row[targetKey] ===this.state[targetKey]){
                    storage[keyLocalStore].push(row)
                }
            }
        }

        let inputList = [];

        const {bldOptions}=this.props;

        for(let i in bldOptions){
            let record = bldOptions[i];
            let lookInState = record.lookInState;
            let targetStorage = record.targetStorage;
            let stateStorage = this.state[targetStorage];
            if(typeof  record.Conditions!=="undefined"){
                let lsInner=[];
                console.log("FCC 1 > ",targetStorage,stateStorage)
                for(let a in stateStorage){
                    const rc1 =stateStorage[a];
                    let boo=true;
                    for(let c in record.Conditions){
                        const rec2=record.Conditions[c];
                        if(typeof this.state[rec2.targetStateKey] !=="undefined"){//this means key exist on the state
                            const tValue=rc1[rec2.localField];
                            const actValue = this.state[rec2.targetStateKey]
                            if(tValue!==actValue){
                                boo=false
                            }
                        }else{//even if the key doesn't exist dont add til selected
                            boo=false
                        }
                    }

                    if(boo){
                        lsInner.push(rc1)
                    }
                }
                stateStorage = lsInner
            }

            let data = GetRowOptions(
                record.key,
                record.value,
                lookInState?stateStorage: storage[targetStorage],
                );


            inputList.push({
                name: record.StateKey,
                label: record.label,// "Name of Company",
                type: "option",
                required: true,
                options: data,
                message:record.message,// "Please input your company name"
            })
        }

        let {bldFields}=this.props;
        for(let i in bldFields){
            let row=bldFields[i];
            inputList.push({
                name: row.StateKey,
                label: row.label,// "Name of Company",
                type: row.type,
                required: row.required,
                options: row.options===undefined?[]:row.options,
                message:row.message,// "Please input your company name"
            })
        }

        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };
        const tailLayout = {
            wrapperCol: {offset: 8, span: 16},
        };

        return (
            <Form
                {...layout}
                onSubmit={this.handleSubmit}
                className="login-form"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                name="basic"
                initialValues={{remember: true}}
                style={{minWidth: "50%"}}
            >
                <Alert style={{margin: '16px 0'}} message={this.props.newFormTitle}/>

                {inputList.map((row) => {
                    if (row.type === "option" || row.type==="boolean") {
                        return (
                            <Form.Item
                                {...tailLayout}
                                label={row.label}
                                name={row.name}
                                rules={[{required: row.required, message: row.message}]}
                            >
                                <Select
                                    name={row.name}
                                    defaultValue=""
                                    /*style={{ width: 120 }}*/
                                    onChange={(e)=>this.handleChange(e,row.name)}
                                >
                                    {row.options.map((record, index) => {
                                        return (
                                            <Option value={record.key}>{record.val}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        )
                    }
                    return (
                        <Form.Item
                            {...tailLayout}
                            label={row.label}
                            name={row.name}
                            rules={[{required: row.required, message: row.message}]}
                        >
                            <Input
                                onChange={this.handInput}
                                type={row.type}
                                /*prefix={<LockOutlined type={row.prefix} style={{fontSize: 13}}/>}*/
                            />
                        </Form.Item>
                    )
                })}

                <Form.Item {...tailLayout}>
                    <Button
                        {...tailLayout}
                        type="secondary"
                        htmlType="submit"
                        style={{marginRight: 20,float:"right",marginLeft:10}}
                        onClick={() => this.setState({component: "list"})}
                    >
                        Cancel
                    </Button>

                    <Button type="primary" htmlType="submit" style={{float:"right",marginLeft:10}}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )





    }
    handleChangeCompany = (value, key) => {

        this.setState({
            [key]: value,
        })
    }
    renderCompaniesSelection = () => {
        let {includeSelection}=this.props;
        if(typeof includeSelection !=="undefined"){
            return null;
        }
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={"Select Company"}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedCompany")}
                    value={this.state.selectedCompany}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }

    renderAlert=()=>{
        let {includeSelection}=this.props;
        if(typeof includeSelection !=="undefined"){
            return null;
        }
        if(this.state.selectedCompany !== ""){
            return null;
        }
        return(
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Alert style={{ margin: '16px 0' }} message="Please select your company above to view your records!" type={"error"}/>

            </div>
        )
    }
    render() {
        let props = this.props;
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    overflow: "auto"
                }}>
                    <Title style={styles.title}>{props.pageTitle}</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        {this.renderCompaniesSelection()}
                    </div>
                    {this.renderAlert()}
                    {this.renderList()}
                    {this.renderForm()}

                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }

}

export default Connector(PageBuilder);

const styles = {
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
