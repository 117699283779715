import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import {
    GetMonthList,
    GetYearList,
    LoadDataCommon,
    BuilderOptionComboBox,
    CheckConditions,
    BuilderTableList
} from "../../../../api/fn";
import moment from "moment";
import Title from "../../../../components/Common/Title";
import {Col, Form, Row, Select, Divider, Table, DatePicker, Button} from "antd";
import Page from "../../../../layout/web/Page";
import Connector from "../../../../redux/connector";
import DrawerNewEntry from "../../../../components/General/DrawerNewEntry";
import {UploadToDropBoxBase64WithBusket} from "../../../../services/dropbox-uploader";
import {PostToPbx} from "../../../../api/http-post";
import LoadingProcessing from "../../../../components/Common/page/Common/LoadingProcessing";
import DrawerEditEntry from "../../../../components/General/DrawerEditEntry";
import SwitchPeriodInvoice from "./SwitchPeriodInvoice2";
import {FindFinancialYearRange, GetFinancialYearRange, isDateBetween} from "../../../../api/date-functions";

class InvoiceAccountStatementPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Clients: [],
            Items: [],
            Data: [],
            isLoading: false,
            newEntry: {
                Year: moment().year()
            },
            newHaddock: {},
            selectedCompany: "",
            selectedClient: "",
            selectedItem: "",

            StartDate:moment().format("YYYY-MM-DD"),
            EndDate:moment().format("YYYY-MM-DD"),

            Result: null,

        }

        this.Data = [];
    }

    componentDidMount = async () => {
        await this.loadInitialData();
        this.Data = this.state.Data;
        this.setState({
            financeYearInfo:FindFinancialYearRange(this.state.financialYearMonth,this.state.financialYearReferenceDate)
        })
    }
    loadInitialData = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await LoadDataCommon("modules", "Modules", this);
        await LoadDataCommon("SettingInvoiceServiceDebtors", "Clients", this);
        await LoadDataCommon("SettingInvoiceServiceItem", "Items", this);
        await LoadDataCommon("SettingInvoiceServiceItem", "Items", this);
        await LoadDataCommon("PurchaseOrder", "PurchaseOrders", this);
        await LoadDataCommon("InvoiceNumberInfo", "Sequences", this);
        await LoadDataCommon("SettingInvoiceServiceAddonPeriod", "Periods", this);
        await LoadDataCommon("SettingInvoiceServiceAddonSite", "Sites", this);
        await LoadDataCommon("InvoiceTracker", "InvoiceTrackers", this);
    }

    handleInput = (val, key) => {
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp)
    }
    onSubmit= async () => {

        /**
         * let now generate our adhoc invoice
         */
        let _this = this;
        let state = this.state;

        let post = {
            Org: state.selectedCompany,
            Client: state.selectedClient,
            StartDate : state.StartDate,
            EndDate: state.EndDate,
        }

        let endpoint = "/invoice/account/statement";
        this.setState({
            isLoading: true,
            Result:null
        });
        console.log("submitGenerate send > ", post);
        await PostToPbx(post, endpoint, async (data) => {
            let result = null;
            if(data!==null){
                result =  data.RESULT;
            }else{
                alert("Sorry invoice can not be generator due to the missing setting, check if the following is set: header, backing detail")
            }
            _this.setState({isLoading: false, Result: result});
        });

    }

    renderIFrameInvoice = () => {
        let info = this.state.Result;
        var delayInMilliseconds = 500; // half a second
        let link = `https://cloudcalls.easipath.com/backend-pmis/api/files/download/${info.DropBoxRev}`

        setTimeout(function () {
            let iframe = document.getElementsByTagName('iframe')[0];

        }, delayInMilliseconds);
        return (
            <iframe
                src={link}
                title="Invoice"
                style={{minWidth: "100%", minHeight: 1024, background: "#FFFFFF"}}
                frameborder="0"

            >
                Presss me: <a href={link}>Download PDF</a>
            </iframe>
        )
    }

    render() {
        console.log("this.state (--) ", this.state)
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Invoice Account Statement</Title>

                    <Divider style={{color: "red"}}>Filter</Divider>
                    <BuilderOptionComboBox
                        data={this.state.Companies}
                        keyVal={"CustomerNumber"}
                        keyLabel={"Name"}
                        label={"Select Organization"}
                        conditions={[]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedCompany"}
                        currentValue={this.state.selectedCompany}
                        comboId={"company"}
                    />
                    <BuilderOptionComboBox
                        data={this.state.Clients}
                        keyVal={"mame"}
                        keyLabel={"name"}
                        label={"Select Debtor"}
                        conditions={[
                            {key: "org", val: this.state.selectedCompany}
                        ]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedClient"}
                        currentValue={this.state.selectedClient}
                        comboId={"client"}
                    />

                    <Row style={{marginTop:20,marginBottom:20}}>
                    <Col span={12} >
                        <div className={"datePicker"}>
                            <span>Start Date:</span>
                            <DatePicker
                                onChange={(date, dateString) => this.setState({"StartDate": dateString})}
                                placeholder={"Start Date"}
                                style={{width:"100%"}}
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className={"datePicker"}>
                            <span>End Date:</span>
                            <DatePicker
                                onChange={(date, dateString) =>this.setState({"EndDate": dateString}) }
                                placeholder={"End Date"}
                                style={{width:"100%"}}
                            />
                        </div>

                    </Col>
                </Row>

                    <Row>
                        <Col span={24} >

                                <Button onClick={() => this.onSubmit()}
                                        style={{
                                            minWidth: "100%",
                                            border: "0.5px solid red",
                                            borderRadius: 5,
                                            color: "white",
                                            fontWeight: "bold",
                                            fontSize: 16
                                        }}
                                        type={"primary"}>
                                    Generate Account statement
                                </Button>
                        </Col>
                    </Row>

                    {this.state.Result !== null ?
                        <Row>
                            <Col span={24}>
                                {this.renderIFrameInvoice()}
                            </Col>
                        </Row> : null}


                    <Row>
                        <Col span={24}>
                            <LoadingProcessing open={this.state.isLoading}/>
                        </Col>
                    </Row>


                </div>
            </Page>
        )
    }

}


export default Connector(InvoiceAccountStatementPage);

const styles = {
    podNumber: {
        color: "orange",
    },
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

