import react, {useState} from "react"
import {Button, Col, DatePicker, Divider, Drawer, Form, Input, Row} from "antd";
import React from "react";
import {BuilderOptionComboBox} from "../../../api/fn";
import InputLabel from "@material-ui/core/InputLabel";
import {UploadToDropBoxBase64WithBusket} from "../../../services/dropbox-uploader";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";

let DataCategories = [
    {key: "Labour", label: "Labour"},
    {key: "Service", label: "Service"},
]
let DataTypes = [
    {key: "Normal", label: "Normal"},
    {key: "CPA", label: "CPA"},
    {key: "Additional",label: "Additional"}
]
let DataItems = [
    {key: "Labour", label: "Labour",category:"Labour"},
    {key: "Service", label: "Service",category:"Service"},
    {key: "Key Personnel", label: "Key Personnel",category:"Labour"},
    {key: "Vaccination", label: "Vaccination",category:"Labour"},
    {key: "Water Tanker", label: "Water Tanker",category:"Service"},
]
export default ({
                    visible,
                    onClose,
                    onSubmit,
                    DataOrganisations,
                    DataClients,
                    DataProjects,
                    onIsLoading
                }) => {

    const [SelectedCompany, setSelectedCompany] = useState("")
    const [SelectedClient, setSelectedClient] = useState("")
    const [SelectedProject, setSelectedProject] = useState("")
    const [SelectedItem, setSelectedItem] = useState("")
    const [SelectedCategory, setSelectedCategory] = useState("")
    const [SelectedType, setSelectedType] = useState("normal")

    const [StartDate, setStartDate] = useState("")
    const [EndDate, setEndDate] = useState("")
    const [QuoteDate, setQuoteDate] = useState("")

    const [InputQuoteNumber, setInputQuoteNumber] = useState("")
    const [InputSubtotal, setInputSubtotal] = useState("")
    const [InputVat, setInputVal] = useState("")
    const [InputTotal, setInputTotal] = useState("")

    const [LinkRevision, setLinkRevision] = useState("")
    const [LinkDownload, setLinkDownload] = useState("")

    const [FileName,setFileName]=useState("")
    const [FileBase64,setFileBase64]=useState("")

    const validationInputs=()=>{
        let fields =[
            {field:"Organization",input:SelectedCompany},
            {field:"Client",input:SelectedClient},
            {field:"Item",input:SelectedItem},
            {field:"Category",input:SelectedCategory},
            {field: "Type of quote",input: SelectedType},
            {field:"Period Start",input:StartDate},
            {field:"Period End",input:EndDate},
            {field:"Quote Date",input:QuoteDate},
            {field:"Subtotal Amount",input:InputSubtotal},
            {field:"Vat Amount",input:InputVat},
            {field:"Total Amount",input:InputTotal},
            {field:"File Upload",input:LinkRevision},
        ]
        if(SelectedCategory==="labour"){
            fields.push({field: "Project",input: SelectedProject})
        }

        for(let  i in fields){
            let row = fields[i];
            if(row.input===""){
                alert(`validation fail, input ${row.field} is empty!`)
                return false
            }
        }
        return true
    }
    const onSubmitTracker = () => {

        if(!validationInputs()){
            return
        }
        if (!window.confirm("Are you sure to submit this quote tracker?")) {
            return
        }
        /**
         * Collection our inputs and selection filter then submit to quote tracker history
         * @type {{}}
         */
        let record = {
            "Org": SelectedCompany,
            "Ref": SelectedProject,
            "QuoteNumber": InputQuoteNumber,
            "QuoteDate": QuoteDate,
            "CustomerName": SelectedClient,
            "Description": SelectedItem,
            "PeriodStart": StartDate,
            "PeriodEnd": EndDate,
            "AmountExVat": parseFloat(InputSubtotal),
            "AmountVat": parseFloat(InputVat),
            "AmountTotal": parseFloat(InputTotal),
            "Category": SelectedCategory,
            "TypeOfQuote":SelectedType,
            "PoNumber": "",
            "PoLink": "",
            "Approved": false,
            "LinkRevision": LinkRevision,
            "LinkDownload": LinkDownload,
        }
        console.log("<|Quote Record|>", record)
        onSubmit(record)
        onClose()
    }

    const storeFileToDropbox=async (base64String,fileName)=>{
        /**
         * upload to drop box
         */
        onIsLoading(true)
        await UploadToDropBoxBase64WithBusket(
            base64String,
            "quote-tracker",
            SelectedCompany,
            SelectedClient,
            SelectedItem,
            InputQuoteNumber,
            fileName,
            (revision) => {

            }).then(revision => {
                let rev = revision.replace("https://cloudcalls.easipath.com/backend-pmis/api/files/download/","")
            console.log("UploadToDropBoxBase64WithBusket revision > ", rev)
            setLinkRevision(rev)
            setLinkDownload(`https://cloudcalls.easipath.com/backend-pmis/api/files/download/${rev}`)

            onIsLoading(false)
            })
    }

    const RenderInputBox = (label, fn) => {
        const onChangeLocal = (e, key) => {
            fn(e.target.value)
        }
        return (
            <div style={{marginTop: 10}}>
                <InputLabel htmlFor="demo-customized-select-native">{label}</InputLabel>
                <Input
                    onChange={(e) => onChangeLocal(e)}
                />
            </div>
        )
    }

    const RenderQuoteFile=()=>{
        const handleInputFile = (e) => {
            let inputUpload = document.getElementById("fName1");
            let myfile = inputUpload.files[0];
            if (!myfile) {
                console.log("No file selected.");
                return;
            }
            let reader = new FileReader();
            reader.readAsDataURL(myfile);
            reader.onload = function () {
                setFileName(myfile.name);
                setFileBase64(reader.result);
                storeFileToDropbox(reader.result,myfile.name)
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }

        return (
            <div style={{marginTop: 10}}>
                <Form.Item
                    name={"file"}
                    label={"Choose File"}
                    rules={[{required: true, message: 'Please enter file ' }]}
                >
                    <Input type={"file"} id={"fName1"} onChange={handleInputFile}/>
                </Form.Item>
            </div>
        )
    }

    return (
        <>
            <Drawer
                title={"New Quote Tracker"}
                width={720}
                onClose={onClose}
                visible={visible}
                bodyStyle={{paddingBottom: 80, overflow: "scroll"}}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={onClose} style={{marginRight: 8}}>
                            Cancel
                        </Button>
                        <Button onClick={onSubmitTracker} type="primary">
                            Submit
                        </Button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <Divider orientation="left" style={{color: "red"}}>
                        File
                    </Divider>
                    {RenderQuoteFile()}

                    <Divider orientation="left" style={{color: "red"}}>
                        Filter
                    </Divider>
                    <BuilderOptionComboBox
                        key={"cbCompany"}
                        data={DataOrganisations}
                        keyVal={"CustomerNumber"}
                        keyLabel={"Name"}
                        label={"Select Organization"}
                        conditions={[]}
                        handleFunction={(val, key) => setSelectedCompany(val)}
                        stateKey={"SelectedCompany"}
                        currentValue={SelectedCompany}
                        comboId={"company"}
                    />
                    <BuilderOptionComboBox
                        key={"cbClient"}
                        data={DataClients}
                        keyVal={"mame"}
                        keyLabel={"name"}
                        label={"Select Debtor"}
                        conditions={[
                            {key: "org", val: SelectedCompany}
                        ]}
                        handleFunction={(val, key) => setSelectedClient(val)}
                        stateKey={"selectedClient"}
                        currentValue={SelectedClient}
                        comboId={"client"}
                    />
                    <BuilderOptionComboBox
                        data={DataCategories}
                        keyVal={"key"}
                        keyLabel={"label"}
                        label={"Select Category"}
                        conditions={[]}
                        handleFunction={(val, key) => setSelectedCategory(val)}
                        stateKey={"SelectedCategory"}
                        currentValue={SelectedCategory}
                        comboId={"category"}
                    />
                    <BuilderOptionComboBox
                        data={DataTypes}
                        keyVal={"key"}
                        keyLabel={"label"}
                        label={"Select Type"}
                        conditions={[]}
                        handleFunction={(val, key) => setSelectedType(val)}
                        stateKey={"SelectedType"}
                        currentValue={SelectedType}
                        comboId={"type"}
                    />



                    {SelectedCategory === "labour" &&
                        <BuilderOptionComboBox
                            data={DataProjects}
                            keyVal={"code"}
                            keyLabel={"name"}
                            label={"Select Project"}
                            conditions={[
                                {key: "org", val: SelectedCompany},
                            ]}
                            handleFunction={(val, key) => setSelectedProject(val)}
                            stateKey={"SelectedProject"}
                            currentValue={SelectedProject}
                            comboId={"project"}
                        />
                    }

                    <BuilderOptionComboBox
                        data={DataItems}
                        keyVal={"key"}
                        keyLabel={"label"}
                        label={"Select Item"}
                        conditions={[
                            {key: "category", val: SelectedCategory}
                        ]}
                        handleFunction={(val, key) => setSelectedItem(val)}
                        stateKey={"SelectedItem"}
                        currentValue={SelectedItem}
                        comboId={"item"}
                    />


                    <Row style={styles.dateRow}>
                        <Col span={11} style={{height: 45}}>
                            <DatePicker
                                style={styles.datePickerBox}
                                onChange={(date, dateString) => setStartDate(dateString)}
                                placeholder={"Period Start Date"}
                            />
                        </Col>
                        <Col span={11} style={{height: 45}}>
                            <DatePicker
                                style={styles.datePickerBox}
                                onChange={(date, dateString) => setEndDate(dateString)}
                                placeholder={"Period End Date"}
                            />
                        </Col>
                    </Row>
                    <Row style={styles.dateRow}>
                        <Col span={24} style={{height: 45}}>
                            <DatePicker
                                style={styles.datePickerBox}
                                onChange={(date, dateString) => setQuoteDate(dateString)}
                                placeholder={"Quote Date"}
                            />
                        </Col>
                    </Row>

                    <Divider orientation="left" style={{color: "red"}}>
                        Inputs
                    </Divider>
                    {RenderInputBox("Quote Number", setInputQuoteNumber)}
                    {RenderInputBox("Subtotal", setInputSubtotal)}
                    {RenderInputBox("Vat", setInputVal)}
                    {RenderInputBox("Total", setInputTotal)}




                </Form>


            </Drawer>
        </>
    );
}


const styles = {
    dateRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 20,
        marginTop: 20
    },
    datePickerBox: {
        height: 40,
        width: "100%"
    },
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}