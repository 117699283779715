import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {Layout, Select, Input, Row, Col, Button} from "antd";
import {GetUserToken} from "../../../api/token";
import {PostToPbx} from "../../../api/http-post";
import {fetchDataEntity} from "../../../api/entity-data";
import {BuilderOptionComboBox, SortData} from "../../../api/fn";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {Colors} from "../../../constants/colors";
import InvoiceChangeServiceSiteRatePoper from "../invoices/SetupInvoiceService/InvoiceChangeServiceSiteRatePoper";


class QuoteServicePlanedRateChangePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Modules: [],
            Clients: [],
            Items: [],
            isLoading: false,
            company: "",
            component: "list",
            selectedCompany:"",
            selectedClient:"",
            selectedItem:"",
            visible:false,
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.loadInitialData()
    }
    loadInitialData = async () => {
        await this.loadData("modules", "Modules");
        await this.loadData("SettingInvoiceServiceDebtors", "Clients");
        //await this.loadData("SettingInvoiceServiceItem", "Items");
        await this.loadData("QuoteServicePlannedSite", "Data")
    }
    loadData = async (targetEntity, stateKey) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            await _this.setState({
                [stateKey]: data.RESULT
            })
        });
    }
    handleInput = (val, key) => {
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp)
    }

    getDataFilter = () => {
        let ls = [];
        for (let i in this.state.Data) {
            let row = this.state.Data[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            // condition for client
            if(row.client!==this.state.selectedClient){
                continue
            }
            /*//todo condition for item
            if(row.item!==this.state.selectedItem){
                continue
            }*/
            if(row.site===""){
                continue
            }
            if(row.item===""){
                continue
            }
            ls.push(row)
        }
        return ls
    }
    onRequestNewRate = () => {

        if (this.state.selectedCompany==="") {
            alert("Please select company!")
            return
        }
        // condition for client
        if(this.state.selectedClient===""){
            alert("Please select client!")
            return
        }
        /*//todo condition for item
        if(this.state.selectedItem===""){
            alert("Please select item!")
            return
        }*/
        this.setState({visible:true})
    }
    onCloseNewRate = () => {
        this.setState({visible:false})
    }
    onSubmit=async (records)=>{
        let _this = this
        console.log("onSubmit: ",records);
        if(!window.confirm("Are you sure to update site rate?")){
            return
        }
        let endpoint = "/generic/insert/bulk";
        this.setState({
            isLoading: true,
        });
        let postData={
            data:records,
            table:"QuoteServicePlannedSite",
            host:"pmis.easipath.com",
            db:"pmis"
        }
        console.log("onSubmit send > ", postData);
        await PostToPbx(postData, endpoint, async (data) => {
            let result = null;
            if(data!==null){
                //
                _this.setState({isLoading: false,visible:false});
            }else{
                alert("Sorry something went wrong!")
                _this.setState({isLoading: false,visible:false});
                return
            }
            _this.setState({isLoading: false,visible:false});
            await this.loadData("QuoteServicePlannedSite", "Data")
            alert("Thank you record updated!")

        });

    }
    renderList = () => {
        const replaceBooleanValue = (rowIn) => {
            let ls = {};
            for (let i in rowIn) {
                let val = rowIn[i];
                let isBool = false;
                for (let e in fields) {
                    const rec = fields[e];
                    if (rec.name === i) {
                        if (rec.dataType === "boolean") {
                            isBool = true;
                        }
                    }
                }
                if (isBool) {
                    val = val ? "Yes" : "No"
                }
                ls[i] = val;

            }
            return ls;
        }
        let {fields, addFormText, stepTitle} = this.props;
        let data = this.getDataFilter();
        let columns = [
            {
                label: "Site",
                id: "site",
            },
            {
                label: "Rate",
                id: "rate",
                cellStyle: { textAlign: 'right' ,color:Colors.brand.blue,fontSize: 16 ,fontWeight: 'bold' },
                headerStyle: { textAlign: 'right' }
            },
        ];


        let ls = [];
        for (let i in data) {
            let row = data[i];
            row = replaceBooleanValue(row);
            ls.push(row);
        }
        ls = SortData("site", ls)

        console.log("ls >>", ls)

        let otherFunctions = []
        let dataTable = {columns: columns, data: ls}


        return (
            <div style={{minWidth: "100%", padding: 0, overflow: "scroll", marginTop: "30px"}}>
                <TableDataView
                    {...dataTable}
                    tableTitle={" Records  (" + ls.length + ") "}
                    onDelete={this.requestDeleteRecord}
                    otherFunctions={otherFunctions}
                    allowedAdd={true}
                    onAddRecord={this.onRequestNewRate}
                    options={{
                        pageSize: 20,
                    }}
                />
            </div>

        )

    }

    render() {

        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Change Quote Site Rate</Title>

                    <BuilderOptionComboBox
                        data={this.state.Companies}
                        keyVal={"CustomerNumber"}
                        keyLabel={"Name"}
                        label={"Select Organization"}
                        conditions={[]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedCompany"}
                        currentValue={this.state.selectedCompany}
                        comboId={"company"}
                    />
                    <BuilderOptionComboBox
                        data={this.state.Clients}
                        keyVal={"mame"}
                        keyLabel={"name"}
                        label={"Select Debtor"}
                        conditions={[
                            {key: "org", val: this.state.selectedCompany}
                        ]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedClient"}
                        currentValue={this.state.selectedClient}
                        comboId={"client"}
                    />
                    {/*<BuilderOptionComboBox
                        data={this.state.Items}
                        keyVal={"name"}
                        keyLabel={"name"}
                        label={"Select Item | Job description"}
                        conditions={[
                            {key: "org", val: this.state.selectedCompany},
                            {key: "client", val: this.state.selectedClient},
                        ]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedItem"}
                        currentValue={this.state.selectedItem}
                        comboId={"item"}
                    />*/}


                    <Row>
                        <Col span={24}>
                            <div style={{float: "left", minWidth: "100%", maxWidth: "100%"}}>
                                {this.renderList()}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div style={{float: "left", minWidth: "100%", maxWidth: "100%"}}>
                                <InvoiceChangeServiceSiteRatePoper
                                    visible={this.state.visible}
                                    data={this.getDataFilter()}
                                    onClose={this.onCloseNewRate}
                                    submitRecord={this.onSubmit}
                                />
                            </div>
                        </Col>
                    </Row>


                </div>
            </Page>
        )
    }

}

export default Connector(QuoteServicePlanedRateChangePage);

const styles = {
    podNumber: {
        color: "orange",
    },
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}


