
import React from "react";

export default ()=>{

    return(
        <div style={{textAlign:"left"}}>
            <h2>
                Get started by setting up a contract for your project
            </h2>
            <p>
                Follow the steps in this guide to smoothly and accurately set up your project contract in PMIS.<br/>
                By entering critical information relating to the EPWP contract during the process, the company will<br/>
                be ready to start using PMIS for its EPWP payroll management.
            </p>
            <p>
                Collect critical information now!
                Before continuing with the set up guide, collect critical information about the contract so you
                are ready to enter it during the set up process.
            </p>
            <br/>
            <h4>Set up Guide: Collect these things before you start - </h4>
            1.<br/>
            2.<br/>
            3.<br/>
            <br/>
            <br/>
            <p>Click the 'Next' button to begin.</p>
            <br/>
            <br/>
        </div>
    )
}