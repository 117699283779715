import {GetUserToken} from "./token";

export const CheckDisplayModule=(appName,props)=>{
    let user = GetUserToken();
    const nav =props.nav;
    let lsApp={};
    for(let app in nav.menus){
        let boo =false;
        for(let module in nav.menus[app]){
            let lsMenus =[];
            for(let i in nav.menus[app][module]){
                let row =nav.menus[app][module][i];
                lsMenus.push(row);
            }
            for(let i in lsMenus){
                const menu =lsMenus[i];
                if(IsRoleIn(menu.roles)){
                    boo= true
                }
            }
        }
        lsApp[app]=boo
    }
    return lsApp[appName]
}
export const CheckDisplayMenu=(appName,module,props)=>{
    const nav =props.nav;
    let lsModules =[];
    for(let module in nav.menus[appName]){
        lsModules.push(module);
    }
    let lsMenus = GetMenuList(module,props);
    for(let i in lsMenus){
        const menu =lsMenus[i];
        if(IsRoleIn(menu.roles)){
            return true
        }
    }
    return false;
}

export const IsRoleIn=(arr)=>{
    let user = GetUserToken();
    for(let i in arr){
        const item=arr[i];
        if(item===user.Role){
            return true
        }
    }
    return false
}
export const GetMenuList=(selectedModule,props)=>{
    const nav =props.nav;
    const CurrentApp = nav.CurrentApp;
    const CurrentModule = nav.CurrentModule;
    const CurrentMenu = nav.CurrentMenu;
    let lsMenus =[];
    for(let i in nav.menus[CurrentApp][selectedModule]){
        let row =nav.menus[CurrentApp][selectedModule][i];
        lsMenus.push(row);
    }
    return lsMenus;
}
export const SetMenu=(module,menu,props)=>{
    props.SetNavAppModule(module);
    props.SetNavAppMenu(menu.name);
    props.SetNavAppLink(menu.link);
    window.location.href = "#/"+menu.link;
    console.log("<><<><><>========> ",module," > ",menu.name," > ",menu.link);
    window.location.reload();
}
