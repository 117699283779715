import {Button, Drawer, Form} from "antd";
import React from "react";


export default (props)=>{

    return(
        <>
            <Drawer
                title={props.title+""}
                width={720}
                onClose={props.onClose}
                visible={props.visible}
                bodyStyle={{paddingBottom: 80, overflow: "scroll"}}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={props.onClose} style={{marginRight: 8}}>
                            Cancel
                        </Button>
                        <Button onClick={props.onSubmit} type="primary">
                            Submit
                        </Button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    {props.children}
                </Form>
            </Drawer>
        </>
    )
}