import React from "react";
import {fetchDataEntity} from "../../../api/entity-data";
import ControllerSelectBox from "../../../components/Common/ControllerInput/ControllerSelectBox";
import {ArrayToSelectOption, FilterArrayByKey,  UpcaseFirst} from "../../../api/fn";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {PostToPbx} from "../../../api/http-post";
import {Button, Col, Row} from "antd";
import TableDataView from "../../../components/Common/tables/TableDataView";
import moment from "moment";
import {SearchOutlined} from "@ant-design/icons";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";

class LabourGenerateReportPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Attributes: [],
            Projects: [],
            Periods: [],
            Results: null,

            selectedCompany: "",
            selectedProject: "",
            selectedPeriod: "",

            isLoading:false,
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Projects", "", "", this, undefined, "common/entity/modules/list");
        await fetchDataEntity("Periods", "", "", this, undefined, "common/entity/LabourPeriod/list");
    }
    handleChangeInput = async (key, value) => {
        console.log("handleChangeInput > ", key, " > ", value)
        this.setState({
            [key]: value,
        })
    }
    handleGetInputValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return ""
        }
        return this.state[key];
    }
    submitForLabour=async ()=>{
        let arrPeriod = this.state.selectedPeriod.split("#");
        let payDate = arrPeriod[2];
        let payDateString = moment(payDate,"YYYY-MM-DD").format("DD MMM YYYY")
        let hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedProject,
            CompanyName:"MSHENGU TOILET HIRE",
            PayDate: payDateString,
            TitleReport: "EMPLOYMEE PAYMENT SUMMARY REPORT - EPWP 100L Container Employees",
            InnerData: this.state.Results.Summary,
            Method:"actual"
        }
        let endpoint = "/wage/report/labour2"
        await PostToPbx(hub, endpoint, async (output) => {
            console.log("submitSearch response > ", output)
            this.setState({
                isLoading: false,
            });
            if(output===null){
                console.log("Error response null > ",output);
                return
            }
            let data = output.RESULT;
            let url = data.LinkDownload;
            window.document.title = hub.TitleReport;
            window.open(url, '_blank');
        });
    }
    submitSearch=async ()=>{
        let state = this.state;
        if(state.selectedCompany===""){
            return alert("Please select your company !")
        }
        if(state.selectedProject===""){
            return alert("Please select your project !")
        }
        if(state.selectedPeriod===""){
            return alert("Please select your period !")
        }
        this.setState({isLoading:true})


        let hub = {
            Org: this.state.selectedCompany,
            Period: this.state.selectedPeriod,
            Project: this.state.selectedProject,
        }
        let endpoint = "/wage/calculation/hourly"
        await PostToPbx(hub, endpoint, async (output) => {
            console.log("submitSearch response > ", output)
            if(output===null){
                console.log("Error response null > ",output);
                return
            }
            let data = output.RESULT;
            this.setState({Results:data})
            await this.submitForLabour()
        });
    }

    renderCompanyBox = () => {
        let ls = [];
        ls = FilterArrayByKey(this.state.Companies, {})
        ls = ArrayToSelectOption(ls, ["CustomerNumber"], ["Name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Company"}
                name={"selectedCompany"}
            />
        )
    }
    renderProjectBox = () => {
        let ls = [];
        for (let i in this.state.Projects) {
            let row = this.state.Projects[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            ls.push(row)
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["name"], ["name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Project"}
                name={"selectedProject"}
            />
        )
    }
    renderPeriodBox = () => {
        let formatDate=(dateIn)=>{
            return moment(dateIn,"YYYY-MM-DD").format("DD-MMM-YYYY");
        }
        let ls = [];
        for (let i in this.state.Periods) {
            let row = this.state.Periods[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.project !== this.state.selectedProject) {
                continue
            }
            let periodValue = `${row.startdate}#${row.enddate}#${row.paydate}`;
            let periodLabel = `From: ${formatDate(row.startdate)} To: ${formatDate(row.enddate)} `
            ls.push({
                value:periodValue,
                label:periodLabel
            })
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["value"], ["label"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Project"}
                name={"selectedPeriod"}
            />
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>GENERATE LABOUR PERIOD REPORT</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <Row gutter={18}>
                            <Col span={7}>
                                {this.renderCompanyBox()}
                            </Col>
                            <Col span={7}>
                                {this.renderProjectBox()}
                            </Col>
                            <Col span={7}>
                                {this.renderPeriodBox()}
                            </Col>
                            <Col span={3} style={{marginTop:35}}>
                                <Button
                                    type="primary"
                                    icon={<SearchOutlined />}
                                    onClick={()=>this.submitSearch()}
                                >
                                    Search
                                </Button>

                            </Col>
                        </Row>

                        <div style={{
                            borderRadius: 5,
                            borderWidth: 1,
                            borderColor: "lightblue",
                            borderStyle: 'solid',
                            minHeight: 35,
                            padding: 5
                        }}>

                        </div>

                    </div>
                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }

}


export default Connector(LabourGenerateReportPage);
const styles = {
    rowForm:{
        marginBottom:10,
        paddingBottom:5,
        border:"0px solid lightgray",
        borderBottomWidth: 1,

    },
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}