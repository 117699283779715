import * as React from 'react';

 const SummaryInfo =   (props)=>{
     let {label,val}=props;
    return (
        <div style={styles.container}>
            <div style={styles.pullLeft}>{label}</div>
            <div style={styles.pullRight}>{val}</div>
        </div>
    );
}

export default SummaryInfo;

 const styles={
     pullLeft:{
         minWidth:"50%",
         maxWidth:"50%",
         textAlign:"left",
     },
     pullRight:{
         minWidth:"50%",
         maxWidth:"50%",
         textAlign:"right",
     },
     container:{
         minWidth:"100%",
         maxWidth:"100%",
         border:"1px solid #337aff",
         borderRadius:5,
         display:"flex",
         flexDirection:"row",
         alignItems:"center",
         justifyContent:"between-space",
         margin:10,
         marginLeft:0,
         paddingLeft:10,
         paddingRight:10,
     }
 }
