import React from "react";
import "./dashbaord.css";
import {FormatMoneySouthAfrica} from "../../../api/fn";

const TotalBreakDown=({amountInvoice,amountPaid,BalanceOwing,partialAmount,overPaidAmount,overPaidInvoices,partialPaidInvoices})=>{

    const onClickPartial=()=>{
        let msg ='Partial Payment';
        for(let i in partialPaidInvoices){
            let row = partialPaidInvoices[i]
            let invAmount =`R ${FormatMoneySouthAfrica(row.invoiceamount)}`;
            let paidAmount =`R ${FormatMoneySouthAfrica(row.paidamount)}`;
            let diffAmount =`R ${FormatMoneySouthAfrica(row.invoiceamount  - row.paidamount)}`;
            msg +=`\n\n ${row.invoicenumber} > amount: ${invAmount} paid:${paidAmount} partial: ${diffAmount}`
        }
        alert(msg)
    }
    const onClickOver=()=>{
        let msg ='Over Payment';
        for(let i in overPaidInvoices){
            let row = overPaidInvoices[i]
            let invAmount =`R ${FormatMoneySouthAfrica(row.invoiceamount)}`;
            let paidAmount =`R ${FormatMoneySouthAfrica(row.paidamount)}`;
            let diffAmount =`R ${FormatMoneySouthAfrica(   row.paidamount - row.invoiceamount)}`;
            msg +=`\n\n ${row.invoicenumber} > amount: ${invAmount} paid:${paidAmount} over: ${diffAmount}`
        }
        alert(msg)
    }
    return(
        <div className={"tbdContainer"}>
            <div className={"tbdTitle"}>Invoice Amount</div>
            <div className={"tbdAmount"}>R {FormatMoneySouthAfrica(amountInvoice)}</div>
            <div className={"tbdTitle"}>Paid Amount</div>
            <div className={"tbdAmount"}>R {FormatMoneySouthAfrica(amountPaid)}</div>
            <div className={"tbdTitle"}>Balance Owing</div>
            <div className={"tbdAmount"}>R {FormatMoneySouthAfrica(BalanceOwing)}</div>
            <div style={{
                display:"flex",
                flexDirection:"row",
                justifyContent:'space-between',
                width:"100%",
                borderTop:"1px solid black"
            }}>
                <div><a onClick={()=>onClickPartial()}>Partial Paid </a> </div>
                <div> R {FormatMoneySouthAfrica(partialAmount)}</div>
            </div>
            <div style={{
                display:"flex",
                flexDirection:"row",
                justifyContent:'space-between',
                width:"100%"
            }}>
                <div><a onClick={()=>onClickOver()}>Over Paid</a> </div>
                <div>R {FormatMoneySouthAfrica(overPaidAmount)}</div>
            </div>
        </div>
    )
}
export default TotalBreakDown;