import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import {LoadDataCommon} from "../../../../api/fn";
import Title from "../../../../components/Common/Title";
import {Col, Row} from "antd";
import LoadingProcessing from "../../../../components/Common/page/Common/LoadingProcessing";
import Connector from "../../../../redux/connector";
import Page from "../../../../layout/web/Page";

class InvoiceReportAccountReceivablePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Clients: [],
            Items: [],
            Data: [],
            Modules: [],
            isLoading: false,
            keySearch: "",
        }
    }

    componentDidMount = async () => {
        await this.loadInitialData()
    }
    loadInitialData = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await LoadDataCommon("modules", "Modules", this);
        await LoadDataCommon("SettingInvoiceServiceDebtors", "Clients", this);
        await LoadDataCommon("SettingInvoiceServiceItem", "Items", this);
        await LoadDataCommon("InvoiceTracker", "Data", this);
    }

    RenderListHeader=()=>{
        return(
            <Row>
                <Col span={2}>Invoice Date</Col>
                <Col span={2}>Invoice No</Col>
                <Col span={3}>Customer</Col>
                <Col span={4}>Item</Col>
                <Col span={1}>Amount</Col>
                <Col span={2}>Due Date</Col>
                <Col span={2}>Days Over Due</Col>
            </Row>
        )
    }

    render() {
        console.log("this.state (--) ", this.state)
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Invoice Account Receivable</Title>


                    <Row>
                        <Col span={24}>
                            <LoadingProcessing open={this.state.isLoading}/>
                        </Col>
                    </Row>
                </div>
            </Page>
        )
    }
}


export default Connector(InvoiceReportAccountReceivablePage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}



