import React from "react";
import "./InvoiceDashboard.css";
import Connector from "../../../../redux/connector";
import Title from "../../../../components/Common/Title";
import Page from "../../../../layout/web/Page";
import {Col, Row} from "antd";
import DashPaid from "../../../../components/Invoicings/Dashboard/DashPaid";

import dashPaidFullIcon from "../../../../assets/icons-dashboard/image014.png";
import dashPaidPercentageIcon from "../../../../assets/icons-dashboard/image025.png";
import dashPaidCashIcon from "../../../../assets/icons-dashboard/image016.png";

import dashPaidFullIcon2 from "../../../../assets/icons-dashboard/image064.png";
import dashPaidPercentageIcon2 from "../../../../assets/icons-dashboard/image068.png"; //"./icons-dashboard/image066.png";
import dashPaidCashIcon2 from "../../../../assets/icons-dashboard/image066.png";

import dashPaidFullIcon3 from "../../../../assets/icons-dashboard/image109.png";
import dashPaidPercentageIcon3 from "../../../../assets/icons-dashboard/image113.png";
import dashPaidCashIcon3 from "../../../../assets/icons-dashboard/image111.png";


import {ColorGreen, ColorOrange, ColorYellowDark} from "./Colors";
import TotalInvoicesSend from "../../../../components/Invoicings/Dashboard/TotalInvoicesSend";
import TotalBreakDown from "../../../../components/Invoicings/Dashboard/TotalBreakDown";
import SwitchPeriodInvoice from "../../../../components/Invoicings/Dashboard/SwitchPeriodInvoice";
import AnalysisOfAgedDebtors from "../../../../components/Invoicings/Dashboard/AnalysisOfAgedDebtors";
import DashChartColumn from "../../../../components/Invoicings/Dashboard/DashChartColumn";
import DashChart2 from "../../../../components/Invoicings/Dashboard/DashChart2";
import DashOutstandingByStatus from "../../../../components/Invoicings/Dashboard/DashOutstandingByStatus";
import DashInvoiceAmountByCategory from "../../../../components/Invoicings/Dashboard/DashInvoiceAmountByCategory";
import {LeftCircleOutlined,CloseCircleOutlined} from "@ant-design/icons";
import AnalysisOfAgedDebtorsTable from "../../../../components/Invoicings/Dashboard/AnalysisOfAgedDebtorsTable";
import {fetchDataEntity} from "../../../../api/entity-data";
import {
    BuilderOptionComboBox,
    FormatMoneySouthAfrica,
    LoadDataCommon,
    SortData, SortDataDate,
    SortDataDesc
} from "../../../../api/fn";
import moment from "moment";
import {
    calculateNumberOfDaysBetweenDates,
    FindFinancialYearRange,
    FindMonthIndexFromShortName,
    getDateListBetween,
    isDateBetween,
    isDateGreatThan
} from "../../../../api/date-functions";

const fixed2 = (valIn) => {
    return parseFloat(valIn.toFixed(2))
}

const isValidPeriod = ({
                           mode,
                           invoicedate,
                           mapMontIndex,
                           monthIndex,
                           targetYear,
                           financeYearMonth,
                           monthList,
                           defineStartDate,
                           defineEndDate
                       }) => {

    let arr = invoicedate.split("-");
    if (mode === "month") {
        let compareMonth = moment(invoicedate, "YYYY-MM-DD").format("YYYY-MM")
        if (compareMonth === monthIndex) {
            return true
        }
    } else if (mode === "finance-year") {
        let fYear = FindFinancialYearRange(financeYearMonth,targetYear);
        if (isDateBetween(fYear.startDate, fYear.endDate, invoicedate)) {
            let compareDate = moment(invoicedate, "YYYY-MM-DD").format("YYYY-MM") + "-01"
            let tDate = monthIndex + "-01"
            if (!isDateGreatThan(compareDate, tDate)) {
                return true
            }
        }
    } else if (mode === "YTD") {
        let startDate = monthList[0].period + "-01";
        let endDate = monthList[11].period + "-01";
        let tDate = monthIndex + "-01"
        if (isDateBetween(startDate, endDate, invoicedate)) {
            let compareDate = moment(invoicedate, "YYYY-MM-DD").format("YYYY-MM") + "-01"
            if (!isDateGreatThan(compareDate, tDate)) {
                return true
            }
        }

    } else if (mode === "define") {
        let startDate = moment(defineStartDate, "YYYY-MM-DD").format("YYYY-MM") + "-01";
        let endDate = moment(defineEndDate, "YYYY-MM-DD").format("YYYY-MM") + "-01";
        let tDate = moment(invoicedate, "YYYY-MM-DD").format("YYYY-MM") + "-01";
        console.log(`define period > startDate:${startDate} > endDate: ${endDate} > tDate: ${tDate} > ${isDateBetween(startDate, endDate, tDate)}`)
        if (isDateBetween(startDate, endDate, tDate)) {
            return true
        }
    }
    return false
}

const getDefaultTargetYear=()=>{
    let currentYear = parseInt(moment().format("YYYY"))
    return `${currentYear}`
}

class InvoiceDashboardPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Clients: [],
            ItemsService: [],
            ItemsLabour: [],
            Data: [],
            VirtualCompanies:[],
            VirtualCompaniesDebtors:[],
            isLoading: false,
            selectedCompany: "",
            selectedVirtualCompany:"all",
            selectedClient: "",
            selectedItem: "",
            selectedYear: "",
            selectedMonth: moment().format("YYYY-MM"),
            selectedCategory: "",
            mode: "finance-year",
            targetYear: getDefaultTargetYear(), //moment().format("YYYY"),
            monthIndex: parseInt(moment().format("YYYY-MM")),
            defineStartDate: moment().format("YYYY-MM-DD"),
            defineEndDate: moment().format("YYYY-MM-DD"),
            financialYearMonth: "Mar",
            financeYearInfo: null,



        }
    }

    componentDidMount = async () => {
        await this.loadInitialData();
        this.setState({
            financeYearInfo: FindFinancialYearRange(this.state.financialYearMonth, this.state.targetYear)
        })
    }


    loadInitialData = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await LoadDataCommon("modules", "Modules", this);
        await LoadDataCommon("SettingInvoiceServiceDebtors", "Clients", this);
        await LoadDataCommon("SettingInvoiceServiceItem", "ItemsService", this);
        await LoadDataCommon("SettingInvoiceAddonLabourItems", "ItemsLabour", this);
        await LoadDataCommon("InvoiceTracker", "Data", this);

        await LoadDataCommon("InvoiceVirtualCompany", "VirtualCompanies", this);
        await LoadDataCommon("InvoiceVirtualCompanyClient", "VirtualCompaniesDebtors", this);
    }
    checkIfValidPeriod = (invoicedate) => {
        return isValidPeriod({
            mode: this.state.mode,
            invoicedate: invoicedate,
            mapMontIndex: this.mapMontIndex,
            monthIndex: this.state.selectedMonth,
            targetYear: this.state.targetYear,
            financeYearMonth: this.state.financialYearMonth,
            monthList: this.getMonthList(),
            defineStartDate: this.state.defineStartDate,
            defineEndDate: this.state.defineEndDate,
        });

    }
    handleInput = (value, key) => {
        this.setState({[key]: value})
    }
    onChangePeriod = (value) => {
        this.setState({selectedMonth: value})
    }
    getColumnColor = () => {
        return ["#01B1A8", "#e0bb2e", "saddlebrown", "saddlebrown"]
    }
    getDataForAgeSummary = () => {
        let {chartData} = this.getAgedByClient();
        return chartData;
    }
    getDataCustomerInvoice = () => {
        return [

            {
                name: 'CoCT',
                data: [13740000, '', '', '']
            },
            {
                name: 'Sanitech',
                data: ['', 6220000, '', '']
            },
            {
                name: 'Moreki',
                data: ['', '', 5320000, '']
            },
            {
                name: 'Stellenbosh',
                data: ['', '', '', 530000]
            },
        ]
    }

    getDataOutstandingByStatus = () => {
        let info = this.getOutstandingByStatus();
        return [
            {name: "Overdue", y: info.overduePercentage},
            {name: "On Time Payment", y: info.onTimePercentage}
        ]
    }
    goHome = () => {
        let link = "invoicemanagement-reports-tracker"
        window.location.href = "#/" + link
        window.location.reload()
    }
    mapMontIndex = (value) => {
        let infos = {
            1: "01",
            2: "02",
            3: "03",
            4: "04",
            5: "05",
            6: "06",
            7: "07",
            8: "08",
            9: "09",
            10: "10",
            11: "11",
            12: "12"
        }
        let val = infos[value];
        return val;
    }
    isVirtualCompany=(client)=>{
        if(this.state.selectedVirtualCompany==="all"){
            return true
        }
        let targetClient={};
        for(let i in this.state.VirtualCompaniesDebtors){
            let row = this.state.VirtualCompaniesDebtors[i];
            if(row.company===this.state.selectedVirtualCompany){
                targetClient[row.client]=true
            }
        }
        return typeof targetClient[client] !== "undefined";
    }
    cleanEmptyInvoiceDate=(dataIn)=>{
        let infoData=[];
        for (let i in dataIn) {
            let row = dataIn[i];
            if(row.invoicedate===""){
                continue
            }
            infoData.push(row)
        }
        return infoData
    }
    getAmountByMonth = () => {
        let amountByMonth = {}
        let stateData = this.cleanEmptyInvoiceDate(this.state.Data);
        stateData = SortDataDate("invoicedate",stateData);
        for (let i in stateData) {
            let row = stateData[i];
            if(!this.isVirtualCompany(row.client)){
                continue
            }
            if (this.state.selectedCompany !== row.org) {
                continue
            }
            if (row.invoicedate === "") {
                continue
            }

            if (!this.checkIfValidPeriod(row.invoicedate)) {
                continue
            }

            //Invoice amount by month
            let shortDate = moment(row.invoicedate, "YYYY-MM-DD").format("MMM-YY");
            if (typeof amountByMonth[shortDate] === "undefined") {
                amountByMonth[shortDate] = 0;
            }
            amountByMonth[shortDate] += row.invoiceamount;
        }

        let monthlyAmount = [];
        for (let i in amountByMonth) {
            let name = i;
            let y = amountByMonth[i];

            monthlyAmount.push({
                name, y,
                index: FindMonthIndexFromShortName(i.split("-")[0])
            })
        }
        //monthlyAmount = SortData("index", monthlyAmount);
        console.log("monthlyAmount > ", monthlyAmount)
        return monthlyAmount;
    }
    getCustomerByInvoiceAmount = () => {
        let tmpInfo = {};
        for (let i in this.state.Data) {
            let row = this.state.Data[i];
            if(!this.isVirtualCompany(row.client)){
                continue
            }
            if (this.state.selectedCompany !== row.org) {
                continue
            }
            if (row.invoicedate === "") {
                continue
            }

            if (!this.checkIfValidPeriod(row.invoicedate)) {
                continue
            }

            if (typeof tmpInfo[row.client] === "undefined") {
                tmpInfo[row.client] = 0;
            }
            let amount = tmpInfo[row.client];
            amount += row.invoiceamount;
            tmpInfo[row.client] = amount;
        }

        //let try to sort data
        let sortData = [];
        for (let i in tmpInfo) {
            let client = i;
            let amount = tmpInfo[client];
            sortData.push({
                client: client,
                amount: amount
            })
        }

        sortData = SortDataDesc("amount", sortData);

        /**
         * find total customer
         */
        let totalCustomer = 0;
        let categories = [];
        for (let i in sortData) {
            let row = sortData[i]
            totalCustomer++
            categories.push(row.client)
        }
        /**
         * loop for build data
         */
        const makeData = (index, valueIn) => {
            let data = [];
            for (let i = 0; i < totalCustomer; i++) {
                console.log(`makeData > index: ${index} , i: ${i} value: ${valueIn} > ${i === parseInt(index)} => `, tmpInfo)
                if (i === parseInt(index)) {
                    data.push(valueIn)
                } else {
                    data.push('')
                }
            }
            return data
        }

        let tmpInfo2 = [];
        for (let i in sortData) {
            let row = sortData[i];
            tmpInfo2.push({
                name: row.client,
                amount: fixed2(row.amount)
            })
        }

        let ls = [];
        for (let i in tmpInfo2) {
            let rec = tmpInfo2[i];
            let record = {
                name: rec.name,
                data: makeData(i, rec.amount),
                amount: rec.amount,
            }
            ls.push(record)
        }

        ls = SortData("amount", ls)
        console.log("TTTTT--> ", ls)


        let returnData = {ls, categories}
        console.log("returnData > ", returnData)
        return returnData


    }
    getOutstandingByStatus = () => {
        let total = 0, overdue = 0, onTime = 0;

        for (let i in this.state.Data) {
            let row = this.state.Data[i];
            if(!this.isVirtualCompany(row.client)){
                continue
            }
            if (this.state.selectedCompany !== row.org) {
                continue
            }
            if (row.invoicedate === "") {
                continue
            }
            if (!this.checkIfValidPeriod(row.invoicedate)) {
                continue
            }
            /**
             * avoid current invoice
             */
            let todayDate = moment().format("YYYY-MM-DD");
            let age = calculateNumberOfDaysBetweenDates(row.invoicedate, todayDate);
            if (age <= 30) {
                continue
            }
            /**
             * avoid none paid invoice and deal with paid only
             */
            if (!row.paid) {
                continue
            }

            /**
             * now let apply our logic
             */
            if (isDateBetween(row.invoicedate, row.duedate, row.datepaid)) {
                onTime++
            } else {
                overdue++
            }
            total++
        }

        let onTimePercentage = (onTime / total) * 100;
        onTimePercentage = fixed2(onTimePercentage);
        let overduePercentage = fixed2(overdue);
        return {total, onTime, overdue, onTimePercentage, overduePercentage}
    }
    getAmountByCategory = () => {
        let service = {amount: 0, percentage: 0};
        let labour = {amount: 0, percentage: 0};
        let totalAll = 0;
        let categories = {};
        const cleanCat = (catIn) => {
            catIn = catIn.toLowerCase();
            catIn = catIn.trim();
            return catIn
        }
        for (let i in this.state.Data) {
            let row = this.state.Data[i];
            if(!this.isVirtualCompany(row.client)){
                continue
            }
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.invoicedate === "") {
                continue
            }
            if (!this.checkIfValidPeriod(row.invoicedate)) {
                continue
            }

            let cat = cleanCat(row.category);
            categories[cat] = true;
            if (this.checkIfValidPeriod(row.invoicedate)) {
                totalAll += parseFloat(row.invoiceamount);

                if (cleanCat(row.category) === "service") {
                    service.amount += parseFloat(row.invoiceamount);
                } else if (cleanCat(row.category) === "labour") {
                    labour.amount += parseFloat(row.invoiceamount);
                }
            }
        }


        service.percentage = (service.amount / totalAll) * 100;
        labour.percentage = (labour.amount / totalAll) * 100;
        service.percentage = fixed2(service.percentage);
        labour.percentage = fixed2(labour.percentage);
        service.amount = fixed2(service.amount);
        labour.amount = fixed2(labour.amount);

        console.log("Categories ::> ", categories)

        return {service, labour}
    }

    getAgedByClientOverPaid = () => {
        let information = {};
        let clients = {};
        let amountByMonth = {}
        for (let i in this.state.Data) {
            let row = this.state.Data[i];
            if(!this.isVirtualCompany(row.client)){
                continue
            }
            if (this.state.selectedCompany !== row.org) {
                continue
            }
            if (row.invoicedate === "") {
                continue
            }
            if (!this.checkIfValidPeriod(row.invoicedate)) {
                continue
            }

            if (!row.paid) {
                continue
            }



            if (typeof information[row.client] === "undefined") {
                information[row.client] = {}
            }

            let todayDate = moment().format("YYYY-MM-DD");
            let age = calculateNumberOfDaysBetweenDates(row.invoicedate, todayDate);

            let innerAmount=row.invoiceamount - row.paidamount;
            if(innerAmount< 0){
                innerAmount = row.paidamount - row.invoiceamount
                console.log("OVER PAID ZZZZZZZXXXXX: ",age," > ",row.invoiceamount," > ",row.paidamount," > ",innerAmount)
            }else{
                innerAmount = 0
            }

            if (age <= 30) {
                if (typeof information[row.client]["one"] === "undefined") {
                    information[row.client]["one"] = 0
                }
                let num = information[row.client]["one"] +innerAmount ;
                information[row.client]["one"] = num
            }
            if (age >= 31 && age <= 60) {
                if (typeof information[row.client]["two"] === "undefined") {
                    information[row.client]["two"] = 0
                }
                let num = information[row.client]["two"] +  innerAmount ;
                information[row.client]["two"] = num
            }
            if (age >= 61 && age <= 90) {
                if (typeof information[row.client]["three"] === "undefined") {
                    information[row.client]["three"] = 0
                }
                let num = information[row.client]["three"] +  innerAmount ;
                information[row.client]["three"] = num
            }
            if (age >= 91) {
                if (typeof information[row.client]["four"] === "undefined") {
                    information[row.client]["four"] = 0
                }
                let num = information[row.client]["four"] +  innerAmount;
                information[row.client]["four"] = num
            }

            clients[row.client] = "";

            //Invoice amount by month
            let shortDate = moment(row.invoicedate, "YYYY-MM-DD").format("MMM-YY");
            if (typeof amountByMonth[shortDate] === "undefined") {
                amountByMonth[shortDate] = 0;
            }
            amountByMonth[shortDate] += (innerAmount);
        }

        let data = [];
        // {client:"City of Cape Town",ageOne:3484681.64,ageTwo:0,ageThree:0,ageFour:0,total:3484681.64},
        let totOne = 0, totTwo = 0, totThree = 0, totFour = 0, totAll = 0;
        for (let i in information) {
            let client = i;
            let one = typeof information[client]["one"] === "undefined" ? 0 : information[client]["one"]
            let two = typeof information[client]["two"] === "undefined" ? 0 : information[client]["two"]
            let three = typeof information[client]["three"] === "undefined" ? 0 : information[client]["three"]
            let four = typeof information[client]["four"] === "undefined" ? 0 : information[client]["four"]
            let total = one + two + three + four
            data.push({
                client: client,
                ageOne: FormatMoneySouthAfrica(fixed2(one)),
                ageTwo: FormatMoneySouthAfrica(fixed2(two)),
                ageThree: FormatMoneySouthAfrica(fixed2(three)),
                ageFour: FormatMoneySouthAfrica(fixed2(four)),
                total: FormatMoneySouthAfrica(fixed2(total))
            })
            //total line
            totOne += one;
            totTwo += two;
            totThree += three;
            totFour += four;
            totAll += total;
        }

        const makeBold = (value) => {
            return <span style={{fontWeight: "bold"}}>{FormatMoneySouthAfrica(value)}</span>
        }
        data.push({
            client: makeBold("Total"),
            ageOne: makeBold(fixed2(totOne)),
            ageTwo: makeBold(fixed2(totTwo)),
            ageThree: makeBold(fixed2(totThree)),
            ageFour: makeBold(fixed2(totFour)),
            total: makeBold(fixed2(totAll))
        })

        console.log("over paid getAgedByClient data > ", information);
        let chartData = [
            {name: "< 30 Days", y: totOne},
            {name: "31-60 Days", y: totTwo},
            {name: "61-90 Days", y: totThree},
            {name: "91-120 Days", y: totFour},
        ]


        return {data, chartData};
    }

    getAgedByClient = () => {
        let overPayments = this.getAgedByClientOverPaid();
        const cleanSpace =(valIn)=>{
            valIn = valIn.replace(" ","")
            valIn = parseFloat(valIn)
            return valIn
        }
        const findOverDueRecordInfoData=(client)=>{
            let rec = {
                client: client,
                ageOne: FormatMoneySouthAfrica(fixed2(0)),
                ageTwo: FormatMoneySouthAfrica(fixed2(0)),
                ageThree: FormatMoneySouthAfrica(fixed2(0)),
                ageFour: FormatMoneySouthAfrica(fixed2(0)),
                total: FormatMoneySouthAfrica(fixed2(0))
            }
            for(let i in overPayments.data){
                let row = overPayments.data[i];
                if( row.client===client){
                    rec = row
                }
            }
            return rec
        }
        let information = {};
        let clients = {};
        let amountByMonth = {}
        for (let i in this.state.Data) {
            let row = this.state.Data[i];
            if(!this.isVirtualCompany(row.client)){
                continue
            }
            if (this.state.selectedCompany !== row.org) {
                continue
            }
            if (row.invoicedate === "") {
                continue
            }
            if (!this.checkIfValidPeriod(row.invoicedate)) {
                continue
            }

            if (row.paid) {
                continue
            }



            if (typeof information[row.client] === "undefined") {
                information[row.client] = {}
            }

            let todayDate = moment().format("YYYY-MM-DD");
            let age = calculateNumberOfDaysBetweenDates(row.invoicedate, todayDate);

            let innerAmount=row.invoiceamount - row.paidamount;
            if(row.paidamount>0){

                innerAmount = row.invoiceamount- row.paidamount
                console.log("ZZZZZZZXXXXX: ",row.invoiceamount," > ",row.paidamount)
            }else{
                innerAmount = row.invoiceamount
            }

            if (age <= 30) {
                if (typeof information[row.client]["one"] === "undefined") {
                    information[row.client]["one"] = 0
                }
                let num = information[row.client]["one"] +innerAmount ;
                information[row.client]["one"] = num
            }
            if (age >= 31 && age <= 60) {
                if (typeof information[row.client]["two"] === "undefined") {
                    information[row.client]["two"] = 0
                }
                let num = information[row.client]["two"] +  innerAmount ;
                information[row.client]["two"] = num
            }
            if (age >= 61 && age <= 90) {
                if (typeof information[row.client]["three"] === "undefined") {
                    information[row.client]["three"] = 0
                }
                let num = information[row.client]["three"] +  innerAmount ;
                information[row.client]["three"] = num
            }
            if (age >= 91) {
                if (typeof information[row.client]["four"] === "undefined") {
                    information[row.client]["four"] = 0
                }
                let num = information[row.client]["four"] +  innerAmount;
                information[row.client]["four"] = num
            }

            clients[row.client] = "";

            //Invoice amount by month
            let shortDate = moment(row.invoicedate, "YYYY-MM-DD").format("MMM-YY");
            if (typeof amountByMonth[shortDate] === "undefined") {
                amountByMonth[shortDate] = 0;
            }
            amountByMonth[shortDate] += (innerAmount);
        }

        let data = [];
        // {client:"City of Cape Town",ageOne:3484681.64,ageTwo:0,ageThree:0,ageFour:0,total:3484681.64},
        let totOne = 0, totTwo = 0, totThree = 0, totFour = 0, totAll = 0;
        for (let i in information) {

            let client = i;
            const overPaidInfo  = findOverDueRecordInfoData(client)
            console.log("WWWWWW-> ",overPaidInfo)
            let one = typeof information[client]["one"] === "undefined" ? 0 : information[client]["one"] - cleanSpace(overPaidInfo.ageOne)
            let two = typeof information[client]["two"] === "undefined" ? 0 : information[client]["two"]- cleanSpace(overPaidInfo.ageTwo)
            let three = typeof information[client]["three"] === "undefined" ? 0 : information[client]["three"]- cleanSpace(overPaidInfo.ageThree)
            let four = typeof information[client]["four"] === "undefined" ? 0 : information[client]["four"]- cleanSpace(overPaidInfo.ageFour)
            let total = one + two + three + four
            data.push({
                client: client,
                ageOne: FormatMoneySouthAfrica(fixed2(one)),
                ageTwo: FormatMoneySouthAfrica(fixed2(two)),
                ageThree: FormatMoneySouthAfrica(fixed2(three)),
                ageFour: FormatMoneySouthAfrica(fixed2(four)),
                total: FormatMoneySouthAfrica(fixed2(total))
            })
            //total line
            totOne += one;
            totTwo += two;
            totThree += three;
            totFour += four;
            totAll += total;
        }

        const makeBold = (value) => {
            return <span style={{fontWeight: "bold"}}>{FormatMoneySouthAfrica(value)}</span>
        }
        data.push({
            client: makeBold("Total"),
            ageOne: makeBold(fixed2(totOne)),
            ageTwo: makeBold(fixed2(totTwo)),
            ageThree: makeBold(fixed2(totThree)),
            ageFour: makeBold(fixed2(totFour)),
            total: makeBold(fixed2(totAll))
        })

        console.log("getAgedByClient data > ", data);
        let chartData = [
            {name: "< 30 Days", y: totOne},
            {name: "31-60 Days", y: totTwo},
            {name: "61-90 Days", y: totThree},
            {name: "91-120 Days", y: totFour},
        ]


        return {data, chartData};
    }
    getInfoFully = () => {
        let total = 0, paid = 0, amount = 0, percentage = 0, paidAmount = 0, balanceOwing = 0;
        let overdueAmount = 0, overdueTotal = 0, overduePercentage = 0;
        let currentAmount = 0, currentTotal = 0, currentPercentage = 0;
        let partialAmount=0,overPaidAmount=0;
        let partialInvoices=[],overPaidInvoices=[];
        let overDueInvoices=[],currentInvoices=[],fullPaidInvoices=[];

        let targetYear = this.state.targetYear;

        console.log("this.state.monthIndex > ", targetYear + "-" + this.state.monthIndex)
        for (let i in this.state.Data) {
            let row = this.state.Data[i];
            if(!this.isVirtualCompany(row.client)){
                continue
            }
            if (this.state.selectedCompany !== row.org) {
                continue
            }
            if (row.invoicedate === "") {
                continue
            }
            /**
             * let check for full paid
             */
            if (this.checkIfValidPeriod(row.invoicedate)) {
                amount += parseFloat(row.invoiceamount);
                if(row.paid&& row.invoiceamount !==row.paidamount){
                    console.log("QQQQQ: ",row.invoicenumber," , invoiceamount: ",row.invoiceamount," > ",row.paidamount)
                }
                if (row.paid&& (row.invoiceamount - row.paidamount)<=0) {
                    paid++;
                    fullPaidInvoices.push(row)

                }
                paidAmount += parseFloat(row.paidamount)
                total++;
            }

            /**
             * let check for overdue
             */
            if (this.checkIfValidPeriod(row.invoicedate)) {
                let todayDate = moment().format("YYYY-MM-DD");
                if (!row.paid && isDateGreatThan(todayDate, row.duedate)) {
                    overdueTotal++;
                    overdueAmount += parseFloat(row.invoiceamount-row.paidamount)
                    overDueInvoices.push(row);

                }
            }

            /**
             * let check for current invoice
             */
            if (this.checkIfValidPeriod(row.invoicedate)) {
                let todayDate = moment().format("YYYY-MM-DD");
                if (!row.paid && calculateNumberOfDaysBetweenDates(row.invoicedate, todayDate) <= 30) {
                    currentTotal++;
                    currentAmount += parseFloat(row.invoiceamount);
                    console.log("):(Current invoice: ", row.invoicenumber);
                    currentInvoices.push(row)
                }
            }

            /**
             * let check for partial payment
             */
            if (this.checkIfValidPeriod(row.invoicedate)) {
                let diff = row.invoiceamount - row.paidamount
                if(diff > 0 && row.paidamount>0){
                    partialAmount +=diff
                    partialInvoices.push(row);
                }
                if(diff<0){
                    let amountString = `${diff}`;
                    amountString = amountString.replace("-","")
                    overPaidAmount +=parseFloat(amountString)
                    overPaidInvoices.push(row);
                }

            }



        }

        if (paid !== 0) {
            percentage = (paid / total) * 100;
            percentage = parseFloat(percentage.toFixed(2));
        }

        balanceOwing = amount - paidAmount - overPaidAmount /*- overdueAmount*/;
        const fixed2 = (valIn) => {
            return parseFloat(valIn.toFixed(2))
        }

        amount = fixed2(amount)
        balanceOwing = fixed2(balanceOwing);
        paidAmount = fixed2(paidAmount);

        overduePercentage = (overdueTotal / total) * 100
        overduePercentage = fixed2(overduePercentage);

        currentPercentage = (currentTotal / total) * 100
        currentPercentage = fixed2(currentPercentage);

        // let remove the over paid amount
        overdueAmount = overdueAmount  - overPaidAmount

        overdueAmount = fixed2(overdueAmount)

        return {
            total, amount, paid, percentage, paidAmount, balanceOwing,
            overdueTotal, overdueAmount, overduePercentage,
            currentTotal, currentAmount, currentPercentage,
            partialAmount,overPaidAmount,overPaidInvoices,partialInvoices,
            overDueInvoices,currentInvoices,fullPaidInvoices

        }

    }

    getMonthList = () => {
        let ls = [];
        let dates = [];
        if (this.state.mode === "all" || this.state.mode === "YTD" || this.state.mode === "month") {
            let year = this.state.targetYear;
            for (let m = 1; m <= 12; m++) {
                let month = m < 10 ? `0${m}` : `${m}`;
                let date = `${year}-${month}-01`;
                dates.push(date)
            }
        }
        if (this.state.mode === "finance-year") {
            let fYear = FindFinancialYearRange(this.state.financialYearMonth, this.state.targetYear);
            dates = getDateListBetween(fYear.startDate, fYear.endDate)
        }

        /**
         * let generate now our date
         */
        for (let i in dates) {
            let item = dates[i];
            ls.push({
                label: moment(item, "YYYY-MM-DD").format("MMM").toUpperCase(),
                period: moment(item, "YYYY-MM-DD").format("YYYY-MM"),
                selected: false
            })
        }

        console.log("getMonthList > ", this.state.mode, " > ", ls)
        return ls
    }

    barInfo=()=>{
        let arr=this.getMonthList()
        let start="0000-00-00";
        let end="0000-00-00";
        if(arr.length>0){
            start = arr[0].period
            end = arr[(arr.length-1)].period
        }
        const changeFormat=(dateIn)=>{
            return moment(dateIn,"YYYY-MM").format("MMM-YYYY")
        }

        return(
            <div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                <span>From: <span style={{fontWeight:"bold"}}>{changeFormat(start)}</span></span>
                <span>To: <span style={{fontWeight:"bold"}}>{changeFormat(end)}</span></span>
            </div>
        )
    }

    render() {
        let extraStyle1 = {fontWeight: "bold", fontSize: 18}
        let infoFull = this.getInfoFully()
        return (

            <div className={"dashboardContainer"}>
                <div style={{marginLeft: 10, marginRight: 10,display:"flex",flexDirection:"row",justifyItems:"center"}}>
                    <Col span={8}>
                        <BuilderOptionComboBox
                            data={this.state.Companies}
                            keyVal={"CustomerNumber"}
                            keyLabel={"Name"}
                            label={"Select Organization"}
                            conditions={[]}
                            handleFunction={this.handleInput}
                            stateKey={"selectedCompany"}
                            currentValue={this.state.selectedCompany}
                            comboId={"company"}
                        />
                    </Col>
                    <Col span={8} style={{marginLeft:10}}>
                        <BuilderOptionComboBox
                            data={this.state.VirtualCompanies}
                            keyVal={"name"}
                            keyLabel={"name"}
                            label={"Select Company"}
                            conditions={[
                                {key:"org",val:this.state.selectedCompany}
                            ]}
                            handleFunction={this.handleInput}
                            stateKey={"selectedVirtualCompany"}
                            currentValue={this.state.selectedVirtualCompany}
                            comboId={"company"}
                            insertAllOption={true}
                        />
                    </Col>
                    <Col span={4} style={{
                        marginLeft:10,
                        display:'flex',
                        alignItems:"center",
                        fontSize:18,
                        marginTop:10
                    }}>
                        {this.barInfo()}
                    </Col>
                    <Col span={3}>&nbsp;</Col>
                    <Col span={1} style={{padding: 10, fontSize: 34,marginTop:10,marginLeft:10}}>
                        <CloseCircleOutlined size={34} onClick={() => this.goHome()}/>
                    </Col>
                </div>


                {this.state.selectedCompany !== "" ?
                    <Row>
                        <Col span={10}>
                            <Row>
                                <Col span={8}>
                                    <DashPaid
                                        amount={infoFull.paid} footerText={"Fully Paid invoices"}
                                        icon={dashPaidFullIcon}
                                        colorSchema={ColorGreen}
                                        invoices={infoFull.fullPaidInvoices}
                                    />
                                </Col>
                                <Col span={8}>
                                    <DashPaid amount={infoFull.paidAmount} footerText={"Amount Paid (ZAR)"}
                                              icon={dashPaidCashIcon} colorSchema={ColorGreen} extraBold={extraStyle1}/>
                                </Col>
                                <Col span={8}>
                                    <DashPaid amount={infoFull.percentage} footerText={"% Invoices Paid"}
                                              icon={dashPaidPercentageIcon}
                                              suffix={"%"} colorSchema={ColorGreen}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <DashPaid amount={infoFull.overdueTotal} footerText={"Overdue invoices"}
                                              icon={dashPaidFullIcon2}
                                              colorSchema={ColorOrange}
                                              invoices={infoFull.overDueInvoices}
                                    />
                                </Col>
                                <Col span={8}>
                                    <DashPaid amount={infoFull.overdueAmount} footerText={"Amount Overdue (ZAR)"}
                                              icon={dashPaidCashIcon2} colorSchema={ColorOrange}
                                              extraBold={extraStyle1}/>
                                </Col>
                                <Col span={8}>
                                    <DashPaid amount={infoFull.overduePercentage} footerText={"% Invoices Overdue"}
                                              icon={dashPaidPercentageIcon2}
                                              suffix={"%"} colorSchema={ColorOrange}

                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <DashPaid amount={infoFull.currentTotal} footerText={"Current invoices"}
                                              icon={dashPaidFullIcon3}
                                              colorSchema={ColorYellowDark}
                                              invoices={infoFull.currentInvoices}
                                    />
                                </Col>
                                <Col span={8}>
                                    <DashPaid amount={infoFull.currentAmount} footerText={"Amount Current (ZAR)"}
                                              icon={dashPaidCashIcon3} colorSchema={ColorYellowDark}
                                              extraBold={extraStyle1}/>
                                </Col>
                                <Col span={8}>
                                    <DashPaid amount={infoFull.currentPercentage} footerText={"% Invoices Current"}
                                              icon={dashPaidPercentageIcon3} suffix={"%"}
                                              colorSchema={ColorYellowDark}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <AnalysisOfAgedDebtorsTable data={this.getAgedByClient().data}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={14}>
                            <Row>
                                <Col span={5}>
                                    <TotalInvoicesSend amount={infoFull.total}/>
                                </Col>
                                <Col span={5}>
                                    <TotalBreakDown amountInvoice={infoFull.amount}
                                                    amountPaid={infoFull.paidAmount}
                                                    BalanceOwing={infoFull.balanceOwing}
                                                    partialAmount={infoFull.partialAmount}
                                                    overPaidAmount={infoFull.overPaidAmount}
                                                    overPaidInvoices={infoFull.overPaidInvoices}
                                                    partialPaidInvoices={infoFull.partialInvoices}
                                    />
                                </Col>
                                <Col span={14}>
                                    <SwitchPeriodInvoice
                                        year={this.state.targetYear}
                                        onChangePeriod={this.onChangePeriod}
                                        handleInput={this.handleInput}
                                        mode={this.state.mode}
                                        monthList={this.getMonthList()}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <AnalysisOfAgedDebtors/>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12}>
                                    <DashChartColumn
                                        chartId={"ch01"}
                                        titleChart={"Age Summary"}
                                        data={this.getDataForAgeSummary()}
                                        columnColor={["#00B050", "#FFC000", "#FF6359", "#B17ED8", ]}
                                    />
                                </Col>
                                <Col span={12}>
                                    <DashChartColumn
                                        chartId={"ch02"}
                                        titleChart={"Invoice Amount by month"}
                                        data={this.getAmountByMonth()}
                                        columnColor={["#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50",]}

                                    />
                                </Col>
                            </Row>

                            <Row style={{marginTop: -30}}>
                                <Col span={9}>
                                    <DashChart2
                                        chartId={"CustInv01"}
                                        data={this.getCustomerByInvoiceAmount().ls}
                                        columnColor={["#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50", "#333F50",]}
                                        titleChart={"Customers by invoice Amount"}
                                        categories={this.getCustomerByInvoiceAmount().categories}
                                    />
                                </Col>
                                <Col span={5}>
                                    <DashInvoiceAmountByCategory
                                        category={"Labour"}
                                        cost={this.getAmountByCategory().labour.amount}
                                        percentage={this.getAmountByCategory().labour.percentage}
                                        backgroundColor={"#01B1A8"}
                                        includeTitle={true}
                                    />
                                </Col>
                                <Col span={5}>
                                    <DashInvoiceAmountByCategory
                                        category={"Service"}
                                        cost={this.getAmountByCategory().service.amount}
                                        percentage={this.getAmountByCategory().service.percentage}
                                        backgroundColor={"rgb(67, 67, 72)"}
                                        includeTitle={true}
                                    />
                                </Col>
                                <Col span={5} style={{minHeight:300,maxHeight:300}}>
                                    <DashOutstandingByStatus
                                        titleChart={"Outstanding by status"}
                                        chartId={"ch0012"}
                                        data={this.getDataOutstandingByStatus()}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row> :
                    <div className={"errorSelectCompany"}>Please selected your company before viewing the
                        dashboard</div>}



            </div>

        )

    }
}

export default Connector(InvoiceDashboardPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
