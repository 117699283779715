import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {Button, Alert, Form, Layout, Select, Modal, Input} from "antd";
import {fetchDataEntity} from "../../../api/entity-data";
import {GetUserToken} from "../../../api/token";
import {
    attachImageProfile,
    GetProfileLink,
    GetRecordDisplayName,
    GetTargetFilesListOrg,
    SortData,
    UpCaseFirst
} from "../../../api/fn";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {NewAttributeFormStep} from "../../../components/web/Database/NewAttributeFormStep";
import DateSelectorBar from "../../../components/web/Clocking/DateSelectorBar";
import {StepBulkClockingIndex} from "../../../components/web/Clocking/StepBulkClockingIndex";
import {SubmitPost} from "../../../components/web/Common/localFunctions";
import SearchComboBox from "../../../components/Common/SearchBoxSelectionImage/SearchComboBox";
import SearchBoxSelectionImage from "../../../components/Common/SearchBoxSelectionImage";

const {TextArea} = Input;
const {Content} = Layout;
const {Option} = Select;


class MobileBulkClockUserPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Entities: [],
            Attributes: [],
            Databases: [],
            selectedCompany: "",
            selectedEntity: "",
            selectedRecord: {},
            Data: [],
            isLoading: false,
            component: "list",
            showNewForm: false,
            newUsername: "",
            newPassword: "",
            selectedEmployee:"",
            selectedEmployeeName:"",
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Entities", "", "", this, undefined, "common/entity/modules/list");
        await fetchDataEntity("Databases", "", "", this, undefined, "common/entity/databases/list");
        await fetchDataEntity("Attributes", "", "", this, undefined, "common/entity/attributes/list");
        await fetchDataEntity("Files", "", "", this, undefined, "common/entity/files/list");
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await fetchDataEntity("Data", "", "", this, undefined, "common/entity/MobileBulkClockUser/list")
    }
    initialReload=async ()=>{
        await fetchDataEntity("Data", "", "", this, undefined, "common/entity/MobileBulkClockUser/list")
    }
    handleChangeCompany = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    handleInput2=( key,value) => {
        this.setState({
            [key]: value,
        });
        console.log("handleInput2 > ", key," > ",value)
    }
    submitForm = async () => {
        let _this = this;
        if (this.state.selectedEmployee === "") {
            return alert("Field validation fail,please select your employee!")
        }

        let user = GetUserToken();
        if (!window.confirm("Are you sure that u want to submit  [" + this.state.selectedEmployee + "] " + this.state.selectedEmployeeName + " " +
            "as a bulk clock user?")) {
            return
        }
        _this.setState({
            isLoading: false,
        });

        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";

        let hub = {};
        hub.Org = this.state.selectedCompany;
        hub.Module = this.state.selectedEntity;
        hub.Ref = this.state.selectedEmployee;
        hub.Name = this.state.selectedEmployeeName;

        post.Data = hub;
        post.Params = {}

        let endpoint = "/common/entity/MobileBulkClockUser/insert"

        await SubmitPost(post, endpoint, "Thank you , Mobile login attributes submitted", this, async (dIn) => {
            alert("Thank you , Mobile login attributes submitted!")
            _this.setState({
                isLoading: false,
                showNewForm: false,
                component: "list",
                newUsername: "",
                newName: "",
            });
            await _this.initialReload();
            window.location.reload();
            // alert("Thank you , attributes submitted")
        })

    }
    onDeleteRecord=async (row)=>{
        console.log("onDeleteRecord > ",row)
        if(!window.confirm("Are you sure to remove "+row.name +" from bulk clock user list?")){
            return
        }
        let _this = this;
        _this.setState({
            isLoading: false,
        });

        let params=[
            {key:"Org", type:"string", val:row.org},
            {key:"Module", type:"string", val:row.module},
            {key:"Ref", type:"string", val:row.ref},
        ];
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";

        let hub = {};
        hub.Org = row.org;
        hub.Module = row.module;
        hub.Ref = row.ref;

        post.Data = hub;
        post.Params = params

        let endpoint = "/common/entity/MobileBulkClockUser/delete"

        await SubmitPost(post, endpoint, "Thank you , Mobile login attributes submitted", this, async (dIn) => {
            alert("Thank you , bulk clock user  submitted!")
            _this.setState({
                isLoading: false,
                showNewForm: false,
                component: "list",
                newUsername: "",
                newName: "",
            });
            await _this.initialReload();
            window.location.reload();
            // alert("Thank you , attributes submitted")
        })
    }
    onAddRecord = () => {
        if (this.state.selectedCompany === "") {
            return alert("Please select company first!")
        }
        if (this.state.selectedEntity === "") {
            return alert("Please select project first!")
        }
        this.setState({
            showNewForm: true,
            component: "form"
        });
    }
    closeNewForm = () => {
        this.setState({
            showNewForm: false,
            component: "list"
        });
    }
    renderCompaniesSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={"Select Company"}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedCompany")}
                    value={this.state.selectedCompany}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderModuleSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = [];
        for (let i in this.state.Entities) {
            const row = this.state.Entities[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            ls.push(row);
        }
        return (
            <Form.Item
                label={"Select Project"}
                name={"selectedEntity"}
                rules={[{required: true, message: "Please select your project!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedEntity")}
                    value={this.state.selectedEntity}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.name}
                                value={record.name}
                            >
                                {record.name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderList = () => {
        if (this.state.component !== "list") {
            return
        }
        let columns = [
            {id: "module", label: "Project"},
            {id: "ref", label: "User Reference"},
            {id: "name", label: "Full name"},
        ];

        let ls = [];
        for (let i in this.state.Data) {
            const row = this.state.Data[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.module !== this.state.selectedEntity) {
                continue
            }
            ls.push(row);
        }

        ls = SortData("position", ls)

        let data = {columns: columns, data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Records")}
                    onAddRecord={this.onAddRecord}
                    allowedAdd={true}
                    allowDelete={false}
                    onDelete={this.onDeleteRecord}
                />
            </GridItem>
        )

    }
    renderWarningBar = () => {
        return
        let boo = false
        if (this.state.selectedCompany === "" || this.state.selectedEntity === "" || this.state.component === "form") {
            boo = true
        }
        if (!boo) {
            return
        }
        return (
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Alert style={{margin: '16px 0'}} message="Please select your company above to view your records!"
                       type={"error"}/>
            </div>
        )
    }
    renderNewForm = () => {
        if (this.state.component !== "form") {
            return
        }

        const buildSelector = (stateKey, options, label, msg) => {
            return (
                <Form.Item
                    label={label}
                    name={stateKey}
                    rules={[{required: true, message: "Please select your " + msg}]}
                >
                    <Select
                        style={{width: "100%"}}
                        onChange={(value) => this.handleChangeCompany(value, stateKey)}
                        value={this.state[stateKey]}
                    >
                        {options.map((record, index) => {
                            return (
                                <Option
                                    key={record.key}
                                    value={record.val}
                                >
                                    {record.val}
                                </Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            )
        }



        return (
            <div>
                <Alert
                    message={(
                        <div>
                            <span style={{float: "left"}}>New Bulk clock user setup</span>
                            <a
                                style={{float: "right", color: "blue", cursor: "pointer"}}
                                onClick={this.closeNewForm}
                            >Close</a>
                        </div>
                    )}
                    style={{minHeight: 50}}
                />
                <br/>
                <Form.Item
                    label={"Select Employee"}
                    name={"selectedEmployee"}
                    rules={[{required: true, message: "Please select your employee" }]}
                >
                    {this.renderComboBoxEmployees()}
                </Form.Item>
                {/*{buildSelector("newUsername", ls, "Select Username attribute", "Username")}
                {buildSelector("newPassword", ls, "Select Password attribute", "Password")}*/}
                <Form.Item
                    label={""}
                    name={""}
                    rules={[{required: true, message: "Please select your "}]}
                >
                    <Button
                        type="primary"
                        style={{float: "right"}}
                        onClick={() => this.submitForm()}

                    >
                        Submit
                    </Button>
                </Form.Item>
            </div>
        )
    }


    renderComboBoxEmployees = () => {
        return(
            <SearchBoxSelectionImage
                _this={this}
                Databases={this.state.Databases}
                Attributes={this.state.Attributes}
                selectedCompany={this.state.selectedCompany}
                selectedModule={this.state.selectedEntity}
                Files={this.state.Files}
                handleInput={this.handleInput2}
                stateKey={"selectedEmployee"}
                stateKeyVal={"selectedEmployeeName"}
            />
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Mobile Setup Bulk Clock User</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        {this.renderCompaniesSelection()}
                        {this.renderModuleSelection()}

                    </div>
                    {this.renderWarningBar()}
                    {this.renderList()}
                    {this.renderNewForm()}
                </div>
            </Page>
        )
    }

}


export default Connector(MobileBulkClockUserPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
