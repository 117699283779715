import {PostToPbx} from "../../../api/http-post";

export  const SubmitPost2 = async (hub, endpoint, successMsg,_this,reloadFunction,callback) => {

    await PostToPbx(hub, endpoint, async (data) => {

        if(successMsg!=="") {
            _this.setState({
                isLoading: false,
                component: "list"
            })
        }
        if (data === null) {
            //alert("Server connection Error! try again later");
            return
        }
        if(data.STATUS==="fail"){
            alert("Fail, could not insert your extension,try again")
        }

        if(successMsg!==""){
            alert(successMsg);
            _this.setState({
                isLoading: false,
                component: "list"
            });
            if(typeof reloadFunction!=="undefined"){
                reloadFunction()
            }
            if(typeof callback!=="undefined"){
                callback("done")
            }
        }



    });
}


export  const SubmitPost = async (hub, endpoint, successMsg,_this,reloadFunction,callback) => {

    await PostToPbx(hub, endpoint, async (data) => {

        if(successMsg!=="") {
            _this.setState({
                isLoading: false,
                component: "list"
            })
        }
        if (data === null) {
            //alert("Server connection Error! try again later");
            return
        }
        if(data.STATUS==="fail"){
            alert("Fail, could not insert your extension,try again")
        }

        if(successMsg!==""){
            alert(successMsg);
            _this.setState({
                isLoading: false,
                component: "list"
            });
            await _this.loadInitialData();
            if(typeof reloadFunction!=="undefined"){
                reloadFunction()
            }
            if(typeof callback!=="undefined"){
                callback("done")
            }
        }



    });
}
