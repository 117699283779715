export default (props) => {
    let {_this, current} = props;

    if (current === 1) {//let validation mapping allocation
        if (_this.state.CsvPureData.length < 2) {
            alert("Validation Fail! Sorry the minimum data in your csv is 2 record, please check")
            return false;
        }
    }
    if (current === 2) {//let validation mapping allocation
        let booError = false;
        for (let i in _this.state.CsvHeaders) {
            let item = _this.state.CsvHeaders[i];
            if (typeof _this.state.entryMapping[item] === "undefined") {
                booError = true;
            }
        }
        if (booError) {
            alert("Validation fail, please map all csv heading to corresponding field")
            return false
        }
    }


    /**
     * TODO check for unique key
     */
        // let find the unique key
    let arrayMapField = [];
    let arrayMandatoryKeys = [];
    let uniqueKey = "";
    let uniqueKeyFind = false;
    if (current === 2) {//let validation for unique key mapping
        for (let i in _this.state.Attributes) {
            let row = _this.state.Attributes[i];
            if (row.org !== _this.state.selectedCompany) {
                continue
            }
            if (row.module !== _this.state.selectedModule) {
                continue
            }
            if (row.unique) {
                uniqueKey = row.fieldname;
            }
            if (row.mandatory) {
                arrayMandatoryKeys.push(row.fieldname);
            }
        }
        for (let key in _this.state.entryMapping) {
            const val = _this.state.entryMapping[key];
            arrayMapField.push({
                key: key,
                value: val
            });
            if (val === uniqueKey) {
                uniqueKeyFind = true
            }
        }
        if (!uniqueKeyFind) {
            alert("Fail! please map header to Unique which is: " + uniqueKey)
            return false
        }
    }

    //todo clean arrayMandatoryKeys
    let arrayMandatoryKeysTmp={};
    for(let i in arrayMandatoryKeys){
        const item = arrayMandatoryKeys[i];
        arrayMandatoryKeysTmp[item]=item
    }
     arrayMandatoryKeys=[];
    for(let item in arrayMandatoryKeysTmp){
        arrayMandatoryKeys.push(item)
    }

    const isInArrayValue=(keyIn,mapValues)=>{
        for(let key in mapValues){
            const val = mapValues[key];
            if(val===keyIn){
                return true
            }
        }
        return false;
    }

    if (current === 2) {//let do field  mandatory validation mapping
        let keyErrorString = "Fail, following key is required but not mapped: ";
        let booError = false;
        for (let i in arrayMandatoryKeys) {
            const targetKey = arrayMandatoryKeys[i];
            if(!isInArrayValue(targetKey,_this.state.entryMapping)){
                booError = true;
                keyErrorString = keyErrorString + "\n- " + targetKey;
            }

        }
        if (booError) {
            alert(keyErrorString);

            return false;
        }
    }

    if (current === 2) {//let do data validation unique key
        let ErrorCode = 0;
        let CsvDataMapped = [];
        /*
        ErrorTitle:"",
            ErrorCode:0,
            ErrorData:[],
         */
        //TODO let build our data in
        for (let i in _this.state.CsvPureData) {
            const row = _this.state.CsvPureData[i];
            let record = {};
            for (let key in _this.state.entryMapping) {
                let targetKey = _this.state.entryMapping[key];
                const targetVal = row[key];
                record[targetKey] = targetVal;
            }
            CsvDataMapped.push(record);
        }
        _this.setState({
            CsvDataMapped
        });

        //TODO check for unique key data
        let ErrorData = [];
        let CsvDataValid=[];
        for(let i in CsvDataMapped){
            let index = parseInt(i);
            index++;
            const row=CsvDataMapped[i];
            let rec = { errorCode:0,line:index,UniqueKeyError:"No",UniqueKey:"",MandatoryKeyError:"",MandatoryKeys:""};
            let anyError=false;
            if(row[uniqueKey]===""){
                ErrorCode=2;
                rec.errorCode = 2;
                rec.UniqueKey = uniqueKey;
                rec.UniqueKeyError = "Unique key empty"
            }

            //let verify mandatory data
            for(let x in arrayMandatoryKeys){
                const item = arrayMandatoryKeys[x];
                if(row[item]===""){
                    ErrorCode=2;
                    rec.errorCode = 2;
                    rec.MandatoryKeyError = "Mandatory key empty";
                    rec.MandatoryKeys = rec.MandatoryKeys+" | "+item
                }
            }
            if(rec.errorCode===2){
                ErrorData.push(rec);
            }else{
                CsvDataValid.push(row);
            }
        }

        _this.setState({
            ErrorData: ErrorData,
            ErrorCode:ErrorCode,
            ErrorTitle:"Unique & Mandatory validation fail",
            CsvDataValid:CsvDataValid
        });

        //TODO let check for
        return true;
    }

    // console.log("3 -(:)---> ",_this.state.selectedModule," > ",row.module);


    console.log("arrayMapField > ", arrayMapField, uniqueKey);
    return true;
}

/*
delete from databases where appname='pmis' and org='C100015' and module='Cleaning of Public Toilets/Bath Houses - Group 3';
 */