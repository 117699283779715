import React from "react";
import {Button, Col, DatePicker, Form, Row, Select} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";

const Option = Select.Option;
export default (props) => {
    let {_this} = props;
    let state = _this.state;
    let listSites = [];
    let listShift = [];
    for (let i in state.DataSite) {
        const row = state.DataSite[i];
        listSites.push({
            key: row.Name,
            val: row.Name,
        })
    }
    for (let i in state.DataShift) {
        const row = state.DataShift[i];
        listShift.push({
            key: row.Name,
            val: row.Name + " | start: " + row.Start + " | End: " + row.End,
        })
    }
    const onChange=(value,key)=>{
        _this.setState({
            [key]:value
        })
    }
    return (
        <div>
            <h3>Site & Date & Shift</h3>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="selectedSite"
                        label="Site"
                        rules={[{required: true, message: 'Please choose your site'}]}
                    >
                        <Select
                            style={{width: "100%"}}
                            onChange={(value) => onChange(value, "selectedSite")}
                            value={state.selectedSite}
                        >
                            {listSites.map((record, index) => {
                                return (
                                    <Option
                                        key={record.key}
                                        value={record.val}
                                    >
                                        {record.val}
                                    </Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="selectedShift"
                        label="Shift"
                        rules={[{required: true, message: 'Please choose your shift'}]}
                    >
                        <Select
                            style={{width: "100%"}}
                            onChange={(value) => onChange(value, "selectedShift")}
                            value={state.selectedShift}
                        >
                            {listShift.map((record, index) => {
                                return (
                                    <Option
                                        key={record.key}
                                        value={record.val}
                                    >
                                        {record.val}
                                    </Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}
