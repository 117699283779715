import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import {
    BuildComboDataClient,
    BuildComboDataCompany, BuildComboDataProject,
    FetchConfigDataService
} from "../../../../services/service-woodchipping";
import Title from "../../../../components/Common/Title";
import Page from "../../../../layout/web/Page";
import {Button, Col, DatePicker, Row} from "antd";
import ControllerSelectBox from "../../../../components/Common/ControllerInput/ControllerSelectBox";
import LoadingProcessing from "../../../../components/Common/page/Common/LoadingProcessing";

import Connector from "../../../../redux/connector";
import moment from "moment/moment";
import WoodChippingLoadBarInfoWrapper
    from "../../../../components/WoodChippingValidaation/WoodChippingLoadBarInfoWrapper";
import {PostToWoodChipping} from "../../../../api/http-post";

import {SampleData} from "./dumy"

class WoodChippingValidationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: null,
            ConfigData: {},
            ClientAlias:[],
            selectedCompany: "",
            selectedClient: "",
            selectedProject: "",
            selectedCompostMaker: "",
            targetDate: moment().format("YYYY-MM-DD"),
            InputDateStart:"",// "2024-05-01",
            InputDateEnd:"",// "2024-05-31",
            Results:null,//SampleData,
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("ClientAlias", "", "", this, undefined, "common/entity/WcAlias/list");
        //await fetchDataEntity("Data", "", "", this, undefined, "common/entity/WcRate/list");
        await this.fetchConfigData();
    }
    fetchConfigData = async () => {
        await FetchConfigDataService((res) => {
            this.setState({
                ConfigData: res
            })
        })
    }
    handleChangeInput = async (key, value) => {
        this.setState({
            [key]: value,
        })
    }
    handleGetInputValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return ""
        }
        return this.state[key];
    }

    validationOfInput = () => {
        if(this.state.selectedCompany===""){
            alert("Please select a company")
            return false
        }else if(this.state.selectedClient===""){
            alert("Please select a client")
            return false
        }else if(this.state.selectedProject===""){
            alert("Please select a project")
            return false
        }else if(this.state.InputDateStart===""){
            alert("Please select start date")
            return false
        }else if(this.state.InputDateEnd===""){
            alert("Please select end date")
            return false
        }
        return true
    }

    onSubmit = async () => {
        if (!this.validationOfInput()) {
            return
        }
        let payload = {
            Org: this.state.selectedCompany,
            Client: this.state.selectedClient,
            Project: this.state.selectedProject,
            StartDate: this.state.InputDateStart,
            EndDate: this.state.InputDateEnd,
        }
        let endpoint = "/report/delivery-note/validation"
        console.log("::onSubmit send> ", payload)
        this.setState({
            busy: true,
            Results: null,
        })
        await PostToWoodChipping(payload, endpoint, (res) => {
            console.log("::onSubmit result> ", res)
            try {
                this.setState({
                    busy: false,
                    Results: res ? res.RESULT : null,
                })
            } catch (e) {
                console.log("::onSubmit response error ", e)
                this.setState({
                    busy: false,
                    Results: null,
                })
            }

        })
    }

    render() {
        return (

            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    marginTop:-40
                }}>
                    <Title style={styles.title}> Wood Chipping Validation</Title>
                    <div style={{minWidth: "100%",}}>
                        <Row gutter={18} style={{marginTop:-50}}>
                            <Col span={12}>
                                <ControllerSelectBox
                                    noTop={true}
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={BuildComboDataCompany(this.state.ConfigData)}
                                    label={"Company"}
                                    name={"selectedCompany"}
                                />
                            </Col>
                            <Col span={12}>
                                <ControllerSelectBox
                                    noTop={true}
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={BuildComboDataClient(this.state.ConfigData, this.state.selectedCompany)}
                                    label={"Client"}
                                    name={"selectedClient"}
                                />
                            </Col>
                        </Row>
                        <Row gutter={18} style={{marginTop:-35}}>
                            <Col span={12}>
                                <ControllerSelectBox
                                    noTop={true}
                                    handleInput2={this.handleChangeInput}
                                    getHandleValue={this.handleGetInputValue}
                                    ls={BuildComboDataProject(
                                        this.state.ConfigData,
                                        this.state.selectedCompany,
                                        this.state.selectedClient
                                    )}
                                    label={"Project"}
                                    name={"selectedProject"}
                                />
                            </Col>
                            <Col span={12} style={styles.dateRow}>
                                <Col span={10} style={{paddingTop: 30}}>
                                    <DatePicker
                                        style={styles.datePickerBox}
                                        onChange={(date, dateString) => this.setState({InputDateStart: dateString})}
                                        placeholder={"Start Date"}
                                    />
                                </Col>
                                <Col span={10} style={{paddingTop: 30}}>
                                    <DatePicker
                                        style={styles.datePickerBox}
                                        onChange={(date, dateString) => this.setState({InputDateEnd: dateString})}
                                        placeholder={"End Date"}
                                    />
                                </Col>
                                <Col span={4} style={{
                                    paddingTop: 30 ,
                                    display:"flex",
                                    flexDirection:"row",
                                    justifyContent:"flex-end",
                                }}>
                                    <Button
                                        className={"antd-input"}
                                        onClick={this.onSubmit}
                                        style={{
                                            width: "100%",
                                            height: 40,
                                            alignSelf:"right",
                                            marginRight:-20
                                        }}
                                    >Search</Button>
                                </Col>

                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <LoadingProcessing open={this.state.busy}/>
                            </Col>
                        </Row>
                        {this.state.Results && <Row style={{backgroundColor: "transparent"}}>
                            <Col span={24}>
                                <WoodChippingLoadBarInfoWrapper
                                    results={this.state.Results}
                                    endDate={this.state.InputDateEnd}
                                    clientAlias={this.state.ClientAlias}
                                />
                            </Col>
                        </Row>}
                    </div>
                </div>
            </Page>
        )
    }

}

export default Connector(WoodChippingValidationPage);

const styles = {
    boxFilterInner: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 5,
        borderRight: "1px solid black",
        fontsize: 14,
        fontWeight: "bold"
    },
    boxFilter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    dateRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent:"center"
    },
    datePickerBox: {
        height: 40,
        width: "100%"
    },
    row1Th: {
        border: "1px solid black",
    },
    rowForm: {
        marginBottom: 10,
        paddingBottom: 5,
        border: "0px solid lightgray",
        borderBottomWidth: 1,

    },

    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray",
        marginTop:-50
    }
}