import React from "react";
import {attachImageProfile, GetProfileLink, GetRecordDisplayName, GetTargetFilesListOrg} from "../../../api/fn";
import SearchComboBox from "./SearchComboBox";



export default (props)=>{
    let {Databases,Files,Attributes,selectedCompany,selectedModule,_this,handleInput,stateKey,stateKeyVal}=props;
    console.log("stateKey > ",stateKey);
    if (selectedCompany === "" || selectedModule === "") {
        return null
    }
    let lsFiles = GetTargetFilesListOrg(selectedCompany,Files);
    Databases = attachImageProfile(lsFiles, Databases);

    let ls = [];
    for (let i in Databases) {
        let row = Databases[i];
        if (row.org !== selectedCompany || row.module !== selectedModule) {
            continue
        }
        let displayName = GetRecordDisplayName(selectedCompany,selectedModule,row, Attributes, "displayselection")
        let link = GetProfileLink(row.org, row.module, row.ref,Files);
        ls.push({
            key: row.ref,
            val: displayName,
            link: link
        })
    }

    return (
        <SearchComboBox
            _this={_this}
            label={"Employee Worker"}
            ls={ls}
            noImage={true}
            stateKey={stateKey}
            stateKeyVal={stateKeyVal}
            handleInput={handleInput}
        />
    )



}
