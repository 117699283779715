export const NAVIGATION_APP = "NAVIGATION_APP";
export const NAVIGATION_MENU = "NAVIGATION_MENU";
export const NAVIGATION_MODULE = "NAVIGATION_MODULE";
export const NAVIGATION_CONTENT = "NAVIGATION_CONTENT";
export const SET_APP_NAME = "SET_APP_NAME";
export const SET_APP_MODULE = "SET_APP_MODULE";
export const SET_APP_MENU = "SET_APP_MENU";
export const SET_APP_CONTENT = "SET_APP_CONTENT";
export const SET_APP_LINK = "SET_APP_LINK";
export const SET_NAV_MENUS = "SET_NAV_MENUS";
export const LOGGED_IN = "LOGGED_IN";

export const DASHBOARD_LIVE_CALL_IN = "DASHBOARD_LIVE_CALL_IN";
export const DASHBOARD_LIVE_CALL_OUT = "DASHBOARD_LIVE_CALL_OUT";
export const DASHBOARD_LIVE_PEER_STATUS = "DASHBOARD_LIVE_PEER_STATUS";

export const PBX_CHANGE_EXTENSION = "PBX_CHANGE_EXTENSION";
export const PBX_CHANGE_AGENT = "PBX_CHANGE_AGENT";
export const PBX_CALL_OUT_REQUEST = "PBX_CALL_OUT_REQUEST";
export const PBX_CALL_OUT_END = "PBX_CALL_OUT_END";
export const PBX_CALL_OUT_DO = "PBX_CALL_OUT_DO";
export const PBX_CALL_OUT_CALLERID = "PBX_CALL_OUT_CALLERID";
export const PBX_CALL_OUT_FORM = "PBX_CALL_OUT_FORM";
export const PBX_CALL_INCOME_REQUEST = "PBX_CALL_INCOME_REQUEST";
export const PBX_CALL_INCOME_ANSWER = "PBX_CALL_INCOME_ANSWER";
export const PBX_CALL_INCOME_HANGUP = "PBX_CALL_INCOME_HANGUP";
export const PBX_CHANGE_REGISTRY = "PBX_CHANGE_REGISTRY";
export const PBX_CALLING_STATUS = "PBX_CALLING_STATUS";
export const PBX_CALL_INCOME_ACTIVE = "PBX_CALL_INCOME_ACTIVE";
export const SAVE_MODULE_MENU="SAVE_MODULE_MENU",SAVE_APP_NAME="SAVE_APP_NAME";

export const CURRENT_CALLS = "CURRENT_CALLS";

export const GetFieldsAdditional = (categories) => {
    return [

        {name: "fieldname", dataType: "string", required: true, label: "Field Name", options: [],show:true},
        /*{name: "ref", dataType: "option", required: true, label: "Activity", options: categories ,show:true},*/
        {
            name: "datatype", dataType: "option", required: true, label: "Data Type", options: [
                {key: "string", val: "Text"},
                {key: "float", val: "number"},
                {key: "boolean", val: "Boolean (Yes or No)"},
                {key: "list", val: "List"},
                {key: "date", val: "Date"},
            ],show:true
        },
        {name: "displaytable", dataType: "boolean", required: true, label: "Display Table", options: yesNoOption,show:true},
        {name: "displayselection", dataType: "boolean", required: true, label: "Display Popup", options: yesNoOption,show:true},
        {name: "linkentity", dataType: "string", required: false, label: "Link To Entity", options: [] ,show:false},
        {name: "Options", dataType: "list", required: false, label: "Options List", options: [] ,show:false},
        {name: "defaultvalue", dataType: "string", required: false, label: "Default Value", options: [] ,show:false},
        {name: "position", dataType: "float", required: false, label: "Position", options: [],show:true},
        {name: "unique", dataType: "boolean", required: false, label: "Unique Key", options: yesNoOption,show:true},
        {name: "mandatory", dataType: "boolean", required: false, label: "Mandatory Key", options: yesNoOption,show:true},
        {name: "email", dataType: "boolean", required: false, label: "Email Key", options: yesNoOption  ,show:false},
        {name: "phone", dataType: "boolean", required: false, label: "Phone Key", options: yesNoOption  ,show:false} ,
    ]
}


export const GetFieldsAdditional2 = () => {
    return [

        {name: "fieldname", dataType: "string", required: true, label: "Field Name", options: [],show:true},
        {
            name: "datatype", dataType: "option", required: true, label: "Data Type", options: [
                {key: "string", val: "Text"},
                {key: "float", val: "number"},
                {key: "boolean", val: "Boolean (Yes or No)"},
                {key: "list", val: "List"},
                {key: "date", val: "Date"},
            ],show:true
        },
        {name: "displaytable", dataType: "boolean", required: true, label: "Display Table", options: yesNoOption,show:true},
        {name: "displayselection", dataType: "boolean", required: true, label: "Display Popup", options: yesNoOption,show:true},
        {name: "linkentity", dataType: "string", required: false, label: "Link To Entity", options: [] ,show:false},
        {name: "Options", dataType: "list", required: false, label: "Options List", options: [] ,show:false},
        {name: "defaultvalue", dataType: "string", required: false, label: "Default Value", options: [] ,show:false},
        {name: "position", dataType: "float", required: false, label: "Position", options: [],show:true},
        {name: "unique", dataType: "boolean", required: false, label: "Unique Key", options: yesNoOption,show:true},
        {name: "mandatory", dataType: "boolean", required: false, label: "Mandatory Key", options: yesNoOption,show:true},
        {name: "email", dataType: "boolean", required: false, label: "Email Key", options: yesNoOption  ,show:false},
        {name: "phone", dataType: "boolean", required: false, label: "Phone Key", options: yesNoOption  ,show:false} ,
    ]
}



const yesNoOption =[
    {key:"no",val:"No"},
    {key:"yes",val:"Yes"},
]
export const EntitiesOptions=[
    {
        label: "Include Contract setup ?",
        message: "Please select your Contract setup",
        type: "boolean",
        StateKey: "HasPayroll",
        required:true,
        options:yesNoOption
    },
    /*{
        label: "Include Clocking support",
        message: "Please select your clock",
        type: "boolean",
        StateKey: "HasClock",
        required:false,
        options:yesNoOption
    },
    {
        label: "Has Title ?",
        message: "Please select your title",
        type: "boolean",
        StateKey: "HasTitle",
        required:false,
        options:yesNoOption
    },
    {
        label: "Has Names ?",
        message: "Please select your names",
        type: "boolean",
        StateKey: "HasName",
        required:false,
        options:yesNoOption
    },
    {
        label: "Has Contact ?",
        message: "Please select your contact",
        type: "boolean",
        StateKey: "HasContact",
        required:false,
        options:yesNoOption
    },
    {
        label: "Has Vehicle ?",
        message: "Please select your vehicle",
        type: "boolean",
        StateKey: "HasVehicle",
        required:false,
        options:yesNoOption
    },
    {
        label: "Has Identity ?",
        message: "Please select your Identity",
        type: "boolean",
        StateKey: "HasIdentity",
        required:false,
        options:yesNoOption
    },
    {
        label: "Has Login ?",
        message: "Please select your login",
        type: "boolean",
        StateKey: "HasLogin",
        required:false,
        options:yesNoOption
    },
    {
        label: "Has Geo location ?",
        message: "Please select your geo location",
        type: "boolean",
        StateKey: "HasGeoLocation",
        required:false,
        options:yesNoOption
    },*/

]


