import {GetUserToken} from "../../../../api/token";
import {PostToPbx} from "../../../../api/http-post";
import {SortData} from "../../../../api/fn";


export const loadData = async (targetEntity, stateKey,_this) => {
    let userInfo = GetUserToken();
    let table = targetEntity;
    let hub = {};
    hub.Org = userInfo.Org;
    let post = {}
    post.AutoGenerate = false;
    post.HasUniqueKey = false;
    post.AutoGenerateField = "";
    post.Data = {};
    post.Params = {}
    post.Entity = table;
    let endpoint = "/common/entity/" + table + "/list";
    _this.setState({
        isLoading: true,
    })

    await PostToPbx(post, endpoint, async (data) => {
        await _this.setState({
            [stateKey]: data.RESULT
        })
    });
}
export const getModuleList = (modules,org) => {
    let ls = [];
    for (let i in modules) {
        let row = modules[i];
        if (row.org === org) {
            ls.push({
                key: row.name,
                val: row.name
            });
        }
    }
    return ls;
}
export const   getClientOption=(clients,org)=>{
    let ls = [];
    for (let i in clients) {
        let row = clients[i];
        if (row.org === org) {
            ls.push({
                key: row.name,
                val: row.name
            });
        }
    }
    return ls;
}
export const getCompanyList = (data) => {
    let ls = [];
    for (let i in data) {
        let row = data[i];
        ls.push({
            key: row.CustomerNumber,
            val: row.Name,
        });
    }
    return ls;
}
export const getProjectList = (data,org,client) => {
    let ls = [];
    for (let i in data) {
        let row = data[i];
        if (row.org === org&&row.client===client) {
            ls.push({
                key: row.name,
                val: row.name
            });
        }
    }
    return ls;
}
export const getItemList = (data,org,client,project) => {
    let ls = [];
    for (let i in data) {
        let row = data[i];
        if (row.org === org&&row.client===client) {
            ls.push({
                key: row.name,
                val: row.name
            });
        }
    }
    return ls;
}
export const getPeriod1List=(data,org,client,project,item) => {
    let ls = [];
    data = SortData("periodnumber",data)
    for (let i in data) {
        let row = data[i];
        if (row.org === org&&row.client===client&& row.project ===project&& row.item===item) {
            ls.push({
                key: row.month+"-"+row.year,
                val:  row.month+" - "+row.year,
            });
        }
    }
    return ls;
}
export const getTemplateList = () => {
    return [
        {key: "CoCT-Worker", val: "CoCT Worker"},
        {key: "CoCT-Personnel", val: "CoCT Key Personnel"},
        {key: "CoCT-Vaccination", val: "CoCT Vaccination"},
    ]
}
export const getYearOption = () => {
    return [
        {key: "2021", val: "2021"},
        {key: "2022", val: "2022"},
        {key: "2023", val: "2023"},
        {key: "2024", val: "2024"},
        {key: "2025", val: "2025"},
    ]
}
export const  getMonthOption = () => {
    return [
        {key: "January", val: "January"},
        {key: "February", val: "February"},
        {key: "March", val: "March"},
        {key: "April", val: "April"},
        {key: "May", val: "May"},
        {key: "June", val: "June"},
        {key: "July", val: "July"},
        {key: "August", val: "August"},
        {key: "September", val: "September"},
        {key: "October", val: "October"},
        {key: "November", val: "November"},
        {key: "December", val: "December"},
    ]
}
export const  getYesNoOption = () => {
    return [
        {key: "yes", val: "Yes"},
        {key: "no", val: "No"},
    ]
}