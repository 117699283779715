import {GetUserToken} from "./token";
import Configures from "../configures";
import {Col, DatePicker, Form, Input, Row, Select, Table} from "antd";
import React from "react";
import {PostToPbx} from "./http-post";
import deleteIcon from "../assets/img/delete-icon-image.png"
import updateIcon from "../assets/img/edit-icon-image.jpg";
import moment from "moment";
import TableDataView from "../components/Common/tables/TableDataView";
import {GetConfigures} from "../configures/serve";

const server =GetConfigures();

const {Option} = Select;

export const formatDateSouth1=(dataIn)=>{
    return moment(dataIn).format("DD-MMM-YYYY");
}
export function nFormatter(num, digits) {

    /**
     *
     *  peter way
     */

    let x1 = num/1000000
    let x2 = x1.toFixed(1)
    let x3 = x2+"m"
    return x3


    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}
export const BuilderTableList = ({
                                     data,
                                     fields,
                                     conditions,
                                     deleteLink,
                                     deleteConditions,
                                     deleteAction,
                                     searchKey,
                                     AvoidDisplay,
                                     MakeLinks,
                                     editAction
                                 }) => {
    let columns = [{
        title: "#",
        dataIndex: "Action",
        align: "left",
    }];
    let columns2=[];
    let indexColumns = 0;
    let fieldDataTypes = {};
    for (let i in fields) {
        let row = fields[i]
        if (typeof AvoidDisplay !== "undefined") {
            if (typeof AvoidDisplay[row.field] !== "undefined") {
                continue
            }
        }
        columns.push({
            title: row.label,
            dataIndex: row.field.toLowerCase(),
            align: row.align,
        })
        columns2.push({
            id: row.field.toLowerCase(), label: row.label, minWidth: 170, dataType: "string"
        })
        fieldDataTypes[row.field.toLowerCase()] = row.datatype;
        indexColumns++
    }

    console.log("fieldDataTypes > ", fieldDataTypes)

    let ls = [];
    for (let i in data) {
        let row = data[i];
        let OriginalData = {...row};
        if (!CheckConditions(row, conditions)) {
            continue
        }
        let keyLink = <>
            <a onClick={() => deleteAction(row, deleteLink, deleteConditions)}>
                <img
                    style={{
                        width: 22, height: 22, resizeMode: "contain"
                    }}
                    src={deleteIcon}
                    alt={"delete icon"}/>
            </a>
            <a onClick={() => editAction(row, deleteLink, deleteConditions)}>
                <img
                    style={{
                        width: 22, height: 22, resizeMode: "contain",marginLeft:10,
                    }}
                    src={updateIcon}
                    alt={"edit icon"}/>
            </a>

        </>
        //row.Action = keyLink

        /**
         * appande link
         */
        if (typeof MakeLinks !== "undefined") {
            for (let a in MakeLinks) {
                let rec = MakeLinks[a];
                let targetField = rec.target;
                let linkUrl = row[rec.link];

                row[targetField] = <>
                    <a onClick={() => OnDocView(linkUrl, "View Doc")}>{row[targetField]}</a>

                </>
            }
        }
        /**
         * check for boolean
         */
        for (let a in row) {
            let val = row[a];
            if (typeof val == "boolean") {
                // variable is a boolean
                row[a] = val === true ? "Yes" : "No"
            }
        }
        /*
            let check for date and number format
         */
        const isReadyDate=(valIn)=>{
            let listMonths=[
                {label:"JAN",period:1,selected:false},
                {label:"FEB",period:2,selected:false},
                {label:"MAR",period:3,selected:false},
                {label:"APR",period:4,selected:false},
                {label:"MAY",period:5,selected:false},
                {label:"JUN",period:6,selected:false},
                {label:"JUL",period:7,selected:false},
                {label:"AUG",period:8,selected:false},
                {label:"SEP",period:9,selected:false},
                {label:"OCT",period:10,selected:false},
                {label:"NOV",period:11,selected:false},
                {label:"DEC",period:12,selected:false},
            ]

            let boo=false;
            let arrDate = valIn.split("-");
            let month = arrDate[1].toLocaleUpperCase();
            for(let z in listMonths){
                let rec = listMonths[z];
                if(rec.label===month){
                    boo = true
                }
            }
            return boo
        }
        for (let a in row) {
            let val = row[a];
            let dataType = fieldDataTypes[a];
            if (dataType === "date" && val !== "") {
                if(isReadyDate(val)){
                    row[a] =val;
                }else{
                    row[a] = moment(`${val}T09:52:03.915Z`).format("DD-MMM-YY");
                }

            }
            if (dataType === "float" && val !== "") {
                row[a] = FormatMoneySouthAfrica(val)
            }
        }

        row.OriginalData  = OriginalData;
        ls.push(row)
    }

    let otherFunctions=[
        /*https://materializecss.com/icons.html*/
        {
            icon:"edit",
            isFreeAction:false,
            tooltip:"Edit",
            myAction:editAction,
            color:"primary"
        }
    ]

    let dataTable = {columns: columns2, data: ls}

    return (
        <TableDataView
            {...dataTable}
            tableTitle={ " Records  (" + ls.length + ") "}
            onDelete={deleteAction}
            otherFunctions={otherFunctions}
            allowedAdd={false}
            options={{
                pageSize:20,
            }}
        />
    )


    return (
        <div style={{minWidth: "100%", padding: 5, overflow: "scroll"}}>
            <div style={{
                selfAlign: "right",
                minWidth: "100%",
                maxWidth: "100%",
                minHeight: 38,
                alignItems: "center",
                padding: 0,
            }}>
                <Table
                    columns={columns}
                    dataSource={ls}
                    bordered
                />
            </div>
        </div>
    )
}
export const OnDownload = (linkDownload) => {
    let url = linkDownload;
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = "Invoice_Labour_" + linkDownload;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        a.remove()
    };
    xhr.open('GET', url);
    xhr.send();
}

export const OnDocView = (linkDownload, title) => {
    let link = linkDownload;
    console.log("Opening link pdf link request > ", link);
    document.title = title;
    window.open(link, '_blank');
}
export const CheckConditions = (row, conditions) => {
    let boo = true;
    if (conditions.length > 0) {
        for (let e in conditions) {
            let rec = conditions[e];
            const kCheck = row[rec.key]
            const kValue = rec.val;
            if (kValue !== kCheck) {
                boo = false
                break
            }
        }
    }
    return boo
}
export const BuilderOptionComboBox = ({
                                          data,
                                          label,
                                          keyVal,
                                          keyLabel,
                                          conditions,
                                          stateKey,
                                          handleFunction,
                                          currentValue,
                                          comboId,
    insertAllOption,
                                      }) => {
    let ls = [];
    const onChange = (e) => {
        let value = e.target.value;
        //console.log("BuilderOptionComboBox onChange > ", value, " > ", " > ", label, " > ", stateKey, " > ", data)
        handleFunction(value, stateKey)
    }

    if (label === "Select Item | Job description") {
        //console.log(`BuilderOptionComboBox :)) ${label} -- ${keyVal} -- `, conditions, " -- ", data)
    }

    if(typeof insertAllOption !=="undefined"){
        if(insertAllOption){
            let record = {
                key: "all",
                val: "All"
            }
            ls.push(record)
        }
    }

    for (let i in data) {
        let row = data[i];
        if (!CheckConditions(row, conditions)) {
            continue
        }

        let record = {
            key: row[keyVal],
            val: row[keyLabel]
        }
        ls.push(record)
    }

    return (
        <div style={{minWidth: "100%", padding: 0}}>
            <Row gutter={16} style={{marginTop: 10}}>
                <Col span={24}>
                    <label>{label}</label><br/>

                    <select
                        style={{
                            minWidth: "100%",
                            maxWidth: "100%",
                            border: "0.5px solid gray",
                            borderRadius: 5,
                            minHeight: 30,
                            backgroundColor: "transparent"
                        }}
                        onChange={(e) => onChange(e)}
                        key={comboId}
                        name={comboId}
                    >
                        <option key={""} value={""}>{""}</option>
                        {ls.map((record, index) => {

                            return (
                                <option key={record.key} value={record.key}>{record.val}</option>
                            )
                        })}
                    </select>

                </Col>
            </Row>
        </div>
    )
}

export const GetYearList = () => {
    let currentYearString =moment().format("YYYY")
    let currYear = parseInt(currentYearString)
    let list=[];
    /*for(let i=currYear;i>=(currYear-4);i--) {
        list.push({
            key: `${i}`,
            val: `${i}`
        })
    }*/

        for(let i=(currYear-4);i<=(currYear+4);i++){
            list.push({
                key: `${i}`,
                val: `${i}`
            })
        }

        return list
    /*return [
        {key: "2021", val: "2021"},
        {key: "2022", val: "2022"},
        {key: "2023", val: "2023"},
    ]*/
}
export const GetMonthList = () => {
    return [
        {Key: "January", val: "January"},
        {Key: "February", val: "February"},
        {Key: "March", val: "March"},
        {Key: "April", val: "April"},
        {Key: "May", val: "May"},
        {Key: "June", val: "June"},
        {Key: "July", val: "July"},
        {Key: "August", val: "August"},
        {Key: "September", val: "September"},
        {Key: "October", val: "October"},
        {Key: "November", val: "November"},
        {Key: "December", val: "December"},
    ]
}
export const LoadDataCommon = async (targetEntity, stateKey, _this) => {
    let userInfo = GetUserToken();
    let table = targetEntity;
    let hub = {};
    hub.Org = userInfo.Org;
    let post = {}
    post.AutoGenerate = false;
    post.HasUniqueKey = false;
    post.AutoGenerateField = "";
    post.Data = {};
    post.Params = {}
    post.Entity = table;
    let endpoint = "/common/entity/" + table + "/list";
    _this.setState({
        isLoading: true,
    })
    await PostToPbx(post, endpoint, async (data) => {
         _this.setState({
            [stateKey]: data.RESULT
        });
         console.log("LoadDataCommon> ",stateKey," > ",table," > ",data.RESULT)
         _this[stateKey] = data.RESULT
    });
}

// Create our number formatter.
export const FormatMoneySouthAfrica = (num, div = " ") => {
    //num = parseFloat(num.toFixed(2))
    let strResult = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, div);
    let arr = strResult.split(".");
    if(arr.length===1){
        strResult = strResult+".00";
    }else{
        let last = arr[1];
        if(last.length===1){
            strResult = strResult+"0";
        }
    }
    return strResult;

}
export const FormatMoneySouthAfrica2 = (num, div = " ") => {
    if(typeof num ==="undefined"){
        num = 0;
    }
    let strResult = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, div);
    return strResult;
}
export const FormatMoneySouthAfrica3 = (num, div = " ") => {
    if(typeof num ==="undefined"){
        num = 0;
    }
    //num = parseFloat(num.toFixed(2))
    let strResult = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, div);
    let arr = strResult.split(".");
    if(arr.length===1){
        strResult = strResult+".00";
    }else{
        let last = arr[1];
        if(last.length===1){
            strResult = strResult+"0";
        }
    }
    return "R "+strResult;

}
export function formatMoney(number, decPlaces, decSep, thouSep) {
    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
    decSep = typeof decSep === "undefined" ? "." : decSep;
    thouSep = typeof thouSep === "undefined" ? " " : thouSep; // use the "," instead
    let sign = number < 0 ? "-" : "";
    let i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
    let j = 0
    j = (j = i.length) > 3 ? j % 3 : 0;

    return sign +
        (j ? i.substr(0, j) + thouSep : "") +
        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
        (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
}

export const isValueBoolean = (variable) => {
    if (typeof variable === "boolean") {
        // variable is a boolean
        return true
    }
    return false
}


export const ArrayToSelectOption = (arrayList, keysValues, keysLabels) => {
    let ls = [];
    for (let i in arrayList) {
        let rowRecord = arrayList[i];
        let key = "";
        let val = "";
        //let find our keys
        let x = 0;
        for (let a in keysValues) {
            const item = keysValues[a]

            if (x === 0) {
                key = rowRecord[item]
            } else {
                key = key + " " + rowRecord[item]
            }
            x++
        }
        //let find our values
        x = 0;
        for (let a in keysLabels) {
            const item = keysLabels[a]
            if (x === 0) {
                val = rowRecord[item]
            } else {
                val = val + " " + rowRecord[item]
            }
            x++
        }

        ls.push({
            key: key,
            val: val
        })
    }
    return ls
}
export const FilterArrayByKey = (arrList, filterObject) => {
    let ls = [];
    for (let i in arrList) {
        let row = arrList[i];
        let boo = true;
        for (let key in filterObject) {
            let val = filterObject[key];
            if (row[key] !== val) {
                boo = false
            }
        }
        if (!boo) {
            continue
        }
        ls.push(row)
    }
    return ls
}

export const renderP = (key, val) => {
    return (
        <p>{key} : {val}</p>
    )
}
export const avoidKeys = (keyIn) => {
    let arr = ["id", "appname", "org", "profile", "status", "orgdatetime", "key", "date", "time"];
    for (let i in arr) {
        const item = arr[i];
        if (item === keyIn) {
            return false
        }
    }
    return true
}
export const GetTargetFilesListOrg = (org, Files) => {
    let lsFiles = [];
    for (let i in Files) {
        const row = Files[i];
        if (row.org !== org) {
            continue
        }
        lsFiles.push(row)
    }
    return lsFiles
}

export const GetRecordDisplayName = (org, module, inData, attributes, targetField) => {
    let lsAttributes = [];
    for (let i in attributes) {
        let row = attributes[i];
        if (row.org !== org || row.module !== module) {
            continue
        }
        if (row[targetField]) {
            lsAttributes.push(row)
        }
    }
    console.log("+++++++>>List fields > ", lsAttributes);
    let names = "";
    for (let i in lsAttributes) {
        const row = lsAttributes[i];
        const fieldname = row.fieldname;
        names += inData.data[fieldname] + " "
    }
    names = names.trim();
    names = UpCaseFirst(names);
    return names
}

export const GetProfileLink = (org, module, ref, Files) => {
    for (let i in Files) {
        const row = Files[i];
        if (row.org !== org) {
            continue
        }
        if (row.module !== module) {
            continue
        }
        if (row.ref !== ref) {
            continue
        }
        if (row.type.trim() === "profile") {
            const dropboxId = row.dropboxref;
            const endpoint = "/files/download/" + dropboxId;
            let url = server + "" + endpoint;
            return url;
        }
    }
    return "https://lh3.googleusercontent.com/proxy/JhRvqc9ggu11-VxutWzJk9JTDeHXijqLAjvIcsQwMJGBXAL642lreMWW4KjPI-KvA1f9smVix0z0K6ZzDAQNFMEFpCrTjwaTVFcdnaz3QnSWc7-ENiTWNXD2fKY-iOgyl9m9EF99DbnMeXqrjqN-Nhyf"

}


export const renderCompaniesComboxbox = (storage, key, val, handleChange, stateKey) => {
    let user = GetUserToken();
    let ls = [];
    for (let i in storage) {
        const row = storage[i];
        if (user.Role === "super") {
            ls.push({
                key: row[key],
                val: row[val],
            })
        } else {
            if (row[key] === user.Org) {
                ls.push({
                    key: row[key],
                    val: row[val],
                })
            }
        }


    }
    return (
        <Select
            name={stateKey}
            defaultValue=""
            onChange={(e, ee) => handleChange(ee.value, stateKey)}
        >
            {ls.map((record, index) => {
                return (
                    <Option value={record.key}>{record.val}</Option>
                )
            })}
        </Select>
    )
}
export const LoadDataAnyEndpoint = async (hub, endpoint, stateKey, _this, feedback) => {

    //let endpoint = "/common/entity/" + table + "/list";
    _this.setState({
        isLoading: true,
    })

    await PostToPbx(hub, endpoint, async (data) => {
        console.log("LoadDataNoCondition response > ", hub, " > ", data);
        let res = [];
        if (data !== null) {
            res = data.RESULT
        }
        await _this.setState({
            [stateKey]: res,
            isLoading: false,
        });
        feedback(res);
    });
}
export const LoadDataNoCondition = async (targetEntity, stateKey, _this, params, feedback) => {

    let userInfo = GetUserToken();
    let table = targetEntity;
    let hub = {};
    hub.Org = userInfo.Org;
    let post = {}
    post.AutoGenerate = false;
    post.HasUniqueKey = false;
    post.AutoGenerateField = "";
    post.Data = {};
    post.Params = {};
    if (typeof params !== "undefined") {
        post.Params = params;
    }
    post.Entity = table;
    let endpoint = "/common/entity/" + table + "/list";
    _this.setState({
        isLoading: true,
    });

    await PostToPbx(post, endpoint, async (data) => {
        console.log("LoadDataNoCondition response > ", post, " > ", data);
        let res = [];
        if (data !== null) {
            res = data.RESULT
        }

        if (typeof feedback !== "undefined") {
            feedback(res);
        } else {
            await _this.setState({
                [stateKey]: res,
                isLoading: false,
            });
        }
    });
}
export const makeCol = (row, _this, uniquekey) => {
    let {state, handleChangeInput} = _this;
    const onChangeDate = (date, dateString, fieldName) => {
        handleChangeInput(fieldName, dateString)
    }
    const isUniqueKey = (key) => {
        if (key === uniquekey) {
            return true
        }
        return false
    }
    if (row === null) {
        return null;
    }
    if (row.datatype === "list") {
        let options = [];
        for (let i in row.options) {
            const key = i;
            const val = i;
            options.push({
                key: key,
                val: val,
            })
        }
        return (
            <Col span={12}>
                <Form.Item
                    name={row.fieldname}
                    label={row.fieldname}
                    rules={[{required: row.mandatory, message: 'Please enter user ' + row.fieldname}]}
                >
                    <Select
                        placeholder={"Please enter user " + row.fieldname}
                        onChange={(e) => handleChangeInput(row.fieldname, e)}
                        defaultValue={_this.getHandleChangeValKey(row.fieldname)}
                        required={row.mandatory}
                        disabled={isUniqueKey(row.fieldname)}
                    >
                        {options.map(rec => {
                            return (
                                <Option value={rec.key}>{rec.val}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            </Col>
        )
    }
    if (row.datatype === "date") {
        return (
            <Col span={12}>
                <Form.Item
                    name={row.fieldname}
                    label={row.fieldname}
                    rules={[{required: row.mandatory, message: 'Please enter user ' + row.fieldname}]}
                >
                    <DatePicker
                        onChange={(date, dateString) => onChangeDate(date, dateString, row.fieldname)}
                        disabled={isUniqueKey(row.fieldname)}
                    />
                </Form.Item>
            </Col>
        )
    }
    return (
        <Col span={12}>
            <Form.Item
                name={row.fieldname}
                label={row.fieldname}
                rules={[{required: row.mandatory, message: 'Please enter user ' + row.fieldname}]}
            >
                <Input
                    placeholder={"Please enter user " + row.fieldname}
                    onChange={(e) => handleChangeInput(row.fieldname, e.target.value)}
                    defaultValue={_this.getHandleChangeValKey(row.fieldname)}
                    value={_this.getHandleChangeValKey(row.fieldname)}
                    required={row.mandatory}
                    disabled={isUniqueKey(row.fieldname)}
                />
            </Form.Item>
        </Col>
    )
}

export function csvJSON(csv) {
    let lines = csv.split("\n");
    let result = [];
    // NOTE: If your columns contain commas in their values, you'll need
    // to deal with those before doing the next step
    // (you might convert them to &&& or something, then covert them back later)
    // jsfiddle showing the issue https://jsfiddle.net/
    let headers = lines[0].split(",");
    for (let i = 1; i < lines.length; i++) {

        let obj = {};
        let currentline = lines[i].split(",");

        for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j];
        }
        result.push(obj);

    }
    return {
        headers: headers,
        data: result
    }
}

export function ToDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

export const RecordExtractDataField = (org, record, TypeKey, attributes) => {
    for (let i in attributes) {
        const row = attributes[i];
        if (row.Org !== org) {
            continue
        }
        if (row[TypeKey]) {
            const tField = row.FieldName;
            const val = record.Data[tField];
            return val;
        }
    }
    return ""
}
export const RenderModules = (org, modules) => {
    let ls = [];
    for (let i in modules) {
        const row = modules[i];
        if (row.Org === org) {
            ls.push({
                key: row.Name,
                val: row.Name,
            })
        }
    }
    return ls
}
export const attachImageProfile = (files, dataIn) => {

    let ls = [];
    for (let i in dataIn) {
        let row = dataIn[i];

        const link = FindImages2(row, files)
        row.link = link;
        ls.push(row)
    }

    return ls;
}
export const buildAssociationData = (props, self, targetDisplay) => {
    const Databases = props.state.Databases;
    const files = props.state.Files;
    const StoreAssociations = props.state.Associations;
    let associations = [];
    //let self = this;

    const GetRecord = (module, ref) => {
        let names = "---";
        for (let i in Databases) {
            const row = Databases[i];
            if (row.module === module && row.ref === ref) {
                names = getDisplayNameEntity(props, module, row, targetDisplay)
            }
        }
        return names;
    }
    for (let i in StoreAssociations) {
        let row = StoreAssociations[i];
        /**
         * Get Names of participants
         */
        row.MainName = GetRecord(row.MainEntity, row.Main);
        row.SupplierName = GetRecord(row.SupplierEntity, row.Supplier);
        /**
         * Get Image profile link
         */
        row.Mainimg = FindImages(row.Main, files);
        row.Supplierimg = FindImages(row.Supplier, files);
        /**
         * save into array
         */
        associations.push(row);
    }

    return associations;

}
export const getDisplayNameEntity = (props, module, record, targetDisplay) => {
    const fieldList = getListDisplayField(props, module, targetDisplay);
    let names = "";
    for (let i in fieldList) {
        const row = fieldList[i];
        names += record.data[row.fieldname] + " "
    }
    return names
}
export const getListDisplayField = (props, module, targetDisplay) => {
    let ls = [];
    const attributes = props.state.Attributes;
    for (let i in attributes) {
        const row = attributes[i];
        if (row.module === module) {
            if (row[targetDisplay]) {
                ls.push(row)
            }
        }
    }
    ls = SortData("Position", ls);
    return ls
}
export const findRecordType = (name, fieldsList) => {
    let rows = fieldsList;
    for (let i in rows) {
        let row = rows[i];
        if (row.field === name) {
            return row.type
        }
    }
    return "text"
}
export const findRecordUniqueKey = (fieldsList, _this) => {
    //const listFields = _this.getListField();
    let ls = fieldsList;
    for (let i in ls) {
        const row = ls[i];
        if (row.unique) {
            let fValue = _this.state[row.fieldname];
            return fValue
        }
    }
    return ""
}
export const findRecordUniqueKeyX = (fieldsList, data) => {
    //const listFields = _this.getListField();
    let ls = fieldsList;
    for (let i in ls) {
        const row = ls[i];
        if (row.unique) {
            let fValue = data[row.fieldname];
            return fValue
        }
    }
    return ""
}

export const findRecordDisplayKey = (fieldsList, key, _this) => {
    let name = "";
    let ls = fieldsList;
    for (let i in ls) {
        const row = ls[i];

        if (row[key]) {
            let fValue = _this.state[row.fieldname];
            name = name + fValue + " ";
        }
    }
    return name;
}
export const findRecordDisplayKeyX = (fieldsList, key, data) => {
    let name = "";
    let ls = fieldsList;
    for (let i in ls) {
        const row = ls[i];

        if (row[key]) {
            let fValue = data[row.fieldname];
            name = name + fValue + " ";
        }
    }
    return name;
}

export const findRecordKey = (fieldsList, key, _this) => {
    let name = "";
    let ls = fieldsList;
    for (let i in ls) {
        const row = ls[i];
        if (row[key]) {
            let fValue = _this.state[row.fieldname];
            return fValue;
        }
    }
    return name;
}
export const findRecordKeyX = (fieldsList, key, data) => {
    let name = "";
    let ls = fieldsList;
    for (let i in ls) {
        const row = ls[i];
        if (row[key]) {
            let fValue = data[row.fieldname];
            return fValue;
        }
    }
    return name;
}
export const findRecordKeyField = (fieldsList, key, _this) => {
    let name = "";
    let ls = _this.state.Attributes;
    for (let i in ls) {
        const row = ls[i];
        if (row[key]) {
            return row.fieldname;
        }
    }
    return name;
}

export const SortDataDate = (sortKey, data) => {
    let tmpData=[];
    for(let i in data){
        let row = data[i];
        row[sortKey] = new Date(row[sortKey])
        tmpData.push(row)
    }
    tmpData.sort((a, b) => (a[sortKey] > b[sortKey]) ? 1 : -1)

    let outData=[]
    for(let i in tmpData) {
        let row = tmpData[i];
        row[sortKey] = moment(row[sortKey]).format("YYYY-MM-DD")
        outData.push(row)
    }
    return outData;
}
export const SortData = (sortKey, data) => {
    data.sort((a, b) => (a[sortKey] > b[sortKey]) ? 1 : -1)
    return data;
}
export const SortDataDesc = (sortKey, data) => {
    data.sort((a, b) => (a[sortKey] < b[sortKey]) ? 1 : -1)
    return data;
}
export const ConvertBase64IntoString = (b64) => {
    let b = new Buffer(b64, 'base64');
    let s = b.toString();
    console.log("convertBase64IntoString > ", s); // Outputs: "SGVsbG8gV29ybGQh"
    return s;
}

export const ConvertStringToBase64 = (htmlBody) => {
    let b = new Buffer(htmlBody);
    let s = b.toString('base64');
    console.log("convertStringToHtml > ", s); // Outputs: "SGVsbG8gV29ybGQh"
    return s;
}


export const IsFindInArray = (searchKey, searchValue, data) => {
    for (let i in data) {
        const row = data[i];
        if (typeof row[searchKey] !== "undefined") {
            if (row[searchKey] === searchValue) {
                return true
            }
        }
    }

    return false
}
export const UpcaseFirst = (inWord) => {
    const upper = inWord.charAt(0).toUpperCase() + inWord.substring(1);
    return upper;
}
export const FindImages2 = (rowIn, Files) => {
    for (let i in Files) {
        const row = Files[i];
        if (row.org !== rowIn.org) {
            continue
        }
        if (row.module !== rowIn.module) {
            continue
        }
        if (row.ref !== rowIn.ref) {
            continue
        }
        if (row.type.trim() === "profile") {
            const dropboxId = row.dropboxref;
            const endpoint = "/files/download/" + dropboxId;
            let url = server + "" + endpoint;
            return url;
        }
    }
    return "https://lh3.googleusercontent.com/proxy/JhRvqc9ggu11-VxutWzJk9JTDeHXijqLAjvIcsQwMJGBXAL642lreMWW4KjPI-KvA1f9smVix0z0K6ZzDAQNFMEFpCrTjwaTVFcdnaz3QnSWc7-ENiTWNXD2fKY-iOgyl9m9EF99DbnMeXqrjqN-Nhyf"
}

export const FindImages = (ref, Files) => {
    for (let i in Files) {
        const row = Files[i];
        if (row.ref === ref) {
            if (row.type.trim() === "profile") {
                const dropboxId = row.dropboxref;
                const endpoint = "/document/download/" + dropboxId;
                let url = server + "" + endpoint;
                return url;
            }
        }
    }
    return "https://www.pngfind.com/pngs/m/676-6764065_default-profile-picture-transparent-hd-png-download.png"
}
export const extraDataFromCol = (data, cols) => {
    let out = [];
    let fields = {};
    for (let i in cols) {
        const row = cols[i];
        fields[row.id] = true;
    }
    for (let i in data) {
        const row = data[i];
        let o = {};
        for (let key in row) {
            let val = row[key];
            /*let ans = Array.isArray(val);
            if(!ans && (typeof val !== "boolean")){
                val = val.replace("<nil>","");
            }*/

            if (typeof fields[key] !== "undefined") {
                o[key] = val
            }
        }
        out.push(o)
    }
    console.log("extraDataFromCol > ", out);
    return out;
}
const AttachImageProfile = (files, dataIn) => {
    let ls = [];
    for (let i in dataIn) {
        let row = dataIn[i];
        const link = FindImages(row.ref, files)
        row.link = link;
        ls.push(row)
    }
    return ls;
}

export const FindFieldFromRecord = (keyValue, keyReturn, row) => {
    if (typeof row[keyReturn] !== "undefined") {
        return row[keyReturn]
    }
    return keyValue
}
export const FindFieldFromRecordWithData = (keySearch, keyValue, keyReturn, data) => {
    console.log("ZZZZ---> ", keySearch, keyValue, keyReturn, data)
    for (let i in data) {
        const row = data[i];
        if (typeof row[keySearch] !== "undefined") {
            if (row[keySearch] === keyValue) {
                return row[keyReturn]
            }
        }
    }
    return keyValue
}
export const IsNotSuperUser = () => {
    let user = GetUserToken();
    console.log("LOGIN ROLE: ", user.Role);
    if (user.Role !== "super") {
        window.location.href = "#/access-authorize-denied";
        return false
    }
    return true
}
export const UpCaseFirst = (inWord) => {
    const upper = inWord.charAt(0).toUpperCase() + inWord.substring(1);
    return upper;
}
export const GetRowOptions = (key, val, data) => {
    let ls = [];
    for (let i in data) {
        const row = data[i];
        console.log("GetRowOptions > ", key, val, " > ", row)
        ls.push({
            key: row[key],
            val: row[val]
        })
    }
    return ls
}
export const GetRowOptionsCondition = (key, val, data, conditions) => {
    let ls = [];
    for (let i in data) {
        const row = data[i];
        console.log("GetRowOptions > ", key, val, " > ", row);
        let boo = true;
        for (let a in conditions) {
            let record = conditions[a];
            if (row[record.key] !== record.val) {
                boo = false;
            }
        }
        if (!boo) {
            continue
        }
        ls.push({
            key: row[key],
            val: row[val]
        })
    }
    return ls
}
