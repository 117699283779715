import {SetNavAppMenu} from "../redux/action";


export const SetUserNavigationMenus=({_this,data})=>{
    let initialMenus=_this.props.nav.menus;
    let newMenus={};
    const isInMenus=(inVal)=>{
        for(let i in data){
            let row = data[i];
            if(row.link===inVal){
                return true
            }
        }
        return false
    }

    for(let module in initialMenus){
        let moduleData = initialMenus[module];
        if(typeof newMenus[module]==="undefined"){
            newMenus[module] = {};
        }
        for(let menu in moduleData){
            let menuData = moduleData[menu];
            if(typeof newMenus[module][menu]==="undefined"){
                newMenus[module][menu] = [];
                for(let row in menuData){
                    if(isInMenus(row.link)){
                        newMenus[module][menu].push(row)
                    }
                }
            }
        }
    }


    _this.props.SetNavAppMenu(newMenus);
}

export const GetUserNavigationMenus_=(props,data)=>{
    let initialMenus=props.nav.menus;
    let newMenus={};
    const isInMenus=(inVal)=>{
        for(let i in data){
            let row = data[i];
            if(row.link===inVal){
                return true
            }
        }
        return false
    }

    for(let module in initialMenus){
        let moduleData = initialMenus[module];
        if(typeof newMenus[module]==="undefined"){
            newMenus[module] = {};
        }
        for(let menu in moduleData){
            let menuData = moduleData[menu];
            if(typeof newMenus[module][menu]==="undefined"){
                newMenus[module][menu] = [];
                for(let a in menuData){
                    let row = menuData[a];

                    if(isInMenus(row.link)){

                        newMenus[module][menu].push(row)
                    }
                }
            }
        }
    }



    return newMenus
    props.SetNavAppMenu(newMenus);
}