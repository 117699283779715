import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import Title from "../../../../components/Common/Title";
import {Alert, Button, Card, Col, Divider, Input, Row, Table} from "antd";
import Page from "../../../../layout/web/Page";
import {BuilderOptionComboBox, formatDateSouth1, FormatMoneySouthAfrica2} from "../../../../api/fn";
import LoadingProcessing from "../../../../components/Common/page/Common/LoadingProcessing";
import Connector from "../../../../redux/connector";
import {PostToAny, PostToPbx} from "../../../../api/http-post";
import TableDataView from "../../../../components/Common/tables/TableDataView";
import {Flex} from "antd-mobile";

const defaultDataTest = {
    "error": "",
    "payment_date": "2024-07-04",
    "payment_ref": "22027",
    "records": [
        {
            "discount_amount": 0,
            "invoice_amount": 103017.79,
            "invoice_date": "2024-06-30",
            "invoice_number": "227",
            "order_number": "4504205479",
            "payment_amount": 103017.79,
            "payment_date": "2024-07-04",
            "payment_ref": "22027",
            "vendor_code": "1002933719"
        },
        {
            "discount_amount": 0,
            "invoice_amount": 106441.88,
            "invoice_date": "2024-06-28",
            "invoice_number": "225",
            "order_number": "4504345271",
            "payment_amount": 106441.88,
            "payment_date": "2024-07-04",
            "payment_ref": "22027",
            "vendor_code": "1002933719"
        },
        {
            "discount_amount": 0,
            "invoice_amount": 99314.25,
            "invoice_date": "2024-06-28",
            "invoice_number": "223",
            "order_number": "4504330606",
            "payment_amount": 99314.25,
            "payment_date": "2024-07-04",
            "payment_ref": "22027",
            "vendor_code": "1002933719"
        },
        {
            "discount_amount": 0,
            "invoice_amount": 104067.18,
            "invoice_date": "2024-06-28",
            "invoice_number": "221",
            "order_number": "4504319929",
            "payment_amount": 104067.18,
            "payment_date": "2024-07-04",
            "payment_ref": "22027",
            "vendor_code": "1002933719"
        },
        {
            "discount_amount": 0,
            "invoice_amount": 25233.14,
            "invoice_date": "2024-06-27",
            "invoice_number": "218",
            "order_number": "4503973085",
            "payment_amount": 25233.14,
            "payment_date": "2024-07-04",
            "payment_ref": "22027",
            "vendor_code": "1002933719"
        },
        {
            "discount_amount": 0,
            "invoice_amount": 97115.59,
            "invoice_date": "2024-06-27",
            "invoice_number": "217",
            "order_number": "4503957010",
            "payment_amount": 97115.59,
            "payment_date": "2024-07-04",
            "payment_ref": "22027",
            "vendor_code": "1002933719"
        },
        {
            "discount_amount": 0,
            "invoice_amount": 50053.52,
            "invoice_date": "2024-06-27",
            "invoice_number": "216",
            "order_number": "4503973082",
            "payment_amount": 50053.52,
            "payment_date": "2024-07-04",
            "payment_ref": "22027",
            "vendor_code": "1002933719"
        },
        {
            "discount_amount": 0,
            "invoice_amount": 160505.32,
            "invoice_date": "2024-06-27",
            "invoice_number": "215",
            "order_number": "4503957008",
            "payment_amount": 160505.32,
            "payment_date": "2024-07-04",
            "payment_ref": "22027",
            "vendor_code": "1002933719"
        }
    ],
    "total_amount_discount": 0,
    "total_amount_invoice": 745748.67,
    "total_amount_payment": 745748.67,
    "validation_status": true,
    "validation_total_discount": 0,
    "validation_total_invoiced": 745748.67,
    "validation_total_payment": 745748.67,
    "vendor_code": "1002933719"
}

class InvoiceRemittanceCoCTPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            isLoading: false,
            selectedCompany: "",
            base64String: "",
            fileName: "",
            newEntry: {},
            Result: null,
            ResultToSave:null,
        }
    }

    componentDidMount = async () => {
        await this.loadInitialData();
    }
    loadInitialData = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
    }
    handleInput = (val, key) => {
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp)
    }
    saveToTracker =async () => {

        if(!window.confirm("Are you saving payment to tracker?")){
            return
        }
        const toFloat = (str) => {
            // Remove any spaces or non-numeric characters (except decimal point)
            return parseFloat(str.replace(/[^0-9.-]+/g, ""));
        };
        let infos =this.state.ResultToSave;
        let formattedData = [];
        for(let i in infos.records){
            let row = infos.records[i];
            let rec = { ...row };

            // Convert the relevant fields to float
            rec.invoice_amount = toFloat(row.invoice_amount);
            rec.payment_amount = toFloat(row.payment_amount);
            rec.discount_amount = toFloat(row.discount_amount);
            formattedData.push(rec);
        }

        // Update the infos.records with the formatted data
        infos.records = formattedData;
        console.log("Formatted Data: ", JSON.stringify(formattedData, null, 2));
        let post={
            Data:infos
        }
        this.setState({
            isLoading: true,
        })
        let endpoint = "/invoice/remittance/coct/insert";

        console.log("saveToTracker send > ",post)

        await PostToPbx(post, endpoint, async (res) => {
            this.setState({
                isLoading: false,
            })
            if(res===null){
                alert("Error something went wrong, check with the developer")
                return
            }
            alert("thank you, payments to tracker  submitted");
        });
    }
    checkForNewEntry = () => {
        if (this.state.selectedCompany === "") {
            return false
        }
        return this.state.base64String !== "";

    }
    handleInputFile = (e) => {
        let _this = this;
        let fName = `fileID1001`;
        let inputUpload = document.getElementById(fName);
        let myfile = inputUpload.files[0];
        if (!myfile) {
            console.log("No file selected.");
            return;
        }
        let reader = new FileReader();
        reader.readAsDataURL(myfile);
        reader.onload = function () {
            _this.setState({
                base64String: reader.result,
                fileName: myfile.name
            })
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    submitRemittanceRequest = async () => {
        //todo
        this.setState({
            isLoading: true,
            Result:null,
        })
        if (this.state.selectedCompany === "") {
            alert("Please select a company")
            return
        }
        if (this.state.base64String === "") {
            alert("Please upload file")
            return
        }

        let postData = {
            Org: this.state.selectedCompany,
            Base64String: this.state.base64String,
            FileName: this.state.fileName,
        }

        console.log("submitRemittanceRequest send > ", postData)
        let endpoint = "https://cloudcalls.easipath.com/backend-biatechocr/api/ocr/reader/pdf/coct"
        await PostToAny(endpoint, postData, (res) => {
            console.log("submitRemittanceRequest response > ", res)
            this.setState({
                isLoading: false,
            })
            if (res !== null) {
                let myResult = res.RESULT.remittance;
                this.setState({
                    Result: myResult,
                    ResultToSave:myResult,
                })
            }
            console.log(res)
        })

    }
    renderTotalLines = () => {
        let info = this.state.Result;
        const columns = [
            {
                title: 'Total',
                dataIndex: 'field',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                align:"right"
            },
        ]
        let formattedData=[
            {field:"Invoice Amount",amount: FormatMoneySouthAfrica2(info.total_amount_invoice )},
            {field:"Discount Amount",amount: FormatMoneySouthAfrica2(info.total_amount_discount) },
            {field:"Payment Amount",amount: FormatMoneySouthAfrica2(info.total_amount_payment)},
            {field:"Payment Date",amount: formatDateSouth1(info.payment_date)},
            {field:"Payment Reference",amount:info.payment_ref},
            {field:"Vendor Code",amount:info.vendor_code},
        ]

        return(
            <div>
                {info.validation_status?
                    <Alert message="Remittance successfully matched to invoices" type="success" />:
                    <Alert message="Remittance does not match invoices" type="error" />}
            <Table
                columns={columns}
                dataSource={formattedData}
            />

                {info.validation_status&&<Flex vertical gap="small" style={{ width: '100%' }}>
                    <Button onClick={this.saveToTracker} type="primary" style={{borderRadius:5,fontSize:18}} block>
                        Save remittance to CMIS
                    </Button>
                </Flex>}
            </div>
        )

    }
    renderList = () => {
        if (this.state.Result === null) {
            return null
        }
        const colBuilder = (field, display, align) => {
            return {
                label: display,
                id: field,
                cellStyle: {textAlign: align},
                headerStyle: {textAlign: align},
            }
        }
        let columns = [
            colBuilder("order_number", "Order #", "left"),
            colBuilder("invoice_number", "Invoice #", "left"),
            colBuilder("invoice_date", "Invoice Date", "left"),
            colBuilder("invoice_amount", "Invoice Amount", "right"),
            colBuilder("discount_amount", "Discount Amount", "right"),
            colBuilder("payment_amount", "Payment Amount", "right"),
        ]
        const infos = this.state.Result.records;
        let formattedData = [];
        for (let i = 0; i < infos.length; i++) {
            let info = infos[i];
            info.invoice_amount = FormatMoneySouthAfrica2(info.invoice_amount)
            info.discount_amount = FormatMoneySouthAfrica2(info.discount_amount)
            info.payment_amount = FormatMoneySouthAfrica2(info.payment_amount)
            info.invoice_date = formatDateSouth1(info.invoice_date)
            formattedData.push(info)
        }
        let dataTable = {columns: columns, data: formattedData}
        console.log("^^^dataTable > ", dataTable)


        return (
            <div>
                <Card title="Upload Verification" style={{marginBottom: 20}}>
                    {this.renderTotalLines()}
                </Card>
                <TableDataView
                    {...dataTable}
                    tableTitle={" Records  (" + formattedData.length + ") "}
                    otherFunctions={[]}
                    allowedAdd={false}
                    options={{
                        pageSize: 10,
                    }}
                />

            </div>
        )
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Upload CoCT Remittance</Title>
                    <Divider style={{color: "red"}}>Filter</Divider>
                    <BuilderOptionComboBox
                        data={this.state.Companies}
                        keyVal={"CustomerNumber"}
                        keyLabel={"Name"}
                        label={"Select Organization"}
                        conditions={[]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedCompany"}
                        currentValue={this.state.selectedCompany}
                        comboId={"company"}
                    />
                    <Row style={{marginTop: 20}}>
                        <Col span={24}>
                            <Input type={"file"} accept=".pdf" onChange={this.handleInputFile} id={"fileID1001"}/>
                        </Col>
                    </Row>
                    <Divider orientation="right" style={{color: "#3880ff"}}>
                        {this.checkForNewEntry() ?
                            <a onClick={this.submitRemittanceRequest}>Submit Remittance</a>
                            : <h3 style={{color: "red"}}>Please select the organisation and file to upload.</h3>
                        }
                    </Divider>
                    <Row>
                        <Col span={24}>
                            {this.renderList()}
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col span={24}>
                        <LoadingProcessing open={this.state.isLoading}/>
                    </Col>
                </Row>
            </Page>
        )
    }

}

export default Connector(InvoiceRemittanceCoCTPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
