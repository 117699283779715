import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {Layout, Select, Input, Form, Alert, Button, Table, Modal} from "antd";
import {GetUserToken} from "../../../api/token";
import PluginAddDeleteList from "../../../components/Common/PluginAddDeleteList2";
import {PostToPbx} from "../../../api/http-post";
import {fetchDataEntity} from "../../../api/entity-data";
import GenericComboBox from "../../../components/web/Common/GenericComboBox";
import {PlusCircleFilled} from "@ant-design/icons";
import {avoidKeys,renderP} from "../../../api/fn";

const {TextArea} = Input;
const {Content} = Layout;
const {Option} = Select;

class DatabaseQaOnboardingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Modules: [],
            isLoading: false,
            company:"",
            component: "list",
            selectedCompany:"",
            selectedEntity:"",
            targetRecord:null,
            showDialog:false,
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.loadData("modules", "Modules",{})
        await this.loadData("OnboardQa", "Data",{})
        //await this.loadInitialData()
    }
    handleChangeCompany = async (value, key) => {
        this.setState({
            [key]: value,
        });
        let company = this.state.selectedCompany;
        let module = this.state.selectedEntity;
        if(company!=="" && module!==""){
            let params=[
                {key:"Org",val:company,"type":"string"},
                {key:"Module",val:module,"type":"string"},
            ]
            //await this.loadData("OnboardQa", "Data",params)
        }
    }
    loadInitialData = async () => {
        await this.loadData("OnboardQa", "Data",{});
    }
    loadData = async (targetEntity, stateKey,params) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = params;
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            console.log("SettingProductPage loadInitialData response > ", post, " > ", data);
            await _this.setState({
                [stateKey]: data.RESULT
            })
        });
    }
    requestRejectRecord=(record)=>{
        //todo
        this.cancelActionPerform()

    }
    cancelActionPerform = () => {
        this.setState({
            showDialog: false,
            targetRecord: null,
        })
    }
    removeRecordFromQa=async (record)=>{
        if(record===null){
            return
        }
        if(typeof record==="undefined"){
            return
        }
        let table = "OnboardQa";
        let self=this;
        let hub = {...record};

        let params=[
            {key:"org",val:record.org,type:"string"},
            {key:"module",val:record.module,type:"string"},
            {key:"name",val:record.name,type:"string"},
            {key:"id",val:record.id,type:"string"},
        ]

        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = hub;
        post.Params = params
        post.Entity  = table;
        let endpoint = "/common/entity/" + table + "/delete"; // "/manager/entity/insert/extensions";
        // let endpoint =this.props.postEndpoint; // "/manager/entity/insert/extensions";
        this.setState({
            isLoading: true,
        })
        await PostToPbx(post, endpoint, async (data) => {
            await self.loadData();
            self.cancelActionPerform()
        });
    }
    requestApproveRecord=async (record)=>{
        let self=this;
        //todo
        if(!window.confirm("Are you to approve this record: "+record.name+"?")){
            return
        }
        let hub=record;
        let endpoint = "/mobile/onboard/employee/supervisor"; // "/manager/entity/insert/extensions";
        // let endpoint =this.props.postEndpoint; // "/manager/entity/insert/extensions";
        this.setState({
            isLoading: true,
        })
        await PostToPbx(hub, endpoint, async (data) => {
            alert("Record submitted!")
            await self.removeRecordFromQa(record);
            await self.loadInitialData();
        });
    }
    renderProjectSelection = () => {
        let data = [];
        let company = this.state.selectedCompany;
        for(let i in this.state.Modules){
            let row=this.state.Modules[i];
            console.log("&&&&--isMo> ",company," > ",row.org)
            if(company ===row.org){
                data.push(row)
            }
        }
        return (
            <GenericComboBox
                displayConditionStateKey={this.state.component}
                displayConditionStateValue={"form"}
                data={data}
                label={"Select Project"}
                name={"selectedEntity"}
                message={"Please select your project!"}
                handleChange={this.handleChangeCompany}
                getValue={this.state.selectedEntity}
                recordOptionKey={"name"}
                recordOptionValue={"name"}
                recordOptionDisplay={"name"}
            />
        )
    }
    renderCompaniesSelection = () => {

        return(
            <GenericComboBox
                displayConditionStateKey={this.state.component}
                displayConditionStateValue={"form"}
                data={this.state.Companies}
                label={"Select Company"}
                name={"selectedCompany"}
                message={ "Please select your company!"}
                handleChange={this.handleChangeCompany}
                getValue={this.state.selectedCompany}
                recordOptionKey={"CustomerNumber"}
                recordOptionValue={"CustomerNumber"}
                recordOptionDisplay={"Name"}
            />
        )
    }
    renderWarningBar = () => {
        return
        let boo = false
        if (this.state.selectedCompany === "" || this.state.selectedEntity === "" || this.state.component === "form") {
            boo = true
        }
        if (!boo) {
            return
        }
        return (
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Alert style={{margin: '16px 0'}} message="Please select your company above to view your records!"
                       type={"error"}/>
            </div>
        )
    }

    requestAction = ( text) => {
        let data = this.getTargetData();
        let record = null;
        for(let i in data){
            let row = data[i];
            if(row.name ===text){
                record = row;
            }
        }
        console.log("requestAction > ",record);
        if(record!==null){
            this.setState({
                targetRecord:record,
                showDialog:true,
            })
        }
    }
    getTargetData=()=>{
        let company = this.state.selectedCompany;
        let module = this.state.selectedEntity;
        let ls=[];
        for(let i in this.state.Data) {
            let row = this.state.Data[i];
            if(company===row.org&& module===row.module){
                ls.push(row);
            }
        }
        return ls;
    }
    renderModal=()=>{
        let state = this.state;
        let visible = state.showDialog;
        let lsData = [];
        let record = state.targetRecord;
        if(record===null){
            return;
        }
        for (let i in record.data) {
            if (!avoidKeys(i)) {
                continue
            }
            const val = record.data[i];
            lsData.push({
                key: i,
                val: val,
            })
        }
        const  titleForm ="Action Perform : " +record.name.toLocaleUpperCase();
        return (
            <Modal
                title={titleForm}
                visible={visible}
                onCancel={this.cancelActionPerform}
            >
                {lsData.map(rec => {
                    return renderP(rec.key, rec.val)
                })}
                <React.Fragment>
                    <br/>
                    <Button
                        danger={true}
                        onClick={()=>this.requestRejectRecord(record)}
                    >Reject</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                        type={"dashed"}
                        onClick={()=>this.requestApproveRecord(record)}
                    >Approve</Button>&nbsp;&nbsp;

                </React.Fragment>
            </Modal>
        )
    }

    renderList=()=>{
        let company = this.state.selectedCompany;
        let module = this.state.selectedEntity;
        let columns=[];

        columns.push({
            title: "Name",
            dataIndex: "name",
            render: text => <a onClick={() => this.requestAction( text)}>{text}</a>,
        });
        columns.push({
            title: "Submitted Data",
            dataIndex: "orgdatetime",
            align: 'right',
        })

        let ls=this.getTargetData();

        return (
            <div style={{minWidth: "100%", padding: 5,}}>
                <Table
                    columns={columns}
                    dataSource={ls}
                    bordered
                />
            </div>

        )

    }

    render() {

        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Qa Onboard Employee</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                    <React.Fragment>
                        <div style={{minWidth: "100%", padding: 10}}>
                            {this.renderCompaniesSelection()}
                            {this.renderProjectSelection()}
                        </div>
                        {this.renderWarningBar()}
                        {this.renderList()}
                        {this.renderModal()}
                    </React.Fragment>
                    </div>
                </div>
            </Page>
        )
    }

}


export default Connector(DatabaseQaOnboardingPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

