import React, {useRef} from "react"
import "./PayslipDemo.css";
import ReactToPrint from 'react-to-print';

import jsPDF from 'jspdf';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';

const MyStyle={
    table:{
        borderCollapse:"collapse",
        collapse:true,
        minWidth:"100%",
        maxWidth:"100%"
    }
    /*
    table, tr, th {
    border-collapse: collapse;
    collapse: true;
}

table {
    min-width: 100%;
    max-width: 100%;
}
     */
}
export default class PdfComponent extends React.PureComponent {

    printDocument() {
        const input = document.getElementById('divToPrint');

        const doc = new jsPDF('p', 'pt', 'a4');
        doc.html(document.querySelector('#divToPrint'), {
            callback: function (doc) {
                doc.save('MLB World Series Winners.pdf');
            },
        });


        return

        //get table html
        const pdfTable = document.getElementById('divToPrint');
        //html to pdf format
        let html = htmlToPdfmake(pdfTable.innerHTML);

        const documentDefinition = { content: html,style:MyStyle };
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.createPdf(documentDefinition).open();

    }
    render() {

        return (
            <div id="divToPrint">
                <ComponentToPrint/>
                <button class="btn btn-primary" onClick={this.printDocument}>Export To PDF</button>
            </div>
        )

        return (
            <PrinterWrapper>
                <ComponentToPrint/>
            </PrinterWrapper>
        )
    }
}

 function PrinterWrapper({ children }) {
    const linkToPrint = () => {
        return (
            <button>Click To PrintOF Body</button>
        )
    }
    const componentRef = useRef();
    return (
        <>
            <ReactToPrint
                trigger={linkToPrint}
                content={() => componentRef.current}
                copyStyles={true}
                bodyClass={`table tr th td thAlignCenter td.thNoBorder th.thNoBorder tr.trBorder container secondTable spaceMe putLeft perFive pushCenter pullLeft pullRight addressContainer descriptionItem pushRight descriptionItemDetail amountItemDetail noBorderColumn borderColumn grossEarningTh thNoBorder trBorder equalColumn removeLeftBorder increaseBy50 trBorderBottom`}
            />
            <div ref={componentRef}>
                {children}
            </div>
        </>
    );
}

const ComponentToPrint  = React.forwardRef((props, ref) => {
    return (
        <div className="container">
            <table border="1">
                <tr className="trBorder">
                    <th colSpan="3" className="thNoBorder">
                        COMPANY
                    </th>
                    <th className="" width="150">
                        PERIOD
                    </th>
                    <th className="150">
                        DATE
                    </th>
                </tr>
                <tr className="trBorder">
                    <td colSpan="3" className="thAlignCenter thNoBorder" cellspacing="0" cellpadding="0">
                        Sivuyile Maintenance Services
                    </td>
                    <td className="thAlignCenter" cellspacing="0" cellpadding="0">
                        7
                    </td>
                    <td className="thAlignCenter" cellspacing="0" cellpadding="0" width="200">
                        30/09/2020
                    </td>
                </tr>
                <tr className="trBorder">
                    <th className="" width="200">
                        EMPLOYEE CODE
                    </th>
                    <th className="" colSpan="4">
                        EMPLOYEE NAME
                    </th>
                </tr>
                <tr className="trBorder">
                    <td width="200" className="thAlignCenter">
                        HL00001
                    </td>
                    <td className="thAlignCenter" colSpan="4">
                        Ms E logger
                    </td>
                </tr>
            </table>

            <table border="1" className="secondTable">
                <tr>
                    <th width="175" className="putLeft">EMPLOYEE CODE</th>
                    <td width="100" className="spaceMe">HL1000</td>
                    <th width="175">EMPLOYEE NAME</th>
                    <td className="spaceMe" colSpan="2">Ms E Logger</td>
                </tr>
                <tr>
                    <th width="175" className="putLeft">DESIGNATION</th>
                    <td colSpan="4" className="spaceMe">General worker</td>
                </tr>
                <tr>
                    <th width="175" className="putLeft">COMPANY NAME</th>
                    <td className="spaceMe perFive" colSpan="2">Mushengu Toilet Hire CC</td>
                    <th width="130">PERIOD</th>
                    <td className="pushCenter">10</td>
                </tr>
                <tr>
                    <td colSpan="3" rowSpan="2">
                        <p className="addressContainer">
                            <label className="pullLeft">Private Bag 38014</label>
                            <label className="pullRight">&nbsp;</label>
                        </p>
                        <p className="addressContainer">
                            <label className="pullLeft">Galtesvile</label>
                            <label className="pullRight">Athlone</label>
                        </p>
                        <p className="addressContainer">
                            <label className="pullLeft">Cape town</label>
                            <label className="pullRight">7764</label>
                        </p>
                    </td>
                    <th width="130">DATE</th>
                    <td className="pushCenter">31/12/2021</td>
                </tr>
                <tr>
                    <th width="130">RATE</th>
                    <td className="pushCenter">23.87</td>
                </tr>
                <tr>
                    <th colSpan="5">INCOME</th>
                </tr>
                <tr>
                    <td colSpan="5">
                        <table>
                            <tr className="trBorderBottom">
                                <th className="descriptionItem">DESCRIPTION</th>
                                <th className="pushCenter">QUANTITY</th>
                                <th className="pushCenter">RATE</th>
                                <th width="150" className="pushRight amountItemDetail">AMOUNT</th>
                            </tr>
                            <tr>
                                <td className="descriptionItemDetail">Normal Time</td>
                                <td className="pushCenter">184</td>
                                <td className="pushCenter">23.87</td>
                                <td className="pushRight amountItemDetail">4392.08</td>
                            </tr>
                            <tr>
                                <td className="descriptionItemDetail">Overtime</td>
                                <td className="pushCenter">8</td>
                                <td className="pushCenter">35.91</td>
                                <td className="pushRight amountItemDetail">285.44</td>
                            </tr>
                            <tr>
                                <td className="descriptionItemDetail">Public Holiday - Saturday</td>
                                <td className="pushCenter">184</td>
                                <td className="pushCenter">23.87</td>
                                <td className="pushRight amountItemDetail">439.08</td>
                            </tr>
                            <tr>
                                <td className="descriptionItemDetail">Public Holiday - Worked</td>
                                <td className="pushCenter">184</td>
                                <td className="pushCenter">23.87</td>
                                <td className="pushRight amountItemDetail">439.08</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td className="pushCenter">&nbsp;</td>
                                <td className="pushCenter">&nbsp;</td>
                                <td className="pushRight">&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td className="pushCenter">&nbsp;</td>
                                <td className="pushCenter">&nbsp;</td>
                                <td className="pushRight">&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td className="pushCenter">&nbsp;</td>
                                <td className="pushCenter">&nbsp;</td>
                                <td className="pushRight">&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td className="pushCenter">&nbsp;</td>
                                <td className="pushCenter">&nbsp;</td>
                                <td className="pushRight">&nbsp;</td>
                            </tr>

                        </table>
                    </td>
                </tr>
                <tr>
                    <td className="noBorderColumn">&nbsp;</td>
                    <th colSpan="2" className="pullRight noBorderColumn">GROSS EARNINGS</th>
                    <td colSpan="2" className="pushRight noBorderColumn">5824.23</td>
                </tr>
                <tr>
                    <th colSpan="5">
                        <table border="0">
                            <tr className="">
                                <th className="borderColumn removeLeftBorder equalColumn">BENEFITS</th>
                                <th className="borderColumn equalColumn">COMPANY CONTRIBUTIONS</th>
                            </tr>
                            <tr className="">
                                <th className="borderColumn removeLeftBorder equalColumn increaseBy50">&nbsp;</th>
                                <th className="borderColumn equalColumn increaseBy50">&nbsp;</th>
                            </tr>
                        </table>
                    </th>
                </tr>
                <tr>
                    <th colSpan="5">DEDUCTIONS</th>
                </tr>
                <tr>
                    <td colSpan="5">
                        <table>
                            <tr className="trBorderBottom">
                                <th className="descriptionItem">DESCRIPTION</th>
                                <th className="pushCenter">BALANCE</th>
                                <th width="150" className="pushRight amountItemDetail">AMOUNT</th>
                            </tr>
                            <tr>
                                <td className="descriptionItemDetail increaseBy50" valign="top">UIF Contribution</td>
                                <td className="pushCenter" valign="top">184</td>
                                <td className="pushRight amountItemDetail" valign="top">4392.08</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td className="noBorderColumn">&nbsp;</td>
                    <th colSpan="2" className="pullRight noBorderColumn">TOTAL DEDUCTIONS</th>
                    <td colSpan="2" className="pushRight noBorderColumn">5824.23</td>
                </tr>
                <tr>
                    <td colSpan="5">
                        <table border="0">
                            <tr className="trBorderBottom">
                                <th className="borderColumn">LEAVE DAYS DUE</th>
                                <td className="borderColumn">6.25</td>
                                <th className="borderColumn">NET PAY</th>
                                <td className="borderColumn">5766.04</td>
                            </tr>
                            <tr className="trBorderBottom">
                                <td className="borderColumn pushCenter">3699 Gross Remuneration</td>
                                <td className="borderColumn pushCenter">4103 Total Employee's Tax</td>
                                <td className="borderColumn pushCenter">40005 Medical Aid Contributions</td>
                                <td className="borderColumn pushCenter">4001 Pension Fund - Current</td>
                            </tr>
                            <tr>
                                <td className="borderColumn pushCenter">27 498.24</td>
                                <td className="borderColumn pushCenter">0.00</td>
                                <td className="borderColumn pushCenter">0.00</td>
                                <td className="borderColumn pushCenter">0.00</td>
                            </tr>
                        </table>
                    </td>
                </tr>

            </table>
        </div>
    )
});