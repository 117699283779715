import React from "react";
import ReactFileReader from "react-file-reader";
import Alert from "@material-ui/lab/Alert";

export default (props)=>{
    let {_this}=props;
    let state=_this.state;
    if(state.selectedCompany === "" || state.selectedEntity === "" ){
        return
    }

    return(
        <div style={{minWidth: "100%", padding: 10}}>
            <ReactFileReader handleFiles={_this.handleFiles} fileTypes={'.csv'}>
                <button className='btn'>Upload</button>
            </ReactFileReader>
            {state.CsvPureData.length>=1?
                <React.Fragment>
                <Alert
                    type={"info"}
                    message={"Number of record in csv file: "+state.CsvPureData.length}
                    style={{padding:10}}
                />
                <h3>
                    {"Number of record in csv file: "+state.CsvPureData.length}
                </h3>
                </React.Fragment>
                :null}
        </div>
    )

}