import {Alert, Button, Col, Divider, Row} from "antd";
import OrderedListOutlined from "@ant-design/icons/lib/icons/OrderedListOutlined";
import ClockCircleFilled from "@ant-design/icons/lib/icons/ClockCircleFilled";
import React, {useState} from "react";
import WeeklyView from "./WeeklyView";
import {PostToPbx} from "../../api/http-post";
import Configures from "../../configures";
import PrintPdfTimesheet from "./PrintPdfTimesheet";
import PopupDetail2 from "../../pages/web/Report/PopupDetail2";
import PopupExportPastel from "../../pages/web/Report/PopupExportPastel";
import PopupExportPastel2 from "../../pages/web/Report/PopupExportPastel2";
import {CSVLink, CSVDownload} from 'react-csv';
import PopupExportPayAccZSys from "../../pages/web/Report/PopupExportPaymentGateway";
import moment from "moment";
import PopupExportBank2 from "../../pages/web/Report/PopupExportBank2";
import PopupQuickSummary from "../../pages/web/Report/PopupQuickSummary";
import {GetConfigures} from "../../configures/serve";
import {FormatMoneySouthAfrica} from "../../api/fn";


export default (props) => {
    let {dataStore, payDate, _this,submitSearch,submitGeneratePaymentSummary,submitGeneratePaymentSummaryCsv,submitGenerateLabourReport,submitGenerateLabourReportCalendar,
        submitGenerateTimesheetCalendar,FnbCsvInfo,
        submitGenerateLabourReport2Actual,submitGenerateLabourReport2Calendar} = props;
    const [keySearch, setKeySearch] = useState("");
    const [selectedEmp, setSelectedEmp] = useState("")
    const [popupWeekly, setPopupWeekly] = useState(false);
    const [printTimesheet, setPrintTimesheet] = useState(false);
    const [clocks, setClocks] = useState([]);
    const [showClocks, setShowClocks] = useState(false);
    const [viewClocksType, setViewClocksType] = useState("");
    const [viewClocksRec, setViewClocksRec] = useState(null);
    const [showViewPastel,setShowViewPastel]=useState(false);
    const [showPayGateWay,setShowPayGateWay]=useState(false);
    const [ShowBankExport,setShowBankExport]=useState(false);
    const [ShowQuickSummary,setShowQuickSummary]=useState(false);
    const [ShowTimesheetCalendar,setShowTimesheetCalendar]=useState(false);

    let {state} = _this;
    let payPeriod = state.selectedPeriod;
    let org = state.selectedCompany;
    let project = state.selectedModule;
    let companyName = org
    for(let i in state.Companies){
        let row=state.Companies[i];
        if(row.CustomerNumber===org){
            companyName= row.Name;
        }
    }


    const removeClock = async (clockRecord) => {
        const myJson = JSON.stringify(clockRecord)
        //alert(`Are you sure to delete this :  ${myJson}`);

        if(!window.confirm("Are you sure to delete this record!")){
            return
        }
        let item={
            Org:org,
            Project:project,
            ClockDate:clockRecord.Date,
            Site:viewClocksRec.EmpSite,
            WorkerRef:viewClocksRec.EmpNumber,
        }
        let hub = {...item}
        let endpoint = "/clock/admin/delete"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            alert("Clock deleted!")
            closeViewClocks();
            await submitSearch();
            //await this.submitSearch()
        });
    }
    const closeViewClocks = () => {
        setViewClocksType("");
        setViewClocksRec(null);
        setClocks([]);
        setShowClocks(false);
    }

    const findRateCost=(empCode,day,hours,IsPh)=>{

        let recordInfo = dataStore.Summary[empCode];

        let rate = 0;
        let cost = 0;
        if (day === "Saturday") {
            rate  = recordInfo.RateSat
        }else if(day==="Sunday"){
            rate  = recordInfo.RateSun
        }else{
            rate  = recordInfo.RateNor
        }

        if(typeof IsPh!=="undefined" && IsPh){
            rate  = recordInfo.RatePh
        }

        cost  = rate * hours

        console.log("findRateCost > ",empCode,day,hours,IsPh," > ",rate," | ",cost," | ",recordInfo)

        /*

         */
        return {rate,cost}
    }
    const requestViewClocks = (empCode, viewType, RecEmp) => {
        const isNormalDay = (dayIn) => {
            console.log("isNormalDay > ",dayIn," > ",)
            let arr = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
            for (let i in arr) {
                const item = arr[i];
                if (item === dayIn) {
                    return true
                }
            }
            return false
        }
        let recs = [];
        setViewClocksType(viewType);
        setViewClocksRec(RecEmp)
        for (let i in dataStore.Clocks) {
            let row = dataStore.Clocks[i];
            if (row.EmpCode !== empCode) {
                continue
            }
            let {rate,cost}  = findRateCost(empCode,row.Day,row.Hours,row.IsPh);
            row.Rate  = rate
            console.log("OOOOOP---> ",viewType,">",row)
            /**
             * Normal clock (Monday to friday)
             */
            if (viewType === "Norm") {

                    if (isNormalDay(row.Day)) {
                        recs.push(row)
                    }

            }
            if (viewType === "Saturday") {
                    if (row.Day === "Saturday") {
                        recs.push(row)
                    }
            }

            if (viewType === "Sunday") {

                    if (row.Day === "Sunday") {
                        recs.push(row)
                    }

            }
            if (viewType === "Ph") {
                if (row.IsPh) {
                    recs.push(row)
                }
            }

            if (viewType === "all") {
                recs.push(row)
            }

            setClocks(recs);
            setShowClocks(true);

        }
    }

    const getDataSource = () => {
        let recs = [];
        for (let i in dataStore.Summary) {
            let row = dataStore.Summary[i];
            let data = JSON.stringify(row).toLocaleLowerCase();
            let key = keySearch.toLocaleLowerCase();
            if (keySearch === "") {
                recs.push(row)
            } else {
                if (data.indexOf(key) > -1) {
                    recs.push(row)
                }
            }
        }
        return recs
    }
    const requestWeekView = (empNo) => {
        setPopupWeekly(true);
        setSelectedEmp(empNo);
    }
    const closeWeekView = () => {
        setPopupWeekly(false);
        setSelectedEmp("");
        setKeySearch("")
    }

    const getSelectedDataSummary = () => {
        return dataStore.Summary[selectedEmp];
    }
    const getSelectedDataWeeks = () => {
        return dataStore.Weeks[selectedEmp];
    }

    const requestGeneratePaySlipBulk = async () => {

        if (!window.confirm("Are you to generate payslip for all employee " )) {
            return
        }
        const records =  dataStore.Summary ;
        let employees=[];
        for (let i in records) {
            let row = records[i];
            employees.push(i)
        }

        let hub = {
            Org: org,
            Period: payPeriod,
            Project: project,
            PayDate: payDate,
            Employees:employees,
            EmailTo:"peterd@marginmentor.co.za"
        }

        console.log("requestGeneratePaySlipBulk > ",  hub);

        _this.setState({
            currentPayslip: null,
            isLoading: true,
        });

        let endpoint = "/payslip/bulk/generate"
        await PostToPbx(hub, endpoint, async (output) => {
            if(output===null){
                alert("Error generating your bulk payslip please try again later!")
                return
            }
            let data = output.RESULT;
            _this.setState({
                currentPayslip: data,
                isLoading: false,
            });
            alert("Thank you for your payslip request is ready and rendered on the new tab after click ok button, please check it.\n" )
            // DropBoxRev
            const server =GetConfigures();
            const dropboxId = data.DropBoxRev;
            const endpoint = "/files/download/" + dropboxId;
            let url = server + "" + endpoint;

            window.document.title = "Payslip - " + org + " - " + payPeriod;
            window.open(url, '_blank');

            console.log("out-Data--> ", data)
        });
    }

    const requestGeneratePaySlip = async (rowIn) => {

        if (!window.confirm("Are you to generate payslip for employee: " + rowIn.EmpName)) {
            return
        }
        let employees=[];
        employees.push(rowIn.EmpNumber)
        let hub = {
            Org: org,
            Period: payPeriod,
            Project: project,
            PayDate: payDate,
            Employees:employees,
            EmployeeNumber:rowIn.EmpNumber,
            EmailTo:"peterd@marginmentor.co.za"
        }

        console.log("requestGeneratePaySlip > ", rowIn, " > ", hub);

        _this.setState({
            currentPayslip: null,
            isLoading: true,
        });
        console.log("1 -Data--> ", hub)
        console.log("generatePaySlip > ", rowIn);
        let endpoint = "/payslip/bulk/generate"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            _this.setState({
                currentPayslip: data,
                isLoading: false,
            });
            // DropBoxRev
            const server =GetConfigures();
            const dropboxId = data.DropBoxRev;
            const endpoint = "/files/download/" + dropboxId;
            let url = server + "" + endpoint;

            window.document.title = "Payslip - " + rowIn.EmpNumber + " - " + rowIn.Name;
            window.open(url, '_blank');

            console.log("out-Data--> ", data)
        });
    }
    const buildBankExportCsv = () => {
        let infoBankOwnerPrefix="PMIS"
        let infoBankBenPrefix="PMIS"
        let infoBankHeader = "I-2022"
        let infoBankAccount = ""
        if(FnbCsvInfo!==null){
            let row         = FnbCsvInfo;
            infoBankOwnerPrefix = row.prefixrefkeyowner;
            infoBankBenPrefix = row.prefixrefkeybeneficiary;
            infoBankHeader = row.header;
            infoBankAccount = row.accountnumber;
        }

        let BankOwnRefPrefix=infoBankOwnerPrefix;
        let BankTheirRefPrefix=infoBankBenPrefix;
        let BankHolderAccountType =1
        let ls = [];
        let lsCsv = [];
        let shortPayDate = moment(payDate,"YYYY-MM-DD").format("MMM-YYYY")
        if (typeof dataStore.Summary === "undefined") {
            return ls;
        }
        for (let i in dataStore.Summary ) {
            let row = dataStore.Summary [i];

            let bank = row.BankName;
            let acc = row.BankAccount;
            let branch = row.BranchCode;
            let extRef = row.IdNumber;
            let arrNames = row.EmpName.split(" ");
            let name = arrNames[0];
            let surname = arrNames[1];

            let ourRef = `${BankOwnRefPrefix} ${name} ${surname} ${shortPayDate}`;
            let theirRef = BankTheirRefPrefix + " " + shortPayDate;

            lsCsv.push({
                "RECIPIENT NAME":name+' '+surname,
                "RECIPIENT ACCOUNT":acc,
                "RECIPIENT ACCOUNT TYPE":BankHolderAccountType,
                "BRANCHCODE":branch,
                "AMOUNT":row.NetPay.toFixed(2),
                "OWN REFERENCE":ourRef,
                "RECIPIENT REFERENCE":theirRef
            })

        }
        return lsCsv;
    }
    const displayDeduction=(row)=>{
        console.log("displayDeduction > ",row)
        let pensionFund = 0;
        if(typeof row.PensionFund !=="undefined"){
            pensionFund = row.PensionFund
        }
        let msg = `Deductions\nUIF: R${row.UIF}\nPaye: R ${row.Paye}\nPension Fund: ${pensionFund}\nSalary Advance: ${row.Deduction}`
        alert(msg)
    }
    const toFix2 = (valIn) => {
        return valIn.toFixed(2)
    }
    const buildExportCsvPastel = () => {
        let lsNormal = [];
        let lsNormalCsv = [];
        let lsSaturday = [];
        let lsSaturdayCsv = [];
        let lsSunday = [];
        let lsSundayCsv = [];
        let lsPhh = [];
        let lsPhhCsv = [];
        let lsBonus=[];
        let lsBonusCsv=[];
        let lsOT=[];
        let lsOtCsv=[];

        const records = getDataSource();
        if (typeof dataStore.Summary === "undefined") {
            return {
                lsNormal,
                lsNormalCsv,
                lsSaturday,
                lsSaturdayCsv,
                lsSunday,
                lsSundayCsv,
                lsPhh,
                lsPhhCsv,
                lsBonus,
                lsBonusCsv,
                lsOT,
                lsOtCsv,
            }
        }



        const builder = (empCode, fullname, transCode, numDays, rate, override, storeData, storeCsv) => {
            if(numDays===0){return}
            storeData.push({
                EmployeeCode: empCode,
                Employee: fullname,
                TransactionCode: transCode,
                JobCode: '',
                CostCentre: "",
                Quantity: numDays,
                Rate: rate.toFixed(2),
                Amount: "",
                Override: override
            });
            storeCsv.push({
                Code: empCode,
                Tans: transCode,
                Blanc1: '',
                Blanc2: '',
                Days: numDays,
                Rate: rate.toFixed(2),
                Blanc3: '',
                Override: override
            })
        }

        const findBonusTotal=(ref)=>{
            let dataRecordWeeks = dataStore.Weeks[ref];
            let totalBonus=0;
            let totalOT= 0;
            for (let i in dataRecordWeeks) {
                let row = dataRecordWeeks[i];
                totalBonus +=row.TotHourBonus;
                totalOT +=row.TotHourOT
            }
            return {totalBonus,totalOT};
        }

        if (typeof dataStore.Summary !== "undefined") {
            for (let i in records) {
                let row = records[i];
                let totN =  row.TotNor.Hours;
                let ref = row.EmpNumber;
                let name =  row.EmpName;
                if(row.RateNor===0){
                    continue
                }
                console.log("BUILDER :> ", row)
                let {totalBonus,totalOT} = findBonusTotal(ref);
                let rateOt =  row.RateNor * 1.5;
                //console.log("OOOOOO Bonus > ",row)
                builder(ref, name, "NORM", totN, row.RateNor, "N", lsNormal, lsNormalCsv)
                builder(ref, name, "SATU", row.TotSat.Hours, row.RateSat, "N", lsSaturday, lsSaturdayCsv)
                builder(ref, name, "SUND",row.TotSun.Hours, row.RateSun, "N", lsSunday, lsSundayCsv)
                builder(ref, name, "PUBW", row.TotPh.Hours, row.RateNor, "N", lsPhh, lsPhhCsv)
                builder(ref, name, "PUBS", row.TotBonus.Hours, row.RateNor, "N", lsBonus, lsBonusCsv)
                builder(ref, name, "OVTM", row.TotOT.Hours, rateOt, "N", lsOT, lsOtCsv)
                console.log("row.TotBonus.Hours > ",row.TotBonus.Hours)
                if(ref==="HL0001"){
                    console.log("row.TotBonus.Hours > ",row)
                }
            }

        }

        return {
            lsNormal,
            lsNormalCsv,
            lsSaturday,
            lsSaturdayCsv,
            lsSunday,
            lsSundayCsv,
            lsPhh,
            lsPhhCsv,
            lsBonus,
            lsBonusCsv,
            lsOT,
            lsOtCsv
        }
    }
    const renderReportPopupExportCsvPastel = () => {
        if (!showViewPastel) {
            return
        }
        let informations = buildExportCsvPastel();
        return (
            <PopupExportPastel2 showViewPastel={showViewPastel} setShowViewPastel={setShowViewPastel} {...informations}/>
        )
    }

    const renderReportPopupExportCsvPaymentGateway = () => {
        if (!showPayGateWay) {
            return
        }
        if (typeof dataStore.Summary === "undefined") {
            return
        }
        return <PopupExportPayAccZSys setShowView={setShowPayGateWay} showView={showPayGateWay} data={dataStore.Summary} companyName={companyName}/>
    }

    const renderRowBody = () => {
        let ls = [];
        const records = getDataSource();
        if (typeof dataStore.Summary !== "undefined") {
            for (let i in records) {
                let row = records[i];
                let divWeek = (
                    <>
                        <ClockCircleFilled
                            style={{width: 15, color: "#3880ff", cursor: "pointer"}}
                            onClick={() => requestWeekView(row.EmpNumber)}
                        />
                        &nbsp;
                        <OrderedListOutlined
                            style={{width: 15, color: "red", cursor: "pointer"}}
                            onClick={() => requestGeneratePaySlip(row)}
                        />
                    </>
                )

                let makePopupClocks = (empRecord, viewT, val) => {
                    return <a
                        style={{cursor: "pointer",}}
                        onClick={() => requestViewClocks(row.EmpNumber, viewT, empRecord)}
                    >
                        {val}
                    </a>
                }
                let totDeduction = row.Deduction + row.Paye + row.UIF + row.PensionFund;
                let record = [
                    {name: "", value: divWeek, span: 1, style: styles.RowHeaderAlignCenter},
                    {name: "Code", value: row.EmpNumber, span: 1, style: styles.RowHeaderAlignLeft},
                    {name: "Name", value: row.EmpName, span: 3, style: styles.RowHeaderAlignLeft},
                    {name: "Site", value: row.EmpSite, span: 2, style: styles.RowHeaderAlignLeft},
                    {
                        name: "Days",
                        value: makePopupClocks(row, "all", (row.TotNor.Days + row.TotSat.Days + row.TotSun.Days)),
                        span: 1,
                        style: styles.RowHeaderAlignCenter
                    },
                    {
                        name: "NOR/D",
                        value: makePopupClocks(row, "Norm", (row.TotNor.Days)),
                        span: 1,
                        style: styles.RowHeaderAlignCenter
                    },
                    {
                        name: "SAT/D",
                        value: makePopupClocks(row, "Saturday", row.TotSat.Days),
                        span: 1,
                        style: styles.RowHeaderAlignCenter
                    },
                    {
                        name: "SUN/D",
                        value: makePopupClocks(row, "Sunday", row.TotSun.Days),
                        span: 1,
                        style: styles.RowHeaderAlignCenter
                    },
                    {
                        name: "PPH/D",
                        value: makePopupClocks(row, "Ph", row.TotPh.Days),
                        span: 1,
                        style: styles.RowHeaderAlignCenter
                    },
                    {
                        name: "Hours",
                        value: row.TotHours,
                        span: 1,
                        style: {...styles.RowHeaderAlignCenter, backgroundColor: "#F2F2F2"}
                    },
                    {
                        name: "NOR/H",
                        value: row.TotNor.Hours,
                        span: 1,
                        style: {...styles.RowHeaderAlignCenter, backgroundColor: "#F2F2F2"}
                    },
                    {
                        name: "SAT/H",
                        value: row.TotSat.Hours,
                        span: 1,
                        style: {...styles.RowHeaderAlignCenter, backgroundColor: "#F2F2F2"}
                    },
                    {
                        name: "SUN/H",
                        value: row.TotSun.Hours,
                        span: 1,
                        style: {...styles.RowHeaderAlignCenter, backgroundColor: "#F2F2F2"}
                    },
                    {
                        name: "PPH/H",
                        value: row.TotPh.Hours,
                        span: 1,
                        style: {...styles.RowHeaderAlignCenter, backgroundColor: "#F2F2F2"}
                    },
                    {
                        name: "OTM/H",
                        value: row.TotOT.Hours,
                        span: 1,
                        style: {...styles.RowHeaderAlignCenter, backgroundColor: "#F2F2F2"}
                    },
                    {
                        name: "GPay",
                        value: "R " + FormatMoneySouthAfrica(parseFloat(toFix2(row.GrossPay))),
                        span: 2,
                        style: {...styles.RowHeaderAlignRight, fontWeight: "bold"}
                    },
                   /* {name: "UIF", value: "R " + FormatMoneySouthAfrica(parseFloat(toFix2(row.UIF))), span: 1, style: styles.RowHeaderAlignRight},*/
                    {name: "PAYE", value: <a onClick={()=>displayDeduction(row)}> {"R " + FormatMoneySouthAfrica(parseFloat(toFix2(totDeduction)))}</a>, span: 2, style: styles.RowHeaderAlignRight},
                    {
                        name: "NPay.",
                        value: "R " + FormatMoneySouthAfrica(parseFloat(toFix2(row.NetPay))),
                        span: 2,
                        style: {
                            ...styles.RowHeaderAlignRight,
                            backgroundColor: "#849780",
                            color: "white",
                            fontWeight: "bold"
                        }
                    },
                ]
                ls.push(record)
            }
        }
        return ls.map(rows => {
            return (
                <Row>
                    {rows.map((row, index) => {
                        return (
                            <Col span={row.span} style={row.style}>
                                {row.value}
                            </Col>
                        )
                    })}
                </Row>
            )
        })
    }
    const renderTotalSection = () => {
        const records = getDataSource();
        let totDayHours = 0, totDayNorm = 0, totDaySat = 0, totDaySun = 0, totDayPh = 0;
        let totHourHours = 0, totHourNorm = 0, totHourSat = 0, totHourSun = 0, totHourPh = 0, totHourOT = 0;
        let totSumGross = 0, totSumUIF = 0, totSumDed = 0,totSumPaye=0, totSumNet = 0;
        let totEmployee = 0;
        let totalDeduction  = 0;
        if (typeof dataStore.Summary !== "undefined") {
            totEmployee = records.length;
            for (let i in records) {
                let row = records[i];
                totDayHours += row.TotDays;
                totDayNorm += row.TotNor.Days;
                totDaySat += row.TotSat.Days;
                totDaySun += row.TotSun.Days;
                totDayPh += row.TotPh.Days;

                totHourHours += row.TotHours;
                totHourNorm += row.TotNor.Hours;
                totHourSat += row.TotSat.Hours;
                totHourSun += row.TotSun.Hours;
                totHourPh += row.TotPh.Hours;
                totHourOT += row.TotOT.Hours;

                totSumGross += row.GrossPay;
                totSumUIF += row.UIF;
                totSumDed += row.Deduction;
                totSumPaye +=row.Paye;
                totalDeduction += row.Deduction + row.Paye + row.UIF

                totSumNet += row.NetPay
            }
        }

        return (
            <Row style={{marginBottom: 35}}>
                <Col span={1} style={{...styles.RowHeaderAlignRow, ...styles.noBorder}}>&nbsp;</Col>
                <Col span={1} style={{...styles.RowHeaderAlignRow, ...styles.noBorder}}>&nbsp;</Col>
                <Col span={3} style={{...styles.RowHeaderAlignRow, backgroundColor: "#C4BD97"}}>{totEmployee}</Col>
                <Col span={2} style={{...styles.RowHeaderAlignRow, ...styles.noBorder}}>&nbsp;</Col>

                {/*Days info*/}
                <Col span={1} style={{...styles.RowHeaderAlignCenter2, backgroundColor: "#C4BD97"}}>{totDayHours}</Col>
                <Col span={1} style={{...styles.RowHeaderAlignCenter2, backgroundColor: "#C4BD97"}}>{totDayNorm}</Col>
                <Col span={1} style={{...styles.RowHeaderAlignCenter2, backgroundColor: "#C4BD97"}}>{totDaySat}</Col>
                <Col span={1} style={{...styles.RowHeaderAlignCenter2, backgroundColor: "#C4BD97"}}>{totDaySun}</Col>
                <Col span={1} style={{...styles.RowHeaderAlignCenter2, backgroundColor: "#C4BD97"}}>{totDayPh}</Col>
                {/*Hours info*/}
                <Col span={1} style={{
                    ...styles.RowHeaderAlignCenter2,
                    borderLeftWidth: 2,
                    backgroundColor: "#C4BD97"
                }}>{totHourHours}</Col>
                <Col span={1} style={{...styles.RowHeaderAlignCenter2, backgroundColor: "#C4BD97"}}>{totHourNorm}</Col>
                <Col span={1} style={{...styles.RowHeaderAlignCenter2, backgroundColor: "#C4BD97"}}>{totHourSat}</Col>
                <Col span={1} style={{...styles.RowHeaderAlignCenter2, backgroundColor: "#C4BD97"}}>{totHourSun}</Col>
                <Col span={1} style={{...styles.RowHeaderAlignCenter2, backgroundColor: "#C4BD97"}}>{totHourPh}</Col>
                <Col span={1} style={{
                    ...styles.RowHeaderAlignCenter2,
                    borderRightWidth: 2,
                    backgroundColor: "#C4BD97"
                }}>{totHourOT}</Col>
                {/*Summary info*/}
                <Col span={2} style={{
                    ...styles.RowHeaderAlignRow, ...styles.noBorderUpCol,
                    backgroundColor: "#A9D0BE",
                    color: "white"
                }}>{"R " + FormatMoneySouthAfrica(parseFloat(toFix2(totSumGross)))}</Col>
                {/*<Col span={1} style={{
                    ...styles.RowHeaderAlignRow, ...styles.noBorderUpCol,
                    backgroundColor: "#C4BD97",
                    color: "white"
                }}>{"R " + FormatMoneySouthAfrica(parseFloat(toFix2(totSumUIF)))}</Col>*/}
                <Col span={2} style={{
                    ...styles.RowHeaderAlignRow, ...styles.noBorderUpCol,
                    backgroundColor: "#C4BD97",
                    color: "white"
                }}>{"R " + FormatMoneySouthAfrica(parseFloat(toFix2(totalDeduction)))}</Col>
                <Col span={2} style={{
                    ...styles.RowHeaderAlignRow, ...styles.noBorderUpCol,
                    backgroundColor: "#008050",
                    color: "white"
                }}>{"R " + FormatMoneySouthAfrica(parseFloat(toFix2(totSumNet)))}</Col>
            </Row>
        )


    }
    const renderHeader = () => {


        return (
            <>
                <Row>
                    <Col span={1} style={{...styles.RowHeaderAlignRow, ...styles.noBorderDownCol}}>Actions</Col>
                    <Col span={1} style={{...styles.RowHeaderAlignRow, ...styles.noBorderDownCol}}>Code</Col>
                    <Col span={3} style={{...styles.RowHeaderAlignRow, ...styles.noBorderDownCol}}>Name</Col>
                    <Col span={2} style={{...styles.RowHeaderAlignRow, ...styles.noBorderDownCol}}>Site</Col>
                    <Col span={5} style={{...styles.RowHeaderAlignCenter, color: "#3880ff"}}>
                        Days
                    </Col>
                    <Col span={6}
                         style={{...styles.RowHeaderAlignCenter, ...styles.headerSeparatorHours, color: "#3880ff"}}>
                        Hours
                    </Col>
                    <Col span={2} style={{...styles.RowHeaderAlignRow, ...styles.noBorderDownCol}}>Gross</Col>
                    {/*<Col span={1} style={{...styles.RowHeaderAlignRow, ...styles.noBorderDownCol}}>UIF</Col>*/}
                    <Col span={2} style={{...styles.RowHeaderAlignRow, ...styles.noBorderDownCol}}>Deductions</Col>
                    <Col span={2} style={{
                        ...styles.RowHeaderAlignRow, ...styles.noBorderDownCol,
                        backgroundColor: "#849780",
                        color: "white"
                    }}>Net</Col>
                </Row>
                <Row>
                    <Col span={1} style={{...styles.RowHeaderAlignRow, ...styles.noBorderUpCol}}>&nbsp;</Col>
                    <Col span={1} style={{...styles.RowHeaderAlignRow, ...styles.noBorderUpCol}}>&nbsp;</Col>
                    <Col span={3} style={{...styles.RowHeaderAlignRow, ...styles.noBorderUpCol}}>&nbsp;</Col>
                    <Col span={2} style={{...styles.RowHeaderAlignRow, ...styles.noBorderUpCol}}>&nbsp;</Col>
                    {/*Days info*/}
                    <Col span={1} style={{...styles.RowHeaderAlignCenter2,}}>Total</Col>
                    <Col span={1} style={styles.RowHeaderAlignCenter2}>Week</Col>
                    <Col span={1} style={styles.RowHeaderAlignCenter2}>Sat</Col>
                    <Col span={1} style={styles.RowHeaderAlignCenter2}>Sun</Col>
                    <Col span={1} style={{...styles.RowHeaderAlignCenter2,}}>Pph</Col>
                    {/*Hours info*/}
                    <Col span={1} style={{
                        ...styles.RowHeaderAlignCenter2,
                        borderLeftWidth: 2,
                        backgroundColor: "#F2F2F2"
                    }}>Total</Col>
                    <Col span={1} style={{...styles.RowHeaderAlignCenter2, backgroundColor: "#F2F2F2"}}>Norm</Col>
                    <Col span={1} style={{...styles.RowHeaderAlignCenter2, backgroundColor: "#F2F2F2"}}>Sat</Col>
                    <Col span={1} style={{...styles.RowHeaderAlignCenter2, backgroundColor: "#F2F2F2"}}>Sun</Col>
                    <Col span={1} style={{...styles.RowHeaderAlignCenter2, backgroundColor: "#F2F2F2"}}>Pph</Col>
                    <Col span={1} style={{
                        ...styles.RowHeaderAlignCenter2,
                        borderRightWidth: 2,
                        backgroundColor: "#F2F2F2"
                    }}>O/Time</Col>
                    {/*Summary info*/}
                    <Col span={2} style={{...styles.RowHeaderAlignRow, ...styles.noBorderUpCol}}>&nbsp;</Col>
                    {/*<Col span={1} style={{...styles.RowHeaderAlignRow, ...styles.noBorderUpCol}}>&nbsp;</Col>*/}
                    <Col span={2} style={{...styles.RowHeaderAlignRow, ...styles.noBorderUpCol}}>&nbsp;</Col>
                    <Col span={2} style={{
                        ...styles.RowHeaderAlignRow, ...styles.noBorderUpCol,
                        backgroundColor: "#849780"
                    }}>&nbsp;</Col>
                </Row>
            </>
        )


    }

    const renderCommand=()=>{
        return(
            <Row gutter={16} style={{marginTop: -10}}>
                <Col span={24}>
                    <Divider orientation="center" plain>
                        <Button danger onClick={() => setShowViewPastel(true)}>Pastel</Button>
                        &nbsp;&nbsp;
                        <Button danger onClick={() => setShowPayGateWay(true)}>Payment Gateway</Button>
                        &nbsp;&nbsp;
                        <Button danger onClick={() =>  setShowBankExport(true)}>Bank</Button>
                        &nbsp;&nbsp;
                        <Button danger onClick={() => submitGeneratePaymentSummary()}>Payment Summary</Button>
                        &nbsp;&nbsp;
                        <Button danger onClick={() => submitGeneratePaymentSummaryCsv()}>Payment Summary CSV</Button>


                        &nbsp;&nbsp;
                        <Button danger onClick={() => setShowQuickSummary(true)}>Quick Summary</Button>
                        &nbsp;&nbsp;
                        <Button danger onClick={() => submitGenerateLabourReport()}>Labour 1 Actual</Button>
                        &nbsp;&nbsp;
                        <Button danger onClick={() => submitGenerateLabourReportCalendar()}>Labour 1 Calendar</Button>

                        &nbsp;&nbsp;
                        <Button danger onClick={() => submitGenerateLabourReport2Actual()}>Labour 2 Actual</Button>
                        &nbsp;&nbsp;
                        <Button danger onClick={() => submitGenerateLabourReport2Calendar()}>Labour 2 Calendar</Button>

                        &nbsp;&nbsp;
                        <Button danger onClick={() => submitGenerateTimesheetCalendar()}>Timesheet Calendar</Button>
                        &nbsp;&nbsp;
                        <Button danger onClick={() => requestGeneratePaySlipBulk()}>Bulk payslip</Button>
                    </Divider>
                </Col>
            </Row>
        )
    }

    if (popupWeekly) {
        return (
            <div style={styles.container}>
                <h3>GROSS WAGE CALCULATOR
                    <a style={styles.closeWeekView} onClick={() => closeWeekView()}>Close</a>
                </h3>
                <WeeklyView
                    payDate={payDate}
                    dataRecordSummary={getSelectedDataSummary()}
                    dataRecordWeeks={getSelectedDataWeeks()}
                />
            </div>
        )
    }

    return (
        <div style={styles.container}>
            <input placeholder={"Search...."} style={styles.searchInput} onChange={e => setKeySearch(e.target.value)}/>
            {renderCommand()}
            {renderTotalSection()}
            {renderHeader()}
            {renderRowBody()}
            {printTimesheet ? <PrintPdfTimesheet/> : null}
            <PopupDetail2
                clocks={clocks}
                showClocks={showClocks}
                viewClocksType={viewClocksType}
                viewClocksRec={viewClocksRec}
                closeViewClocks={closeViewClocks}
                removeClock={removeClock}
            />
            {renderReportPopupExportCsvPastel()}
            {renderReportPopupExportCsvPaymentGateway()}
            <PopupExportBank2
                data={buildBankExportCsv()}
                showView={ShowBankExport}
                setShowView={setShowBankExport}
                FnbCsvInfo={FnbCsvInfo}
                />
            <PopupQuickSummary
              data={dataStore.Summary }
              showView={ShowQuickSummary}
              setShowView={setShowQuickSummary}
            />
        </div>
    )
}

const styles = {
    noBorderDownCol: {
        borderBottomWidth: 0,
    },
    noBorderUpCol: {
        borderTopWidth: 0,
    },
    noBorder: {
        borderWidth: 0,
    },
    closeWeekView: {
        marginLeft: 50,
        color: "red"
    },
    searchInput: {
        width: "100%",
        marginBottom: 10,
        borderRadius: 10,
        border: "1px solid blue"
    },
    container: {
        marginTop: -50,
        minWidth: "100%"
    },
    headerSeparatorHours: {
        backgroundColor: "#F2F2F2",
        border: "2px solid gray"
    },
    RowHeaderAlignRight: {
        fontSize: 11,
        /*color: "#3880ff",*/
        /*fontWeight:"bold",*/
        textAlign: "right",
        border: "0.5px solid gray"
    },
    RowHeaderAlignLeft: {
        fontSize: 11,
        color: "black",
        /*fontWeight:"bold",*/
        textAlign: "right",
        border: "1px solid gray"
    },
    RowHeaderAlignRow: {
        fontSize: 11,
        color: "#3880ff",
        fontWeight: "bold",
        paddingRight: 10,
        textAlign: "right",
        border: "1px solid gray",
    },
    RowHeaderAlignCenter: {
        fontSize: 11,
        /* color: "red",
         fontWeight: "bold",*/
        textAlign: "center",
        border: "1px solid gray"
    },

    RowBodyAlignRight: {
        fontSize: 11,
        color: "gray",
        textAlign: "right",
    },
    RowBodyAlignLeft: {
        fontSize: 11,
        color: "gray",
        textAlign: "right",
    },
    RowBodyAlignCenter: {
        fontSize: 11,
        color: "gray",
        textAlign: "center",
    },

    RowHeaderAlignCenter2: {
        fontSize: 11,
        color: "black",
        textAlign: "center",
        border: "1px solid black"
    },


    firstRowCol1: {
        textAlign: "right"
    },
    headerColor: {
        color: "orange",
        textAlign: "center"
    },
}