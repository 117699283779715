import React from "react";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import PluginAddDeleteList from "../../../../components/Common/PluginAddDeleteList2";
import {fetchDataEntity} from "../../../../api/entity-data";
import {
    getClientOption,
    getCompanyList, getItemList,
    getMonthOption,
    getProjectList,
    getTemplateList,
    getYearOption,
    loadData
} from "./lib";


class SetupLabourInvoiceItemHeaderPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Modules: [],
            Clients: [],
            Projects:[],
            Items:[],
            isLoading: false,
            company: "",
            component: "list",
            newEntry: {},
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await loadData("modules", "Modules",this);
        await loadData("SettingInvoiceServiceDebtors", "Clients",this);
        await loadData("SettingInvoiceAddonProject", "Projects",this);
        await loadData("SettingInvoiceAddonLabourItems","Items",this)
    }

    render() {
        const org = this.state.newEntry.selectedCompany;
        const client = this.state.newEntry.selectedClient;
        const project = this.state.newEntry.selectedProject;
        const item = this.state.newEntry.selectedItem;
        const companyOptions = getCompanyList(this.state.Companies);
        const clientOptions = getClientOption(this.state.Clients,org);
        const projectOptions =getProjectList(this.state.Projects,org,client);
        const itemOptions = getItemList(this.state.Items,org,client,project);

        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Setup Labour Invoice Header</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"SettingInvoiceAddonLabourItemHeader"}
                            depending={{
                                "org": {
                                    targetKey: "CustomerNumber",
                                    displayKey: "Name",
                                    storageTable: "Companies"
                                },
                                "project":{
                                    targetKey: "code",
                                    displayKey: "name",
                                    storageTable: "Projects"
                                }
                            }}
                            fields={[
                                {
                                    name: "org",
                                    dataType: "option",
                                    required: true,
                                    label: "Company",
                                    options: companyOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Companies"}
                                },
                                {
                                    name: "client",
                                    dataType: "option",
                                    required: true,
                                    label: "Debtor",
                                    options: clientOptions,
                                    depending: {stateKey: "org", displayKey: "Name", storageTable: "Clients"}
                                },
                                {
                                    name: "project",
                                    dataType: "option",
                                    required: true,
                                    label: "Project",
                                    options: projectOptions,
                                    depending: {stateKey: "project", displayKey: "Name", storageTable: "Projects"}
                                },
                                {
                                    name: "item",
                                    dataType: "option",
                                    required: true,
                                    label: "Item Description",
                                    options: itemOptions,
                                    depending: {stateKey: "item", displayKey: "Name", storageTable: "Items"}
                                },

                                {
                                    name: "contractnumber",
                                    dataType: "text",
                                    required: true,
                                    label: "Contract Number",
                                    options: []
                                },
                                {
                                    name: "contractdescription",
                                    dataType: "text",
                                    required: true,
                                    label: "Contract Desc",
                                    options: []
                                },
                                {
                                    name: "contractarea",
                                    dataType: "text",
                                    required: true,
                                    label: "Contract Area",
                                    options: []
                                },


                                {
                                    name: "fromcompanyname",
                                    dataType: "text",
                                    required: true,
                                    label: "Company Name",
                                    options: []
                                },
                                {
                                    name: "fromcompanydesc",
                                    dataType: "text",
                                    required: true,
                                    label: "Company Desc",
                                    options: []
                                },
                                {
                                    name: "fromcompanypobox",
                                    dataType: "text",
                                    required: true,
                                    label: "Company PoBox",
                                    options: []
                                },
                                {
                                    name: "fromcompanyaddressline1",
                                    dataType: "text",
                                    required: true,
                                    label: "Company Address Line1",
                                    options: []
                                },
                                {
                                    name: "fromcompanyaddressline2",
                                    dataType: "text",
                                    required: true,
                                    label: "Company Address Line2",
                                    options: []
                                },
                                {
                                    name: "fromcompanyregnumber",
                                    dataType: "text",
                                    required: true,
                                    label: "Company RegNumber",
                                    options: []
                                },
                                {
                                    name: "fromcompanyvatnumber",
                                    dataType: "text",
                                    required: true,
                                    label: "Company vatNumber",
                                    options: []
                                },
                                {
                                    name: "fromcompanysupplynumber",
                                    dataType: "text",
                                    required: true,
                                    label: "Company SupplyNumber",
                                    options: []
                                },
                                {
                                    name: "fromcompanyvendornumber",
                                    dataType: "text",
                                    required: true,
                                    label: "Company VendorNumber ",
                                    options: []
                                },



                                {
                                    name: "tocompanyname",
                                    dataType: "text",
                                    required: true,
                                    label: "Client Name",
                                    options: []
                                },
                                {
                                    name: "tocompanydesc",
                                    dataType: "text",
                                    required: true,
                                    label: "Client Desc",
                                    options: []
                                },
                                {
                                    name: "tocompanypobox",
                                    dataType: "text",
                                    required: true,
                                    label: "Client PoBox",
                                    options: []
                                },
                                {
                                    name: "tocompanyaddressline1",
                                    dataType: "text",
                                    required: true,
                                    label: "Client Address Line1",
                                    options: []
                                },
                                {
                                    name: "tocompanyaddressline2",
                                    dataType: "text",
                                    required: true,
                                    label: "Client Address Line2",
                                    options: []
                                },
                                {
                                    name: "tocompanyregnumber",
                                    dataType: "text",
                                    required: true,
                                    label: "Client RegNumber",
                                    options: []
                                },
                                {
                                    name: "tocompanyvatnumber",
                                    dataType: "text",
                                    required: true,
                                    label: "Client vatNumber",
                                    options: []
                                },
                                {
                                    name: "tocompanysupplynumber",
                                    dataType: "text",
                                    required: true,
                                    label: "Client SupplyNumber",
                                    options: []
                                },
                                {
                                    name: "tocompanyvendornumber",
                                    dataType: "text",
                                    required: true,
                                    label: "Client VendorNumber ",
                                    options: []
                                },

                            ]}
                            deleteKeys={[
                                {key: "org", type: "string"},
                                {key: "client", type: "string"},
                                {key: "project", type: "string"},
                                {key: "item", type: "string"},
                            ]}
                            stateArray={"Data"}
                            table={"SettingInvoiceAddonLabourItemHeader"}
                            addFormText={"Invoice Period"}
                            stepTitle={"Setup invoice period"}

                            filterByCompany={true}
                            displayRules={[
                                {rowKey:"org",stateKey:"selectedCompany"},
                                {rowKey:"client",stateKey:"selectedClient"},
                                {rowKey:"project",stateKey:"selectedProject"},
                                {rowKey:"item",stateKey:"selectedItem"}
                            ]}
                            extraFilters={[
                                {
                                    recordKey:"key",
                                    recordValue:"val",
                                    stateKey:"client",
                                    filterRecordKey:"client",
                                    filterStateKey:"selectedClient",
                                    allowBlank:true,
                                    name:"selectedClient",
                                    label:"Debtors",
                                    options: clientOptions,
                                    errorMsg: "Sorry you can't save, Please select your client!"
                                },
                                {
                                    recordKey:"key",
                                    recordValue:"val",
                                    stateKey:"project",
                                    filterRecordKey:"project",
                                    filterStateKey:"selectedProject",
                                    allowBlank:true,
                                    name:"selectedProject",
                                    label:"Project",
                                    options: projectOptions,
                                    errorMsg: "Sorry you can't save, Please select your project!"
                                },
                                {
                                    recordKey:"key",
                                    recordValue:"val",
                                    stateKey:"item",
                                    filterRecordKey:"item",
                                    filterStateKey:"selectedItem",
                                    allowBlank:true,
                                    name:"selectedItem",
                                    label:"Item Description",
                                    options: itemOptions,
                                    errorMsg: "Sorry you can't save, Please select your item!"
                                }
                            ]}

                            sortMe={"name"}

                        />

                    </div>
                </div>
            </Page>
        )
    }

}

export default Connector(SetupLabourInvoiceItemHeaderPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

