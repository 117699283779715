import React from "react";
import {Row,Col,Input,TimePicker} from "antd";
import moment from 'moment';


const calculateHoursBetweenTimer=(startTime,end)=>{
    let duration = moment.duration(end.diff(startTime));
    let hours = duration.asHours();
    return hours;
}
export default (props)=>{
    let {_this}=props;
    let state=_this.state;

    const onChangeEndTime=(time, timeString,key)=> {
        let tmp=_this.state;
        let rec = tmp.newEntry[key];
        rec.end = timeString;
        rec.endTimer = time;
        if(rec.startTimer!==null&&rec.endTimer!==null){
            rec.hours = calculateHoursBetweenTimer(rec.startTimer,rec.endTimer)
        }
        tmp.newEntry[key] = rec;
        _this.setState(tmp);
        console.log("onChangeEndTime > ",time, timeString);
    }
    const onChangeStartTime=(time, timeString,key)=> {
        let tmp=_this.state;
        let rec = tmp.newEntry[key];
        rec.start = timeString;
        rec.startTimer = time;
        if(rec.startTimer!==null&&rec.endTimer!==null){
            rec.hours = calculateHoursBetweenTimer(rec.startTimer,rec.endTimer)
        }
        tmp.newEntry[key] = rec;
        _this.setState(tmp);
        console.log("onChangeStartTime > ",time, timeString);
    }
    const onChangeHours=(rec)=>{

    }

    const getValueHours=(key)=>{
        let tmp=_this.state;
        let rec = tmp.newEntry[key];
        return rec.hours;
    }
    let ls=[];
    for(let i in state.newEntry){
        const row = state.newEntry[i];
        ls.push(row)
    }
    return(
        <div>
            <h3>Working Time</h3>
            <Row gutter={16}>
                <Col span={6} style={styles.headerCol}>Worker</Col>
                <Col span={6} style={styles.headerCol}>Start Time</Col>
                <Col span={6} style={styles.headerCol}>End Time</Col>
                <Col span={6} style={styles.headerCol}>Hours</Col>
            </Row>
            {ls.map(rec=>{
                return(
                    <Row gutter={16}>
                        <Col span={6} style={styles.bodyCol}>{rec.name}</Col>
                        <Col span={6} style={styles.bodyCol}>
                            <TimePicker
                                onChange={(time,timeString)=>onChangeStartTime(time,timeString,rec.key)}
                                defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                            />
                        </Col>
                        <Col span={6} style={styles.bodyCol}>
                            <TimePicker
                                onChange={(time,timeString)=>onChangeEndTime(time,timeString,rec.key)}
                                defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                            />
                        </Col>
                        <Col span={6} style={styles.bodyCol}>
                            <Input
                                onChange={(e,ee)=>onChangeHours(e,ee,rec.key)}
                                value={getValueHours(rec.key)}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                )
            })}
        </div>
    )
}

const styles={
    headerCol:{
        fontWeight:"bold",
        fontSize:16
    },
    bodyCol:{
        fontSize:14
    }
}