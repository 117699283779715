import React from "react";
import {Drawer, Form, Button, Col, Row, Input, Select, DatePicker} from 'antd';

const {Option} = Select;

const makeCol = (row,_this) => {
    let {state,handleChangeInput} = _this;
    if (row === null) {
        return null;
    }
    if(row.datatype==="list"){
        let options=[];
        for(let i in row.options){
            const key = i;
            const val = i;
            options.push({
                key:key,
                val:val,
            })
        }
        return(
            <Col span={12}>
                <Form.Item
                    name={row.fieldname}
                    label={row.fieldname}
                    rules={[{required: true, message: 'Please enter user ' + row.fieldname}]}
                >
                    <Select
                        placeholder={"Please enter user " + row.fieldname}
                        onChange={(e)=>handleChangeInput(row.fieldname,e)}
                        value={_this.getHandleChangeValKey(row.fieldname)}
                        required={row.mandatory}
                    >
                        {options.map(rec =>{
                            return(
                                <Option value={rec.key}>{rec.val}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            </Col>
        )
    }
    return (
        <Col span={12}>
            <Form.Item
                name={row.fieldname}
                label={row.fieldname}
                rules={[{required: true, message: 'Please enter user ' + row.fieldname}]}
            >
                <Input
                    placeholder={"Please enter user " + row.fieldname}
                    onChange={(e)=>handleChangeInput(row.fieldname,e.target.value)}
                    value={_this.getHandleChangeValKey(row.fieldname)}
                    required={row.mandatory}
                />
            </Form.Item>
        </Col>
    )
}

export default (props) => {
    let {_this,onSubmitRecord,attributes,visible} = props;
    let {state,
        handleChangeInput,
        handleChangeInput2,
        closeDrawer
    } = _this;

    //let attributes = SampleAttributes();

    let ls = [];
    let loopIndex = 0;
    for (let i = 0; i < attributes.length; i += 2) {
        loopIndex = i;
        const row = attributes[i];
        let row2 = null;
        let nextIndex = i + 1;
        if(nextIndex < (attributes.length)){
            row2 = attributes[nextIndex]
        }
        ls.push(
            <Row gutter={16}>
                {makeCol(row,_this)}
                {makeCol(row2,_this)}
            </Row>
        );
    }

    const onClose = () => {
        closeDrawer();
    }
    const onSubmit=async ()=>{
        //alert("onsubmit record request")
        await onSubmitRecord();
    }
    return (
        <>
            <Drawer
                title="Create a new account"
                width={720}
                onClose={onClose}
                visible={visible}
                bodyStyle={{paddingBottom: 80}}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={onClose} style={{marginRight: 8}}>
                            Cancel
                        </Button>
                        <Button  onClick={onSubmit} type="primary">
                            Submit
                        </Button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    {ls.map(row=>{
                        return row;
                    })}
                </Form>
            </Drawer>
        </>
    );
}
