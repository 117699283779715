

import React from 'react';
//import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';


import {Row,Col} from "antd";

export default (props)=>{
    let data=[
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
        {date:"2021-08-01",hours:8,day:"monday"},
    ]
    const onDocumentLoadSuccess=()=>{
        console.log("onDocumentLoadSuccess > ",)
    }
    return (

        <div
        >
            <div  style={styles.page}>
                <div style={styles.section}>
                    <label>Section #1</label>
                    <Row>
                        <Col span={1}>
                            #
                        </Col>

                        <Col span={10}>
                            Date
                        </Col>
                        <Col span={10}>
                            Day
                        </Col>
                        <Col span={3}>
                            Hours
                        </Col>
                    </Row>
                    {data.map((row,index)=>{
                        return(
                            <Row>
                                <Col span={1}>
                                    {index+1}
                                </Col>

                                <Col span={10}>
                                    {row.date}
                                </Col>
                                <Col span={10}>
                                    {row.day}
                                </Col>
                                <Col span={3}>
                                    {row.hours}
                                </Col>
                            </Row>
                        )
                    })}
                </div>
                <div style={styles.section}>
                    <label>Section #2</label>
                </div>
            </div>
        </div>
    )
}

// Create styles
const styles = {
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }
};