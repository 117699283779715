import {Button, Col, DatePicker, Form, Row, Select} from "antd";
import {SearchOutlined,PlusCircleOutlined} from "@ant-design/icons";
import React from "react";
const {Option} = Select;

export default (props)=>{
    let {_this}=props;
    const onChangeStartDate=(key,date)=>{
        _this.setState({
            clockDate:date
        });
        console.log("onChangeStartDate -----> ",date)
    }
    return(
        <Row gutter={16}>
            <Col span={20}>
                <Form.Item
                    name="startDate"
                    label="Start Date"
                    rules={[{ required: true, message: 'Please choose the start date' }]}
                >
                    <DatePicker onChange={onChangeStartDate} style={{minWidth:"100%"}}/>
                </Form.Item>
            </Col>
            <Col span={4}>
                <Button type="default" onClick={_this.requestNewClock} style={{marginTop:0,float:"right"}}>
                    <PlusCircleOutlined /> Add New Clock
                </Button>
            </Col>
        </Row>
    )
}