
import * as PropTypes from "prop-types";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";

import './CircularProgressBar.css'
import {Colors} from "../../constants/colors";


CircularProgressbar.propTypes = {
    styles: PropTypes.any,
    text: PropTypes.string,
    value: PropTypes.number
};
export default ({percentageValue,displayLabel})=>{
    let backgroundColor = Colors.brand.lightBlue
    let percentage =parseFloat(percentageValue)
    return (
        <div className={"payDashboardPie"}>
            <CircularProgressbar
                value={percentage}
                text={displayLabel&&`${percentage.toFixed(2)}%`}
                styles={buildStyles({
                    textSize: '12px',
                    strokeLinecap: 'butt',
                    textColor: 'black',
                    trailColor: 'lightgray',
                    pathColor: backgroundColor,
                })}
            />
        </div>
    )
}