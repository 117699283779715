import React from "react";
import {Alert} from "antd";
import {Steps, Button, message} from 'antd';
import {PostToPbx} from "../../../api/http-post";
import StepChooseFile from "./StepChooseFile";
import StepMappingField from "./StepMappingField";
import StepValidateInput from "./StepValidateInput";
import ValidationsInput from "./ValidationsInput";
import StepFinishInput from "./StepFinishInput";

const {Step} = Steps;
// period, payCycle,designation, condition, rate, site, finish


export default class Steppers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
        };
    }

    componentDidMount = async () => {
    }

    next() {
        let _this = this.props._this;
        const current = this.state.current + 1;

        if(!ValidationsInput({_this:_this,current:current})){
            return
        }

        this.setState({current});
        if(current===2){
           this.props._this.checkValidation();
        }

    }

    prev() {
        const current = this.state.current - 1;
        this.setState({current});
    }

    closeSetup = () => {
        if (!window.confirm("Are you sure to exit this setup?")) {
            return
        }
        let {_this} = this.props;
        _this.setState({
            showSetup: false,
            selectContract: null,
        })
    }
    steps = () => {
        return [
            {
                title: 'Choose file',
                content: <StepChooseFile _this={this.props._this}/>,
            },
            {
                title: 'Define Mapping',
                content: <StepMappingField _this={this.props._this}/>,
            },
            {
                title: 'Validation',
                content:<StepValidateInput _this={this.props._this}/>,
            },
            {
                title: 'Finish',
                content: <StepFinishInput _this={this.props._this}/>,
            },

        ]
    }

    getSelectedCompanyName=()=>{
        let {selectedCompany,Companies}=this.props._this.state;
        let name = selectedCompany;
        for(let i in Companies){
            const row = Companies[i];
            if(row.CustomerNumber===selectedCompany){
                name=row.Name
            }
        }
        return name.toLocaleUpperCase();
    }
    doneStep=async ()=>{
        let {_this} = this.props;
        await _this.onSubmitUpload();
    }

    render() {
        let {_this} = this.props;
        const {current} = this.state;
        return (
            <div style={{marginTop: -50}}>
                <div style={{minWidth: "100%", padding: 10, color: "red",marginTop:10,}}>
                    <Alert style={{margin: '16px 0', minHeight: 40, maxHeight: 40,itemAlign:"center"}}
                           type={"info"}
                           message={<div style={{minWidth: "100%"}}>
                               <span style={{float: "left"}}>{this.getSelectedCompanyName()}</span>
                               <span style={{float: "right"}}>{_this.state.selectedModule.toLocaleUpperCase()}</span>
                           </div>
                           }>

                    </Alert>
                </div>
                <Steps current={current}>
                    {this.steps().map(item => (
                        <Step key={item.title} title={item.title}/>
                    ))}
                </Steps>
                <div style={styles.myBarContent}>
                    <div style={styles.stepsAction}>
                        <Button type="danger" style={{margin: '0 8px'}} onClick={() => this.closeSetup()}>
                            Exit
                        </Button>
                        {current > 0 && (
                            <Button style={{margin: '0 8px'}} onClick={() => this.prev()}>
                                Previous
                            </Button>
                        )}
                        {current < this.steps().length - 1 && (
                            <Button type="primary" onClick={() => this.next()}>
                                Next
                            </Button>
                        )}
                        {current === this.steps().length - 1 && (
                            <Button type="primary" onClick={() => {
                                message.success('Processing complete!');
                                this.doneStep();
                            }}>
                                Done
                            </Button>
                        )}

                    </div>
                </div>


                <div style={styles.stepsContent}>{this.steps()[current].content}</div>


            </div>
        );
    }
}

const styles = {
    myBarContent: {
        margin: 10,
        justifyContent: "center",
        minWidth: "100%",
        maxWidth: "100%",
        flex: 1,
        display: "flex",
        marginTop: 20
    },
    gutterBox: {
        padding: "8px 0",
        margin: 10,
        /*backgroundColor: "#00a0e9",
        minWidth: "50%",
        maxWidth: "50%"*/
    },
    stepsContent: {
        marginTop: 16,
        border: "1px dashed #e9e9e9",
        borderRadius: 2,
        backgroundColor: "#fafafa",
        minHeight: 200,
        textAlign: "center",
        paddingTop: 80,
    },
    stepsAction: {
        minWidth: "25%",
        maxWidth: "25%",
    }
}

