import React from "react";
import {Checkbox,Col,Row} from "antd";
import {SortData} from "../../../api/fn";
import moment from "moment";

const getDisplayName=(data,module,entity,attributes)=>{
    attributes = SortData("Position",attributes)
    let targetName="";
    for(let i in attributes){
        let row=attributes[i];
        if(row.org!==entity){continue}
        if(row.module!==module){continue}
        if(!row.displayselection){continue}
        targetName =targetName+""+data[row.fieldname]+" "
    }
    return targetName;
}
export default (props)=>{
    let {_this}=props;
    let state=_this.state;
    let ls=[];
    let entity = state.selectedCompany;
    let module = state.selectedEntity;
    let selectedSite=state.selectedSite;
    let newEntry = state.newEntry;
    const isSelected = (key)=>{
        if(typeof newEntry[key] ==="undefined"){return false}else{return true}
    }
    for(let i in state.Databases){
        const row=state.Databases[i];
        //console.log("1 ^^^^^^^^---> ",row.org!==entity," > ",row.org," > ",entity)

        if(row.org!==entity){continue}
        console.log("1 ^^^^^^^^---> ",row.module!==module," > ",row.module," > ",module)
        if(row.module!==module){continue}
        let site =row.data["Site"];
        if(site !==selectedSite){continue}
        ls.push({
            key: row.ref,
            val:getDisplayName(row.data,module,entity,state.Attributes),
            checked:isSelected(row.ref)
        })
    }
    const onCheckChange=(e,rec)=>{
        let tmp = _this.state;
        let defaultTime = moment().format( 'HH:mm:ss');
        if(e.target.checked){
            tmp.newEntry[rec.key]={
                name:rec.val,
                key:rec.key,
                start:defaultTime,
                end:defaultTime,
                startTimer:null,
                endTimer:null,
                hours:0
            }
        }else{
            delete tmp.newEntry[rec.key];
        }
        _this.setState(tmp);
    }
    return(
        <div>
            <h3>Choose Worker</h3>
            <div style={{backgroundColor:"white"}}>
                <Row gutter={16}>
            {ls.map(rec=>{
                return(

                    <Col span={6} style={{textAlign:"left"}}>
                    <Checkbox
                        onChange={(e)=>onCheckChange(e,rec)}
                        checked={rec.checked}
                        disabled={false}
                    >
                        {rec.val}
                    </Checkbox>
                    </Col>
                )
            })}
                </Row>
            </div>
        </div>
    )
}
