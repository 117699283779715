import React from "react";
import {Row, Col, Button} from 'antd';
import {Input} from 'antd';
import {List, Typography, Divider} from 'antd';
import {DeleteOutlined} from "@ant-design/icons";

export default (props) => {
    let {_this, handleInput} = props;

    const onChangeNewField = (e, ee) => {
        console.log("onChangeNewField > ", e.target.value, " > ", e);
        _this.setState({
            newFieldOther: e.target.value
        })
    }
    const removeItem = (item) => {
        if (!window.confirm("Are you sure to remove this item")) {
            return
        }
        let ls = [];
        for (let i in _this.state.otherFields) {
            const row = _this.state.otherFields[i];
            if (i === item.index) {
                continue
            }
            ls.push(row)
        }
        _this.setState({
            ["otherFields"]: ls
        })
    }
    const onAdd = () => {
        const rec = _this.props._this.state.selectedRecord;
        let tmp = _this.state;
        console.log("onAdd > ", _this.state.newFieldOther);
        tmp.otherFields.push({
            Org: rec.org,
            Module: rec.name,
            Position: 0,
            FieldName: _this.state.newFieldOther,
            Unique: false,
            Mandatory:false,
            Required: false,
            DisplayTable: false,
            DisplaySelection: false,
            DataType: "string",
            Options:"",
            LinkEntity:"",
        });
        _this.setState(tmp);
    }
    const renderInput = (keyName, label) => {
        return (
            <Row>
                <Col span={12} style={styles.alignMeRight}>
                    New field:
                </Col>
                <Col span={12} style={styles.alignLeft}>
                    <Input
                        name={keyName}
                        placeholder="Basic usage"
                        style={styles.input}
                        onChange={onChangeNewField}
                    />
                    <Button
                        type="primary"
                        onClick={onAdd}
                    >ADD</Button>
                </Col>
            </Row>
        )
    }

    let data = [];
    for (let i in _this.state.otherFields) {
        let row = _this.state.otherFields[i];
        console.log("@@@@@@@@----> ", row)
        data.push({
            FieldName: row.FieldName,
            index: i
        })
    }
    return (
        <div>
            {renderInput("Name", "Name")}
            <div>
                <Row>
                    <Col span={12} style={styles.alignMeRight}>

                    </Col>
                    <Col span={12} style={styles.alignLeft}>
                        <List
                            style={{minWidth: "100%"}}
                            header={<div style={{color: "red"}}>List of others field</div>}
                            bordered
                            dataSource={data}
                            renderItem={item => (
                                <List.Item>
                                    <Typography.Text mark>
                                        <DeleteOutlined onClick={() => removeItem(item)}/>
                                        <span style={{
                                            fontSize: 18,
                                            marginLeft: 10
                                        }}>{item.FieldName}</span>
                                    </Typography.Text>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>

            </div>
        </div>
    )
}
const styles = {
    alignLeft: {
        justifyContent: "flex-start",
        color: "white",
        display: "flex",
        flexDirection: "row"
    },
    alignMeRight: {
        textAlign: "right",
        paddingTop: 5,
        /*backgroundColor:"green",
        color:"white"*/
    },
    input: {
        maxWidth: 300,
        alignSelf: "left",
        marginLeft: 5,
        marginBottom: 5
    }
}
