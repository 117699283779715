import React, {useEffect, useState} from "react";
import {Button, Col, Row, Drawer, DatePicker,Input,Checkbox} from "antd";
import {string} from "prop-types";
import moment from "moment";

export const ServiceInvoiceAdhocStellenbosch = ({visible, onClose, sites, period, _this, onSubmit}) => {

    const [SiteInfos, setSiteInfo] = useState([]);
    const [isSync, setIsSync] = useState(false)
    const [step,setStep]=useState(1);
    const [stepTotal,setStepTotal]=useState(2);

    const onCheck = (e, info) => {
        let ls=[];
        for(let i in SiteInfos){
            let row = SiteInfos[i];
            console.log(`onCheck @@--> ${info} -- ${e.target.checked} `)
            if(row.site===info){
                row.action = e.target.checked
            }
            ls.push(row)
        }

        setSiteInfo(ls)
    }
    const onCheck2 = (e, site,fieldName) => {
        let ls=[];
        for(let i in SiteInfos){
            let row = SiteInfos[i];
            if(row.site===site){
                row[fieldName] = e.target.checked
            }
            ls.push(row)
        }
        setSiteInfo(ls)
    }
    const isChecked=(site)=>{
        for(let i in SiteInfos){
            let row = SiteInfos[i];
            if(row.site===site){
                return row.action
            }
        }
        return false;
    }
    const isChecked2=(site,fieldName)=>{
        for(let i in SiteInfos){
            let row = SiteInfos[i];
            if(row.site===site){
                return row[fieldName]
            }
        }
        return false;
    }
    const onChangeInput=(e, site, value, fieldName,index)=>{
        let ls=[];
        for(let i in SiteInfos){
            let row = SiteInfos[i];
            if(row.site===site){
                row[fieldName] = e
            }
            ls.push(row)
        }

        setSiteInfo(ls)
    }

    const convertStringDateIntoTime=(inDate)=>{
        return moment(inDate)
    }

    useEffect(() => {
        if (!isSync) {
            const myPeriod = JSON.parse(period)
            let ls=[];
            for(let i in sites){
                let row = sites[i];
                row.startdate = myPeriod.startdate;
                row.enddate=myPeriod.enddate;
                row.servicepermonth = typeof row.servicepermonth==="undefined"?8:row.servicepermonth
                row.action=true;
                ls.push(row);
            }
            setSiteInfo(ls);
            setIsSync(true);
            console.log(`):(X -> period `, period)
        }
    }, [])

    const goNext = () => {
        if (step === stepTotal) {
            return
        }
        setStep(step + 1)
    }
    const goBack = () => {
        if (step === 1) {
            return
        }
        setStep(step - 1)
    }
    const StepCommand = () => {
        return (
            <div style={styles.CommandStepBox}>
                <div style={styles.CommandBtnInnerBox}>
                    {step > 1 ?
                        <Button
                            style={{...styles.CommandBtn, border: "1px solid red", color: "red"}}
                            onClick={goBack}
                        >
                            Back
                        </Button> : null
                    }
                </div>
                <label>Step: {step}/{stepTotal}</label>
                <div style={styles.CommandBtnInnerBox}>
                    {step < stepTotal ?
                        <Button
                            style={{...styles.CommandBtn, border: "1px solid blue", color: "blue"}}
                            onClick={goNext}
                        >
                            Next
                        </Button> : null
                    }
                </div>
            </div>
        )
    }
    const myInput = (site, value, fieldName,index) => {
        return (
            <Input
                style={styles.input}
                defaultValue={value}
                onBlur={(e) => onChangeInput(e.target.value, site, value, fieldName,index)}
            />
        )
    }
    const myInputDate =(site, value, fieldName, onChange,index)=>{

        return(
            <DatePicker
                format="YYYY-MM-DD"
                onChange={(date, dateString) => onChangeInput(dateString, site, value, fieldName,index)}
                defaultValue={convertStringDateIntoTime(value)}
                style={{minWidth: "100%"}}
            />
        )
    }
    const Step1RenderListHeading = () => {
        return (
            <Row style={styles.row}>
                <Col span={2} style={styles.colHeader}>
                    #
                </Col>
                <Col span={5} style={styles.colHeader}>Site</Col>
                <Col span={3} style={styles.colHeader}>Total Units</Col>
                <Col span={3} style={styles.colHeader}>Rate</Col>
                <Col span={3} style={styles.colHeader}>Max(s)</Col>
                <Col span={4} style={styles.colHeader}>StartDate</Col>
                <Col span={4} style={styles.colHeader}>EndDate</Col>
            </Row>
        )
    }
    const Step2RenderListHeading = () => {
        return (
            <Row style={styles.row}>
                <Col span={6} style={styles.colHeader2}>Site</Col>
                <Col span={2} style={styles.colHeader2}>Mon</Col>
                <Col span={2} style={styles.colHeader2}>Tue</Col>
                <Col span={2} style={styles.colHeader2}>Wed</Col>
                <Col span={2} style={styles.colHeader2}>Thu</Col>
                <Col span={2} style={styles.colHeader2}>Fri</Col>
                <Col span={2} style={styles.colHeader2}>Sat</Col>
                <Col span={2} style={styles.colHeader2}>Sun</Col>
            </Row>
        )
    }

    const Step1RenderListBody = () => {

        const myPeriod = JSON.parse(period)
        return SiteInfos.map((row,index) => {
            return (
                <Row style={styles.row}>
                    <Col span={2} style={styles.colBody}>
                        <Checkbox
                            type={"checkbox"}
                            style={styles.checkbox}
                            onChange={(e)=>onCheck(e,row.site)}
                            checked={isChecked(row.site)}
                        />
                    </Col>
                    <Col span={5} style={styles.colBody}>{row.site}</Col>
                    <Col span={3} style={styles.colBody}>{myInput(row.site, row.quantity, "quantity",index)}</Col>
                    <Col span={3} style={styles.colBody}>{myInput(row.site, row.rate, "rate",index)}</Col>
                    <Col span={3} style={styles.colBody}>{myInput(row.site, row.servicepermonth, "servicepermonth",index)}</Col>
                    <Col span={4} style={styles.colBody}>{myInputDate(row.site,row.startdate,"startdate",index)}</Col>
                    <Col span={4} style={styles.colBody}>{myInputDate(row.site,row.enddate,"enddate",index)}</Col>
                </Row>
            )
        })

    }
    const Step2RenderListBody = () => {
        let myCheckbox=(site,fieldName)=>{
            return(
                <Checkbox
                    type={"checkbox"}
                    style={styles.checkbox}
                    onChange={(e)=>onCheck2(e,site,fieldName)}
                    checked={isChecked2(site,fieldName)}
                />
            )
        }
        return SiteInfos.map((row,index) => {
            if(!row.action)return null;
            return (
                <Row style={styles.row}>
                    <Col span={6} style={styles.colBody2}>{row.site}</Col>
                    <Col span={2} style={styles.colBody2}>{myCheckbox(row.site, "mon")}</Col>
                    <Col span={2} style={styles.colBody2}>{myCheckbox(row.site, "tue")}</Col>
                    <Col span={2} style={styles.colBody2}>{myCheckbox(row.site, "wed")}</Col>
                    <Col span={2} style={styles.colBody2}>{myCheckbox(row.site, "thu")}</Col>
                    <Col span={2} style={styles.colBody2}>{myCheckbox(row.site, "fri")}</Col>
                    <Col span={2} style={styles.colBody2}>{myCheckbox(row.site, "sat")}</Col>
                    <Col span={2} style={styles.colBody2}>{myCheckbox(row.site, "sun")}</Col>
                </Row>
            )
        })

    }

    return (
        <Drawer
            title={"Invoice Adhoc Stellenbosch"}
            width={720}
            onClose={onClose}
            visible={visible}
            bodyStyle={{paddingBottom: 80, overflow: "scroll"}}
            footer={
                <div
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={onClose} style={{marginRight: 8}}>
                        Cancel
                    </Button>
                    {step === stepTotal ?
                        <Button onClick={()=>onSubmit(SiteInfos)} type="primary">
                            Submit
                        </Button>:null}
                </div>
            }
        >
            <StepCommand/>

            {step===1?
                <div>

                    <Step1RenderListHeading/>
                    <Step1RenderListBody/>
                </div>:null}

            {step===2?
                <div>
                    <Step2RenderListHeading/>
                    <Step2RenderListBody/>
                </div>:null}
        </Drawer>
    )
}

const styles = {
    checkbox: {
        maxWidth: 20,
        minWidth: 20,
        minHeight: 20,
        maxHeight: 20,
    },
    input: {
        maxWidth: 50,
        minWidth: 50,
        minHeight: 25,
        maxHeight: 25,
        margin: 10
    },
    colHeader: {
        color: "blue",
        fontSize: 16,
        fontWeight: "bold",
        display: 'flex',
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column"
    },
    colHeader2: {
        color: "blue",
        fontSize: 16,
        fontWeight: "bold",
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    colBody: {
        color: "gray",
        fontSize: 14,
        fontWeight: 500,
        display: 'flex',
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        textAlign: "left"
    },
    colBody2: {
        color: "gray",
        fontSize: 14,
        fontWeight: 500,
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        textAlign: "left"
    },
    row: {
        borderBottom: "1px solid red",
        marginBottom: 10,
        display: 'flex',
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row"
    },

    stepBox: {
        minWidth: "100%",
        maxWidth: "100%",
        minHeight: 300,
    },
    CommandBtnInnerBox: {
        minWidth: 150,
        maxWidth: 150,
        minHeight: 50,
        maxHeight: 50,
    },
    CommandBtn: {
        minWidth: 100,
        maxWidth: 100,
        minHeight: 25,
        maxHeight: 25,
        border: "1px solid red",
        borderRadius: 25,
        color: "gray",
        fontSize: 18,
        /*fontWeight: "bold",*/
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flex: 1
    },
    CommandStepBox: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        minWidth: "100%",
        maxWidth: "100%",
        minHeight: 50,
        maxHeight: 50,
    }
}