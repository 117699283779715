import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {Alert, Form, Select,Input,Button,Row,Col} from "antd";
import {fetchDataEntity, fetchDataEntityWithConditions} from "../../../api/entity-data";
import {GetUserToken} from "../../../api/token";
import TableDataView from "../../../components/Common/tables/TableDataView";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import {
    attachImageProfile,
    buildAssociationData,
    findRecordDisplayKey, findRecordDisplayKeyX, findRecordKey, findRecordKeyField,
    findRecordType,
    findRecordUniqueKey,
    SortData, UpcaseFirst
} from "../../../api/fn";
import PopupMe from "../../../components/Common/PopupMe";
import UploadDocument from "../../../components/web/Document/UploadDocument";
import PopupDownloadFile from "../../../components/web/Crm/PopupDownloadFile";
import ChooseCategoryModal from "../../../components/web/Crm/ChooseCategoryModal";
import {SubmitPost} from "../../../components/web/Common/localFunctions";
import {PostToPbx} from "../../../api/http-post";
import ModelSimple from "../../../components/Common/popup-modal/PopupDetail";
import {DrawerEditForm} from "../../../components/Common/DrawerEditForm";

const {Option} = Select;


class DatabaseSettingRecordPage extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            Companies:[],
            Entities:[],
            Attributes:[],
            Databases:[],
            ListFilter: [],
            Files: [],
            Associations: [],
            Logins:[],
            selectedCompany:"",
            selectedModule:"",
            selectedRecord:{},
            DataView: {},
            Data:[],
            isLoading:false,
            selectViewData: {},
            component:"list",
            fileName:"",
            fileNameUpload:"",

            showViewDetail: false,
            showUpload: false,
            showEditFormType: true,
            showDownloadFile: false,
            showDetail: false,
            showChooseCategory:false,
            showEditForm:false,
        }
    }
    componentDidMount=async () =>{
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.initialReload()
    }
    initialReload=async()=>{
        await fetchDataEntity("Entities", "", "", this,undefined,"common/entity/modules/list");
        await fetchDataEntity("Attributes", "", "", this,undefined,"common/entity/attributes/list");
        await fetchDataEntity("Databases", "", "", this,undefined,"common/entity/databases/list");
        await fetchDataEntity("Files", "", "", this,undefined,"common/entity/files/list");

    }
    loadInitialData = async () => {
        await fetchDataEntity("Databases", "", "", this,undefined,"common/entity/databases/list");
    }
    fetchDataByFilter=async ()=>{
        let conditions=[
            {key:"Org",val:this.state.Org},
            {key:"module",val:this.state.selectedModule}
        ]
        await fetchDataEntityWithConditions("Logins", "", "", this,conditions,"mobile/user/list");
    }
    getListField = () => {
        let ls = [];
        let store = this.state.Attributes;
        store = SortData("position", store)
        for (let i in store) {
            const row = store[i];
            if (row.org !== this.state.Org) {
                continue
            }
            if (row.module !== this.state.selectedModule) {
                continue
            }

            let type = "text";
            let options = [];
            if (row.datatype === "string") {
                type = "text";
            }
            if (row.datatype === "boolean") {
                type = "radio";
                options = [true, false];
            }
            if (row.datatype === "list") {
                type = "option";
                for (let i in row.options) {
                    options.push({
                        key: i,
                        val: row.options[i],
                    })
                }
            }
            if (row.datatype === "link") {
                type = "option";
                for (let e in this.state.Databases) {
                    const record = this.state.Databases[e];
                    if(record.org!==this.state.Org){continue}
                    if(record.module!==row.linkentity){continue}
                    options.push({
                        key: record.ref,
                        val:record.ref,
                    })
                }
            }

            let my = {
                field: row.fieldname,
                label: row.fieldname.toLocaleUpperCase(),
                options: options,
                type: type,
                required: row.mandatory,
                dataType: row.datatype,
                position: row.position,
            }
            ls.push(my);

        }
        ls = SortData("position",ls);
        return ls;
    }
    handleChange = async (e, key) => {
        const value = e.value;
        this.setState({
            [key]: value,
        })

        if(key==="selectedModule"&&this.state.Org!==""){
            await this.fetchDataByFilter()
        }

    }
    handleChange2 = async (e, key) => {
        const value = e;
        this.setState({
            [key]: value,
        })

    }
    handInput = (e) => {
        let key = e.target.name;
        let val = e.target.value;
        this.setState({
            [key]: val
        })
    }
    handleInputFileChooseCategory = async (key, e) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
    }
    closeChooseFileCategory = (record) => {
        if (this.state.fileCategory === "") {
            return alert("Sorry can't continue,please choose your file category!")
        }
        if (this.state.fileCategory !== "profile") {
            if (this.state.fileName === "") {
                return alert("Please provide the file name before continue!")
            }
        }
        this.setState({
            showChooseCategory: false,
            showUpload: true,
            selectedRecord: record,
            fileName: "",
            fileBase64: "",
        });
        //this.handleOpenUpload()

    }
    cancelChooseFileCategory = () => {
        this.setState({
            showChooseCategory: false,
            showUpload: false,
            selectedRecord: null,
            fileCategory: "",
            fileName: "",
            fileBase64: "",
        });
    }
    saveProfileFile = async (info) => {
        if (typeof info === "undefined") {
            return
        }
        const user = GetUserToken();
        let hub = {};
        hub.Org = this.state.Org;
        hub.Module =this.state.selectedModule;
        hub.Ref = this.state.selectedRecord.ref;
        hub.Category = info.category;
        hub.Type = info.category;
        hub.Name =  this.state.fileNameUpload;
        hub.Username = user.Username;
        hub.Filename = info.filename;
        hub.Base64String = info.base64string;
        const _this = this;
        let endpoint = "/files/upload/new";
        this.setState({isLoading: true});
        await SubmitPost(hub, endpoint, "Thank your your record have been saved!", this)
        _this.cancelChooseFileCategory();
    }
    openDetail = (row) => {
        this.setState({
            showViewDetail: true,
            selectedRecord: row,
        });
    }
    getListDisplayField = (targetField) => {
        let ls = [];
        let attributes = this.state.Attributes;
        attributes = SortData("position", attributes);
        for (let i in attributes) {
            const row = attributes[i];
            if (row.org !== this.state.Org) {
                continue
            }
            if (row.module !== this.state.selectedModule) {
                continue
            }
            if (row[targetField]) {
                ls.push(row)
            }
        }
        ls = SortData("position", ls);
        return ls
    }
    requestLoginFromMobile=async (rec,state)=>{
        let _this = this;
        let action = "logout";
        if(state==="logout"){
            action="login"
        }
        let msg= "Are you sure that you want to create "+action+" mobile for employee ref:"+rec.ref+
            "\nProject: "+rec.module+" ? ";
        if(!window.confirm(msg)){
            return
        }
        _this.setState({
            isLoading: true,
        })
        let hub={
            Org    :rec.org,
            Module :rec.module,
            Ref    :rec.ref,
            Data   :rec.data,
            State:state==="logout"?"login":"logout"
        }

        let endpoint = "/mobile/user/new"

        await PostToPbx(hub, endpoint, async (data) => {
            alert("Thank you "+action+" crated!")
            await _this.fetchDataByFilter();
        });

    }
    buildDataView = () => {
        const associationsData = buildAssociationData(this, this, "displaytable");
        let columns = [
            {id: 'mobile', label: '', minWidth: 170, dataType: "string"},
            {id: 'link', label: 'Profile Pic.', minWidth: 170, dataType: "string"},
        ];

        /**
         *
         * Let add our display field
         */
        const attributes = this.getListDisplayField("displaytable");
        for (let i in attributes) {
            const row = attributes[i];
            if (row.org !== this.state.Org) {
                continue
            }
            if (row.module !== this.state.selectedModule) {
                continue
            }
            const fieldname = row.fieldname;
            columns.push({
                id: fieldname, label: fieldname, minWidth: 170, dataType: "string"
            })
        }

        /**
         * let add association columns
         */
        let assCol = {};
        for (let i in associationsData) {
            const row = associationsData[i];
            if (row.MainEntity === this.state.selectedModule) {
                assCol[row.SupplierEntity] = true;
            }
        }
        for (let i in assCol) {
            columns.push({
                id: i, label: i, minWidth: 170, dataType: "string"
            })
        }
        /**
         * Let add our display fields data
         *
         */
        const addFiledDisplayData = (inData) => {
            for (let i in attributes) {
                const row = attributes[i];
                const fieldname = row.fieldname;

                inData[fieldname] = inData.data[fieldname]
            }
            return inData
        }

        let ls = [];
        const databases = this.state.Databases;
        for (let i in databases) {
            const row = databases[i];
            if (row.org !== this.state.Org) {
                continue
            }
            if (row.module === this.state.selectedModule) {

                const rowOut = addFiledDisplayData(row);

                ls.push(rowOut)
            }
        }

        let lsFiles=[];
        for(let i in this.state.Files){
            const row=this.state.Files[i];
            if(row.org!==this.state.Org){
                continue
            }
            lsFiles.push(row)
        }
        ls = attachImageProfile(lsFiles, ls);

        /**
         *
         * let me add our associate data
         */
        let tmpLst = [];
        for (let i in ls) {
            let rowRecord = ls[i];
            const ref = rowRecord.ref;
            for (let i in associationsData) {
                const row = associationsData[i];
                if (row.MainEntity === this.state.selectedModule) {
                    if (row.Main === ref) {
                        rowRecord[row.SupplierEntity] = row.SupplierName;
                    }
                }
            }
            tmpLst.push(rowRecord)
        }

        /**
         * let me clocking mobile allowed
         */
        const isOnMobile=(ref)=>{
            for(let i in this.state.Logins){
                const row=this.state.Logins[i];
                if(row.Ref===ref){
                    return true
                }
            }
            return false
        }
        for(let i in ls){
            let rec  = ls[i];
            // phonelink_erase ->
            let state = "logout";
            let color="red";
            let icon = "phonelink_lock";
            if(isOnMobile(rec.ref)){
                state = "login"
            }
            if(state!=="logout"){
                 color="green";
                 icon = "phonelink_ring";
            }
            rec.mobile = (
                <a

                    onClick={()=>this.requestLoginFromMobile(rec,state)}
                >
                <i
                className="large material-icons"
                style={{color:color}}

            >{icon}</i>
                </a>
            )
        }

        ls = tmpLst;

        return {columns: columns, data: ls}
    }
    openDownload = (row) => {
        let ls = [];
        for (let i in this.state.Files) {
            const o = this.state.Files[i];
            if (o.ref === row.Ref) {
                ls.push(o);
            }
        }
        this.setState({
            showDownloadFile: true,
            downloadFileList: ls,
            selectedRecord: row,
        })

    }
    closeDownload = () => {
        this.setState({
            showDownloadFile: false,
            downloadFileList: [],
        })
    }
    onDeleteView = async (row) => {
        let _this=this;
        const reloadMe=()=>{
            window.location.reload()
        }
        if (!window.confirm(`Are you sure that you want to edit this record? ${row["ref"]}`)) {
            return
        }

        console.log("onDeleteView --> ",row);

        let hub={
            Org:row["org"],
            Module: row["module"],
            Ref: row["ref"],
        }

        let endpoint ="/mobile/delete/employee/supervisor";


        await PostToPbx(hub, endpoint, async (data) => {
            alert("Thank you record deleted!");
            window.location.reload()
        })
    }
    onAddRecord = () => {
        this.setState({
            component: "form"
        })
    }
    onSubmitRecord = async () => {
        if (!window.confirm("Are you sure to submit this record!")) {
            return
        }
        let _this = this;
        let fieldsList = this.getListField();
        let fieldsListOriginal=[];
        let ls = [];
        for (let i in this.state.Attributes) {
            const row = this.state.Attributes[i];
            if (row.org !== this.state.Org) {
                continue
            }
            if (row.module !== this.state.selectedModule) {
                continue
            }
            fieldsListOriginal.push(row);
        }

        let o = {};
        for (let i in fieldsList) {
            const row = fieldsList[i];
            const key = row.field;
            let val = this.state[key];
            const type = findRecordType(key, fieldsList);
            if (type === "float") {
                val = parseFloat(val)
            } else if (type === "number") {
                val = parseInt(val)
            } else if (type === "boolean") {
                val = val === "yes"
            } else {
                //todo do nothing
            }

            if(typeof val ==="undefined"){
                val = ""
            }
            o[key] = val;
        }

        const fRecordKeyField=(targetField)=>{
            let returnValue="";
            for(let i in fieldsListOriginal) {
                let row = fieldsListOriginal[i];
                if(row[targetField]){
                    return row.fieldname;
                }
            }
            return returnValue;
        }
        const fRecordKeyTypeValue=(keyType)=>{
            let returnValue="";
            for(let i in fieldsListOriginal){
                let row = fieldsListOriginal[i];
                if(row[keyType]){
                    let innerVal = o[row.fieldname];
                    if(typeof innerVal==="undefined"){
                        innerVal = ""
                    }
                    returnValue = `${returnValue} ${innerVal}`;
                }
            }
            returnValue = returnValue.trim();
            return returnValue
        }


        delete o.mobile;
        delete o.tableData;
        let hub = {
            Org: this.state.Org,
            module: _this.state.selectedModule,
            Data: o,
            Ref: "",//findRecordUniqueKey(fieldsListOriginal, _this),
            names: fRecordKeyTypeValue( "displaydelection"),
            Email: fRecordKeyTypeValue( "email"),
            Phone: fRecordKeyTypeValue( "phone"),
            Username: fRecordKeyTypeValue( "username"),
            Name: fRecordKeyTypeValue( "name"),
            Surname: fRecordKeyTypeValue( "surname"),
            Password: fRecordKeyTypeValue( "password"),
            UniqueKey: fRecordKeyField("unique")
        }


        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = hub;
        post.Params = {}
        let endpoint ="/mobile/onboard/employee/supervisor" // "/common/entity/databases/insert"; // "/manager/entity/insert/extensions";

        // let endpoint =this.props.postEndpoint; // "/manager/entity/insert/extensions";
        this.setState({
            isLoading: true,
        })
        await SubmitPost(hub, endpoint, "Thank your your record have been saved!", this)
    }
    openUpload = (row) => {
        this.setState({
            showChooseCategory: true,
            showUpload: false,
            selectedRecord: row,
        })
    }
    closeDetail = () => {
        this.setState({
            showViewDetail: false,
            selectViewData: null,
            selectViewTitle: null
        });
    }
    renderList = () => {



        if (this.state.component === "form" || this.state.selectedModule === "" || this.state.Org === "") {
            return null
        }
        for(let i in this.state.Databases){
            const row=this.state.Databases[i];
            if(row.org===this.state.Org){
                if(row.module==="Employee"){

                }
            }
        }
        let data = this.buildDataView();
        let otherFunctions=[
            /*https://materializecss.com/icons.html*/
            /*{
                icon:"phonelink_lock",
                isFreeAction:false,
                tooltip:"Suspend Client",
                myAction:this.activateClient,
                color:"primary"
            }*/
        ]
        return (
            <TableDataView
                {...data}
                tableTitle={UpcaseFirst(this.state.selectedModule) + " Records  (" + data.data.length + ") "}
                onAddRecord={this.onAddRecord}
                onDelete={this.onDeleteView}
                openUpload={this.openUpload}
                openDetail={this.openDetail}
                closeDownload={this.closeDownload}
                openDownload={this.openDownload}
                otherFunctions={otherFunctions}
                options={{
                    pageSize:20,
                }}
            />
        )
    }
    renderNewForm = () => {
        if (this.state.component === "list") {
            return null
        }
        let inputList = [];
        let fieldsStore = this.getListField();
        for (let i in fieldsStore) {
            const row = fieldsStore[i];
            inputList.push({
                name: row.field,
                label: row.field.toUpperCase(),
                required: row.required,
                message: "Please provide your " + row.field,
                type: row.type,
                StateKey: row.field,
                options: row.options
            })
        }

        const layout = {
            labelCol: {span: 12},
            wrapperCol: {span: 12},
        };
        const tailLayout = {
            wrapperCol: {offset: 8, span: 15},
        };

        return (
            <Form
                {...layout}
                onSubmit={this.onSubmitRecord}
                className="login-form"
                onFinish={this.onSubmitRecord}
                name="basic"
                initialValues={{remember: true}}
                style={{minWidth: "100%"}}
            >
                <Alert style={{margin: '16px 0'}} message={"New Record Form: "+this.state.selectedModule}/>

                {inputList.map((row) => {
                    if (row.type === "option" || row.type === "boolean") {
                        return (
                            <Form.Item
                                {...tailLayout}
                                label={row.label}
                                name={row.name}
                                rules={[{required: row.required, message: row.message}]}
                            >
                                <Select
                                    name={row.name}
                                    defaultValue={this.state[row.name]}
                                    /*style={{ width: 120 }}*/
                                    value={this.state[row.name]}
                                    onChange={(e) => this.handleChange2(e, row.name)}
                                >
                                    {row.options.map((record, index) => {
                                        return (
                                            <Option value={record.key}>{record.val}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        )
                    }
                    return (
                        <Form.Item
                            {...tailLayout}
                            label={row.label}
                            name={row.name}
                            rules={[{required: row.required, message: row.message}]}
                        >
                            <Input
                                defaultValue={this.state[row.name]}
                                value={this.state[row.name]}
                                name={row.name}
                                onChange={this.handInput}
                                type={row.type}
                                /*prefix={<LockOutlined type={row.prefix} style={{fontSize: 13}}/>}*/
                            />
                        </Form.Item>
                    )
                })}

                <Form.Item {...tailLayout}>
                    <Button
                        {...tailLayout}
                        type="secondary"
                        htmlType="submit"
                        style={{marginRight: 20, float: "right", marginLeft: 10}}
                        onClick={() => this.setState({component: "list"})}
                    >
                        Cancel
                    </Button>

                    <Button type="primary" htmlType="submit" style={{float: "right", marginLeft: 10}}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }
    renderCompaniesComboxbox = () => {
        let user = GetUserToken();
        let ls = [];
        for (let i in this.state.Companies) {
            const row = this.state.Companies[i];
            if (user.Role === "super") {
                ls.push({
                    key: row.CustomerNumber,
                    val: row.Name,
                })
            } else {
                if (row.CustomerNumber === user.Org) {
                    ls.push({
                        key: row.CustomerNumber,
                        val: row.Name,
                    })
                }
            }


        }
        return (
            <Select
                style={{minWidth:"100%"}}
                name={"Org"}
                defaultValue=""
                onChange={(e, ee) => this.handleChange(ee, "Org")}
            >
                {ls.map((record, index) => {
                    return (
                        <Option value={record.key}>{record.val}</Option>
                    )
                })}
            </Select>
        )
    }
    renderModules = () => {
        let ls = [];
        let org = this.state.Org;
        for (let i in this.state.Entities) {
            const row = this.state.Entities[i];

            if (row.org === org) {
                ls.push({
                    key: row.name,
                    val: row.name,
                })
            }
        }
        return (
            <Select
                style={{minWidth:"100%"}}
                name={"selectedModule"}
                defaultValue=""
                onChange={(e, ee) => this.handleChange(ee, "selectedModule")}
            >
                {ls.map((record, index) => {
                    return (
                        <Option value={record.key}>{record.val}</Option>
                    )
                })}
            </Select>
        )
    }
    renderChooseFileCategory = () => {
        if (!this.state.showChooseCategory) {
            return null
        }
        let record = this.state.selectedRecord;

        return (
            <ChooseCategoryModal
                status={this.state.showChooseCategory}
                record={record}
                onChangeCategory={this.handleInputFileChooseCategory}
                handleClose={this.closeChooseFileCategory}
                onCancel={this.cancelChooseFileCategory}
                openUpload={this.openUpload}
                _this={this}
            />
        )
    }
    requestEditRecord=()=>{
        this.setState({
            showViewDetail:false,
            showEditForm:true,
        })
    }
    renderEditForm=()=>{
        if(!this.state.showEditForm){
            return null;
        }
        return(
            <DrawerEditForm
                _this={this}
                title={"Edit Database Record"}
                visibleKey={"showEditForm"}
            />
        )
    }
    renderViewDetail = () => {

        let info = this.state.selectedRecord.data;
        return (
            <ModelSimple
                data={info}
                open={this.state.showViewDetail}
                entity={this.state.selectedModule}
                title={"Data view: "+this.state.selectedRecord.ref}
                ref={this.state.selectedRecord.ref}
                onClose={() => this.closeDetail()}
                includeHeader={true}
                link={this.state.selectedRecord.link}
                requestEditRecord={this.requestEditRecord}
            />
        )
    }
    renderWarningBar=()=>{
        return
        let boo=false
        if(this.state.selectedCompany === "" || this.state.selectedEntity === "" ||this.state.component==="form"){
            boo=true
        }
        if(!boo){
            return
        }
        return(
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Alert style={{ margin: '16px 0' }} message="Please select your company above to view your records!" type={"error"}/>
            </div>
        )
    }
    render() {
        let props = this.props;
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Database Records</Title>
                    <Row gutter={16} style={{marginTop: 20}}>
                        <Col span={12}>
                            <Form.Item
                                label={"Company"}
                            >
                                {this.renderCompaniesComboxbox()}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={"Entity"}
                            >
                                {this.renderModules()}
                            </Form.Item>
                        </Col>
                        {/*<Col span={1}>
                            <Button onClick={()=>this.fetchDataByFilter()}>
                                Fetch Data
                            </Button>
                        </Col>*/}
                    </Row>

                    {this.renderWarningBar()}
                    {this.renderList()}
                    {this.renderNewForm()}
                    {this.renderViewDetail()}
                    {this.renderEditForm()}
                </div>
                {this.renderChooseFileCategory()}
                <UploadDocument
                    open={this.state.showUpload}
                    handleClose={this.handleCloseUpload}
                    selectedRecord={this.state.selectedRecord}
                    uploadCategory={this.state.fileCategory}
                    handleSave={this.saveProfileFile}
                    module={this.state.selectedModule}
                    filesLimit={1}
                    submitFile={this.saveProfileFile}
                />
                <PopupDownloadFile
                    popTitle={"Download File"}
                    _this={this}
                    popStatus={this.state.showDownloadFile}
                    popFnClose={this.closeDownload}
                    record={this.state.selectedRecord}
                    fileList={this.state.downloadFileList}
                    tableRef={this.selectedModule}
                />
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }
}

export default Connector(DatabaseSettingRecordPage);

const styles = {
    container:{

    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
