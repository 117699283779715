import React from "react";
import {Drawer, Form, Button, Col, Row, Input, Select, DatePicker} from 'antd';
import moment from "moment";
import "./index.css";

const {Option} = Select;


const isEditable=(val)=>{
    if(typeof val==="undefined"){
        return false
    }
    if (val) {
        return true
    } else {
        return false
    }
}
const makeCol = (row, handleInput, getHandleInput, modeForm) => {
    const onChangeDate = (date, dateString, fieldName) => {
        handleInput(dateString, fieldName)
    }
    if (row === null) {
        return null;
    }
    if (row.datatype === "option") {
        let options = [];
        for (let i in row.options) {
            const rec = row.options[i];
            const key = rec.key;
            const val = rec.val;
            options.push({
                key: key,
                val: val,
            })
        }
        return (
            <Col span={12}>
                <Form.Item
                    name={row.field}
                    label={row.label}
                    rules={[{required: row.mandatory, message: 'Please enter user ' + row.field}]}
                >
                    <select
                        placeholder={"Please enter user " + row.field}
                        onChange={(e) => handleInput(e.target.value, row.field)}
                        value={getHandleInput(row.field)}
                        required={row.mandatory}
                        className={"own-input"}
                        disabled={isEditable(row.editKey)}
                    >
                        <option value={""}/>
                        {options.map(rec => {
                            let isSelected = false
                            if(getHandleInput(row.field)===rec.key){
                                isSelected=true
                            }
                            return (
                                <option value={rec.key} selected={isSelected}>{rec.val}</option>
                            )
                        })}
                    </select>
                </Form.Item>
            </Col>
        )
    }
    if (row.datatype === "file") {
        let fName=`fileID-${row.field}`;
        const handleInputFile = (e) => {
            let inputUpload = document.getElementById(fName);
            let myfile = inputUpload.files[0];
            if (!myfile) {
                console.log("No file selected.");
                return;
            }
            let reader = new FileReader();
            reader.readAsDataURL(myfile);
            reader.onload = function () {
                handleInput({
                    base64String:reader.result,
                    filename:myfile.name
                }, row.field)
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }

        return (
            <Col span={12}>
                <Form.Item
                    name={row.field}
                    label={row.label}
                    rules={[{required: true, message: 'Please enter user ' + row.field}]}
                >
                    <Input type={"file"} id={fName} onChange={handleInputFile} disabled={isEditable(row.editKey)}/>
                </Form.Item>
            </Col>
        )
    }
    if (row.datatype === "date") {
        let myDefaultDate = moment();
        let dt = getHandleInput(row.field);
        let mode = modeForm;
        if (mode === "edit" && dt !== "") {
            myDefaultDate = moment(getHandleInput(row.field), "YYYY-MM-DD")
        }

        return (
            <Col span={12}>
                <Form.Item
                    name={row.field}
                    label={row.label}
                    rules={[{required: true, message: 'Please enter user ' + row.field}]}
                >
                    <DatePicker
                        format="YYYY-MM-DD"
                        onChange={(date, dateString) => onChangeDate(date, dateString, row.field)}
                        defaultValue={myDefaultDate}
                        style={{minWidth: "100%"}}
                        disabled={isEditable(row.editKey)}
                    />
                </Form.Item>
            </Col>
        )
    }
    return (
        <Col span={12}>
            <Form.Item
                name={row.field}
                label={row.label}
                rules={[{required: true, message: 'Please enter user ' + row.field}]}
            >
                <Input
                    placeholder={"Please enter user " + row.label}
                    onChange={(e) => handleInput( e.target.value,row.field)}
                    value={getHandleInput(row.field)}
                    defaultValue={getHandleInput(row.field)}
                    required={row.mandatory}
                    disabled={isEditable(row.editKey)}
                />
            </Form.Item>
        </Col>
    )
}

export default ({
                    _this,
                    onSubmitRecord,
                    attributes,
                    visible,
                    title,
                    handleInput,
                    getHandleInput,
                    modeForm,
                    closeDrawer
                }) => {


    //let attributes = SampleAttributes();

    let ls = [];
    let loopIndex = 0;
    for (let i = 0; i < attributes.length; i += 2) {
        loopIndex = i;
        const row = attributes[i];
        let row2 = null;
        let nextIndex = i + 1;
        if (nextIndex < (attributes.length)) {
            row2 = attributes[nextIndex]
        }
        ls.push(
            <Row gutter={16}>
                {makeCol(row, handleInput, getHandleInput, modeForm)}
                {makeCol(row2, handleInput, getHandleInput, modeForm)}
            </Row>
        );
    }

    const onClose = () => {
        closeDrawer();
    }
    const onSubmit = async () => {
        //alert("onsubmit record request")
        await onSubmitRecord();
    }
    return (
        <>
            <Drawer
                title={title}
                width={720}
                onClose={onClose}
                visible={visible}
                bodyStyle={{paddingBottom: 80, overflow: "scroll"}}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={onClose} style={{marginRight: 8}}>
                            Cancel
                        </Button>
                        <Button onClick={onSubmit} type="primary">
                            Submit
                        </Button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    {ls.map(row => {
                        return row;
                    })}
                </Form>
            </Drawer>
        </>
    );
}
