import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { PageHeader} from 'antd';

export default function Title(props) {
    let {color,style}=props;
    if(typeof color ==="undefined"){
        color="primary"
    }
    if(typeof style ==="undefined"){
        style={}
    }
    return (
        <div style={{
            minWidth:"100%",
            ...style
        }}>
            <PageHeader
                className="site-page-header"

                title={props.children}
                subTitle=""
                style={{border: "1px solid rgb(235, 237, 240)"}}
            />

       {/* <Typography
            component="h2"
            variant="h6"
            color={color}
            style={{...style,color:"gray"}}
            gutterBottom
        >
            {props.children}
        </Typography>
        <hr style={{color:"gray"}}/>*/}
        </div>
    );
}

Title.propTypes = {
    children: PropTypes.node,
};
