import React from "react";
import {fetchDataEntity} from "../../../api/entity-data";
import ControllerSelectBox from "../../../components/Common/ControllerInput/ControllerSelectBox";
import {ArrayToSelectOption, FilterArrayByKey,  UpcaseFirst} from "../../../api/fn";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {PostToPbx} from "../../../api/http-post";
import {Button, Col, Row} from "antd";
import TableDataView from "../../../components/Common/tables/TableDataView";

class PayrollWageDefineRatePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Attributes: [],
            Projects: [],
            Periods: [],
            DataDesignation: [],
            Data:[],

            selectedCompany: "",
            selectedProject: "",
            selectedCategory: "",
            selectedDesignation: "",
            newEntry:{
                Mon:0,
                Tue:0,
                Wed:0,
                Thu:0,
                Fri:0,
                Sat:0,
                Sun:0,
                Ph:0,
            },

            mode: "list",
        }

    }
    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Projects", "", "", this, undefined, "common/entity/modules/list");
        await fetchDataEntity("Data", "", "", this, undefined, "common/entity/DefineRate/list");
        await fetchDataEntity("DataDesignation", "", "", this, undefined, "common/entity/terms-designation/list");
    }
    handleChangeInput = async (key, value) => {
        console.log("handleChangeInput > ", key, " > ", value)
        this.setState({
            [key]: value,
        })
    }
    handleGetInputValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return ""
        }
        return this.state[key];
    }
    onAddRecord=()=>{
        let state = this.state;
        if(state.selectedCompany===""){
            return alert("Please select your company!")
        }
        if(state.selectedProject===""){
            return alert("Please select your project!")
        }
        if(state.selectedCategory===""){
            return alert("Please select your charge type!")
        }

        this.setState({mode:"form"})
    }
    submitRecord = async () => {
        let state = this.state;
        let entry = state.newEntry;
        if(state.selectedDesignation===""){
            return alert("Please select your designation type!")
        }
        if(!window.confirm("Are you sure to submit this record?")){
            return
        }
        let hub = {};
        hub.Org = state.selectedCompany;
        hub.Project = state.selectedProject;
        hub.Designation = state.selectedDesignation;
        hub.Category = state.selectedCategory;
        hub.Mon = parseFloat(entry.Mon);
        hub.Tue = parseFloat(entry.Tue);
        hub.Wed = parseFloat(entry.Wed);
        hub.Thu = parseFloat(entry.Thu);
        hub.Fri = parseFloat(entry.Fri);
        hub.Sat = parseFloat(entry.Sat);
        hub.Sun = parseFloat(entry.Sun);
        hub.Ph = parseFloat(entry.Ph);

        let postData = {
            Data:hub,
        }
        console.log("submitDeduction send > ", hub)
        let endpoint = "/common/entity/DefineRate/insert"
        await PostToPbx(postData, endpoint, async (output) => {
            let data = output.RESULT;
            console.log("submitRecord response > ", data)
            await fetchDataEntity("Data", "", "", this, undefined, "common/entity/DefineRate/list");
            this.setState({mode:"list"})
            alert("Thank you, yours Define rate have been recorded!")
        });
    }
    renderCompanyBox = () => {
        let ls = [];
        ls = FilterArrayByKey(this.state.Companies, {})
        ls = ArrayToSelectOption(ls, ["CustomerNumber"], ["Name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Company"}
                name={"selectedCompany"}
            />
        )
    }
    renderProjectBox = () => {
        let ls = [];
        for (let i in this.state.Projects) {
            let row = this.state.Projects[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            ls.push(row)
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["name"], ["name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Project"}
                name={"selectedProject"}
            />
        )
    }
    renderCategory = () => {
        let ls = [
            {key: "client", val: "Client"},
            {key: "employee", val: "employee"}
        ]
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Charge Type"}
                name={"selectedCategory"}
            />
        )
    }
    renderDesignationBox = () => {
        let ls = [];

        for (let i in this.state.DataDesignation) {
            let row = this.state.DataDesignation[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.module !== "contract") {
                continue
            }
            if (row.ref !== this.state.selectedProject) {
                continue
            }
            ls.push(row)
        }
        ls = FilterArrayByKey(ls, {})
        ls = ArrayToSelectOption(ls, ["name"], ["name"])
        return (
            <ControllerSelectBox
                handleInput2={this.handleChangeInput}
                getHandleValue={this.handleGetInputValue}
                ls={ls}
                label={"Designation"}
                name={"selectedDesignation"}
            />
        )
    }

    renderList = () => {
        if(this.state.mode!=="list"){
            return null
        }
        let columns = [
            {id: 'designation', label: 'Designation', minWidth: 170, dataType: "string"},
            {id: 'category', label: 'Category', minWidth: 170, dataType: "string"},
            {id: 'mon', label: 'Mon', minWidth: 170, dataType: "string"},
            {id: 'tue', label: 'Tue', minWidth: 170, dataType: "string"},
            {id: 'wed', label: 'Wed', minWidth: 170, dataType: "string"},
            {id: 'thu', label: 'Thu', minWidth: 170, dataType: "string"},
            {id: 'fri', label: 'Fri', minWidth: 170, dataType: "string"},
            {id: 'sat', label: 'Sat', minWidth: 170, dataType: "string"},
            {id: 'sun', label: 'Sun', minWidth: 170, dataType: "string"},
            {id: 'ph', label: 'Ph', minWidth: 170, dataType: "string"},
        ];


        let ls=[];
        for(let i in this.state.Data){
            let row= this.state.Data[i];
            if(row.org!==this.state.selectedCompany){continue}
            if(row.project!==this.state.selectedProject){continue}
            ls.push(row)
        }
        let otherFunctions=[
            /*https://materializecss.com/icons.html*/
            /*{
                icon:"phonelink_lock",
                isFreeAction:false,
                tooltip:"Suspend Client",
                myAction:this.activateClient,
                color:"primary"
            }*/
        ]
        let data  = {columns: columns, data: ls}
        return (
            <div>
                <h4 style={{color: "blue",}}>
                    <u>DEFINE RATE LIST</u>
                </h4>
                <Row gutter={18}>
                    <Col span={24}>
                        <TableDataView
                            {...data}
                            tableTitle={UpcaseFirst(this.state.selectedProject) + " Records  (" + data.data.length + ") "}
                            onAddRecord={this.onAddRecord}
                            onDelete={this.onDeleteView}
                            openUpload={this.openUpload}
                            openDetail={this.openDetail}
                            closeDownload={this.closeDownload}
                            openDownload={this.openDownload}
                            otherFunctions={otherFunctions}
                            options={{
                                pageSize:20,
                            }}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
    renderForm = () => {
        if(this.state.mode!=="form"){
            return null
        }
        let entry = this.state.newEntry;
        const onChange=(key,val)=>{
            let tmp = this.state.newEntry;
            tmp[key] = val;
            this.setState({newEntry:tmp})
        }
        const innerRender=(label,value,key)=>{
            return(
                <Row gutter={18} style={styles.rowForm}>
                    <Col span={6}>
                        {label} Rate
                    </Col>
                    <Col span={6}>
                        <input
                            className={"input"}
                            value={value}
                            onChange={(e)=>onChange(key,e.target.value)}
                        />
                    </Col>
                </Row>
            )
        }
        return (
            <div>
                <h4 style={{color: "blue",}}>
                    <u>NEW DEFINE RATE</u>
                </h4>
                <Row gutter={18}>
                    <Col span={12}>
                        {this.renderDesignationBox()}
                    </Col>
                </Row>
                {innerRender("Monday",entry.Mon,"Mon")}
                {innerRender("Tuesday",entry.Tue,"Tue")}
                {innerRender("Wednesday",entry.Wed,"Wed")}
                {innerRender("Thursday",entry.Thu,"Thu")}
                {innerRender("Friday",entry.Fri,"Fri")}
                {innerRender("Saturday",entry.Sat,"Sat")}
                {innerRender("Sunday",entry.Sun,"Sun")}
                {innerRender("Public Holiday",entry.Ph,"Ph")}
                <Row gutter={18}>
                    <Col span={24}>
                        <Button
                            type={"primary"}
                            style={{
                                minWidth: "100%",
                                marginTop: 30,
                                minHeight: 40,
                                borderRadius: 15,
                                fontSize: 18,
                                fontWeight: "bold"
                            }}
                            onClick={() => this.submitRecord()}
                        >Add Rate</Button>
                    </Col>
                </Row>
            </div>

        )
    }
    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>DEFINE RATE</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <Row gutter={18}>
                            <Col span={12}>
                                {this.renderCompanyBox()}
                            </Col>
                            <Col span={12}>
                                {this.renderProjectBox()}
                            </Col>
                        </Row>
                        <Row gutter={18}>
                            <Col span={24}>
                                {this.renderCategory()}
                            </Col>
                        </Row>
                        <div style={{
                            borderRadius: 5,
                            borderWidth: 1,
                            borderColor: "lightblue",
                            borderStyle: 'solid',
                            minHeight: 35,
                            padding: 5
                        }}>

                            {this.renderList()}
                            {this.renderForm()}
                        </div>

                    </div>
                </div>
            </Page>
        )
    }
}

export default Connector(PayrollWageDefineRatePage);
const styles = {
    rowForm:{
      marginBottom:10,
        paddingBottom:5,
        border:"0px solid lightgray",
        borderBottomWidth: 1,

    },
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}