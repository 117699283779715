import React from "react";
import PageBuilder from "../../../components/web/Common/PageBuilder";
import Connector from "../../../redux/connector";
import {EntitiesOptions} from "../../../constants";
import { PlayCircleOutlined } from '@ant-design/icons';
import {PostToPbx} from "../../../api/http-post";

class SuperCompanyOnboardPage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {}
    }
    activateClient=async (row)=>{
        if(!window.confirm("Are you sure you want to suspend this client? "+row.Name+" ["+row.CustomerNumber+"]")){
            return
        }
        /*let hub={
            CustomerNumber:row.CustomerNumber
        };
        let endpoint = "/client/action/activate"; // "/manager/entity/insert/extensions";

        this.setState({
            isLoading: true,
        })

        await PostToPbx(hub, endpoint, async (data) => {
            alert("Thank you client activate! you see him on the menu [Activate company]")
            window.location.reload()
        });*/

        console.log("Client activate function!")
    }
    render() {
        let StateStorage = [
            /*{stateField: "Companies", moduleEntity: "client/entity", table: "company", noCondition: false},*/
            {stateField: "Clients", moduleEntity: "client/entity", table: "company", noCondition: false}
        ];
        let displayListColumns = [
            {id: "CustomerNumber", label: "CLient Code"},
            {id: "Name", label: "Client Name"},
            {id: "ContactName", label: "Contact Person"},
            {id: "ContactNumber", label: "Contact Phone"},
            {id: "ContactEmail", label: "Contact Email"},
        ]
        let translateFields = [
            /*{
                keyName: "Client",
                keySearch: "CustomerNumber",
                keyValue: "org",
                keyReturn: "Name",
                stateEntity: "Companies"
            }*/
        ];
        let entityOptions = [];

        let bldOptions = [        ];


        let bldFields = [
            {name: "Name",StateKey:"Name", label: "Name of Company (Trading name)", type: "text",required:true, message:"Please input your company name"},
            {name: "ContactName",StateKey:"ContactName", label: "Contact Person", type: "text",required:true, message:"Please input your contact name"},
            {name: "ContactNumber",StateKey:"ContactNumber", label: "Contact Number", type: "text",required:true, message:"Please input your contact number"},
            {name: "ContactEmail",StateKey:"ContactEmail", label: "ContactEmail", type: "email",required:true, message:"Please input your email"},
            {name: "Address",StateKey:"Address", label: "Address", type: "text",required:true,message:"Please input your address"},
            {name: "Province",StateKey:"Province", label: "Province", type: "option",required:true,message:"Please input your Province",options:[
                    {key:"Western cape",val:"Western cape"},
                    {key:"Guateng",val:"Guateng"},
                ]},
            {name: "City", StateKey:"City",label: "City", type: "option",required:true,message:"Please input your city",options:[
                    {key:"Cape town",val:"Cape town"},
                    {key:"JHB",val:"JHB"},
                ]},
        ]

        /*
        icon: row.icon,
                tooltip: row.tooltip,
                isFreeAction: row.isFreeAction,
                onClick: (event, rowData) => row.myAction(prepareData(rowData)),
         */


        let otherFunctions=[
            /*https://materializecss.com/icons.html*/
            {
                icon:"close",
                isFreeAction:false,
                tooltip:"Suspend Client",
                myAction:this.activateClient,
                color:"primary"
            }
        ]

        return (
            <PageBuilder
                pageTitle={"Active Client"}
                StateStorage={StateStorage}
                displayListColumns={displayListColumns}
                displayTableKeyState={"Clients"}
                hasTranslateField={false}
                translateFields={translateFields}
                entityOptions={entityOptions}
                bldOptions={[]}
                bldFields={bldFields}
                newFormTitle={"New CLient"}
                postMsgResponse={"Thank you, client submitted!"}
                postMsgWarning={"Are you sure you want to submit this new client?"}
                postEndpoint={"/client/entity/insert/company-activate"}
                listKeyToLower={false}

                AutoGenerate={false}
                HasUniqueKey={false}
                AutoGenerateField={""}
                ParamsDelete={[
                    {key:"Org",type:"string"},
                    {key:"Name",type:"string"},
                ]}
                includeSelection={false}
                allowedDelete={false}
                allowedAdd={false}
                injectFunctionAllowed={false}
                injectFunctionIcon={<PlayCircleOutlined />}
                injectFunctionAction={this.activateClient}
                injectFunctionTitle={"Activate"}
                injectFunctionTitleStyle={{color:"blue"}}
                injectFunctionKey={"Activate"}
                otherFunctions={otherFunctions}
            />
        )
    }

}

export default Connector(SuperCompanyOnboardPage);

