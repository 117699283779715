import React from "react";
import Page from "../../../layout/web/Page";
import {fetchDataEntity} from "../../../api/entity-data";
import {PostToPbx} from "../../../api/http-post";
import {Alert, Button, Col, DatePicker, Form, Row, Select} from "antd";
import Title from "../../../components/Common/Title";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import DashboardWagePayroll from "../../../components/Reports/DashboardWagePayroll";
import Connector from "../../../redux/connector";
import moment from "moment";

const {Option} = Select;

function convertDateFormat_DDMMYYYY(dateIn) {
    return moment(dateIn, "YYYY-MM-DD").format("DD MMM YYYY");
}

const initialResult={
    TotalHeadCount      :0,
    ActiveHeadCount     :0,
    TerminatedHeadCount :0,
    Female              :0,
    Male                :0,
    Designations        :0,

    ClockedToday      :0,
    NotClockedToday   :0,
    ClockedPeriod     :0,
    UnClockedPeriod   :0,
    SiteWithUnClocked :0,
    ClocksUnApproved  :0,
    TotalSite         :0,

    NoCertifiedCopyOfId  :0,
    NoTimesheet          :0,
    NoEmploymentContract :0,

    Summary :{
        GrossPay  :0,
        Deduction :0,
        UIF       :0,
        Paye      :0,
        NetPay    :0,
    }
}

class DashboardWagePayrollContractPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Org: "",
            Companies: [],
            Entities: [],

            selectedCompany: "",
            selectedModule: "",
            selectedRecord: {},
            selectedPeriod: "",
            selectedSite: "",

            StartDate: "",
            EndDate: "",

            Data: [],
            isLoading: false,
            component: "list",
            initLoading: false,

            DataPeriod: [],
            DataPayCycle: [],
            DataSite: [],

            Results: initialResult,
            showViewDetail: false,
        }

    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.initialReload()
    }
    initialReload = async () => {
        await fetchDataEntity("Entities", "", "", this, undefined, "common/entity/modules/list");
    }
    loadContractInfo = async (selectedModule) => {
        let _this = this;
        let module = this.state.selectedModule;
        let hub = {
            Org: this.state.selectedCompany,
            Module: "contract",
            Ref: selectedModule,
        }

        console.log("loadContractInfo > ", hub)
        let endpoint = "/payroll/contract/fetch-data"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            let tmp = _this.state;
            tmp.DataPeriod = data.TermsPeriod;
            tmp.DataDesignation = data.TermsDesignation;
            tmp.DataPayCycle = data.TermsPayCycle;
            tmp.DataSite = data.TermsSite;
            tmp.DataRate = data.TermsRate;
            tmp.DataRateSpecific = data.TermsRateSpecific;
            tmp.DataSlot = data.TermsSlot;
            tmp.DataSiteDesignation = data.TermsSiteDesignation;
            tmp.DataShift = data.TermsShift;
            tmp.DataCondition = data.TermsCondition;
            _this.setState(tmp);

            console.log("loadInitialData > ", output, " > ", data)
        });
    }
    handleChangeCompany = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    handleChange = async (e, key) => {
        const value = e.value;
        this.setState({
            [key]: value,
        });

        if (key === "selectedModule") {
            console.log("selected module > ", key, value)
            await this.loadContractInfo(value)
        }

    }
    handleChange2 = (e, ee, key) => {
        const val = e;
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
    }
    validateSubmitSearch = () => {
        const state = this.state;
        if (state.selectedCompany === "") {
            alert("Validation fail! Please select your company")
            return false
        }
        if (state.selectedModule === "") {
            alert("Validation fail! Please select your project")
            return false
        }

        return true
    }
    submitSearch = async () => {
        this.setState({
            isLoading: true,
            Results: null,
        });

        if (!this.validateSubmitSearch()) {
            return
        }

        let hub = {
            Org: this.state.selectedCompany,
            Project: this.state.selectedModule,
            StartDate: this.state.StartDate,
            EndDate: this.state.EndDate,
            HasFinance:true,
        }

        console.log("1 -Data--> ", hub)
        let endpoint = "/wage/dashboard/contract/summary"
        await PostToPbx(hub, endpoint, async (output) => {
            let data = output.RESULT;
            this.setState({
                Results: data,
                isLoading: false,
            });
            console.log("-search Data--> ", data)
        });
    }
    renderWarningBar = () => {
        return
        let boo = false
        if (this.state.selectedCompany === "" || this.state.selectedEntity === "" || this.state.component === "form") {
            boo = true
        }
        if (!boo) {
            return
        }
        return (
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Alert style={{margin: '16px 0'}} message="Please select your company above to view your records!"
                       type={"error"}/>
            </div>
        )
    }
    renderCompaniesSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={""}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedCompany")}
                    value={this.state.selectedCompany}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderModules = () => {
        let ls = [];
        let org = this.state.selectedCompany;
        for (let i in this.state.Entities) {
            const row = this.state.Entities[i];

            if (row.org === org) {
                ls.push({
                    key: row.name,
                    val: row.name,
                })
            }
        }
        return (
            <Select
                name={"selectedModule"}
                defaultValue=""
                onChange={(e, ee) => this.handleChange(ee, "selectedModule")}
            >
                {ls.map((record, index) => {
                    return (
                        <Option value={record.key}>{record.val}</Option>
                    )
                })}
            </Select>
        )
    }
    formatDate = (dateIn) => {
        return convertDateFormat_DDMMYYYY(dateIn)
    }

    renderDateSelector = ({label, stateKey}) => {
        let _this = this;
        const onSelectDate = (dateString) => {
            let tmp = _this.state;
            tmp[stateKey] = dateString
            _this.setState(tmp)
        }
        return (
            <Col span={12}>
                <Form.Item
                    name={"" + label}
                    label={label}
                    rules={[{required: true, message: 'Please select date: ' + label}]}
                >
                    <DatePicker
                        onChange={(date, dateString) => onSelectDate(dateString)}
                        style={{
                            minWidth:"100%"
                        }}/>
                </Form.Item>
            </Col>
        )
    }

    render() {
        let props = this.props;
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Dashboard Payroll</Title>
                    <Row gutter={16} style={{marginTop: 20}}>
                        <Col span={12}>
                            <Form.Item
                                label={"Company"}
                            >
                                {this.renderCompaniesSelection()}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={"Project"}
                            >
                                {this.renderModules()}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{marginTop:-20}}>
                        {this.renderDateSelector({stateKey: "StartDate", label: "Start Date"})}
                        {this.renderDateSelector({stateKey: "EndDate", label: "End Date"})}
                    </Row>

                    <Row gutter={16} style={{marginTop: -10}}>
                        <Col span={24}>
                            <Button style={{minWidth: "100%"}} danger onClick={() => this.submitSearch()}>Search
                            </Button>&nbsp;&nbsp;
                        </Col>
                    </Row>

                    {this.state.Results !== null ?
                        <Row gutter={24} style={{marginTop: 40}}>
                            <DashboardWagePayroll
                                result={this.state.Results}
                            />
                        </Row> : null
                    }
                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }

}

export default Connector(DashboardWagePayrollContractPage);

const styles = {}