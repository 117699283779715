import React from "react";

import {Steps, Button, message, Alert} from 'antd';
import AttributeStepPosition from "./AttributeStepPosition";
import AttributeStepInitial from "./AttributeStepInitial";
import AttributeStepOther from "./AttributeStepOther";
import {
    ArrayFieldContact, ArrayFieldGeolocation, ArrayFieldIdentity,
    ArrayFieldLogin, ArrayFieldName, ArrayFieldTitle, ArrayFieldVehicle
} from "./InitialField.js";
import {SubmitPost} from "../Common/localFunctions";
import AttributeStepSubmit from "./AttributeStepSubmit";

const {Step} = Steps;


export class NewAttributeFormStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            initialFields: [],
            otherFields: [],
            newFieldOther: "",
        }
    }

    componentDidMount = () => {
        let _this = this.props._this;
        console.log("NewAttributeFormStep selectedRecord :> ", _this.state.selectedRecord);
        this.loadInitialField()
    }
    handleInput = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    loadInitialData=async ()=>{
        this.props._this.setState({
            component: "list",
            selectedRecord: {}
        })
    }
    initialReload=()=>{
        const rec = this.props._this.state.selectedRecord;
        let ls = [];
        const addFields = (rows) => {
            for (let i in rows) {
                let row = rows[i];
                row.Org = rec.org;
                row.Module = rec.name;
                row.Position = 0;
                row.Unique = false;
                row.Mandatory = false;
                row.Required = false;
                row.DisplayTable = false;
                row.DisplaySelection = false;
                row.Options = "";
                row.LinkEntity = "";
                /*row.DataType="string"*/
                ls.push(row)
            }
        }
        if (rec.hasname) {
            addFields(ArrayFieldName)
        }
        if (rec.hasidentity) {
            addFields(ArrayFieldIdentity)
        }
        if (rec.hastitle) {
            addFields(ArrayFieldTitle)
        }
        if (rec.haslogin) {
            addFields(ArrayFieldLogin)
        }
        if (rec.hasgeolocation) {
            addFields(ArrayFieldGeolocation)
        }
        if (rec.hascontact) {
            addFields(ArrayFieldContact)
        }

        this.setState({
            initialFields: ls
        })
    }
    loadInitialField = () => {
        const rec = this.props._this.state.selectedRecord;
        let ls = [];
        const addFields = (rows) => {
            for (let i in rows) {
                let row = rows[i];
                row.Org = rec.org;
                row.Module = rec.name;
                row.Position = 0;
                row.Unique = false;
                row.Mandatory = false;
                row.Required = false;
                row.DisplayTable = false;
                row.DisplaySelection = false;
                row.Options = "";
                row.LinkEntity = "";
                /*row.DataType="string"*/
                ls.push(row)
            }
        }
        if (rec.hasname) {
            addFields(ArrayFieldName)
        }
        if (rec.hasidentity) {
            addFields(ArrayFieldIdentity)
        }
        if (rec.hastitle) {
            addFields(ArrayFieldTitle)
        }
        if (rec.haslogin) {
            addFields(ArrayFieldLogin)
        }
        if (rec.hasgeolocation) {
            addFields(ArrayFieldGeolocation)
        }
        if (rec.hascontact) {
            addFields(ArrayFieldContact)
        }

        this.setState({
            initialFields: ls
        })
    }


    next() {
        const current = this.state.current + 1;
        this.setState({current});
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({current});
    }

    getSteps = () => {

        return [
            /*{
                title: 'Pre-Populating Fields',
                content: <AttributeStepInitial _this={this}/>,
            },*/
            {
                title: 'Field Heading',
                content: <AttributeStepOther _this={this}/>,
            },
            {
                title: 'Field Definition',
                content: <AttributeStepPosition _this={this}/>,
            },
            {
                title: 'Submit',
                content: <AttributeStepSubmit _this={this}/>,
            },
        ];
    }


    submitRecord = async () => {
        // attributes-bulk
        let _this = this;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";

        let endpoint = "/common/entity/attributes-bulk/insert"; // "/manager/entity/insert/extensions";
        this.setState({
            isLoading: true,
        });
        const buildOptions = (strIn) => {
            strIn  = strIn.toString();
            const arr = strIn.split(",");
            let ls = {};
            for (let i in arr) {
                const item = arr[i];
                ls[item] = item
            }
            return ls
        }
        let data = [];
        let haveUniqueKey = false;
        for (let i in this.state.initialFields) {
            const row = this.state.initialFields[i];
            data.push(row);
            if (row.Unique) {
                haveUniqueKey = true
            }
            row.Position = parseInt(row.Position)
            if (row.Options === "") {
                row.Options = {}
            } else {
                row.Options = buildOptions(row.Options)
            }
        }
        for (let i in this.state.otherFields) {
            const row = this.state.otherFields[i];
            data.push(row);
            if (row.Unique) {
                haveUniqueKey = true
            }
            row.Position = parseInt(row.Position);
            if (row.Options === "") {
                row.Options = {}
            } else {
                row.Options = buildOptions(row.Options)
            }
        }

        if (!haveUniqueKey) {
            return alert("Sorry please provide your Unique key!")
        }
        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        post.Data = data;
        post.Params = {}

        console.log("PageBuilder post > ", post);

        await SubmitPost(post, endpoint, "Thank you , attributes submitted", this, async (dIn) => {
            _this.props._this.setState({
                component: "list",
                selectedRecord: {}
            })
            _this.setState({
                isLoading: false,
            });
            await _this.initialReload();
            // alert("Thank you , attributes submitted")
        })
    }

    selectionInfo=()=>{
        const entity = this.props._this.state.selectedEntity;
        let company ="";
        const clientCode = this.props._this.state.selectedCompany;
        for(let i in this.props._this.state.Companies){
            const row=this.props._this.state.Companies[i];
            if(row.ClientNumber===clientCode){
                company = row.Name;
            }
        }
        return{
            entity,company
        }
    }

    render() {
        let {current} = this.state;
        let steps = this.getSteps();
        let {company ,entity}= this.selectionInfo();

        return (
            <div style={styles.container}>
                <div style={{minWidth: "100%", color: "red"}}>
                    <Alert style={{margin: '16px 0'}}
                           message={"New Structure | Database: "+entity+" | company: "+company}
                           type={"info"}
                    />

                </div>
                <Steps current={current}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title}/>
                    ))}
                </Steps>
                <div style={styles.stepsContent}>{steps[current].content}</div>
                <div style={styles.stepsAction}>
                    <Button
                        type="secondary"
                        onClick={() => this.props._this.setState({
                            component: "list",
                            selectedRecord: {}
                        })}
                        style={{backgroundColor: "red", color: "white", marginRight: 5}}
                    >
                        Cancel
                    </Button>
                    {current < steps.length - 1 && (
                        <Button type="primary" onClick={() => this.next()}>
                            Next
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button type="primary" onClick={async () => {
                            await this.submitRecord();
                            message.success('Processing complete!')
                        }}>
                            Done
                        </Button>
                    )}
                    {current > 0 && (
                        <Button style={{margin: '0 8px'}} onClick={() => this.prev()}>
                            Previous
                        </Button>
                    )}
                </div>
            </div>
        )
    }

}

const styles = {
    stepsContent: {
        marginTop: 16,
        border: '1px dashed #e9e9e9',
        borderRadius: 2,
        backgroundColor: '#fafafa',
        minHeight: 200,
        textAlign: "center",
        paddingTop: 80
    },
    stepsAction: {
        marginTop: 24
    },
    container: {
        padding: 10,
        minWidth: "100%",
        maxWidth: "100%",
        minHeight: 500,
        /*backgroundColor: "lightgray"*/
    }
}
