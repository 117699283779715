import React from "react";
import {Button, Drawer, Table} from 'antd';
import {Tabs, Tab} from 'material-ui/Tabs';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import moment from "moment";

export default (props) => {
    let {_this} = props;
    let {showViewBank,} = _this.state;
    let {data} = props;
    const onClose = () => {
        _this.setState({
            showViewBank: false,
        })
    }

    return (
        <Drawer
            title={"Export Csv Pastel"}
            width={720}
            onClose={onClose}
            visible={showViewBank}
            bodyStyle={{paddingBottom: 80}}
            footer={
                <div
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <MuiThemeProvider>
                        <Tabs>
                            <Tab label="FNB" className="tab-header">
                                <BuildTable data={data} _this={_this} />
                            </Tab>
                        </Tabs>
                    </MuiThemeProvider>

                    <Button onClick={onClose} style={{marginRight: 8}}>
                        Close
                    </Button>

                </div>
            }
        >

        </Drawer>
    )
}

const BuildTable = ({data, _this}) => {
    /*
    lsCsv.push({
            "RECIPIENT NAME":'',
            "RECIPIENT ACCOUNT":'',
            "RECIPIENT ACCOUNT TYPE":'',
            "BRANCHCODE":'',
            "AMOUNT":'',
            "OWN REFERENCE":'',
            "RECIPIENT REFERENCE":''
        })
     */
    let columns = [
        {title: "RECIPIENT NAME", dataIndex: "RECIPIENT NAME", align: 'left'},
        {title: "RECIPIENT ACCOUNT", dataIndex: "RECIPIENT ACCOUNT", align: 'left'},
        {title: "RECIPIENT ACCOUNT TYPE", dataIndex: "RECIPIENT ACCOUNT TYPE", align: 'center'},
        {title: "AMOUNT", dataIndex: "AMOUNT", align: 'right'},
        {title: "OWN REFERENCE", dataIndex: "OWN REFERENCE", align: 'left'},
        {title: "RECIPIENT REFERENCE", dataIndex: "RECIPIENT REFERENCE", align: 'left'},
    ];

    return (
        <div className="pastelDiv">
            <h3 className="pastelHeading">
                <span className="pastelTitle">FNB Payment </span>
                &nbsp;&nbsp;
                <span className="pastelTitleDetail"><small>(People=<b>{data.length}</b>)</small></span>
                &nbsp;
                {/*<span className="pastelTitleDetail"><small>(TotalDay=<b>{totalDay}</b>)</small></span>
                &nbsp;&nbsp;*/}

                <a onClick={() => (MyDownload(data, _this))} className="pastelDownloadBtn">
                    Download
                </a>

            </h3>
            <Table
                columns={columns}
                dataSource={data}
                bordered
            />
        </div>
    )
};

const MyDownload = (rows, _this) => {
    //const rows = [["name1", "city1", "some other info"], ["name2", "city2", "more info"]];
    let datas = [];
    datas.push(
        [_this.state.BankFileHeading,"", "", "", "", "",""],

    );
    datas.push([
        moment(_this.state.BankFoundReleaseDate, "YYYY-MM-DD").format("YYYY/MM/DD"),"", "", "", "", "",""
    ]);
    datas.push([_this.state.BankAccountFoundRelease,"", "", "", "", "",""]);
    datas.push([
        "RECIPIENT NAME",
        "RECIPIENT ACCOUNT",
        "RECIPIENT ACCOUNT TYPE",
        "BRANCHCODE",
        "AMOUNT",
        "OWN REFERENCE",
        "RECIPIENT REFERENCE"]);
    for (let i in rows) {
        const row = rows[i];
        datas.push([
            cleanVal(row["RECIPIENT NAME"]),
            cleanVal(row["RECIPIENT ACCOUNT"]),
            cleanVal(row["RECIPIENT ACCOUNT TYPE"]),
            cleanVal(row["BRANCHCODE"]),
            cleanVal(row["AMOUNT"]),
            cleanVal(row["OWN REFERENCE"]),
            cleanVal(row["RECIPIENT REFERENCE"])
        ]);
    }
    let csvContent = "data:text/csv;charset=utf-8,";
    datas.forEach(function (rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "FNB_Upload_" +
        moment(_this.state.BankFoundReleaseDate,"YYYY-MM-DD").format("DD MMM YYYY") + ".csv");
    link.innerHTML = "Click Here to download";
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
}

const cleanVal=(str)=>{
    str=str.toString().replace(`\r`,'');
    str=str.toString().replace(`\n`,'');
    return str
}