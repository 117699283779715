import { createStore,applyMiddleware} from 'redux';
import rootReducer from './index';
import logger from "redux-logger";
//import {EntityDataMiddleware,SaveGlobalMiddleware} from "./services";


const store = createStore(
    rootReducer,
    applyMiddleware(logger,/*EntityDataMiddleware,SaveGlobalMiddleware*/)
);


export default store;
