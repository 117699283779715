import React from "react";
import {Button, Drawer, Table} from 'antd';
import {Tabs, Tab} from 'material-ui/Tabs';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

export default (props)=> {
    let {showViewPastel, setShowViewPastel} =props;
    let {
        lsNormal ,
        lsNormalCsv ,
        lsSaturday ,
        lsSaturdayCsv ,
        lsSunday ,
        lsSundayCsv ,
        lsPhh ,
        lsPhhCsv ,
        lsBonus,
        lsBonusCsv,
        lsOT,
        lsOtCsv,
    }=props;

    const onClose = () => {
        setShowViewPastel(false)
    }


    const counterFunction=(ls)=>{
        let tot=0;
        for(let i in ls){
            const row=ls[i];
            tot +=row.Quantity;
        }
        return tot;
    }

    let totDayNormal=counterFunction(lsNormal),
        totDaySaturday=counterFunction(lsSaturday),
        totDaySunday=counterFunction(lsSunday),
        totDayPh=counterFunction(lsPhh),
        totHourOT=counterFunction(lsOT),
        totHourBonus=counterFunction(lsBonus)
    ;

    const cleanEmptyDaysWorked=(dataIn)=>{
        let rows=[];
        for(let i in dataIn){
            let row=dataIn[i];
            if(row.Days===0){
                continue
            }
            rows.push(row)
        }
        return rows;
    }

    lsNormalCsv = cleanEmptyDaysWorked(lsNormalCsv)
    lsSaturdayCsv = cleanEmptyDaysWorked(lsSaturdayCsv)
    lsSundayCsv = cleanEmptyDaysWorked(lsSundayCsv)
    lsPhhCsv = cleanEmptyDaysWorked(lsPhhCsv)
    lsBonusCsv = cleanEmptyDaysWorked(lsBonusCsv)
    lsOtCsv = cleanEmptyDaysWorked(lsOtCsv)

    console.log("lsOtCsv > ",lsOtCsv)

    return (
        <Drawer
            title={"Export Csv Pastel 2"}
            width={720}
            onClose={onClose}
            visible={showViewPastel}
            bodyStyle={{paddingBottom: 80}}
            footer={
                <div
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <MuiThemeProvider>
                        <Tabs>
                            <Tab label="Normal Day" className="tab-header">
                                <BuildTable data={lsNormal} dataCsv={lsNormalCsv} daytype={"Normal"} totalDay={totDayNormal}/>
                            </Tab>
                            <Tab label="Saturday">
                                <BuildTable data={lsSaturday} dataCsv={lsSaturdayCsv} daytype={"Saturday"}  totalDay={totDaySaturday}/>
                            </Tab>
                            <Tab label="Overtime">
                                <BuildTable data={lsOT} dataCsv={lsOtCsv} daytype={"Overtime"}  totalDay={totDaySaturday}/>
                            </Tab>
                            <Tab label="Sunday">
                                <BuildTable data={lsSunday} dataCsv={lsSundayCsv} daytype={"Sunday"}  totalDay={totDaySunday}/>
                            </Tab>
                            <Tab label="PBH W">
                                <BuildTable data={lsPhh} dataCsv={lsPhhCsv} daytype={"Public Holiday W"}  totalDay={totDayPh}/>
                            </Tab>
                            <Tab label="PBH T">
                                <BuildTable data={lsBonus} dataCsv={lsBonusCsv} daytype={"Public Holiday T"}  totalDay={totHourBonus}/>
                            </Tab>
                        </Tabs>
                    </MuiThemeProvider>

                    <Button onClick={onClose} style={{marginRight: 8}}>
                        Close
                    </Button>

                </div>
            }
        >

        </Drawer>
    )
}

const BuildTable = ({data,dataCsv,daytype,totalDay})=>{
    const headers = [{
        id: 'first',
        display: 'First column'
    }, {
        id: 'second',
        display: 'Second column'
    }];

    const rows = [{
        first: 'foo',
        second: 'bar'
    }, {
        first: 'foobar',
        second: 'foobar'
    }];


    let columns=[
        {title: "Code", dataIndex: "EmployeeCode", align: 'left'},
        {title: "Name", dataIndex: "Employee", align: 'left'},
        {title: "Trans.", dataIndex: "TransactionCode", align: 'center'},
        {title: "Cost", dataIndex: "CostCentre", align: 'right'},
        {title: "Quantity", dataIndex: "Quantity", align: 'center'},
        {title: "Rate", dataIndex: "Rate", align: 'right'},
        {title: "Amount", dataIndex: "NetPay", align: 'right'},
    ];

    let totalCost=0;
    let data2=[];
    let peoples=0;
    for(let i in data){
        let row=data[i];
        let qty = row.Quantity;
        if(qty===0){
            continue
        }
        let rate =parseFloat(row.Rate);
        let tot = qty *  rate;
        let row2 = {...row}
        row2.NetPay = tot.toFixed(2)
        data2.push(row2)
        totalCost +=tot;
        peoples++
    }

    return(
        <div className="pastelDiv">
            <h3 className="pastelHeading">
                <span className="pastelTitle">{daytype}</span>
                &nbsp;&nbsp;
                <span className="pastelTitleDetail"><small>(People=<b>{peoples}</b>)</small></span>
                &nbsp;
                <span className="pastelTitleDetail"><small>(Total Cost=R<b>{totalCost.toFixed(2)}</b>)</small></span>
                &nbsp;&nbsp;

                <a onClick={()=>(MyDownload(dataCsv,daytype))} className="pastelDownloadBtn">
                    Download
                </a>

            </h3>
            <Table
                columns={columns}
                dataSource={data2}
                bordered
            />

        </div>
    )
};

const MyDownload=(rows,daytype)=>{
    //const rows = [["name1", "city1", "some other info"], ["name2", "city2", "more info"]];
    let datas = [];
    for(let  i in rows){
        const row = rows[i];
        datas.push([
            row.Code,
            row.Tans,
            row.Blanc1,
            row.Blanc2,
            row.Days,
            row.Rate,
            row.Blanc3,
            row.Override
        ]);
    }
    let csvContent = "data:text/csv;charset=utf-8,";
    datas.forEach(function(rowArray){
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "pmis_pastel_"+daytype+".csv");
    link.innerHTML= "Click Here to download";
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
}
