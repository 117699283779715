import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import {
    GetMonthList,
    GetYearList,
    LoadDataCommon,
    BuilderOptionComboBox,
    CheckConditions,
    BuilderTableList
} from "../../../../api/fn";
import moment from "moment";
import Title from "../../../../components/Common/Title";
import {Col, Form, Row, Select, Divider, Table} from "antd";
import Page from "../../../../layout/web/Page";
import Connector from "../../../../redux/connector";
import DrawerNewEntry from "../../../../components/General/DrawerNewEntry";
import {UploadToDropBoxBase64WithBusket} from "../../../../services/dropbox-uploader";
import {PostToPbx} from "../../../../api/http-post";
import LoadingProcessing from "../../../../components/Common/page/Common/LoadingProcessing";
import DrawerEditEntry from "../../../../components/General/DrawerEditEntry";
import SwitchPeriodInvoice from "./SwitchPeriodInvoice2";
import {FindFinancialYearRange, GetFinancialYearRange, isDateBetween} from "../../../../api/date-functions";

const {Option} = Select;


const isEditable = (val) => {
    if (typeof val === "undefined") {
        return false
    }
    if (val) {
        return true
    } else {
        return false
    }
}

const isValidPeriod=({mode,invoicedate,mapMontIndex,monthIndex,targetYear,financeYearMonth})=>{
    let invoicedate2 =moment(invoicedate,"DD-MMM-YY").format("YYYY-MM-DD");
    let targetMonth = mapMontIndex(monthIndex);

    let arr = invoicedate2.split("-");
    if(mode==="month"){
        if (arr[0] === targetYear && arr[1] === targetMonth) {
            return true
        }
    }else if(mode==="calendar-year"){
        if (arr[0] === targetYear){
            return true

        }
    }else if(mode==="finance-year"){
        let fYear = FindFinancialYearRange(financeYearMonth,moment().format("YYYY-MM-DD"));
        if(isDateBetween(fYear.startDate,fYear.endDate,invoicedate2)){
            return true
        }
    }else if(mode==="all"){
        return true
    }
    return false
}


class InvoiceReportTrackerPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Clients: [],
            ItemsService: [],
            ItemsLabour: [],
            Data: [],
            isLoading: false,
            newEntry: {
                Year: moment().year()
            },
            selectedCompany: "",
            selectedClient: "",
            selectedItem: "",
            selectedYear: "",
            selectedMonth: "",
            selectedCategory: "",
            searchKey: "",
            SelectedRecord: null,
            showEdit: false,

            showForm: false,
            actionTable: "InvoiceTracker",

            targetYear:moment().format("YYYY"),
            monthIndex: parseInt(moment().format("MM")),
            mode:"all",
            financialYearMonth:"Mar",
            financialYearReferenceDate:moment().format("YYYY-MM-DD"),
            financeYearInfo:null
        }

        this.Data = [];
    }

    componentDidMount = async () => {
        await this.loadInitialData();
        this.Data = this.state.Data;
        this.setState({
            financeYearInfo:FindFinancialYearRange(this.state.financialYearMonth,this.state.financialYearReferenceDate)
        })
    }
    loadInitialData = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await LoadDataCommon("modules", "Modules", this);
        await LoadDataCommon("SettingInvoiceServiceDebtors", "Clients", this);
        await LoadDataCommon("SettingInvoiceServiceItem", "ItemsService", this);
        await LoadDataCommon("SettingInvoiceAddonLabourItems", "ItemsLabour", this);
        await LoadDataCommon("InvoiceTracker", "Data", this);
    }
    mapMontIndex = (value) => {
        let infos = {
            1: "01",
            2: "02",
            3: "03",
            4: "04",
            5: "05",
            6: "06",
            7: "07",
            8: "08",
            9: "09",
            10: "10",
            11: "11",
            12: "12"
        }
        let val = infos[value];
        return val;
    }
    checkIfValidPeriod=(invoicedate)=>{
        return isValidPeriod({
            mode: this.state.mode,
            invoicedate: invoicedate,
            mapMontIndex: this.mapMontIndex,
            monthIndex: this.state.monthIndex,
            targetYear:this.state.targetYear,
        });

    }
    closeDrawer = () => {
        this.setState({showForm: false, showEdit: false})
    }
    handleGeneralEntryInput = (val, key) => {
        let tmp = this.state;
        tmp.newEntry[key] = val;
        this.setState(tmp)
    }
    onChangePeriod = (value) => {
        this.setState({monthIndex: parseInt(value)})
    }
    handleInput = (val, key) => {
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp)
    }
    getHandleInput = (key) => {
        let tmp = this.state;
        if (typeof tmp.newEntry[key] === "undefined") {
            tmp.newEntry[key] = ""
        }
        return tmp.newEntry[key]
    }
    getFieldListNewForm=()=>{
        let infos=this.getFieldList();
        let ls=[];
        for(let i in infos){
            let row = infos[i];
            if(typeof row.newForm!=="undefined"){
                if(!row.newForm){continue}
            }
            ls.push(row);
        }
        return ls;
    }
    getFieldList = () => {
        return [
            {
                field: "inv#",
                datatype: "text",
                label: "Inv#",
                options: [],
                align: "left",
                mandatory: true,
                editKey: true,
                newForm:false,
            },
            {
                field: "po#",
                datatype: "text",
                label: "PO#",
                options: GetMonthList(),
                align: "left",
                mandatory: true,
                newForm:false,
                editKey: true
            },
            {
                field: "invoicenumber",
                datatype: "text",
                label: "Invoice Number",
                options: [],
                align: "left",
                mandatory: true,
                newForm:true,
                editKey: true
            },
            {
                field: "ponumber",
                datatype: "text",
                label: "PO Number",
                options: GetMonthList(),
                align: "left",
                mandatory: true,
                newForm:true,
                editKey: true
            },

            {
                field: "client",
                datatype: "text",
                label: "Client",
                options: [],
                align: "left",
                mandatory: true,
                newForm:false,
                editKey: true
            },
            {
                field: "item",
                datatype: "text",
                label: "Item",
                options: [],
                align: "left",
                mandatory: true,
                newForm:false,
                editKey: true
            },
            {
                field: "invoicedate",
                datatype: "date",
                label: "Invoice Date",
                options: [],
                align: "left",
                mandatory: true,
                editKey: true
            },
            {
                field: "invoiceamount",
                datatype: "float",
                label: "Invoice Amount",
                options: [],
                align: "right",
                mandatory: true,
                newForm:true,
                editKey: true
            },
            /*{field: "poexvat", datatype: "float", label: "PO Exl.Vat", options: [],align:"left",mandatory:true,editKey:true},
            {field: "povat", datatype: "float", label: "PO Vat", options: [],align:"right",mandatory:true,editKey:true},
            {field: "pototal", datatype: "float", label: "PO Total", options: [],align:"left",mandatory:true,editKey:true},*/
            {
                field: "duedate",
                datatype: "date",
                label: "Due Date",
                options: [],
                align: "left",
                mandatory: true,
                newForm:true,
                editKey: true
            },

            {
                field: "paid",
                datatype: "option",
                label: "Paid",
                options: [
                    {key: "yes", val: "Yes"},
                    {key: "no", val: "No"},
                ],
                align: "left",
                newForm:false,
                mandatory: true
            },
            {
                field: "paidamount",
                datatype: "float",
                label: "Paid Amount",
                options: [],
                align: "right",
                mandatory: true,
                newForm:false,
                editKey: false
            },

            {
                field: "category", datatype: "option", label: "Category", options: [
                    {key: "service", val: "Service"},
                    {key: "labour", val: "Labour"},
                ], align: "left", mandatory: true,editKey: true
            },

            {field: "datepaid", datatype: "date", label: "Date Paid", options: [], align: "left",newForm:true, mandatory: true},
            {
                field: "linkinvnumber",
                datatype: "file",
                label: "Upload Invoice",
                options: [],
                align: "left",
                mandatory: true,
                newForm:true,
                editKey: true
            },
            {
                field: "linkponumber",
                datatype: "file",
                label: "Upload PO Number",
                options: [],
                align: "left",
                mandatory: true,
                newForm:true,
                editKey: true
            },
            /* {field: "ponumber", datatype: "text", label: "PO Number", options: GetMonthList(),align:"left",mandatory:true,editKey:true},*/
        ]
    }
    onSubmitRecord = async () => {

        this.setState({
            isLoading: true,
        })
        const table = this.state.actionTable;
        const _this = this;
        let state = this.state;
        let fields = this.getFieldListNewForm()
        let record = {
            Org: state.selectedCompany,
            Client: state.selectedClient,
            Item: state.selectedItem
        };
        for (let i in fields) {
            let row = fields[i];

            if(typeof row.newForm!=="undefined"){
                if(!row.newForm){
                    continue
                }
            }

            let val = state.newEntry[row.field];
            if (row.datatype === "float") {
                val = val !== "" ? parseFloat(val) : 0
            } else if (row.datatype === "file") {
                /**
                 * upload to drop box
                 */
                let base64String = val.base64String;
                let fileName = val.filename;
                await UploadToDropBoxBase64WithBusket(
                    base64String,
                    "invoice-tracker",
                    state.selectedCompany,
                    state.selectedClient,
                    state.selectedItem,
                    state.newEntry["invoicenumber"],
                    fileName,
                    (revision) => {

                    }).then(revision => {
                    console.log("UploadToDropBoxBase64WithBusket revision > ", revision)
                    val = revision
                })
            }
            record[row.field] = val
        }

        record.terms = parseInt(record.terms);
        record.paid =false // record.paid === "yes"
        record.category = this.state.selectedCategory;
        record.paidamount =0 //record.invoiceamount;

        record.Org = state.selectedCompany;
        record.Client = state.selectedClient;
        record.Item = state.selectedItem;

        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = record;
        post.Params = {}
        let endpoint = "/common/entity/" + table + "/insert";

        await PostToPbx(post, endpoint, async (data) => {
            await LoadDataCommon(table, "Data", _this);
            _this.Data = _this.state.Data;
            _this.setState({isLoading: false, showForm: false, newEntry: {}});
            alert("thank you, tracker  submitted");
        });

        console.log("onSubmitRecord > ", record);

    }
    getItemData = () => {
        if (this.state.selectedCategory === "service") {
            return this.state.ItemsService;
        } else {
            return this.state.ItemsLabour
        }
    }
    onSubmitRecordEdit = async () => {
        const table = this.state.actionTable;
        const _this = this;
        let state = this.state;
        let record = this.state.SelectedRecord;
        let inputData = state.newEntry;
        let paidDate =record.paiddate;
        let paidAmount = record.paidamount;
        let paid = record.paid;
        console.log("inputData > ",inputData);

        if(typeof inputData.paidamount!=="undefined"){
            paidAmount = parseFloat(inputData.paidamount);
        }else{
            alert("Please provide your paid amount!")
            return
        }
        if(typeof inputData.datepaid!=="undefined"){
            paidDate = inputData.datepaid;
        }else{
            alert("Please provide your paid date!")
            return
        }
        if(typeof inputData.paid!=="undefined"){
            paid = inputData.paid==="yes";
        }

        let post={
            paidAmount,
            paidDate,
            paid,
            TargetInvoice: record
        }

        let endpoint = "/invoices/tracker/payment";
        this.setState({
            isLoading: true,
        })
        await PostToPbx(post, endpoint, async (data) => {
            console.log("onSubmitRecordEdit response: ",data)
            await LoadDataCommon(table, "Data", _this);
            _this.Data = _this.state.Data;
            _this.setState({isLoading: false, showForm: false, showEdit: false, newEntry: {}});
            alert("thank you, tracker  submitted");
        });

        console.log("onSubmitRecordEdit record post> ",record, post);

    }
    onEdit = async (record) => {
        console.log("Record in Edit: ", record.OriginalData);
        let info = {};
        for (let i in record.OriginalData) {
            let val = record.OriginalData[i];
            if (typeof val === "boolean") {
                val = val ? "yes" : "no"
            }
            info[i] = val
        }
        this.setState({newEntry: {...info}});
        this.setState({SelectedRecord: record.OriginalData});
        this.setState({invNumber: record.OriginalData.invNumber})
        this.setState({showEdit: true})
    }
    onDelete = async (dataIn, deleteLink, conditions) => {
        let record = dataIn.OriginalData;
        if (!window.confirm("Are you sure to delete?")) {
            return
        }
        console.log("onDelete > ", record)
        let _this = this;
        console.log("You accept to delete!")
        let endpoint = "/invoices/tracker/delete"; // "/manager/entity/insert/extensions";
        // let endpoint =this.props.postEndpoint; // "/manager/entity/insert/extensions";
        this.setState({
            isLoading: true,
        })
        await PostToPbx(record, endpoint, async (data) => {
            await LoadDataCommon(_this.state.actionTable, "Data", _this);
            this.setState({
                isLoading: false,
            })
        });
    }

    checkForNewEntry = () => {
        if (this.state.selectedCompany === "") {
            return false
        }
        if (this.state.selectedClient === "") {
            return false
        }
        if (this.state.selectedCategory === "") {
            return false
        }
        if (this.state.selectedItem === "") {
            return false
        }
        return true;
    }
    getData = () => {
        let ls = [];
        let allCompanyInvoice=[];
        let companiesMap={};
        for (let i in this.Data ) {
            let row = this.Data [i];

            /**
             * to delete
             * @type {boolean}
             */
            if(typeof companiesMap[row.org]==="undefined"){
                companiesMap[row.org] =0;
            }
            let counter = companiesMap[row.org];
            counter++;
            companiesMap[row.org] = counter;

            if(row.org!==this.state.selectedCompany){continue}
            allCompanyInvoice.push(row)
            if(row.invoicedate===""){continue}
            if(!this.checkIfValidPeriod(row.invoicedate)){continue}
            row["inv#"] = "inv#";
            row["po#"] = "po#";
            ls.push(row);
        }
        console.log("allCompanyInvoice > ",allCompanyInvoice," > ",this.state.selectedCompany," > ",companiesMap," > ALl: ",this.Data.length)
        return ls;
    }

    RenderList=()=>{

    }

    render() {
        console.log("this.state (--) ", this.state)
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Invoice Tracker History</Title>

                    <Divider style={{color: "red"}}>Filter</Divider>
                    <BuilderOptionComboBox
                        data={this.state.Companies}
                        keyVal={"CustomerNumber"}
                        keyLabel={"Name"}
                        label={"Select Organization"}
                        conditions={[]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedCompany"}
                        currentValue={this.state.selectedCompany}
                        comboId={"company"}
                    />
                    <BuilderOptionComboBox
                        data={this.state.Clients}
                        keyVal={"mame"}
                        keyLabel={"name"}
                        label={"Select Debtor"}
                        conditions={[
                            {key: "org", val: this.state.selectedCompany}
                        ]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedClient"}
                        currentValue={this.state.selectedClient}
                        comboId={"client"}
                    />
                    <BuilderOptionComboBox
                        data={[{key: "service", val: "Service Invoice"},
                            {key: "labour", val: "Labour Invoice"},]}
                        keyVal={"key"}
                        keyLabel={"val"}
                        label={"Select category of invoice"}
                        conditions={[]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedCategory"}
                        currentValue={this.state.selectedCategory}
                        comboId={"Category"}
                    />
                    <BuilderOptionComboBox
                        data={this.getItemData()}
                        keyVal={"name"}
                        keyLabel={"name"}
                        label={"Select Item | Job description"}
                        conditions={[
                            {key: "org", val: this.state.selectedCompany},
                            {key: "client", val: this.state.selectedClient},
                        ]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedItem"}
                        currentValue={this.state.selectedItem}
                        comboId={"item"}
                    />
                    <Divider orientation="right" style={{color: "#3880ff"}}>
                        {this.checkForNewEntry() ?
                            <a onClick={() => this.handleInput(true, "showForm")}>New Invoice Tracker</a>
                            : null
                        }
                    </Divider>

                    <Row>
                        <Col span={24}>
                            <SwitchPeriodInvoice
                                year={this.state.targetYear}
                                onChangePeriod={this.onChangePeriod}
                                handleInput={this.handleInput}
                                mode={this.state.mode}
                            />
                        </Col>
                    </Row>

                    {/*{BuilderTableList({
                        data:this.getData(),
                        fields:this.getFieldList(),
                        deleteAction:this.onDelete,
                        editAction:this.onEdit,
                        conditions:[
                                {key: "org", val: this.state.selectedCompany},
                        ],
                        deleteConditions:[
                    {key: "org", datatype: "string"},
                    {key: "client", datatype: "string"},
                    {key: "item", datatype: "string"},
                    {key: "invoicenumber", datatype: "string"},
                        ],
                        deleteLink:"year",
                        searchKey:this.state.searchKey,
                        AvoidDisplay:{"linkinvnumber": true, linkponumber: true},
                        MakeLinks:[
                    {target: "inv#", link: "linkinvnumber"},
                    {target: "po#", link: "linkponumber"},
                        ],
                    })}*/}

                    <BuilderTableList
                        data={this.getData()}
                        fields={this.getFieldList()}
                        deleteAction={this.onDelete}
                        editAction={this.onEdit}
                        conditions={[
                            {key: "org", val: this.state.selectedCompany},
                            /*{key: "client", val: this.state.selectedClient},
                            {key: "category", val: this.state.selectedCategory},
                            {key: "item", val: this.state.selectedItem},*/
                        ]}
                        deleteConditions={[
                            {key: "org", datatype: "string"},
                            {key: "client", datatype: "string"},
                            {key: "item", datatype: "string"},
                            {key: "invoicenumber", datatype: "string"},
                        ]}
                        deleteLink={"year"}
                        searchKey={this.state.searchKey}
                        AvoidDisplay={{"linkinvnumber": true, linkponumber: true}}
                        MakeLinks={[
                            {target: "inv#", link: "linkinvnumber"},
                            {target: "po#", link: "linkponumber"},
                        ]}

                    />
                </div>

                <DrawerNewEntry
                    _this={this}
                    attributes={this.getFieldListNewForm()}
                    visible={this.state.showForm}
                    onSubmitRecord={this.onSubmitRecord}
                    title={"New Purchase order"}
                    getHandleInput={this.getHandleInput}
                    handleInput={this.handleGeneralEntryInput}
                    modeForm={"new"}
                    closeDrawer={this.closeDrawer}
                />
                <DrawerEditEntry
                    _this={this}
                    attributes={this.getFieldList()}
                    visible={this.state.showEdit}
                    onSubmitRecord={this.onSubmitRecordEdit}
                    title={"Edit Tracker"}
                    getHandleInput={this.getHandleInput}
                    handleInput={this.handleGeneralEntryInput}
                    modeForm={"edit"}
                    closeDrawer={this.closeDrawer}
                />

                <Row>
                    <Col span={24}>
                        <LoadingProcessing open={this.state.isLoading}/>
                    </Col>
                </Row>
            </Page>
        )
    }


}


export default Connector(InvoiceReportTrackerPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
