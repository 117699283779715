
import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {Alert, Form, Layout, Select} from "antd";
import {fetchDataEntity} from "../../../api/entity-data";
import {GetUserToken} from "../../../api/token";
import {SortData, UpCaseFirst} from "../../../api/fn";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {NewAttributeFormStep} from "../../../components/web/Database/NewAttributeFormStep";
import DateSelectorBar from "../../../components/web/Clocking/DateSelectorBar";
import {StepBulkClockingIndex} from "../../../components/web/Clocking/StepBulkClockingIndex";
const {Content} = Layout;
const {Option} = Select;


class ClockingBulkPage extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            Companies:[],
            Entities:[],
            Databases:[],
            Attributes:[],
            selectedCompany:"",
            selectedEntity:"",
            selectedRecord:{},
            Data:[],
            isLoading:false,
            component:"list",
            newEntry:{},
            clockDate:"",

            DataPeriod: [],
            DataDesignation: [],
            DataSite: [],
            DataSiteDesignation: [],
            DataPayCycle: [],
            DataRate: [],
            DataRateSpecific:[],
            DataCondition: [],
            DataShift: [],
            selectedSite:"",
            selectedShift:""
        }
    }
    componentDidMount=async () =>{
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await fetchDataEntity("Entities", "", "", this,undefined,"common/entity/modules/list");
        await fetchDataEntity("Databases", "", "", this,undefined,"common/entity/databases/list");
        await fetchDataEntity("Attributes", "", "", this,undefined,"common/entity/attributes/list");
        await this.initialReload()
    }
    initialReload=async()=>{
        await fetchDataEntity("Data", "", "", this,undefined,"common/entity/clock-request/list")
    }
    onAddRecord=async ()=>{
        let record ={}
        for(let i in this.state.Entities){
            const row = this.state.Entities[i];
            console.log("@@@@---> ",this.state.selectedCompany,"!=",row.org," > ",this.state.selectedCompany!==row.org)
            if(this.state.selectedCompany!==row.org){
                continue
            }
            console.log("@@@@---> 2: ",this.state.selectedEntity,"!=",row," > ",this.state.selectedEntity!==row.name)
            if(this.state.selectedEntity!==row.name){
                continue
            }
            record = row
        }
        this.setState({
            component:"form",
            selectedRecord:record
        })
    }
    submitClockRequest=async ()=>{
        // clock-request
        /*

type ClockRequest struct {
	Org       string
	Module    string
	Ref       string
	ClockDate string
	ProjectModule string
	ProjectRef    string
	PayCycle      string
	Shift         string
	Slot          float64

	WorkerRef    string
	WorkerModule string
	Site string
	StartTime string
	EndTime   string
	QaState   string
}
         */

    }
    requestNewClock=()=>{
        this.setState({
            component:"form"
        })
    }
    handleChangeCompany = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    renderCompaniesSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = this.state.Companies;
        return (
            <Form.Item
                label={"Select Company"}
                name={"selectedCompany"}
                rules={[{required: true, message: "Please select your company!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedCompany")}
                    value={this.state.selectedCompany}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.CustomerNumber}
                                value={record.CustomerNumber}
                            >
                                {record.Name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderModuleSelection = () => {
        if (this.state.component === "form") {
            return
        }
        let ls = [];
        for(let i in this.state.Entities){
            const row = this.state.Entities[i];
            if(row.org!==this.state.selectedCompany){continue}
            ls.push(row);
        }
        return (
            <Form.Item
                label={"Select Project"}
                name={"selectedEntity"}
                rules={[{required: true, message: "Please select your project!"}]}
            >
                <Select
                    style={{width: "100%"}}
                    onChange={(value) => this.handleChangeCompany(value, "selectedEntity")}
                    value={this.state.selectedEntity}
                >
                    {ls.map((record, index) => {
                        return (
                            <Option
                                key={record.name}
                                value={record.name}
                            >
                                {record.name}
                            </Option>
                        )
                    })}
                </Select>
            </Form.Item>

        )
    }
    renderList=()=> {

        let user = GetUserToken();
        let columns = [
            {id:"clockdate",label:"Clock Date"},
            {id:"workerref",label:"Worker"},
            {id:"site",label:"Site"},
            {id:"hours",label:"Hours"},
            {id:"starttime",label:"Start Time"},
            {id:"endtime",label:"End Time"},
            {id:"qastate",label:"Qa Start"},
        ];
        if (this.state.component !== "list") {
            return
        }

        let ls=[];
        for(let i in this.state.Data){
            const row=this.state.Data[i];
            if(row.org!==this.state.selectedCompany){continue}
            if(row.module!==this.state.selectedEntity){continue}
            ls.push(row);
        }

        ls = SortData("position",ls)

        let data = {columns: columns, data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Records")}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                    allowedAdd={false}
                />
            </GridItem>
        )

    }
    renderForm=()=>{
        if (this.state.component !== "form") {
            return
        }
        return(
            <StepBulkClockingIndex  _this={this} />
        )
    }
    renderWarningBar=()=>{
        return
        let boo=false
        if(this.state.selectedCompany === "" || this.state.selectedEntity === "" ||this.state.component==="form"){
            boo=true
        }
        if(!boo){
            return
        }
        return(
            <div style={{minWidth: "100%", padding: 10, color: "red"}}>
                <Alert style={{ margin: '16px 0' }} message="Please select your company above to view your records!" type={"error"}/>
            </div>
        )
    }
    renderDateSelectorBar=()=>{
        if (this.state.component !== "list") {
            return
        }
        return(
            <DateSelectorBar _this={this} />
        )
    }
    render() {
        let props = this.props;
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Bulk Clocking</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        {this.renderCompaniesSelection()}
                        {this.renderModuleSelection()}
                        {this.renderDateSelectorBar()}
                    </div>
                    {this.renderWarningBar()}
                    {this.renderList()}
                    {this.renderForm()}
                </div>
            </Page>
        )
    }
}

export default Connector(ClockingBulkPage);

const styles = {
    container:{

    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
